import styled from "styled-components";

const TableWrapper = styled.div`
  th.ant-table-cell {
    background: var(--data-table-ulg, #f6f6f6) !important;
    color: var(--text-field-body-color, #5e718d);
    font-family: Lato;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px;
    padding: 6px 12px;
    padding-left: 0px;
    &:nth-child(1){
    padding-left: 8px;
    }
    &::before{
      display: none;
    }
  }
  td.ant-table-cell {
    border-bottom: 0px !important;
    padding:12px;
    padding-left: 0px;
}
`;
export { TableWrapper };
