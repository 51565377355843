import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import LabelInput from "../../components/Inputs/label-input";
import Button from "../../components/Button";
import Notification from '../../components/notifications/notification'

import { APP_LOGOS, APP_TITLES } from "../../constants";

import { AuthWrapper } from "./style";

import { ForgotPassword, SetAuthState } from "../../redux/slices/auth-slice";

const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const hostName = window.location.hostname;

const ForgetPassword = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState();

  const { message, error, loading } = useSelector((state) => state.auth);

  useEffect(() => {
    if (error) {
      Notification({
        type: "error",
        title: "Error",
        description: error
      });
      dispatch(SetAuthState({ field: "error", value: "" }));
      return;
    }
    if (message) {
      Notification({
        type: "success",
        title: "Success",
        description: message
      });
      dispatch(SetAuthState({ field: "message", value: "" }));
      dispatch(SetAuthState({ field: "success", value: false }));

      history.push("/auth/otp-verification");
      return;
    }
  }, [error, message]);


  const handleChange = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const handleLogin = () => {
    if (email === '') {
      getStyles();
      setEmailError("Enter the Email");
    } else {
      const result = validateEmail(email);
      if (result) {
        dispatch(ForgotPassword({ email }));
      } else setEmailError("Enter the Valid Email");
    }
  };

  const handleBlur = () => {
    if (email === '') {
      setEmailError("Enter the Email");
    } else {
      const result = validateEmail(email);
      if (!result) {
        setEmailError("Enter the Valid Email");
      } else {
        setEmailError("");
      }
    }
  };

  const getStyles = () => {
    if (emailError) {
      return {
        border: "1px solid red",
      };
    }
  };
  return (
    <AuthWrapper>
      <div className="content-holder" style={loading ? { opacity: 0.5 } : null}>
        <Row className="m-0 header-space">
          <Col md={5} sm={12}>
            <div className="left-section">
              {APP_LOGOS[hostName] ?
                <img src={APP_LOGOS[hostName]} alt="no-logo" />
                :
                <h2>{APP_TITLES[hostName]}</h2>
              }
              <p className="logo-description mb-0"> Don’t have an account?
              Create one. It takes less than a minuite!
                </p>
            </div>
          </Col>
          <Col md={7} sm={12}>
            <div className="right-content">
              <div className="user-form">
                <h2 className="heading">Forgot Password?</h2>
                <span className="heading-caption">
                  Enter the email address you used when you joined and we'll send
              you instructions to reset your password.{" "}
                </span>
                <hr className="bottom-line" />
                <LabelInput
                  label="Email"
                  placeholder="Email"
                  value={email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  style={getStyles()}
                  name='email'
                />
                <div className="d-flex align-items-center button-top justify-content-between">
                  <Button
                    text={loading ? "Sending....." : "Send Reset Instructions"}
                    className="primary mb-0"
                    onClick={handleLogin}
                    width=""
                  />
                  <div className="d-flex justify-content-center have-account">
                    <p>
                      Return to
                  <Link
                        to="/auth/sign-in"
                      >
                        Login
                  </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </AuthWrapper>
  );
};

export default ForgetPassword;
