import Styled from 'styled-components';
const StorePage = Styled.div`
.store-filter{
    border: 1px solid #E6E6E6;
    border-radius:50px;
    button{
        border-radius:50px;
        background-color:transparent ;
        border:none;
        margin-bottom:0;
        color: ${({ theme }) => theme["text-primary"]};
        font-weight:${({ theme }) => theme["normal-weight"]};
        &.active{
        background:${({theme}) => `linear-gradient(90deg, ${theme['button-secondary-color']} 0%, ${theme['primary-color']} 100%)`};
        border-radius:50px;
        font-weight:${({ theme }) => theme["bold-weight"]};
        border-top-right-radius: 50px!important;
        border-bottom-right-radius: 50px!important;
        }
    }
}
.grid-column-gap-16{
    grid-column-gap:16px;
}
.add-scroll{
    height:calc(100vh - 300px);
    overflow: auto;
    div:nth-child(3n){
        padding-right:0
    }
}
.store{
        border-radius:12px;
        border:1px solid #E6E6E6;
        padding:24px 20px;
        background:url("../../assets/images/newImages/background-lines.svg"),#f8f8f8 ;
        background-size:100% ;
        background-repeat:no-repeat ;
        margin-bottom:24px;
        .title{
            color:${({ theme }) => theme["text-primary"]};
            font-size:1.125rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width:188px;
            font-weight:${({ theme }) => theme["bold-weight"]};
        }
        .complete{
            padding: 2px 9px;
            background:${({ theme }) => theme["white-color"]};
            color:${({ theme }) => theme["secondary-color"]};
            border:1px solid ${({ theme }) => theme["secondary-color"]};
            border-radius:${({ theme }) => theme["border-radius-base"]};
            font-weight:${({ theme }) => theme["bold-weight"]};
            cursor:pointer;
        }
        .trash{
            color:${({ theme }) => theme["danger-color"]};
            cursor:pointer;
        }
        .mb-12{
            margin-bottom:12px;
        }
        .card-badge{
            color:${({ theme }) => theme["white-color"]};
            display:flex;
            align-items:center;
            padding:0px 12px;
            border-radius:50px;
            font-size:${({ theme }) => theme["medium-font-size"]};
            font-weight:${({ theme }) => theme["bold-weight"]};
            &.connected{
                background:${({ theme }) => theme["success-color"]};
            }
            &.disconnected{
                background:${({ theme }) => theme["warning-color"]};
            }
        }
        .flags{
            display:flex;
            align-items:center;
            grid-column-gap:15px;
        }

    }
        }
    }
    .header{
        border-radius:12px;
        &.total{
            background:${({ theme }) => theme["primary-color"]}03;
            border: 1px solid ${({ theme }) => theme["primary-color"]};
        }
        &.connected{
            background:${({ theme }) => theme["header-success-color"]}03;
            border: 1px solid ${({ theme }) => theme["header-success-color"]};
        }
        &.pending{
            background:${({ theme }) => theme["header-warning-color"]}03;
            border: 1px solid ${({ theme }) => theme["header-warning-color"]};
        }
        .logo{
        width:46px;
        height: 46px;
        border-radius:50px;
        background:${({ theme }) => theme["white-color"]};
        display: flex;
         align-items: center;
         justify-content: center;
    }
    h1{
        font-size:2.25rem
    }
    }
    .error {
      position: relative;
      left: 40%;
      top: 50%;
      h1{
        color: black;
      }
   }
`

const StoreWrapper = Styled.div`
font-family: 'Titillium Web', sans-serif;
.change-background{
  background-color:#E5E5E5;
  height:100vh;
  padding-top:57px;
}
    height:100%;
   .add-store{
      background-color: ${({ theme }) => theme['white-color']};
      margin:24px;
      padding:24px;
      height: calc(100vh - 92px);
      overflow-y: auto;
      border-radius:5px;
      .s-mp{
        height: calc(100vh - 300px);
        h2{
          color: ${({ theme }) => theme['primary-color']};
          margin-bottom:48px;
        }

        .add_account{
          border: 1px solid #CFD8DC;
          text-align:center;
          position: relative;
          min-height: 226px;
          margin-bottom:10px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius:5px;
          cursor:pointer;
          &_div{
                background-color: ${({ theme }) => theme['primary-color']};
                text-align:center;
                position: absolute;
                bottom:0;
                width:100%;
                padding:19.5px 0;
                border-bottom-left-radius: 5px;
                border-bottom-right-radius: 5px;
                h3{
                    color: ${({ theme }) => theme['white-color']};
                    font-size:${({ theme }) => theme['base-font-size']};
                    margin-bottom:0;
                }
          }
        }
    }
          .divider{
          display: flex;
          margin: 16px 0;
          color: #000000d9;
          font-weight: 500;
          font-size: 16px;
          white-space: nowrap;
          text-align: center;
          border-top: 0;
          border-top-color: #0000000f;
          clear: both;
          width: 100%;
          min-width: 100%;
          margin-top:50px;
            &::before{
              position: relative;
            top: 50%;
            width: 50%;
            border-top: 1px solid transparent;
            border-top-color: inherit;
            border-bottom: 0;
            transform: translateY(50%);
            content: "";
            }
              &::after{
              position: relative;
              top: 50%;
              width: 50%;
              border-top: 1px solid transparent;
              border-top-color: inherit;
              border-bottom: 0;
              transform: translateY(50%);
              content: "";
                  }
              }
                .inner-text{
                display: inline-block;
                  padding: 0 1em;
                  border: 1px solid #f0f0f0;
                  border-radius: 50px;
                      color: ${({ theme }) => theme['secondary-color']};
                }
          }
        }
        .select-region{
              background-color: ${({ theme }) => theme['white-color']};
              margin:24px;
              padding:24px;
              /* height: calc(100vh - 105px);
              overflow-y: auto; */
              .region-scroll{
                     height: calc(100vh - 252px);
                       overflow-y: auto;
              }
              border-radius:5px;
              .arrow-box{
                display: flex;
                align-items:center;
                  svg{
                     color: ${({ theme }) => theme['primary-color']};
                  }
          }
          .caption{
            color: ${({ theme }) => theme['cutty_sark']};
              margin-bottom:24px ;
          }
          .select_region{
                 color: ${({ theme }) => theme['base-font-size-small']};
                 font-size:${({ theme }) => theme['base-font-sizes']};
          }
          .region_marketplace{
              .add_scrolll{
                max-height: 281px;
                overflow-y: auto;
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                margin-top:10px;
                border-bottom:1px solid #EEF1F5;
                margin-bottom:20px;
                .flag-name{
                      color: ${({ theme }) => theme['secondary-color']};
                      font-size: ${({ theme }) => theme['base-font-size-small']};
                      margin-left:10px;
                }
              }
          }
          .region{
            &_block{
                border: 1px solid #CFD8DC;
                border-radius:6px;
                display: flex;
                flex-direction:column;
                align-items:center;
                justify-content:center;
                padding: 16px;
                margin-bottom:16px;
                cursor: pointer;

                  &:hover{
              box-shadow: 6px 6px 8px rgba(0, 0, 0, 0.16);
            }
                span{
                    font-size: ${({ theme }) => theme['base-font-size']};
                    color: ${({ theme }) => theme['secondary-color']};
                }
                img{
                  margin-bottom:10px;
                }
                .round {
                          position: relative;
                          margin-right: 12px;
                          margin-top: 6px;
                        }

                        .round label {
                          background-color: #fff;
                          border: 1px solid #ccc;
                          border-radius: 50%;
                          cursor: pointer;
                          height: 16px;
                          left: 0;
                          position: absolute;
                          top: 1px;
                          width: 16px;
                        }

                        .round label:after {
                            border: 2px solid #fff;
                            border-top: none;
                            border-right: none;
                            content: "";
                            height: 4px;
                            left: 2.5px;
                            opacity: 0;
                            position: absolute;
                            top: 4px;
                            transform: rotate(-45deg);
                            width: 9px;
                        }

                        .round input[type="checkbox"] {
                          visibility: hidden;
                        }

                        .round input[type="checkbox"]:checked + label {
                          background-color: #00B48F;
                          border-color: #00B48F;
                        }

                        .round input[type="checkbox"]:checked + label:after {
                          opacity: 1;
                        }

            }

          }
        }
        .map_region{
          text-align:center;
          img{
            height: 500px;
          }
        }
        .grid-row-gap-20{
          grid-row-gap:20px;
        }
        .walmart-list{
          list-style:none;
          padding-left:0;
          margin-bottom:30px;
          li{
               font-size: ${({ theme }) => theme['base-font-size']};
                color: ${({ theme }) => theme['secondary-color']};
                margin-bottom:10px;
          }
        }
        .select-region-scroll{
          height: calc(100vh - 302px);
             overflow: auto;
        }
        .add-user-list{
          list-style:none;
          li{
            color: ${({ theme }) => theme['text-primary']};
            font-size: ${({ theme }) => theme['base-font-size']};
            font-weight:${({ theme }) => theme['bold-weight']};
            margin-bottom:12px;
          }
        }
        .add-padding-top{
          padding-top:96px;
          height: calc(100vh - 200px);
          overflow: auto;
        }
        .seller-setting{
          list-style:none;
          li{
            color: ${({ theme }) => theme['text-primary']};
            strong{

            }
          }
        }
        .add-scroll{
          padding-top:96px;
          height: calc(100vh - 200px);
          overflow: auto;
        }
        .user-guide-img{
          img{
            max-width:750px;
          }
          .user-guide{
          font-size: ${({ theme }) => theme['base-font-size-small']};
          color:${({ theme }) => theme['text-primary']};
          display: flex;
           margin:0 38px 0;
            div:nth-child(1){
                min-width:143px;
                max-width:143px;
              }
              div:nth-child(2){
                min-width:251px;
                max-width:251px;
              }
              div:nth-child(3){
                min-width:162px;
                max-width:162px;
              }
        }
        }
      .add-store-scroll{
        height: calc(100vh - 263px);
        overflow: auto;
      }
      .select-card{
      margin:24px;
      padding:24px;
      border-radius:5px;
      .add-payment{
      height: calc(100vh - 230px);
      overflow: auto;
      }
      }
      .mt-77{
        margin-top:77px;
      }
  `;
export {StorePage, StoreWrapper} ;
