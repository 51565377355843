import styled from 'styled-components';

const ShipmentFbaWrapper = styled.div`
  .shipment-store {
    span {
      display: block;
      &:first-child {
        margin-top: 8px;
        line-height: 14px;
      }
      &:nth-child(2) {
        color: ${({ theme }) => theme["on-surface"]};
        font-size: ${({ theme }) => theme["xs-small-size"]};
        font-weight: ${({ theme }) => theme["medium-weight"]};
        line-height: 12px;
      }
    }
  }
  .loading-wrapper {
    height: 100vh;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    left: 0;
    right: 0;
    background: #ffffffd9;
}
  .ribbon-text {
    width: 100%;
    position: absolute;
    top: -8px;
    left: -6px;
    font-size: 6px;
    font-weight: ${({ theme }) => theme["xl-bold-weight"]};
  }
  #ribbon {
    width: 82px;
    height: 10px;
    position: absolute;
    background: ${({ theme }) => theme["new-bg"]};
    left: 0px;
    top:-8px;
    color: white;
    text-align: center;
  }
  #ribbon:after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border-right: 10px solid white;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
  }
  .top-margin{
    margin-top: 20%;
  }
  .shipment-name {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: pre-wrap;
    line-height: 16px;
  }
`
const UploadDocumentsWrapper = styled.div`
  .loader-wrapper {
      width: 100%;
      right: 0;
      height: calc(100vh*1.39);
      background: #ffffffd9;
      top: 0;
      z-index:2;
  }
  .drawer-main {
    padding: 30px 24px 24px;
    .drawer-heading {
      background-color: ${({theme}) => `${theme['heading-bg-color']}`};
      padding: 8px 16px;
      margin-bottom: 24px;
      h6 {
        margin-bottom: 0;
        font-weight: ${({theme}) => `${theme['xl-bold-weight']}`};
        color: ${({theme}) => `${theme['on-surface']}`};
        line-height: 24px;
      }
      .document-status {
        p {
          font-size: ${({theme}) => `${theme['base-font-size-small']}`};
          font-weight: ${({theme}) => `${theme['bold-weight']}`};
          color: ${({theme}) => `${theme['on-surface']}`};
          line-height: 16px;
          margin-bottom: 0;
          margin-right: 4px;
        }
        div {
          padding: 8px 12px;
          border-radius: 40px;
          font-size: ${({theme}) => `${theme['medium-font-size']}`};
          font-weight: ${({theme}) => `${theme['xl-bold-weight']}`};
          color: ${({theme}) => `${theme['bg-white']}`};
          line-height: 16px;
          &.awaiting {
            background-color: ${({theme}) => `${theme['info-color']}`};
          }
          &.pending {
            background-color: ${({theme}) => `${theme['warning-color']}`};
          }
          &.info {
            background-color: ${({theme}) => `${theme['purple-color']}`};
          }
        }
        .view-chat-btn {
          gap: 16px;
          button {
            padding: 8px 12px;
          }
        }
      }
    }
    .document-details {
      border: 1px solid ${({theme}) => `${theme['table-border-color']}`};
      border-radius: 8px;
      box-shadow: 0px 4px 20px 0px #0000000D;
      padding: 24px 20px 24px 24px;
      position: relative;
      &.less-height {
        height: 145px;
        overflow: hidden;
        transition: overflow 0.5s;
        .shipment-hr {
          opacity: 0;
        }
      }
      &.full-height {
        height: 100%;
        transition: height 0.5s;
      }
      .gap-32 {
        gap: 32px;
      }
      p{
        font-size: ${({theme}) => `${theme['medium-font-size']}`};
        font-weight: ${({theme}) => `${theme['bold-weight']}`};
        color: ${({theme}) => `${theme['on-surface']}`};
        line-height: 20px;
        margin-bottom: 12px;
      }
      label {
        display: block;
        font-size: ${({theme}) => `${theme['base-font-size-small']}`};
        font-weight: ${({theme}) => `${theme['bold-weight']}`};
        color: ${({theme}) => `${theme['on-surface']}`};
        line-height: 16px;
      }
      span {
        display: block;
        font-size: ${({theme}) => `${theme['base-font-size-small']}`};
        color: ${({theme}) => `${theme['text-color']}`};
        line-height: 16px;
      }
      .shipment-data {
        gap: 20px;
        span {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          white-space: pre-wrap;
          line-height: 16px;
          max-width: 100px;
        }
      }
      .ag-header {
        border-bottom: none;
      }
      .ag-row-odd  {
        background-color: transparent;
      }
    }
    .show-more-btn {
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
      bottom: -30px;
      button {
        border-radius: 40px;
      }
      .outlined {
        background-color: ${({theme}) => `${theme['white-color']}`};
      }
    }
  }
  .ownership-box {
    border: 1px solid ${({theme}) => `${theme['table-border-color']}`};
    border-radius: 8px;
    margin-top: 28px;
    .ownership-heading {
      background-color: ${({theme}) => `${theme['light-bg-color']}`};
      padding: 8px 16px;
      border-radius: 8px 8px 0 0;
      span {
        font-size: ${({theme}) => `${theme['medium-font-size']}`};
        font-weight: ${({theme}) => `${theme['xl-bold-weight']}`};
        color: ${({theme}) => `${theme['on-surface']}`};
        line-height: 16px;
      }
    }
    .inner-box {
      padding: 12px 16px 16px;
      .inventory-box {
        border: 1px solid ${({theme}) => `${theme['info-stroke']}`};
        background-color: ${({theme}) => `${theme['light-blue-bg']}`};
        padding: 12px 16px;
        border-radius: 8px;
        .info-title {
          gap: 12px;
          margin-bottom: 12px;
          span {
            font-size: ${({theme}) => `${theme['base-font-size-small']}`};
            font-weight: ${({theme}) => `${theme['bold-weight']}`};
            color: ${({theme}) => `${theme['body-darker']}`};
            line-height: 18px;
          }
        }
        .slip-box {
          background-color: ${({theme}) => `${theme['white-color']}`};
          border-radius: 8px;
          padding: 12px;
          /* min-height: 126px; */
          span {
            font-size: 9px;
            font-weight: ${({theme}) => `${theme['xl-bold-weight']}`};
            color: ${({theme}) => `${theme['text-color']}`};
            line-height: 10px;
            &.slip-title {
              display: block;
              margin-bottom: 8px;
            }
          }
          ul {
            padding-left: 8px;
            margin-bottom: 0;
            li {
              font-size: 9px;
              color: ${({theme}) => `${theme['text-color']}`};
              line-height: 14px;
            }
          }
        }
      }
    }
    .upload-document {
      margin-top: 12px;
      .upload-heading {
        font-size: ${({theme}) => `${theme['base-font-size-small']}`};
        font-weight: ${({theme}) => `${theme['xl-bold-weight']}`};
        color: ${({theme}) => `${theme['on-surface']}`};
        line-height: 24px;
        display: block;
        margin-bottom: 8px;
      }
      .drag-files {
        gap: 16px;
        p {
          &:first-child {
            font-size: ${({theme}) => `${theme['base-font-size-small']}`};
            font-weight: ${({theme}) => `${theme['xl-bold-weight']}`};
            color: ${({theme}) => `${theme['dark-text']}`};
            line-height: 14px;
            text-align: left;
            margin-bottom: 8px;
          }
          &:nth-child(2) {
            font-size: ${({theme}) => `${theme['xs-small-size']}`};
            color: ${({theme}) => `${theme['on-secondary']}`};
            line-height: 12px;
          }
        }
      }
      .ant-upload.ant-upload-drag {
        background-color: transparent;
        border-radius: 8px;
        .ant-upload-btn {
          padding: 12px 0;
        }
      }
      .ant-upload-list {
        display: none;
      }
      .no-file-box {
        border: 1px solid ${({theme}) => `${theme['border-light-color']}`};
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 58px;
        margin-top: 32px;
        span {
          font-size: ${({theme}) => `${theme['base-font-size-small']}`};
          font-weight: ${({theme}) => `${theme['xl-bold-weight']}`};
          color: ${({theme}) => `${theme['dark-gray-text']}`};
          line-height: 14px;
        }
      }
      .uploaded-height {
        max-height: 58px;
        overflow: auto;
        .uploaded-files {
          padding-bottom: 8px;
          margin-bottom: 8px;
          border-bottom: 1px solid ${({theme}) => `${theme['border-color']}`};
          div {
            &:first-child {
              gap: 8px;
            }
            &:nth-child(2) {
              gap: 12px;
            }
            span {
              font-size: ${({theme}) => `${theme['base-font-size-small']}`};
              font-weight: ${({theme}) => `${theme['medium-weight']}`};
              color: ${({theme}) => `${theme['text-color']}`};
              line-height: 16px;
            }
          }
        }
      }
    }
  }
  .submitted-btn {
    display: flex;
    justify-content: flex-end;
    margin-top: 32px;
    button {
      background: ${({ theme }) => `${theme['new-bg']}`};
      font-size: ${({theme}) => `${theme['xs-small-size']}`};
      border-radius: 50px;
      padding: 10px 12px;
      line-height: 12px;
    }
  }
  .equal-height-row {
    display: flex;
    .col-sm-6 {
      display: flex;
      flex-direction: column;
    }
    .col-sm-6 > div{
      flex: 1;
    }
  }
  .case-box {
    border: 1px solid ${({theme}) => `${theme['table-border-color']}`};
    box-shadow: 0px 4px 20px 0px #0000000D;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 24px;
    .case-input {
      gap: 12px;
      .form-group {
        margin-bottom: 0;
        max-width: 322px;
        input {
          font-size: ${({theme}) => `${theme['base-font-size-small']}`};
        }
        label {
          font-size: ${({theme}) => `${theme['base-font-size-small']}`};
        }
      }
    }
    .client-input {
      gap: 12px;
      .form-group {
        margin-bottom: 0;
        textarea {
          font-size: ${({theme}) => `${theme['base-font-size-small']}`};
        }
        label {
          font-size: ${({theme}) => `${theme['base-font-size-small']}`};
        }
      }
      button {
        margin-top: 16px;
      }
    }
    button {
      line-height: 12px;
      padding: 8.5px;
    }
  }
  .client-notes {
    border: 1px solid ${({theme}) => `${theme['info-stroke']}`};
    background-color: ${({theme}) => `${theme['info-bg']}`};
    border-radius: 8px;
    gap: 16px;
    padding: 16px;
    margin-bottom: 24px;
    p {
      font-size: ${({theme}) => `${theme['medium-font-size']}`};
      font-weight: ${({theme}) => `${theme['xl-bold-weight']}`};
      color: ${({theme}) => `${theme['body-darker']}`};
      line-height: 18px;
      margin-bottom: 4px;
    }
    span {
      display: block;
      font-size: ${({theme}) => `${theme['base-font-size-small']}`};
      font-weight: ${({theme}) => `${theme['medium-weight']}`};
      color: ${({theme}) => `${theme['bold-dark']}`};
    }
  }
`

const ChatBoxWrapper = styled.div`
  .chat-box-heading {
    border-bottom: 1px solid ${({theme}) => `${theme['divider-color']}`};
    div {
      padding: 16px 24px;
      h6 {
        font-weight: ${({theme}) => `${theme['xl-bold-weight']}`};
        color: ${({theme}) => `${theme['on-surface']}`};
        line-height: 24px;
        margin-bottom: 0;
      }
    }
  }
  .chat-box {
    margin: 16px 24px;
    padding: 24px 32px 24px 24px;
    border: 1px solid ${({theme}) => `${theme['divider-color']}`};
    border-radius: 2px;
    height: calc(100vh - 90px);
    overflow: auto;
    .admin-chat {
      margin-bottom: 24px;
      .chat-content {
        background-color: ${({theme}) => `${theme['light-blue']}`};
        font-size: ${({theme}) => `${theme['base-font-size-small']}`};
        color: ${({theme}) => `${theme['text-color']}`};
        border-radius: 2px;
        padding: 4px 12px 4px 8px;
        line-height: 16px;
      }
    }
    .user-chat {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-bottom: 24px;
      .chat-content {
        background-color: ${({theme}) => `${theme['divider-color']}`};
        font-size: ${({theme}) => `${theme['base-font-size-small']}`};
        color: ${({theme}) => `${theme['text-color']}`};
        border-radius: 2px;
        padding: 4px 8px 4px 12px;
        line-height: 16px;
      }
      .chat-time {
        text-align: right;
      }
    }
    .chat-time {
      font-size: 8px;
      font-weight: ${({theme}) => `${theme['bold-weight']}`};
      color: ${({theme}) => `${theme['gray-text']}`};
      display: block;
      margin-top: 4px;
      line-height: 8px;
    }
    .image-file {
      background-color: ${({theme}) => `${theme['light-blue']}`};
      padding: 8px;
      border-radius: 0 8px 8px 8px;
      max-width: 302px;
      width: max-content;
      overflow: hidden;
      .chat-img {
        position: relative;
        line-height: 0;
        cursor: pointer;
        img {
          object-fit: cover;
        }
        .img-overlay {
          position: absolute;
          top: 0;
          width: 100%;
          height: 100%;
          background-color: #00000066;
          display: flex;
          align-items: center;
          justify-content: center;
          h6 {
            color: ${({theme}) => `${theme['white-color']}`};
            margin-bottom: 0;
          }
        }
      }
    }
  }
`

export { ShipmentFbaWrapper, UploadDocumentsWrapper, ChatBoxWrapper };
