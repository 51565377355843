import * as Sentry from '@sentry/browser';

const SaveSentryLog = (error, section) => {
  const userId = localStorage.getItem('userId');

  if (process.env.NODE_ENV === 'production') {
    Sentry.configureScope((scope) => scope.setTransactionName(section));
    Sentry.setUser({ id: userId });
    Sentry.withScope(scope => {
      scope.setExtra(userId, error);
      Sentry.captureException(error, {
        tags: {
          section
        }
      });
    });
  }
};

export default SaveSentryLog;
