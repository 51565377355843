import React, { useState, useEffect } from "react";
import { Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";

import Alert from '../../../components/alert';
import TableSt from "../../../components/TableSt";
import RadioButton from "../../../components/RadioButton";
import RadioCardBar from "../../../components/radioCardBar";
import ButtonSt from "../../../components/ButtonSt/";
import Button from "../../../components/ButtonSt/";

import { APP_DOMAINS, BASE_URLS } from "../../../constants";

import Permission from "../../../assets/images/permission-2.svg";
import PermissionSp from "../../../assets/images/sp-key-icon.svg";
import PermissionIcon from "../../../assets/images/permission-st.svg";
import OptionImage from "../../../assets/images/option-image-st.svg";

import { ManegePermissionsWrapper } from "./style";

import { 
  DisconnectSocket,
  HandlePermissionUpdate,
  HandleRecoveryUpdate,
  HandleStatusUpdate,
  SocketConnection 
} from "../../../helpers/socket";

import { GetAmazonAccountDetail, SetState, StartEcsPermissionTask } from "../../../redux/slices/account-detail-slice";

const ManegePermissions = () => {
  const hostName = window.location.hostname;
  const dispatch = useDispatch();
  const [err, setErr] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [showButton, setShowButton] = useState(true);
  const [url, setUrl] = useState(BASE_URLS.na);

  const {
    storeId,
    store,
    loading,
    potentialRecovery
  } = useSelector((state) => state.store);
  const { user: { userId } } = useSelector((state) => state.auth);
  const { account, amazonAccountSuccess, managerName } = useSelector((state) => state.accountDetail);
  const [loader, setLoader] = useState(true);

  const cardBar = [
    {
      titleText: "Manage FBA Inventory/Shipments",
      labelText: "For analyzing lost inbound shipments",
      firstValue: false,
      secondValue: true
    },
    {
      titleText: `${store?.region === 'eu' ? 'Fulfilment' :'Fulfillment'} Reports`,
      labelText: "For analyzing reimbursement opportunities",
      firstValue: true,
      secondValue: false
    },
    {
      titleText: "Payments",
      labelText: "For analyzing overcharged fee",
      firstValue: false,
      secondValue: true
    },
    {
      titleText: "Manage Your Cases",
      labelText: "For submitting claims",
      firstValue: false,
      secondValue: true
    },
    {
      titleText: "Selling Partner Appstore",
      labelText: "For analyzing overcharged fee",
      firstValue: false,
      secondValue: true
    },
  ];
  const dataSource = [
    {
      key: "1",
      name: (
        <div className="card_ui_wrapper">
          <p>
            {
              loader ? (
                <Spin size="small" />
              ) : (
                hostName === APP_DOMAINS.sellerRepay ?
                  managerName || 'Seller Repay Manager' :
                  managerName || 'Seller Terminal Manager'
              )
            }
          </p>
        </div>
      ),
      email: (
        <div className="card_ui_wrapper">
          <p>{account?.sellerCentralLoginEmail || 'Manager Email'}</p>
        </div>
      ),
      language: (
        <div className="overlay_card_item">
          <div className="card_ui_wrapper">
            <p className="btn-type-text">Manage Permission</p>
          </div>
          <p className="card_ui_item"> Delete</p>
        </div>
      ),
    },
  ];

  const permissionIndex = ['shipment', 'fbaReports', 'paymentReports', 'manageCases', 'appStore'];
  const columns = [
    { title: "Name", dataIndex: "name", key: "name" },
    { title: "Email", dataIndex: "email", key: "email" },
    { title: "Language", dataIndex: "language", key: "language" },
  ];
  const indicesToCheck = [1, 3, 6, 8, 10];
  const notIndicesToCheck = [2, 4, 5, 7, 9];

  useEffect(() => {
    if (store?.invite?.amazonAccountDetailId) {
      dispatch(GetAmazonAccountDetail({ accountId: store?.invite?.amazonAccountDetailId }))
    }

    const socket = SocketConnection({ url: process.env.SERVER_URL, userId });

    HandleStatusUpdate(socket, dispatch, storeId, store);
    HandlePermissionUpdate(socket, dispatch, storeId);
    HandleRecoveryUpdate(socket, dispatch, storeId, potentialRecovery);

    if (store?.region === 'eu') {
      setUrl(BASE_URLS.eu)
    } else if (store?.region === 'fe') {
      setUrl(BASE_URLS.fe)
    }

    window.addEventListener('beforeunload', () => {
      DisconnectSocket(socket);
    });

    return () => {
      DisconnectSocket(socket);

      window.addEventListener('beforeunload', () => {
        DisconnectSocket(socket);
      });
    }
  }, []);

  useEffect(() => {
    if (!loading && showLoading) {
      setShowButton(true);
    }
  }, [loading]);

  useEffect(() => {
    if (amazonAccountSuccess !== null) {
      setLoader(false);
      dispatch(SetState({ field: 'amazonAccountSuccess', value: null }));
    }
  }, [amazonAccountSuccess])

  useEffect(() => {
    let data = { ...store.permissions };
    delete data.permissionsGranted;
    delete data.permissionsUpdatedAt;
    const values = Object.values(data);
    if (values.length === 5 && values.includes(false)) {
      setErr(true);
    }
  }, [store.permissions])

  return (
    <ManegePermissionsWrapper>
      <div className="overlay_wrapper">
        <div className="header_wrapper">
          <div className="sub-header-wrapper">
            <img
              src={
                hostName === APP_DOMAINS.sellerTerminal
                  ? Permission
                  : PermissionSp
              }
            />
            <h2>Manage Permissions</h2>
          </div>
          {
            (err && showButton) && (
              <Alert
                className="manage-permissions"
                errorText={<p>You're missing required permissions to proceed. Please update missing permissions, then click <b>'{(showLoading ? 'Re-' : '') + 'Check Permissions'}'</b> to Proceed.</p>}
                buttonText=""
                hide={() => setErr(false)}
              />
            )
          }
        </div>
        <div className="card-items_wrapper">
          <div className="card_items_overlay">
            <div className="card_items">
              <Button onClick={() => window.open(`${url}gp/account-manager/home.html/ref=xx_userperms_dnav_xx`, '_blank')} outline>Add Permissions</Button>
              <p>
                Navigate to <b>'User Permissions'</b> then click{" "}
                <b>'Manage Permissions'</b> next to {managerName?.length ? managerName : hostName === APP_DOMAINS.sellerRepay ? 'Seller Repay' : 'Seller Terminal'}.
              </p>
            </div>
            <div className="image-container-wrapper">
              <div className="table_container">
                <h6>Add a New User</h6>
                <TableSt
                  className="step_two_table_wrapper"
                  dataSource={dataSource}
                  pagination={false}
                  columns={columns}
                />
              </div>
              <div className="ask-question-wrapper">
                <h6>
                  Amazon may ask the following question. In this event, please
                  select 'No'
                </h6>
                <p>
                  Do you want to allow the secondary user in Seller Central to
                  handle return requests and/or issue refunds?
                </p>
                <div className="radio-buttons-wrapper">
                  <RadioButton defaultValue={false} disabled>Yes</RadioButton>
                  <RadioButton>No</RadioButton>
                </div>
              </div>
            </div>
          </div>
          <div className="right_side_content_overlay">
            <div className="header_st_wrapper">
              <div className="card_st_item">
                <img src={PermissionIcon} />
                <h6>Add the Following Permissions</h6>
              </div>
              <div className="right_side_header_item">
                <span>View</span>
                <span>View & Edit</span>
              </div>
            </div>
            <div className="bar_ui_wrapper_st">
              {cardBar.map((item, index) => (
                <RadioCardBar
                  key={index}
                  name={permissionIndex[index]}
                  titleText={item.titleText}
                  labelText={item.labelText}
                  showSpin={showLoading}
                  firstValue={item.firstValue}
                  secondValue={item.secondValue}
                  showButton={showButton}
                />
              ))}
              <small className="p-1 text-danger">
                Click <b>{showLoading ? 'Re-' : ''}Check Permissions</b> when Permissions are {showLoading ? 'Edited' : 'Added'} & Saved.
              </small>
              <ButtonSt
                onClick={() => {
                  dispatch(StartEcsPermissionTask({ storeId }))
                  setShowLoading(true);
                  setShowButton(false);
                }}
                outline
                disabled={showButton ? false : true}
              >{showLoading ? 'Re-' : ''}Check Permissions</ButtonSt>
            </div>
          </div>
        </div>
      </div>
    </ManegePermissionsWrapper>
  );
};

export default ManegePermissions;
