import styled from "styled-components";

const RestartButtonWrapper = styled.div`
  button {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    position: absolute;
    right: 0;
    top:68px;
    z-index: 2;
    &:hover,
    :focus {
      border-radius: 4px 0px 0px 4px;
    }
    &.not-hovered {
      width: auto !important;
      transition: width 1s ease-in;
      span{
        display: none;
      }
    }
    &.not-hovered {
      width: 33px !important;
      padding: 0 0 0 11px;
      transition: width 1s ease-in;
      display: block;
    }
  }
  .box-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h6 {
      color: var(--Secondary, #151a26);
      text-align: center;
      font-family: Lato;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
      margin: 0;
      margin-top: 16px;
      margin-bottom: 12px;
      width: 100%;
      max-width: 316px;
    }
    p {
      color: var(--text-field-body-color, #5e718d);
      text-align: center;
      font-family: Lato;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      margin: 0px;
      margin-bottom: 24px;
      width: 100%;
      max-width: 336px;
    }
  }
  .button-footer-ui {
    display: flex;
    align-items: center;
    grid-gap: 16px;
    button{
      position: relative;
      top: 0;
    }
}
`;
export { RestartButtonWrapper };
