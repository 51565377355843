import React from "react";
import { SearchInputWrapper } from "./style";
import { Form } from "react-bootstrap";
import { BsSearch } from "react-icons/bs";

const Index = (props) => {
  const { type, placeholder, onChange, value, disabled, className } = props;

  return (
    <SearchInputWrapper>
      <Form.Control
        disabled={disabled}
        value={value}
        type={type}
        placeholder={placeholder}
        onChange={onChange}
        className={className}
      />
      <BsSearch className="color-secondary" />
    </SearchInputWrapper>
  );
};

export default Index;
