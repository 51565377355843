import Styled from "styled-components";

const UserWrapper = Styled.div`
span.table-heading-arrow-wrapper {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    svg {
        font-size: 32px;
        cursor:pointer;
        margin-right: -10px;
    }
}
p.circle-icon {
    border-radius: 30px;
    background: rgba(2, 99, 99, 0.1);
    display: flex;
    width: var(--Main-System-16px, 16px);
    height: var(--Main-System-16px, 16px);
    padding: 0px 2px 0px 1px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    cursor: pointer;
    width: 20px;
    font-size: 11px !important;
    height: 20px;
    margin: 0px;
    padding: 5px !important;
    margin: 0px !important;
    color: #026363 !important;
  }
.agancy {
    border-radius:${({ theme }) => theme['border-radius-normal']}; 
    margin-bottom:12px;
    span{
        font-size:${({ theme }) => theme['medium-font-size']}; 
    }
    h1{
        font-size:2.25rem;
    }
    &.recovered{
        background:linear-gradient(135deg, #600D7D 0%, #105AC6 100%);
        span,h1{
        color:${({ theme }) => theme['white-color']};  
        }
       
    }
    &.claimed{
        background:rgba(6, 74, 172, 0.02);
        border: 1.5px solid #064AAC;
        span{
        color:${({ theme }) => theme['grey-color']};  
        }
        h1{
            color:${({ theme }) => theme['primary-color']};  
        }
    }
    &.toClaimed{
        background: rgba(255, 169, 0, 0.02);
        border: 1.5px solid #FFA900;
        span{
        color:${({ theme }) => theme['grey-color']};  
        }
        h1{
            color:${({ theme }) => theme['primary-color']};  
        }
    }  
    .hover-info{
        height:0;
        opacity: 0;
        -moz-transition: height 1s ease;
        -webkit-transition: height 1s ease;
        -o-transition: height 1s ease;
        transition: visibility 1s ease;
        transition: opacity .25s ease-in-out;
    }   
    &:hover{
     .hover-info{
        height:40px;
        opacity: 1;
        -moz-transition: height 0.5s ease;
        -webkit-transition: height 0.5s ease;
        -o-transition: height 0.5s ease;
        transition: height 0.5s ease;
    }  
    }
}
.table-action{
    grid-column-gap:24px; 
    svg{
        color:${({ theme }) => theme['secondary-color']}; 
        cursor:pointer ;
    }
}
.shipping-detail{
    grid-column-gap:5px;
    .info{
        grid-row-gap:5px;
        .d-flex{
            grid-column-gap:16px;   
            span{
                font-size:0.625rem
            }
        }
    }
}
.filter-box{
    position: absolute;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.12);
    padding: 16px;
    top:32px;
    background-color:${({ theme }) => theme['white-color']}; 
    width: 172px;
    z-index: 1001;
        h6{
            font-size:${({ theme }) => theme['medium-font-size']};  
        }
        .text-right{
            span{
                font-size:${({ theme }) => theme['medium-font-size']};  
                color:${({ theme }) => theme['secondary-color']};  
                cursor: pointer;
            }
        }
}
.agancy-name{
    span:nth-child(1){
        font-size:${({ theme }) => theme['medium-font-size']};  
        color:${({ theme }) => theme['text-primary']};  
        font-weight:${({ theme }) => theme['bold-weight']}; 
    }
    span:nth-child(2){
        font-size:${({ theme }) => theme['medium-font-size']};  
        color:${({ theme }) => theme['text-primary']};  
    }
}
.calender-input{
    svg{
        position: absolute;
        right:5px;
        top: 6px;
    }
    input::-webkit-calendar-picker-indicator {
    cursor: pointer;
  }
    input{
        width:25px;
        opacity: 0;
    }
}
.text-ellipse{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px;
}
.select-option{
    span:nth-child(1){
        font-size:${({ theme }) => theme['medium-font-size']};  
        font-weight:${({ theme }) => theme['bold-weight']};  
    }
    span:nth-child(2){
        font-size:${({ theme }) => theme['medium-font-size']};  
    }
}
.error {
    position: absolute;
    left: 40%;
    top: 50%;
 } 
 .users-table-wrapper{

 table{

     tbody{

         tr{
            td{
                p{
                 padding: 1px 10px;
                }
                &:nth-child(4) {
                    display: flex;
                    flex-direction: column;
                    grid-row-gap: 4px;
                }
            }
         } 
     } 
 }
} 
.d-flex.edit-commision-column{
    grid-gap: 8px;
    align-items: center;
 span {
    cursor: pointer;
    padding: 0px 8px;
    background-color: ${({ theme }) => theme['new-primary']};
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    border-radius: 4px;
}}
`
export { UserWrapper };
