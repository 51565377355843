import styled from "styled-components";
import { Button } from "antd";
import "antd/dist/antd.css";

const StButton = styled(Button)`
  background: ${(props) =>
    props.outline ? props.theme["bg-white"] : props.theme["new-bg"]} !important;
  color: ${(props) =>
    props.disabled ? 'grey':props.outline ? props.theme["new-primary"] : props.theme["bg-white"]} !important;
  border: 1px solid ${(props) => props.disabled ? 'grey' : props.theme["new-primary"]} !important;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  padding: 8px 32px;
  border-radius: 4px !important;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: ${(props) =>
      props.disabled? '':props.outline ? props.theme["new-bg"] : props.theme["bg-white"]}!important;
    border: 1px solid ${(props) => props.disabled ? 'grey' : props.theme["new-primary"]}!important;
    color: ${(props) =>
      props.disabled ? 'grey':props.outline ? props.theme["bg-white"] : props.theme["new-primary"]} !important;
  }

  &.ant-btn:hover, .ant-btn:focus, .ant-btn:active {
    background-color: ${(props) =>
      props.disabled? '':props.outline ? props.theme["new-bg"] : props.theme["bg-white"]}!important;
    border: 1px solid ${(props) => props.disabled ? 'grey' : props.theme["new-primary"]}!important;
    color: ${(props) =>
      props.disabled? 'grey':props.outline ? props.theme["bg-white"] : props.theme["new-primary"]} !important;
  }
`;

export { StButton };
