import crypto from 'crypto';
import moment from 'moment';
import { cloneDeep, debounce } from "lodash";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ButtonGroup, Row, Col } from "react-bootstrap";
import { components } from "react-select";
import { BsTrash, BsPencilSquare } from "react-icons/bs";
import { Tooltip } from 'antd';

import Button from "../../components/Button";
import Checkbox from "../../components/CheckBox/index";
import Modal from "../../components/Modal/modal";
import Select from "../../components/Select/index";
import SeachInput from "../../components/SearchInput";
import Spin from "../../components/Spin/Index";
import Toast from '../../components/Toast';
import Notification from '../../components/notifications/notification';

import Total from "../../assets/images/newImages/total.svg";
import Connected from "../../assets/images/newImages/connected.svg";
import Pending from "../../assets/images/newImages/pending.svg";
import AmazonStore from "../../assets/images/newImages/amazon-store.svg";

import { marketplaceFlags } from "../../helpers/marketplace-flag";
import { ClearStates } from '../../helpers/clear-store-states';
import { CapitalizeFirstLetter } from '../../helpers/format-value';

import { STORE_STATUS, APP_IDS, REDIRECT_URIS } from '../../constants';

import { StorePage } from "./style";

import {
  DeleteStore,
  GetStores,
  SetStoreState,
} from "../../redux/slices/store-slice";
import { GetUsersName, GetUser } from "../../redux/slices/user-slice";
import { SetAuthState } from "../../redux/slices/auth-slice";

const { Option } = components;
const Tile = (props) => {
  return (
    <Option {...props} value={"default"}>
      <div className="select-option">
        <span className="d-block bold-weight">{props.data.userEmail !== 'All' ? props.data.userEmail : null}</span>
        <span>{props.data.name}</span>
      </div>
    </Option>
  );
}

const Index = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const hostName = window.location.hostname;

  const [filters, setFilters] = useState({
    storeStatus: 'All',
  });
  const [storeBody, setStoreBody] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [usersNameData, setUsersNameData] = useState([]);
  const [isSelected, setIsSelected] = useState(false);
  const [pass, setPass] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [search, setSearch] = useState('');

  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [severity, setSeverity] = useState('');
  const [apiConnected, setApiConnected] = useState(false);

  const {
    stores,
    success,
    total,
    message,
    totalConnected,
    totalPending,
    totalInviteAccepted,
    totalStores,
    error: storeError,
    loading,
    searchedStore
  } = useSelector(
    (state) => state.store
  );
  const { user } = useSelector((state) => state.auth);
  const { usersName, error: userError, user: userData } = useSelector((state) => state.user)

  const storePermissions = ['shipment', 'fbaReports', 'paymentReports', 'appStore', 'manageCases']

  useEffect(() => {
    dispatch(SetAuthState({ field: 'showSidebar', value: true }));
    if (user.role === "admin" || user.role === "agency-manager") {
      dispatch(GetUsersName({ apiFrom: 'store' }));
    }
  }, []);

  useEffect(() => {
    if (success && stores?.length > 0) {
      const storesData = stores.map((store, i) => {
        let region = "N/A";
        if (store.region === "na") {
          region = "North America";
        } else if (store.region === "eu") {
          region = "Europe";
        } else if (store.region === "fe") {
          region = "Far East";
        }

        let statusClassName = 'card-badge disconnected';
        if (store.status === 'Connected') {
          statusClassName = 'card-badge connected';
        }

        return (
          <Col key={i} md={4} sm={6} className="pl-0">
            <div className="store">
              <div className="d-flex justify-content-between">
                <div>
                  <div className="title weight-bold">
                    {store.name || "N/A"}
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  {store.status == "Connected" ? null :
                    <span className="complete fs-14 weight-bold mr-3" onClick={(e) => handlePendingStep(store)}>
                      Incomplete
                    </span>
                  }

                  {/* {user.role == 'user' ? <BsPencilSquare
                    className="color-primary mr-3"
                    onClick={() => {
                      dispatch(SetStoreState({ field: 'storeId', value: store._id }))
                      history.push({
                        pathname: '/setting/edit-store'
                      });

                    }}
                  /> : ""} */}

                  {user.role === 'admin' ? <BsTrash
                    onClick={() => {
                      setSelectedId(store._id), setShowModal(true);
                    }}
                    className="trash"
                  /> : null}
                </div>
              </div>
              <p className="fs-14 text-primary weight-bold mb-12">
                {store.sellerId || ' N/A'}
              </p>
              {user.role === 'admin' || user.role == 'agency-manager' ? (
                <>
                  <div className="d-flex justify-content-between mb-3">
                    <span className='fs-14 text-primary weight-bold'>Seller Central Store: {store.sellerCentralName || 'N/A'}</span>
                  </div>
                </>
              ) : null}
              <div className="d-flex justify-content-between mb-3 ">
                <img src={AmazonStore} alt="no-marketplace" />
                {
                  store.status !== STORE_STATUS.PENDING ? (
                    <Tooltip
                      color='white'
                      placement='top'
                      title={
                        <>
                        <div className='d-flex flex-column'>
                          {storePermissions.map((key) => (
                            <span key={key} style={{ color: store.permissions[key] ? 'green' : 'red' }}>
                              {CapitalizeFirstLetter(key)}
                            </span>
                          ))}
                        </div>
                        </>
                      }
                    >
                      <b>Permissions</b>
                    </Tooltip>
                  ): null
                }
                <span className={statusClassName}>{store.status === STORE_STATUS.CONNECTED ? STORE_STATUS.CONNECTED : store.status === STORE_STATUS.INVITE_ACCEPTED ? STORE_STATUS.INVITE_ACCEPTED : STORE_STATUS.PENDING}</span>
              </div>
              {user.role === 'admin' || user.role == 'agency-manager' ? (
                <>
                <div className="d-flex justify-content-between">
                    <span className='fs-14 text-primary weight-bold'>User Name: {store.userName}</span>
                  </div>
                  <div className="d-flex justify-content-between mb-2">
                    <span className='fs-14 text-primary weight-bold'>Email: {store.userEmail}</span>
                  </div>
                  <div className="d-flex justify-content-between">
                    <span className='fs-14 text-primary weight-bold'>Created At: {moment(store.createdAt).format('DD-MMM-YYYY')}</span>
                  </div>
                  <div className="d-flex justify-content-between mb-3">
                    <span className='fs-14 text-primary weight-bold'>Updated At: {moment(store.updatedAt).format('DD-MMM-YYYY')}</span>
                  </div>
                </>
              ) : null}
              <div className="d-flex justify-content-between flex-nowrap">
                <span className="fs-14 text-primary weight-bold">
                  {region}
                </span>
                <div className="flags d-flex flex-wrap">
                  {store.marketplaces?.map((marketplace, index) => {
                    const { countryCode } = marketplace;
                    const marketplaceFlagsList = marketplaceFlags(store.region);
                    const flag = marketplaceFlagsList.find(
                      (item) => item.name === countryCode
                    );
                    return (
                      <img key={index} src={flag?.country} alt="no-country" className='m-sm-1' />
                    );
                  })}
                </div>
              </div>
            </div>
          </Col>
        );
      });

      setStoreBody(storesData);
    } else setStoreBody([]);
  }, [stores]);

  useEffect(() => {
    if (storeError) {
      setToastMessage(storeError);
      setSeverity('error');
      setToastOpen(true);
      dispatch(SetStoreState({ field: 'error', value: '' }));
      return;
    } else if (userError) {
      setToastMessage(userError);
      setSeverity('error');
      setToastOpen(true);
      dispatch(SetStoreState({ field: 'error', value: '' }));
      return;
    }
  }, [storeError, userError]);

  useEffect(() => {
    if (success && message) {
      Notification({
        type: "success",
        title: 'Store Deletion',
        description: message
      });

      dispatch(SetStoreState({ field: 'success', value: false }));
      dispatch(SetStoreState({ field: 'message', value: null }));
      dispatch(GetUser());
    }
  }, [success]);

  useEffect(() => {
    if (userData) {
      dispatch(SetAuthState({ field: 'user', value: { ...user, ...userData }}));
    }
  }, [userData]);

  const handleToastClose = (reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToastOpen(false);
  };

  const getStores = () => {
    dispatch(GetStores({ filters }));
  };

  const handelSearch = debounce((value) => {
    const filtersData = {
      ...filters,
      searchKeyword: value,
    };

    setFilters(filtersData)
  }, 500);

  const handleDelete = (storeId) => {
    dispatch(DeleteStore({ storeId }));
    setShowModal(false);
    dispatch(GetStores({ filters }));
  };

  const handleChange = (eValue, key) => {
    let value = eValue;
    if (key === 'userId') {
      setPass(true);
      value = eValue?.value;
      setSelectedUser({
        value: eValue?.value || 'All',
        label: eValue?.userEmail || 'All',
        userEmail: eValue?.userEmail || 'All',
        name: eValue?.name || 'All'
      });
      setFilters({
        ...filters,
        [key]: value ? value[0] || 'All': 'All',
      });
    } else {
      setFilters({
        ...filters,
        [key]: value || 'All',
      });
    }
  };

  const handleAddStore = () => {
    ClearStates({ dispatch, fromApp: true });

    history.push({
      pathname: '/auth/sp-api'
    });
  }

  const handlePendingStep = (store) => {
    const {
      _id,
      status: storeStatus,
      region,
      name: storeName,
      spApi
    } = store;

    if (storeStatus !== "Connected") {
      ClearStates({ dispatch, storeId: _id, fromApp: true });
      if (!spApi?.apiConnected) {
        const state = crypto.randomBytes(20).toString("hex");

        dispatch(SetStoreState({ field: 'state', value: state }));

        const url = `https://sellercentral-europe.amazon.com/authorization/select-account?returnTo=/apps/authorize/consent?state=${state}%26application_id=${APP_IDS[hostName]}&redirect_uri=${REDIRECT_URIS[hostName]}`
        window.open(url, "_self");
      } else {
        history.push({
          pathname: '/auth/sp-api'
        });
      }
    }
  }

  useEffect(() => {
    if (!searchedStore) {
      getStores();
    }
  }, [filters]);

  useEffect(() => {
    if (usersName && usersName.length > 0) {
      const usersNamesData = usersName.map(user => ({
        value: [user._id, user.email],
        label: `${user.name}`,
        userEmail: user.email,
        name: user.name
      }));

      const users = [];

      users.push(...usersNamesData);
      setUsersNameData(users);
    }
  }, [usersName]);

  useEffect(() => {
    if (searchedStore?.length) {
      const filtersData = {
        ...filters,
        searchKeyword: searchedStore,
      };

      setSearch(searchedStore);
      dispatch(SetStoreState({ field: 'searchedStore', value: '' }));
      setFilters(filtersData);
    }
  }, [searchedStore]);

  return (
    <StorePage>
      <Toast
        message={toastMessage}
        severity={severity}
        toastOpen={toastOpen}
        handleToastClose={handleToastClose}
      />
      <div className="d-flex justify-content-between align-items-center">
        <h2>Stores</h2>
        <div className="d-flex flex-wrap grid-column-gap-16 align-items-center">
          {
            user.role === 'admin' || user.role === 'agency-manager' ? (
              <ButtonGroup className="store-filter" onClick={(e) => handleChange(e.target.value, 'storeStatus')}>
                <Button
                  text="All"
                  value="All"
                  className={filters.storeStatus === "All" ? "active" : "non-active"}
                />
                <Button
                  text="Connected"
                  value="Connected"
                  className={filters.storeStatus === STORE_STATUS.CONNECTED ? "active" : "non-active"}
                />
                <Button
                  text="Invite Accepted"
                  value="Invite_Accepted"
                  className={filters.storeStatus === STORE_STATUS.INVITE_ACCEPTED ? "active" : "non-active"}
                />
                <Button
                  text="Pending"
                  value="Pending"
                  className={filters.storeStatus === STORE_STATUS.PENDING ? "active" : "non-active"}
                />
              </ButtonGroup> 
            ): null 
          }
          {
            filters.storeStatus === STORE_STATUS.PENDING ? (
              <Checkbox
                label="API Disconnected"
                value="apiConnected"
                onChange={(e) => {
                  setApiConnected(e.target.checked)
                  if (e.target.checked) {
                    setFilters({
                      ...filters,
                      apiConnected: false
                    });
                  } else {
                    setFilters( prev =>{
                      const clone = cloneDeep(prev);
                      delete clone['apiConnected'];

                      return clone;
                    });
                  }
                }}
                checked={apiConnected}
              />
            ): null
          }
          {user.role === 'admin' || user.role === 'agency-manager' ?
            <Select
              styles={{
                option: (base, state) => ({
                  ...base,
                  paddingLeft: "11px",
                  paddingRight: "11px",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                  borderBottom: `1px solid #E3E2E2`,
                  height: "100%",
                  backgroundColor: 'transparnet',
                  color: !isSelected ? '#4E5969' : "#064AAC"
                }),
                menuList: (provided, state) => ({
                  ...provided,
                  padding: 10,
                }),
              }}
              className="store-select"
              isClearable={true}
              options={usersNameData}
              placeholder='Select User'
              handleChange={(e) => handleChange(e, 'userId')}
              components={{ Option: Tile }}
            />
            : null}
          <SeachInput
            value={search}
            placeholder="Search"
            onChange={(e) => {
              setSearch(e.target.value);
              handelSearch(e.target.value);
            }}
          />
          {(user.role === 'user' || user.role === 'affiliate-manager') && (
            <Button text="Add Store" className="outlined mb-0" onClick={handleAddStore} />
          )}
        </div>
      </div>
      {
        user.role === 'admin' || user.role === 'agency-manager' ? (
          <Row className="m-0 pt-4">
            <Col md={3} className="pl-0">
              <div className="p-4 header total d-flex justify-content-between align-items-center">
                <div className="logo">
                  <img src={Total} alt="no-svg" />
                </div>
                <div className="d-flex flex-column align-items-center">
                  <span className="fs-14 text-primary">Total</span>
                  <h1 className="mb-0">{totalStores}</h1>
                </div>
              </div>
            </Col>
            <Col md={3} >
              <div className="p-4 header connected d-flex justify-content-between align-items-center">
                <div className="logo">
                  <img src={Connected} alt="no-svg" />
                </div>
                <div className="d-flex flex-column align-items-center">
                  <span className="fs-14 text-primary">Connected</span>
                  <h1 className="mb-0">{totalConnected}</h1>
                </div>
              </div>
            </Col>
            <Col md={3} className="pr-0">
              <div className="p-4 header pending d-flex justify-content-between align-items-center">
                <div className="logo">
                  <img src={Pending} alt="no-svg" />
                </div>
                <div className="d-flex flex-column align-items-center">
                  <span className="fs-14 text-primary">Invite Accepted</span>
                  <h1 className="mb-0">{totalInviteAccepted}</h1>
                </div>
              </div>
            </Col>
            <Col md={3} className="pr-0">
              <div className="p-4 header pending d-flex justify-content-between align-items-center">
                <div className="logo">
                  <img src={Pending} alt="no-svg" />
                </div>
                <div className="d-flex flex-column align-items-center">
                  <span className="fs-14 text-primary">Pending</span>
                  <h1 className="mb-0">{totalPending}</h1>
                </div>
              </div>
            </Col>
          </Row>
        ): null
      }
      <Row className="mt-4 m-0 add-scroll">
        {<>
          {!loading && !stores.length ? (
            <tr><td>
              <div className='error'>
                <h1>No Data Available</h1>
              </div>
            </td></tr>
          )
            : null
          }
          {loading ? <tr><td><Spin /></td></tr> : storeBody}
        </>
        }
      </Row>

      {showModal ? <Modal
        showModal={showModal}
        modalHeader="Delete Store"
        modalBody="Are you sure you want to delete it ?"
        handleConfirm={() => handleDelete(selectedId)}
        handleClose={() => setShowModal(false)}
        showFooter={true}
      />
        : null
      }
    </StorePage>
  );
};

export default Index;
