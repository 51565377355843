import styled from "styled-components";

const StepThreeWrapper = styled.div`
  height: 100%;
  .amount_Box_overlay {
    border-radius: 8px;
    border: 1px solid var(--Border-Variant, #dadfe3);
    background: var(--White, #fff);
    padding: 16px;
    display: flex;
    grid-gap: 32px;
    justify-content: space-between;
    .amount_Box_item {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      grid-row-gap: 12px;
      h4 {
        color: var(--Secondary, #151a26);
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        margin: 0px;
      }
      .amount_card_overlay {
        display: flex;
        align-items: center;
        grid-gap: 24px;
        flex-wrap: wrap;
        grid-row-gap: 16px;
      }
    }
  }
`;

export {
  StepThreeWrapper
}
