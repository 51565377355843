import React from "react";
import { Modal } from "antd";
import WalletImage from "../../../assets/images/wallet-1.svg";
import ButtonSt from "../../../components/ButtonSt";
import { ModalStyleWrapper } from "./style";

const ModalStepsFour = ({ payment, setPayment, handleNext }) => {
  return (
    <Modal
      footer={null}
      centered
      onOk={() => setPayment(false)}
      closeIcon={false}
      closable={false}
      okText="Proceed"
      width={480}
      open={payment}
      className="processed-payment-modal"
    >
      <ModalStyleWrapper>
        <div className="box-wrapper">
          <img src={WalletImage} />
          <h6>Payment Method Successfully Added!</h6>
          <p>
            Your cases will be filed by your account manager as soon as
            possible. We will contact you via email for further updates.
          </p>
          <ButtonSt onClick={() => {
            setPayment(false);
            handleNext();
          } }>Proceed</ButtonSt>
        </div>
      </ModalStyleWrapper>
    </Modal>
  );
};

export default ModalStepsFour;
