import React from "react";

import { ButtonWrapper } from "./style";

const Index = (props) => {
  const { variant, type, className, text, onClick, disabled, value, style, width, icon, startIcon, name } = props;

  return (
    <ButtonWrapper
      variant={variant}
      type={type}
      className={className}
      onClick={onClick}
      disabled={disabled}
      value={value}
      style={style}
      width={width}
      name={name}
    >
     {startIcon && startIcon} {text} {icon && icon}
    </ButtonWrapper>
  );
};

export default Index;
