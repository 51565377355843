import React from "react";
import { Select } from "antd";
import { SelectStWrapper } from "./style";

const SelectSt = (props) => {
  const { defaultValue, style, onChange, options, ...rest } = props;
  return (
    <SelectStWrapper>
      <Select
        {...rest}
        defaultValue={defaultValue}
        style={style}
        onChange={onChange}
        options={options}
      />
    </SelectStWrapper>
  );
};

export default SelectSt;
