import { isEmpty } from "lodash";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input, Modal } from "antd";

import Customer from "../../../assets/images/customer-icon.svg";
import CustomerSt from "../../../assets/images/icon_chat_seller_repay.svg";
import HistoryIcon from "../../../assets/images/history-icon.svg";
import PhoneIcon from "../../../assets/images/phone-icon.svg";
import InfoIcon from "../../../assets/images/info-icon.svg";
import InfoIconSp from "../../../assets/images/info-icon-2.svg";
import Button from "../../../components/ButtonSt";
import SelectSt from "../../../components/SelectSt";
import MeetingCalender from "../../../components/MeetingCalender";
import Notification from '../../../components/notifications/notification';

import { StepperFooterWrapper } from "./style";

import { APP_DOMAINS, REPORT_ISSUES_LIST, ST_REPORT_ISSUE_SLACK_CHANNEL } from '../../../constants';

import { SendNotificationOnSlack, SetStoreState } from "../../../redux/slices/store-slice";


const { TextArea } = Input;

const StepperFooter = () => {
  const dispatch = useDispatch();

  const { user: { email } } = useSelector((state) => state.auth);
  const { message: successMessage } = useSelector((state) => state.store);

  const host = window.location.hostname;

  const [reportIssue, setReportIssue] = useState();
  const [openCalendar, setOpenCalendar] = useState();
  const [selectedQuery, setSelectedQuery] = useState('');
  const [message, setMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleCancel = () => {
    setReportIssue(false);
    setSelectedQuery('');
    setMessage('');
  }

  const handleChange = (e) => {
    if (isEmpty(e)) {
      setErrorMessage('Select A Query!');
    } else {
      setErrorMessage('');
    }
    setSelectedQuery(e);
  }

  const handleSubmit = () => {
    if (isEmpty(selectedQuery)) {
      setErrorMessage('Select A Query!');
    } else {
      setReportIssue(false);
      const displayFields = [{
        User: email
      }, {
        Query: selectedQuery
      }];

      if (!isEmpty(message)) {
        displayFields.push({
          Message: message
        })
      }

      dispatch(SendNotificationOnSlack({
        url: ST_REPORT_ISSUE_SLACK_CHANNEL,
        displayFields,
        task: 'Report-An-Issue'
      }));

      setSelectedQuery('');
      setMessage('');
      setErrorMessage('');
    }
  };

  useEffect(() => {
    if (successMessage) {
      Notification({
        type: "success",
        title: "Message",
        description: successMessage
      });
      dispatch(SetStoreState({ field:'message', value: ''}));
    }
  }, [successMessage])


  return (
    <StepperFooterWrapper>
      <div className="stepper_side_footer">
        <img src={host === APP_DOMAINS.sellerTerminal ? Customer : CustomerSt } alt="Customer" />
        <div className="heading-wrapper">
          <h5>Having trouble?</h5>
          <p>
            Feel free to contact us and we will help guide you through the
            sign-up process
          </p>
        </div>
        <div className="footer-st-buttons">
          <Button onClick={() => setOpenCalendar(true) }
           outline icon={<img src={host === APP_DOMAINS.sellerTerminal ?  HistoryIcon : PhoneIcon}
          />}>
            Schedule a Call
          </Button>
          <Button
            onClick={() => setReportIssue(true)}
            outline
            icon={<img src={host === APP_DOMAINS.sellerTerminal ? InfoIcon :InfoIconSp} />}

          >
            Report Issue
          </Button>
        </div>
      </div>
      <Modal
        footer={null}
        centered
        onOk={() => setReportIssue(false)}
        closeIcon={false}
        closable={false}
        okText="Proceed"
        width={480}
        open={reportIssue}
        className="processed-payment-modal"
      >
        <StepperFooterWrapper>
          <div className="box-wrapper">
            <h5>Report An Issue</h5>
            <div className="item-wrapper">
              <label>Query</label>
              <SelectSt
                placeholder="Select"
                options={REPORT_ISSUES_LIST}
                reportIssue={reportIssue}
                value={isEmpty(selectedQuery) ? null: selectedQuery}
                onChange={(e) => handleChange(e)}
              />
              <span style={{ color: "red", fontSize: "12px" }}>
                {" "}
                {errorMessage}{" "}
              </span>
            </div>
            <div className="item-wrapper">
              <label>Message</label>
              <TextArea 
                rows={4}
                placeholder="Enter"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                 />
            </div>
            <div className="button-footer-ui">
              <Button outline onClick={() => handleCancel()}>
                Cancel
              </Button>
              <Button onClick={handleSubmit}>Submit</Button>
            </div>
          </div>
        </StepperFooterWrapper>
      </Modal>
      <Modal
      footer={null}
      centered
      onCancel={() => setOpenCalendar(false)} 
      cancelText="Close"
      maskClosable={true}
      okText="Proceed"
      width="100%"
      height={900}
      visible={openCalendar}
      className="calendar-ui-added"
    >
      <MeetingCalender />
    </Modal>
    </StepperFooterWrapper>
  );
};

export default StepperFooter;
