import moment from "moment";

import React, { useState } from 'react';
import { GoCalendar } from "react-icons/go";

import Button from "../Button";

import { DateRangePicker } from "react-date-range";

import "react-date-range/dist/theme/default.css";
import "react-date-range/dist/styles.css";
import { DatePickerWrapper } from './style'

const DateRangerPicker = ({
  date,
  startDate,
  endDate,
  pageName,
  onApply,
  onChange
}) => {
  const [showDate, setShowDate] = useState(false);

  return (
  <React.Fragment>
    <DatePickerWrapper>
      <button id="dateRangeButton" variant="outlined" onClick={() => setShowDate(!showDate)}>
        <span style={{ fontSize: '10px', color: '#3C4356' }}>
          {moment({ day: startDate.startDay, month: startDate.startMonth - 1, year: startDate.startYear }).format('DD/MM/YYYY')}-{
            moment({ day: endDate.endDay, month: endDate.endMonth - 1, year: endDate.endYear }).format('DD/MM/YYYY')
          }
        </span>
        <GoCalendar size={20} onClick={() => setShowDate(!showDate)} />
      </button>

      {showDate ?
        <div className="calender-input postion-relative">
          <div className="date-picker" style={ pageName === 'dashboard' ? { top: '120px' } : { top: '45px' } } >
            <DateRangePicker
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              onChange={item => onChange(item)}
              months={2}
              ranges={date}
              direction="horizontal"
            />
            <div>
            <Button style={{ float: 'right', fontSize: '13px', marginRight: '5px' }} className='outlined' variant="outlined" onClick={() => setShowDate(!showDate)} text='Cancel' />
            <Button style={{ float: 'right', fontSize: '13px', marginRight: '10px' }} className='outlined' variant="contained" onClick={() => {onApply(); setShowDate(!showDate); }} text='Apply' />
           </div>
          </div>
        </div>
        : null
      }

    </DatePickerWrapper>
  </React.Fragment>
  )
}

export default DateRangerPicker;
