import Styled from 'styled-components';

const CheckBoxWrapper = Styled.div`
.form-group{
  margin-bottom:0px;
  display:flex;
  align-items:center;
}
  input{
    margin-top: 4px;
    width: 20px;
    height: 16px;
    &:focus{
      box-shadow:none
    }
  }
  .form-check-label{
    font-size:${({ theme }) => theme['medium-font-size']};
    color:${({ theme }) => theme['nevada-color']};
    padding-left:8px;
 
  }
  `;
export { CheckBoxWrapper };
