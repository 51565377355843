import React, { useState } from 'react';
import { Popover } from 'antd';

import Input from '../../components/Inputs/label-input';
import Button from '../../componentsv2/Button';
import searchIconSt from '../../assets/icons/search-primary-icon.svg';
import searchIconSp from '../../assets/icons/search-primary-icon-sp.svg';
import SortIconSt from '../../assets/icons/sort-arrow-up.svg';
import SortIconSp from '../../assets/icons/sort-arrow-up-sp.svg';
import DownIconSt from '../../assets/icons/sort-arrow-down.svg';
import DownIconSp from '../../assets/icons/sort-arrow-down-sp.svg';
import TopArrow from '../../assets/icons/top-arrow.svg';
import DownArrow from '../../assets/icons/down-arrow.svg';

import { APP_DOMAINS } from '../../constants';

const SearchFilter = ({
  title,
  showArrow,
  filterName,
  handleChange,
  handleSorting,
  sorting
}) => {
  const [openFilter, setOpenFilter] = useState(false);
  const [event, setEvent] = useState({});
  const [err, setErr] = useState('');

  return (
    <>
      <div className='d-flex align-items-center'>
        <span>{title}</span>
        <div className="d-flex flex-column mx-2">
          <div 
            onClick={() => {
              let value = null;
              if (sorting[filterName] === -1 || !sorting[filterName]) {
                value = 1;
              } else {
                value = -1
              }

              handleSorting(filterName, value)
            }} 
            className='lineheight-normal'
          >
            <img src={sorting[filterName] === 1 ? TopArrow : sorting[filterName] === -1 ? DownArrow : window.location.hostname === APP_DOMAINS.sellerTerminal ? SortIconSt : SortIconSp} className="pointer" alt='arrow' />
          </div>
          {
            !sorting[filterName] ? (
              <div onClick={() => handleSorting(filterName, -1)} className='lineheight-normal'>
                <img src={window.location.hostname === APP_DOMAINS.sellerTerminal ? DownIconSt : DownIconSp } className="pointer" alt='arrow' />
              </div>
            ) : null
          }
        </div>
        <div onClick={() => setOpenFilter(true)}>
          <img src={window.location.hostname === APP_DOMAINS.sellerTerminal ? searchIconSt : searchIconSp} alt='filter' />
        </div>
      </div>
      <Popover
        content={(
          <div className='filter-popover'>
            <Input
              placeholder={`Enter ${title}`}
              onChange={(e) => {
                setEvent((prev) => ({ ...prev, value: e.target.value }));
                if (err) {
                  setErr('');
                }
              }}
            />
            <Button
              onClick={() => {
                handleChange(filterName, event);
                setOpenFilter(false);
              }}
              text='Apply'
              width="100%"
              className="primary"
            />
          </div>
        )}
        title={`Search By ${title}`}
        placement="bottom"
        trigger="click"
        open={openFilter}
        onOpenChange={() => setOpenFilter(false)}
      />
    </>
  )
}

export default SearchFilter;
