import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { axiosBaseUrl } from '../../config/axios-configuration';

import SaveSentryLog from '../../helpers/sentry-log';

const axios = axiosBaseUrl();

export const GetFulfillmentFee = createAsyncThunk(
  'fulfillmentFee/getFulfillmentFee',
  async (data, { rejectWithValue }) => {
    try {
      const {
        filters,
        limit,
        skip
      } = data;

      const response = await axios.get('/fulfillment-fee/get-fulfillment-fee', {
        params: {
          filters,
          limit,
          skip
        }
      });

      return response.data;
    } catch (err) {
      SaveSentryLog(new Error(err), 'GetFulfillmentFeeError');
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const MakeAffectedOrdersFile = createAsyncThunk(
  'fulfillmentFees/makeAffectedOrdersFile',
  async (data, thunkAPI) => {
    try {
      const {
        storeId,        
        sellerSku,        
      } = data;

      const params = {
        storeId,       
        sellerSku,       
      };
      const getQueryString = (p) => {
        const esc = encodeURIComponent;
        return Object.keys(p)
          .map(k => `${esc(k)}=${esc(params[k])}`)
          .join('&');
      };
      console.log('getQueryString(params)', getQueryString(params));
      const url = (process.env.NODE_ENV === 'development')
        ? `http://localhost:4000/v1/fulfillment-fee/make-affected-orders-file?${getQueryString(params)}`
        : `https://api.sellerterminal.com/v1/fulfillment-fee/make-affected-orders-file?${getQueryString(params)}`;
      setTimeout(() => {
        window.open(url, '_blank');
      }, 0);
    } catch (err) {
      SaveSentryLog(new Error(err), 'MakeAffectedOrdersFileError');
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return thunkAPI.rejectWithValue({
        err: {
          error: 'Network Error'
        }
      });
    }
  }
);

export const ClaimFulfillmentFee = createAsyncThunk(
  'fulfillmentFee/claimFulfillmentFee',
  async (data, { rejectWithValue }) => {
    try {
      const {
        storeId,
        fulfillmentFeesCalculatorId,
        caseId
      } = data;

      const response = await axios.post('/fulfillment-fee/claim-fulfillment-fee', {
        storeId,
        fulfillmentFeesCalculatorId,
        caseId
      });

      return response.data;
    } catch (err) {
      SaveSentryLog(new Error(err), 'ClaimFulfillmentFeeError');
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const SaveTrueDimensions = createAsyncThunk(
  'fulfillmentFee/saveTrueDimensions',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post('/fulfillment-fee//save-true-dimensions', data);

      return response.data;
    } catch (err) {
      SaveSentryLog(new Error(err), 'SaveTrueDimensionsError');
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const GetFulfillmentFeeForUser = createAsyncThunk(
  'fulfillmentFee/getFulfillmentFeeForUser',
  async (data, { rejectWithValue }) => {
    try {
      const {
        filters,
        limit,
        skip
      } = data;

      const response = await axios.get('/fulfillment-fee/get-fulfillment-fee-for-user', {
        params: {
          filters,
          limit,
          skip
        }
      });

      return response.data;
    } catch (err) {
      SaveSentryLog(new Error(err), 'GetFulfillmentFeeForUserError');
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

const fulfillmentFee = createSlice({
  name: 'fulfillmentFee',
  initialState: {
    error: '',
    loading: '',
    message: '',
    success: false,
    total: 0,
    fulfillmentFeesData: [],
    totalFulfillmentFee: 0,
    fileUploaded: false
  },
  reducers: {
    SetFulfillmentFeeState(state, {payload: {field, value} }) {
      state[field] = value;
    }
  },
  extraReducers: {
    [GetFulfillmentFee.pending]: (state, action) => ({
      ...state,
      loading: true
    }),
    [GetFulfillmentFee.fulfilled]: (state, action) => ({
      ...state,
      success: true,
      loading: false,
      fulfillmentFeesData: action.payload.fulfillmentFeesData,
      totalFulfillmentFee: action.payload.total
    }),
    [GetFulfillmentFee.rejected]: (state, action) => ({
      ...state,
      fulfillmentFeesData: [],
      totalFulfillmentFee: 0,
      success: false,
      loading: false,
      error: action.payload.err.message
    }),
    [MakeAffectedOrdersFile.pending]: (state, action) => ({
      ...state,
      loading: true
    }),
    [MakeAffectedOrdersFile.fulfilled]: (state, action) => ({
      ...state,
      success: true,
      loading: false  
    }),
    [MakeAffectedOrdersFile.rejected]: (state, action) => ({
      ...state,     
      success: false,
      loading: false,
      error: action.payload.err.message
    }),
    [ClaimFulfillmentFee.pending]: (state, action) => ({
      ...state,
      loading: true
    }),
    [ClaimFulfillmentFee.fulfilled]: (state, action) => ({
      ...state,
      success: true,
      loading: false  
    }),
    [ClaimFulfillmentFee.rejected]: (state, action) => ({
      ...state,     
      success: false,
      loading: false,
      error: action.payload.err.message
    }),
    [SaveTrueDimensions.pending]: (state) => ({
      ...state,
      loading: true,
      fileUploaded: false
    }),
    [SaveTrueDimensions.fulfilled]: (state) => ({
      ...state,
      fileUploaded: true,
      loading: false  
    }),
    [SaveTrueDimensions.rejected]: (state, action) => ({
      ...state,     
      fileUploaded: false,
      loading: false,
      error: action.payload.err.message
    }),
    [GetFulfillmentFeeForUser.pending]: (state, action) => ({
      ...state,
      loading: true
    }),
    [GetFulfillmentFeeForUser.fulfilled]: (state, action) => ({
      ...state,
      success: true,
      loading: false,
      fulfillmentFeesData: action.payload.products,
      totalFulfillmentFee: action.payload.total
    }),
    [GetFulfillmentFee.rejected]: (state, action) => ({
      ...state,
      fulfillmentFeesData: [],
      totalFulfillmentFee: 0,
      success: false,
      loading: false,
      error: action.payload.err.message
    })
  }
});

const { reducer, actions } = fulfillmentFee;

export const { SetFulfillmentFeeState } = actions;

export default reducer;
