import styled from "styled-components";

const StepperStyeWrapper = styled.div`
  padding-top: 62px;

  .stepper_wrapper {
    display: flex;
    .steps_overlay_wrapper {
      min-height: calc(100vh - 62px);
      border: 1px solid #dadfe3;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border-top: 0px;
    }
    .ant-steps-vertical {
      display: flex;
      flex-direction: column;
      width: 341px;
      border-left: none;
      border-top: none;
      padding: 22px 16px 0 34px;
      grid-row-gap: 8px;
    }
    .ant-steps-item {
      position: relative;
      display: inline-block;
      flex: none;
      overflow: hidden;
      vertical-align: top;
    }
  }
  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-tail::after {
    background-color: #1890ff;
  }
  .ant-steps-vertical
    > .ant-steps-item
    > .ant-steps-item-container
    > .ant-steps-item-tail::after {
    width: 1px;
    height: 100%;
  }
  .ant-steps-vertical.ant-steps-small
    .ant-steps-item-container
    .ant-steps-item-tail {
    position: absolute;
    /* top: -7px; */
    left: 11px;
    padding: 30px 0 6px;
    z-index: -1;
  }
  /* .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background: #1890ff;
    width: 26px;
    height: 24px;
} */
  .ant-steps-item-finish .ant-steps-item-icon {
    background-color: #52c41a;
    border-color: #52c41a;
    svg {
      color: #fff;
    }
  }
  .ant-steps-item-title h3 {
    color: var(--Body-Text-Varaint, #98a1a5);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    margin-bottom: 0px;
    padding-top: 4px;
  }
  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-tail::after {
    background-color: #52c41a;
  }
  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title
    h3 {
    color: var(--Selected_Tab, #1890ff);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
  }
  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title
    p {
    color: #4e5969;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    padding-top: 8px;
    margin-bottom: 0px;
  }
  .ant-steps-item-wait
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title
    p {
    color: var(--Body-Text-Varaint, #98a1a5);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    margin-top: 8px;
    margin-bottom: 0px;
  }
  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title
    h3 {
    color: #4e5969;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    padding: 0px;
    padding-top: 4px;
  }
  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title
    p {
    color: var(--Body-Text-Varaint, #98a1a5);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    margin-top: 8px;
    margin-bottom: 0px;

  }
  .ant-steps-item-container {
    outline: none;
    display: flex;
    max-width: 331px;
  }
  .ant-steps-vertical > .ant-steps-item .ant-steps-item-icon {
    float: left;
    margin-right: 8px;
  }
  .ant-steps-item-wait .ant-steps-item-icon {
    background-color: #fff;
    border-radius: 32px;
    border: 1px solid var(--Disable, #a6afb1);
  }
  span.ant-steps-icon {
    color: var(--White, #fff);
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }
  .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
    color: var(--Disable, #a6afb1);
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }
  .stepper_side_footer {
    padding-left: 35px;
    max-width: 329px;
    .footer-st-buttons {
      button {
        display: flex;
        grid-gap: 4px;
        &:hover,
        :focus {
          background-color: ${(props) => props.theme["bg-white"]};
          border: 1px solid ${(props) => props.theme["new-primary"]};
          color:${(props) => props.theme["new-primary"]};
        }
      }
    }
  }
  .ant-steps-vertical
    > .ant-steps-item
    > .ant-steps-item-container
    > .ant-steps-item-tail::after {
    width: 1px;
    height: calc(100% + 10px);
  }
  .steps-content {
    padding: 24px 35px 24px 24px;
    width: 100%;
    background: var(--bg, #f9fafb);
    height: calc(100vh - 62px);
    overflow: auto;
    position:relative;
  }
  .loader-wrapper {
    width: 100%;
    right: 0;
    height: calc(100vh - 62px);
    background: #ffffffd9;
    top: 0;
    z-index:2;
  }
`;
const StepperFooterWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;
span.anticon.anticon-down.ant-select-suffix {
    margin-top: 16px;
}
.ant-select.ant-select-single.ant-select-show-arrow{
  border: none !important;
  background-color: transparent !important;
}
textarea.ant-input {
    border-radius: 4px;
    border: 1px solid var(--Placeholder-Border, #DBDFE1);
    background: var(--White, #FFF);
    color: var(--text-field-body-color, #5E718D);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}
  .stepper_side_footer {
    padding-left: 35px;
    max-width: 329px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    grid-row-gap: 16px;
    padding-bottom: 24px;
     span.ant-select-arrow {
    top: 16px !important;
}
    .heading-wrapper {
      display: flex;
      flex-direction: column;
      grid-row-gap: 8px;
      h5 {
        color: var(--Secondary, #151a26);
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 18px;
        margin: 0px;
      }
      p {
        color: var(--text-field-body-color, #5e718d);
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        margin: 0px;
      }
    }
    .footer-st-buttons {
      display: flex;
      align-items: center;
      grid-gap: 8px;
      button {
        display: flex;
        grid-gap: 4px;
        padding: 8px 16px;
        color:${(props) => props.theme["new-primary"]};
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
        &:hover{
          background: ${(props) => props.theme["bg-white"]} !important;
          border: 1px solid ${(props) => props.theme["new-primary"]} !important;
          color:${(props) => props.theme["new-primary"]} !important;
        }
        &:focus {
          background: ${(props) => props.theme["bg-white"]} !important;
          border: 1px solid ${(props) => props.theme["new-primary"]} !important;
          color:${(props) => props.theme["new-primary"]} !important;
        }
      }
    }
  }
  .item-wrapper {
    margin-top: 24px;
    label {
      color: var(--Label, #8e97a4);
      font-family: Lato;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      margin: 0;
    }
  }
  .button-footer-ui {
    display: flex;
    justify-content: end;
    align-items: center;
    grid-gap: 16px;
    margin-top: 24px;
}
`;
export { StepperStyeWrapper, StepperFooterWrapper };
