import { isEmpty, sortBy } from "lodash";

import React, { useEffect, useState } from "react";
import { Input, Spin } from "antd";
import { useSelector, useDispatch } from "react-redux";

import CommissionCard from "../../../components/CommissionCard";
import Notification from "../../../components/notifications/notification";
import LockImage from "../../../assets/images/lock-padlock.svg";
import Select from "../../../components/SelectSt";
import ButtonSt from "../../../components/ButtonSt";
import ModalStepsFour from "./modalStepsFour";
import PaymentLaterModal from "./paymentLaterModal";

import { StepFourWrapper } from "./style";
import {
  useStripe,
  useElements,
  Elements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { COUNTRIES, PUBLIC_KEYS } from "../../../constants";

import { AddPaymentDetails, GetCountries, SetState } from "../../../redux/slices/payment-slice";
import { SetAuthState } from "../../../redux/slices/auth-slice";
import { UpdateStore } from "../../../redux/slices/store-slice";

const hostName = window.location.hostname;

const publishablekey = PUBLIC_KEYS[hostName];

const stripePromise = loadStripe(publishablekey);

const StepFour = ({ handleNext }) => {
  const dispatch = useDispatch();
  const elements = useElements();
  const stripe = useStripe();

  const { countries, success, loading, payment: userPayment, err } = useSelector((state) => state.payment);
  const { user, storeId } = useSelector((state) => state.auth);

  const [payment, setPayment] = useState(false);
  const [paymentLater, setPaymentLater] = useState(false);
  const [allCountries, setAllCountries] = useState([]);

  const [addressData, setAddressData] = useState({
    selectedCountry: '',
    selectedCity: '',
    streetAddress: '',
    postalCode: ''
  });

  const [cardValidationError, setCardValidationError] = useState({
    cvcError: "",
    dateError: "",
    numberError: "",
    cvcTouched: false,
    dateTouched: false,
    numberTouched: false,
  });

  const [addressError, setAddressError] = useState({
    countryError: '',
    cityError: '',
    streetAddressError: '',
    postalCodeError: ''
  });

  const CardNumberOptions = {
    showIcon: true,
    placeholder: "Card Number",
    style: {
      base: {
        color: "#5E718D",
        fontWeight: 400,
        fontSize: "12px",
        fontSmoothing: "antialiased",
        "::placeholder": {
          color: "#5E718D",
        },
      },
      invalid: {
        iconColor: "#ffc7ee",
        color: "#ffc7ee",
      },
    },
  };

  const Stripe_Options = {
    style: {
      base: {
        color: "#5E718D",
        fontWeight: 400,
        fontSize: "12px",
        fontSmoothing: "antialiased",
        "::placeholder": {
          color: "#5E718D",
        },
      },
      invalid: {
        iconColor: "#5E718D",
        color: "#5E718D",
      },
    },
  };

  const handleCardValidationError = (e) => {
    const { elementType, error, complete, empty } = e;

    let cvcTouched = false;
    let dateTouched = false;
    let numberTouched = false;

    let cvcError = "";
    let dateError = "";
    let numberError = "";

    if (elementType === "cardNumber") {
      if (!isEmpty(error)) {
        numberError = error.message;
        numberTouched = true;
      } else if (complete) numberTouched = true;
      else if (empty) numberTouched = false;
      setCardValidationError({
        ...cardValidationError,
        numberError: numberError,
        numberTouched,
      });
    } else if (elementType === "cardCvc") {
      if (!isEmpty(error)) {
        dateError = error.message;
        cvcTouched = true;
      } else if (complete) cvcTouched = true;
      else if (empty) cvcTouched = false;
      setCardValidationError({
        ...cardValidationError,
        cvcError: dateError,
        cvcTouched,
      });
    } else if (elementType === "cardExpiry") {
      if (!isEmpty(error)) {
        cvcError = error.message;
        dateTouched = true;
      } else if (complete) dateTouched = true;
      else if (empty) dateTouched = false;
      setCardValidationError({
        ...cardValidationError,
        dateError: cvcError,
        dateTouched,
      });
    }
  };

  const clearPaymentStates = () => {
    setAddressData({
      selectedCountry: '',
      selectedCity: '',
      streetAddress: '',
      postalCode: ''
    });
    elements.getElement(CardNumberElement).clear();
    elements.getElement(CardExpiryElement).clear();
    elements.getElement(CardCvcElement).clear();
  };

  const handleAddressError = (key, value) => {
    setAddressData({
      ...addressData,
      [key]: value
    });

    let errorObj = addressError;
  
    if (key === 'streetAddress') {
      if (!value) {
        errorObj = {
          ...errorObj,
          streetAddressError: 'Street Address cannot be Empty!'
        };
      } else {
        errorObj = {
          ...errorObj,
          streetAddressError: ''
        };
      }
    } else if (key === 'selectedCountry') {
      if (!value) {
        errorObj = {
          ...errorObj,
          countryError: 'Country cannot be Empty!'
        };
      } else {
        errorObj = {
          ...errorObj,
          countryError: ''
        };
      }
    } else if (key === 'selectedCity') {
      if (!value) {
        errorObj = {
          ...errorObj,
          cityError: 'City cannot be Empty!'
        };
      } else {
        errorObj = {
          ...errorObj,
          cityError: ''
        };
      }
    } else if (key === 'postalCode') {
      if (!value) {
        errorObj = {
          ...errorObj,
          postalCodeError: 'Postal Code cannot be Empty!'
        };
      } else {
        errorObj = {
          ...errorObj,
          postalCodeError: ''
        };
      }
    }
    setAddressError(errorObj);
  };

  const savePayment = async () => {
    if (!stripe || !elements) {
      return;
    }
    const {
      selectedCountry,
      selectedCity,
      streetAddress,
      postalCode
    } = addressData;

    let errorObj = addressError;

    if (!selectedCountry) {
      errorObj = {
        ...errorObj,
        countryError: 'Country cannot be Empty!'
      };
    }
    if (!selectedCity) {
      errorObj = {
        ...errorObj,
        cityError: 'City cannot be Empty!'
      };
    }
      if (!streetAddress) {
        errorObj = {
          ...errorObj,
          streetAddressError: 'Street Address cannot be Empty!'
        };
      }
      if (!postalCode) {
        errorObj = {
          ...errorObj,
          postalCodeError: 'Postal Code cannot be Empty!'
        };
      }
    setAddressError(errorObj);
    if (selectedCountry
        && selectedCity
          && streetAddress
            && postalCode) {
      const card = elements.getElement(CardNumberElement);
      let token;

      try {
        const { token: stripeToken } = await stripe.createToken(card);
        token = stripeToken;
      } catch (error) {
        console.log('error in creating token');
        console.log({ error })
      }
      if (token) {
        dispatch(AddPaymentDetails({
          country: selectedCountry,
          address: streetAddress,
          city: selectedCity,
          postalCode,
          token
        }));
      }
    }

  };

  useEffect(() => {
      let countriesData = COUNTRIES?.map((country, index) => {
        return {
          value: country.name,
          label: country.name,
        };
      });
      countriesData = sortBy(countriesData, 'label')
      setAllCountries(countriesData);
  }, []);

  useEffect(() => {
    if (success) {
      dispatch(SetAuthState({ field: 'user','value': {
        ...user,
        payment: userPayment
      }}))
      dispatch(UpdateStore({ storeId, updateParams: { payLater: true } }));
      dispatch(SetAuthState({ field: 'payLater', value: true }));

      setPayment(true);
    }
  }, [success]);

  useEffect(() => {
    if (err) {
      Notification({
        type: "error",
        title: "Error",
        description: err
      });
      dispatch(SetState({ field: 'err', value: '' }))
    }
  }, [err]);

  return (
    <StepFourWrapper>
      <h6 className="primary">Add Payment Method</h6>
      <div className="page-overlay-wrapper">
        <CommissionCard />
        <div className="form-ui-wrapper-st">
          <h3>Enter Card Details</h3>
          {loading &&
            <div className="d-flex position-absolute justify-content-center align-items-center loader-wrapper">
              <Spin size="large" />
            </div>
          }
          <div className="strip-st-ui-wrapper">
            <div className="card-input">
              <label className="card-label-wrapper">
                Credit card information{" "}
                <span>
                  <img src={LockImage} />
                  Secured by Stripe
                </span>
              </label>
              <CardNumberElement
                className="card-number-stripe"
                onChange={(e) => handleCardValidationError(e)}
                options={CardNumberOptions}
                placeholder="Card Number"
                autoFill={true}
              />
              <span style={{ color: "red", fontSize: "12px" }}>
                {" "}
                {cardValidationError.numberError}{" "}
              </span>
            </div>
            <div className="input-with-icon input-icon-left">
              <CardExpiryElement
                className="card-number-stripe"
                onChange={(e) => handleCardValidationError(e)}
                options={Stripe_Options}
                autoFill={true}
              />
              <span style={{
                color: "red",
                fontSize: "12px"
              }}>
                {" "}
                {cardValidationError.dateError}{" "}
              </span>
            </div>
            <div className="input-with-icon input-icon-left input-cvc">
              <CardCvcElement
                options={Stripe_Options}
                onChange={(e) => handleCardValidationError(e)}
                autoFocus={true}
                autoFill={true}
              />
              <span style={{ color: "red", fontSize: "12px" }}>
                {" "}
                {cardValidationError.cvcError}{" "}
              </span>
            </div>
          </div>
          <div className="form-wrapper">
            <div className="item-wrapper">
              <label>Card Location</label>
              <Select
                placeholder="Select a country"
                defaultValue="Select a country"
                options={allCountries}
                value={addressData.selectedCountry || null}
                onChange={(e) => handleAddressError('selectedCountry', e)}
              />
              <span style={{ color: "red", fontSize: "12px" }}>
                {" "}
                {addressError.countryError}{" "}
              </span>
            </div>
            <div className="item-wrapper">
              <p>Street Address</p>
              <Input placeholder="Enter your street address"
                value={addressData.streetAddress}
                onChange={(e) => handleAddressError('streetAddress', e.target.value)}
              />
              <span style={{ color: "red", fontSize: "12px" }}>
                {" "}
                {addressError.streetAddressError}{" "}
              </span>
            </div>
            <div className="item-overlay-wrapper">
              <div className="item-wrapper">
                <p>City</p>
                <Input
                  placeholder="City"
                  value={addressData.selectedCity}
                  onChange={(e) => handleAddressError('selectedCity', e.target.value)}
                />
                <span style={{ color: "red", fontSize: "12px" }}>
                  {" "}
                  {addressError.cityError}{" "}
                </span>
              </div>
              <div className="item-wrapper">
                <p>Postal Code</p>
                <Input
                  placeholder="000000"
                  value={addressData.postalCode}
                  onChange={(e) => handleAddressError('postalCode', e.target.value)}
                />
                <span style={{ color: "red", fontSize: "12px" }}>
                  {" "}
                  {addressError.postalCodeError}{" "}
                </span>
              </div>
            </div>
            <div className="buttons-wrapper">
              <ButtonSt onClick={() => setPaymentLater(true)} outline>Add Payment Later</ButtonSt>
              <ButtonSt onClick={savePayment}>Save Payment method</ButtonSt>
            </div>
            <ModalStepsFour payment={payment} setPayment={setPayment} handleNext={handleNext} />
            <PaymentLaterModal paymentLater={paymentLater} setPaymentLater={setPaymentLater} handleNext={handleNext} clearPaymentStates={clearPaymentStates} />
          </div>
        </div>
      </div>
      </StepFourWrapper>
    )
};

const Form = ({ handleNext }) => {
  return (
    <Elements stripe={stripePromise}>
      <StepFour handleNext={handleNext} />
    </Elements>
  )
}

export default Form;


