import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown } from "react-bootstrap";
import { BiChevronDown } from "react-icons/bi";
import { FiLogOut } from "react-icons/fi";
import { AiOutlineMenu } from "react-icons/ai";
import { BsPersonCircle } from "react-icons/bs";
import PowerIcon from "../../assets/images/newImages/power-icon.svg";
import { LogOut } from "../../redux/slices/auth-slice";
import { Link } from "react-router-dom";

import { APP_LOGOS, APP_TITLES } from "../../constants";

// import SellerRepayLogo from '../../assets/img/logo/app.sellerrepay.png';
// import SellerRepayLogo from "../../assets/img/logo/seller-repay.png";
import SellerRepayLogo from '../../assets/images/logo-main.svg';

import { HeaderWrapper } from "./style";

const Header = (props) => {
  const {
    onClick,
    auth,
    congratulations,
    sidebar
  } = props;

  const dispatch = useDispatch();

  const { user, token } = useSelector((state) => state.auth);

  const [nameAbbreviation, setNameAbbreviation] = useState();
  const [logo, setLogo] = useState(false);

  useEffect(() => {
    let splitedName = user?.name?.toUpperCase()?.split(" ") || [];
    splitedName = splitedName?.map((s) => s[0])?.join("");
    setNameAbbreviation(splitedName);
  }, []);
  const hostName = window.location.hostname;
  const handleLogOut = () => {
    dispatch(LogOut());
  };
  return (
    <HeaderWrapper
      className={`${window.location.hostname.split(".").join("")}`}
    >
      <div className={'congratulation-screen-wrapper'}>
      <div className="header">
        {!sidebar ?
        <div
          className="logout grid-column-gap-10 d-flex  justify-content-between align-items-center"
        >
          <div className='logo-onboarding onboarding-space-left'>
            {APP_LOGOS[hostName] ?
              <img className={"logo-congratulation-sc"} src={hostName === 'app.sellerrepay.com' ? SellerRepayLogo : APP_LOGOS[hostName]} alt="no-logo" />
                :
                <h2>{APP_TITLES[hostName]}</h2>
            }
          </div>
          <span className="cursor-pointer" onClick={handleLogOut}><FiLogOut /> Logout</span>
        </div> : null
        }
        
        {/* <div className="logo-box d-flex">
              {!auth ?  <AiOutlineMenu onClick={onClick} /> : null }     
              {
                APP_LOGOS[window.location.hostname]
                 ?
                  <Link to={token ? '/' : '/auth/sign-in'} > <img  src={APP_LOGOS[window.location.hostname]|| ''} width="120px" height="45px" alt="Ecomm Refund" /> </Link>
                : <h2 className="mb-0 color-primary">Ecomm Refund</h2>
              }
            </div> */}
        {/* {!auth ? ( <div className="logn-session"> */}
        {/* <Dropdown className="switch-user">
                  <Dropdown.Toggle>
                    <div className="switch-user align-items-center">

                     <BsPersonCircle />
                      <div className="user-info d-flex flex-column align-items-start grid-row-gap-4">
                        <span className="userName">Impersonated By</span>
                        <span className="email fs-10">
                        oliveroilbox.master@outlook.com
                        </span>
                      </div>
                      <img src={PowerIcon} alt="no-user" />
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <div className="userSwitchinfo">
                      <div className="d-flex align-items-center">
                        <div className="d-flex align-items-center grid-column-gap-16">
                          <BsPersonCircle className="user-icon" />
                          <span className="username weight-bold">steven r</span>
                        </div>
                      </div>
                      <div className="login-info d-flex flex-column">
                        <span className="email">steven@wraithco.com</span>
                        <span className="role color-primary fs-14 ">Warehouse</span>
                        <span className="role color-primary fs-14">SalesSupport</span>
                      </div>
                    </div>
                  </Dropdown.Menu>
                </Dropdown> */}
        {/* <div className="white-divider" />
              <Dropdown className="avatar">
                <Dropdown.Toggle> 
                    <span className="fs-12 weight-bold">{nameAbbreviation}</span>
                  <span className="arrow-box">
                    <BiChevronDown />
                  </span>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <div className="user-dropdown">
                    <div className="d-flex grid-column-gap-10 marginy-8">
                      <div>
                        <span className="icon-user fs-16"></span>
                      </div>
                      <div className="d-flex flex-column grid-column-2">
                        <span className="username fs-14"></span>
                        <span className="email"></span>
                        <span className="role color-secondary fs-14"></span>
                        <span className="role color-secondary fs-14"></span>
                      </div>
                    </div>
                    <hr />
                    <div className="logout grid-column-gap-10 d-flex cursor-pointer" onClick= {handleLogOut}>
                      <div>
                        <FiLogOut className="color-primary" />
                      </div>
                      <span>Logout</span>
                    </div>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>) : null} */}
      </div>
      </div>
    </HeaderWrapper>
  );
};

export default Header;
