// MyTable.js
import React from "react";
import { Table } from "antd";
import { TableWrapper } from "./style";

const TableSt = ({ dataSource, columns, pagination, ...tableProps }) => {
  return (
    <TableWrapper>
      <Table
        columns={columns}
        pagination={pagination}
        dataSource={dataSource}
        {...tableProps}
      />
    </TableWrapper>
  );
};

export default TableSt;
