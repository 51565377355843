import { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Row, Col, Spinner } from 'react-bootstrap';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { startCase } from 'lodash';
import { BiLeftArrowAlt } from "react-icons/bi";
import { useDispatch, useSelector } from 'react-redux';

import Header from '../Header/header';
import Checkbox from '../../components/CheckBox';
import Button from '../../components/Button';
import SearchInput from '../../components/Inputs/label-input/index';
import Toast from '../../components/Toast';

import MapGrey from '../../assets/images/newImages/map_gray.svg';
import MapEuorpe from '../../assets/images/newImages/map_europe.svg';
import MapEast from '../../assets/images/newImages/map_east.svg';
import MapNorth from '../../assets/images/newImages/map_north.svg';

import { MARKETPLACE_FLAG } from '../../helpers/marketplace-flag';

import { StoreWrapper } from './style';

import { UpdateStoreData, SetStoreState, GetSPAPIToken, GetActiveMarketplaces } from '../../redux/slices/store-slice';
import { SetAuthState } from '../../redux/slices/auth-slice';

const SelectMarketplaces = () => {
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const dispatch = useDispatch();

  const { amazonStoreInfo, storeId, message, error, storeSuccess, loading, activeMarketplaces } = useSelector((state) => state.store || {});
  const { storesCount, showSidebar } = useSelector((state) => state.auth || {});
  const { region, storeName = '', state } = amazonStoreInfo;

  const [marketplace, setMarketplace] = useState([]);
  const [marketplacesList, setMarketplaceList] = useState([]);
  const [localStateStoreName, setLocalStateStoreName] = useState('');
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [severity, setSeverity] = useState('');
  const [mapLocation, setMapLocation] = useState(MapGrey);

  useEffect(() => {
    if (storeId) {
      dispatch(GetActiveMarketplaces({ storeId }))
    }
  }, [storeId]);

  useEffect(() => {
    if (activeMarketplaces?.length) {
      if (true) {
        let marketplacesList = activeMarketplaces?.map(item => {
          return {
            countryCode: item.countryCode,
            image: MARKETPLACE_FLAG[`${item.marketplaceId}`],
          }
        });
        setLocalStateStoreName(name);
        setMarketplace(marketplacesList);

        const marketPlaceCodes = activeMarketplaces?.map(item => item.countryCode);
        setMarketplaceList(marketPlaceCodes);
        if (region === 'na') setMapLocation(MapNorth);
        else if (region === 'eu') setMapLocation(MapEuorpe);
        else if (region === 'fe') setMapLocation(MapEast);
      }
    }
  }, [activeMarketplaces])

  useEffect(() => {
    if (localStateStoreName) {
      if (storeName !== localStateStoreName) {
        const payload = {
          ...amazonStoreInfo,
          storeName: localStateStoreName
        }
        dispatch(SetStoreState({ field: 'amazonStoreInfo', value: payload }));
      }
    }
  }, [localStateStoreName]);

  useEffect(() => {
    if (error) {
      setToastMessage(error);
      setSeverity('error');
      setToastOpen(true);
      dispatch(SetStoreState({ field: 'error', value: '' }));
      if (error === 'An error occured, please try again later.' || 'Error in store creation') {
        setTimeout(() => {
          history.push('/select-region');
        }, 500)
      }
      return;
    }
    if (message) {
      setToastMessage(message);
      setSeverity('success');
      setToastOpen(true);
      dispatch(SetStoreState({ field: 'message', value: '' }));
      dispatch(SetStoreState({ field: 'updateSuccess', value: false }));
      if (message === 'MarketPlaces and StoreName Added successfully') {
        history.push('/permissions');
      }
      return;
    }
  }, [error, message]);

  useEffect(() => {
    if (location.pathname === '/auth/sp-api') {
      const params = new URLSearchParams(history.location.search);
      const authCode = params.get('spapi_oauth_code');
      const sellerId = params.get('selling_partner_id');
      const spApiState = params.get('state');
      setLocalStateStoreName(storeName);

      console.log({
        authCode,
        sellerId,
        spApiState,
        state
      });

      if (sellerId) {
        if (spApiState === state) {
          dispatch(GetSPAPIToken({
            authCode: authCode,
            sellerId: sellerId,
            region,
            storeName,
          }));
        }
        else {
          dispatch(SetStoreState({ field: 'error', value: 'Error in store creation' }));
        }
      } else {
        dispatch(SetStoreState({ field: 'error', value: 'Error in store creation' }));
      }
    }
  }, []);

  useEffect(() => {
    if (storeSuccess && storeId) {
      dispatch(SetAuthState({ field: 'storesCount', value: storesCount + 1 }));
      dispatch(SetAuthState({ field: 'storeStatus', value: 'Pending' }));
      dispatch(SetStoreState({ field: 'storeId', value: storeId }));
      dispatch(SetStoreState({ field: 'storeSuccess', value: false }));
    }
  }, [storeSuccess]);

  useEffect(() => {
    if (storesCount >= 1 && storeId) {
      history.push("/select-marketplaces");
    }
  }, [storesCount, storeId]);

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToastOpen(false);
  };

  const handleChange = (e) => {
    const selectedIndex = marketplacesList.indexOf(e.target.name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(marketplacesList, e.target.name);
    } else {
      newSelected = cloneDeep(marketplacesList);
      newSelected.splice(selectedIndex, 1);
    }
    setMarketplaceList(newSelected);
  };

  const isSelected = (name) => marketplacesList.indexOf(name) !== -1;

  const handleSave = () => {
    dispatch(UpdateStoreData({ marketplaces: marketplacesList, storeName: localStateStoreName, storeId }));
  }

  return (
    <StoreWrapper>
      {showSidebar ? null : <Header />}

      <Toast
        message={toastMessage}
        severity={severity}
        toastOpen={toastOpen}
        handleToastClose={handleToastClose}
      />
      <div className={showSidebar ? null : "change-background"}>
        <div className={showSidebar ? "select-region m-0 p-0" : "select-region"} style={loading ? { opacity: 0.5 } : null}>
          <div className="arrow-box grid-column-gap-10">
            {showSidebar && (
              <BiLeftArrowAlt className="cursor-pointer" onClick={() => history.goBack()} />
            )}
            <h1>Add Marketplaces</h1>
          </div>

          {marketplace && marketplace.length > 0 ?
            <>
              <div className="search-box text-center mt-20 mb-20">
                <Row className="align-items-center justify-content-center">
                  <Col lg={6} md={8} sm={12}>
                    <SearchInput placeholder="Type Store Name Here" value={localStateStoreName} onChange={(e) => setLocalStateStoreName(e.target.value)} />
                  </Col>
                </Row>
              </div>
              <Row className={showSidebar ? "justify-content-center region-scroll" : "justify-content-center region-scroll-store"}>
                <Col lg={3} md={5}>
                  <div className="pl-39">
                    <h3 className="color-primary fw-bold mb-4">Your {startCase(params.id)} Region</h3>
                    <div className="caption mb-2">Select Region from</div>
                    <span className="select_region">{startCase(params.id)} Region</span>
                    <div className="region_marketplace">
                      <div className="add_scrolll">
                        {marketplace && marketplace?.map((countries, index) =>
                          <div key={index}>
                            <Checkbox type="checkbox"
                              name={countries.countryCode}
                              checked={isSelected(countries.countryCode)}
                              label={
                                <><img src={countries.image} alt="no-marketplace" />
                                  <span className="flag-name">{countries.countryCode}</span></>
                              }
                              onChange={(e) => handleChange(e, countries.name)}
                            />
                          </div>
                        )}
                      </div>
                      <Button className="w-100" text={loading ? "Saving...." : "Save"} onClick={handleSave} disabled={!localStateStoreName || marketplacesList?.length <= 0} />
                    </div>
                  </div>
                </Col>
                <Col lg={9} md={7}>
                  <div className="map_region">
                    <img src={mapLocation} alt="no-region" />
                  </div>
                </Col>
              </Row>
            </>
            :
            <div style={{ marginTop: '20%', marginLeft: '50%' }}>
              <Spinner animation="border" variant="primary" />
            </div>
          }
        </div>
      </div>
    </StoreWrapper>
  )
}

export default SelectMarketplaces;
