import React, { useEffect, useState } from "react";
import moment from "moment";
import { debounce } from "lodash";
import SpinFC from "antd/lib/spin";
import { Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { components } from "react-select";

import DatePickerRange from "../../components/DatePicker/date-picker-range";
import Modal from "../../components/Modal/modal";
import Pagination from "../../components/Pagination/pagination";
import Table from "../../components/Table/table";
import Spin from "../../components/Spin/Index";
import Select from "../../components/Select";
import SearchBar from "../../components/SearchInput";
import Toast from '../../components/Toast';

import OpenCase from "../../assets/images/case-icons.svg";
import OpenCaseConfirm from "../../assets/images/case-icon-1.svg";
import OpenCaseRejected from "../../assets/images/case-icons-2.svg";

import { ActivityLogWrapper } from "./style";

import {
  GetAutomationLogs,
  GetAutomationLogsSummary,
  SetAutomationState
} from '../../redux/slices/automation-slice';
import { GetStoreNames } from '../../redux/slices/store-slice';

import { GetS3ImageUrl, ListS3BucketImages } from "../../helpers/get-s3-image-url";

import { AUTOMATION_LOG_STATUS } from "../../constants";

const { Option } = components;
const Tile = (props) => {
  return (
    <Option {...props} value={"default"}>
      <div className="select-option">
        <span className="d-block bold-weight">{props.data.userName !== 'All' ? props.data.userName : null}</span>
        <span>{props.data.name}</span>
      </div>
    </Option>
  );
}

const ActivityLog = () => {
  const dispatch = useDispatch();
  const {
    loading,
    total,
    totalCaseClaimed,
    totalRejected,
    automationlogs,
    error,
    success,
    totalCasesOpen
  } = useSelector((state) => state.automation);
    
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [severity, setSeverity] = useState("");

  const {
    storeNamesList
  } = useSelector((state) => state.store)
  
  const [filters, setFilters] = useState({
    storeId: '',
    status: '',
    type: '',
    searchKeyword: '',
    limit: 25,
    skip: 0,
    pageNumber: 1
  });

  const [modalLoading, setModalLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [automationImages, setAutomationImages] = useState([]);
  const [selectedStoreValue, setSelectedStoreValue] = useState();

  const [storeNames, setStoreNames] = useState([])

  const [startDate, setStartDate] = useState({
    startDay: "",
    startMonth: "",
    startYear: "",
  });
  
  const [endDate, setEndDate] = useState({
    endDay: "",
    endMonth: "",
    endYear: "",
  });

  const [date, setDate] = useState([
    {
      startDate: moment().toDate(),
      endDate: moment().toDate(),
      key: "selection",
    },
  ]);
  
  const Case = [
    { value: "", label: "All" },
    { value: "Lost", label: "Lost" },
    { value: "Damaged", label: "Damaged" },
    { value: "Destroyed", label: "Destroyed" },
    { value: "Refund", label: "Refund" }
  ];

  const handleSearch = debounce((value, key) => {
    setFilters({
      ...filters,
      pageNumber: 1,
      [key]: value
    });
  }, 500);

  useEffect(() => {
    if (success === false && error) {
      setToastMessage(error);
      setSeverity("error");
      setToastOpen(true);
      dispatch(SetAutomationState({ field: "error", value: "" }));
    }
  }, [success])

  useEffect(() => {
    if (storeNamesList && storeNamesList.length > 0) {
      const storeNamesData = storeNamesList?.map(store => ({
        value: store._id,
        label: `${store.name}`,
        userName: store.userName,
        name: store.name
      }));

      const stores = [];

      stores.push(...storeNamesData);
      setStoreNames(stores);
    } else {
      setStoreNames([]);
    }
    setSelectedStoreValue('All')
  }, [storeNamesList]);

  useEffect(() => {
    const formattedEndDate = moment(endDate).format('L');
    const checkDate = moment(formattedEndDate);
    const day = checkDate.format('D');
    const month = checkDate.format('M');
    const year = checkDate.format('YYYY');

    setStartDate({ startDay: Number(day), startMonth: Number(month), startYear: Number(year) });
    setEndDate({ endDay: Number(day), endMonth: Number(month), endYear: Number(year) });
    setSelectedStoreValue('All')
  }, [])

  useEffect(() => { 
    dispatch(GetStoreNames());
  }, [])

  useEffect(() => {
    if (selectedStoreValue && endDate.endDay !== '') {
      const skip = (filters.pageNumber - 1) * filters.limit;
      const limit = filters.limit;
      dispatch(GetAutomationLogs({
        ...filters,
        storeId: selectedStoreValue,
        limit,
        skip,
        startDate,
        endDate
      }));
    }
  }, [selectedStoreValue, filters, startDate, endDate])

  useEffect(() => {
    if (selectedStoreValue && endDate.endDay !== '') {
      dispatch(GetAutomationLogsSummary({
        storeId: selectedStoreValue,
        type: filters.type,
        status: filters.status,
        startDate,
        endDate
      }));
    }
  }, [startDate, endDate])

  const handleAutomationLogId = async (item) => {
    setModalLoading(true);
    setShowModal(true);
    const bucketName = 'st-activity-logs';
    if (item) {
      let taskLogId = item._id;
      if (item.type?.toLowerCase() === 'lost') {
        taskLogId = item.taskRunningLogId;
      }
      const folderPath = `${item.storeId}/${item.type?.toLowerCase()}/${taskLogId}/${item.marketplaceId}`;
      const { imageKeys } = await ListS3BucketImages({ bucketName, folderPath }) || {};
  
      if (imageKeys && imageKeys.length) {
        const imagesArray = [];
        for (let i = 0; i < imageKeys.length; i += 1) {
          const url = GetS3ImageUrl({ bucketName, key: imageKeys[i] });
          imagesArray.push(url);
        } 
        setAutomationImages(imagesArray);
      }
    }
    setModalLoading(false);
  }

  const handleSnapshot = (imageLink) => {
    window.open(imageLink, '_blank');
  };

  const onApply = () => {
    const { startDate, endDate } = date[0];

    const formatedStartDate = moment(startDate).format("L");
    const formatedEndDate = moment(endDate).format("L");

    const checkStart = moment(formatedStartDate);
    const startDay = checkStart.format("D");
    const startMonth = checkStart.format("M");
    const startYear = checkStart.format("YYYY");

    setStartDate({ startDay, startMonth, startYear });

    const checkEnd = moment(formatedEndDate);
    const endDay = checkEnd.format("D");
    const endMonth = checkEnd.format("M");
    const endYear = checkEnd.format("YYYY");

    setEndDate({ endDay, endMonth, endYear });
  };

  const handleDateChange = (item) => {
    setDate([item.selection]);
  };

  const handlePageLimit = (e) => {
    setFilters({
      ...filters,
      limit: e.value,
      pageNumber: 1
    })
  };

  const handlePageNumberChange = (e) => {
    const totalPages = Math.ceil(total / filters.limit)
    if (e.key == "Enter") {
      if (e.target.value < 1 || !e.target.value) {
        setFilters({ ...filters, pageNumber: 1 })
      } else if (e.target.value > totalPages) {
        setFilters({ ...filters, pageNumber: totalPages })
      } else {
        setFilters({
          ...filters, pageNumber: Number(e.target.value)
        })

      }
    }
  }

  const handlePageChangeLeft = () => {
    if (filters.pageNumber - 1 > 0) {
      setFilters({
        ...filters, pageNumber: filters.pageNumber - 1
      })
    }
  }

  const handlePageChangeRight = () => {
    const totalPages = Math.ceil(total / filters.limit)
    if (filters.pageNumber < totalPages) {
      setFilters({
        ...filters, pageNumber: filters.pageNumber + 1
      })
    }
  }

  const handleChange = (e, key) => {
    setFilters({
      ...filters,
      pageNumber: 1,
      [key]: e?.value || ''
    })
  };

  const handleToastClose = (reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToastOpen(false);
  };  
  
  useEffect(()=>{
    console.log({toastOpen})
  },[toastOpen])

  return (
    <ActivityLogWrapper>
      <Toast
        message={toastMessage}
        severity={severity}
        toastOpen={toastOpen}
        handleToastClose={handleToastClose}
      />
      
      <Row>
      <Col md={6}>
      <div className="header-wrapper" style={{display: 'contents'}}>
        <h6>Activity Logs</h6>
        <p className="sub-header-item">
        All details about the activity logs of your stores.
      </p>
      </div>
      <div></div>
      </Col>
      <Col md={6}>
          <Row>
            <Col md={12}><p className='fs-5 m-0 small'><b>Other</b> ➝ Not Eligible</p></Col>
            <Col md={12}><p className='fs-5 m-0 small'><b>Success</b> ➝ Case Claimed</p></Col>
            <Col md={12}><p className='fs-5 m-0 small'><b>Reimbursed/Already_Reimbursed</b> ➝ Case already resolved/reimbursed</p></Col>
            <Col md={12}><p className='fs-5 m-0 small'><b>Initial</b> ➝ Automation is not done yet</p></Col>
            <Col md={12}><p className='fs-5 m-0 small'><b>No_Actionable_Event</b> ➝ Maybe in future, we can claim</p></Col>
          </Row>
      </Col>
      </Row>
      <div className="header-right-section mt-2">
          <DatePickerRange
            date={date}
            startDate={startDate}
            endDate={endDate}
            onApply={onApply}
            onChange={handleDateChange}
          />
          <Select width={189} placeholder="Select Case Type" options={Case} handleChange={(e) => handleChange(e, 'type')} />
          <Select width={189} placeholder="Select Status" options={AUTOMATION_LOG_STATUS} handleChange={(e) => handleChange(e, 'status')} />
          <Select
            handleChange={(e) => {
              if (e === null) {
                setSelectedStoreValue('All')
              }
              else
                setSelectedStoreValue(e.value)
            }}
            options={storeNames}
            className="dashbaord-select"
            isClearable={true}
            components={{ Option: Tile }}
            placeholder='Select Store'
          />
          <SearchBar placeholder="Search" 
            onChange={(e) => handleSearch(e.target.value, 'searchKeyword')} 
          />

        </div>
      <Row>
        <Col md={4}>
          <div className="card-wrapper total-cases">
            <img src={OpenCase} />
            <div className="card-right-side">
              <p>Total Cases Open</p>
              <h2>{totalCasesOpen}</h2>
            </div>
          </div>
        </Col>
        <Col md={4}>
          <div className="card-wrapper success-cases">
            <img src={OpenCaseConfirm} />
            <div className="card-right-side">
              <p>Successfully Claimed</p>
              <h2>{totalCaseClaimed}</h2>
            </div>
          </div>
        </Col>
        <Col md={4}>
          <div className="card-wrapper rejected-cases">
            <img src={OpenCaseRejected} />
            <div className="card-right-side">
              <p>Rejected</p>
              <h2>{totalRejected}</h2>
            </div>
          </div>
        </Col>
      </Row>
      <Table
        className="table-activity-log-wrapper"
        tableHeader={
          <tr>
            <th>Name</th>
            <th>Store Name</th>
            <th>Date</th>
            <th>Description</th>
            <th>Case Type</th>
            <th>Automation Log ID</th>
            <th>FNSKU / Transaction Item ID / Order ID</th>
            <th>Status</th>
          </tr>
        }
        tableBody={
          <>
            {!loading && !automationlogs.length ? (
              <tr>
                <td>
                  <div className="error">
                    <h1>No Data Available</h1>
                  </div>
                </td>
              </tr>
            ) : null}
            {loading ? (
              <tr>
                <td>
                  <Spin />
                </td>
              </tr>
            ) : (
              automationlogs.map((item) => (
                <tr>
                  <td>{item.userName}</td>
                  <td>{item.storeName}</td>
                  <td>{moment(item.createdAt).format('ll hh:mm A')}</td>
                  <OverlayTrigger
                    overlay={<Tooltip>{item?.content || ''}</Tooltip>}
                  >
                    <td>{item?.content ? item?.content?.substring(0, 30) + '...' : ''}</td>
                  </OverlayTrigger>

                  <td>{item.type}</td>
                  <td><a onClick={() => handleAutomationLogId(item)}>{item.taskArn}</a></td>
                  <td>{item.type === 'Lost' ? item.fnsku :
                    item.type === 'Destroyed' || item.type === 'Damaged' ? item.transactionItemId : 
                      item.type === 'Refund' ? item.orderId : ""}</td>
                  <td> {
                    <td><span className={
                      item.status === 'Case Open' ? 'bages-item open-case' :
                        item.status === 'Success' ? 'bages-item' : 'bages-item erorr-color'}
                    >
                      {item.status === 'Case Open' ? 'Initial' : item.status}
                    </span>
                    </td>
                  }
                  </td>
                </tr>
              ))
            )}
          </>
        }
      />
      <Pagination
        className="affiliate-pagination-wrapper"
        pageLimit={filters.limit}
        total={total}
        pageNumber={filters.pageNumber}
        totalPages={Math.ceil(total / filters.limit)}
        handlePageChangeLeft={handlePageChangeLeft}
        handlePageChangeRight={handlePageChangeRight}
        handleLimitChange={handlePageLimit}
        handlePageNumberChange={handlePageNumberChange}
      />
      {
        showModal ? (
          <Modal
          showModal={showModal}
          modalHeader='Automation Snapshots'
          modalType="image"
          modalBody={
            <>
            {modalLoading ? <SpinFC /> : automationImages.length ? automationImages.map((image, index) => {
              let imageName = image.split('/');
              imageName = imageName[imageName.length - 1].replace(/\.jpg$/, '');

            return (
            <div style={{
                display: 'inline-block',
                padding: '2px',
                marginBottom: '5px',
                maxWidth: '50%'
              }}>
              {index > 0 && index % 2 === 0 ?
                <>
                <br/>
                <img style={{ width: '350px', height: '300px', border: '2px solid black',  marginLeft: '20px', marginTop: '20px', cursor: 'pointer' }} src={image} onClick={() => handleSnapshot(image)} alt="Image" />
                <b style={{ margin: '100px' }}>{imageName}</b>
                </>:
                (<>
                  <img style={{ width: '350px', height: '300px', border: '2px solid black', marginLeft: '20px', marginTop: '20px', cursor: 'pointer' }} src={image} onClick={() => handleSnapshot(image)} alt="Image" />
                  <b style={{ margin: '100px' }}>{imageName}</b>
                  </>
                  )}
              </div>
            )
            }): 'No Snapshots Captured'}
            </>
          }
          handleClose={() => {
            setAutomationImages([]);
            setShowModal(false);
          }
          }
          showFooter={true}
          />
        ): null
      }
    </ActivityLogWrapper>
  );
};

export default ActivityLog;
