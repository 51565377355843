import React from 'react';
import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'
const Index = (props) => {
  const { show, onHide,size } = props;

  return (
    <Drawer 
      open={show}
      onClose={onHide}
      size={size}
      direction='right'
      className='drawer-main'
    >
      {props.children}
    </Drawer>
  )
}

export default Index;