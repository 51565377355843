import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { axiosBaseUrl } from '../../config/axios-configuration';

import SaveSentryLog from '../../helpers/sentry-log';

const axios = axiosBaseUrl();

export const GetCases = createAsyncThunk(
  'dashboard/getAdjustmentCases',
  async (data, { rejectWithValue }) => {
    try {
      const {
        userId,
        storeId,
        caseStatus,
        caseType,
        draft,
        startDate,
        endDate,
        searchKeyword,
        limit,
        skip
      } = data;

      const response = await axios.get('/dashboard/get-adjustment-cases', {
        params: {
          userId,
          storeId,
          caseStatus,
          caseType,
          draft,
          startDate,
          endDate,
          searchKeyword,
          limit,
          skip
        }
      });

      return response.data;
    } catch (err) {
      SaveSentryLog(new Error(err), 'GetAdjustmentCasesError');
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const RejectCase = createAsyncThunk(
  'dashboard/rejectCase',
  async (data, { rejectWithValue }) => {
    try {
      const {
        caseStatId
      } = data;

      const response = await axios.put('/dashboard/reject-case', {
          caseStatId
      });

      return response.data;
    } catch (err) {
      SaveSentryLog(new Error(err), 'RejectCaseError');
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const GetUserAmountsSummary = createAsyncThunk(
  'dashboard/get-user-amounts-summary',
  async (data, { rejectWithValue }) => {
    try {
      const { userId, storeId } = data;
      const response = await axios.get('/dashboard/user-amounts-summary', {
        params: {
          userId,
          storeId
        }
      });
      console.log('response.data', response.data);
      return response.data;
    } catch (err) {
      SaveSentryLog(new Error(err), 'GetUserAmountsSummaryError');
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const GetOverallPercentageSummary = createAsyncThunk(
  'dashboard/get-overall-percentage-summary',
  async (data, { rejectWithValue }) => {
    try {
      const { userId, storeId } = data;
      const response = await axios.get('/dashboard/overall-percentage-summary', {
        params: {
          userId,
          storeId
        }
      });

      return response.data;
    } catch (err) {
      SaveSentryLog(new Error(err), 'GetOverallPercentageSummaryError');
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const GetCaseTypeGraphData = createAsyncThunk(
  'dashboard/get-case-type-graph-data',
  async (data, { rejectWithValue }) => {
    try {
      const {
        storeId,
        startDate,
        endDate
      } = data;

      const response = await axios.get('/dashboard/get-case-type-graph-data', {
        params: {
          storeId,
          startDate,
          endDate
        }
      });

      return response.data;
    } catch (err) {
      SaveSentryLog(new Error(err), 'GetCaseTypeGraphDataError');
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const GetCaseStatusGraphData = createAsyncThunk(
  'dashboard/get-case-status-graph-data',
  async (data, { rejectWithValue }) => {
    try {
      const {
        storeId,
        startDate,
        endDate
      } = data;

      const response = await axios.get('/dashboard/get-case-status-graph-data', {
        params: {
          storeId,
          startDate,
          endDate
        }
      });

      return response.data;
    } catch (err) {
      SaveSentryLog(new Error(err), 'GetCaseStatusGraphDataError');
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

const dashboard = createSlice({
  name: 'dashboard',
  initialState: {
    message: '',
    err: '',
    userId: '',
    total: 0,
    cases: [],
    totalCases: 0,
    success: false,
    dashboardSummary: {},
    caseTypeGraphData: {},
    caseStatusGraphData: {},
    loading: false
  },
  reducers: {
    SetState(state, { payload: { field, value } }) {
      state[field] = value;
    }
  },
  extraReducers: {
    [GetCases.pending]: (state, action) => ({
      ...state,
      loading: true,
      success: false
    }),
    [GetCases.fulfilled]: (state, action) => ({
      ...state,
      total: action.payload.total,
      cases: action.payload.cases,
      totalCases: action.payload.total,
      loading: false,
      success: true
    }),
    [GetCases.rejected]: (state, action) => ({
      ...state,
      loading: false,
      success: false,
      err: action.payload.err?.message
    }),
    [RejectCase.pending]: (state, action) => ({
      ...state,
      loading: true
    }),
    [RejectCase.fulfilled]: (state, action) => ({
      ...state,
      message: action.payload.message,
      loading: false
    }),
    [RejectCase.rejected]: (state, action) => ({
      ...state,
      loading: false
    }),    
    [GetUserAmountsSummary.pending]: (state, action) => ({
      ...state,
      loading: true,
      success: false
    }),
    [GetUserAmountsSummary.fulfilled]: (state, action) => ({
      ...state,
      userAmountsSummary: action.payload.userAmountsSummary,
      loading: false,
      success: true
    }),
    [GetUserAmountsSummary.rejected]: (state, action) => ({
      ...state,
      loading: false,
      success: true,
      err: action.payload.err?.message
    }),
    [GetOverallPercentageSummary.pending]: (state, action) => ({
      ...state,
      loading: true,
      success: false
    }),
    [GetOverallPercentageSummary.fulfilled]: (state, action) => ({
      ...state,
      overAllPercentageSummary: action.payload.overAllPercentageSummary,
      loading: false,
      success: true
    }),
    [GetOverallPercentageSummary.rejected]: (state, action) => ({
      ...state,
      loading: false,
      success: true,
      err: action.payload.err?.message
    }),
    [GetCaseTypeGraphData.pending]: (state, action) => ({
      ...state,
      loading: true
    }),
    [GetCaseTypeGraphData.fulfilled]: (state, action) => ({
      ...state,
      caseTypeGraphData: action.payload.caseStatistics
    }),
    [GetCaseTypeGraphData.rejected]: (state, action) => ({
      ...state,
      loading: false
    }),
    [GetCaseStatusGraphData.pending]: (state, action) => ({
      ...state,
      loading: true
    }),
    [GetCaseStatusGraphData.fulfilled]: (state, action) => ({
      ...state,
      caseStatusGraphData: action.payload.caseStatistics
    }),
    [GetCaseStatusGraphData.rejected]: (state, action) => ({
      ...state,
      loading: false
    })
  }
});

const { reducer, actions } = dashboard;

export const {
  SetState
} = actions;

export default reducer;
