import styled from "styled-components";

const StoreWrapper = styled.div`
  /* font-family: 'Titillium Web', sans-serif; */
  font-family: "Inter", sans-serif;
  padding-left: 24px;
  padding-right: 24px;
  height: 100%;
  margin-top: 64px;
  .map_region {
    text-align: center;
    margin-left: 102px;
    margin-top: 71px;
    margin-right: 99px;
  }
  .success-content-wrapper {
    margin-left: 21px;
    margin-top: 15px;
  }
  .btn-congratulation-wrapper {
    background: linear-gradient(91.7deg, #3e8ee3 4.69%, #4242e4 79%);
    border-radius: 50px;
    border-radius: 50px;
    color: #fff !important;
    border: none;
    min-width: 182px;
    min-height: 40px;
    font-size: 16px;
    font-weight: 900;
  }
  .change-background {
    background-color: #e5e5e5;
    height: 100vh;
    padding-top: 57px;
  }
  .logo-onboarding {
    padding-top: 16px;
    padding-bottom: 40px;
  }
  .select-region {
    background-color: ${({ theme }) => theme["white-color"]};
    position: relative;
    .heading {
      font-size: 36px;
      color: #064aac;
      letter-spacing: 1px;
    }
    .region-scroll {
      height: calc(100vh - 252px);
      overflow-y: auto;
    }
    .region-scroll-store {
    }
    border-radius: 5px;
    .arrow-box {
      display: flex;
      align-items: center;
      svg {
        color: ${({ theme }) => theme["primary-color"]};
      }
    }
    .caption {
      color: ${({ theme }) => theme["cutty_sark"]};
      margin-bottom: 23px;
    }
    .select_region {
      color: ${({ theme }) => theme["base-font-size-small"]};
      font-size: ${({ theme }) => theme["base-font-sizes"]};
    }
    .region_marketplace {
      .add_scrolll {
        max-height: 281px;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        margin-top: 10px;
        border-bottom: 1px solid ${({ theme }) => theme["secondary-variant"]};
        margin-bottom: 20px;
        .flag-name {
          color: ${({ theme }) => theme["secondary-color"]};
          font-size: ${({ theme }) => theme["base-font-size-small"]};
          margin-left: 10px;
        }
      }
    }
    .region {
      &_block {
        background: ${({ theme }) => theme["background"]};
        border: 1px solid ${({ theme }) => theme["secondary-variant"]};
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 24px 17px 15px 23px;
        cursor: pointer;
        height: 100%;
        transition: 0.3s all ease-in;

        &:hover {
          box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.16);
          border: 2px solid ${({ theme }) => theme["primary-variant"]};
        }
        h3 {
          color: ${({ theme }) => theme["on-background-variant"]};
          font-size: ${({ theme }) => theme["medium-font-size"]};
          line-height: 17px;
          margin-bottom: 0;
        }
        img {
          margin-bottom: 10px;
        }
        .round {
          position: relative;
          margin-right: 6px;
          margin-top: 0px;
          height: 16px;
        }

        .round label {
          background-color: ${({ theme }) => theme["white"]};
          border: 1px solid ${({ theme }) => theme["on-background-variant"]};
          border-radius: 50%;
          cursor: pointer;
          height: 13px;
          left: 0;
          position: absolute;
          top: 1px;
          width: 13px;
          margin-bottom: 0;
        }

        .round label:after {
          border: 1.5px solid ${({ theme }) => theme["white"]};
          border-top: none;
          border-right: none;
          content: "";
          height: 4px;
          left: 1.5px;
          opacity: 0;
          position: absolute;
          top: 3px;
          transform: rotate(-45deg);
          width: 8px;
        }

        .round input[type="checkbox"] {
          visibility: hidden;
        }

        .round input[type="checkbox"]:checked + label {
          background-color: #00b48f;
          border-color: #00b48f;
        }

        .round input[type="checkbox"]:checked + label:after {
          opacity: 1;
        }
      }
      .region-block-content {
        height: 100%;
        .region-group-inline {
          flex-wrap: wrap;
          .region-block-group {
            margin-right: 68px;

            &:last-of-type {
              margin-right: 0;
            }
          }
        }
        .region-block-group {
          margin-top: 23px;
          margin-bottom: 9px;
        }
      }
    }
  }
  .search-input {
    margin-bottom: 24px;
    input {
      border-radius: 20px;
      font-weight: 300;
      font-size: ${({ theme }) => theme["base-font-size-small"]};
      line-height: 15px;
      padding: 10px 16px;
      min-height: 10px;
      height: 36px;
      max-height: 100%;
    }
  }
  .marketplace-region {
    h2 {
      letter-spacing: 2px;
      margin-bottom: 12px;
    }
    .region-name {
      margin-top: 0px;
      margin-right: 0px;
      span {
        font-weight: 500;
        font-size: ${({ theme }) => theme["base-font-size-small"]};
        line-height: 15px;
        color: ${({ theme }) => theme["on-background-variant"]};
      }
    }

    .country-region {
      margin-top: 15px;
      grid-column-gap: 16px;
      img {
        background: ${({ theme }) => theme["background"]};
        border: 1px solid ${({ theme }) => theme["secondary-variant"]};
        border-radius: 20px;
        border-radius: 20px;
        width: 24px;
        height: 24px;
        margin-bottom: 0;
      }
    }
    .add-button-space {
      margin-top: 40px;
    }
  }
  .section-heading {
    h2 {
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      color: ${({ theme }) => theme["on-surface"]};
      margin-bottom: 6px;
      letter-spacing: 0;
    }
    .caption {
      font-size: ${({ theme }) => theme["base-font-size-small"]};
      line-height: 15px;
      color: ${({ theme }) => theme["body-text-variant"]};
    }
    &.thankyou-heading {
      h2 {
        margin-bottom: 12px;
      }
      span {
        color: ${({ theme }) => theme["primary"]};
      }
    }
  }
  .info-tooltip {
    background-color: ${({ theme }) => theme["accent-info"]};
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: ${({ theme }) => theme["xs-small-size"]};
    line-height: 12px;
    color: ${({ theme }) => theme["on-primary"]};
    width: 140px;
    height: 140px;
    position: absolute;
    right: 6.5%;
    top: -58px;
    mix-blend-mode: multiply;
    border-radius: 55%;
    padding: 13px 11px 13px 16px;
    z-index: 1;
    text-align: center;
    span {
      font-family: "Inter";
      font-weight: 400;
      font-size: 29px;
      line-height: 35px;
      display: block;
    }
  }
  .onboarding-space-left {
    padding-left: 112px;
  }
  .onboarding-space-right {
  }
  .add-details {
    max-width: 420px;
    padding-bottom: 11px;
    border-bottom: 1px solid ${({ theme }) => theme["secondary-variant"]};
    padding-top: 10px;
    margin-bottom: 15px;

    button {
      padding: 6.5px 15px;
      margin-right: 12px;
    }
    p {
      font-weight: 400;
      font-size: ${({ theme }) => theme["base-font-size-small"]};
      line-height: 17px;
      color: ${({ theme }) => theme["body-text-varaint"]};
      padding-right: 8px;
      margin-bottom: 0;
      strong {
        font-weight: 700;
      }
    }
  }
  .table-region-main {
    border: 1px solid ${({ theme }) => theme["secondary-variant"]};
    border-radius: 10px;
    padding: 12px;
    margin-top: 12px;
  }
  .table-region {
    margin-bottom: 0;
    thead {
      background: rgba(222, 219, 219, 0.55);
      tr {
        th {
          padding: 3px;
          font-weight: 400;
          font-size: ${({ theme }) => theme["xs-small-size"]};
          line-height: 12px;
          color: ${({ theme }) => theme["body-text"]};
          padding-left: 0px;
          padding-right: 26px;
          border-bottom: 0px;
          &:first-of-type {
            padding-left: 10px;
          }
        }
      }
    }
    .form-group {
      margin-bottom: 0;
    }
    tbody {
      tr {
        td {
          padding: 0;
          padding-top: 10px;
          padding-left: 0px;
          padding-right: 9px;
          &:first-of-type {
            padding-left: 0;
          }
          &:last-of-type {
            padding-right: 0;
            text-align: right;
          }
        }
      }
    }
    ul.actions {
      padding: 0;
      margin: 0;
      display: flex;
      list-style: none;
      li {
        margin-right: 9px;
        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }
  .select2-container {
    .select2-selection__placeholder,
    .select2-selection__single-value {
      color: ${({ theme }) => theme["placeholder"]};
      font-size: ${({ theme }) => theme["xs-small-size"]}!important;
      margin: 0;
    }
    .select2-selection__single-value {
      ${({ theme }) => theme["body-text"]};
    }
    .select2-selection__dropdown-indicator {
      padding: 3px;
    }
    .select2-selection__control {
      border: 1px solid ${({ theme }) => theme["secondary-variant"]};
      max-height: 28px !important;
      min-height: 28px !important;
      line-height: 20px;
      .select2-selection__rendered {
        line-height: 20px;
        padding-left: 0px;
      }
      .select2-selection__arrow {
        height: 34px;
        width: 34px;
        right: 3px;
      }
      svg {
        color: ${({ theme }) => theme["body-text"]};
        height: 15px;
        width: 15px;
      }
    }
  }
  .select2-selection__menu-list {
    .select2-selection__option {
      padding: 4px 8px;
      font-size: ${({ theme }) => theme["base-font-size-small"]};
    }
  }
  .without-layout-sidebar-wrapper {
    margin-top: -72px;
    margin-left: 2px;
  }
  .invite-btn {
    padding: 7.5px 15px;
    font-weight: 400;
    font-size: ${({ theme }) => theme["xs-small-size"]};
    line-height: 12px;
    color: ${({ theme }) => theme["body-text"]};
    border: 0.5px solid ${({ theme }) => theme["secondary-variant"]};
    min-width: 10px;
    border-radius: 5px;
    margin-bottom: 0;
    height: 28px;
  }
  .region-note {
    font-weight: 400;
    font-size: ${({ theme }) => theme["base-font-size-small"]};
    line-height: 15px;
    color: ${({ theme }) => theme["body-text-variant"]};
    margin-top: 24px;
    margin-bottom: 15px;
  }
  .add-global-account {
    .form-check {
      margin-top: -6px;
      margin-left: 2px;
    }
  }
  .inviting-user-wrapper {
    margin-top: -72px;
  }
  .onboarding-right-section {
    max-width: 195px;
    margin: 0 auto;
    margin-right: 0;
    height: calc(100vh - 128px);
    display: flex;
    align-items: end;
    .info-box-main {
      position: relative;
      width: 100%;
      &.no-bubbles {
        &:after {
          display: none;
        }
      }
    }
    .circle-section {
      margin-left: -112px;
      margin-bottom: 3px;
      display: none;
    }
    .info-block {
      background: ${({ theme }) => theme["background"]};
      border-radius: 16px;
      padding: 16px 15px;
      margin-bottom: 13px;
      &:last-of-type {
        margin-bottom: 0;
      }
      h3 {
        font-weight: 700;
        font-size: ${({ theme }) => theme["base-font-size-small"]};
        line-height: 15px;
        color: ${({ theme }) => theme["primary"]};
        margin-bottom: 8px;
      }
      p {
        font-weight: 700;
        font-size: 13px !important;
        line-height: 12px;
        color: ${({ theme }) => theme["body-text-variant"]};
        margin-bottom: 12px !important;
        margin: auto;
        max-width: 112px;
        text-align: center;
        line-height: 12px !important ;
      }
      button {
        background:${({theme}) => `linear-gradient(180deg, ${theme['primary-variant']} 0%, ${theme['primary']} 49.67%, ${theme['primary-btn-gradient-3']} 98.63%);`};
        margin-bottom: 0;
        border-radius: 30px;
        font-size: 14px;
        font-weight: 700;
      }
      &.success-block {
        p {
          color: ${({ theme }) => theme["primary"]};
        }
        button {
          background: ${({ theme }) => theme["accent-success"]};
          border: 1px solid ${({ theme }) => theme["accent-success"]};
        }
      }
    }
  }
  .sidebar-wrapper-new {
    max-width: 195px;
    margin: 0 auto;
    margin-right: 0;
    height: calc(100vh - 200px);
    display: flex;
    align-items: end;
    position: relative;
    right: -116px;
    .info-box-main {
      position: relative;
      width: 100%;
      &.no-bubbles {
        &:after {
          display: none;
        }
      }
    }
    .circle-section {
      margin-left: -112px;
      margin-bottom: 3px;
      display: none;
    }
    .info-block {
      background: ${({ theme }) => theme["background"]};
      border-radius: 16px;
      padding: 16px 15px;
      margin-bottom: 13px;
      &:last-of-type {
        margin-bottom: 0;
      }
      h3 {
        font-weight: 700;
        font-size: ${({ theme }) => theme["base-font-size-small"]};
        line-height: 15px;
        color: ${({ theme }) => theme["primary"]};
        margin-bottom: 8px;
      }
      p {
        font-weight: 700;
        font-size: 13px !important;
        line-height: 12px;
        color: ${({ theme }) => theme["body-text-variant"]};
        margin-bottom: 12px !important;
        margin: auto;
        max-width: 112px;
        text-align: center;
      }
      button {
        background:${({theme}) => `linear-gradient(180deg, ${theme['primary-variant']} 0%, ${theme['primary']} 49.67%, ${theme['primary-btn-gradient-3']} 98.63%);`};
        margin-bottom: 0;
        border-radius: 30px;
        font-size: 14px;
        font-weight: 700;
      }
      &.success-block {
        p {
          color: ${({ theme }) => theme["primary"]};
        }
        button {
          background: ${({ theme }) => theme["accent-success"]};
          border: 1px solid ${({ theme }) => theme["accent-success"]};
        }
      }
    }
  }
  .permission-right {
    .onboarding-right-section {
      height: calc(100vh - 204px);
      margin-top: 76px;
    }
  }
  .thankyou-content-with-layout {
    width: 420px;
  }
  .thankyou-content {
    max-width: 420px;
    .thankyou-step {
      background: ${({ theme }) => theme["background"]};
      border-radius: 16px;
      margin-bottom: 16px;
      padding: 16px 24px;
      h3 {
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: ${({ theme }) => theme["on-surface"]};
      }
      p {
        font-weight: 400;
        font-size: ${({ theme }) => theme["base-font-size-small"]};
        line-height: 15px;
        color: ${({ theme }) => theme["primary"]};
        margin-bottom: 0;
      }
    }
    .thankyou-caption {
      background: ${({ theme }) => theme["background"]};
      border-radius: 16px;
      padding: 24px 16px;
      p {
        font-weight: 400;
        font-size: ${({ theme }) => theme["base-font-size-small"]};
        line-height: 15px;
        color: ${({ theme }) => theme["body-text-variant"]};
        margin-bottom: 8px;
        &:last-of-type {
          margin-bottom: 24px;
        }
      }
    }
  }

  .thankyou-content-with-layout {
    .thankyou-step {
      background: ${({ theme }) => theme["background"]};
      border-radius: 16px;
      margin-bottom: 16px;
      padding: 16px 24px;
      h3 {
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: ${({ theme }) => theme["on-surface"]};
      }
      p {
        font-weight: 400;
        font-size: ${({ theme }) => theme["base-font-size-small"]};
        line-height: 15px;
        color: ${({ theme }) => theme["primary"]};
        margin-bottom: 0;
      }
    }
    .thankyou-caption {
      background: ${({ theme }) => theme["background"]};
      border-radius: 16px;
      padding: 24px 16px;
      p {
        font-weight: 400;
        font-size: ${({ theme }) => theme["base-font-size-small"]};
        line-height: 15px;
        color: ${({ theme }) => theme["body-text-variant"]};
        margin-bottom: 8px;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
  .with-siderbar-store-layout {
    margin-top: 72px;
  }
  &.store-success {
    height: calc(100vh - 171px);
    .success-content {
      /* padding-top:108px; */
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 112px;
    }
    .success-check {
      margin-bottom: 36px;
    }
    h2 {
      font-size: 32px;
      font-weight: 900;
      line-height: 39px;
      letter-spacing: 0px;
      text-align: left;
      color: ${({ theme }) => theme["primary"]};
      margin-bottom: 16px;
    }
    p {
      font-weight: 700;
      font-size: 16px !important;
      line-height: 19.36px !important;
      line-height: 15px;
      display: flex;
      align-items: center;
      color: ${({ theme }) => theme["body-text-variant"]};
      margin-bottom: 24px;
      text-align: center;
      display: block;
    }
    .onboarding-right-section {
      /* height:auto; */
      height: calc(100vh - 168px);
      padding-bottom: 32px;
    }
  }
  .permission-table {
    margin-top: 19px;
    .section-heading {
      border-bottom: 1px solid ${({ theme }) => theme["secondary-variant"]};
    }
    table {
      thead {
        background-color: ${({ theme }) => theme["white"]};
        tr {
          th {
            padding: 0.75rem;
            text-align: center;
            padding-top: 8px;
            padding-bottom: 8px;
            &:last-of-type {
              padding-right: 0;
            }
          }
        }
      }
      tbody {
        tr {
          td {
            text-align: center;
            color: ${({ theme }) => theme["body-text"]};
            font-weight: 400;
            line-height: 12px;
            padding: 0.75rem;
            vertical-align: middle;
            padding-top: 9px;
            padding-bottom: 9px;
            padding-left: 0;
            p {
              font-size: ${({ theme }) => theme["xs-small-size"]};
              margin-bottom: 0;
            }
            small {
              font-size: 7px;
              line-height: 8px;
              display: block;
            }
            &:first-of-type {
              text-align: right;
              padding-left: 29px;
            }
            &:last-of-type {
              text-align: center;
            }
            .form-check {
              position: relative;
              top: -2px;
              padding-left: 0;
              .form-check-input {
                margin-left: 0;
              }
            }
          }
          &:nth-of-type(odd) {
            td {
              padding-top: 2.5px;
              padding-bottom: 2.5px;
            }
          }
        }
      }
    }
    .permission-table-name {
      max-width: 160px;
    }
  }
  .permission-footer {
    border-top: 1px solid ${({ theme }) => theme["secondary-variant"]};
    border-bottom: 1px solid ${({ theme }) => theme["secondary-variant"]};
    padding-top: 7px;
    padding-bottom: 7px;
    margin-top: 16px;
    .region-note {
      margin-top: 0;
      margin-bottom: 0;
    }
    .btn {
      padding: 8px 14px;
      border: 1px solid ${({ theme }) => theme["accent-warning"]};
      font-size: ${({ theme }) => theme["base-font-size-small"]};
      line-height: 15px;
      margin-bottom: 0;
      /* identical to box height */
      color: ${({ theme }) => theme["accent-warning"]};
    }
  }
  .permission-scroll {
    height: calc(100vh - 275px);
    overflow: auto;
    margin-top: -16px;
  }
  .visibility {
    opacity: 0.5;
    pointer-events: none;
  }
  &.dashboard-store {
    padding-left: 0;
    padding-right: 0;
    .search-input {
      margin-bottom: 40px;
      .form-group {
        margin-bottom: 0;
      }
    }
    .info-tooltip {
      top: -16px;
      right: 58px;
    }
  }
  .amazon-ask-info {
    margin-top: 24px;
    border-bottom: 1px solid #DEDBDB;
    padding-bottom: 15px;
    .section-heading {
      border-bottom: 1px solid #DEDBDB;
      margin-bottom: 12px;
      h2 {
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: #353e44;
        margin-bottom: 12px;
      }
    }
    .amazon-ask-content {
      p {
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        margin-bottom: 14px;

        color: #494f51;
      }
      .amazon-ask-bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .amazon-ask-options {
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
          color: #494f51;
          input {
            margin-top: 0;
          }
          .form-check{
            margin-bottom:11px;
            &:last-of-type{
              margin-bottom: 0;
            }
          }
        }
        .amazon-ask-btn {
          .btn {
            background: #e8c347;
            border: 1px solid #e8c347;
            border-radius: 10px;
            font-weight: 700;
            font-size: 12px;
            line-height: 15px;
            /* identical to box height */

            color: #322a12;
          }
        }
      }
    }
  }
  .amazon-ask-info-continue {
    margin-top: 24px;
    border-bottom: 1px;
    padding-bottom: 15px;
    .section-heading {
      border-bottom: 1px;
      margin-bottom: 12px;
      h2 {
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: #353e44;
        margin-bottom: 12px;
      }
    }
    .amazon-ask-content {
      p {
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        margin-bottom: 14px;

        color: #494f51;
      }
      .amazon-ask-bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .amazon-ask-options {
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
          color: #494f51;
          input {
            margin-top: 0;
          }
          .form-check{
            margin-bottom:11px;
            &:last-of-type{
              margin-bottom: 0;
            }
          }
        }
        .amazon-ask-btn {
          .btn {
            background: #e8c347;
            border: 1px solid #e8c347;
            border-radius: 10px;
            font-weight: 700;
            font-size: 12px;
            line-height: 15px;
            /* identical to box height */

            color: #322a12;
          }
        }
      }
    }
  }
`;
const DataGatheringWrapper = styled.div`
  margin-top: 36px;
  .data-gathering-divider {
    position: relative;
    &:after {
      content: "";
      background-color: ${({ theme }) => theme["secondary-variant"]};
      width: 100%;
      position: absolute;
      right: -280px;
      height: 1px;
      top: 162px;
      z-index: -1;
    }
  }
  .data-gathering-info {
    max-width: 195px;
    margin: 0 auto;
    margin-left: 0;
    position: relative;
    &:before {
      content: "";
      background-color: ${({ theme }) => theme["secondary-variant"]};
      width: 1px;
      position: absolute;
      right: -36%;
      height: 78.5%;
      top: 34px;
      z-index: -1;
    }
    /* &:after {
      content: "";
      background-color: ${({ theme }) => theme["secondary-variant"]};
      width: 7em;
      position: absolute;
      right: -12em;
      height: 1px;
      top: 162px;
      z-index: -1;
    } */
    .data-gathering-box {
      background-color: ${({ theme }) => theme["background"]};
      padding: 24px;
      padding-left: 21px;
      padding-top: 16px;
      padding-bottom: 21px;
      text-align: center;
      border-radius: 16px;
      position: relative;
      p {
        font-size: ${({ theme }) => theme["xs-small-size"]};
        line-height: 12px;
        color: ${({ theme }) => theme["body-text"]};
        margin-bottom: 13px;
      }
      margin-bottom: 21px;
      .progress {
        height: 10px;
        width: 103px;
        margin: 0 auto;
        border-radius: 12px;
        .progress-bar {
          border-radius: 25px;
          &.bg-progress2 {
            background-color: #3a45c6;
          }
          &.bg-progress3 {
            background-color: #706eed;
          }
          &.bg-progress4 {
            background-color: #2f72b3;
          }
          &.bg-progress5 {
            background-color: #5034c0;
          }
        }
      }
      &:before {
        content: "";
        background-color: ${({ theme }) => theme["secondary-variant"]};
        width: 6em;
        position: absolute;
        right: -5em;
        height: 1px;
        top: 34px;
        z-index: -1;
      }
      &:nth-of-type(4) {
        &:before {
          top: 42px;
        }
      }
      &:last-of-type {
        &:before {
          top: 27px;
        }
      }
    }
  }
  .data-collapse-main {
    /* overflow: hidden; */
    background-color: #ffffff;
    .data-gathering-collapse {
      width: 195px;
      /* margin-right: 30px; */
      overflow: hidden;
      &.gathering-collapse-open {
        /* overflow: visible; */
        transition: over 0.3s all ease-in-out;
      }
      .data-collapse-top {
        padding: 16px 22px 24px;
        background-color: ${({ theme }) => theme["primary"]};
        text-align: center;
        border-radius: 14px;
        position: relative;
        z-index: 1;
        &.primary-variant {
          background-color: ${({ theme }) => theme["primary-variant"]};
          padding-top: 25px;
          & + .data-collapse-bottom {
            padding-top: 14px;
          }
          .data-collapse-btn {
            border-color: ${({ theme }) => theme["primary-variant"]};
          }
        }
        .CircularProgressbar {
          display: inline-block;
          width: 108px;
          height: 108px;
          margin: 0 auto;
          margin-bottom: 16px;
        }
        h3 {
          font-weight: 700;
          font-size: ${({ theme }) => theme["medium-font-siz"]};
          line-height: 17px;
          color: ${({ theme }) => theme["on-primary"]};
          margin-bottom: 12px;
        }
        h2 {
          font-weight: 700;
          font-size: 20px;
          line-height: 24px;
          color: ${({ theme }) => theme["on-primary"]};
          padding-top: 7px;
          margin-bottom: 16px;
        }
        p {
          font-weight: 400;
          font-size: 8px;
          line-height: 10px;
          color: ${({ theme }) => theme["on-primary"]};
          margin-bottom: 16px;
        }
        button {
          &.collpase-btn {
            background-color: ${({ theme }) => theme["on-primary"]};
            padding: 10px 10px;
            font-size: ${({ theme }) => theme["xs-small-size"]};
            line-height: 12px;
            height: auto;
            width: 100%;
            border-radius: 10px;
            &:focus {
              background-color: ${({ theme }) => theme["on-primary"]};
            }
          }
          margin-bottom: 0;
        }
      }
      .data-collapse-bottom {
        /* height: 164px; */
        max-width: 181px;
        background: ${({ theme }) => theme["background"]};
        border-radius: 7px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        padding-top: 16px;
        padding-bottom: 16px;
        margin: 0 auto;
        transform: translate(0%, -100%);
        transition: 0.3s all ease-in;
        &.data-collapse-open {
          transform: translate(0%, 0);
        }
        @keyframes over {
          0% {
            overflow: hidden;
          }
          100% {
            overflow: visible;
          }
        }
        .form-content {
          padding-left: 16px;
          padding-right: 16px;
          .form-check {
            padding-bottom: 9px;
            border-bottom: 1px solid
              ${({ theme }) => theme["secondary-variant"]};
            margin-bottom: 8px;
            margin-top: -6px;
            input {
            }
            .form-check-label {
              font-weight: 400;
              font-size: 7px;
              line-height: 8px;
              color: ${({ theme }) => theme["body-text-variant"]};
              position: relative;
              top: -2px;
            }
          }
          .form-group {
            margin-bottom: 8px;
            .form-label {
              margin-bottom: 6px;
              font-weight: 400;
              font-size: 7px;
              line-height: 8px;
              color: ${({ theme }) => theme["body-text-variant"]};
            }
            input {
              background: transparent;
              border-radius: 12px;
              font-size: 7px;
              line-height: 8px;
              &::-webkit-input-placeholder {
                font-size: 7px;
                line-height: 8px;
                /* Edge */
              }
              &::placeholder {
                font-size: 7px;
                line-height: 8px;
              }
            }
            select {
              background: transparent;
              border-radius: 12px;
              font-size: 7px;
              line-height: 8px;
            }
            .select2-selection__placeholder,
            .select2-selection__single-value {
              font-size: 7px !important;
            }
            .select2-selection__control {
              max-height: 18px !important;
              min-height: 18px !important;
              line-height: 8px !important;
              background: transparent;
              border-radius: 12px;
              .select2-selection__rendered {
                line-height: 8px;
                padding-left: 12px;
              }
              .select2-selection__value-container {
                padding-top: 0;
                padding-bottom: 0;
              }
              .select2-selection__input-container {
                padding-top: 0;
                padding-bottom: 0;
              }
              .select2-selection__arrow {
                height: 18px;
                width: 18px;
                right: 3px;
              }
              svg {
                height: 10px;
                width: 10px;
              }
            }
          }
          &.form-content-primary {
            .form-group {
              .form-label {
                margin-bottom: 12px;
              }
            }
            & + .form-button {
              padding-top: 9px;
            }
          }
          &.form-content-secondary {
            input {
              padding: 5px 8px;
              max-height: 18px;
            }
            .input-icon {
              height: 8px;
              width: 10px;
            }
          }
        }
      }
    }
  }
  .form-button {
    padding-top: 8px;
    padding-left: 6px;
    padding-right: 7px;
    text-align: center;
    button {
      border-radius: 10px;
      font-size: ${({ theme }) => theme["xs-small-size"]};
      line-height: 12px;
      padding: 10px 12px;
    }
  }
  .onboarding-right-section {
    margin-left: 0;
    display: block;
    max-width: 100%;
  }
  .marketplace-region {
    max-width: 195px;
  }
  .select-region {
    .region_block {
      padding: 24px;
      padding-bottom: 19px;
      &:hover {
        border: 1px solid ${({ theme }) => theme["secondary-variant"]};
        box-shadow: none;
      }
      h3 {
        font-size: ${({ theme }) => theme["medium-font-siz"]};
        line-height: 17px;
        color: ${({ theme }) => theme["primary"]};
        text-align: center;
        margin-bottom: 26px;
      }
      .round {
        width: 16px;
        margin-top: -2px;
        label {
          width: 16px;
          height: 16px;
          &:after {
            top: 4px;
            left: 3px;
          }
        }
      }
      .region-name {
        justify-content: center;
      }
      .country-region {
        gap: 10px;
        grid-column-gap: 16px;
        margin-top: 16px;
      }
      .form-button {
        padding-top: 24px;
      }
    }
  }
  .info-box-main {
    max-width: 195px;
    margin: 0 auto;
    margin-right: 0;
    margin-top: 43px;
    padding-bottom: 30px;
  }
`;

export { StoreWrapper, DataGatheringWrapper };
