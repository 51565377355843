import Styled from "styled-components";

const FulfillmentFeeWrapper = Styled.div`
 .dimensions-filter{
    border: 1px solid #E6E6E6;
    border-radius:50px;
    button{
        border-radius:50px;
        background-color:transparent ;
        border:none;
        margin-bottom:0;
        color: ${({ theme }) => theme["text-primary"]};
        font-weight:${({ theme }) => theme["normal-weight"]};
        &.active{
        background:${({theme}) => `linear-gradient(90deg, ${theme['button-secondary-color']} 0%, ${theme['primary-color']} 100%)`};
        border-radius:50px;
        font-weight:${({ theme }) => theme["bold-weight"]};
        border-top-right-radius: 50px!important;
        border-bottom-right-radius: 50px!important;
        }
    }
}
 .filter-box{
     position: absolute;
     box-shadow: 0 0 10px #ccc;
     padding: 16px;
     top:32px;
     background-color:${({ theme }) => theme['white-color']}; 
     width: 172px;
     z-index: 1001;
         h6{
             font-size:${({ theme }) => theme['medium-font-size']};  
         }
         .text-right{
             span{
                 font-size:${({ theme }) => theme['medium-font-size']};  
                 color:${({ theme }) => theme['secondary-color']};  
                 cursor: pointer;
             }
         }
 }

 .text-right{
  span{
      font-size:${({ theme }) => theme['medium-font-size']};  
      color:${({ theme }) => theme['secondary-color']};  
      cursor: pointer;
  }
}

 .error {
    position: absolute;
    left: 40%;
    top: 50%;
 }
 .table-type-data.d-flex{
        p{
            font-size:14px;
        }
    }
 .table-type-data.space-between{
   justify-content: space-between
 }
    .table-badge{
        padding:5px 12px;
        border-radius:50px;
        font-size:0.75rem;
        color:#fff;
    }
    .outlined-color{
        color:${({ theme }) => theme['text-secondary']};   
    }
    .grid-column-gap-12{
        grid-column-gap:12px;
    }
    #dateRangeButton{
        height: 32.5px;
        background-color: #fff;
        padding: 0px 17px;
        display: flex;
        align-items: center;
        grid-column-gap: 10px;
        border: 1px solid #CFD8DC;
        padding: 0.375rem 0.75rem;
        border-radius: 0.25rem;
      }
 .fZIryo {
    position: relative;
    margin-bottom: 14px !important;
 }
 .icon-color {
    color:${({theme}) => `${theme['secondary-color']}!important`};
}
.highlighted-text{
    color:${({ theme }) => `${theme['secondary-color']}!important`}; 
}
`
export { FulfillmentFeeWrapper };
