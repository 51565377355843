import React from 'react'
import HeaderSt from '../HeaderSt'
import StepperSt from './Stepper'
import { OnboardingFlowStWrapper } from './style'

const OnboardingFlowSt = () => {
  return (
    <OnboardingFlowStWrapper>
      <HeaderSt/>
      <StepperSt/>
    </OnboardingFlowStWrapper>
  )
}

export default OnboardingFlowSt
