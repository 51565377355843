import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Popover } from "antd";

import { MARKETPLACES_IMAGES } from "../../../constants";

import ArrowIcon from "../../../assets/images/arrow.svg";

import { TitleStyleWrapper } from "./style";

const Title = ({ currentStep }) => {
  const { store } = useSelector((state) => state.store);
  const [url, setUrl] = useState('')

  useEffect(() => {
    if (currentStep > 0) {
      if(store?.region === 'na') {
        setUrl(`https://www.amazon.com/sp?seller=${store?.sellerId}`);
      }

      if(store?.region === 'eu') {
        setUrl(`https://www.amazon.co.uk/sp?seller=${store?.sellerId}`);
      }
      
      if(store?.region === 'fe') {
        setUrl(`https://www.amazon.co.jp/sp?seller=${store?.sellerId}`);
      }
    }
  })

  return (
    <TitleStyleWrapper>
      <h3 className={currentStep === 0 ? "current-tile" : "complete-tile"}>
        Connect Amazon Account
      </h3>
      {currentStep === 0 ? (
        <p className="step_one_text">This authorizes us to retrieve data from your account</p>
      ) : (
        <div className="card_overlay_wrapper">
          <div className="card_overlay_item">
            <p>Storefront</p>
            <span>
                <a href={url} target="_blank">{store?.name || store?.sellerId || 'Amazon Store'}</a> <img src={ArrowIcon} />
            </span>
          </div>
          <div className="card_overlay_item">
            <p>Marketplace</p>
            <div className="card_flags_wrapper">
              {
                store?.marketplaces?.map((market, index) => (
                  index > 1 ? null:
                  <img key={index} src={MARKETPLACES_IMAGES[market.countryCode]} />
                ))
              }
              {
                store?.marketplaces?.length > 1 && (
                    <Popover
                      placement="bottom"
                      trigger="hover"
                      title={null}
                      showArrow={false}
                      content={
                        <div className="st_overlay_wrapper_tooltip">
                          {
                            store.marketplaces.map((market, index) => (
                              index < 2 ? null :
                                <img key={index} src={MARKETPLACES_IMAGES[market.countryCode]} />
                            ))
                          }
                        </div>
                      }
                    >
                      {store.marketplaces.length - 2 > 0 && (
                        <p className="circle-icon">+{store.marketplaces.length - 2}</p>
                      )}
                    </Popover>
                )
              }
              
            </div>
          </div>
        </div>
      )}
    </TitleStyleWrapper>
  );
};

export default Title;
