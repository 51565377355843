import React from "react";
import {  Table } from "react-bootstrap";
import { TableWrapper } from "./style";
const Index = (props) => {
  const { 
    tableHeader,
    tableBody, 
    viewHeight,
    className
  } = props;

  return (
    <TableWrapper className={className}>
      <div className= {`table-responsive ${viewHeight}`}>
        <Table className="table-scroll">
          <thead>{tableHeader}</thead>
          <tbody>{tableBody}</tbody>
        </Table>
      </div>
    </TableWrapper>
  );
};

export default Index;
