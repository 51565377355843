import Styled from 'styled-components';

const InvoiceWrapper = Styled.div`
    .invoice-status{
        border: 1px solid ${({ theme }) => theme["warning-color"]};
        border-radius:${({ theme }) => theme["border-radius-base"]};
        background-color:${({ theme }) => theme["warning-color"]}02;
        .badge{
            background-color:${({ theme }) => theme["success-color"]};
            color:${({ theme }) => theme["white-color"]};
            border-radius:50px;
        }
    }
    .shipping-detail{
    grid-column-gap:5px;
    .info{
        grid-row-gap:5px;
        .d-flex{
            span:nth-child(2){
                color:${({ theme }) => theme["black-color"]};
            }
            grid-column-gap:16px;   
        }
    }
}
.badge-warning{
    background-color:${({ theme }) => theme["warning-color"]};
    color:${({ theme }) => theme["white-color"]};
    border-radius:50px; 
    font-weight:${({ theme }) => theme["bold-weight"]};
    padding:2px 12px;
}
.tooltip-box{
    padding:24px;
    background-color:${({ theme }) => theme["white-color"]};
}
.error {
    position: absolute;
    left: 40%;
    top: 50%;
    h1{
      color: black;
    }
} 
`;

export { InvoiceWrapper };