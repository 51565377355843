import React, {
  useState,
  useEffect
} from "react";
import {
  useDispatch,
  useSelector
} from "react-redux";
import {
  BsArrowLeftShort,
} from "react-icons/bs";

import Pagination from "../../components/Pagination/pagination";
import Spin from "../../components/Spin/Index";
import Table from "../../components/Table/table";

import {
  CURRENCY_SYMBOLS,
  MONTHS
} from '../../constants';
import {
  GetAffialiteManagerDetail,
  SetAffiliateState
} from '../../redux/slices/affiliate-slice';

const Index = ({
  affiliateManager,
  handleClick
}) => {
  const dispatch = useDispatch();
  const {
    loading,
    success,
    total,
    affiliateManagerDetail,
    totalAmountPaid,
    totlaCommissionEarn
  } = useSelector(state => state.affiliate);
  const [invoiceBody, setInvoiceBody] = useState([]);

  const handlelimit = (e) => {
    setFilters({ ...filters, limit:e.value, pageNumber: 1, skip: 0});
  };

  const [filters, setFilters] = useState({
    limit: 25,
    skip:0,
    pageNumber: 1
  })

  useEffect(()=>{
    dispatch(GetAffialiteManagerDetail({ affiliateManagerId: affiliateManager._id, limit:filters.limit, skip: filters.limit * (filters.pageNumber-1)}))
  },[filters])

  useEffect(()=>{
    return () =>{
      dispatch(SetAffiliateState({field:'affiliateManagerDetail', value:[]}))
      dispatch(SetAffiliateState({field: 'total', value: 0}))
      dispatch(SetAffiliateState({field: 'totlaCommissionEarn', value: 0}))
      dispatch(SetAffiliateState({field: 'totalAmountPaid', value: 0}))
    }
  },[])

  const handlePageNumberChange = (e) => {
    if (e.key == "Enter") {
      if (e.target.value < 1 || !e.target.value) {
        setFilters({
          ...filters,
          pageNumber: 1,
          skip: 1 * filters.limit
        });
      } else if (e.target.value > Math.ceil(total / 25)) {
        setPageNumber(Math.ceil(total / 25))
        setFilters({
          ...filters,
          pageNumber: Math.ceil(total / 25),
          skip: Math.ceil(total / 25) * filters.limit
        })
      } else setPageNumber(e.target.value)
    }
  }

  const handlePageChangeLeft = () => {
    console.log(filters)
    if (filters.pageNumber - 1 > 0) {
      setFilters({
        ...filters,
        pageNumber: filters.pageNumber - 1,
        skip: filters.limit * (filters.pageNumber - 2)
      })
    }
  }

  const handlePageChangeRight = () => {
    const totalPages = Math.ceil(total / filters.limit)
    if (filters.pageNumber + 1 <= totalPages) {
      setFilters({
        ...filters,
        skip: (filters.pageNumber) * filters.limit,
        pageNumber: filters.pageNumber + 1,
      })
    }
  }

  useEffect(() => {
    if (success && affiliateManagerDetail.length > 0) {
      const affiliateManagerDetailBody =
        affiliateManagerDetail.map((item, i) => {
          console.log(item)

            return (
              <tr key={i} style={item.refunded ? { backgroundColor: '#FFFDF2' } : null}>
                <td>{`${MONTHS[item.month]} - ${item.year}`}</td>
                <td>{item.currencyCode? CURRENCY_SYMBOLS[item.currencyCode.toUpperCase()]: ""}{Number(item.commissionEarn).toFixed(2)}</td>
                <td>{item.currencyCode? CURRENCY_SYMBOLS[item.currencyCode.toUpperCase()]: "" }{Number(item.amountPaid).toFixed(2)}</td>
              </tr>
            );
          });
      setInvoiceBody(affiliateManagerDetailBody);
    } else setInvoiceBody([]);
  }, [affiliateManagerDetail, filters.pageNumber]);

  return (
    <>
      <div className="d-flex align-items-center grid-column-gap-10">
      </div>
      <div className="p-4">
        <div className="d-flex align-items-center grid-column-gap-10">
          <BsArrowLeftShort
            onClick={() => { handleClick() }}
            className="cursor-pointer icon-color"
          />
          <h3 className="mb-0">Affiliate Manager Detail</h3>
        </div>
        <div className="mt-4 invoice-status p-3 d-flex justify-content-between">
          <div className="d-flex flex-column">
            <span className="text-primary weight-bold fs-10">
              Affiliate Manager Email: <span className="color-black">{affiliateManager.email}</span>
            </span>
            <span className="text-primary weight-bold fs-10">
              Affiliate Manager Name: <span className="color-black">{affiliateManager.name}</span>
            </span></div>
          <div className="d-flex flex-column">
            <span className="text-primary weight-bold fs-12">Total Commission Earned</span>
            <span className="fs-14 d-block text-primary">  {CURRENCY_SYMBOLS[affiliateManager.currency] + totlaCommissionEarn.toFixed(2)} </span>
          </div>
          <div className="d-flex flex-column">
            <span className="text-primary weight-bold fs-12">Total Amount Paid by User</span>
            <span className="fs-14 d-block text-primary">  {CURRENCY_SYMBOLS[affiliateManager.currency] + totalAmountPaid.toFixed(2)} </span>
          </div>
        </div>
        <div className="pt-4">
          <Table
            viewHeight="case-stat-table"
            tableHeader={
              <tr>
                <th>Date</th>
                <th>Commission Earned</th>
                <th>Commision Paid by Users</th>
              </tr>
            }
            tableBody={
              <>
                {(!loading && !affiliateManagerDetail.length && success) ? (
                  <tr><td>
                    <div className='error'>
                      <h1>No Data Available</h1>
                    </div>
                  </td>
                  </tr>
                ) : loading ? <tr><td><Spin /></td></tr> : invoiceBody}
              </>
            }
          />
          <Pagination
             pageLimit={filters.limit}
             total={total}
             pageNumber={filters.pageNumber}
             totalPages={Math.ceil(total / filters.limit)}
            handlePageChangeLeft={handlePageChangeLeft}
            handlePageChangeRight={handlePageChangeRight}
            handleLimitChange={handlelimit}
            handlePageNumberChange={handlePageNumberChange}
          />
        </div>
      </div>

    </>
  );
};

export default Index;

