import styled from "styled-components";
const AffiliateDashboardWrapper = styled.div`
  .page-info-wrapper {
    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #353e44;
      span {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #4438f9;
      }
    }
  }
  .arrow-box{
    display: flex;
    align-items:center;
      svg{
         color: ${({ theme }) => theme['primary-color']};
      }
  }
  .page-header {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    .buttons-wrapper {
      display: flex;
      grid-gap: 16px;
      button {
        &:nth-child(1) {
          background: linear-gradient(
            270deg,
            #0985ec 0%,
            #4438f9 53.41%,
            #4438f9 99.25%,
            #5448fb 99.26%
          );
          border-radius: 10px;
          grid-gap: 8px;
          display: flex;
          align-items: center;
        }
        &:nth-child(2) {
          background: #52ad93;
          border-radius: 10px;
          border: none;
        }
        &:nth-child(3) {
          background: #283085;
          border-radius: 10px;
          grid-gap: 8px;
          display: flex;
          align-items: center;
          border: none;
        }
      }
    }
  }
  .page-header-receipts {
    display: flex;
    justify-content: space-between;
    .buttons-wrapper {
      display: flex;
      grid-gap: 16px;
      button {
        &:nth-child(1) {
          background: linear-gradient(
            270deg,
            #0985ec 0%,
            #4438f9 53.41%,
            #4438f9 99.25%,
            #5448fb 99.26%
          );
          border-radius: 10px;
          grid-gap: 8px;
          display: flex;
          align-items: center;
        }
        &:nth-child(2) {
          background: #52ad93;
          border-radius: 10px;
          border: none;
        }
        &:nth-child(3) {
          background: #283085;
          border-radius: 10px;
          grid-gap: 8px;
          display: flex;
          align-items: center;
          border: none;
        }
      }
    }
  }
  .table-responsive .table-fd-wrapper .table-responsive {
    overflow: visible;
}
  .table-fd-wrapper {
    height: calc(100vh - 188px);
    overflow: auto;
    position: relative;
    table {
      margin-top: 24px !important;
      thead {
      }
      tbody {
        .error.error-wrapper {
          position: absolute;
          top: 55%;
          left: 40%;
        }
        tr {
          td.table-active-batch {
            background: #009c34;
            color: #fff !important;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0px !important;
            width: 68px;
            height: 20px;
            border-radius: 32px;
            font-size: 12px !important;
            margin-top: 12px;
          }
          td.table-inactive-batch {
            background: #f31819;
            color: #fff !important;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0px !important;
            width: 68px;
            height: 20px;
            border-radius: 32px;
            font-size: 12px !important;
            margin-top: 12px;
          }
        }
      }
    }
  }
`;
export { AffiliateDashboardWrapper };
