import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { extend } from 'lodash';

import Header from "../Header/header";
import Button from "../../components/Button/index";
import { StoreWrapper } from "./style";
import Table from "react-bootstrap/Table";
import LabelInput from "../../components/Inputs/label-input";
import Form from "react-bootstrap/Form";
import Spin from "../../components/Spin/Index";

import { APP_DOMAINS, MANAGER_NAMES, SELLER_CENTRAL_EMAILS } from "../../constants";

import { 
  GetLatestAmazonAccountDetail,
  StartEcsPermissionTask
} from '../../redux/slices/account-detail-slice';
import { SetAuthState } from "../../redux/slices/auth-slice";
import { GetStore } from '../../redux/slices/store-slice';

import { AGENCIES_EMAIL } from '../../constants';

const hostName = window.location.hostname;

const sellerCentralEmail = SELLER_CENTRAL_EMAILS;

const naUrl = "https://sellercentral.amazon.com/gp/account-manager/home.html/ref=xx_userperms_dnav_xx";
const euUrl = "https://sellercentral.amazon.co.uk/gp/account-manager/home.html/ref=xx_userperms_dnav_xx";
const feUrl = 'https://sellercentral-japan.amazon.com/gp/account-manager/home.html/ref=xx_userperms_dnav_xx';

const AddPermission = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [showDone, setShowDone] = useState(false)

  const { user, showSidebar } = useSelector((state) => state.auth);
  const {
    latestAmazonAccountDetail,
    ecsPrmissionTaskStart,
    loading
  } = useSelector((state) => state.accountDetail);
  const { storeId, store } = useSelector((state) => state.store);

  useEffect(() => {
    if (storeId) {
      console.log("In StoreId useEffect");
      dispatch(GetStore({ storeId }));
    }
  }, [storeId]);

  useEffect(() => {
    if (ecsPrmissionTaskStart) {
      history.push({
        pathname: '/waiting-screen',
        state: {
          step: 'Step 2',
          content: 'We  are check your permissions',
          nextStep: 'Store success page'
        }
      });
    }
  }, [ecsPrmissionTaskStart]);

  useEffect(() => {
    dispatch(GetStore({ storeId }));
    dispatch(GetLatestAmazonAccountDetail());
  }, []);

  useEffect(() => {
    if (hostName === APP_DOMAINS.sellerRepay) {
      extend(sellerCentralEmail, { [hostName]: latestAmazonAccountDetail });
    }
  }, [latestAmazonAccountDetail]);

  useEffect(() => {
    if (store) {
      const { status } = store;
      dispatch(SetAuthState({ field: "storeStatus", value: status }));
      if (status === "Connected") {
        history.push("/store-success");
      }
    }
  }, [store]);

  const handleAddPermissionClick = () => {
    const { region } = store;

    if (region === 'na') {
      window.open(naUrl, "_blank");
    } else if (region === 'eu') {
      window.open(euUrl, "_blank");
    } else if (region === 'fe') {
      window.open(feUrl, "_blank");
    }
  };

  const handlePermission = () => {
    history.push({
      pathname: '/waiting-screen',
      state: {
        step: 'Step 2',
        content: 'check your permissions',
        nextStep: 'Store success page'
      }
    });
    dispatch(StartEcsPermissionTask({ storeId: store._id }));
  };

  return (
    <>
      {showSidebar ? null : <Header />}
      <StoreWrapper>
        <Row>
          <Col lg={{ span: showSidebar ? 7: 6, offset: showSidebar ? 0 : 1 }} sm={7} className="">
            <div className="permission-scroll">
              <div className="justify-content-end section-heading">
                <h2>Step 2: Add Permissions</h2>
              </div>
              <div className="add-details d-flex">
                <div>
                  <Button
                    onClick={handleAddPermissionClick}
                    width="148px"
                    text="Add Permissions"
                    className="outlined"
                  />
                </div>
                <p>
                  or Sign into your Seller Central Account click{" "}
                  <strong>'Settings’</strong> Tab, then{" "}
                  <strong>'User Permissions’</strong>{" "}
                </p>
              </div>
              <div className="caption">
                Locate ‘Seller Repay Manager’ & click ‘Manage Permissions’ button
              </div>
              <div className="table-region-main">
                <Table className="table-region">
                  <thead>
                    <tr>
                      <th width="178px">Name</th>
                      <th width="193px">Email Address</th>
                      <th>&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <LabelInput
                          name="name"
                          type="text"
                          value={MANAGER_NAMES[hostName]}
                          placeholder="Seller Repay Manager"
                        />
                      </td>
                      <td>
                        <LabelInput
                          name="email"
                          type="email"
                          value={sellerCentralEmail[hostName]}
                          placeholder={sellerCentralEmail[hostName]}
                        />
                      </td>
                      <td>
                        <ul className="actions">
                          <li>
                            <Button
                              width="130px"
                              text="Manage Permissions"
                              className="outlined invite-btn"
                            />
                          </li>
                          <li>
                            <Button
                              width="140px"
                              text="Add to Global Account"
                              className="outlined invite-btn"
                            />
                          </li>
                          <li>
                            <Button
                              width="56px"
                              text="Delete"
                              className="outlined invite-btn"
                            />
                          </li>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              <div className="amazon-ask-info">
              <div className="justify-content-end section-heading">
                  <h2>If Amazon Asks this Question, Please Select ‘No’</h2>
                </div>
                <div className="amazon-ask-content">
                  <p>Do you want to allow the secondary user in Seller Central to handle return requests and/or issue refunds?</p>
                  <div className="amazon-ask-bottom">
                    <div className="amazon-ask-options">
                    <Form.Check
                    label="Yes"
                    type="radio"
                    id="radio-ask-yes"
                    name="radio-ask"
                    checked={false}
                    />
                    <Form.Check
                    label="No"
                    type="radio"
                    id="radio-ask-no"
                    name="radio-ask"
                    checked={true}
                    />
                    </div>
                    <div className="amazon-ask-btn">
                    <Button
                      className="btn"
                      text="Save and continue"
                    />
                    </div>
                  </div>
                </div>
              </div>
              <div className="permission-table">
                <div className="justify-content-end section-heading">
                  <h2>Add the Following Permissions:</h2>
                </div>
                <Table striped className="table-region table-permissions">
                  <thead>
                    <tr>
                      <th width="299px"></th>
                      <th width="109px">
                        <Button
                          width="58px"
                          text="None"
                          className="outlined invite-btn"
                        />
                      </th>
                      <th width="109px">
                        <Button
                          width="56px"
                          text="View"
                          className="outlined invite-btn"
                        />
                      </th>
                      <th width="105px">
                        <Button
                          width="90px"
                          text="View & Edit"
                          className="outlined invite-btn"
                        />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div className="permission-table-name">
                          <p>Manage FBA Inventory/Shipments</p>
                        </div>
                      </td>
                      <td>
                        <Form.Check
                          type="radio"
                          id="radio-1"
                          checked={false}
                        />
                      </td>
                      <td>
                        <Form.Check
                          type="radio"
                          id="radio-1"
                          checked={false}
                        />
                      </td>
                      <td>
                        <Form.Check
                          type="radio"
                          id="radio-1"
                          checked={true}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="permission-table-name">
                          <p>Selling Partner Appstore</p>
                          <small>
                            A directory of internal and external selling
                            <br /> tools to manage and grow your business
                          </small>
                        </div>
                      </td>
                      <td>
                        <Form.Check
                          type="radio"
                          id="radio-2"
                          checked={false}
                        />
                      </td>
                      <td>
                        <Form.Check
                          type="radio"
                          id="radio-2"
                          checked={false}
                        />
                      </td>
                      <td>
                        <Form.Check
                          type="radio"
                          id="radio-2"
                          checked={true}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="permission-table-name">
                          <p>Fulfillment Reports</p>
                        </div>
                      </td>
                      <td>
                        <Form.Check
                          type="radio"
                          aria-label="radio 1"
                          checked={false}
                        />
                      </td>
                      <td>
                        <Form.Check
                          type="radio"
                          aria-label="radio 1"
                          checked={true}
                        />
                      </td>
                      <td>
                        <Form.Check
                          type="radio"
                          aria-label="radio 1"
                          checked={false}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="permission-table-name">
                          <p>Manage Your Cases</p>
                          <small>
                            Enables User to create, view & reply to
                            <br /> all support cases on your Selling Account
                          </small>
                        </div>
                      </td>
                      <td>
                        <Form.Check
                          type="radio"
                          checked={false}
                        />
                      </td>
                      <td>
                        <Form.Check
                          type="radio"
                          checked={false}
                        />
                      </td>
                      <td>
                        <Form.Check
                          type="radio"
                          checked={true}
                        />
                      </td>
                    </tr>
                    {/* <tr>
                      <td>
                        <div className="permission-table-name">
                          <p>Payments</p>
                        </div>
                      </td>
                      <td>
                        <Form.Check
                          type="radio"
                          aria-label="radio 1"
                        />
                      </td>
                      <td>
                        <Form.Check
                          type="radio"
                          aria-label="radio 1"
                        />
                      </td>
                      <td>
                        <Form.Check
                          type="radio"
                          aria-label="radio 1"
                        />
                      </td>
                    </tr> */}
                    {/* <tr>
                      <td>
                        <div className="permission-table-name">
                          <p>Payments</p>
                          <small>
                            Enables access to the Payments report
                            <br /> visibility of the home page Payments Summary.
                          </small>
                        </div>
                      </td>
                      <td>
                        <Form.Check
                          type="radio"
                          aria-label="radio 1"
                        />
                      </td>
                      <td>
                        <Form.Check
                          type="radio"
                          aria-label="radio 1"
                        />
                      </td>
                      <td>
                        <Form.Check
                          type="radio"
                          name="fba"
                          aria-label="radio 1"
                        />
                      </td>
                    </tr> */}

                  </tbody>
                </Table>
              </div>
              <div className="permission-footer d-flex align-items-center justify-content-between">
                <div>
                  <p className="region-note">
                    Click the ‘Continue’ button to save the permissions:
                  </p>
                </div>
                <div className="amazon-ask-info-continue">
                <div className="amazon-ask-content">
                  <div className="amazon-ask-bottom">
                    <div className="amazon-ask-btn">
                    <Button
                      className="btn"
                      text="Continue"
                    />
                    </div>
                  </div>
                </div>
              </div>
              </div>
            </div>
            <div className="activate-button">
              <p className="region-note">
                Once you have given permissions, please click below to{" "}
                <strong>activate your account:</strong>{" "}
              </p>
              <div className="add-button-space text-right">
                <Button
                  className="outlined btn"
                  text="Activate My Account"
                  onClick={handlePermission}
                />
              </div>
            </div>
          </Col>
          <Col sm={5}>
            <div className={showSidebar ? null : 'without-layout-sidebar-wrapper'}>
            <div className= { showSidebar ? "permission-right" : 'onboarding-space-right permission-right'} >
              <div className="onboarding-right-section">
                <div className="info-box-main">
                  {showDone && (
                    <div className="info-block success-block text-center ">
                      <p>
                        We will contact you shortly with the relevant Documentation.{" "}
                      </p>
                      <Button text="Done!" />
                    </div>
                  )}
                  {store.region === 'eu' && !showDone ?
                    <div className="info-block text-center">
                      <p>Has Amazon asked you for additional information?</p>
                      <Button
                        text="Request Info"
                        onClick={() => setShowDone(!showDone)}
                      />
                    </div> : ''}
                  <div className="info-block text-center">
                    <h3>Have a Question?</h3>
                    <p>Contact your case manager:</p>
                    <a href={`mailto:${AGENCIES_EMAIL[user.agencyId]}`}>
                      <Button text="Contact" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </Col>
        </Row>

        {loading && (
          <Spin />
        )}
      </StoreWrapper>
    </>
  );
};

export default AddPermission;
