import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Spin } from 'antd';

import Otp from '../../components/OtpInput';
import Button from '../../components/ButtonSt'
import Notification from '../../components/notifications/notification';

import { APP_LOGOS, APP_TITLES } from '../../constants';

import { MailVerificationWrapper } from './style';

import { SetAuthState, VerifyUser } from '../../redux/slices/auth-slice';


const hostName = window.location.hostname;
const MailVerification = () => {
  const { error, loading, message } = useSelector(state => state.auth);

  const history = useHistory();
  const dispatch = useDispatch();

  const [otp, setOtp] = useState('');
  const [disabled, setDisabled] = useState(true);

  const verifyUser = () => {
    dispatch(VerifyUser({ verificationCode: otp }));
  };

  const handleOtp = (e) => {
    setOtp(e);
    if (!e || e.length < 6) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  };

  useEffect(() => {
    if (error) {
      Notification({
        type: 'error',
        title: error
      });
      setOtp('');
      dispatch(SetAuthState({ field: 'error', value: '' }));
    } else if (message) {
      Notification({
        type: 'success',
        title: message
      });
      dispatch(SetAuthState({ field: 'message', value: '' }));
      history.push('/auth/sign-in');
      return;
    }
  }, [message, error]);

  return (
    <MailVerificationWrapper>
      <div className='verification-screen-wrapper'>
        <div className='left-section'>
          {APP_LOGOS[hostName] ? (
            <img src={APP_LOGOS[hostName]} alt='no-logo' />
          ) : (
            <h2>{APP_TITLES[hostName]}</h2>
          )}
          <p className='logo-description mb-0'>
            Don’t have an account? Create one. It takes less than a minuite!
          </p>
        </div>
        {loading ? <div className='d-flex position-absolute justify-content-center  loader-wrapper'>
          <Spin className='centered-spin' size='large' />
        </div> : null}
        <div className='right-content'>
          <h2>Email Verification</h2>
          <p>Please enter the 6-digit verification code that was sent to your email.</p>
          <Otp
            value={otp}
            onChange={handleOtp}
            numInputs={6}
            renderInput={(props) => <input type='number' {...props} />}
            className='opt-inputs-wrapper'
            containerStyle={{
              display: 'flex',
              gridGap: 16,
              marginBottom: 24
            }} />
          <div className='right-content-footer'>
            <Button className={disabled ? 'disabled-button': ''} onClick={disabled ? null: verifyUser}>Continue</Button>
            <p>Don’t receive an email? <Link to='/auth/resend-verification-email' className='resend-button'>Resend</Link></p>
          </div>
        </div>
      </div>
    </MailVerificationWrapper>
  );
};

export default MailVerification;
