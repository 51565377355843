import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import crypto from 'crypto';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../components/Button';
import SearchInput from '../../components/Inputs/label-input/index'
import Notification from '../../components/notifications/notification'
import Spin from "../../components/Spin/Index";
import Header from '../Header/header';

import US from '../../assets/images/newImages/flags/US.svg';
import CA from '../../assets/images/newImages/flags/CA.svg';
import MX from '../../assets/images/newImages/flags/MX.svg';
import BR from '../../assets/images/newImages/flags/BR.svg';
import EG from '../../assets/images/newImages/flags/EG.svg';
import FR from '../../assets/images/newImages/flags/FR.svg';
import GB from '../../assets/images/newImages/flags/GB.svg';
import IN from '../../assets/images/newImages/flags/IN.svg';
import IT from '../../assets/images/newImages/flags/IT.svg';
import NL from '../../assets/images/newImages/flags/NL.svg';
import PL from '../../assets/images/newImages/flags/PL.svg';
import SE from '../../assets/images/newImages/flags/SE.svg';
import SA from '../../assets/images/newImages/flags/SA.svg';
import TN from '../../assets/images/newImages/flags/TN.svg';
import SG from '../../assets/images/newImages/flags/SG.svg';
import AU from '../../assets/images/newImages/flags/AU.svg';
import JP from '../../assets/images/newImages/flags/JP.svg';
import AE from '../../assets/images/newImages/flags/AE.svg';
import DE from '../../assets/images/newImages/flags/DE.svg';
import ES from '../../assets/images/newImages/flags/ES.svg';
import UK from '../../assets/images/newImages/flags/UK.svg';
import BE from '../../assets/images/newImages/flags/BE.svg';
import TR from '../../assets/images/newImages/flags/TR.svg';

import { StoreWrapper } from './style';

// import SellerRepayLogo from "../../assets/img/seller-repay-logo.svg";

import { SetStoreState, GetSPAPIToken } from '../../redux/slices/store-slice';
import { SetAuthState } from '../../redux/slices/auth-slice';

import { APP_IDS, MARKETPLACE_TO_REGEION_MAPPING, REDIRECT_URIS } from '../../constants';

const northAmerica = [
  US, CA, MX, BR
];

const europe1 = [
  GB, ES, FR, BE, NL, DE, IT, SE, PL,
]

const europe2 = [
  TR,
];
const europe3 = [
  SA,
];
const europe4 = [
  AE,
];
const europe5 = [
  IN,
];
const europe6 = [
  EG,
];

const east1 = [
  JP,
];
const east2 = [
  AU,
];
const east3 = [
  SG,
];

const SelectRegion = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const hostName = window.location.hostname;
  const [mapRegionSelect, setMapRegionSelect] = useState('na');
  const [storeName, setStoreName] = useState('');

  const {
    amazonStoreInfo: {
      storeName: name,
      region,
      state
    },
    storeSuccess,
    store,
    error,
    loading
  } = useSelector((state) => state.store);
  const { showSidebar, storesCount } = useSelector((state) => state.auth);

  useEffect(() => {
    console.log('name', name);
    if (name) {
      setStoreName(name)
    }

    if (location.pathname === '/auth/sp-api') {
      const params = new URLSearchParams(history.location.search);
      const authCode = params.get('spapi_oauth_code');
      const sellerId = params.get('selling_partner_id');
      const spApiState = params.get('state');
      console.log({
        authCode,
        sellerId,
        spApiState,
        state
      });

      if (sellerId && spApiState === state) {
        dispatch(GetSPAPIToken({
          authCode: authCode,
          sellerId: sellerId,
          region,
          storeName: name,
        }));
      } else {
        dispatch(SetStoreState({ field: 'error', value: 'Error in store creation' }))
      }
    }
  }, []);

  useEffect(() => {
    if (error) {
      Notification({
        type: 'error',
        title: 'Marketplace Connection Failed',
        description: error
      })
      dispatch(SetStoreState({ field: 'error', value: '' }))
      history.push('/select-region');
    }
  }, [error]);

  useEffect(() => {
    if (storeSuccess && store) {
      dispatch(SetAuthState({ field: 'storesCount', value: storesCount + 1 }));
      dispatch(SetAuthState({ field: 'storeStatus', value: store.status }));
      dispatch(SetStoreState({ field: 'storeId', value: store._id }));
      dispatch(SetStoreState({ field: 'storeSuccess', value: false }));
      history.push('/invite-user')
    }
  }, [storeSuccess]);

  const handleRegisterAccount = async () => {
    if (!storeName) {
      dispatch(SetStoreState({ field: 'error', value: 'Store Name is required' }));
    } else {
      const state = crypto.randomBytes(20).toString("hex");

      const region = MARKETPLACE_TO_REGEION_MAPPING[mapRegionSelect];
      const payload = {
        storeName,
        state,
        region
      };

      dispatch(SetStoreState({ field: 'amazonStoreInfo', value: payload }));
      let url = `https://sellercentral.amazon.com/apps/authorize/consent?application_id=${APP_IDS[hostName]}&state=${state}&version=beta&redirect_uri=${REDIRECT_URIS[hostName]}`;

      if (region === 'eu') {
        url = `https://sellercentral-europe.amazon.com/apps/authorize/consent?application_id=${APP_IDS[hostName]}&state=${state}&version=beta&redirect_uri=${REDIRECT_URIS[hostName]}`;
      }

      if (region === 'fe') {
        url = `https://sellercentral-japan.amazon.com/apps/authorize/consent?application_id=${APP_IDS[hostName]}&state=${state}&version=beta&redirect_uri=${REDIRECT_URIS[hostName]}`;
      }

      window.open(url, "_self");
    }
  };

  return (
    <>
      {showSidebar ? null : <Header />}
      <StoreWrapper className={showSidebar ? "dashboard-store" : ""}>
        <Row
          className={loading ? 'visibility' : null}
        >

          <Col sm={12}>
            <div className={showSidebar ? null : ""}>
              <div className={showSidebar ? "select-region m-0 p-0" : "select-region"}>
                <div className='info-tooltip'>
                  <span>!</span>
                  Please ensure you<br /> are signed-in to the<br />
                  <strong> seller central account<br /> Region </strong>
                  you wish<br /> to link, before<br /> proceeding
                </div>
                <Row className={showSidebar ? "justify-content-start" : "justify-content-md-end"}>
                  <Col sm={showSidebar ? "12" : "11"}>
                    <div className="search-box text-center search-input">
                      <Row className="">
                        <Col lg={showSidebar ? "5" : "4"}>
                          <SearchInput
                            value={storeName}
                            placeholder="Type Storename Here"
                            onChange={(e) => setStoreName(e.target.value)}
                          />
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
                <Row className={showSidebar ? "justify-content-center region-scroll" : "justify-content-center region-scroll-store"}>
                  <Col lg={12} md={12}>
                    <div className="marketplace-region">
                      <Row className={showSidebar ? 'justify-content-start' : 'justify-content-end section-heading'}>
                        <Col md={showSidebar ? "12" : "11"}>
                          <h2>Marketplace Region</h2>
                          <div className="caption">Select from following</div>
                        </Col>
                      </Row>
                      <div className="region">
                        <Row className={!showSidebar ? 'justify-content-start' : 'justify-content-end'}>
                          {!showSidebar && (<Col md={1}></Col>)}
                          <Col md={showSidebar ? "3" : "2"}>
                            <div className="region_block" onClick={() => {
                              setMapRegionSelect('na')
                            }}>
                              <div className='region-block-content'>
                                <h3>North America Region</h3>
                                <div className='region-block-groups'>
                                  <div className='region-block-group'>
                                    <div className="d-flex align-items-center region-name">
                                      <div className="round">
                                        <input type="checkbox" checked={mapRegionSelect === 'na'} onChange={() => setMapRegionSelect("na")} key="checkbox" />
                                        <label htmlFor="checkbox"></label>
                                      </div>
                                      <span>NA Region</span>
                                    </div>
                                    <div className="d-flex flex-wrap country-region ">
                                      {northAmerica.map((country, index) => <img src={country} key={index} />)}
                                    </div>
                                  </div>
                                </div>
                              </div>

                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="region_block" >
                              <div className='region-block-content'>
                                <h3>Europe Region</h3>
                                <div className='region-block-groups'>
                                  <div className='region-block-group'>
                                    <div className="d-flex align-items-center region-name">
                                      <div className="round" onClick={() => setMapRegionSelect('eu')} >
                                        <input type="checkbox" checked={mapRegionSelect === 'eu'} key="euRegionCheckbox" />
                                        <label htmlFor="euRegionCheckbox"></label>
                                      </div>
                                      <span>EU Region</span>
                                    </div>
                                    <div className="d-flex flex-wrap country-region ">
                                      {europe1.map((country, index) => <img src={country} alt="no-country" key={index} />)}
                                    </div>
                                  </div>
                                  <div className='region-group-inline d-flex'>
                                    <div className='region-block-group'>
                                      <div className="d-flex align-items-center region-name">
                                        <div className="round" onClick={() => setMapRegionSelect('tr')}>
                                          <input type="checkbox" checked={mapRegionSelect === 'tr'} onClick={() => handleCheckBox('eu', 'TR')} key="TRCheckbox" />
                                          <label htmlFor="TRCheckbox"></label>
                                        </div>
                                        <span>TR</span>
                                      </div>
                                      <div className="d-flex flex-wrap country-region ">
                                        {europe2.map((country, index) => <img src={country} alt="no-country" key={index} />)}
                                      </div>
                                    </div>
                                    <div className='region-block-group'>
                                      <div className="d-flex align-items-center region-name">
                                        <div className="round" onClick={() => setMapRegionSelect('sa')}>
                                          <input type="checkbox" checked={mapRegionSelect === 'sa'} onClick={() => handleCheckBox('eu', 'SA')} key="SACheckbox" />
                                          <label htmlFor="SACheckbox"></label>
                                        </div>
                                        <span>SA</span>
                                      </div>
                                      <div className="d-flex flex-wrap country-region ">
                                        {europe3.map((country, index) => <img src={country} alt="no-country" key={index} />)}
                                      </div>
                                    </div>
                                    <div className='region-block-group'>
                                      <div className="d-flex align-items-center region-name">
                                        <div className="round" onClick={() => setMapRegionSelect('ae')}>
                                          <input type="checkbox" checked={mapRegionSelect === 'ae'} onClick={() => handleCheckBox('eu', 'AE')} key="AECheckbox" />
                                          <label htmlFor="AECheckbox"></label>
                                        </div>
                                        <span>AE</span>
                                      </div>
                                      <div className="d-flex flex-wrap country-region ">
                                        {europe4.map((country, index) => <img src={country} alt="no-country" key={index} />)}
                                      </div>
                                    </div>
                                    <div className='region-block-group'>
                                      <div className="d-flex align-items-center region-name">
                                        <div className="round" onClick={() => setMapRegionSelect('in')}>
                                          <input type="checkbox" checked={mapRegionSelect === 'in'} onClick={() => handleCheckBox('eu', 'IN')} key="INCheckbox" />
                                          <label htmlFor="INCheckbox"></label>
                                        </div>
                                        <span>IN</span>
                                      </div>
                                      <div className="d-flex flex-wrap country-region ">
                                        {europe5.map((country, index) => <img src={country} alt="no-country" key={index} />)}
                                      </div>
                                    </div>
                                    <div className='region-block-group'>
                                      <div className="d-flex align-items-center region-name">
                                        <div className="round" onClick={() => setMapRegionSelect('eg')}>
                                          <input type="checkbox" checked={mapRegionSelect === 'eg'} onClick={() => handleCheckBox('eu', 'EG')} key="EGCheckbox" />
                                          <label htmlFor="EGCheckbox"></label>
                                        </div>
                                        <span>EG</span>
                                      </div>
                                      <div className="d-flex flex-wrap country-region ">
                                        {europe6.map((country, index) => <img src={country} alt="no-country" key={index} />)}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                            </div>
                          </Col>
                          <Col md={3}>
                            <div className="region_block">
                              <div className='region-block-content'>
                                <h3>Far East Region</h3>
                                <div className='region-block-groups'>
                                  <div className='region-group-inline d-flex'>
                                    <div className='region-block-group'>
                                      <div className="d-flex align-items-center region-name">
                                        <div className="round" onClick={() => setMapRegionSelect('jp')}>
                                          <input type="checkbox" checked={mapRegionSelect === 'jp'} />
                                          <label htmlFor="checkbox2"></label>
                                        </div>
                                        <span>JP</span>
                                      </div>
                                      <div className="d-flex flex-wrap country-region ">
                                        {east1.map((country, index) => <img src={country} alt="no-country" key={index} />)}
                                      </div>
                                    </div>
                                    <div className='region-block-group'>
                                      <div className="d-flex align-items-center region-name">
                                        <div className="round" onClick={() => setMapRegionSelect('au')}>
                                          <input type="checkbox" checked={mapRegionSelect === 'au'} />
                                          <label htmlFor="checkbox2"></label>
                                        </div>
                                        <span>AU</span>
                                      </div>
                                      <div className="d-flex flex-wrap country-region ">
                                        {east2.map((country, index) => <img src={country} alt="no-country" key={index} />)}
                                      </div>
                                    </div>
                                    <div className='region-block-group'>
                                      <div className="d-flex align-items-center region-name">
                                        <div className="round" onClick={() => setMapRegionSelect('sg')}>
                                          <input type="checkbox" checked={mapRegionSelect === 'sg'} />
                                          <label htmlFor="checkbox2"></label>
                                        </div>
                                        <span>SG</span>
                                      </div>
                                      <div className="d-flex flex-wrap country-region ">
                                        {east3.map((country, index) => <img src={country} alt="no-country" key={index} />)}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Col>

                          <Col md={11}>
                            <div className='add-button-space text-right'>
                              <Button
                                className="outlined"
                                text={showSidebar ? "Add Region " : "Continue"}
                                onClick={() => handleRegisterAccount()}
                              />

                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Col>

                </Row>
              </div>
            </div>
          </Col>
        </Row>

        {loading && (
          <Spin />
        )}

      </StoreWrapper>
    </>
  )
}

export default SelectRegion;
