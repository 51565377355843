import React, { useState } from "react";
import { Button, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";

import ButtonSt from "../ButtonSt";

import RestartIcon from "../../assets/images/restart_icon.svg";
import ResetBlueIcon from "../../assets/images/reset-blue.svg";
import RestartIconColor from "../../assets/images/restart_icon_1.svg";
import RestartIconColorSp from "../../assets/images/restart-icon-sp.svg";

import { APP_DOMAINS } from '../../constants';

import { ClearStates } from "../../helpers/clear-store-states";

import { RestartButtonWrapper } from "./style";


const RestartButton = ({ setStep, ...rest }) => {
  const host = window.location.hostname;
  const dispatch = useDispatch();
  const [onMouseOver, setOnMouseOver] = useState();
  const [reset, setReset] = useState();

  const clearStates = () =>{
    ClearStates({dispatch, fromApp: false});
    setReset(false);
    setStep(0);
  }

  return (
    <RestartButtonWrapper>
      <Button
        onMouseEnter={() => setOnMouseOver(true)}
        onMouseLeave={() => setOnMouseOver(false)}
        onClick={() => setReset(true)}
        {...rest}
        className={onMouseOver ? "hovered" : "not-hovered"}
      >
        <img src={!onMouseOver ? RestartIcon : host === APP_DOMAINS.sellerTerminal ? RestartIconColor :  RestartIconColorSp } />
        <span>Restart the Process</span>
      </Button>
      <Modal
        footer={null}
        centered
        onOk={() => setPaymentLater(false)}
        closeIcon={false}
        closable={false}
        okText="Proceed"
        width={400}
        open={reset}
        className="processed-payment-modal"
      >
        <RestartButtonWrapper>
          <div className="box-wrapper">
            <img src={ResetBlueIcon} />
            <h6>Restart Process</h6>
            <p>Are you sure you want to reset the onboarding process?</p>{" "}
            <div className="button-footer-ui">
              <ButtonSt outline onClick={() => setReset(false)}>
                Cancel
              </ButtonSt>
              <ButtonSt onClick={clearStates}>Confirm</ButtonSt>
            </div>
          </div>
        </RestartButtonWrapper>
      </Modal>
    </RestartButtonWrapper>
  );
};

export default RestartButton;
