import Styled from "styled-components";
const Div = Styled.div`
`;
const Content = Styled.div`
  padding:40px 24px;
  width:100%;
  padding-bottom:0;
  position:relative;
  overflow: hidden;
  .collapsed-none{
    transition: all .2s;
  }
  .alerts-sr-overlay-wrapper {
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 2;
    left: 10%;
    right: 10%;
    margin: auto;
    top: 4px;
}
`;

export {Content };
