import React from "react";
import Copy from "../../assets/img/copy-icon-st.svg"
import EyeIcon from "../../assets/img/eye-icon.svg"

import { ButtonWrapper } from "./style";

const Index = (props) => {
  const { variant, type, className, text, onClick, disabled, value, style, width, CopyIcon, Eye } = props;

  return (
    <ButtonWrapper
      variant={variant}
      type={type}
      className={className}
      onClick={onClick}
      disabled={disabled}
      value={value}
      style={style}
      width={width}
    >
      {CopyIcon && <img src={Copy}/>}
      {Eye && <img src={EyeIcon}/>}
      {text}
    </ButtonWrapper>
  );
};

export default Index;
