import React from 'react'
import { StButton } from './style';

const ButtonSt = ({children,...rest}) => {
  return (
      <StButton {...rest}>{children}</StButton>
  );
};

export default ButtonSt
