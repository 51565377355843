import styled from "styled-components";

const HeaderWrapper = styled.div`

  .header-wrapper-custon{
    background-color: ${({ theme }) => theme["primary"]};
  padding: 21.5px 22px;
  }
  img.logo-congratulation-sc {
    margin-left: 113px;
}
  .congratulation-screen-wrapper{
  padding: 21.5px 22px;
  .logout{
color:${({ theme }) => theme["primary"]}; ;
  }
  }
  .logout {
    color: ${({ theme }) => theme["on-primary"]};
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    padding-right: 4px;
    svg {
      margin-right: 1px;
    font-size: 23px;
    margin-top: 5px;
    }
  }
  /* &.appsellertermianlcom{
  background: linear-gradient(90deg, #7E0072 0%, #064AAC 100%);
}
&.appsellerrepaycom{
  background: linear-gradient(270deg, #4AB2D0 0%, #092636 68.53%);
}
&.localhost{
  background: linear-gradient(90deg, #7E0072 0%, #064AAC 100%);
}
&.reimbursementssalessupport{
  background-color: ${({ theme }) => theme["primary-color"]};
}
 height: 60px;
 position: fixed;
  width: 100%;
  top: 0;
  z-index: 1;
  border-bottom: 2px solid transparent;
  border-image:  linear-gradient(135deg, #600D7D 0%, #105AC6 100%);
  border-image-slice: 1;
  .header{
    margin:0 23px;
    .logo-box{
      align-items:center;
      img,h2{
        margin-left:18px;
      }
      svg{
        color: ${({ theme }) => theme["white-color"]};
        cursor: pointer;
        font-size:20px;
      }
    }
    h1{
      margin: 0 14px;HeaderWrapper
    }
  }
 .logn-session{
   display:flex;
   grid-column-gap:15px;
   align-items:center;
   .switch-user{
    display: flex;
    .dropdown-menu{
      min-width:320px;
      padding:0 0;
    }
     button{
        background-color: ${({ theme }) => theme["white-color"]};
        display: flex;
        justify-content:space-between;
        border-radius:50px;
        padding: 0 10px 0 10px;
        grid-column-gap:5px;
        cursor:pointer;
        &:after{
          display: none;
        }
        &:focus{
          box-shadow:none;
        }
        .mr-4{
          margin-right:8px ;
        }
        .margin-right-4{
          margin-left:8px ;
        }
        svg{
      cursor: pointer;
      color:#C4C4C4;
      font-size:22px;
      margin-right:8px;
   }
        .user-info{
          .userName{
            color: ${({ theme }) => theme["primary-color"]};
            font-size:${({ theme }) => theme["base-font-size-small"]};
            font-weight:${({ theme }) => theme["bold-weight"]};
            margin:0 2px;
            height: 12px;
          }
          .email{
            color: ${({ theme }) => theme["grey-color"]};
            font-size:${({ theme }) => theme["font-size-small"]};
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width:118px;
            margin-right:8px;
          }
        }
      }
      }
   svg{
      cursor: pointer;
      color: ${({ theme }) => theme["white-color"]};
      &.user-icon{
        color:#C4C4C4;
      font-size:22px;
      margin-right:8px;
      }
   }
 }
 .avatar{
   position: relative;
   button{
    color: ${({ theme }) => theme["grey-color"]}!important;
    background-color: ${({ theme }) => theme["white-color"]}!important;
    border-color: ${({ theme }) => theme["secondary-color"]}!important;
    font-size:${({ theme }) => theme["base-font-size-small"]};;
    height: 30px;
    width: 30px;
    border-radius: 50px;
    padding: 0;
    margin-right:7px;
    &:focus{
      outline:0;
        }
        &:active{
           border-style: outset;
        }
     &::after{
       display: none;
     }
   }
   .dropdown-menu{
    min-width:258px;
    padding:8px 3px;

  }
   .arrow-box{
     position: absolute;
     border: 1px solid #7E0072;
     padding: 3px;
     background-color: ${({ theme }) => theme["white-color"]};
     border-radius: 50px;
     width: 10px;
     height: 10px;
     bottom: 1px;
     right: 7px;
    svg{
      color: ${({ theme }) => theme["primary-color"]};
      position: absolute;
      top: 0px;
      right: 0px;
      font-size: 8px;
}

    }
   }
 }
 
  .bell-badge{
        position: relative;
        svg{
          position: absolute;
          top: -9px;
          right:10px;
          font-size:16px;
        }
        div{
          height: 8px;
          width: 8px;
          background-color: #c00;
          border-radius: 50px;
          position: absolute;
          top: -10px;
          right: 9px;
        }
      }
      .userSwitchinfo{
        padding:16px 16px 12px 16px;
        div:first-child{
          justify-content:space-between;
          span:nth-of-type(2){
            color: ${({ theme }) => theme["black-color"]};
            font-size: ${({ theme }) => theme["medium-font-size"]};
            font-weight: ${({ theme }) => theme["bold-weight"]};
          }
            .status{
              &.inactive{
                background-color: ${({ theme }) => theme["orange_peel"]};
                color: ${({ theme }) => theme["white-color"]};
                font-size: ${({ theme }) => theme["base-font-size-small"]};
                border-radius:50px;
                padding:0 10px;
                cursor: pointer;
              }
              }
          }
        }
        .login-info{
          margin-left:34px;
          grid-row-gap:5px;
          .email{
            font-size: ${({ theme }) => theme["base-font-size-small"]};
            color: ${({ theme }) => theme["dusty_grey"]};
            margin:4px -1px;
          }
          .tile{
            margin:4px -2px;
          }
          .role{
            margin: -2px -1px;
          }
          .users{
            display:flex;
            align-items:center;
            position: relative;
            margin: -1px -1px;
            svg{
              color:#009C34;
              margin:0 8px;
            }
            span{
              position: absolute;
              right:-5px;
              top: -1px;
            }
          }
        }
        .affilate{
          margin:2px -1px;
            .title{
              /* min-width:69px; */
  /* }
           
            b{
              color: ${({ theme }) => theme["secondary-color"]};
              font-weight: ${({ theme }) => theme["bold-weight"]};
              margin-left: 12px;
              &.ml-4{
              margin-left:-1px;
            }
            }
        }
      }
      .user-dropdown{
        padding: 0 16px;
        .marginy-8{
          margin:8px 0;
        }
        .username{
          color: ${({ theme }) => theme["black-color"]};
        }
        .email{
          color: ${({ theme }) => theme["dusty_grey"]};
          font-size: ${({ theme }) => theme["font-size-small"]}
        }
        hr{
          margin:10px 0;
        }
      }
      .add-margin{
        margin-right:5px;
        margin-bottom:2px;
      }
      .mr-31{
        margin-right:31px;
      } */
`;

export { HeaderWrapper };
