import styled from "styled-components";

const AuthWrapper = styled.div`
  background-image: url(${({ theme }) => theme['background-image'] });
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  height: 100vh;
  overflow: auto;
  .content-holder {
    max-width: 1125px;
    margin: 0 auto;
    /* height:100%; */
    background-position: bottom;
    background-repeat: no-repeat;
  }
  .left-side-content-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 113px;
    padding-left: 234px;
    .left-side-content {
      p {
        font-weight: 300;
        font-size: 16px;
        line-height: 19px;
        color: #ffffff;
        margin-bottom: 0px;
        padding-top: 36px;
        margin-right: 150px;
      }
      h6 {
        font-weight: 300;
        font-size: 16px;
        line-height: 29px;
        color: #ffffff;
      }
    }
  }
  .right-side-content {
    background: #ffffff;
    border-radius: 10px;
    padding: 24px;
    margin-top: 113px;
    width: 419px;
    h2 {
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      color: ${({ theme }) => theme['heading-primary']};
      padding-bottom: 40px;
    }
    p {
      color: #a2a2a2;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #a2a2a2;
      margin: 0px !important;
      padding-top: 38px;
      letter-spacing: 0.2px;
    }
    .verifactionn-img {
      padding-top: 7px;
      padding-left: 17px;
    }
  }
  span {
    font-size: ${({ theme }) => theme["font-size-base"]};
    color: ${({ theme }) => theme["lynch-color"]};
  }
  .password-input {
    position: relative;
    &.signup-password {
      .password-field {
        margin-bottom: 16px;
      }
      .password-field-2 {
        margin-bottom: 2px;
      }
      svg {
        position: absolute;
        top: 25px;
        right: 15px;
        /* color: ${({ theme }) => theme["primary-color"]}; */
        color: #818181;
      }
    }
    svg {
      position: absolute;
      top: 25px;
      right: 10px;
      /* color: ${({ theme }) => theme["primary-color"]}; */
      color: #818181;
    }
  }
  .bg-albaster {
    background-color: ${({ theme }) => theme["alabaster-color"]}80;
    height: 100%;
    display: flex;
    align-items: center;
    .py-40 {
      padding: 0px 40px;
      padding-top: 48px;
    }
  }
  .forget-password {
    font-size: ${({ theme }) => theme["xs-small-size"]};
    color: ${({ theme }) => theme["primary-variant"]};
    font-weight: ${({ theme }) => theme["bold-weight"]};
    text-decoration: none;
    padding-right: 2px;
  }
  .heading {
    &.signup-haeding {
      h1 {
        line-height: 50px;
        padding: 0px 28px;
        letter-spacing: 0px;
      }
    }
  }

  .header-space {
    /* padding-top:69px; */
    padding-top: 75px;
  }
  .button-top {
    padding-top: 8px;
  }
  .button-top-change {
    padding-top: 16px;
  }
  .have-account {
    /* padding-right: 6px; */
    p {
      font-size: ${({ theme }) => theme["xs-small-size"]};
      margin-left: 0px;
      margin-bottom: 0;
      a {
        margin: 0px 7px;
        /* margin-left: 0px; */
        text-decoration: none;
        font-weight: 700;
        margin-right: 2px;
        color: ${({ theme }) => theme["primary-variant"]};
      }
    }
  }
  .already-account {
    color: ${({ theme }) => theme["nevada-color"]};
  }
  .recive-email {
    padding-top: 49px;
    color: ${({ theme }) => theme["nevada-color"]};
    font-size: 1rem;
    margin-right: 6px;
    a {
      color: ${({ theme }) => theme["primary-color"]};
      text-decoration: underline;
    }
  }
  .verifactionn-img {
    margin-top: -6px;
    margin-left: -16px;
  }
  .forget-password-form {
    margin: 5px 61px 0 61px;
    h3 {
      margin-bottom: 30px;
    }
    button {
      margin-top: 15px;
    }
    .email-dont-recieve {
      margin: 25px 0px 0 25px;
    }
  }
  .change-password-form {
    margin: 5px 61px 0 61px;
    h3 {
      margin-bottom: 30px;
    }
    button {
      margin-top: 15px;
    }
    .email-dont-recieve {
      margin: 25px 0px 0 25px;
    }
  }
  .left-section {
    padding-left: 113px;
    padding-top: 38px;
    .logo-description {
      color: ${({ theme }) => theme["white-color"]};
      padding-top: 35px;
      padding-right: 53px;
    }
  }
  .right-content {
    padding: 39px 94px;
    padding-right: 113px;
    .user-form {
      background-color: ${({ theme }) => theme["white-color"]};
      border-radius: 10px;
      padding: 24px;
      .heading {
        margin-bottom: 13px;
        letter-spacing: 0.3px;
        color: ${({ theme }) => theme["primary-variant"]};
      }
      .heading-secondary {
        margin-bottom: 33px;
      }
      .heading-caption {
        font-size: ${({ theme }) => theme["base-font-size-small"]};
        line-height: 14.52px;
        display: block;
        color: ${({ theme }) => theme["body-text-variant"]};
        letter-spacing: 0px;
      }
      hr {
        margin-top: 16px;
        margin-bottom: 24px;
      }
      .auth-form {
        margin-top: 22px;
      }
      .captcha-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #353e44;
        font-size: 12px;
        margin-bottom: 23px;
        margin-top: 10px;
        .white-box {
          background-color: ${({ theme }) => theme["white-color"]};
          width: 61px;
          height: 34px;
          border-radius: 6px;
        }
        &.signup-captcha {
          margin-top: 10px;
        }
      }
    }
  }

  .customized-row {
    margin-left: -8px;
    margin-right: -8px;
    [class*="col-"] {
      padding-left: 8px;
      padding-right: 8px;
    }
  }
  #rc-anchor-alert,
  .rc-anchor-alert {
    color: blue;
  }
`;
const MailVerificationWrapper = styled.div`
 background: ${({ theme }) => theme["new-bg"]};
 height:100vh;
 padding-top: 114px;
 .verification-screen-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    max-width: 865px;
    margin: auto;
    grid-gap: 24px;
    .left-section {
    max-width: 305px;
    p{
      color: #FFF;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding-top: 47px;
    }
}
    .right-content {
    max-width: 416px;
    border-radius: 10px;
    background: #FFF;
    padding: 24px;
    h2{
      color: ${({ theme }) => theme["new-primary"]};
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-bottom: 13px;
    }
    p{
      color: var(--Secondary, #151A26);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 24px;
    }  
}
.centered-spin {
  position: absolute;
  top: 50%;
}
.loader-wrapper {
  width: 420px;
  right: 3px;
  background: #ffffffd9;
  top: 112px;
  height: 250px;
  z-index:2;
  left: 880px
}
.right-content-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    p{
      color: ${({ theme }) => theme["new-primary"]};
      font-size: 10px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      margin: 0;
      span{
      color: ${({ theme }) => theme["new-primary"]};
      font-weight: 700;
      }
    }
    .resend-button{
      color: ${({ theme }) => theme["new-primary"]};
      font-size: 10px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin: 0;
      color: ${({ theme }) => theme["new-primary"]};
      font-weight: 700;
      background: transparent;
      border: none;
    }
}
}
.disabled-button {
  cursor: not-allowed;
  opacity: 0.5;
  &:hover {
    background: ${(props) => props.theme["new-bg"]};
    border: 1px solid ${(props) => props.theme["new-primary"]};
    color: ${(props) => props.theme["bg-white"]} !important;
  }
  &:focus {
    background-color: ${(props) => props.theme["new-bg"]} !important;
    border: 1px solid ${(props) => props.theme["new-primary"]} !important;
    color: ${(props) => props.theme["bg-white"]} !important;
  }

}
`;

export { AuthWrapper,MailVerificationWrapper };
