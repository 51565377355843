import React from "react";
import { useDispatch, useSelector } from "react-redux";

import ButtonSt from "../../../components/ButtonSt";

import NotEligibleIcon from "../../../assets/images/return-on-investment.svg";

import { ClearStates } from "../../../helpers/clear-store-states";

import { NoEligibleWrapper } from "./style";

import { SetAuthState } from "../../../redux/slices/auth-slice";
import { SetStoreState } from "../../../redux/slices/store-slice";
import { SetState } from "../../../redux/slices/account-detail-slice";

const NoEligible = ({ setStep }) => {
  const dispatch = useDispatch();
  const { storesCount } = useSelector((state) => state.auth);

  const clearStates = () => {
    ClearStates({ dispatch, fromApp: false });
    setStep(0);
  }

  return (
    <NoEligibleWrapper>
      <div className="card_ui_overlay">
        <div className="card_ui_wrapper">
          <img src={NotEligibleIcon} alt="Not Eligible Icon" />
          <h2>No Eligible Cases Identified</h2>
          <h5 className="mt-0">Your Invite Will Not Be Accepted</h5>
          <p>
            After a thorough review of your account, we regret to inform you that we are unable to accept your invite at this time, as no eligible cases have been found for reimbursements.
          </p>
          <br/>
          <p>
            We understand that this may be disappointing, but please rest assured that we are actively monitoring for new opportunities. We will reach out to you as soon as we find any data.
          </p>
          <br />
          <p>
            In the meantime, you may consider adding another store or marketplace:
          </p>
          <ButtonSt onClick={clearStates} outline >Add Another Store</ButtonSt>
        </div>
      </div>

    </NoEligibleWrapper>
  );
};

export default NoEligible;
