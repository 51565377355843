import backgroundImage from '../../assets/img/Background.svg';
import sellerTerminalBackgroundImage from '../../assets/img/seller-terminal-background.svg';

const primaryColor = '#064AAC';
const secondaryColor = '#7E0072';
const backgroundColor= "#F4F7F9";
const whiteColor="#fff";
const lightColor = "#FAFAFA";
const blackColor="#000";
const greyColor="#3C4356";
const darkGreyColor = "#616980"
const textPrimary="#4E5969"
const textSecondary="#78909C"
const tableHeader="#F8FAFB"
const successColor="#009C34"
const headerSuccessColor="#009C34"
const greySecondary="#CED4DA"
const dangerColor="#CF0909"
const warningColor="#FF9900"
const Nevada="#696F79"
const primaryButtonGradientColor1 = '#3E8EE3'
const primaryButtonGradientColor2 = '#4242E4'
const primaryButtonGradientColor3 = '#5448FB'
const calenderIconColor = '#4538f9'
const cardBackgroundColor = '#414bc6'
const loadingColor = '#064AAC'
const greyTextColor = '#8C90A7'
const lightBgColor = '#F5F5F5'

const primary= '#4438F9'
const primaryV2= '#026363'
const onPrimary= '#ffffff'
const primaryVariant= '#0985EC'
const secondary= '#F4F4F4'
const onSecondary= '#A2A2A2'
const secondaryVariant = '#DEDBDB'
const surface = '#FFFFFF'
const onSurface = '#353E44'
const background = '#F4F4F4'
const onBackground = '#494F51'
const onBackgroundVariant = '#707070'
const bodyText = "#494F51"
const bodyTextv2 = "#5D5D5D"
const bodyTextVariant = "#A2A2A2"
const placeholder = '#C0C0C0'
const labelColor = '#494F51'
const accentWarning = '#e8c347'
const accentSuccess = '#54AF94'
const accentInfo = '#ff1c71'
const headingPrimary = '#007EFF'
const tableBorderColor = '#E8E8E8'
const afterColor = '#C4C4C4'
const paginationBg = '#FCFCFC'
const headingBgColor = '#F6FBFB'
const infoColor = '#42AEFC'
const textColor = '#4E6069'
const infoStroke = '#89C0C0'
const lightBluebg = '#EFFBFB'
const bodyDarker = '#474747'
const darkText = '#4B4F51'
const borderLightColor = '#EBEBEB'
const darkGrayText = '#656667'
const borderColor = '#D9D9D9'
const purpleColor = '#4048E7'
const infoBg = '#F8FFFF'
const boldDark = '#535E63'
const dividerColor = '#ECEFF1'
const lightBlue = '#EFF6F7'
const grayText = '#7E8A90'

// new colors
 const newPrimary = '#4438F9'
 const newBg = `linear-gradient(
  180deg,
  #0985EC 0%,
  #4438F9 49.67%,
  #5448FB 98.63%
)`


const sellerTerminalPrimaryColor = '#089EA1';
const sellerTerminalSecondaryColor = '#006264';
const sellerTerminalBackgroundColor = '#F6F6F6';
const sellerTerminalHeaderSuccessColor = '#0096FF'
const sellerTerminalWarningColor = '#DC2458'
const stPrimaryButtonGradientColor1 = '#089EA1'
const stPrimaryButtonGradientColor2 = '#006264'
const stPrimaryButtonGradientColor3 = '#076e70'
const sellerTerminalPrimary = '#008989'
const sellerTerminalPrimaryVariant = '#008989'
const sellerTerminalLoadingColor = '#008989'

const newStPrimary = '#026363'
const newStBg = `linear-gradient(180deg, #014446 0%, #005A5C 100%)`;
const BgWhite = '#fff'
const newBgWhite = '#fff'


const theme = {
  // new color system
  'primary' : primary,
  'on-primary':onPrimary,
  'primary-variant': primaryVariant,
  'secondary': secondary,
  'on-secondary': onSecondary,
  'secondary-variant':secondaryVariant,
  'surface': surface,
  'on-surface': onSurface,
  'background': background,
  'on-background': onBackground,
  'on-background-variant':onBackgroundVariant,
  'body-text': bodyText,
  'body-text-v2': bodyTextv2, 
  'body-text-variant': bodyTextVariant,
  'placeholder':placeholder,
  'label-color':labelColor,
  'white':whiteColor,
  'accent-warning':accentWarning,
  'accent-success':accentSuccess,
  'accent-info': accentInfo,
// new color system ends here

  'primary-color': primaryColor, // primary color for all components
  'primary-color-v2': primaryV2,
  'secondary-color': secondaryColor, // secondary color for all component
  'button-secondary-color': secondaryColor, // secondary color for all component
  'white-color':whiteColor,//white color for all component
  'light-color': lightColor,
  'background-color':backgroundColor,//background color of all component
  'black-color':blackColor,
  'grey-color':greyColor,
  'text-primary':textPrimary,
  'text-secondary':textSecondary,
  'table-header':tableHeader,
  'success-color':successColor,
  'header-success-color':headerSuccessColor,
  'grey-secondary':greySecondary,
  'danger-color':dangerColor,
  'header-warning-color':warningColor,
  'warning-color':warningColor,
  'nevada-color':Nevada,
  'primary-btn-gradient-1': primaryButtonGradientColor1,
  'primary-btn-gradient-2': primaryButtonGradientColor2,
  'primary-btn-gradient-3': primaryButtonGradientColor3,
  'calender-icon-color': calenderIconColor,
  'card-background-color': cardBackgroundColor,
  'loading-color': loadingColor,
  'heading-primary': headingPrimary,
  'table-border-color': tableBorderColor,
  'gray-text-color': greyTextColor,
  'after-color': afterColor,
  'pagination-bg': paginationBg,
  'dark-grey-color': darkGreyColor,
  'heading-bg-color': headingBgColor,
  'info-color': infoColor,
  'text-color': textColor,
  'light-bg-color': lightBgColor,
  'info-stroke': infoStroke,
  'light-blue-bg': lightBluebg,
  'body-darker': bodyDarker,
  'dark-text': darkText,
  'border-light-color': borderLightColor,
  'dark-gray-text': darkGrayText,
  'border-color': borderColor,
  'purple-color': purpleColor,
  'info-bg': infoBg,
  'bold-dark': boldDark,
  'divider-color': dividerColor,
  'light-blue': lightBlue,
  'gray-text': grayText,
 //border-radius
 'border-radius-base': '4px', // major border radius
 'border-radius-normal': '12px', // major border radius
 //font-sizes
 'xs-small-size':'10px',
 'base-font-size-small': '12px',
 'medium-font-size': '14px',
 'base-font-size': '16px',

 //font-weight
 'normal-weight': '400',
 'medium-weight': '500',
 'bold-weight':'600',
 'xl-bold-weight': '700',
 //base-padding
 'base-padding':'24px',
 'background-image': backgroundImage,

 'new-primary':newPrimary,
 'new-bg':newBg,
 'bg-white':BgWhite,
};

const sellerTerminalTheme = {
  // new color system
  'gray-text-color': greyTextColor,
  'table-border-color': tableBorderColor,
  'body-text-v2': bodyTextv2, 
  'light-color': lightColor,
  'primary' : sellerTerminalPrimary,
  'on-primary': onPrimary,
  'primary-variant': sellerTerminalPrimaryVariant,
  'secondary': secondary,
  'on-secondary': onSecondary,
  'secondary-variant': secondaryVariant,
  'surface': surface,
  'on-surface': onSurface,
  'background': background,
  'on-background': onBackground,
  'on-background-variant': onBackgroundVariant,
  'body-text': bodyText,
  'body-text-variant': bodyTextVariant,
  'placeholder': placeholder,
  'label-color': labelColor,
  'white': whiteColor,
  'accent-warning': accentWarning,
  'accent-success': accentSuccess,
  'accent-info': accentInfo,
// new color system ends here
  'primary-color': sellerTerminalPrimaryColor, // primary color for all components
  'secondary-color': sellerTerminalPrimary, // secondary color for all component
  'button-secondary-color': sellerTerminalSecondaryColor, // secondary color for all component
  'white-color': whiteColor,//white color for all component
  'background-color': sellerTerminalBackgroundColor,//background color of all component
  'black-color': blackColor,
  'grey-color': greyColor,
  'text-primary': textPrimary,
  'text-secondary': textSecondary,
  'table-header': tableHeader,
  'success-color': successColor,
  'header-success-color': sellerTerminalHeaderSuccessColor,
  'grey-secondary': greySecondary,
  'danger-color': dangerColor,
  'header-warning-color': sellerTerminalWarningColor,
  'warning-color': warningColor,
  'nevada-color': Nevada,
  'primary-btn-gradient-1': stPrimaryButtonGradientColor1,
  'primary-btn-gradient-2': stPrimaryButtonGradientColor2,
  'primary-btn-gradient-3': stPrimaryButtonGradientColor3,
  'calender-icon-color': sellerTerminalPrimary,
  'card-background-color': sellerTerminalPrimaryVariant,
  'loading-color': sellerTerminalLoadingColor,
  'heading-primary': sellerTerminalPrimary,
 //border-radius
 'border-radius-base': '4px', // major border radius
 'border-radius-normal': '12px', // major border radius
 //font-sizes
 'xs-small-size':'10px',
 'base-font-size-small': '12px',
 'medium-font-size': '14px',
 'base-font-size': '16px',
 //font-weight
 'normal-weight': '400',
 'bold-weight': '600',
 //base-padding
 'base-padding': '24px',
 'background-image': sellerTerminalBackgroundImage,
// new colors here stepper flow
  'new-primary':newStPrimary,
  'new-bg':newStBg,
  'bg-white':newBgWhite,
  'info-stroke': infoStroke,
  'info-bg': infoBg,
  'table-border-color': tableBorderColor,
  'gray-text-color': greyTextColor,
  'after-color': afterColor,
  'pagination-bg': paginationBg,
  'dark-grey-color': darkGreyColor,
  'heading-bg-color': headingBgColor,
  'info-color': infoColor,
  'text-color': textColor,
  'light-bg-color': lightBgColor,
  'info-stroke': infoStroke,
  'light-blue-bg': lightBluebg,
  'body-darker': bodyDarker,
  'dark-text': darkText,
  'border-light-color': borderLightColor,
  'dark-gray-text': darkGrayText,
  'border-color': borderColor,
  'purple-color': purpleColor,
  'info-bg': infoBg,
  'bold-dark': boldDark,
  'divider-color': dividerColor,
  'light-blue': lightBlue,
  'gray-text': grayText,



// new colors here stepper flow
};

export { theme, sellerTerminalTheme };
