import { debounce, isEmpty, round } from "lodash";
import moment from "moment";

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { components } from "react-select";
import { ButtonGroup } from "react-bootstrap";

import Pagination from "../../components/Pagination/pagination";
import Button from "../../components/Button";
import Modal from "../../components/Modal/modal";
import Select from "../../components/Select";
import SearchInput from "../../components/SearchInput";
import Spin from "../../components/Spin/Index";
import Table from "../../components/Table/table";
import Toast from '../../components/Toast';
import Notification from '../../components/notifications/notification'

import { CURRENCY_SYMBOLS } from '../../constants';

import {
  GetAffiliateMonthlyReceiptInvoices,
  PayReceipt,
  SetReceiptState,
} from "../../redux/slices/affiliate-slice";

import {
  CapitalizeFirstLetter
} from '../../helpers/format-value'

import { InvoiceWrapper } from "./style";

const { Option } = components;
const Tile = (props) => {
  return (
    <Option {...props} >
      <div className="select-option">
        <span className="d-block bold-weight">{props.data.userEmail !== 'All' ? props.data.userEmail : null}</span>
        <span>{props.data.name}</span>
      </div>
    </Option>
  );
}

const Index = () => {
  const dispatch = useDispatch();

  const [invoice, setInvoice] = useState(true);
  const [invoiceObj, setInvoiceObj] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [invoiceBody, setInvoiceBody] = useState([]);
  const [usersNameData, setUsersNameData] = useState([]);

  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [severity, setSeverity] = useState('');
  const {
    monthlyAffiliateReceiptData,
    total,
    message,
    error,
    success,
    loading, affiliateManagers
  } = useSelector((state) => state.affiliate);

  const [filters, setFilters] = useState({
    pageNumber: 1,
    pageLimit: 25,
    searchKeyword: '',
    status: 'paid',
    affiliateManagerId: '',
    pageOption: { value: 25, label: 25 }
  });

  const handleInvoiceModal = (invoiceId, status) => {
    setShowModal(true);
    setInvoiceObj({
      monthlyAffiliateReceiptId: invoiceId,
      status
    });
  };

  const handlePayInvoice = () => {
    dispatch(PayReceipt(invoiceObj));
    setShowModal(false);
  };

  useEffect(() => {
    if (!isEmpty(filters)) {
      getReceipteInvoiceSummary(filters);
    }
  }, [filters]);

  useEffect(() => {
    if (affiliateManagers && affiliateManagers.length > 0) {
      dispatch(SetReceiptState({ field: 'message', value: '' }));
      const usersNamesData = affiliateManagers.map(user => ({
        value: [user._id, user.email],
        label: `${user.name}`,
        userEmail: user.email,
        name: user.name
      }));

      const users = [];

      users.push(...usersNamesData);
      setUsersNameData(users);
    }
  }, [affiliateManagers]);

  useEffect(() => {
    if (success && monthlyAffiliateReceiptData.length > 0) {
      const invoiceSummaryData = monthlyAffiliateReceiptData.map((item, i) => {
        let status = item.status;
        let classNameForStatus = 'table-badge paid';
        if (status === 'open') {
          status = 'Unpaid';
          classNameForStatus = 'table-badge unpaid';
        }
        return (
          <tr key={i} style={item.refunded ? { backgroundColor: '#FFFDF2' } : null}>
            <td>
              <>
                <span className="d-block mb-0">{item.invoiceNumber}</span>
                <span className="weight-bold small-fontSize">
                  {moment(item.month).utc().format('ll')}
                </span>
              </>
            </td>
            <td>
              <>
                <span className="d-block mb-0">{item.name}</span>
                <span className="weight-bold small-fontSize">
                  {item.email}
                </span>
              </>
            </td>
            <td>
              <span className="d-block mb-0">{CURRENCY_SYMBOLS[item.currencyCode] + round(item.monthlyCommission, 2)}</span>
            </td>

            <td>
              <span className="d-block mb-0">{CURRENCY_SYMBOLS[item.currencyCode] + round(item.totalPaid, 2)}</span>
            </td>


            <td>
              <span className={classNameForStatus}> {CapitalizeFirstLetter(status)}</span>
            </td>
            {status !== 'paid' && <td><Button
              className="invoice-filter"
              text="Pay"
              value="Pay"
              onClick={() => handleInvoiceModal(item._id, status = 'paid')}
            />
            </td>}
          </tr>
        );
      });
      setInvoiceBody(invoiceSummaryData);
    } else setInvoiceBody([]);
  }, [monthlyAffiliateReceiptData]);

  useEffect(() => {
    if (error) {
      setToastMessage(error);
      setSeverity('error');
      setToastOpen(true);
      dispatch(SetReceiptState({ field: 'error', value: '' }));
      return;
    }
  }, [error]);

  useEffect(() => {
    if (success && message) {
      if (message || authMessage) {
        Notification({
          type: "success",
          title: "Success",
          description: message
        });
        dispatch(SetReceiptState({ field: "message", value: "" }));
        getReceipteInvoiceSummary();
        return;
      }
    }
  }, [success, message]);

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToastOpen(false);
  };

  const getReceipteInvoiceSummary = () => {
    const skip = (filters.pageNumber - 1) * filters.pageLimit;
    const limit = filters.pageLimit;
    dispatch(GetAffiliateMonthlyReceiptInvoices({ filters, skip, limit }));
  };

  const handleSearch = debounce((value, key) => {
    setFilters({
      ...filters,
      pageNumber: 1,
      [key]: value
    });
  }, 500);

  const handlePageLimit = (e) => {
    setFilters({ ...filters, pageLimit: e.value, pageOptoion: e })
  };

  const handlePageNumberChange = (e) => {
    const totalPages = Math.ceil(total / filters.pageLimit)
    if (e.key == "Enter") {
      if (e.target.value < 1 || !e.target.value) {
        setFilters({ ...filters, pageNumber: 1 })
      } else if (e.target.value > totalPages) {
        setFilters({ ...filters, pageNumber: totalPages })
      } else {
        setFilters({
          ...filters, pageNumber: Number(e.target.value)
        })

      }
    }
  }

  const handlePageChangeLeft = () => {
    if (filters.pageNumber - 1 > 0) {
      setFilters({
        ...filters, pageNumber: filters.pageNumber - 1
      })
    }
  }

  const handlePageChangeRight = () => {
    const totalPages = Math.ceil(total / filters.pageLimit)
    if (filters.pageNumber < totalPages) {
      setFilters({
        ...filters, pageNumber: filters.pageNumber + 1
      })
    }
  }

  const handleChange = (e, key) => {
    setFilters({
      ...filters,
      pageNumber: 1,
      [key]: e?.value[0] || ''
    })
  };

  const handleClick = (e, key) => {
    setFilters({
      ...filters,
      pageNumber: 1,
      pageLimit: 25,
      pageOption: { value: 25, label: 25 },
      [key]: e
    })
  };

  return (
    <InvoiceWrapper>
      <Toast
        message={toastMessage}
        severity={severity}
        toastOpen={toastOpen}
        handleToastClose={handleToastClose}
      />
      <div className="d-flex justify-content-between align-items-center flex-sm-column flex-md-row">
        <h3 className="mb-0">Invoices</h3>
        <div className="d-flex flex-wrap grid-column-gap-16 align-items-center">
          <ButtonGroup
            className="invoice-filter"
            onClick={(e) => setInvoice(!invoice)}
          >
            <Button
              text="Paid"
              value="paid"
              className={filters?.status === 'paid' ? "active" : "non-active"}
              onClick={(e) => handleClick(e.target.value, 'status')}
            />

            <Button
              text="Unpaid"
              value="open"
              className={filters?.status === 'open' ? "active" : "non-active"}
              onClick={(e) => handleClick(e.target.value, 'status')}
            />
          </ButtonGroup>
          <Select
            styles={{
              option: (base, state) => ({
                ...base,
                paddingLeft: "11px",
                paddingRight: "11px",
                paddingTop: "5px",
                paddingBottom: "5px",
                borderBottom: `1px solid #E3E2E2`,
                height: "100%",
                backgroundColor: 'transparnet',
                color: '#4E5969'
              }),
              menuList: (provided, state) => ({
                ...provided,
                padding: 10,
              }),
            }}
            className="mb-0 w-232"
            options={usersNameData}
            isClearable={true}
            handleChange={(e) => handleChange(e, 'affiliateManagerId')}
            components={{ Option: Tile }}
            placeholder='Select User'
          />
          <SearchInput placeholder="Search" name='search' onChange={(e) => handleSearch(e.target.value, 'searchKeyword')} />
        </div>
      </div>
      <div className="pt-4">
        <Table
          viewHeight="invoices"
          tableHeader={
            <tr>
              <th>Invoice</th>
              <th>Total Reimbursements</th>
              <th>Monthly Commission</th>
              <th>Paid Amount</th>
              <th>Status</th>
              {(filters?.status !== 'paid') && (
                <th> Action </th>
              )}
            </tr>
          }
          tableBody={
            <>
              {(!loading && !monthlyAffiliateReceiptData.length && success) ? (
                <tr><td>
                  <div className='error'>
                    <h1>No Data Available</h1>
                  </div>
                </td></tr>
              ) : loading ? <tr><td><Spin /></td></tr> : invoiceBody}
            </>
          }
        />

        <Pagination
          className="invoices-pagination-wrapper"
          pageLimit={filters.pageLimit}
          total={total}
          pageNumber={filters.pageNumber}
          totalPages={Math.ceil(total / filters.pageLimit)}
          handlePageChangeLeft={handlePageChangeLeft}
          handlePageChangeRight={handlePageChangeRight}
          handleLimitChange={handlePageLimit}
          defaultValue={filters.pageOption}
          handlePageNumberChange={handlePageNumberChange}
        />
      </div>

      {showModal ? <Modal
        showModal={showModal}
        modalHeader="Pay Invoice"
        modalBody="Do you really want to pay the invoice ?"
        handleConfirm={() => handlePayInvoice()}
        handleClose={() => setShowModal(false)}
        showFooter={true}
      />
        : null
      }
      {
        loading && (
          <Spin />
        )
      }
    </InvoiceWrapper>
  );
};
export default Index;
