import { extend } from "lodash";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import SaveSentryLog from "../../helpers/sentry-log";

import { axiosBaseUrl } from '../../config/axios-configuration';

const axios = axiosBaseUrl();

export const GetSPAPIToken = createAsyncThunk(
  'stores/getSPAPIToken',
  async (data, { rejectWithValue }) => {
    extend(data, { hostName: window.location.host })
    try {
      const response = await axios.post('/stores/get-spapi-refresh-token', data);
      return response.data;
    } catch (err) {
      SaveSentryLog(new Error(err), 'GetSPAPITokenError');
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: {
            message: err.response.data.message,
            status: err.response.status
          }
        });
      }
      return rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

export const CreateStore = createAsyncThunk(
  'stores/createStore',
  async (data, { rejectWithValue }) => {
    try {
      extend(data, { hostName: window.location.host })
      const response = await axios.post('/stores/create-store', data);
      return response.data;
    } catch (err) {
      SaveSentryLog(new Error(err), 'CreateStoreError');
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: {
            message: err.response.data.message,
            status: err.response.status
          }
        });
      }
      return rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

export const CreateNewRefreshToken = createAsyncThunk(
  'stores/createNewRefreshToken',
  async (data, { rejectWithValue }) => {
    try {
      extend(data, { hostName: window.location.host })
      const response = await axios.post('/stores/create-refresh-token', data);
      return response.data;
    } catch (err) {
      SaveSentryLog(new Error(err), 'CreateNewRefreshTokenError');
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: {
            message: err.response.data.message,
            status: err.response.status
          }
        });
      }
      return rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

export const GetStoreBySellerId = createAsyncThunk(
  'stores/getStoreBySellerId',
  async (data, { rejectWithValue }) => {
    try {
      const { sellerId = '', userId, token } = data || {};
      const response = await axios.get('/auth/get-store-by-sellerId', {
        params: {
          sellerId
        }
      });

      if (token && userId && response?.data?.store?.userId == userId) {
        return response.data;
      } else {
        return {
          store: {}
        }
      }
    } catch (err) {
      SaveSentryLog(new Error(err), 'GetStoreBySellerIdError');
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: {
            message: err.response.data.message,
            status: err.response.status
          }
        });
      }
      return rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

export const GetProgress = createAsyncThunk(
  'stores/getProgress',
  async (data, { rejectWithValue }) => {
    try {
      const { storeId = '' } = data || {};
      const response = await axios.get('/stores/get-pt-progress', {
        params: {
          storeId
        }
      });
      return response.data;
    } catch (err) {
      SaveSentryLog(new Error(err), 'GetProgressError');
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: {
            message: err.response.data.message,
            status: err.response.status
          }
        });
      }
      return rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

export const GetPotentialRecovery = createAsyncThunk(
  'stores/getPotentialRecovery',
  async (data, { rejectWithValue }) => {
    try {
      const { storeId = '' } = data || {};
      const response = await axios.get('/stores/get-pt-recovery', {
        params: {
          storeId
        }
      });
      return response.data;
    } catch (err) {
      SaveSentryLog(new Error(err), 'GetPotentialRecoveryError');
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: {
            message: err.response.data.message,
            status: err.response.status
          }
        });
      }
      return rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

export const GetPtRecoveryBreakdown = createAsyncThunk(
  'stores/getPtRecoveryBreakdown',
  async (data, { rejectWithValue }) => {
    try {
      const { storeId = '' } = data || {};
      const response = await axios.get('/stores/get-pt-breakdown', {
        params: {
          storeId
        }
      });
      return response.data;
    } catch (err) {
      SaveSentryLog(new Error(err), 'GetPtRecoveryBreakdownError');
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: {
            message: err.response.data.message,
            status: err.response.status
          }
        });
      }
      return rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

export const UpdateStoreData = createAsyncThunk(
  'stores/updateStoreData',
   async (data, { rejectWithValue }) => {
     try {
       extend(data, { hostName: window.location.host })
       const response = await axios.post('/stores/update-store-data', data);
       return response.data;
     } catch (err) {
       SaveSentryLog(new Error(err), 'UpdateStoreDataError');
       if (err.response && err.response.data) {
         return rejectWithValue({ 
           err: {
             message: err.response.data.message,
             status: err.response.status
           }
         });
       }
       return rejectWithValue({
         err: {
           message: 'Network Error',
           status: 502
         }
       });
     }
   }
 );

export const UpdateStore = createAsyncThunk(
  'stores/updateStore',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post('/stores/update-store', data);
      return response.data;
    } catch (err) {
      SaveSentryLog(new Error(err), 'UpdateStoreError');
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: {
            message: err.response.data.message,
            status: err.response.status
          }
        });
      }
      return rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

export const GetStoreNames = createAsyncThunk(
  'stores/getStoreNames',
  async (data, { rejectWithValue }) => {
    try {
      const { selectedAgencyId = '', userId = '' } = data || {};
      const response = await axios.get('/stores/get-store-names', {
        params: {
          selectedAgencyId,
          userId
        }
      });
      return response.data;
    } catch (err) {
      SaveSentryLog(new Error(err), 'GetStoreNamesError');
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: {
            message: err.response.data.message,
            status: err.response.status
          }
        });
      }
      return rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

export const GetActiveMarketplaces = createAsyncThunk(
  'stores/getActiveMarketplaces',
  async ({ storeId }, { rejectWithValue }) => {
    try {
      const response = await axios.get('/stores/get-active-marketplaces', {
        params: {
          storeId
        }
      });
      return response.data;
    } catch (err) {
      SaveSentryLog(new Error(err), 'GetActiveMarketplacesError');
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: {
            message: err.response.data.message,
            status: err.response.status
          }
        });
      }
      return rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

export const GetStore = createAsyncThunk(
  'stores/getStore',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get('/stores/get-store', {
        params: data
      });
      return response.data;
    } catch (err) {
      SaveSentryLog(new Error(err), 'GetStoreError');
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: {
            message: err.response.data.message,
            status: err.response.status
          }
        });
      }
      return rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

 export const GetStores = createAsyncThunk(
  'stores/getStores',
   async (data, { rejectWithValue }) => {
     try {
      const {
        filters
      } = data;
       const response = await axios.get('/stores/get-stores', {
        params: {
          filters
        }
       });
       return response.data;
     } catch (err) {
       SaveSentryLog(new Error(err), 'GetStoresError');
       if (err.response && err.response.data) {
         return rejectWithValue({ 
           err: {
             message: err.response.data.message,
             status: err.response.status
           }
         });
       }
       return rejectWithValue({
         err: {
           message: 'Network Error',
           status: 502
         }
       });
     }
   }
 );

 export const DeleteStore = createAsyncThunk(
  'stores/deleteStore',
   async (data, { rejectWithValue }) => {
     try {
       const {
         storeId
       } = data;
       const response = await axios.delete('/stores/delete-store', {
         params: {
          storeId
        }
       });
       return response.data;
     } catch (err) {
       SaveSentryLog(new Error(err), 'DeleteStoreError');
       if (err.response && err.response.data) {
         return rejectWithValue({ 
           err: {
             message: err.response.data.message,
             status: err.response.status
           }
         });
       }
       return rejectWithValue({
         err: {
           message: 'Network Error',
           status: 502
         }
       });
     }
   }
);

export const SendNotificationOnSlack = createAsyncThunk(
  'stores/sendNotificationOnSlack',
  async (data, { rejectWithValue }) => {
    try {
      extend(data, { hostName: window.location.host })
      const response = await axios.post('/stores/send-notification-on-slack', data);
      return response.data;
    } catch (err) {
      SaveSentryLog(new Error(err), 'SendNotificationOnSlackError');
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: {
            message: err.response.data.message,
            status: err.response.status
          }
        });
      }
      return rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);


const store = createSlice({
  name: 'store',
  initialState: {
    state: '',
    error: '',
    loading: '',
    message: '',
    storeId: '',
    storeName: '',
    success: false,
    storeSuccess: false,
    activeMarketplaces: [],
    marketplaces: false,
    storeNamesList: [],
    store: {},
    stores: [],
    total: 0,
    totalStores: 0,
    totalConnected: 0,
    totalPending: 0,
    totalInviteAccepted: 0,
    progress: 0,
    potentialRecovery: null,
    recoveredAmount: null,
    potentialRecoveryBreakdown: {},
    updateSuccess: null,
    progressSuccess: null,
    newTokenSuccess: null,
    storeExist: null
  },
  reducers: {
    SetStoreState(state, { payload: { field, value } }) {
      state[field] = value;
    }
  },
  extraReducers: {
    [CreateStore.pending]: (state, action) => ({
      ...state,
      loading: true,
      storeSuccess: false
    }),
    [CreateStore.fulfilled]: (state, action) => ({
      ...state,
      store: action.payload.data,
      storeId: action.payload.data._id,
      success: true,
      loading: false,
      storeSuccess: true,
      message: action.payload.message
    }),
    [CreateStore.rejected]: (state, action) => ({
      ...state,
      success: false,
      loading: false,
      error: action.payload.err.message
    }),
    [CreateNewRefreshToken.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [CreateNewRefreshToken.fulfilled]: (state, action) => ({
      ...state,
      store: action.payload.data,
      message: action.payload.message,
      newTokenSuccess: true
    }),
    [CreateNewRefreshToken.rejected]: (state, action) => ({
      ...state,
      success: false,
      loading: false,
      error: action.payload.err.message,
      newTokenSuccess: false
    }),
    [GetProgress.pending]: (state, action) => ({
      ...state,
      loading: true,
      progressSuccess: false
    }),
    [GetProgress.fulfilled]: (state, action) => ({
      ...state,
      success: true,
      loading: false,
      progress: action.payload.progress,
      progressSuccess: true
    }),
    [GetProgress.rejected]: (state, action) => ({
      ...state,
      success: false,
      loading: false,
      error: action.payload.err.message,
      progressSuccess: null
    }),
    [GetPotentialRecovery.pending]: (state, action) => ({
      ...state,
      loading: true
    }),
    [GetPotentialRecovery.fulfilled]: (state, action) => ({
      ...state,
      success: true,
      loading: false,
      potentialRecovery: action.payload.potentialRecoveryAmount,
      recoveredAmount: action.payload.previouslyReimbursedAmount || null
    }),
    [GetPotentialRecovery.rejected]: (state, action) => ({
      ...state,
      success: false,
      loading: false,
      error: action.payload.err.message
    }),
    [GetPtRecoveryBreakdown.pending]: (state, action) => ({
      ...state,
      loading: true
    }),
    [GetPtRecoveryBreakdown.fulfilled]: (state, action) => ({
      ...state,
      success: true,
      loading: false,
      potentialRecoveryBreakdown: action.payload.data
    }),
    [GetPtRecoveryBreakdown.rejected]: (state, action) => ({
      ...state,
      success: false,
      loading: false,
      error: action.payload.err.message
    }),
    [GetStoreBySellerId.pending]: (state, action) => ({
      ...state,
      loading: true
    }),
    [GetStoreBySellerId.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      storeExist: true,
      store: action.payload.store,
      storeId: action.payload?.store?._id || ''
    }),
    [GetStoreBySellerId.rejected]: (state, action) => ({
      ...state,
      success: false,
      loading: false,
      storeExist: false
    }),
    [GetSPAPIToken.pending]: (state, action) => ({
      ...state,
      loading: true,
      storeSuccess: false,
    }),
    [GetSPAPIToken.fulfilled]: (state, action) => ({
      ...state,
      store: action.payload.data,
      storeId: action.payload.data._id,
      success: true,
      loading: false,
      storeSuccess: true,
      message: action.payload.message
    }),
    [GetSPAPIToken.rejected]: (state, action) => ({
      ...state,
      success: false,
      loading: false,
      storeSuccess: false,
      error: action.payload.err.message
    }),
    [UpdateStoreData.pending]: (state, action) => ({
      ...state,
      loading: true
    }),
    [UpdateStoreData.fulfilled]: (state, action) => ({
      ...state,
      success: true,
      loading: false,
      marketplaces: true,
      store: action.payload.store,
      message: action.payload.message
    }),
    [UpdateStoreData.rejected]: (state, action) => ({
      ...state,
      success: false,
      loading: false,
      error: action.payload.err.message
    }),
    [UpdateStore.pending]: (state, action) => ({
      ...state,
      loading: true,
      updateSuccess: false
    }),
    [UpdateStore.fulfilled]: (state, action) => ({
      ...state,
      success: true,
      loading: false,
      store: action.payload.data,
      message: action.payload.message,
      updateSuccess: true
    }),
    [UpdateStore.rejected]: (state, action) => ({
      ...state,
      success: false,
      loading: false,
      error: action.payload.err.message,
      updateSuccess: null
    }),
    [GetStoreNames.pending]: (state, action) => ({
      ...state,
      loading: true
    }),
    [GetStoreNames.fulfilled]: (state, action) => ({
      ...state,
      success: true,
      loading: false,
      // message: action.payload.message,
      storeNamesList: action.payload.storeNamesList,
    }),
    [GetStoreNames.rejected]: (state, action) => ({
      ...state,
      success: false,
      loading: false,
      error: action.payload.err.message
    }),
    [GetStore.pending]: (state, action) => ({
      ...state,
      success: false,
      loading: true,
      // error: action.payload.err.message
    }),
    [GetStore.fulfilled]: (state, action) => ({
      ...state,
      success: true,
      loading: false,
      store: action.payload.store,
    }),
    [GetStore.rejected]: (state, action) => ({
      ...state,
      loading: false,
      success: false,
      error: action.payload.err.message
    }),
    [GetStores.pending]: (state, action) => ({
      ...state,
      loading: true
    }),
    [GetStores.fulfilled]: (state, action) => ({
      ...state,
      success: true,
      loading: false,
      stores: action.payload.stores,
      total: action.payload.total,
      totalStores: action.payload.totalConnected + action.payload.totalPending + action.payload.totalInviteAccepted,
      totalConnected: action.payload.totalConnected,
      totalPending: action.payload.totalPending,
      totalInviteAccepted: action.payload.totalInviteAccepted
    }),
    [GetStores.rejected]: (state, action) => ({
      ...state,
      success: false,
      loading: false,
      error: action.payload.err.message
    }),
    [DeleteStore.pending]: (state, action) => ({
      ...state,
      loading: true,
      success: false
    }),
    [DeleteStore.fulfilled]: (state, action) => ({
      ...state,
      success: true,
      loading: false,
      message: action.payload.message
    }),
    [DeleteStore.rejected]: (state, action) => ({
      ...state,
      success: false,
      loading: false,
      error: action.payload.err.message
    }),
    [GetActiveMarketplaces.pending]: (state, action) => ({
      ...state,
      loading: true
    }),
    [GetActiveMarketplaces.fulfilled]: (state, action) => ({
      ...state,
      success: true,
      loading: false,
      message: action.payload.message,
      activeMarketplaces: action.payload.marketplaces 
    }),
    [GetActiveMarketplaces.rejected]: (state, action) => ({
      ...state,
      success: false,
      loading: false,
      error: action.payload.err.message
    }),
    [SendNotificationOnSlack.pending]: (state, action) => ({
      ...state,
      loading: true
    }),
    [SendNotificationOnSlack.fulfilled]: (state, action) => ({
      ...state,
      success: true,
      loading: false,
      message: `Your Message has been delivered to the Team! You'll receive a response soon`
    }),
    [SendNotificationOnSlack.rejected]: (state, action) => ({
      ...state,
      success: false,
      loading: false,
      error: action.payload.err.message
    }),
  }
});

const { reducer, actions } = store;

export const { SetStoreState } = actions;

export default reducer;
