import { round } from 'lodash';

import US from '../assets/images/newImages/flags/US.svg';
import CA from '../assets/images/newImages/flags/CA.svg';
import MX from '../assets/images/newImages/flags/MX.svg';
import BR from '../assets/images/newImages/flags/BR.svg';
import EG from '../assets/images/newImages/flags/EG.svg';
import FR from '../assets/images/newImages/flags/FR.svg';
import GB from '../assets/images/newImages/flags/GB.svg';
import IN from '../assets/images/newImages/flags/IN.svg';
import IT from '../assets/images/newImages/flags/IT.svg';
import NL from '../assets/images/newImages/flags/NL.svg';
import PL from '../assets/images/newImages/flags/PL.svg';
import SE from '../assets/images/newImages/flags/SE.svg';
import SA from '../assets/images/newImages/flags/SA.svg';
import TR from '../assets/images/newImages/flags/TN.svg';
import SG from '../assets/images/newImages/flags/SG.svg';
import AU from '../assets/images/newImages/flags/AU.svg';
import JP from '../assets/images/newImages/flags/JP.svg';
import AE from '../assets/images/newImages/flags/AE.svg';
import DE from '../assets/images/newImages/flags/DE.svg';
import ES from '../assets/images/newImages/flags/ES.svg';

export const FormatValueToLocaleString = (value) => {
    return round(value || 0, 2).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 });
}

export const MARKETPLACES_DETAILS = [
  {
    country: 'canada',
    marketplaceId: 'A2EUQ1WTGCTBG2',
    countryCode: 'CA',
    domain: 'Amazon.ca'
  },
  {
    country: 'usa',
    marketplaceId: 'ATVPDKIKX0DER',
    countryCode: 'US',
    domain: 'Amazon.com'
  },
  {
    country: 'mexico',
    marketplaceId: 'A1AM78C64UM0Y8',
    countryCode: 'MX',
    domain: 'Amazon.com.mx'
  },
  {
    country: 'brazil',
    marketplaceId: 'A2Q3Y263D00KWC',
    countryCode: 'BR',
    domain: 'Amazon.com.br'
  },
  {
    country: 'spain',
    marketplaceId: 'A1RKKUPIHCS9HS',
    countryCode: 'ES',
    domain: 'Amazon.es'
  },
  {
    country: 'uk',
    marketplaceId: 'A1F83G8C2ARO7P',
    countryCode: 'GB',
    domain: 'Amazon.co.uk'
  },
  {
    country: 'france',
    marketplaceId: 'A13V1IB3VIYZZH',
    countryCode: 'FR',
    domain: 'Amazon.fr'
  },
  {
    country: 'netherlands',
    marketplaceId: 'A1805IZSGTT6HS',
    countryCode: 'NL',
    domain: 'Amazon.nl'
  },
  {
    country: 'germany',
    marketplaceId: 'A1PA6795UKMFR9',
    countryCode: 'DE',
    domain: 'Amazon.de'
  },
  {
    country: 'italy',
    marketplaceId: 'APJ6JRA9NG5V4',
    countryCode: 'IT',
    domain: 'Amazon.it'
  },
  {
    country: 'sweden',
    marketplaceId: 'A2NODRKZP88ZB9',
    countryCode: 'SE',
    domain: 'Amazon.se'
  },
  {
    country: 'poland',
    marketplaceId: 'A1C3SOZRARQ6R3',
    countryCode: 'PL',
    domain: 'Amazon.pl'
  },
  {
    country: 'turkey',
    marketplaceId: 'A33AVAJ2PDY3EV',
    countryCode: 'TR',
    domain: 'Amazon.tr'
  },
  {
    country: 'uae',
    marketplaceId: 'A2VIGQ35RCS4UG',
    countryCode: 'AE',
    domain: 'Amazon.ae'
  },
  {
    country: 'india',
    marketplaceId: 'A21TJRUUN4KGV',
    countryCode: 'IN',
    domain: 'Amazon.in'
  },
  {
    country: 'singapore',
    marketplaceId: 'A19VAU5U5O7RUS',
    countryCode: 'SG',
    domain: 'Amazon.sg'
  },
  {
    country: 'australia',
    marketplaceId: 'A39IBJ37TRP1C6',
    countryCode: 'AU',
    domain: 'Amazon.com.au'
  },
  {
    country: 'japan',
    marketplaceId: 'A1VC38T7YXB528',
    countryCode: 'JP',
    domain: 'Amazon.co.jp'
  }
];

export const MARKETPLACE_FLAG = {
  ATVPDKIKX0DER :  US,
  A2EUQ1WTGCTBG2: CA,
  A2Q3Y263D00KWC: BR,
  A1AM78C64UM0Y8: MX,
  A1RKKUPIHCS9HS: ES,
  A1F83G8C2ARO7P: GB,
  A13V1IB3VIYZZH: FR,
  A1805IZSGTT6HS: NL,
  A1PA6795UKMFR9: DE,
  APJ6JRA9NG5V4: IT,
  A2NODRKZP88ZB9: SE,
  A1C3SOZRARQ6R3: PL,
  A33AVAJ2PDY3EV: TR,
  A21TJRUUN4KGV: IN,
  A19VAU5U5O7RUS: SG,
  A39IBJ37TRP1C6: AU,
  A1VC38T7YXB528: JP,
  A2VIGQ35RCS4UG: AE
}
export const getMarketPlaceFlagById = (countryCode) => {
  return countryCode
}

export const marketplaceFlags = (region) => {
    if (region === 'na') {
      return [
        {
          name: "US",
          country: US,
        },
        {
          name: "CA",
          country: CA,
        },
        {
          name: "MX",
          country: MX,
        },
        {
          name: "BR",
          country: BR,
        },
      ];
    }
    if (region === 'eu') {
      return [
        {
          name: "AE",
          country: AE,
        },
        {
          name: "DE",
          country: DE,
        },
        {
          name: "IT",
          country: IT,
        },
        {
          name: "EG",
          country: EG,
        },
        {
          name: "ES",
          country: ES,
        },
        {
          name: "FR",
          country: FR,
        },
        {
          name: "GB",
          country: GB,
        },
        {
          name: "IN",
          country: IN,
        },
        {
          name: "NL",
          country: NL,
        },
        {
          name: "PL",
          country: PL,
        },
        {
          name: "SE",
          country: SE,
        },
        {
          name: "SA",
          country: SA,
        },
        {
          name: "TR",
          country: TR,
        },
      ];
    }
    if (region === 'fe') {
      return [
        {
          name: "SG",
          country: SG,
        },
        {
          name: "AU",
          country: AU,
        },
        {
          name: "JP",
          country: JP,
        },
      ];
    }
  }
  
