import moment from 'moment';
import { isEqual, some } from 'lodash';

import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { AiOutlineDelete } from 'react-icons/ai';
import { FcRefresh } from 'react-icons/fc';

import { useDispatch, useSelector } from 'react-redux';
import { components } from "react-select";

import Pagination from '../../components/Pagination/pagination';
import Table from '../../components/Table/table';
import Toast from '../../components/Toast';
import Modal from '../../components/Modal/modal';
import Select from '../../components/Select';
import SearchInput from '../../components/SearchInput';
import Spin from "../../components/Spin/Index";

import { CURRENCYCODE_BY_MARKETPLACE } from '../../constants';

import { JobsWrapper } from './style';

import { GetJobsList, DeleteJob, SetJobsState, RequeueJob } from '../../redux/slices/jobs-slice';
import { GetAgenciesName, SetAgencyState } from '../../redux/slices/agency-slice';
import { GetStoreNames, SetStoreState } from '../../redux/slices/store-slice';
import { GetUsersName } from '../../redux/slices/user-slice';

const jobTypes = [
  {
    value: 'adjustmentJobs',
    label: 'Adjustment Jobs'
  },
  {
    value: 'processAdjustmentJobs',
    label: 'Process Adjustment Jobs'
  },
  {
    value: 'adminJobs',
    label: 'Admin Jobs'
  },
  {
    value: 'awsTaskServiceJobs',
    label: 'Aws Task Service Jobs'
  },
  {
    value: 'feeJobs',
    label: 'Fee Jobs'
  },
  {
    value: 'financeJobs',
    label: 'Finance Jobs'
  },
  {
    value: 'otherJobs',
    label: 'Other Jobs'
  },
  {
    value: 'lowPriorityJobs',
    label: 'Low Priority Jobs'
  },
  {
    value: 'shipmentJobs',
    label: 'Shipment Jobs'
  },
  {
    value: 'refundJobs',
    label: 'Refund Jobs'
  },
  {
    value: 'reimbursementJobs',
    label: 'Reimbursement Jobs'
  },
  {
    value: 'listingJobs',
    label: 'Listing Jobs'
  }
];

const jobProgress = [
  {
    value: '_STARTED_',
    label: 'Started'
  },
  {
    value: '_IN_PROGRESS_',
    label: 'InProgress'
  },
  {
    value: '_RETRY_',
    label: 'Retry'
  },
  {
    value: '_COMPLETED_',
    label: 'Complete'
  },
  {
    value: '_FAILED_',
    label: 'Failed'
  }
];

let column = [
  {
    value: 'storeName',
    label: 'Store',
  },
  {
    value: 'jobName',
    label: 'Job Name',
  },
  {
    value: 'jobData',
    label: 'Data',
  },
  {
    value: 'status',
    label: 'Status',
  },
  {
    value: 'progress',
    label: 'Progress',
  },
  {
    value: 'nextRunAt',
    label: 'Next Run At',
  },
  {
    value: 'lockedAt',
    label: 'Locked At',
  },
  {
    value: 'delete',
    label: 'Action',
    align:'right'
  }
];

const { Option } = components;
const Tile = (props) => {
  return (
    <Option {...props} value={"default"}>
      <div className="select-option">
        <span className="d-block bold-weight">{props.data.userName !== 'All' ? props.data.userName: null}</span>
        <span>{props.data.name}</span>
      </div>
    </Option>
  );
}

const Index = () => {
  const dispatch = useDispatch();

  const [tableHeader, setTableheader] = useState();
  const [tableBody, setTableBody] = useState();
  const [filters, setFilters] = useState({
    jobType: 'adjustmentJobs',
    jobStatus: 'All',
    storeId: '',
  });

  const [pageNumber, setPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState(25);
  const [storeNames, setStoreNames] = useState([]);
  const [userNames, setUserNames] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [isSelected, setIsSelected] = useState(false);
  const [agenciesNameList, setAgenciesNameList] = useState([]);
  const [selectedAgencyId, setSelectedAgencyId] = useState('');
  const [storeName, setStoreName] = useState({});
  const [userName, setUserName] = useState({});
  const [pass, setPass] = useState(false);

  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [severity, setSeverity] = useState('');
  
  const { storeNamesList, error: storeError } = useSelector((state) => state.store);
  const { usersName } = useSelector((state) => state.user);
  const { jobsList, total, message, error: jobsError, loading } = useSelector((state) => state.job);
  const { agenciesName, error: agencyError } = useSelector((state) => state.agency);

  useEffect(() =>{
    getAgenciesNameList();
  }, []);

  useEffect(() =>{
    dispatch(GetUsersName());
  }, []);

  useEffect(() => {
    getStoreNamesList();
    dispatch(SetJobsState({ field: 'jobsList', value: []}));
    dispatch(SetJobsState({ field: 'total', value: 0}));
  }, [selectedAgencyId]);

  useEffect(() => {
    if (storeNamesList && storeNamesList.length > 0) {
      const storeNamesData = storeNamesList?.map(store => ({
        value: store._id,
        label: `${store.name}`,
        userName: store.userName,
        name: store.name
      }));

      const stores = [];

      stores.push(...storeNamesData);
      setStoreNames(stores);
    } else { 
      setStoreNames([]);
      setStoreName({});
    }
  }, [storeNamesList]);

  useEffect(() => {
    if (usersName && usersName.length > 0) {
      const userNamesData = usersName?.map(user => ({
        value: user._id,
        label: `${user.name}`,        
        name: user.name
      }));

      setUserNames(userNamesData);
    } else { 
      setUserNames([]);
      setUserName({});
    }
  }, [usersName]);

  useEffect(() => {
    if (agenciesName && agenciesName.length > 0) {
      const agenciesNameData = agenciesName?.map(agency => ({
        value: agency._id,
        label: agency.email,
        userName: agency.name
      }));

      const agencies = [];

      agencies.push(...agenciesNameData);
      setAgenciesNameList(agencies);
    } else setAgenciesNameList([]);
  }, [agenciesName])

  useEffect(() => {
    setFilters({
      ...filters,
      'storeId': 'All'
    });
  }, [storeNames]);

  useEffect(() => {
    setFilters({
      ...filters,
      'userId': 'All'
    });
  }, [userNames]);

  useEffect(() => {
      getJobsList();
  }, [filters, pageNumber, pageLimit, selectedAgencyId]);

  useEffect(() => {
    createTable();
  }, [jobsList]);

  useEffect(() => {
    if (storeError) {
      setToastMessage(storeError);
      setSeverity('error');
      setToastOpen(true);
      dispatch(SetStoreState({ field: 'error', value: '' }));
      return;
    } else if (jobsError) {
      setToastMessage(jobsError);
      setSeverity('error');
      setToastOpen(true);
      dispatch(SetJobsState({ field: 'error', value: '' }));
    } else if (agencyError) {
      setToastMessage(agencyError);
      setSeverity('error');
      setToastOpen(true);
      dispatch(SetAgencyState({ field: 'error', value: '' }));
    }
  }, [storeError, jobsError, agencyError]);

  const handleToastClose = (reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToastOpen(false);
  };

  const getStoreNamesList = () => {
    dispatch(GetStoreNames({selectedAgencyId}));
  };

  const getAgenciesNameList = () => {
    dispatch(GetAgenciesName());
  };

  const getJobsList = () => {
    if (filters.jobType !== '' && filters.jobStatus !== '' && filters.storeId !== '') {
      const skip = (pageNumber - 1) * pageLimit;
      const limit = pageLimit;
      const filtersData ={
        ...filters,
        agencyId: selectedAgencyId
      }
      dispatch(GetJobsList({ filters: filtersData, skip, limit }));
    }
  };

  const createTable = () => {
    tableHeaderFun();
    tableBodyFun();
  };

  const handleChange = (e, key) => {
    if (key === 'storeId') {
      setPass(true);
      setStoreName({
        value: e?.value || 'All',
        label: e?.userName || 'All',
        userName: e?.userName || 'All'
      });
    }

    setFilters({
      ...filters,
      [key]: e?.value || 'All'
    });
  };

  const handleSearch = (value, key) => {
    setFilters({
      ...filters,
      [key]: value
    });
    setPageNumber(1);
  };

  const handleAgencyIdChange = (e) => {
    setSelectedAgencyId(e?.value || 'All')
  }

  const handleLimitChange = (e) => {
    setPageNumber(1);
    setPageLimit(e.value);
  }

  const handlePageNumberChange = (e) => {
    const totalPages = Math.ceil(total / pageLimit)
    if (e.key == "Enter") {
      if (e.target.value < 1 || !e.target.value) {
        setPageNumber(1);
      } else if (e.target.value > totalPages) {
        setPageNumber(totalPages)
      } else setPageNumber(e.target.value)
    }
  }

  const handlePageChangeRight = () => {
    const totalPages = Math.ceil(total / pageLimit)
    if (Number(pageNumber) + 1 < totalPages) setPageNumber(Number(pageNumber) + 1);
    else setPageNumber(totalPages > 0 ? totalPages : 1);
  }

  const handlePageChangeLeft = () => {
    if (pageNumber - 1 > 0) setPageNumber(pageNumber - 1);
    else setPageNumber(1);
  }

  const handleDelete = (id) => {
    const jobType = filters.jobType
    dispatch(DeleteJob({ id, jobType }));
    setShowModal(false);
    getJobsList();
  };

  const handleRequeue = (id) => {
    const jobType = filters.jobType
    dispatch(RequeueJob({ id, jobType }));
  };

  const tableHeaderFun = () => {
    const userObj = {
      value: 'userName',
      label: 'User',
    };

    if (filters.jobType === 'adminJobs' && !some(column, item => isEqual(item, userObj))) {
      column.unshift(userObj);
    } else {
      column = column.filter(
        item => item.value !== userObj.value
      );
    }
    
    const tblHeader = column.map((index, i) => {
      return (
        <th key={i}>{index.label}</th>
      );
    });

    setTableheader(tblHeader);
  };

  const tableBodyFun = () => {
    const keysToBeShow =  ['reportType', 'count', 'type', 'caseLimit']
    const tblData = jobsList?.map((index, i) => {
      const { data = {} } = index || {};

      const { marketplaceIds = [] } = data;

      let dataKeys = Object.keys(data);

      const filteredKeywords = dataKeys.filter((key) => keysToBeShow.includes(key));

      let dataString= '';
      if (filteredKeywords.length) {
        for(let i = 0; i < filteredKeywords.length; i += 1 ) {
          dataString = dataString.concat(" ", `${filteredKeywords[i]} : ${data[filteredKeywords[i]]}`);
        }
      }  

      let currencyCodeList = '';
      if (marketplaceIds && marketplaceIds.length) {
         let separator = ',' 
         for(let i = 0; i <marketplaceIds.length; i+=1 ) {
           if (i === marketplaceIds.length - 1) separator = '' 
           const currencyCode = CURRENCYCODE_BY_MARKETPLACE[marketplaceIds[i]];
           currencyCodeList = currencyCodeList.concat(currencyCode, separator);
         }
      }

      return (
        <tr key={i} className="table-row-wrapper">
          {
            filters.jobType === 'adminJobs' ? 
            <>
              <td>
                  <>
                    <span className="d-block mb-0">{index.userName || index.agencyName || 'N/A'}</span>                  
                  </>
              </td>
              <td>
                <>
                  <span className="d-block mb-0">{index.storeName || index.agencyName || ''}</span>
                  <span className="weight-bold small-fontSize">
                    {index.storeName || 'N/A'}
                  </span>
                </>
              </td>
            </> :
            <td>
              <>
                <span className="d-block mb-0">{index.storeName || index.agencyName || ''}</span>
                <span className="weight-bold small-fontSize">
                  {index.userName || 'N/A'}
                </span>
              </>
            </td>
          }

          <td><>{index.name || 'N/A'}</></td>
          <td>
            <span>{currencyCodeList}</span>
            <span className="weight-bold small-fontSize">{dataString}</span>
          </td>
          <td><>{index.state || 'N/A'}</></td>
          <td><>{index.progress || 'N/A'}</></td>
          <td><>{index.nextRunAt ? moment(index.nextRunAt).format('MMMM Do YYYY, h:mm:ss a') : 'N/A'}</></td>
          <td><>{index.lockedAt ?  moment(index.lockedAt).format('MMMM Do YYYY, h:mm:ss a') : 'N/A'}</></td>
          <td>
            <>
              <div className="d-flex justify-content-start">
                <div>
                  <AiOutlineDelete style={{ color: 'red'}} className="cursor-pointer" onClick={() => { setSelectedId(index._id), setShowModal(true) }} />
                </div>
                <div className="ml-2">
                  <FcRefresh className="cursor-pointer" onClick={() => handleRequeue(index._id)} />
                </div>
              </div>
            </>
          </td>
        </tr>
      );
    });
    setTableBody(tblData);
  };

  return (
    <JobsWrapper>
      <Toast
        message={toastMessage}
        severity={severity}
        toastOpen={toastOpen}
        handleToastClose={handleToastClose}
      />
      <div>
        <Row >
          <Col md={6}>
          </Col>
          <Col md={6} >
            <div className="d-flex justify-content-end grid-column-gap-10 ">
              <Select
                styles={{
                  option: (base, state) => ({
                    ...base,
                    paddingLeft: "11px",
                    paddingRight: "11px",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    borderBottom: `1px solid #E3E2E2`,
                    height: "100%",
                    backgroundColor: 'transparent',
                    color: !isSelected ? '#4E5969' : "#064AAC"
                  }),
                  menuList: (provided, state) => ({
                    ...provided,
                    padding: 10,
                  }),
                }}
                className="job-selectors"
                isClearable={true}
                options={agenciesNameList}
                handleChange={(e) => handleAgencyIdChange(e, 'agencyId')}
                components={{ Option: Tile }}
                placeholder= 'Select Agency'
              />
              {
                filters.jobType === 'adminJobs' ?
                  <Select
                    styles={{
                      option: (base, state) => ({
                        ...base,
                        paddingLeft: "11px",
                        paddingRight: "11px",
                        paddingTop: "5px",
                        paddingBottom: "5px",
                        borderBottom: `1px solid #E3E2E2`,
                        height: "100%",
                        backgroundColor: 'transparent',
                        color: !isSelected ? '#4E5969' : "#064AAC"
                      }),
                      menuList: (provided, state) => ({
                        ...provided,
                        padding: 10,
                      }),
                    }}
                    className="job-selectors"
                    options={userNames}
                    isClearable={true}
                    handleChange={(e) => handleChange(e, 'userId')}
                    components={{ Option: Tile }}
                    placeholder='Select User'
                  /> :
                  <Select
                    styles={{
                      option: (base, state) => ({
                        ...base,
                        paddingLeft: "11px",
                        paddingRight: "11px",
                        paddingTop: "5px",
                        paddingBottom: "5px",
                        borderBottom: `1px solid #E3E2E2`,
                        height: "100%",
                        backgroundColor: 'transparent',
                        color: !isSelected ? '#4E5969' : "#064AAC"
                      }),
                      menuList: (provided, state) => ({
                        ...provided,
                        padding: 10,
                      }),
                    }}
                    className="job-selectors"
                    options={storeNames}
                    isClearable={true}
                    handleChange={(e) => handleChange(e, 'storeId')}
                    components={{ Option: Tile }}
                    placeholder='Select Store'
                  />
              }
              <Select
                options={jobTypes}
                className="job-selectors"
                handleChange={(e) => handleChange(e, 'jobType')}
                placeholder= 'Select Job Type'
                defaultValue={jobTypes?.[0] || ''}
              />
              <Select
                options={jobProgress}
                handleChange={(e) => handleChange(e, 'jobStatus')}
                className="job-selectors"
                isClearable={true}
                placeholder= 'Select Job State'
              />
              <SearchInput placeholder="Search by job name" name='search' value={filters?.searchKeyword || ''} onChange={(e) => handleSearch(e.target.value, 'searchKeyword')} />
            </div>
          </Col>
        </Row>
        <Table
          viewHeight="jobs-table"
          tableHeader={<tr>{tableHeader}</tr>}
          tableBody={
            <>
             {loading !== '' && !loading && !jobsList.length ? (
                <tr><td>
                  <div className='error'>
                    <h1>No Data Available</h1>
                  </div>
                </td></tr>
              ) 
              : null
            }
            {loading ? <tr><td><Spin /></td></tr>: tableBody}
            </>
          }
        />
        {showModal ? <Modal
          showModal={showModal}
          modalHeader="Delete Job"
          modalBody="Are you sure you want to delete it ?"
          handleConfirm={() => handleDelete(selectedId)}
          handleClose={() => setShowModal(false)}
          showFooter= {true}
        />
          : null
        }
      </div>
      <Pagination
      className="cases-pagination-wrapper"
        pageLimit={pageLimit}
        total={total}
        pageNumber={pageNumber}
        totalPages={Math.ceil(total / pageLimit)}
        handlePageChangeLeft={handlePageChangeLeft}
        handlePageChangeRight={handlePageChangeRight}
        handleLimitChange={handleLimitChange}
        handlePageNumberChange={handlePageNumberChange} 
      />
    </JobsWrapper>
  );
}

export default Index;
