import React, { useState, useEffect } from "react";
import crypto from 'crypto';
import { useHistory } from 'react-router-dom';
import "react-pro-sidebar/dist/css/styles.css";
import { useDispatch, useSelector } from "react-redux";

import Sidebar from "./sidebar";
import Alert from "../../components/alert";

import { Content } from "./style";
import { GetStoreNames, SetStoreState } from "../../redux/slices/store-slice";
import { APP_IDS, REDIRECT_URIS, ROLES, STORE_STATUS } from "../../constants";
import { ClearStates } from "../../helpers/clear-store-states";

function Layout(props) {
  const hostName = window.location.hostname;
  const history = useHistory();
  const dispatch = useDispatch();
  const [collapsed, setCollapsed] = useState(true);
  const [storeError, setStoreError] = useState([]);
  const [error, setError] = useState('Add Payment Method to start your Case Filling!');
  const { user } = useSelector((state) => state.auth);
  const { storeNamesList, success } = useSelector((state) => state.store);

  useEffect(() => {
    if (user?.payment && Object.keys(user?.payment)?.length || ['admin','affiliate-manager', 'agency-manager'].includes(user?.role)) {
      setError('');
    } else {
      setError('Add Payment Method to start your Case Filling!');
    }
  }, [props.children]);

  const handleStoreCompleteion = (id, spapi) => {
    ClearStates({ dispatch, fromApp: true, storeId: id });

    if (!spapi?.apiConnected) {
      const state = crypto.randomBytes(20).toString("hex");

      dispatch(SetStoreState({ field: 'state', value: state }));

      const url = `https://sellercentral-europe.amazon.com/authorization/select-account?returnTo=/apps/authorize/consent?state=${state}%26application_id=${APP_IDS[hostName]}&redirect_uri=${REDIRECT_URIS[hostName]}`
      window.open(url, "_self");
    } else {
      history.push({
        pathname: '/auth/sp-api'
      });
    }
  }

  useEffect(() => {
    if (storeNamesList && storeNamesList.length) {
      const errorIds = [];
      storeNamesList.map((doc) => {
        if (doc.status !== STORE_STATUS.CONNECTED) {
          errorIds.push(doc._id);
        }
      })
      setStoreError(errorIds);
    }
  }, [storeNamesList])

  return (
    <>
      <div className="d-flex">
        <div className="sidebar-collapsed">
          <Sidebar collapsed={collapsed} />
        </div>
        <Content className="dashboard-content">
          
          {
            ([ROLES.USER].includes(user?.role) && (error || storeError?.length && storeNamesList?.length)) ? (
              <div className="alerts-sr-overlay-wrapper">
              {
                error ? (
                  <Alert
                    buttonText="Add Payment Method"
                    errorText={error}
                    hide={() => setError('')}
                    resolve={() => history.push('/payments')}
                    marginBottom='3px'
                  />
                ) : null
              }
              {storeNamesList.map((item, index) => (
                storeError?.includes(item._id) ? (  
                  <Alert
                    key={item._id}
                    buttonText={`Click Here`}
                    endText={item?.spApi?.apiConnected ? ' to complete the remaining steps.' : 'restore your access.'}
                    errorText={`${item?.spApi?.apiConnected ? `There are some pending tasks for your "${item.name}" store.` : `For "${item.name}" we are unable to fetch your Seller Central Data. `}`}
                    resolve={() => handleStoreCompleteion(item._id, item.spApi)}
                    hide={() => {
                      setStoreError(storeError?.filter(alertId => alertId !== item._id));
                    }}
                    marginBottom='3px'
                  />
                ) : null
              ))}
              </div>
            ) : null
          }
          <div>{props.children}</div>
        </Content>
      </div>
    </>
  );
}

export default Layout;
