import styled from "styled-components";

const RadioStyleWrapper = styled.div`
  .ant-radio-inner {
    border-color: #a6afb1 !important;
    &::after {
      background-color:${(props) => props.theme["new-primary"]} !important;
    }
  }
  .ant-radio{
      top: 4px;
  }
  span {
    color: ${(props) => props.theme["new-primary"]};
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    margin: 0;
  }
  .ant-radio-checked {
    &::after {
      border: 1px solid ${(props) => props.theme["new-primary"]};
    }
  }
`;
export { RadioStyleWrapper };
