import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import Button from "../../../components/ButtonSt";

import PartyIcon from "../../../assets/images/party-popper.svg";

import { ClearStates } from "../../../helpers/clear-store-states";

import { StepFiveWrapper } from "./style";

import { SetAuthState } from "../../../redux/slices/auth-slice";
import { SetStoreState } from "../../../redux/slices/store-slice";
import { SetState } from "../../../redux/slices/account-detail-slice";

const StepFive = ({ handleStepChange }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { storesCount, storeStatus } = useSelector((state) => state.auth);
  const {store} = useSelector((state) => state.store);

  const clearStates = () => {
    ClearStates({ dispatch, fromApp: true });
    handleStepChange(0);
  }

  return (
    <StepFiveWrapper>
      <div className="container-finish-wrapper">
        <img src={PartyIcon} />
        <h6>Congratulations</h6>
        <p>
          Hoorah! You have successfully connected your account. Your account
          manager would start working on your account soon.
        </p>
        <div className="buttons-wrapper">
          <Button onClick={clearStates} outline>Add another Store</Button>
          <Button onClick={() => history.push('/dashboard')}>Go to Dashboard</Button>
        </div>
      </div>
    </StepFiveWrapper>
  );
};

export default StepFive;
