import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Space, Image, Avatar } from 'antd';

import Drawer from '../../../components/Drawer';
import LeftArrow from '../../../assets/icons/arrow-left-short.svg';
import adminAvatar from '../../../assets/images/admin-avatar.svg';
import adminAvatarSp from '../../../assets/images/admin-avatar-sp.svg';
import amazonAvatar from '../../../assets/images/amazon-avatar.svg'
import chatImg1 from '../../../assets/images/chat-img1.png';
import chatImg2 from '../../../assets/images/chat-img2.png';
import chatImg3 from '../../../assets/images/chat-img3.png';

import { ChatBoxWrapper } from '../style';

import { APP_DOMAINS } from '../../../constants';

const imgData = [chatImg1, chatImg2, chatImg3, chatImg2, chatImg1];

const ChatBox = ({ openChat, setOpenChat }) => {
  const {
    caseChat,
    loading
  } = useSelector((state) => state.shipment);

  const formatAmazonMessage = (msg) => {
    let formattedMessage = msg.replace(/<br\s*\/?>/g, '<br>');
    return { __html: formattedMessage };
  }

  return (
    <Drawer show={openChat} size='672px'>
      <ChatBoxWrapper>
        <div className='chat-box-heading'>
          <div className='d-flex align-items-center'>
            <img src={LeftArrow} alt='arrow' onClick={() => setOpenChat(false)} className='mr-2 cursor-pointer' />
            <h6>Conversation Chat</h6>
          </div>
        </div>
        <div className='chat-box'>
          {
            (caseChat?.length && !loading) ? (
              caseChat.map((chat) => (
                <>
                  {
                    chat?.sender !== 'Amazon' ? (

                      <div className='admin-chat'>
                        <Space size={[12]} align='start'>
                          <Avatar src={window.location.hostname === APP_DOMAINS.sellerRepay ? adminAvatarSp : adminAvatar} shape='square' size='small' />
                          <Space direction='vertical' size='small'>
                            <div className='chat-content'>
                              <span>{chat.messageText}</span>
                            </div>
                            <div>
                              <span className='chat-time'>{moment(chat.date).format('LLL')}</span>
                            </div>
                          </Space>

                        </Space>
                      </div>
                    ) : (
                      <div className='user-chat'>
                        <Space direction='vertical' size='small' align='end'>
                          <Space size={[12]} align='start'>
                            <div>
                              <div className='chat-content'>
                                <span dangerouslySetInnerHTML={formatAmazonMessage(chat.messageText)} />
                              </div>
                              <span className='chat-time'>{moment(chat.date).format('LLL')}</span>
                            </div>
                            <Avatar src={amazonAvatar} shape='square' size='small' />
                          </Space>
                        </Space>

                      </div>
                    )
                  }
                </>
              ))
            ) : null
          }
        </div>
      </ChatBoxWrapper>
    </Drawer>
  )
}

export default ChatBox;
