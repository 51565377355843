import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import Button from "../../componentsv2/Button";
import AgGrid from "../../componentsv2/AgGridTable";
import Pagination from "../../componentsv2/Pagination";
import Notification from '../../components/notifications/notification';
import StoreFilter from '../../componentsv2/FilterColumnComponents/storeFilter';
import SearchFilter from '../../componentsv2/FilterColumnComponents/searchFilter';
import UploadDocuments from './components/uploadDocuments';
import ActionIcon from "../../assets/images/action-file-icon.svg";
import ActionIconSp from "../../assets/images/action-file-icon-sp.svg";

import { ShipmentFbaWrapper } from './style';

import { APP_DOMAINS } from '../../constants';
import { FormatValueToLocaleString, shipmentStatusMapping } from '../../helpers/format-value';

import { ExportShipments, GetFbaShipments, SetShipmentState } from '../../redux/slices/shipment-slice';
import { GetStoreNames } from '../../redux/slices/store-slice';

const Index = () => {
  const operationList = [{
    label: 'Equal To',
    value: '$eq'
  }, {
    label: 'Less Than',
    value: '$lt'
  }, {
    label: 'Greater Than',
    value: '$gt'
  }, {
    label: 'Less Than Equal To',
    value: '$lte'
  }, {
    label: 'Greater Than Equal To',
    value: '$gte'
  }]
  const statusList = [{
    label: 'Awaiting Document',
    value: 'Awaiting Document'
  }, {
    label: 'Reimbursed',
    value: 'Reimbursed'
  }, {
    label: 'Declined',
    value: 'Declined'
  }, {
    label: 'Additional Information Required',
    value: 'Additional Information Required'
  }, {
    label: `Pending Action From ${window.location.hostname === APP_DOMAINS.sellerRepay ? 'Seller Repay' : 'Seller Terminal'}`,
    value: 'Pending Action From Seller Terminal'
  }, {
    label: 'Pending Action From Amazon',
    value: 'Pending Action From Amazon'
  }]
  const typeList = [{
    label: 'SPD',
    value: 'SPD'
  }, {
    label: 'LTL',
    value: 'LTL'
  }]
  const dispatch = useDispatch();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState(25);
  const [filters, setFilters] = useState({
    shipmentSorting: { shipmentDate: 1 }
  })
  const [storeNames, setStoreNames] = useState([]);
  const [showExport, setShowExport] = useState(false);
  const [selectedStore, setSelectedStore] = useState({});
  const [shipmentDetail, setShipmentDetail] = useState({});
  const [pageLoading, setPageLoading] = useState(false);

  const {
    shipments,
    total,
    message,
    error,
    success,
    loading,
    invoiceUploaded
  } = useSelector((state) => state.shipment);
  const { storeNamesList } = useSelector((state) => state.store);
  const { user } = useSelector((state) => state.auth);

  const handleFilter = (key, e) => {
    if (key === 'storeId') {
      setSelectedStore(e);
    }

    const isViewFilter = ['storeId', 'discrepancy', 'potentialRecovery'].includes(key);
    const filtersKey = isViewFilter ? 'viewFilters' : 'shipmentFilters';
    const { [filtersKey]: currentFilters = [] } = filters;

    const existingIndex = currentFilters.findIndex(filter => filter.key === key);

    const setFilter = (newFilters) => {
      setFilters({
        ...filters,
        [filtersKey]: newFilters
      });
    };

    let operationCondition = true;
    if (['discrepancy', 'potentialRecovery', 'shipmentExpirationDate'].includes(key)) {
      operationCondition = !!e.operation;
    }

    if (existingIndex === -1) {
      if (e.value && operationCondition) {
        if (key === 'storeId' && filtersKey === 'viewFilters') {
          setShowExport(true);
        }
        const newFilters = [
          ...currentFilters,
          { key, operation: e.operation, value: e.value }
        ];
        setFilter(newFilters);
      }
    } else {
      if (e.value) {
        currentFilters[existingIndex] = { key, operation: e.operation, value: e.value };
      } else {
        currentFilters.splice(existingIndex, 1);
        if (key === 'storeId' && filtersKey === 'viewFilters') {
          setShowExport(false);
        }
      }
      setFilter([...currentFilters]);
    }
  };

  const handleSorting = (key, value) => {
    setFilters((prev) => ({
      ...prev,
      shipmentSorting: { [key]: value },
    }))
  }

  const handlePageNumberChange = (e) => {
    const totalPages = Math.ceil(total / pageLimit)
    if (e.value < 1 || !e.value) {
      setPageNumber(1);
    } else if (e.value > totalPages) {
      setPageNumber(totalPages)
    } else setPageNumber(e.value)
  }

  const handlePageChangeLeft = () => {
    if (pageNumber - 1 > 0) setPageNumber(pageNumber - 1);
    else setPageNumber(1);
  }

  const handlePageChangeRight = () => {
    const totalPages = Math.ceil(total / pageLimit)
    if (Number(pageNumber) + 1 < totalPages) setPageNumber(Number(pageNumber) + 1);
    else setPageNumber(totalPages > 0 ? totalPages : 1);
  }

  const handleLimitChange = (e) => {
    setPageNumber(1);
    setPageLimit(e.value);
  }

  const handleExport = () => {
    dispatch(ExportShipments({ filters, userId: user.userId }));
  }

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {props.data}
    </Tooltip>
  );

  const columnDefs = [
    {
      headerName: 'Store',
      headerComponentFramework: StoreFilter,
      headerComponentParams: {
        title: "Store",
        filterName: 'storeId',
        options: storeNames,
        handleChange: handleFilter,
        selectedStore
      },
      sortable: true,
      filter: true,
      width: 160,
      pinned: 'left',
      field: 'storeId',
      cellRendererFramework: ({ data }) => {
        return (
          <div className='position-relative'>
            {
              ['Awaiting Document', 'Additional Information Required', 'Reimbursable'].includes(data?.status) ? (
                <div id="ribbon">
                  <span className='ribbon-text'>Client Input Required</span>
                </div>
              ) : null
            }
            <div className='shipment-store'>
              <span>{data.storeName}</span>
              <span>{data.userEmail}</span>
            </div>
          </div>
        );
      }
    },
    {
      headerName: 'ID',
      headerComponent: SearchFilter,
      headerComponentParams: {
        title: "ID",
        filterName: 'shipmentConfirmationId',
        handleChange: handleFilter,
        handleSorting,
        sorting: filters?.shipmentSorting
      },
      sortable: true,
      filter: true,
      width: 160,
      field: 'shipmentConfirmationId',
      pinned: 'left',
    },
    {
      headerName: 'Name',
      headerComponent: SearchFilter,
      headerComponentParams: {
        title: "Name",
        filterName: 'name',
        handleChange: handleFilter,
        handleSorting,
        sorting: filters?.shipmentSorting
      },
      sortable: true,
      filter: true,
      width: 250,
      field: 'shipmentName',
      pinned: 'left',
      cellRendererFramework: ({ data }) => {
        return (
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip({ data: data.shipmentName })}
          >
            <span className='shipment-name'>{data.shipmentName}</span>
          </OverlayTrigger>
        );
      }
    },
    {
      headerName: 'Expire in',
      headerComponent: StoreFilter,
      headerComponentParams: {
        title: "Expire in",
        expireIn: true,
        filterName: 'shipmentExpirationDate',
        handleChange: handleFilter,
        operationList,
        handleSorting,
        sorting: filters?.shipmentSorting
      },
      sortable: true,
      filter: true,
      minWidth: 130,
      flex: 1,
      field: 'shipmentExpirationDate',
      cellRendererFramework: ({ data }) => {
        return (
          <span>{data.shipmentExpirationDate !== null ? (data.shipmentExpirationDate > 0 ? data.shipmentExpirationDate + ' days' : data.shipmentExpirationDate === 0 ? 'Today' : 'Expired') : 'N/A'}</span>
        );
      }
    },
    {
      headerName: 'Status',
      headerComponent: StoreFilter,
      headerComponentParams: {
        title: "Status",
        handleChange: handleFilter,
        options: statusList,
        filterName: 'status',
        handleSorting,
        sorting: filters?.shipmentSorting
      },
      sortable: true,
      filter: true,
      minWidth: 245,
      flex: 1,
      field: 'status',
      cellRendererFramework: ({ data }) => {
        let cssClass = '';
        if (['Awaiting Document', 'Reimbursable'].includes(data.status)) {
          cssClass = 'status warning-badge';
        } else if ([
          'Pending Action From Seller Terminal',
          'Pending Action From Amazon',
          'Additional Information Required',
        ].includes(data.status)) {
          cssClass = 'status info-badge';
        } else if (data.status === 'Reimbursed') {
          cssClass = 'status success-badge';
        } else if (['Declined', 'Expired'].includes(data.status)) {
          cssClass = 'status error-badge';
        }
        return (
          <span className={cssClass}>{shipmentStatusMapping(data.status)}</span>
        );
      }
    },
    {
      headerName: 'Type',
      headerComponent: StoreFilter,
      headerComponentParams: {
        title: "Type",
        handleChange: handleFilter,
        options: typeList,
        filterName: 'type',
        handleSorting,
        sorting: filters?.shipmentSorting
      },
      sortable: true,
      filter: true,
      minWidth: 120,
      flex: 1,
      field: 'type',
      cellRendererFramework: ({ data }) => {
        return (
          <span>{data.type || 'N/A'}</span>
        );
      }
    },
    {
      headerName: 'Discrepancy',
      headerComponent: StoreFilter,
      headerComponentParams: {
        title: "Discrepancy",
        handleChange: handleFilter,
        filterName: 'discrepancy',
        operationList,
        handleSorting,
        sorting: filters?.shipmentSorting
      },
      sortable: true,
      filter: true,
      minWidth: 160,
      flex: 1,
      field: 'discrepancy'
    },
    {
      headerName: 'Potential Recovery',
      headerComponent: StoreFilter,
      headerComponentParams: {
        title: "Potential Recovery",
        filterName: 'potentialRecovery',
        handleChange: handleFilter,
        operationList,
        handleSorting,
        sorting: filters?.shipmentSorting
      },
      sortable: true,
      filter: true,
      minWidth: 170,
      flex: 1,
      field: 'potentialRecovery',
      cellRendererFramework: ({ data }) => {
        return (
          <span>{data.potentialRecovery ? FormatValueToLocaleString(data.potentialRecovery) : '-'}</span>
        );
      }
    },
    {
      headerName: 'Case ID',
      headerComponent: SearchFilter,
      headerComponentParams: {
        title: "Case ID",
        filterName: 'caseId',
        handleChange: handleFilter,
        handleSorting,
        sorting: filters?.shipmentSorting
      },
      sortable: true,
      filter: true,
      minWidth: 160,
      flex: 1,
      field: 'caseId',
      cellRendererFramework: ({ data }) => {
        return (
          <span>{data.caseId || 'N/A'}</span>
        );
      }
    },
    {
      headerName: 'Action',
      width: 80,
      pinned: 'right',
      cellRendererFramework: ({ data, rowIndex }) => {
        return (
          <div
            onClick={() => {
              setShipmentDetail(data);
              setOpenDrawer(true);
            }}
            className='cursor-pointer'
          >
            <img src={window.location.hostname === APP_DOMAINS.sellerTerminal ? ActionIcon : ActionIconSp} alt='action' />
          </div>
        );
      }
    },
  ]

  useEffect(() => {
    const skip = (pageNumber - 1) * pageLimit;
    const limit = pageLimit;
    if (!storeNamesList.length) {
      dispatch(GetStoreNames());
    }
    setPageLoading(true);
    dispatch(GetFbaShipments({ filters, skip, limit }));
  }, [filters, pageNumber, pageLimit]);

  useEffect(() => {
    const skip = (pageNumber - 1) * pageLimit;
    const limit = pageLimit;
    if (message &&
      ['Documents Submitted Successfully',
        'Case Submitted Successfully',
        'Notes Saved Successfully',
        'Shipment Updated Successfully'
      ].includes(message)) {
      setPageLoading(true);
      dispatch(GetFbaShipments({ filters, skip, limit }));
    }
  }, [message])

  useEffect(() => {
    setRowData(shipments)
  }, [shipments])

  useEffect(() => {
    if (storeNamesList && storeNamesList.length > 0) {
      const storeNamesData = storeNamesList?.map(store => ({
        value: store._id,
        label: `${store.name}\n${store.email}`,
        userName: store.userName,
        name: store.name
      }));

      const stores = [];
      stores.push(...storeNamesData);
      setStoreNames(stores);
    } else {
      setStoreNames([]);
    }
  }, [storeNamesList]);

  useEffect(() => {
    if (error && !success) {
      Notification({
        type: 'error',
        title: 'Error',
        description: error
      })

      dispatch(SetShipmentState({ field: 'error', value: '' }));
    }
  }, [error])

  useEffect(() => {
    if (pageLoading && !loading) {
      setPageLoading(false);
    }
  }, [loading]);

  return (
    <ShipmentFbaWrapper>
      <div className='d-flex align-items-center justify-content-between mb-3'>
        <h2 className='page-heading'>Shipments</h2>
        {
          (showExport && shipments.length) ? (
            <Button onClick={handleExport} text="Export" className='primary' width="118px" />
          ) : null
        }
      </div>
      {pageLoading && (
        <div className='loading-wrapper'>
          <Spin size='large' />
        </div>
      )}
      <AgGrid
        rowData={rowData}
        columnDefs={columnDefs}
        rowHeight={40}
        height="135px"
      />
      <Pagination
        pageLimit={pageLimit}
        total={total}
        pageNumber={pageNumber}
        totalPages={Math.ceil(total / pageLimit)}
        handlePageChangeLeft={handlePageChangeLeft}
        handlePageChangeRight={handlePageChangeRight}
        handleLimitChange={handleLimitChange}
        handlePageNumberChange={handlePageNumberChange}
      />
      {
        openDrawer ? (
          <UploadDocuments
            data={shipmentDetail}
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
          />
        ) : null
      }
    </ShipmentFbaWrapper>
  )
}

export default Index;
