import { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Row, Col, Spinner } from 'react-bootstrap';
import { useParams, useHistory } from 'react-router-dom';
import { startCase } from 'lodash';
import { BiLeftArrowAlt } from "react-icons/bi";
import { useDispatch, useSelector } from 'react-redux';

import Checkbox from '../../components/CheckBox';
import Button from '../../components/Button';
import SearchInput from '../../components/Inputs/label-input/index';
import Toast from '../../components/Toast';

import MapGrey from '../../assets/images/newImages/map_gray.svg';
import MapEuorpe from '../../assets/images/newImages/map_europe.svg';
import MapEast from '../../assets/images/newImages/map_east.svg';
import MapNorth from '../../assets/images/newImages/map_north.svg';

import { MARKETPLACE_FLAG } from '../../helpers/marketplace-flag';

import { StoreWrapper } from './style';

import { UpdateStoreData, SetStoreState, GetActiveMarketplaces } from '../../redux/slices/store-slice';

const EditStore = () => {
    const history = useHistory();
    const params = useParams();
    const dispatch = useDispatch();

    const { storeId,  message, error, loading, activeMarketplaces, stores } = useSelector((state) => state.store || {});
    const { storesCount,storeStatus, showSidebar } = useSelector((state) => state.auth || {});

    const [marketplace, setMarketplace] = useState([]);
    const [marketplaceList, setMarketplaceList] = useState([]);
    const [localStateStoreName, setLocalStateStoreName] = useState('');
    const [toastOpen, setToastOpen] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [severity, setSeverity] = useState('');
    const [mapLocation, setMapLocation] = useState(MapGrey);

    useEffect(() => {
        if (storeId) {
            dispatch(GetActiveMarketplaces({ storeId }))
        }
    }, [storeId]);


    useEffect(() => {
        if (activeMarketplaces?.length) {
            if (stores) {
                const { marketplaces: currentMarketplaces, name, region } = stores.find(item => item._id == storeId)
                let marketplacesList = activeMarketplaces?.map(item => {
                    return {
                        countryCode: item.countryCode,
                        image: MARKETPLACE_FLAG[`${item.marketplaceId}`],
                    }
                });
                setLocalStateStoreName(name);
                setMarketplace(marketplacesList);
        
                const marketPlaceCodes = currentMarketplaces?.map(item => item.countryCode);
                setMarketplaceList(marketPlaceCodes);
                if (region === 'na') setMapLocation(MapNorth);
                else if (region === 'eu') setMapLocation(MapEuorpe);
                else if (region === 'fe') setMapLocation(MapEast);
            }
        }  
    }, [activeMarketplaces])

    useEffect(() => {
        if (error) {
            setToastMessage(error);
            setSeverity('error');
            setToastOpen(true);
            dispatch(SetStoreState({ field: 'error', value: '' }));
            return;
        }
        if (message && message !== "Store Names List") {
            setToastMessage(message);
            setSeverity('success');
            setToastOpen(true);
            dispatch(SetStoreState({ field: 'message', value: '' }));
            if (message === 'MarketPlaces and StoreName Added successfully') {
                history.push('/setting/stores');
            }
            return;
        }
    }, [error, message]);

    const handleToastClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setToastOpen(false);
    };

    const handleChange = (e) => {
        const selectedIndex = marketplaceList?.indexOf(e.target.name);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(marketplaceList, e.target.name);
        } else {
            newSelected = cloneDeep(marketplaceList);
            newSelected.splice(selectedIndex, 1);
        }
        setMarketplaceList(newSelected);

    };

    const isSelected = (name) => marketplaceList.indexOf(name) !== -1;

    const handleSave = () => {
        dispatch(UpdateStoreData({ marketplaces: marketplaceList, storeName: localStateStoreName, storeId }));
    }

    return (
        <StoreWrapper>
            <Toast
                message={toastMessage}
                severity={severity}
                toastOpen={toastOpen}
                handleToastClose={handleToastClose}
            />
            <div className={showSidebar ? null : "change-background"}>
                <div className="select-region m-0 p-0" >
                    <div className="arrow-box grid-column-gap-10">
                        <BiLeftArrowAlt className="cursor-pointer" onClick={() => history.goBack()} />
                        <h1>Edit Marketplaces</h1>
                    </div>

                    {true ?
                        <>
                            <div className="search-box text-center mt-20 mb-20">
                                <Row className="align-items-center justify-content-center ">
                                    <Col lg={6} md={8} sm={12}>
                                        <SearchInput placeholder="Type Store Name Here" value={localStateStoreName} onChange={(e) => setLocalStateStoreName(e.target.value)} />
                                    </Col>
                                </Row>
                            </div>
                            <Row className="justify-content-center region-scroll">
                                <Col lg={3} md={5}>
                                    <div className="pl-39">
                                        <h3 className="color-primary fw-bold mb-4">Your {startCase(params.id)} Region</h3>
                                        <div className="caption mb-2">Select Region from</div>
                                        <span className="select_region">{startCase(params.id)} Region</span>
                                        <div className="region_marketplace">
                                            <div className="add_scrolll">
                                                {marketplace && marketplace?.map((countries, index) =>
                                                    <div key={index}>
                                                        <Checkbox type="checkbox"
                                                            name={countries.countryCode}
                                                            checked={isSelected(countries.countryCode)}
                                                            label={
                                                                <><img src={countries.image} alt="no-marketplace" />
                                                                    <span className="flag-name">{countries.countryCode}</span></>
                                                            }
                                                            onChange={(e) => handleChange(e, countries.name)}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                            <Button className="w-100" text={loading ? "Saving...." : "Save"} onClick={handleSave} disabled={!localStateStoreName || marketplaceList?.length <= 0} />
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={9} md={7}>
                                    <div className="map_region">
                                        <img src={mapLocation} alt="no-region" />
                                    </div>
                                </Col>
                            </Row>
                        </>
                        :
                        <div style={{ marginTop: '20%', marginLeft: '50%' }}>
                            <Spinner animation="border" variant="primary" />
                        </div>
                    }
                </div>
            </div>
        </StoreWrapper>
    )
}

export default EditStore;
