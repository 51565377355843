import styled from "styled-components";

const HeaderStWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 11px 35px;
  border: 1px solid #dadfe3;
  position: fixed;
  width: 100%;
  background: #fff;
  z-index: 1;
  img.seller-repay {
    width: 108px;
}
  .titleWrapper {
    display: flex;
    align-items: center;
  }
  .back-button{
    padding: 8px 15px;
    svg{
  font-size:18px;
}
  }
`;
const HeaderDropDownStyleWrapper = styled.div`
  .title_wrapper {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    position: relative;
    padding-bottom: 16px;
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      background: #dadfe3;
      height: 1px;
      width: 100%;
    }
    span {
      border-radius: var(--Main-System-10px, 10px);
      background: #e8e8e9;
      width: 36px;
      height: 36px;
      color: #666b70;
      font-family: Lato;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .right_side_text {
    p {
      color: var(--Secondary, #151a26);
      font-family: Lato;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      margin-bottom: 0px;
      &:nth-child(2) {
        color: var(--Label, #8e97a4);
        font-family: "Titillium Web";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
      }
    }
  }
  button.ant-btn.ant-btn-default {
    background: transparent;
    border: none;
    display: flex;
    align-items: center;
    grid-gap: 10px;
    color: var(--Secondary, #151a26);
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    width: 100%;
    border: none;
    box-shadow: none;
    &:hover,
    &:focus {
      background-color: transparent !important;
      box-shadow: none !important;
      outline: none !important;
    }
  }
`;
export { HeaderStWrapper, HeaderDropDownStyleWrapper };
