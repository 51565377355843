import moment from 'moment';
import React, {
  useState,
  useEffect
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";

import Button from "../../components/Button/index";
import Table from "../../components/Table/table";
import Pagination from "../../components/Pagination/pagination";
import Notification from '../../components/notifications/notification';
import Spin from "../../components/Spin/Index";

import { AffiliateDashboardWrapper } from "./style";

import {
  GetMonthlyReceipts,
  SetAffiliateState
} from '../../redux/slices/affiliate-slice';

const AffiliateMonthlyScripts = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { user } = useSelector(state => state.auth);
  const {
    loading,
    success,
    affiliateManagerName,
    totalMonthlyReceipts,
    monthlyReceipts,
    affiliateLink
  } = useSelector(state => state.affiliate);

  const [pageNumber, setPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState(25);

  const handlePageChangeLeft = () => {
    if (pageNumber - 1 > 0) setPageNumber(pageNumber - 1);
    else setPageNumber(1);
  };

  const copyToClipboard = (text) => {
    Notification({
      type: "success",
      title: "Copied !!!"
    });

    return navigator.clipboard
      .writeText(text)
      .then(t => true)
      .catch(e => false);
  };

  const handlePageChangeRight = () => {
    const totalPages = Math.ceil(totalAffiliateUsers / pageLimit)
    if (Number(pageNumber) + 1 < totalPages) setPageNumber(Number(pageNumber) + 1);
    else setPageNumber(totalPages > 0 ? totalPages : 1);
  };

  const handleLimitChange = (e) => {
    setPageNumber(1);
    setPageLimit(e.value);
  };

  const handlePageNumberChange = (e) => {
    const totalPages = Math.ceil(totalAffiliateUsers / pageLimit)
    if (e.key == "Enter") {
      if (e.target.value < 1 || !e.target.value) {
        setPageNumber(1);
      } else if (e.target.value > totalPages) {
        setPageNumber(totalPages)
      } else setPageNumber(e.target.value)
    }
  };

  useEffect(() => {
    dispatch(GetMonthlyReceipts({
      limit: pageLimit,
      skip: (pageNumber - 1) * pageLimit,
    }));
  }, []);

  useEffect(() => {
    dispatch(GetMonthlyReceipts({
      limit: pageLimit,
      skip: (pageNumber - 1) * pageLimit,
    }));
  }, [pageLimit, pageNumber]);

  return (
    <AffiliateDashboardWrapper>
      <div className="page-header-receipts">
        <div className="arrow-box">
          <BiArrowBack
            style={{ width: '30px', height: '30px'}}
            className="cursor-pointer"
            onClick={() => history.goBack()}
          />
        </div>
        <div className="buttons-wrapper">
          <Button CopyIcon text="Affiliate Link" onClick={() => copyToClipboard(affiliateLink)}></Button>
        </div>
      </div>
      <div className="pt-3">
        <Table
        className="table-fd-wrapper"
          tableHeader={
            <tr>
              <th>Name</th>
              <th>Payment Reference</th>
              <th>Date</th>
              <th>Total Paid</th>
              <th>Status</th>
            </tr>
          }
          tableBody={
            <>
            {!loading && !monthlyReceipts.length ? (
              <tr><td>
                <div className='error error-wrapper'>
                  <h1>No Data Available</h1>
                  </div>
                </td></tr>
            ) : null}
            {loading ? <tr><td><Spin /></td></tr> : monthlyReceipts.map((item, index) => (
              <tr>
              <td>{affiliateManagerName}</td>
              <td>{item.paymentReference}</td>
              <td>{moment(item.month).format('lll')}</td>
              <td>€{item.totalPaid}</td>
              <td>{item.status}</td>
            </tr>
            ))}
            </>
          }
        />
        <Pagination
          className="affiliate-pagination-wrapper"
          pageLimit={pageLimit}
          total={totalMonthlyReceipts}
          pageNumber={pageNumber}
          totalPages={Math.ceil(totalMonthlyReceipts / pageLimit)}
          handlePageChangeLeft={handlePageChangeLeft}
          handlePageChangeRight={handlePageChangeRight}
          handleLimitChange={handleLimitChange}
          handlePageNumberChange={handlePageNumberChange}
        />
        </div>
    </AffiliateDashboardWrapper>
  );
};

export default AffiliateMonthlyScripts;
