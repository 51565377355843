import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { axiosBaseUrl } from '../../config/axios-configuration';

import SaveSentryLog from '../../helpers/sentry-log';

const axios = axiosBaseUrl();

export const GetCountries = createAsyncThunk(
  'payment/getCountries',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get('https://restcountries.com/v3.1/all?fields=name');
      return response.data;
    } catch (err) {
        SaveSentryLog(new Error(err), 'GetCountriesError');
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);


export const AddPaymentDetails = createAsyncThunk(
  'payment/addPaymentDetails',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post('/users/add-payment-detail', data);
      return response.data;
    } catch (err) {
        SaveSentryLog(new Error(err), 'GetCountriesError');
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);



const dashboard = createSlice({
  name: 'payment',
  initialState: {
    message: '',
    err: '',
    userId: '',
    total: 0,
    countries: [],
    success: false,
    loading: false,
    payment: {}
  },
  reducers: {
    SetState(state, { payload: { field, value } }) {
      state[field] = value;
    }
  },
  extraReducers: {
    [GetCountries.pending]: (state, action) => ({
      ...state,
      loading: true,
      success: false
    }),
    [GetCountries.fulfilled]: (state, action) => ({
      ...state,
      countries: action.payload,
      loading: false
    }),
    [GetCountries.rejected]: (state, action) => ({
      ...state,
      loading: false,
      success: false

    }),
    [AddPaymentDetails.pending]: (state, action) => ({
      ...state,
      loading: true,
      success: false
    }),
    [AddPaymentDetails.fulfilled]: (state, action) => ({
      ...state,
      payment: action.payload.data,
      success: true,
      loading: false
    }),
    [AddPaymentDetails.rejected]: (state, action) => ({
      ...state,
      loading: false,
      success: false,
      err: action.payload.err.message
    })
  }
});

const { reducer, actions } = dashboard;

export const {
  SetState
} = actions;

export default reducer;
