import { useHistory } from 'react-router-dom';
import { 
  debounce, 
  extend, 
  isEmpty 
} from 'lodash';
import { IoMdMail } from "react-icons/io";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { IoIosWarning } from "react-icons/io";

import moment from "moment";

import React, { useState, useEffect } from "react";
import { BsArrowDownShort, BsArrowUpShort } from 'react-icons/bs';
import { useDispatch, useSelector } from "react-redux";
import { components } from "react-select";
import { Row, Col } from 'react-bootstrap';
import { Stack } from "@mui/material";
import SpinFC from 'antd/lib/spin';

import Checkbox from "../../components/CheckBox/index";
import Drawer from "../../components/Drawer";
import LabelInput from "../../components/Inputs/label-input";
import Modal from "../../components/Modal/modal";
import Pagination from "../../components/Pagination/pagination";
import SearchInput from "../../components/SearchInput";
import Select from '../../components/Select';
import Spin from "../../components/Spin/Index";
import Table from "../../components/Table/table";
import Toast from '../../components/Toast';

import EmailLogs from './email-logs-drawer';

import { 
  GetUsers,
  SendEmailToUser,
  SendRequestDeleteInvitation,
  SetUserState,
  UpdateUserInfo
} from '../../redux/slices/user-slice';
import { GetAgenciesName } from '../../redux/slices/agency-slice';

import { APP_DOMAINS, CURRENCY_SYMBOLS } from '../../constants';

import { CapitalizeFirstLetter, FormatValueToLocaleString } from '../../helpers/format-value';

import { UserWrapper } from "./style";
import { Tooltip } from 'antd';

import { SetStoreState } from '../../redux/slices/store-slice';
import { ImpersonateUser, SetAuthState } from '../../redux/slices/auth-slice';

const tableHeaderColumns = [
  {
    value: 'user',
    label: 'User',
  },
  {
    value: 'status',
    label: 'Status',
  },
  {
    value: 'paymentMethod',
    label: 'Payment Method',
  },
  {
    value: 'sellerId',
    label: 'Seller ID',
  },
  {
    value: 'permissions',
    label: 'Permissions',
  },
  {
    value: 'signedUp',
    label: 'Created At'
  },
  {
    value: 'commissionDiscount',
    label: 'Commission',
  },
  {
    value: 'recoveredAmount',
    label: 'Recovered amount',
  },
  {
    value: 'awaitingAction',
    label: 'Awaiting Action',
  },
  {
    value: 'potentialRecovery',
    label: 'Potential Recovery',
  },
  {
    value: 'number',
    label: 'Phone Number',
  },
  {
    value: 'actions',
    label: 'Actions',
  }
]

const statusDropDown = [
  {
    value: 'Active',
    label: 'Active'
  },
  {
    value: 'Inactive',
    label: 'Inactive'
  }
]

const apiFilterDropdown = [
  {
    value: true,
    label: 'Connected'
  },
  {
    value: false,
    label: 'Disconnected'
  }
]

const storePermissions = ['fbaReports', 'paymentReports', 'appStore', 'manageCases']

const camelCaseToWords = (str) => {
  const words = str.split(/(?=[A-Z])/);
  return words[0].toUpperCase();
};

const formatReimbursedAmountWithCurrencySymbol = (amountReimbursed) => {
  if (amountReimbursed) {
    const columnData = [];

    for (const [key, value] of Object.entries(amountReimbursed)) {
      if (key !== 'units') {
        columnData.push(
          <Stack key={key} direction="column">
            {`${CURRENCY_SYMBOLS[key]}${FormatValueToLocaleString(value)}`}
          </Stack>
        )
      }
    };
    return columnData;
  }
};

const renderPtBreakdown = (breakdown) => {
  if (breakdown) {
    const columnData = [];

    for (const [key, value] of Object.entries(breakdown)) {
      let keyShowed = false;
      for (const [cur, total] of Object.entries(value)) {
        columnData.push(
            <Stack key={key+cur} direction="column">
              {!keyShowed && (
                <>
                {camelCaseToWords(key)}:<br /> 
                </>
              )}
              {`${CURRENCY_SYMBOLS[cur]}${FormatValueToLocaleString(total)}`}
            </Stack>
        )
        keyShowed = true;
      }
    };
    return columnData;
  }
};

const { Option } = components;
const Tile = (props) => {
  return (
    <Option {...props} value={"default"}>
      <div className="select-option">
        <span className="d-block bold-weight">{props.data.userName}</span>
        <span>{props.data.label}</span>
      </div>
    </Option>
  );
}

const Index = () => {
  const hostName = window.location.hostname;
  const dispatch = useDispatch();
  const history = useHistory();

  const [userHeader, setUserHeader] = useState();
  const [userBody, setUserBody] = useState();
  const [paymentFailed, setPaymentFailed] = useState(false);

  const [filters, setFilters] = useState({
    agencyId: 'All',
    status: 'All',
    role: 'All',
    api: 'All',
    sellerId: 'All',
    searchKeyword: '',
  });
  const [searchKeyword, setSearchKeyword] = useState('');

  const [pageNumber, setPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState(25);
  const [agenciesNameList, setAgenciesNameList] = useState([]);
  const [isSelected, setIsSelected] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [impersonateModal, setImpersonateModal] = useState(false);
  const [commissionInfo, setCommissionInfo] = useState({
    editCommission: false,
    updatedCommission: '',
    userId: ''
  })
  const [impersonateUserId, setImpersonateUserId] = useState('');
  const [selectedUser, setSelectedUser] = useState({});
  const [sortType, setSortType] = useState('');
  const [sendEmail, setSendEmail] = useState({
    state: false,
    subject: '',
    email: '',
    userId: ''
  });
  const [showLogs, setShowLogs] = useState(false);
  const [logsUserDetails, setLogsUserDetails] = useState({});

  const { users, total, message, error, success, loading } = useSelector((state) => state.user);
  const { agenciesName } = useSelector((state) => state.agency);
  const { user, admin, inImpersonation, impersonateLoading } = useSelector((state) => state.auth);

  const [roleDropDown, setRoleDropDown] = useState([
    {
      value: 'user',
      label: 'User'
    },
    {
      value: 'affiliate-manager',
      label: 'Affiliate manager'
    }
  ])

  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [severity, setSeverity] = useState('');
  const [storeNames, setStoreNames] = useState([]);

  const emailTypes = [
    {
      label: 'Add Secondary User'
    },
    {
      label: 'Add Permissions'
    },
    {
      label: 'Request New Invite'
    },
    {
      label: 'Invite Not Received'
    },
    {
      label: 'Complete Pending Steps'
    },
    {
      label: 'Add Payment Method'
    },
    {
      label: 'No Reimbursement Found'
    }
  ];

  const formatPermissions = (storeInfo) => {
    const columns = [];
    storeInfo.forEach((store, idx) => {
      if (store?.storeStatus !== 'Pending') {
        columns.push(
          <div key={store.sellerId}>
            <Tooltip
              color='white'
              title={
                <div>
                  <p className='m-0'>
                    <strong>Other Permissions:</strong>
                  </p>
                  <div className='d-flex flex-column'>
                  {storePermissions.map((key) => (
                    <span key={key} style={{ color: store.permissions[key] ? 'green' : 'red' }}>
                      {CapitalizeFirstLetter(key)}
                    </span>
                  ))}
                  </div>
                </div>
              }
            >
              <div className='d-flex'>
                <p className={store.permissions['shipment']? 'text-success mb-0' : 'text-danger mb-0'}>
                  Shipment
                </p>
                <p className="circle-icon p-0">+4</p>
              </div>
            </Tooltip>
          </div>
        );
      }
      if (idx !== storeInfo.length - 1) {
        columns.push(<hr className='my-1' key={`separator-${idx}`} />);
      }
    });

    return columns;
  };

  useEffect(() => {
    tableHeaderFun();
    dispatch(GetAgenciesName());
  }, []);

  useEffect(() => {
    if (user.role === 'admin') {
      const roleData = roleDropDown;
      roleData.push({ value: 'agency-manager', label: 'Agency manager' });
      setRoleDropDown(roleData);
    }
  }, []);

  useEffect(() => {
    if (agenciesName && agenciesName.length > 0) {
      const agenciesNameData = agenciesName?.map(agency => ({
        value: agency._id,
        label: agency.email,
        userName: agency.name
      }));

      const agencies = [];

      agencies.push(...agenciesNameData);
      setAgenciesNameList(agencies);
    } else setAgenciesNameList([]);
  }, [agenciesName])

  useEffect(() => {
    tableHeaderFun()
    getUsersList();

  }, [filters, pageNumber, pageLimit, sortType]);

  useEffect(() => {
    if (success && users.length > 0) {
      const userData = users?.map((index, i) => {
        const colorizedUserInfo = index?.userStoreInfo.map(item => {
          let statusColor = '';
          if (item.storeStatus === 'Connected') {
            statusColor = 'mb-0 bag-custom-wrapper-green';
          } else if (item.storeStatus === 'Invite_Accepted') {
            statusColor = 'mb-0 bag-custom-wrapper-blue';
          } else if (item.storeStatus === 'Invalid_Refresh_Token') {
            statusColor = 'mb-0 bag-custom-wrapper-red';
          } else if (item.storeStatus === 'Pending'){
            statusColor = 'mb-0 bag-custom-wrapper-orange';
          }
        
          return { ...item, statusColor };
        });
        return (
          <tr key={i}>
            <td>
              <>
                <span className='d-block mb-0'>{index.name}</span>
                <span className='weight-bold small-fontSize'>{index.email}</span>
              </>
            </td>
            <td>{index.status || 'N/A'}</td>
            <td style={{ color: index.payment ? 'green' : 'red' }}>{index.payment ? 'YES' : 'NO'}</td>
            <td>
              {colorizedUserInfo.map((tag, i) => (
                <p className={tag.statusColor} key={i}>
                  <Tooltip placement='top' title={
                    <div dangerouslySetInnerHTML={{
                      __html: `${(tag?.storeStatus !== 'Pending' &&  index.sellerCentralLoginEmail.length) ? index.sellerCentralLoginEmail.join(', ') : 'N/A'}
                      <br />${(tag?.storeStatus !== 'Pending' && index.sellerCentralStoreName.length) ? index.sellerCentralStoreName.join(', ') : 'N/A'}`
                    }} />}>
                    <span 
                      onClick={() => {
                        dispatch(SetStoreState({ field: 'searchedStore', value: tag.sellerId }))
                        history.push('/stores');
                      }} 
                      style={{ cursor:'pointer', color: 'white' }}
                    >
                      {tag.sellerId}
                    </span>
                  </Tooltip>
                </p>
              ))}
            </td>
            <td>{index?.userStoreInfo? formatPermissions(index?.userStoreInfo):'No Permissions'}</td>
            <td>{moment(index.createdAt).format('MMM DD, YYYY')}</td>
            <td>
              <div className='d-flex edit-commision-column'>
                {index.commissionDiscount || 'N/A'}
                <span onClick={() => setCommissionInfo((prev) => ({
                  ...prev,
                  editCommission: true,
                  userId: index?._id || '',
                  updatedCommission: index?.commissionDiscount || ''
                }))}>
                  Edit
                </span>
              </div>
            </td>
            <td>
              {index.recovered && isEmpty(index.recovered)
                ? (0).toFixed(2)
                : formatReimbursedAmountWithCurrencySymbol(index.recovered, false) || 'N/A'}
            </td>
            <td>
              {index.claimed && isEmpty(index.claimed)
                ? (0).toFixed(2)
                : formatReimbursedAmountWithCurrencySymbol(index.claimed, false) || 'N/A'}
            </td>
            <td>{index.toBeClaimed && isEmpty(index.toBeClaimed) 
                ? (0).toFixed(2) 
              : <Tooltip placement='left' title={renderPtBreakdown(index.ptBreakdown)}>
                  {formatReimbursedAmountWithCurrencySymbol(index.toBeClaimed, false) || 'N/A'}
                </Tooltip>} 
            </td>
            <td>{index.number || 'N/A'}</td>
            <td>
              <div className='d-flex edit-commision-column'>
                <IoMdMail
                  color={hostName === APP_DOMAINS.sellerRepay ? '#4438F9' : '#008989'}
                  style={{ cursor: 'pointer', width: '23px', height:'23px' }}
                  onClick={() => {
                    const names = index?.userStoreInfo?.map((item) => ({
                      label: item.sellerId,
                      value: item.sellerId
                    }));
                    setStoreNames(names);
                    setSendEmail((prev) => ({
                      ...prev,
                      state: true,
                      email: index.email,
                      userId: index._id
                    }));
                  }}
                />
                <IoMdInformationCircleOutline
                  color={hostName === APP_DOMAINS.sellerRepay ? '#4438F9' : '#008989'}
                  style={{ cursor: 'pointer', width: '23px', height: '23px' }}
                  onClick={() => {
                    setLogsUserDetails(index);
                    setShowLogs(true);
                  }}
                />
              <td>
                <div className='d-flex edit-commision-column'>
                  <span onClick={() => {
                    setImpersonateModal(true);
                    setImpersonateUserId(index._id);
                  }}>
                    Impersonate
                  </span>
                </div>
            </td>
            </div>
            </td>
          </tr>
        );
      });
      setUserBody(userData);
    } else setUserBody([]);
  }, [users]);

  useEffect(() => {
    if (error) {
      setToastMessage(error);
      setSeverity('error');
      setToastOpen(true);
      dispatch(SetUserState({ field: 'error', value: '' }));
      return;
    }
  }, [error]);

  useEffect(()=>{
    if(success && message){
      setToastMessage(message);
      setSeverity('success');
      setToastOpen(true);
      if (message !== 'Email Sent Successfully!') {
        getUsersList();
      }
      dispatch(SetUserState({ field: "message", value: "" }))
    } else if (error && !success) {
      setToastMessage(error);
      setSeverity('error');
      setToastOpen(true);
      dispatch(SetUserState({ field: "error", value: "" }))
    }
  },[error, message, success])

  useEffect(() => {
    if (inImpersonation && (user?.email !== admin?.email)) {
      dispatch(SetUserState({ field: 'user', value: user }))
      setImpersonateModal(false);
      history.push('/');
    }
  }, [user]);

  const handleToastClose = (reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToastOpen(false);
  };

  const getUsersList = () => {
    const skip = (pageNumber - 1) * pageLimit;
    const limit = pageLimit;
    const filtersData = {
      ...filters
    };

    if (searchKeyword) {
      extend(filtersData, searchKeyword);
    }
    dispatch(GetUsers({ filters: filtersData, skip, limit, sortType }));
  };

  const tableHeaderFun = () => {
    const tblHeader = tableHeaderColumns.map((index, i) => {
      return (
        <th key={i}>
          {(index.label === 'Potential Recovery' || index.label === 'Created At' || index.label === 'Recovered amount')
            ? (
              <UserWrapper>
                <span className= 'table-heading-arrow-wrapper'>              
                  <BsArrowDownShort
                    onClick={() => setSortType(`${index.label}-desc`)}
                    style={{ 
                      color: sortType.includes(`${index.label}-desc`) ? hostName === APP_DOMAINS.sellerTerminal ? '#008989' : '#4438F9' : '',
                      fontWeight: 'bold',
                      fontSize: 40
                      }}
                    />
                  <BsArrowUpShort
                    onClick={() => setSortType(`${index.label}-asc`)}
                    style={{ 
                      color: sortType.includes(`${index.label}-asc`) ? hostName === APP_DOMAINS.sellerTerminal ? '#008989' : '#4438F9' : '',
                      fontWeight: 'bold',
                      fontSize: 40
                    }}
                  />                 
                  {index.label}    
                </span>
              </UserWrapper>
            )
            : index.label}
        </th>
      );
    });
    setUserHeader(tblHeader);
  };

  const handleChange = (e, key) => {
    setFilters({
      ...filters,
      [key]: e?.value === undefined ? 'All' : e.value
    });
    setPageNumber(1);
  };

  const handleSendEmailChange = (e, key) => {
    if (key === 'store') {
      setSendEmail((prev) => ({
        ...prev,
        sellerId: e?.value || ""
      }));
    } else {
      setSendEmail((prev) => ({
        ...prev,
        subject: e?.label ||  ""
      }));
    }
  }

  const handelSearch = debounce((value, key) => {
    setFilters({
      ...filters,
      searchKeyword: value
    });
    setPageNumber(1);
  }, 500);

  const handlePageLimit = (e) => {
    setPageLimit(e.value);
  };

  const handlePageNumberChange = (e) => {
    const totalPages = Math.ceil(total / pageLimit)
    if (e.key == "Enter") {
      if (e.target.value < 1 || !e.target.value) {
        setPageNumber(1);
      } else if (e.target.value > totalPages) {
        setPageNumber(totalPages)
      } else setPageNumber(e.target.value)
    }
  }

  const handlePageChangeLeft = () => {
    if (pageNumber - 1 > 0) setPageNumber(pageNumber - 1);
    else setPageNumber(1);
  }

  const handlePageChangeRight = () => {
    const totalPages = Math.ceil(total / pageLimit)
    if (Number(pageNumber) + 1 < totalPages) setPageNumber(Number(pageNumber) + 1);
    else setPageNumber(totalPages > 0 ? totalPages : 1);
  }

  const handleSendMail = () => {
    dispatch(SendRequestDeleteInvitation({ userId: selectedUser._id }))
    setShowModal(false)
  }

  return (
    <>
      <UserWrapper>
        <Toast
          message={toastMessage}
          severity={severity}
          toastOpen={toastOpen}
          handleToastClose={handleToastClose}
        />
        <Row>
          <Col md={4}>
            <div className="header-wrapper" style={{ display: 'contents' }}>
              <h5>Guide</h5>
              <p className="sub-header-item">
                All details about the colors of Seller ID & Status of your users.
              </p>
            </div>
          </Col>
          <Col md={4}>
            <Row>
              <Col md={12}><p className='fs-5 m-0 Medium'><b>Status</b></p></Col>
              <Col md={12}><p className='fs-5 m-0 small'><b>Active</b> ➝ Payment Added</p></Col>
              <Col md={12}><p className='fs-5 m-0 small'><b>Inactive</b> ➝ Payment Not Added or Payment Failed </p></Col>
            </Row>
          </Col>
          <Col md={4}>
            <Row>
              <Col md={12}><p className='fs-5 m-0 Medium'><b>Seller ID</b></p></Col>
              <Col md={12}><p className='fs-5 m-0 small success'><b className='text-success'>Green</b> ➝ Connected</p></Col>
              <Col md={12}><p className='fs-5 m-0 small'><b style={{ color: '#ff6b00de' }} >Orange</b> ➝ Pending</p></Col>
              <Col md={12}><p className='fs-5 m-0 small'><b style={{ color: '#4438F9' }} >Blue</b> ➝ Invite Accepted</p></Col>
              <Col md={12}><p className='fs-5 m-0 small'><b className='text-danger'>Red</b> ➝ Invalid_Refresh_Token</p></Col>
            </Row>
          </Col> 
        </Row>
        <div className="d-flex justify-content-between mb-3 postion-realtive flex-wrap">
          <h3>Users</h3>
          <Row>
            <Col md={6}>
            </Col>
            <Col md={6}>
              <div className="d-flex justify-content-end grid-column-gap-10 ">
                {(user.role === 'admin' || user.role === 'agency-manager') && (
                  <>
                   <Select
                      options={apiFilterDropdown}
                      className="dashbaord-select"
                      handleChange={(e) => handleChange(e, 'api')}
                      placeholder='Select Api Status'
                      isClearable= {true}
                    />
                    <Select
                      options={statusDropDown}
                      className="dashbaord-select"
                      handleChange={(e) => handleChange(e, 'status')}
                      placeholder='Select Status'
                      isClearable= {true}
                    />
                    {
                      ['Inactive', 'PaymentFailed'].includes(filters?.status) ? (
                        <Checkbox
                          label="Payment Failed"
                          value="PaymentFailed"
                          onChange={(e) => {
                            setPaymentFailed(e.target.checked);
                            if (e.target.checked) {
                              setFilters({
                                ...filters,
                                status: 'PaymentFailed'
                              });
                            } else {
                              setFilters({
                                ...filters,
                                status: 'Inactive'
                              });
                            }
                          }}
                          checked={paymentFailed}
                        />
                      ) : null
                    }
                    <Select
                      options={roleDropDown}
                      className="dashbaord-select"
                      handleChange={(e) => handleChange(e, 'role')}
                      placeholder='Select Role'
                      isClearable= {true}
                    />
                  </>
                )}
                {(user.role === 'admin') && (
                  <Select
                    styles={{
                      option: (base, state) => ({
                        ...base,
                        paddingLeft: "11px",
                        paddingRight: "11px",
                        paddingTop: "5px",
                        paddingBottom: "5px",
                        borderBottom: `1px solid #E3E2E2`,
                        height: "100%",
                        backgroundColor: 'transparent',
                        color: !isSelected ? '#4E5969' : "#064AAC"
                      }),
                      menuList: (provided, state) => ({
                        ...provided,
                        padding: 10,
                      }),
                    }}
                    className="dashbaord-select"
                    options={agenciesNameList}
                    isClearable={true}
                    handleChange={(e) => handleChange(e, 'agencyId')}
                    components={{ Option: Tile }}
                    placeholder='Select the agency'
                  />
                )}
                <SearchInput placeholder="Search By Name/Email" name='search' onChange={(e) => handelSearch(e.target.value)} />
                <SearchInput placeholder="Search By Seller Id" name='search' onChange={(e) => handleChange(e.target, 'sellerId')} />
              </div>
            </Col>
          </Row>
        </div>

        <Table
          viewHeight="users-table users-table-wrapper"
          tableHeader={<tr>{userHeader}</tr>}
          tableBody={
            <>
              {!loading && !users.length ? (
                <tr><td>
                  <div className='error'>
                    <h1>No Data Available</h1>
                  </div>
                </td></tr>
              ) : null}
              {loading ? <tr><td><Spin /></td></tr> : userBody}
            </>
          }
        />

        <Pagination
          total={total}
          pageLimit={pageLimit}
          pageNumber={pageNumber}
          totalPages={Math.ceil(total / pageLimit)}
          handlePageChangeLeft={handlePageChangeLeft}
          handlePageChangeRight={handlePageChangeRight}
          handleLimitChange={handlePageLimit}
          handlePageNumberChange={handlePageNumberChange}
        />
      </UserWrapper>
      {showModal ? <Modal
        showModal={showModal}
        modalHeader="Send delete the invitation Notification Email"
        modalBody={"Do you really want to Delete the Invitation to " + selectedUser.name+"?"}
        handleConfirm={handleSendMail}
        handleClose={() => setShowModal(false)}
        showFooter={true}
      />
        : null
      }
      {commissionInfo?.editCommission ? <Modal
        size='sm'
        showModal={commissionInfo?.editCommission}
        modalHeader="Update User Commission"
        modalBody={
          <>
            <LabelInput
              label="Updated Value:"
              placeholder="Updated Commission Value"
              value={commissionInfo?.updatedCommission}
              onChange={(e) => setCommissionInfo((prev) => ({
                ...prev,
                updatedCommission: e.target.value
              }))}
            />
          </>
        }
        handleConfirm={()=> {
          if (commissionInfo?.updatedCommission > 0) {
              dispatch(UpdateUserInfo({
                userId: commissionInfo?.userId,
                commissionDiscount: commissionInfo?.updatedCommission
              }));
              setCommissionInfo({
                editCommission: false,
                updatedCommission: '',
                userId: ''
              })
            }
          }
        }
        handleClose={() => setCommissionInfo({
          editCommission: false,
          updatedCommission: '',
          userId: ''
        })}
        showFooter={true}
      />
        : null
      }
      {sendEmail?.state ? <Modal
        size='sm'
        showModal={sendEmail?.state}
        modalHeader="Send Email"
        modalBody={
          <>
            <Select
              options={emailTypes}
              className="dashbaord-select my-2"
              handleChange={(e) => handleSendEmailChange(e)}
              placeholder='Select Email Type'
              isClearable={true}
            />
            <Select
              options={storeNames}
              className="dashbaord-select my-2"
              handleChange={(e) => handleSendEmailChange(e, 'store')}
              placeholder='Select Store'
              isClearable={true}
            />
          </>
        }
        handleConfirm={() => {
          const {
            subject,
            userId,
            sellerId
          } = sendEmail || {};

          if (subject && userId && sellerId) {
            dispatch(SendEmailToUser({
              ...sendEmail
            }));
            setSendEmail({
              state: false,
              subject: '',
              email: '',
              userId: ''
            })
          }
        }
        }
        handleClose={() => setSendEmail({
          state: false,
          subject: '',
          email: '',
          userId: ''
        })}
        showFooter={true}
      />
        : null
      }
      {
        showLogs ? (
          <Drawer show={showLogs} size='75%'>
            <EmailLogs
              userData={logsUserDetails}
              handleClick = {()=> setShowLogs(false)}
            />
          </Drawer>
        ): null
      }
      {impersonateModal ? 
            <Modal
            size='lg'
            showModal={true}
            modalHeader="Impersonate User"
            modalBody={
              <>
              { impersonateLoading ?
                  <SpinFC style={{ marginLeft: '370px' }} />
               : null
              } 
               <IoIosWarning
                  color={'#F2F705'}
                  style={{ width: '150px', height: '150px', margin: 'auto', display: 'flex' }}
                />
                 <p>
                  After this action, you will be acting as a user & access only certain modules of the app.
                  Be aware of the consequences as the actions you performed will be irreversible.
                </p>
              </>
            }
            handleConfirm={()=> {
              dispatch(SetStoreState({ field: 'storeNamesList', value: [] }));
              dispatch(SetAuthState({ field: 'showSidebar', value: false }));
              dispatch(ImpersonateUser({
                userId: impersonateUserId,
              }));
            }
            }
            handleClose={() => {
              setImpersonateModal(false);
            }}
            showFooter={true}
            /> : null}
    </>
  );
};

export default Index;
