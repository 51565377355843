import styled from "styled-components";
import GreyCard from "../../../assets/images/grey-card.svg";

const TitleStyleWrapper = styled.div`
  max-width: 239px;

  h3.current-tile {
    margin: 0px;
    color: var(--Selected_Tab, #1890ff);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
  }
  p {
    color: var(--Body-Text-Varaint, #98a1a5);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    &.process-text {
      margin-top: 7px;
      margin-bottom: 24px;
    }
  }
  .sub_title_wrapper {
    padding-top: 7px;
    h4 {
      color: var(--text-field-body-color, #5e718d);
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px; /* 133.333% */
    }
    p {
      color: var(--Label, #8e97a4) !important;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px; /* 133.333% */
      margin: 0px;
      padding: 0px !important;
    }
  }
`;
const StepTwoWrapper = styled.div`
  .card_wrapper {
    border-radius: 8px;
    border: 1px solid var(--Border-Variant, #dadfe3);
    background: var(--White, #fff);
    padding: 22.5px 32px;
    .card_overlay_wrapper {
      max-width: 344px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin: auto;
      grid-row-gap: 16px;

      .card_overlay_item {
        display: flex;
        flex-direction: column;
        grid-row-gap: 8px;
        p {
          color: var(--Body-Darker, #474747);
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
          margin: 0px;
        }
        h3 {
          color: var(--Secondary, #151a26);
          text-align: center;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 20px;
          margin: 0px;
        }
      }
    }
  }
  .step_two_table_wrapper .ant-table-tbody > tr.ant-table-row:hover > td,
  .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: #fff;
  }
  .card_ui_wrapper {
    display: flex;
    align-items: center;
    grid-gap: 8px;
    padding: 6px 16px;
    border-radius: 3px;
    justify-content: space-between;
    border: 1px solid var(--Border-Variant, #dadfe3);
    user-select: none;
    align-items: center;
    height: 24px;
    grid-gap: 8px;
    p {
      margin: 0px;
      color: var(--text-field-body-color, #5e718d);
      font-style: normal;
      font-size: 10px !important;
      white-space: nowrap;
      font-weight: 500;
      line-height: 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      grid-gap: 8px;
      margin: 0px !important;
    }
    img {
      cursor: pointer;
    }
  }
  .overlay_card_item {
    display: flex;
    grid-gap: 16px;
    align-items: center;
  }
  .card_ui_item {
    display: flex;
    align-items: center;
    grid-gap: 8px;
    padding: 6px 8px;
    border-radius: 3px;
    justify-content: space-between;
    border: 1px solid var(--Border-Variant, #dadfe3);
    user-select: none;
    align-items: center;
    color: var(--text-field-body-color, #5e718d);
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px;
    height: 24px;
    margin: 0px !important;
  }

  .overlay_wrapper {
    border-radius: 8px;
    border: 1px solid var(--Border-Variant, #dadfe3);
    background: var(--White, #fff);
    padding: 16px;
    margin-top: 16px;
    .header_wrapper {
      display: flex;
      align-items: center;
      grid-gap: 12px;
      margin-bottom: 18px;
      h2 {
        color: var(--Secondary, #151a26);
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        margin: 0;
      }
    }
    .card-items_wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 16px;
      .card_items {
        display: flex;
        align-items: center;
        grid-gap: 16px;
        padding-bottom: 16px;
        border-bottom: 1px solid #dadfe3;
        button {
          width: 84px;
        }
        p {
          margin-bottom: 0px;
          color: var(--text-field-body-color, #5e718d);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }
      }
      .right_side_content_wrapper {
        p {
          color: var(--text-field-body-color, #5e718d);
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
          margin-top: 32px;
          margin-bottom: 12px;
        }
      }

      .table_container {
        border-radius: 4px;
        border: 1px solid ${(props) => props.theme["new-primary"]};
        background: var(--White, #fff);
        box-shadow: 0px 1.852px 3.148px 0px rgba(0, 0, 0, 0.01),
          0px 8.148px 6.519px 0px rgba(0, 0, 0, 0.01),
          0px 20px 13px 0px rgba(0, 0, 0, 0.01),
          0px 38.519px 25.481px 0px rgba(0, 0, 0, 0.02);
        padding: 10px 16px;
        h6 {
          color: var(--text-field-body-color, #5e718d);
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 16px;
          margin-bottom: 12px;
        }
      }
    }
  }
  .card_items_overlay {
    min-height: 354px;
    border-radius: 8px;
    border: 1px solid var(--Border-Variant, #dadfe3);
    background: var(--White, #fff);
    padding: 16px;
    padding-bottom: 114px;
  }
  .card_items_content-overlay {
    p {
      margin-top: 32px;
      margin-bottom: 12px;
      color: #5e718d;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      margin-left: 2px;
    }
  }
  .right_side_content_overlay {
    border-radius: 8px;
    border: 1px solid var(--Border-Variant, #dadfe3);
    background: var(--White, #fff);
    padding: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    .right_side_content_wrapper {
      max-width: 323px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      p {
        margin: 0px !important;
        color: var(--text-field-body-color, #5e718d);
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
      h4 {
        color: var(--Secondary, #151a26);
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        margin: 0px;
        margin-top: 16px;
        margin-bottom: 12px;
      }
      button {
        width: 100%;
        margin-top: 24px;
      }
    }
  }
`;
const EligibleWrapper = styled.div`
  border-radius: 8px;
  border: 1px solid var(--Placeholder-Border, #dbdfe1);
  background: var(--White, #fff);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .card_ui_overlay {
    background-image: url(${GreyCard});
    width: 500px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    height: 317px;
    .card_ui_wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      h4 {
        color: var(--Secondary, #151a26);
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        margin: 0;
        margin-top: 16px;
        margin-bottom: 12px;
      }
      p {
        max-width: 353px;
        color: var(--Body-Darker, #474747);
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        margin: 0px;
      }
      button {
        margin-top: 16px;
        width: 397px;
        justify-content: center;
      }
    }
  }
  .ant-progress-line {
    position: relative;
    width: calc(100% - 30%);
    font-size: 14px;
    margin: auto;
    text-align: center;
    margin: 12px auto;
  }
`;
export { TitleStyleWrapper, StepTwoWrapper, EligibleWrapper };
