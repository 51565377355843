import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import Header from '../Header/header';
import Button from '../../components/Button';

import Check from '../../assets/images/success-stars.svg';
import US from '../../assets/images/newImages/flags/US.svg';
import CA from '../../assets/images/newImages/flags/CA.svg';
import MX from '../../assets/images/newImages/flags/MX.svg';
import BR from '../../assets/images/newImages/flags/BR.svg';
import EG from '../../assets/images/newImages/flags/EG.svg';
import FR from '../../assets/images/newImages/flags/FR.svg';
import GB from '../../assets/images/newImages/flags/GB.svg';
import IN from '../../assets/images/newImages/flags/IN.svg';
import IT from '../../assets/images/newImages/flags/IT.svg';
import NL from '../../assets/images/newImages/flags/NL.svg';
import PL from '../../assets/images/newImages/flags/PL.svg';
import SE from '../../assets/images/newImages/flags/SE.svg';
import SA from '../../assets/images/newImages/flags/SA.svg';
import TN from '../../assets/images/newImages/flags/TN.svg';
import SG from '../../assets/images/newImages/flags/SG.svg';
import AU from '../../assets/images/newImages/flags/AU.svg';
import JP from '../../assets/images/newImages/flags/JP.svg';
import AE from '../../assets/images/newImages/flags/AE.svg';
import DE from '../../assets/images/newImages/flags/DE.svg';
import ES from '../../assets/images/newImages/flags/ES.svg';
import { useSelector } from 'react-redux';

import { StoreWrapper } from './style';

import { AGENCIES_EMAIL } from '../../constants';

const northAmerica = [
  US, CA, MX, BR
];

const europe = [
  AE, DE, EG, ES, FR, GB, IN, IT, NL, PL, SE, SA, TN
];

const east = [
  SG, AU, JP
];

const hostName = window.location.hostname;

const AmazonSuccess = () => {
  const history = useHistory();

  const { user, showSidebar } = useSelector((state) => state.auth);

  const handleAddStore = () => {
    history.push('/dashboard');
  };

  return (
    <>
      {showSidebar ? null : <Header congratulations={true} sidebar={showSidebar} />}
      <StoreWrapper className='store-success'>
        <Row>
          <Col lg={!showSidebar ? 8 : 9} sm={7} className="">
            <div className={showSidebar ? 'success-content' : 'offset-6'}>
              <div className={`justify-content-center align-items-center d-flex flex-column ${!showSidebar ? 'success-content-wrapper' : null}`}>
                <div className="success-check">
                  <img src={Check} alt="no-success" />
                </div>
                <h2>Congratulations!</h2>
                <p>Permissions Granted &<br /> Store Added Successfully!</p>
                <div className="d-flex grid-column-gap-30 align-items-center">
                  <Button text="Let's Get Started" className="outlined btn-congratulation-wrapper" onClick={handleAddStore} />
                </div>
              </div>
            </div>
          </Col>
          <Col lg={!showSidebar ? 4 : 3} sm={7} className="">
            <div className={showSidebar ? null : "onboarding-space-right"}>
              <div className={showSidebar ? 'with-siderbar-store-layout' : null}>
              <div className="onboarding-right-section">
                <div className='info-box-main'>
                  <div className="info-block text-center">
                    <h3>Have a Question?</h3>
                    <p className='contact-text-wrapper'>Contact your case manager:</p>
                    <a href={`mailto:${AGENCIES_EMAIL[user.agencyId]}`}>
                      <Button text="Contact" />
                    </a>
                  </div>
                </div>
              </div>
              </div>
            </div>
          </Col>
        </Row>
      </StoreWrapper>
    </>
  )
}

export default AmazonSuccess
