import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import 'bootstrap/dist/css/bootstrap.min.css';

import AuthRoute from './routes/auth-route';
import AppRoute from './routes/app-route'

import { theme, sellerTerminalTheme} from './assets/themes/index';

import { APP_DOMAINS } from './constants';

import './assets/css/style.css';

const App = () => {
  const host = window.location.hostname;
  return (
    <ThemeProvider theme={host === APP_DOMAINS.sellerTerminal ? { ...sellerTerminalTheme } : { ...theme }}>
      <BrowserRouter>
        <Switch>
          <AuthRoute path='/auth' />
          <AppRoute path='/' />
        </Switch>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
