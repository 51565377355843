import { isEmpty  } from 'lodash';
import React, { useEffect, useState } from 'react';
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";

import Select from "../../components/Select/index";

import { PaginationWrapper } from './style';

const Index = (props) => {
  const {
    total,
    totalPages,
    pageNumber,
    pageLimit,
    handleLimitChange,
    defaultValue,
    handlePageNumberChange,
    handlePageChangeRight,
    handlePageChangeLeft,
    className
  } = props;

  const [page, setPage] = useState(pageNumber);
  const [selectedValue, setSelectedValue] = useState({ value: 25, label: 25 });
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (defaultValue) {
      if (!isEmpty(defaultValue)) {
        setSelectedValue(defaultValue);
      }
    }
  }, [pageNumber, defaultValue]);

  useEffect(() => {
    if (totalPages) {
      const data = [];
      console.log({ totalPages })
      for (let i = 0; i < totalPages; i += 1) {
        data.push({ value: i + 1, label: i + 1 })
      }
      console.log({ data });
      setOptions(data);
    }
  }, [totalPages])

  const pageOptions = [
    { value: 25, label: 25 },
    { value: 50, label: 50 },
    { value: 100, label: 100 },
  ];

  const handleLimit = (e) => {
   setSelectedValue(e);
    handleLimitChange(e);
  }

  return (
    <PaginationWrapper className={className}>
      <div className='d-flex justify-content-between'>
        <div className='d-flex align-items-center'>
          <div className='d-flex item-per-page align-items-center'>
            <span className='fs-14'>Items per page:</span>
            <Select
              menuPlacement="top"
              value={selectedValue}
              className="pagination-select"
              options={pageOptions}
              handleChange={(e) => handleLimit(e)}
            />
          </div>
          <div className='total-items d-flex ml-3 fs-14'>
          <span className='pr-1'>{(pageLimit * pageNumber) < total ? (pageLimit * pageNumber) : total}</span> of {total} items
          </div>
        </div>
        <div className="d-flex total-pages align-items-center" >
          <div className='d-flex select-pages align-items-center fs-14'>
            <Select
              menuPlacement="top"
              value={{ value: pageNumber, label: pageNumber }}
              className="pagination-select"
              options={options}
              handleChange={(e) => handlePageNumberChange(e)}
            />
            <span className='pl-2 page-number'>of {totalPages} pages</span> 
          </div>
          <div className='arrows'>
            <BiChevronLeft className='left-controle' onClick={handlePageChangeLeft} />
            <div className='arrow-border' />
            <BiChevronRight className='left-controle' onClick={handlePageChangeRight} />
          </div>
        </div>
      </div>
    </PaginationWrapper>
  );
}

export default Index;
