import { debounce, isEmpty } from "lodash";
import moment from "moment";

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { BsEyeFill } from "react-icons/bs";
import { components } from "react-select";
import { ButtonGroup } from "react-bootstrap";
import { Stack } from "@mui/material";

import Button from "../../components/Button";
import Modal from "../../components/Modal/modal";
import Pagination from "../../components/Pagination/pagination";
import Select from "../../components/Select";
import SearchInput from "../../components/SearchInput";
import Spin from "../../components/Spin/Index";
import Table from "../../components/Table/table";
import Toast from '../../components/Toast';
import Notification from '../../components/notifications/notification'

import { CURRENCY_SYMBOLS } from '../../constants';

import {
  GetInvoiceSummary,
  PayInvoice,
  SetInvoiceState,
  SetInvoiceStateFilters
} from "../../redux/slices/invoice-slice";
import {
  GetUsersName,
  SetUserState
} from "../../redux/slices/user-slice";
import { GetStoreNames } from '../../redux/slices/store-slice';

import {
  FormatValueToLocaleString,
  CapitalizeFirstLetter
} from '../../helpers/format-value'

import { InvoiceWrapper } from "./style";

const { Option } = components;
const Tile = (props) => {
  return (
    <Option {...props} >
      <div className="select-option">
        <span className="d-block bold-weight">{props.data.userEmail !== 'All' ? props.data.userEmail: null}</span>
        <span>{props.data.name}</span>
      </div>
    </Option>
  );
}

const formatExchangeRatesWithCurrencySymbol = (exchangeRates) => {
  if (exchangeRates) {
    const columnData = [];
    for (const [key, value] of Object.entries(exchangeRates)) {
      const currencySymbol = CURRENCY_SYMBOLS[key];
      columnData.push(
        <Stack key={key} className="q36rmd" direction="column" spacing={5}>
          {currencySymbol + '' + FormatValueToLocaleString(value)}
        </Stack>
      )
    };
    return columnData;
  }

}

const Index = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  
  const {
    invoicesSummary,
    total,
    message,
    error,
    success,
    loading,
    invoiceSummarayFilters
  } = useSelector((state) => state.invoice);
  const { storeNamesList } = useSelector((state) => state.store);
  const { usersName } = useSelector((state) => state.user);
  const { user } = useSelector((state) => state.auth);

  const [invoice, setInvoice] = useState(true);
  const [invoiceObj, setInvoiceObj] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [invoiceBody, setInvoiceBody] = useState([]);
  const [usersNameData, setUsersNameData] = useState([]);
  const [isSelected, setIsSelected] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedStore, setSelectedStore] = useState(null);
  const [storeDropdownData, setDropdownData] = useState([]);

  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [severity, setSeverity] = useState('');
  const [pass, setPass] = useState(false);
  const [filters, setFilters] = useState({
    ...invoiceSummarayFilters,
    pageNumber: 1,
    pageLimit: 25,
    searchKeyword: '',
    status: 'paid',
    userId: '',
    pageOption: {value: 25, label: 25},
    storeId: 'All'
  });

  const handleInvoiceModal = (userId, storeId, invoiceId, invoiceNumber) => {
    setShowModal(true);
    setInvoiceObj({
      userId,
      storeId,
      invoiceId,
      invoiceNumber
    });
  };

  const handlePayInvoice = () => {
    dispatch(PayInvoice(invoiceObj));
    setShowModal(false);
  };

  useEffect(() => {
    dispatch(GetStoreNames());
    const path = history.location.state?.from;
    if (typeof(path) === "undefined") {
      dispatch(SetInvoiceStateFilters({ module: 'invoiceSummarayFilters', field: 'pageNumber', value: 1}));
      dispatch(SetInvoiceStateFilters({ module: 'invoiceSummarayFilters', field: 'pageLimit', value: 25}));
      dispatch(SetInvoiceStateFilters({ module: 'invoiceSummarayFilters', field: 'searchKeyword', value: ''}));
      dispatch(SetInvoiceStateFilters({ module: 'invoiceSummarayFilters', field: 'status', value: 'paid'}));
      dispatch(SetInvoiceStateFilters({ module: 'invoiceSummarayFilters', field: 'userId', value: ''}));
      dispatch(SetInvoiceStateFilters({ module: 'invoiceSummarayFilters', field: 'pageOption', value: {}}));
    } else {
      history.push({
        state: {
          from: undefined
        }
      });
    }

    if (user.role === 'admin' || user.role === 'agency-manager' || user.role === "affiliate-manager") {
      dispatch(GetUsersName());
    }

    return () => {
      if (typeof (path) !== "undefined" && !history.location.pathname.includes('invoice')) {
        dispatch(SetInvoiceStateFilters({ module: 'invoiceSummarayFilters', field: 'pageNumber', value: 1 }));
        dispatch(SetInvoiceStateFilters({ module: 'invoiceSummarayFilters', field: 'pageLimit', value: 25 }));
        dispatch(SetInvoiceStateFilters({ module: 'invoiceSummarayFilters', field: 'searchKeyword', value: '' }));
        dispatch(SetInvoiceStateFilters({ module: 'invoiceSummarayFilters', field: 'status', value: 'paid' }));
        dispatch(SetInvoiceStateFilters({ module: 'invoiceSummarayFilters', field: 'userId', value: '' }));
        dispatch(SetInvoiceStateFilters({ module: 'invoiceSummarayFilters', field: 'pageOption', value: {} }));
        dispatch(SetInvoiceStateFilters({ module: 'invoiceSummarayFilters', field: 'storeId', value: 'All' }));
      }
    };
  }, []);

  useEffect(() => {
    if (!isEmpty(filters)){
      getInvoiceSummary(filters);
    }
  }, [filters]);

  useEffect(() => {
    if (storeNamesList && storeNamesList.length) {
      const data = storeNamesList.map((doc) => {
        return {
          storeId: doc._id,
          value: [doc._id, doc.email],
          label: `${doc.name || 'N/A'}`,
          userEmail: doc.name,
          name: doc.email
        }
      });
      if (filters.storeId !== 'All') {
        const storeData = data.find(store => store.storeId === filters.storeId);
        setSelectedStore(storeData || null);
      }
      setFilters({ ...filters,  storeId: 'All', ...invoiceSummarayFilters });
      setDropdownData(data);
    } else {
      setDropdownData([]);
    }
  }, [storeNamesList])

  useEffect(() => {
    if (usersName && usersName.length > 0) {
      dispatch(SetUserState({ field: 'message', value: ''}));
      const usersNamesData = usersName.map(user => ({
        value: [user._id, user.email],
        label: `${user.name}`,
        userEmail: user.name,
        name: user.email
      }));

      if (filters.userId) {
        setPass(true);
        let userSelected = usersNamesData.find(user => user.value?.[0] === filters.userId);
        setSelectedUser(userSelected || null);
      } 

      const users = [];

      users.push(...usersNamesData);
      setUsersNameData(users);
    }
  }, [usersName]);

  useEffect(() => {
    if (success && invoicesSummary.length > 0) {
      const invoiceSummaryData = invoicesSummary.map((item, i) => {
        const currencySymbol = CURRENCY_SYMBOLS[item.currency.toUpperCase()] || '$';
        const { totalAmountReimbursed } = item.totalAmountReimbursed;
        let status = item.status;
        let classNameForStatus = 'table-badge paid';
        if (status === 'open') {
          status = 'Unpaid';
          classNameForStatus = 'table-badge unpaid';
        }
        return (
          <tr key={i} style= {item.refunded ? { backgroundColor: '#FFFDF2'} : null}>
            <td>
              <>
                <span className="d-block mb-0">{item.invoiceNumber}</span>
                <span className="weight-bold small-fontSize">
                  {moment(item.invoiceCreatedAt).format('ll')}
                </span>
              </>
            </td>
            {(user.role === 'agency-manager' || user.role === 'admin' || user.role == "affiliate-manager") && (
              <td>
              <>
                <span className="d-block mb-0">{item.storeName}</span>
                <span className="weight-bold small-fontSize">
                  {item.userEmail}
                </span>
              </>
            </td>
            )}
            <td><>{formatExchangeRatesWithCurrencySymbol(totalAmountReimbursed, false)}</></td>
            <td>
              {status === 'Unpaid' || status === 'draft' ?
                <>
                  {currencySymbol + '' + FormatValueToLocaleString(item.amountDue)}
                </> :
                <>
                  {currencySymbol + '' + FormatValueToLocaleString(item.amountPaid)}
                </>
              }
            </td>
            {
              user?.role === 'affiliate-manager' ?
              <>
                <td>
                  {status === 'Unpaid' || status === 'draft' ?
                    <>
                      {currencySymbol + '' + FormatValueToLocaleString(item.totalCommissionDueToAffiliate)}
                    </> :
                    <>
                      {currencySymbol + '' + FormatValueToLocaleString(item.totalCommissionPaidToAffiliate)}
                    </>
                  }
                </td>
                <td>
                  {status === 'Unpaid' || status === 'draft' ?
                    <>
                      {currencySymbol + '' + FormatValueToLocaleString(item.totalCommissionDueToAgency)}
                    </> :
                    <>
                      {currencySymbol + '' + FormatValueToLocaleString(item.totalCommissionPaidToAgency)}
                    </>
                  }
                </td>
                </>
                : null
              }
            <td><>{item.commissionDiscount}</></td>
            <td>
              <>
                <span className={classNameForStatus}> {CapitalizeFirstLetter(status)}</span>
              </>
            </td>
            <td>
            <>
             { item.refunded ? <span className="weight-bold small-fontSize">Refunded</span>:
              <BsEyeFill className="icon-color cursor-pointer" onClick= {() =>handleInvoiceClick(item.invoiceId, totalAmountReimbursed)}/>
             }
            </>
            </td>
            {(filters?.status !== 'paid' && (user.role === 'agency-manager' || user.role === 'admin')) && (
            <td>
              <Button
                className="invoice-filter"
                text="Pay"
                value="Pay"
                onClick={() => handleInvoiceModal(item.userId, item.storeId, item.invoiceId, item.invoiceNumber)}
              />
            </td>
            )}
          </tr>
        );
      });
      setInvoiceBody(invoiceSummaryData);
    } else setInvoiceBody([]);
  }, [invoicesSummary]);

  useEffect(() => {
    if (error ) {
      setToastMessage(error);
      setSeverity('error');
      setToastOpen(true);
      dispatch(SetInvoiceState({ field: 'error', value: '' }));
      return;
    }
  }, [error]);

  useEffect(() => {
    if (success && message) {
      if (message || authMessage) {
        Notification({
          type: "success",
          title: "Success",
          description: message
        });
        dispatch(SetInvoiceState({ field: "message", value: "" }));
        getInvoiceSummary();
        return;
      }
    }
  }, [success, message]);

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToastOpen(false);
  };

  const handleInvoiceClick = (invoiceId, totalAmountReimbursed) =>{
    dispatch(SetInvoiceState({ field: 'totalReimbursments', value: totalAmountReimbursed}));

    dispatch(SetInvoiceStateFilters({ module: 'invoiceSummarayFilters', field: 'pageNumber', value: filters.pageNumber}));
    dispatch(SetInvoiceStateFilters({ module: 'invoiceSummarayFilters', field: 'pageLimit', value: filters.pageLimit}));
    dispatch(SetInvoiceStateFilters({ module: 'invoiceSummarayFilters', field: 'searchKeyword', value: filters.searchKeyword}));
    dispatch(SetInvoiceStateFilters({ module: 'invoiceSummarayFilters', field: 'status', value: filters.status}));
    dispatch(SetInvoiceStateFilters({ module: 'invoiceSummarayFilters', field: 'userId', value: filters.userId}));
    dispatch(SetInvoiceStateFilters({ module: 'invoiceSummarayFilters', field: 'pageOption', value: filters.pageOption}));
    history.push(`/invoice/${invoiceId}`);

  }

  const getInvoiceSummary = () => {
    const skip = (filters.pageNumber - 1) * filters.pageLimit;
    const limit = filters.pageLimit;
    dispatch(GetInvoiceSummary({ filters, skip, limit }));
  };

  const handleSearch = debounce((value, key) => {
    setFilters({
      ...filters,
      pageNumber: 1,
      searchKeyword: value,
      [key]: value
    });
  }, 500);

  const handlePageLimit = (e) => {
    setFilters({ ...filters, pageLimit: e.value, pageOption: e });
  };

  const handlePageNumberChange = (e) => {
    const totalPages = Math.ceil(total / filters.pageLimit)
    if (e.key == "Enter") {
      if (e.target.value < 1 || !e.target.value) {
        setFilters({ ...filters, pageNumber: 1 })
      } else if (e.target.value > totalPages) {
        setFilters({ ...filters, pageNumber: totalPages })
      } else {
        setFilters({ ...filters, pageNumber: Number(e.target.value) })
      }
    }
  }

  const handlePageChangeLeft = () => {
    if (filters.pageNumber - 1 > 0) {
      setFilters({ ...filters, pageNumber: filters.pageNumber - 1 })
    } else {
      setFilters({ ...filters, pageNumber: 1 })
    }
  }

  const handlePageChangeRight = () => {
    const totalPages = Math.ceil(total / filters.pageLimit)
    if (filters.pageNumber + 1 < totalPages) {
      setFilters({ ...filters, pageNumber: filters.pageNumber + 1 })
    } else {
      setFilters({ ...filters, pageNumber: totalPages > 0 ? totalPages : 1})
    }
  }

  const handleChange = (e, key) => {
    if (key === 'storeId') setSelectedStore(e || null)
    if (key === 'userId') setSelectedUser(e || null)
    dispatch(SetInvoiceStateFilters({ module: 'invoiceSummarayFilters', field: key, value: e?.value[0] || 'All' }));
    setPass(true);
    setFilters({
      ...filters,
      pageNumber: 1,
      [key]: e?.value[0] || 'All'
    })
  };

  const handleClick = (e, key) => {
    setFilters({
      ...filters,
      [key]: e
    })
  };

  return (
    <InvoiceWrapper>
      <Toast
        message={toastMessage}
        severity={severity}
        toastOpen={toastOpen}
        handleToastClose={handleToastClose}
      />
      <div className="d-flex justify-content-between align-items-center flex-sm-column flex-md-row">
        <h3 className="mb-0">Invoices</h3>
        <div className="d-flex flex-wrap grid-column-gap-16 align-items-center">
          <ButtonGroup
            className="invoice-filter"
            onClick={(e) => setInvoice(!invoice)}
          >
            <Button
              text="Paid"
              value="paid"
              className={filters?.status === 'paid' ? "active" : "non-active"}
              onClick={(e) => handleClick(e.target.value, 'status')}
            />
            {user.role === 'admin' || user.role === 'agency-manager' || user.role == 'affiliate-manager' ?
              <>
                <Button
                  text="Draft"
                  value="draft"
                  className={filters?.status === 'draft' ? "active" : "non-active"}
                  onClick={(e) => handleClick(e.target.value, 'status')}
                />
              </>
              : ''
            }

            <Button
              text="Unpaid"
              value="open"
              className={filters?.status === 'open' ? "active" : "non-active"}
              onClick={(e) => handleClick(e.target.value, 'status')}
            />
          </ButtonGroup>
          {user.role === 'admin' || user.role === 'agency-manager' ?
            <Select
              styles={{
                option: (base, state) => ({
                  ...base,
                  paddingLeft: "11px",
                  paddingRight: "11px",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                  borderBottom: `1px solid #E3E2E2`,
                  height: "100%",
                  backgroundColor: 'transparnet',
                  color: !isSelected ? '#4E5969' : "#064AAC"
                }),
                menuList: (provided, state) => ({
                  ...provided,
                  padding: 10,
                }),
              }}
              className="mb-0 w-232"
              options={usersNameData}
              isClearable={true}
              handleChange={(e) => handleChange(e, 'userId')}
              components={{ Option: Tile }}
              placeholder='Select User'
              defaultValue={selectedUser}
              value={selectedUser}
            />
            : null}
          <Select
            handleChange={(e) => handleChange(e, 'storeId')}
            defaultValue={selectedStore}
            value={selectedStore}
            options={storeDropdownData}
            className="dashbaord-select case-state-dropdown"
            isClearable={true}
            components={{ Option: Tile }}
            placeholder='Select Store'
          />
          <SearchInput placeholder="Search" name='search' onChange={(e) => handleSearch(e.target.value, 'searchKeyword')} />
        </div>
      </div>
      <div className="pt-4">
        <Table
          viewHeight="invoices"
          tableHeader={
            <tr>
              <th>Invoice</th>
              {(user.role === 'agency-manager' || user.role === 'admin' || user.role == "affiliate-manager") &&(
                <th> Store </th>
              )}
              <th>Total Reimbursements</th>
              {filters?.status === 'paid' ? user?.role === 'affiliate-manager' ? <th>Total Commission Paid</th> : <th>Commission Paid</th> : <th>Total Due</th>}
              {user?.role === 'affiliate-manager' ? filters?.status === 'paid' ? <th>Commission Paid to Affiliate Manager</th> : <th>Commission Due to Affiliate Manager</th> : null}
              {user?.role === 'affiliate-manager' ? filters?.status === 'paid' ? <th>Commission Paid to Agency</th> : <th>Commission Due to Agency</th> : null}
              <th>Rate%</th>
              <th>Status</th>
              <th className="text-right"></th>
              {(filters?.status !== 'paid' && (user.role === 'agency-manager' || user.role === 'admin')) && (
                <th> Action </th>
              )}
            </tr>
          }
          tableBody={
            <>
            {(!loading && !invoicesSummary.length && success) ? (
                 <tr><td>
                  <div className='error'>
                    <h1>No Data Available</h1>
                  </div>
                  </td></tr>
                 ) : loading ? <tr><td><Spin /></td></tr> : invoiceBody}
            </>
          }
        />

        <Pagination
        className="invoices-pagination-wrapper"
          pageLimit={filters.pageLimit}
          total={total}
          pageNumber={filters.pageNumber}
          totalPages={Math.ceil(total / filters.pageLimit)}
          handlePageChangeLeft={handlePageChangeLeft}
          handlePageChangeRight={handlePageChangeRight}
          handleLimitChange={handlePageLimit}
          defaultValue={filters.pageOption}
          handlePageNumberChange={handlePageNumberChange}
        />
      </div>

      {showModal ? <Modal
        showModal={showModal}
        modalHeader="Pay Invoice"
        modalBody="Do you really want to pay the invoice ?"
        handleConfirm={() => handlePayInvoice()}
        handleClose={() => setShowModal(false)}
        showFooter={true}
      />
        : null
      }
      {
        loading && (
          <Spin />
        )
      }
    </InvoiceWrapper>
  );
};
export default Index;
