import styled from "styled-components";
import CricleImage from "../../assets/images/Circles.svg";

const DashbaordWrapper = styled.div`
  .welcome-text {
    font-weight: 400;
    font-size: ${({ theme }) => theme["base-font-size"]};
    line-height: 19px;
    margin-bottom: 40px;
    span {
      color: ${({theme})=> theme['primary']};
    }
  }
  .account-details-box {
    padding-bottom: 16px;
    .account-details-content {
      border-top: 1px solid ${({theme})=> theme['secondary-variant']};;
      padding-top: 16px;
      padding-bottom: 15px;
      p {
        font-weight: 500;
        font-size: ${({ theme }) => theme["base-font-size-small"]};
        line-height: 15px;
        margin-bottom: 16px;
        color: ${({theme})=> theme['body-text']};
      }
      h6 {
        font-weight: 700;
        font-size: 17px;
        line-height: 21px;
        color: ${({theme})=> theme['primary']};
        margin-bottom: 0;
      }
      h3 {
        font-weight: 700;
        font-size: ${({ theme }) => theme["medium-font-size"]};
        line-height: 17px;
        color: ${({theme})=> theme['primary']};
        margin-bottom: 12px;
      }
      .account-progress-status {
        padding-top: 0px;
        padding-bottom: 0x;
        p {
          margin-bottom: 8px;
        }
      }
      .account-manager-info {
        font-weight: 400;
        font-size: ${({ theme }) => theme["base-font-size-small"]};
        line-height: 15px;
        color: ${({theme})=> theme['primary']};
        margin-bottom: 0;
        svg {
          margin-right: 8px;
          font-size: 26px;
          color: ${({theme})=> theme['primary']};
        }
        p {
          font-weight: 400;
          font-size: ${({ theme }) => theme["xs-small-size"]};
          line-height: 12px;
          color: ${({theme})=> theme['body-text-variant']};
          margin-top: 13px;
          margin-bottom: 16px;
        }
        button {
          margin-bottom: 0;
        }
      }
      &:last-of-type {
        border-bottom: 1px solid ${({theme})=> theme['secondary-variant']};;
      }
      .check-info {
        margin-bottom: 8px;
      }
    }
  }
  h3 {
    font-weight: 700;
    font-size: ${({ theme }) => theme["medium-font-size"]};
    line-height: 17px;
    color: ${({theme})=> theme['primary']};
    margin-bottom: 12px;
  }
  .check-info {
    font-weight: 400;
    font-size: ${({ theme }) => theme["base-font-size-small"]};
    line-height: 15px;
    margin-bottom: 16px;
    color: ${({theme})=> theme['body-text']};
    img {
      width: 16px;
      height: 16px;
      margin-right: 7px;
    }
  }
  .region-details {
    ul {
      padding-left: 0;
      display: flex;
      gap: 8px;
      list-style: none;
      margin-bottom: 22px;
      flex-wrap: wrap;
      li {
        img {
          width: 16px;
          height: 16px;
          border-radius: 55%;
        }
      }
    }
    .check-info {
      margin-bottom: 12px;
    }
    .currency-info {
      p {
        font-weight: 700;
        font-size: ${({ theme }) => theme["base-font-size"]};
        line-height: 19px;
        color: ${({theme})=> theme['accent-success']};
        margin-left: 25px;
        margin-right: 28px;
        margin-bottom: 0;
      }
      svg {
        color: ${({theme})=> theme['primary']};
        height: 16px;
        font-size: ${({ theme }) => theme["base-font-size"]};
        position: relative;
        top: 1px;
        fill: currentColor;
        cursor: pointer;
      }
    }
  }
  .amount-details {
    .amount-recovery {
      background: ${({theme})=> theme['primary-variant']};
      border-radius: 16px;
      text-align: center;
      padding: 16px 15px;
      margin-bottom: 16px;
      padding-bottom: 22px;
      position: relative;
      &:after {
        content: "";
        background-color: ${({theme})=> theme['secondary-variant']};;
        height: 16px;
        position: absolute;
        bottom: -16px;
        width: 1px;
      }
      h2 {
        font-weight: 700;
        font-size: ${({ theme }) => theme["medium-font-size"]};
        line-height: 17px;
        color: ${({theme})=> theme['on-primary']};
        margin-bottom: 15px;
      }
      .region-details {
        ul {
          justify-content: center;
          margin-bottom: 24px;
        }
        .check-info {
          color: ${({theme})=> theme['on-primary']};
          margin-bottom: 7px;
        }
        .currency-info {
          justify-content: center;
          p {
            font-size: 20px;
            line-height: 24px;
            color: ${({theme})=> theme['on-primary']};
            margin-left: 12px;
            margin-right: 12px;
          }
          svg {
            color: ${({theme})=> theme['on-primary']};
            font-size: 20px;
            top: 3px;
          }
        }
      }
    }
    .amount-description {
      background:${({theme})=> theme['background']};
      border-radius: 16px;
      padding: 12px 12px;
      text-align: center;
      margin-bottom: 16px;
      position: relative;
      p {
        font-weight: 400;
        font-size: ${({ theme }) => theme["medium-font-size"]};
        line-height: 17px;
        color: ${({theme})=> theme['on-surface']};
        margin-bottom: 8px;
      }
      h4 {
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        color: ${({theme})=> theme['primary-variant']};
        margin-bottom: 0;
        &.color-primary2 {
          color: #414bc6;
        }
        &.color-primary3 {
          color: #726af5;
        }
        &.color-primary4 {
          color: #0071b9;
        }
      }
      &:after {
        content: "";
        background-color: ${({theme})=> theme['secondary-variant']};;
        height: 16px;
        position: absolute;
        bottom: -16px;
        width: 1px;
      }
      &:last-of-type {
        &:after {
          display: none;
        }
      }
    }
  }
  .active-marketplace {
    background-color: ${({theme})=> theme['background']};
    border-radius: 16px;
    padding: 24px 15px;
    text-align: center;
    h3 {
      margin-bottom: 25px;
    }
    .region-details {
      .check-info {
        margin-bottom: 15px;
      }
      ul {
        gap: 10px 16px;
        padding-left: 12px;
        padding-right: 12px;
        li {
          img {
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }
  .action-required {
    border: 1px solid ${({theme})=> theme['accent-info']};
    padding: 16px 9px;
    border-radius: 16px;
    text-align: center;
    h4 {
      font-weight: 400;
      font-size: ${({ theme }) => theme["base-font-size-small"]};
      line-height: 15px;
      color: ${({theme})=> theme['body-text']};
      margin-bottom: 16px;
      svg {
        color: ${({theme})=> theme['accent-info']};
        position: relative;
        top: -1px;
        font-size: ${({ theme }) => theme["base-font-size"]};
        margin-right: 6px;
      }
    }
    h3 {
      font-weight: 700;
      font-size: ${({ theme }) => theme["base-font-size-small"]};
      line-height: 15px;
      color: ${({theme})=> theme['accent-info']};
      margin-bottom: 16px;
    }
    p {
      font-weight: 400;
      font-size: 11px;
      line-height: 13px;
      color: ${({theme})=> theme['on-surface']};
      margin-bottom: 12px;
      &:last-of-type {
        margin-bottom: 20px;
      }
    }
  }
  button {
    font-size: ${({ theme }) => theme["xs-small-size"]};
    line-height: 12px;
    padding: 10px 12px;
    margin-bottom: 0;
  }

  .bubbles-section {
    height: calc(100vh - 99px);
    display: flex;
    align-items: end;
    text-align: right;
    .info-box-main {
      position: relative;
      width: 100%;
      padding-bottom: 36px;
      &:after {
        content: url(${CricleImage});
        position: static;
        z-index: -1;
        position: relative;
        left: -37px;
        top: 6px;
      }
    }
  }
`;
const BillingPaymentWrapper = styled.div`
  .page-header {
    padding-bottom: 40px;
    h3 {
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      color: ${({theme})=> theme['on-surface']};
      margin-bottom: 12px;
    }
    p {
      font-weight: 400;
      font-size: ${({ theme }) => theme["base-font-size-small"]};
      line-height: 15px;
      color: ${({theme})=> theme['body-text-variant']};
      margin-bottom: 0;
      &.color-primary {
        color: ${({theme})=> theme['primary']};
      }
    }
  }
  .section-divider {
    height: 100%;
    max-height: 394px;
    text-align: center;
    &:after {
      width: 1px;
      content: "";
      background: ${({theme})=> theme['secondary-variant']};
      height: 100%;
      display: inline-block;
    }
  }
  &.profileWrapper{
    .section-divider{
      max-height:347px;
    }
  }
`;
const PaymentCardWrapper = styled.div`
  background: #414bc6;
  padding: 12px;
  border-radius: 16px;
  .payment-card-header {
    background: #1d1d1b;
    padding: 9px 12px 8px;
    p {
      font-weight: 400;
      font-size: ${({ theme }) => theme["base-font-size-small"]};
      line-height: 17px;
      letter-spacing: 0.2px;
      color: ${({theme})=> theme['on-primary']};
      margin-bottom: 0px;
      svg {
        color: ${({theme})=> theme['accent-info']};
        position: relative;
        top: -1px;
        font-size: ${({ theme }) => theme["base-font-size"]};
        margin-right: 3px;
        background-color: ${({theme})=> theme['on-primary']};
        border-radius: 500px;
      }
    }
  }
  .payment-card-content {
    padding-top: 42px;
    padding-bottom: 13px;
    text-align: center;
    p {
      font-weight: 700;
      font-size: ${({ theme }) => theme["base-font-size-small"]};
      line-height: 15px;
      color: ${({theme})=> theme['on-primary']};
      margin-bottom: 0;
    }
  }
`;
const PaymentForm = styled.div`
  h3 {
    font-weight: 700;
    font-size: ${({ theme }) => theme["medium-font-size"]};
    line-height: 17px;
    color: ${({theme})=> theme['primary']};
    margin-bottom: 24px;
  }
  .form-content {
    .form-group {
      margin-bottom: 12px;
      .form-label {
        margin-bottom: 8px;
      }
    }
    .form-control {
      border-radius: 14px;
      padding-left: 10px;
      font-size: ${({ theme }) => theme["xs-small-size"]};
      line-height: 12px;
    }
    .input-with-icon {
      .form-control {
        padding-left: 38px !important;
      }
      .input-icon {
        height: 13px;
        width: 15px;
        left: 13px;
        top: 7px;
        filter: grayscale(1);
        opacity: 0.5;
      }
    }
    .select2-container {
      margin-bottom: 12px;
      .select2-selection__control {
        border-radius: 14px;
        min-height: 28px;
        max-height: 28px;
      }
      .select2-selection__placeholder,
      .select2-selection__single-value {
        font-size: ${({ theme }) => theme["xs-small-size"]};
        line-height: 12px;
        margin-top: -3px;
      }
      .select2-selection__indicator {
        padding-top: 5px;
      }
    }
  }
  .form-button {
    margin-top: 24px;
    button {
      padding: 8px 15px;
    }
  }
`;
const InfoBlock = styled.div`
  max-width: 195px;
  margin: 0 auto;
  margin-right: 0;
  height: 50vh;
  display: flex;
  align-items: end;
  .info-box-main {
    position: relative;
    width: 100%;
    &:after {
      content: url(${CricleImage});
      position: absolute;
      top: -233px;
      left: -112px;
      z-index: -1;
    }
    &.no-bubbles {
      &:after {
        display: none;
      }
    }
  }
  .circle-section {
    margin-left: -112px;
    margin-bottom: 3px;
    display: none;
  }
  .info-block {
    background: ${({ theme }) => theme["background"]};
    border-radius: 16px;
    padding: 16px 15px;
    margin-bottom: 13px;
    &:last-of-type {
      margin-bottom: 0;
    }
    h3 {
      font-weight: 400;
      font-size: ${({ theme }) => theme["base-font-size-small"]};
      line-height: 15px;
      color: ${({ theme }) => theme["primary"]};
      margin-bottom: 8px;
    }
    p {
      font-weight: 400;
      font-size: ${({ theme }) => theme["xs-small-size"]};
      line-height: 12px;
      color: ${({ theme }) => theme["body-text-variant"]};
      margin-bottom: 16px;
    }
    button {
      background:${({theme}) => `linear-gradient(180deg, ${theme['primary-variant']} 0%, ${theme['primary']} 49.67%, ${theme['primary-btn-gradient-3']} 98.63%);`};
      margin-bottom: 0;
    }
    &.success-block {
      p {
        color: ${({ theme }) => theme["primary"]};
      }
      button {
        background: ${({ theme }) => theme["accent-success"]};
        border: 1px solid ${({ theme }) => theme["accent-success"]};
      }
    }
  }
`;
const ProfileForm = styled.div`
  .form-header {
    margin-bottom: 25px;
    h3 {
      font-weight: 700;
      font-size: ${({ theme }) => theme["medium-font-size"]};
      line-height: 17px;
      color: ${({theme})=> theme['primary']};
      margin-bottom: 6px;
    }
    p {
      font-weight: 400;
      font-size: ${({ theme }) => theme["base-font-size-small"]};
      line-height: 14px;
      color: ${({theme})=> theme['body-text-variant']};
      margin-bottom: 0;
    }
  }
  .email-inout-wrapper {
    position: relative;
    svg{
      position: absolute;
      right: 9px;
      top: 8px;
      font-size: 14px;
      cursor: pointer;
    }
}
  span.eye-icon-wraper.position-absolute {
    top: 5px;
    right: 15px;
    cursor: pointer;
}
span.eye-icon-second-wraper.position-absolute {
    top: 49px;
    right: 15px;
    cursor: pointer;
}
span.eye-icon-third-wraper.position-absolute {
    top: 93px;
    right: 15px;
    cursor: pointer;
}
.password-input.position-relative {
    margin-top: 16px;
}
  .form-content {
    // border-bottom: 1px solid ${({theme})=> theme['secondary-variant']};
    padding-bottom:22px ;
    h4 {
      font-weight: 400;
      font-size: ${({ theme }) => theme["xs-small-size"]};
      line-height: 12px;
      color: ${({theme})=> theme['primary']};
      margin-bottom: 12px;
    }
    .form-group {
      margin-bottom: 12px;
      .form-control {
        border-radius: 14px;
        padding: 9px 40px 9px 12px;
        max-height: 32px;
        font-size: ${({ theme }) => theme["base-font-size-small"]};
        line-height: 15px;
      }
    }
    button {
      margin-top: 12px;
      margin-bottom: 24px;
      padding:8px 16px;
      margin-bottom:16px !important;
    }
    .form-button {
    }
    .note {
      font-weight: 400;
      font-size: ${({ theme }) => theme["xs-small-size"]};
      line-height: 12px;
      color: ${({theme})=> theme['body-text-variant']};
      margin-bottom: 16px;
      a {
        color: ${({theme})=> theme['primary']};
        text-decoration: none;
      }
    }
    .form-block-top {
      border-bottom: 1px solid ${({theme})=> theme['secondary-variant']};
    }
    .form-block-bottom {
      padding-top: 53px;
      padding-bottom: 30px;
      button{
        margin-top:0;
      }
    }
    h2 {
      font-weight: 700;
      font-size: ${({ theme }) => theme["base-font-size"]};
      line-height: 19px;
      color: ${({theme})=> theme['primary']};
      margin-bottom: 12px;
    }
  }
`;
export {
  DashbaordWrapper,
  BillingPaymentWrapper,
  PaymentCardWrapper,
  PaymentForm,
  InfoBlock,
  ProfileForm,
};
