import Styled from "styled-components";

const StatsWrapper = Styled.div`
    .table-row-wrapper{
        border-bottom: 1px solid #E8E8E8 !important;
    }

.td-tile-wrapper {
    width: 300px;
}
.calendar-wrapper{

 button{

 svg {
    color: ${({theme}) => theme['calender-icon-color']};
}
}
}
.checkbox-with-text-wrapper{
    margin:0 16px;
    input{
        margin-right:8px;
        width:15px;
        height: 15pxs;
    }

}
.heading-wrapper{
    p{
        margin-top:12px;
        margin-bottom:24px !important;
        font-size: 12px;
        font-weight: 400;
        line-height: 15px;
        letter-spacing: 0px;
        text-align: left;

    }
}
.case-state-dropdown {
    width: 200px !important;
}
 .filter-box{
     position: absolute;
     box-shadow: 0 0 10px #ccc;
     padding: 16px;
     top:32px;
     background-color:${({ theme }) => theme['white-color']}; 
     width: 172px;
     z-index: 1001;
         h6{
             font-size:${({ theme }) => theme['medium-font-size']};  
         }
         .text-right{
             span{
                 font-size:${({ theme }) => theme['medium-font-size']};  
                 color:${({ theme }) => theme['secondary-color']};  
                 cursor: pointer;
             }
         }
 }
 .error {
    position: absolute;
    left: 40%;
    top: 50%;
 }
 .table-type-data.d-flex{
        div{
            width: 10px;
            height: 10px;
       
            margin-right: 4px;  
            &.Damaged{
                background: #FFD600;
            }
            &.Lost{
                background: #3AACFF;
            }
            &.Destroyed{
                background: #EC3A65;
            }
            &.Fba_Shipment {
                background: #2E8A24
            }
            &.Fulfillment_Fee {
                background: #800080
            }
            &.Refund {
                background: #800e4b
            }
            &.Removal_Order {
                background: #83764F
            }
        }
        p{
            font-size:14px;
        }
    }
    .table-badge{
        padding:5px 12px;
        border-radius:50px;
        font-size:0.75rem;
        color:#fff;
        &.Partially_Resolved{
            background-color: #3AACFF;
        }
        &.Resolved{
            background-color: #43913c;
        }
        &.Pending{
            background-color: #DF428A;
        }
        &.Rejected{
            background-color:#FFC700 ;
        }
    }
    .outlined-color{
        color:${({ theme }) => theme['text-secondary']};   
    }
    .grid-column-gap-12{
        grid-column-gap:12px;
    }
    #dateRangeButton{
        height: 32.5px;
        background-color: #fff;
        padding: 0px 17px;
        display: flex;
        align-items: center;
        grid-column-gap: 10px;
        border: 1px solid #CFD8DC;
        padding: 0.375rem 0.75rem;
        border-radius: 0.25rem;
      }
    .highlighted-text{
        color:${({ theme }) => `${theme['secondary-color']}!important`}; 
    }
`
export {StatsWrapper}