import React from "react";
import { Radio } from "antd";
import { RadioStyleWrapper } from "./style";

const RadioButton = ({ defaultValue,...rest }) => {
  return (
    <RadioStyleWrapper>
      <Radio.Group defaultValue={defaultValue}>
        <Radio {...rest} />
      </Radio.Group>
    </RadioStyleWrapper>
  );
};

export default RadioButton;
