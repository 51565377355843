import { isEmpty } from 'lodash';

import React, { useState, useEffect } from "react";
import { BsCheck } from "react-icons/bs";
import { Row, Col } from "react-bootstrap";
import { BsArrowLeftShort } from "react-icons/bs";
import { useDispatch, useSelector } from 'react-redux';
import {
  useStripe,
  useElements,
  Elements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement
} from '@stripe/react-stripe-js';

import { loadStripe } from '@stripe/stripe-js';
import Select from "../../components/Select/index"
import Button from "../../components/Button";
import Offcanvas from "../../components/Drawer/index";
import Toast from "../../components/Toast";
import Input from "../../components/Inputs/label-input/index";
import Spin from "../../components/Spin/Index";

import { CreateCustomer, SetStripeState, UpdateCustomer } from '../../redux/slices/stripe-slice';

import { SetAuthState } from '../../redux/slices/auth-slice';
import { GetUser } from '../../redux/slices/user-slice';

import { COUNTRIES, PUBLIC_KEYS } from '../../constants';

import { CardWrapper } from "./style";

const hostName = window.location.hostname;

const publishablekey = PUBLIC_KEYS[hostName];

const stripePromise = loadStripe(publishablekey);

const CheckoutForm = (props) => {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();

  const [show, setShow] = useState(false);
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');
  const [countries, setCountries] = useState([]);
  const [address, setAddress] = useState({
    line1: '',
    country: ''
  });
  const [addressError, setAddressError] = useState({
    line1Error: '',
    countryError: ''
  });
  const [cardValidationError, setCardValidationError] = useState({
    cvcError: '',
    dateError: '',
    numberError: '',
    cvcTouched: false,
    dateTouched: false,
    numberTouched: false,
  });

  const [selectedCountry, setSelectedCountry] = useState({});
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [severity, setSeverity] = useState("");

  const { user: userData } = useSelector(state => (state.auth));
  const {
    success,
    customerCreated,
    customerUpdated,
    error,
    loading
  } = useSelector(state => (state.stripe));
  const { user } = useSelector(state => (state.user));

  useEffect(() => {
    if (error) {
      setToastMessage(error);
      setSeverity("error");
      setToastOpen(true);
      dispatch(SetStripeState({ field: "error", value: "" }));
      return;
    }
  }, [error]);

  const handleToastClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setToastOpen(false);
  };

  useEffect(() => {
    dispatch(GetUser({ userId: userData._id }));
    const countriesData = COUNTRIES?.map((country, index) => {
      return ({
        value: country.code,
        label: country.name
      })
    })
    setCountries(countriesData);
  }, []);

  useEffect(() => {
    if (user.payment) {
      const country = COUNTRIES.find((country, index) => country.code === user.payment?.address?.country);
      if (country) {
        const countryOption = {
          value: country.code,
          label: country.name
        }
        setSelectedCountry(countryOption);
        setAddress(user.payment?.address);
        setName(user.payment?.name);
      }
      dispatch(SetAuthState({ field: 'user', value: { ...userData, payment: user.payment } }));
    }
  }, [user]);

  useEffect(() => {
    if (customerCreated || customerUpdated) {
        dispatch(GetUser({ userId: userData._id }));
        dispatch(SetStripeState({ field: 'customerCreated', value: false }));
        dispatch(SetStripeState({ field: 'customerUpdated', value: false }));
    }
  }, [customerCreated, customerUpdated])

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      return;
    }

    const {
      cvcTouched,
      dateTouched,
      numberTouched,
    } = cardValidationError;

    if (address.line1 === '' || address.country === '' || name === '' || cvcTouched !== true || dateTouched !== true || numberTouched !== true) {
      let line1Error = '';
      let countryError = '';
      let nameError = '';
      let cvcError = '';
      let numberError = '';
      let dateError = '';

      if (address.line1 === '') line1Error = 'Billing address is required!';
      if (address.country === '') countryError = 'Country is required!';
      if (name === '') nameError= 'Name is Required!';
      if (cardValidationError.cvcError === '' && cvcTouched === false) cvcError = 'Cvc is Requried!';
      else cvcError = cardValidationError.cvcError;
      if (cardValidationError.dateError === '' && dateTouched === false) dateError = 'Date is Required!';
      else dateError = cardValidationError.dateError;
      if (cardValidationError.numberError === '' && numberTouched === false) numberError = 'Card Number is Requried!';
      else numberError = cardValidationError.numberError;

      setAddressError({
        line1Error,
        countryError
      })
      setNameError(nameError);

      setCardValidationError({
       ...cardValidationError,
       cvcError,
       numberError,
       dateError
      });

      return;
    }

    const card = elements.getElement(CardNumberElement);

    const { token } = await stripe.createToken(card);

    if (token) {
      if (user.payment) {
        dispatch(UpdateCustomer({ token, name, address, stripeUserId: user.payment.stripeUserId }));
      } else {
        dispatch(CreateCustomer({ token, name, address }));
      }
    } else {
      setToastMessage('Enter the Valid Card Number!');
      setSeverity("error");
      setToastOpen(true);
    }
  };

  const handleAddress = (e, key) => {
    if (key === 'line1') {
      let line1Error = '';
      setAddressError({
        ...addressError,
        line1Error
      })
    }
    if (key === 'country') {
      let countryError = '';
      setAddressError({
        ...addressError,
        countryError
      });
      setSelectedCountry(e);
    }

    setAddress({
      ...address,
      [key]: e.value
    })
  }

  useEffect(() => {
    if (props.modal && success && user.payment) {
      props.handleCloseModal();
    }
  },[success,user]);

  const handleName = (value) => {
    const name =  value.trim();

    if (name) {
      setNameError('')
    }
    setName(name);
  };

  const handleCardValidationError = (e) => {
    const {
      elementType,
      error,
      complete,
      empty
    } = e;

    let cvcTouched = false;
    let dateTouched = false;
    let numberTouched = false;
    
    let cvcError =  '';
    let dateError = '';
    let numberError = '';

    if (elementType === 'cardNumber') {
      if (!isEmpty(error)) { 
        numberError = error.message;
        numberTouched = true;
      }
      else if (complete) numberTouched = true;
      else if (empty) numberTouched = false;
      setCardValidationError({
        ...cardValidationError,
        numberError: numberError,
        numberTouched
      });
    } else if (elementType === 'cardCvc') {
      if (!isEmpty(error)) { 
        dateError = error.message;
        cvcTouched = true;
      }
      else if (complete) cvcTouched = true;
      else if (empty) cvcTouched = false;
      setCardValidationError({
        ...cardValidationError,
        cvcError: dateError,
        cvcTouched
      });
    } else if (elementType === 'cardExpiry') {
      if (!isEmpty(error)) {
        cvcError = error.message;
        dateTouched = true;
      }
      else if (complete) dateTouched = true;
      else if (empty) dateTouched = false;
      setCardValidationError({
        ...cardValidationError,
        dateError: cvcError,
        dateTouched
      });
    }
  }

  const Stripe_Options = {
    style: {
      base: {
        iconColor: "#c4f0ff",
        color: "#494F51",
        fontWeight: 500,
        fontSize: "10px",
        fontSmoothing: "antialiased",
        ":-webkit-autofill": {
          color: "#000000",
          "-webkit-box-shadow": "0 0 0 1000px #465a7e66 inset",
          'background-color': '#FFFFFF'
        },
        "StripeElement--webkit-autofill": {
          'background-color': '#FFFFFF'
        },
        "::placeholder": {
          color: "#C0C0C0",
        },
      },
      invalid: {
        iconColor: "#ffc7ee",
        color: "#ffc7ee",
      },
    },
  };

  const CardNumberOptions = {
    showIcon: true,
    placeholder: "Card Number",
    style: {
      base: {
        // iconColor: "#c4f0ff",
        color: "#494F51",
        fontWeight: 500,
        fontSize: "10px",
        fontSmoothing: "antialiased",
        ":-webkit-autofill": {
          color: "#000000",
          "-webkit-box-shadow": "0 0 0 1000px #465a7e66 inset",
          'background-color': '#FFFFFF'
        },
        "StripeElement--webkit-autofill": {
          'background-color': '#FFFFFF'
        },
        "::placeholder": {
          color: "#C0C0C0",
        },
      },
      invalid: {
        iconColor: "#ffc7ee",
        color: "#ffc7ee",
      },
    },
  };

  const handleBlur = (e) => {
    const { 
      name,
      value
    } = e;
    if (name === 'name' && isEmpty(value)) {
      setNameError("Name is Required!");
    } else if (name === 'billingAddress' && isEmpty(value)) {
      setAddressError({
        ...addressError,
        line1Error: 'Billing address is required!'
      });
    }
  };

  return (
    <CardWrapper>
      <Toast
        message={toastMessage}
        severity={severity}
        toastOpen={toastOpen}
        handleToastClose={handleToastClose}
      />
      { !props?.modal ? 
        <div className="d-flex justify-content-between mb-4">
          <h3 className="color-dark font-weight-bold">Credit Card & Address</h3>
        </div>
      : null}
     
      {typeof (user?.payment) === 'undefined' ?
        <>
          <div className='d-flex align-items-center justify-content-center'>
            <div style={ props.modal ? {width: '100%', backgroundColor: '#F4F7F9', borderRadius: '3px'} :  {  width: '60%', backgroundColor: '#F4F7F9', borderRadius: '3px' }}>
              <form>
                <div className="p-4 payment-input-wrapper">
                  { !props?.modal ? 
                  <div className="mb-2">
                    <h3 className="color-dark mb-0 ">Add New Credit Card Credentials</h3>
                  </div>
                  : null}
                  <div>
                    <Row className="m-0">
                      <Col className="col-md-4 column">
                        <Input
                        className="custom-style"
                          label="Card Holder Name"
                          name="name"
                          placeholder="Card Holder Name"
                          onChange={(e) => handleName(e.target.value)}
                        />
                          <span style={{ color: 'red', fontSize: '10px' }}> {nameError} </span>
                      </Col>
                      <Col className='col-md-4 column'>
                      <div className="">
                          <span style={{ fontSize: '12px', color: "#78909c" }}> Card Number </span>
                          <CardNumberElement
                          className="cardnumber-stripe"
                            onChange={(e) => handleCardValidationError(e)}
                            options={CardNumberOptions}
                          />
                          <span style={{ color: 'red', fontSize: '10px' }}> {cardValidationError.numberError} </span>
                        </div>
                      </Col>
                      <Col className='col-md-4 column'>
                      <div className="">
                          <span style={{ fontSize: '12px', color: "#78909c" }}> Date </span>
                          <CardExpiryElement
                          className="cardnumber-stripe" 
                            onChange={(e) => handleCardValidationError(e)}
                            options={Stripe_Options}
                          />
                          <span style={{ color: 'red', fontSize: '10px' }}> {cardValidationError.dateError} </span>

                        </div>
                      </Col>
                      </Row>
                      <Row className='p-0 m-0 second-row-wrapper'>
                      <Col className='col-md-4 column'>
                      <span style={{ fontSize: '12px', color: "#78909c" }}> CVC </span>
                        <CardCvcElement
                          onChange={(e) => handleCardValidationError(e)}
                          options={Stripe_Options}
                        />
                        <span style={{ color: 'red', fontSize: '10px' }}> {cardValidationError.cvcError} </span>
                      </Col>
                      <Col className="col-md-4 column">
                       
                        <Input
                        className="custom-style custom-style-alignment" 
                          type='text' 
                          name='billingAddress' 
                          label="Billing Address" 
                          onChange={(e) => handleAddress(e.target, 'line1')} 
                        />
                        <span style={{ color: 'red', fontSize: '10px' }}> {addressError.line1Error} </span>
                      </Col>
                      <Col className="col-md-4 column">
                        
                        <Select
                          label="Country"
                          placeholder='Select country'
                          options={countries}
                          handleChange={(e) => handleAddress(e, 'country')}
                          className="modal-select-wrapper"
                        />
                        <span style={{ color: 'red', fontSize: '10px' }}> {addressError.countryError} </span>
                      </Col>
                      <Col className="col-md-12 d-flex justify-content-end pt-4">
                        <Button
                          className="outlined modal-btn"
                          text='Add Credit Card'
                          onClick={handleSubmit}
                          disabled={loading}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </>
        : null
      }
      {user?.payment ?
        <Row className="m-0">
          <Col md={4} className="pl-0" onClick={() => setShow(true)}>
            <div className="marketplace enabled">
              <div className="d-flex justify-content-between mb-4">
                <div className="title weight-bold">{user && user.payment?.name}</div>
                <span className="check-svg">
                  <BsCheck />
                </span>
              </div>
              <div className="d-flex justify-content-between ">
                <h6 className="fs-6 card-number">xxxx-xxxx-xxxx-{user.payment && user.payment.cardLast4Digits}</h6>
                <span className="switch">Active</span>
              </div>
              <p>{user.payment.address?.line1 || ''} , {user.payment.address?.country || ''}</p>
            </div>
          </Col>
        </Row>
        : null
      }
      {typeof (user?.payment) !== 'undefined' ?
        <Offcanvas show={show} size="80%">
          <form>
            <div className="p-4 form-align-ments">
              <div className="d-flex gap-1 mb-4 align-items-center">
                <BsArrowLeftShort className="color-secondary cursor-pointer" onClick={() => setShow(false)} />
                <h2 className="color-dark mb-0 pl-2">Add Credit Card Credentials</h2>
              </div>
              <Row className="m-0 aign-items-center">
                <Col className="col-md-4">
                  <Input
                  className="custom-style"
                    label="Card Holder Name"
                    name="name"
                    value={name}
                    placeholder="Card Holder Name"
                    onChange={(e) =>  handleName(e.target.value)}
                    // onBlur= {(e) => handleBlur(e.target)}
                  />
                  <span id='new' className='card-holder-alert' style={{ color: 'red', fontSize: '12px', position: "absolute", top: "56px" }}>{nameError}</span>
                  <span style={{ fontSize: '12px', color: "#78909c", display: "block", marginTop: "22px" }}> CVC </span>
                  <CardCvcElement 
                    onChange={(e) => handleCardValidationError(e)}
                    autoFocus={true}
                  />
                   <span style={{ color: 'red', fontSize: '12px' }}> {cardValidationError.cvcError} </span>
                </Col>
                <Col className="col-md-4">
                  <div className="mb-4">
                    <span style={{ fontSize: '12px', color: "#78909c" }}> Card Number </span>
                    <CardNumberElement 
                       onChange={(e) => handleCardValidationError(e)}
                    />
                     <span style={{ color: 'red', fontSize: '12px' }}> {cardValidationError.numberError} </span>
                  </div>
                  <Input
                  className="custom-style" 
                    type='text'
                    name='billingAddress' 
                    label="Billing Address" 
                    placeholder="Billing Address"
                    value={address?.line1} 
                    onChange={(e) => handleAddress(e.target, 'line1')} 
                    // onBlur= {(e) => handleBlur(e.target)}
                  />
                  <span className='biling-address-alert' style={{ color: 'red', fontSize: '12px' }}> {addressError.line1Error} </span>
                </Col>
                <Col className="col-md-4">
                  <div className="mb-4">
                    <span style={{ fontSize: '12px', color: "#78909c" }}> Date </span>
                    <CardExpiryElement
                      onChange={(e) => handleCardValidationError(e)}
                    />
                     <span style={{ color: 'red', fontSize: '12px' }}> {cardValidationError.dateError} </span>
                  </div>
                  <Select
                    className="dashbaord-select mb-0"
                    // name='country'
                    label="Country"
                    placeholder='Select country'
                    options={countries}
                    value={selectedCountry}
                    handleChange={(e) => handleAddress(e, 'country')}
                  />
                  <span className='select-alert' style={{ color: 'red', fontSize: '12px' }}> {addressError.countryError} </span>
                </Col>
                <Col className="col-md-12 d-flex justify-content-end pt-4">
                  <Button
                    className="outlined"
                    text={user.payment ? 'Update Card' : 'Add Card'}
                    onClick={handleSubmit}
                    disabled={loading}
                  />
                </Col>
              </Row>
            </div>
          </form>
        </Offcanvas>
        : null}
        {loading && (
        <Spin />
      )}
    </CardWrapper>
  );
};

const StripeForm = (props) => {

  return (
    <Elements stripe={stripePromise} >
      <CheckoutForm  modal={props?.modal || false }  handleCloseModal= {props.handleCloseModal} />
    </Elements>
  );
};

export default StripeForm;
