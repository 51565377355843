import React from 'react';

import { InlineWidget } from 'react-calendly';

import { CALENDLY_LINKS } from '../../constants';

import CalenderWrapper from './style';

const MeetingCalender = () => {
  const hostName = window.location.hostname;

  return (
    <CalenderWrapper>
        <div className='calendar-ui-wrapper'>
        <InlineWidget url={CALENDLY_LINKS[hostName]}
        styles={{
            height: '800px',
        }}
        iframeTitle='Meeting Calender'/>
        </div>
    </CalenderWrapper>
  )
};

export default MeetingCalender;
