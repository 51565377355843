import styled from "styled-components";

const AmountCardWrapper = styled.div`
  .card_overlay_wrapper {
    background: #f2f5f5;
    width: 218px;
    height: 60px;
    padding: 10px 12px 10px 12px;
    border-radius: 8px;
    display: flex;
    .card_overlay_item {
      display: flex;
      align-items: center;
      grid-gap: 12px;
      .card_item {
        display: flex;
        flex-direction: column;
        grid-row-gap: 4px;
        p {
          font-size: 12px;
          font-weight: 500;
          line-height: 16px;
          letter-spacing: 0em;
          text-align: left;
          margin: 0px;
          color: #858c95;
        }
        h6 {
          font-size: 16px;
          font-weight: 700;
          line-height: 20px;
          letter-spacing: 0em;
          text-align: left;
          margin: 0px;
          color: #474747;
          width: 143px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
  }
`;
export { AmountCardWrapper };
