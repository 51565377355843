import { round } from "lodash";

import { APP_DOMAINS } from "../constants";

export const FormatValueToLocaleString = (value) => {
    const data = (value || 0).toLocaleString(undefined, {  minimumFractionDigits: 2 });
    return data === '0.00' ? data : round(parseFloat(data.replace(/,/g, '')),2);
}

export const CapitalizeFirstLetter = (str) => {
    let capitalized = '';
    if (str) {
        capitalized = str.charAt(0).toUpperCase() + str.slice(1);
    }
    return capitalized;
}

export const shipmentStatusMapping = (status) => {
    if (status === 'Reimbursable') {
        return 'Awaiting Document';
    } else if (status === 'Pending Action From Seller Terminal') {
        return `Pending Action From Seller ${window.location.hostname === APP_DOMAINS.sellerRepay ? 'Repay' : 'Terminal'}`;
    } else {
        return status || 'N/A';
    }
}
