import styled from "styled-components";

const PotentialAmountWrapper = styled.div`
  .potential_overlay_wrapper {
    background: ${({ theme }) => theme['new-bg']};
    grid-row-gap: 12px;
    border-radius: 12px;
    min-width: 192px;
    max-width: 192px;
    height: 168px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p {
      color: #ffffff;
      font-size: 12px;
      font-weight: 700;
      line-height: 16px;
      letter-spacing: 0em;
      text-align: center;
      margin: 0px;
    }
    .potential_overlay_bottom {
      color: #fff;
      display: flex;
      align-items: center;
      grid-gap: 9px;
      p {
        color: #ffca63;
        font-size: 24px;
        font-weight: 700;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: center;
      }
      svg {
        font-size: 16px;
        cursor: pointer;
      }
    }
  }
`;
export { PotentialAmountWrapper };
