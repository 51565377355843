import React, { useState } from "react";
import OtpInput from "react-otp-input";
import { OtpStyleWrapper } from "./style";

const Otp = (props) => {

const { renderSeparator, value, onChange, numInputs, renderInput, containerStyle } = props;
  return (
    <OtpStyleWrapper>
      <OtpInput
        value={value}
        onChange={onChange}
        numInputs={numInputs}
        renderSeparator={renderSeparator}
        renderInput={renderInput}
        containerStyle={containerStyle}
      />
    </OtpStyleWrapper>
  );
};
export default Otp;
