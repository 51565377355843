import React, { useEffect, useState, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spin, Steps } from "antd";

import Title from "../StepOne/title";
import TitleTwo from "../StepTwo/title";
import TitleThree from "../StepThree/title";
import StepperFooter from "./StepperFooter";
import StepOne from "../StepOne";
import StepTwo from "../StepTwo";
import StepThree from "../StepThree";
import RestartButton from "../../../components/RestartButton";
import Notification from "../../../components/notifications/notification";

import { StepperStyeWrapper } from "./style";

import { GetProgress, GetStore, SetStoreState, UpdateStore } from "../../../redux/slices/store-slice";
import { SetAuthState } from "../../../redux/slices/auth-slice";
import { SetState } from "../../../redux/slices/account-detail-slice";

const { Step } = Steps;

const StepperSt = () => {
  const dispatch = useDispatch();
  const { storeStatus, storeId, payLater, user } = useSelector((state) => state.auth);
  const {
    store,
    progress,
    updateSuccess,
    error,
    progressSuccess
  } = useSelector((state) => state.store);
  const { error: accountErr } = useSelector((state) => state.accountDetail);
  const { err } = useSelector((state) => state.payment);

  const [currentStep, setCurrentStep] = useState(null);

  const handleNext = () => {
    setCurrentStep(currentStep + 1);
  };

  const handleStepChange = (step) => {
    setCurrentStep(step);
  }

  const steps = [
    {
      title: <Title currentStep={currentStep} />,
      content: <StepOne />,
      // content: <NotEligible/>,
    },
    {
      title: <TitleTwo currentStep={currentStep} />,
      content: <StepTwo setStep={setCurrentStep} currentStep={currentStep} />,
      // content: <Eligible/>,
    },
    {
      title: <TitleThree currentStep={3} />,
      content: <StepThree setStep={setCurrentStep} currentStep={3} />,
    },
    {
      title: <TitleThree currentStep={4} />,
      content: <StepThree currentStep={4} handleNext={handleNext}/>,
    },
    {
      title: <TitleThree currentStep={5} />,
      content: <StepThree handleStepChange={handleStepChange} currentStep={5} />,
    },
  ];

  useEffect(() => {
    if (storeId) {
      dispatch(GetProgress({ storeId }))
    } else {
      setCurrentStep(0);
    }
  },[])

  useEffect(() =>{
    if(storeId) {
      dispatch(GetStore({storeId}));
    }
  },[]);

  useEffect(() => {
    if ([error, err, accountErr].includes('Network Error')) {
      Notification({
        type: "error",
        title: "Error",
        description: 'Network Error'
      });
      dispatch(SetStoreState({ field: 'error', value: '' }));
      dispatch(SetState({ field:'error', value: '' }))
    }
  }, [error, accountErr, err]);

  useEffect(() => {
    if (Object.keys(store).length) {
      const { status } = store;
      switch (status) {
        case 'Pending': {
          setCurrentStep(1);
          break;
        }

        case 'Invite_Accepted': {
          if (progress === 100) {
            setCurrentStep(2);
          } else if (progressSuccess) {
            setCurrentStep(1);
            dispatch(SetStoreState({ field: 'progressSuccess', value: null }));
          }
          break;
        }

        case 'Connected': {
          if (storeStatus !== status) {
            dispatch(SetAuthState({field: 'storeStatus', value: status}));
          }

          const { payment } = user;

          if (payLater || store?.payLater || !!payment.stripeUserId) {
            if (!payLater) {
              dispatch(SetAuthState({ field: 'payLater', value: true }));
            }

            if (!store.payLater) {
              dispatch(UpdateStore({ storeId, updateParams: { payLater: true } }));
            }

            setCurrentStep(4);
          } else if (updateSuccess !== null) {
            if (updateSuccess && store?.payLater) {
              setCurrentStep(4);
            }
            dispatch(SetStoreState({ field: 'updateSuccess', value: null }));
          } else {
            setTimeout(() => setCurrentStep(3),1000);
          }
          break;
        }
      }
    }
  }, [store, progress])

  return (
    <StepperStyeWrapper>
      <div className="stepper_wrapper">
        <div className="steps_overlay_wrapper">
          <Steps direction="vertical" current={currentStep} size="small">
            {steps.map((item, index) => (
              <Step key={index} title={item.title} />
            ))}
          </Steps>
          <StepperFooter />
          {currentStep >= 1 ? <RestartButton setStep={setCurrentStep} /> : null}
        </div>
        <div className="steps-content">
          {
            currentStep !== null ? steps[currentStep].content :
            (
                <div className="d-flex position-absolute justify-content-center align-items-center loader-wrapper">
                <Spin size="large"/>
              </div>
            )
          }
        </div>
      </div>
    </StepperStyeWrapper>
  );
};

export default StepperSt;
