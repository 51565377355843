import styled from "styled-components";
import BackGroundImage from '../../assets/img/Background.svg'

const AffiliateWrapper = styled.div`
  background-image: url(${BackGroundImage});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  height: 100vh;
  overflow: auto;
  .content-holder {
    max-width: 1125px;
    margin: 0 auto;
    /* height:100%; */
    background-position: bottom;
    background-repeat: no-repeat;
  }
  span {
    font-size: ${({ theme }) => theme["font-size-base"]};
    color: ${({ theme }) => theme["lynch-color"]};
  }
  .password-input {
    position: relative;
    &.signup-password {
      .password-field {
        margin-bottom: 16px;
      }
      .password-field-2 {
        margin-bottom: 2px;
      }
      svg {
        position: absolute;
        top: 25px;
        right: 15px;
        /* color: ${({ theme }) => theme["primary-color"]}; */
        color: #818181;
      }
    }
    svg {
      position: absolute;
      top: 25px;
      right: 10px;
      /* color: ${({ theme }) => theme["primary-color"]}; */
      color: #818181;
    }
  }
  .bg-albaster {
    background-color: ${({ theme }) => theme["alabaster-color"]}80;
    height: 100%;
    display: flex;
    align-items: center;
    .py-40 {
      padding: 0px 40px;
      padding-top: 48px;
    }
  }
  .forget-password {
    font-size: ${({ theme }) => theme["xs-small-size"]};
    color: ${({ theme }) => theme["primary-variant"]};
    font-weight: ${({ theme }) => theme["bold-weight"]};
    text-decoration: none;
    padding-right: 2px;
  }
  .heading {
    &.signup-haeding {
      h1 {
        line-height: 50px;
        padding: 0px 28px;
        letter-spacing: 0px;
      }
    }
  }

  .header-space {
    /* padding-top:69px; */
    padding-top: 75px;
  }
  .button-top {
    padding-top: 8px;
  }
  .button-top-change {
    padding-top: 16px;
  }
  .have-account {
    /* padding-right: 6px; */
    p {
      font-size: ${({ theme }) => theme["xs-small-size"]};
      margin-left: 0px;
      margin-bottom: 0;
      a {
        margin: 0px 7px;
        /* margin-left: 0px; */
        text-decoration: none;
        font-weight: 700;
        margin-right: 2px;
        color: ${({ theme }) => theme["primary-variant"]};
      }
    }
  }
  .already-account {
    color: ${({ theme }) => theme["nevada-color"]};
  }
  .recive-email {
    padding-top: 49px;
    color: ${({ theme }) => theme["nevada-color"]};
    font-size: 1rem;
    margin-right: 6px;
    a {
      color: ${({ theme }) => theme["primary-color"]};
      text-decoration: underline;
    }
  }
  .verifactionn-img {
    margin-top: -6px;
    margin-left: -16px;
  }
  .forget-password-form {
    margin: 5px 61px 0 61px;
    h3 {
      margin-bottom: 30px;
    }
    button {
      margin-top: 15px;
    }
    .email-dont-recieve {
      margin: 25px 0px 0 25px;
    }
  }
  .change-password-form {
    margin: 5px 61px 0 61px;
    h3 {
      margin-bottom: 30px;
    }
    button {
      margin-top: 15px;
    }
    .email-dont-recieve {
      margin: 25px 0px 0 25px;
    }
  }
  .left-section {
    padding-left: 113px;
    padding-top: 38px;
    .logo-description {
      color: ${({ theme }) => theme["white-color"]};
      padding-top: 35px;
      padding-right: 53px;
    }
  }
  .right-content {
    padding: 39px 94px;
    padding-right: 113px;
    .user-form {
      background-color: ${({ theme }) => theme["white-color"]};
      border-radius: 10px;
      padding: 24px;
      .heading {
        margin-bottom: 13px;
        letter-spacing: 0.3px;
        color: ${({ theme }) => theme["primary-variant"]};
      }
      .heading-secondary {
        margin-bottom: 33px;
      }
      .heading-caption {
        font-size: ${({ theme }) => theme["base-font-size-small"]};
        line-height: 14.52px;
        display: block;
        color: ${({ theme }) => theme["body-text-variant"]};
        letter-spacing: 0px;
      }
      hr {
        margin-top: 16px;
        margin-bottom: 24px;
      }
      .auth-form {
        margin-top: 22px;
      }
      .captcha-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #353e44;
        font-size: 12px;
        margin-bottom: 23px;
        margin-top:10px;
        .white-box {
          background-color: ${({ theme }) => theme["white-color"]};
          width: 61px;
          height: 34px;
          border-radius: 6px;
        }
        &.signup-captcha {
          margin-top: 10px;
        }
      }
    }
  }

  .customized-row {
    margin-left: -8px;
    margin-right: -8px;
    [class*="col-"] {
      padding-left: 8px;
      padding-right: 8px;
    }
  }
  #rc-anchor-alert,
  .rc-anchor-alert {
    color: blue;
  }
.icon-color {
  color:${({theme}) => `${theme['secondary-color']}!important`};
}
`;

export { AffiliateWrapper };
