
import Styled from 'styled-components';
import { Form} from "react-bootstrap"
const SearchInputWrapper =Styled(Form.Group)`
    position:relative;
    margin-bottom:0;
    .form-group{
      margin-bottom:0;
    }
  input{
    max-height:32px;
    min-height:32px;
    min-width:215px;  
    font-size: ${({ theme }) => theme['base-font-size-small']};
    border-radius:20px;
  }
  svg{
    font-size:12px;
    position:absolute;
    right:10px;
    top:11px;
    cursor: pointer;
    color:${({theme}) => `${theme['primary']}!important`};
  }

`
export {SearchInputWrapper };