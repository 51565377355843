import styled from "styled-components";

const SelectStWrapper = styled.div`
  .ant-select-selector {
    color: var(--text-field-body-color, #5e718d);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }
  span.ant-select-arrow {
    top: 8px;
    color:${(props) => props.theme["new-primary"]};
  }
  .ant-select.ant-select-single.ant-select-show-arrow {
    border-radius: 4px;
    width:100%;
    border: 1px solid var(--Border-Variant, #dadfe3);
    background-color: transparent !important;
  }
`;
export { SelectStWrapper };
