import React from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import { ModalWrapper } from './style';
import Button from '../Button';

const Index = (props) => {
  const { 
    showModal, 
    modalHeader, 
    modalBody, 
    handleConfirm, 
    handleClose,
    showFooter,
    modalType,
    disabled,
    size='lg'
  } = props;

  return (
    <>
      <Modal
        size={size}
        show={showModal}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <ModalWrapper>
          <Modal.Header closeButton >
            <Modal.Title>{modalHeader}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {modalBody}
          </Modal.Body>
          { showFooter ? 
          <Modal.Footer>
            <Row>
              <Col md={6}>
                <Button variant="secondary" onClick={handleClose} text='Cancel' />
              </Col>
              {modalType === 'image' ? null :
              <Col md={6} className="pl-1">
                <Button 
                  variant="primary"
                  onClick={handleConfirm}
                  text={modalType === 'file' ? 'Save' : 'Confirm'}
                  className="confirm-btn"
                  disabled={disabled}
                />
              </Col>
              }
            </Row>
          </Modal.Footer>
          :null }
         </ModalWrapper>
      </Modal>
    </>
  )
}

export default Index;