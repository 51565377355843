import styled from "styled-components";

const WaitingScreenWrapper = styled.div`
display:flex;
justify-content:center;
align-item:center;


//   .heading h2 {
//     font-size: 24px;
//     font-weight: 700;
//     line-height: 29px;
//     letter-spacing: 0px;
//     text-align: left;
//     color: #064aac;
//   }
  .main-wrapper {
    min-height: 84vh;
    width: 92%;
    margin-top: 92px;
    // margin-top: 18px;
    // margin-left: 3px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    background: #fff;
    h3 {
        font-size: 24px;
        font-weight: 700;
        line-height: 29px;
        text-align: center;
        margin-top: 46px;
        // letter-spacing: -0.2px;
    }
    h6 {
        font-size: 24px;
        font-weight: 700;
        line-height: 29px;
        text-align: center;
        margin-top: 21px;
        color: #064aac;
    }
 img {
        height: 397.9956359863281px;
        width: 735.9993896484375px;
        border-radius: 0px;
        margin: auto;
        margin-top: 24px;
    }
  }
`;
export { WaitingScreenWrapper };
