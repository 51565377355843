import React, { useState, useEffect } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { Formik, getIn } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from "react-redux";
import jwt_decode from "jwt-decode";

import LabelInput from "../../components/Inputs/label-input";
import Button from "../../components/Button";
import Notification from '../../components/notifications/notification'

import { APP_LOGOS, APP_TITLES } from "../../constants";

import { AuthWrapper } from "./style";

import { ResetPassword, SetAuthState } from '../../redux/slices/auth-slice';

const schema = yup.object().shape({
  password: yup.string().required('Password is required').matches(
    /^(?=.{8,})/,
    "Password Must be 8 Characters long"
  ),
  confirmPassword: yup.string().required().oneOf([yup.ref('password'), null], 'Passwords must match')
});

const hostName = window.location.hostname;

const PasswordChange = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [passwordShow, SetPasswordShow] = useState(true);

  const { message, error, loading, verificationCode } = useSelector((state) => state.auth);

  useEffect(() => {
    if (error) {
      Notification({
        type: "error",
        title: "Error",
        description: error
      });
      dispatch(SetAuthState({ field: 'error', value: '' }));
      return;
    }

    if (message) {
      Notification({
        type: "success",
        title: "Password Changed",
        description: message
      });
      dispatch(SetAuthState({ field: 'message', value: '' }));
      dispatch(SetAuthState({ field: 'success', value: false }));

      history.push('/auth/sign-in');
      return;
    }
  }, [error, message]);

  const handleSetPassword = () => {
    SetPasswordShow(!passwordShow);
  };

  const savePassword = async (values) => {
    const { password } = values;

    if (verificationCode) {
      dispatch(ResetPassword({ verificationCode, password }));
    } else {
      Notification({
        type: "error",
        title: "Error",
        description: 'Verify the Email Address'
      });
      return;
    }
  };

  const getStyles = (touched, errors, fieldName) => {
    if (touched[fieldName]) {
      if (getIn(errors, fieldName)) {
        return {
          border: '1px solid red'
        }
      }
    }
  }

  return (
    <AuthWrapper>
      <div className='content-holder'>
        <div className="conatiner" style={loading ? { opacity: 0.5 } : null}>
          <Row className="m-0 header-space">
            <Col md={5} sm={12}>
              <div className="left-section">
                {APP_LOGOS[hostName] ?
                  <img src={APP_LOGOS[hostName]} alt="no-logo" />
                  :
                  <h2>{APP_TITLES[hostName]}</h2>
                }
                <p className="logo-description mb-0"> Don’t have an account?
                Create one. It takes less than a minuite!
                </p>
              </div>
            </Col>
            <Col md={7} sm={12}>
              <div className="right-content">
                <div className="user-form">
                  <h2 className="heading">Set New Password</h2>
                  <span className="heading-caption">
                    Please enter a new, strong password to be used for your account{" "}
                  </span>
                  <hr className="bottom-line" />
                  <Formik
                    validationSchema={schema}
                    onSubmit={(values) => savePassword(values)}
                    initialValues={{
                      password: '',
                      confirmPassword: ''
                    }}
                  >
                    {({
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      values,
                      errors,
                      touched
                    }) => (
                      <Form onSubmit={handleSubmit}>
                        <div className="password-input">
                          <LabelInput
                            name='password'
                            label="Password*"
                            type={passwordShow ? "password" : "text"}
                            placeholder="Type Password"
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            style={getStyles(touched, errors, 'password')}
                          />
                          <LabelInput
                            name='confirmPassword'
                            label="Confirm Password*"
                            type={passwordShow ? "password" : "text"}
                            placeholder="Your Password Here"
                            value={values.confirmPassword}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            style={getStyles(touched, errors, 'confirmPassword')}
                          />
                          {passwordShow ? (
                            <AiFillEyeInvisible onClick={handleSetPassword} />
                          ) : (
                            <AiFillEye onClick={handleSetPassword} />
                          )}
                        </div>

                        <div className="d-flex align-items-center button-top-change justify-content-between">
                          <Button
                            text={loading ? "Saving...." : "Reset Password"}
                            className="primary mb-0"
                            type="submit"
                            width=""
                          />
                          <div className="d-flex justify-content-center have-account">
                            <p>
                              Return to
                              <Link to="#" onClick={() => history.push("/auth/sign-in")}> Login</Link>
                            </p>
                          </div>
                        </div>

                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </AuthWrapper>
  );
};

export default PasswordChange;
