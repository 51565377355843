import Styled from "styled-components";

const CardWrapper = Styled.div`
.select2-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-bottom: 0px;
    margin-top: -11px;
    position: relative;
    top: -4px;
}
.second-row-wrapper {
    margin-top: 20px !important;
    align-items: baseline;
}
.col-md-4.column.col {
    height: 70px;

}
input.InputElement.is-complete.Input {
    padding-top: 6px !important;
}
.cardnumber-stripe {
    padding-left: 13px;
    background-color: #FFFFFF -webkit-text-fill-color:#FFFFF;
    padding-top: 9px;
}
.StripeElement.StripeElement--complete {
    padding-top: 10px;
}
.StripeElement.StripeElement--invalid {
    padding-top: 10px;
}
.StripeElement--focus{
    padding-top:10px;
}
.select2-container .select2-selection__control {
    border: 1px solid #cfd8dc;
    max-height: 32px;
    min-height: 32px;
    background: #f4f7f9;
}
.custom-style.form-group input {
    padding: 15px 10px;
    background: #f4f7f9;
    border-radius: 4px;
}
.StripeElement.StripeElement--empty {
    padding: 9px 0px 0 12px;
}
.custom-style label {
    color: rgb(120, 144, 156);
    font-size: 12px;
    font-weight: 400;
}
.sc-bcXHqe.bemNKV.custom-style.custom-style-alignment.form-group {
    position: relative;
    top: -2px;
}
.custom-style{
    margin-bottom: 0px;
    margin-top: 5px;
     .form-text {
    color: red;
    margin-bottom: 19px;
}

.sc-bcXHqe.dNAgxh.custom-style.form-group input {
    padding: 15px 12px;
    border-radius: 4px;
    font-size:14px;
}
button.sc-dkrFOg.fqxpbm.outlined.modal-btn.btn.btn-primary {
    margin-top: 22px;
}
button.sc-dkrFOg.fqxpbm.outlined.btn.btn-primary {
    display: flex;
    align-items: center;
}
.custom-style.form-group label {
    margin-bottom: 6px;
    color: rgb(120, 144, 156);
    font-size: 12px;
    font-weight: 400;
    line-height: 12.1px;
}
   .marketplace{
        border-radius:12px;
        border:1px solid #E6E6E6;
        padding: ${({ theme }) => theme["base-padding"]};
        background:url("https://sale-support-logos.s3.us-west-1.amazonaws.com/light-bg.svg"),#f8f8f8 ;
        background-size:100% ;
        background-repeat:no-repeat ;
        cursor:pointer;
        @media(max-width:1600px){
            padding:14px;
        }
        
        .card-number{
            color: ${({ theme }) => theme["text-primary"]};  
        }
        .switch{
            background:${({ theme }) => theme["success-color"]}; 
            color:${({ theme }) => theme["white-color"]};
            display:flex;
            align-items:center;
            padding:0px 20px;
            border-radius:50px;
            font-size:${({ theme }) => theme["base-font-size-small"]};
            font-weight:${({ theme }) => theme["bold-weight"]};
        }
        p{
            color:${({ theme }) => theme["text-primary"]};
            font-size:${({ theme }) => theme["medium-font-size"]};
        }
        .arrow-badge{
            grid-column-gap:12px;
            .switch-badge{
                color: ${({ theme }) => theme["white-color"]};
                font-size: 1.125rem;
                padding:1px 12px;
                border-radius:50px;
                &.disabled{
                 background-color: ${({ theme }) => theme["orange_peel"]};
           
                }
            }
    
    }
    .error{
        color: red;
        fontSize: 12px;
    }
    .fba-details{
        flex:1;
        .marketplace-detail{
        grid-row-gap:16px;
        flex:1;
        .info{
            grid-row-gap:5px;
            align-items:flex-start;
            span{
            font-size: ${({ theme }) => theme["font-size-small"]};
            color:${({ theme }) => theme["secondary-color"]};  

        }
        .badge{
            background:${({ theme }) => theme["dusty_grey"]};  
            color:${({ theme }) => theme["white-color"]};  
            border-radius:50px; 
            padding:2px 20px;
        }
        }
       
    }
    }
    &.enabled{
            background:url("https://sale-support-logos.s3.us-west-1.amazonaws.com/blue-bg.svg"), linear-gradient(135deg, #1565D8 0%, #090C53 100%) ;
            .title,.card-number{
                 color: ${({ theme }) => theme["white-color"]};
            }
       
            .check-svg{
                width:16px;
                height:16px;
                border-radius:50px;
                position:relative;
                background:${({ theme }) => theme["success-color"]}; 
                svg{
                color: ${({ theme }) => theme["white-color"]}; 
                position:absolute; 
            } 
            }
            p{
            color:${({ theme }) => theme["grey-secondary"]};
            font-size:${({ theme }) => theme["medium-font-size"]};
        }      
    }
  
    }
        }
    }
    .mt-36{
        margin-top:36px;
        margin-bottom:36px;
    }
    .cardnumber-stripe{
        padding-left:13px;
        background-color: #FFFFFF
        -webkit-text-fill-color: #FFFFF
    }
    .ElementsApp {
      font-size: 10px;
    }
    .StripeElement--webkit-autofill{
      background-color: #f4f7f9 !important;
    }
    .StripeElement--invalid{

    }
  }
`
export { CardWrapper };
