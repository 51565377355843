import styled from "styled-components";

const CommissionCardWrapper = styled.div`
  border-radius: 8px;
  background:${({ theme }) => theme['new-bg']};
  width: 324px;
  height: 345px;
  position: relative;

  .card-wrapper-commission {
    position: relative;
    z-index: 2;
    img {
      position: absolute;
      right: 0;
      z-index: -1;
    }
    h1 {
      color: var(--White, #fff);
      font-size: 32px;
      font-style: normal;
      font-weight: 900;
      line-height: 38px;
      padding: 24px 0px;
      margin: 0 24px;
      border-bottom: 1px solid #077;
    }
  }
  .card-items-st {
    display: flex;
    align-items: center;
    grid-gap: 8px;
    padding: 0 24px 0px;
    p {
      color: var(--White, #fff);

      /* Body 01 */
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      margin: 0px;
    }
    img {
      width: 22px;
    }
  }
  .bar-row-wrapper {
    display: flex;
    flex-direction: column;
    grid-row-gap: 16px;
    margin-top: 16px;
    position: relative;
    z-index: 2;
  }
`;

export { CommissionCardWrapper };
