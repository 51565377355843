import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Form } from "react-bootstrap";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import { Formik, getIn } from "formik";
import * as yup from "yup";
import ReCAPTCHA from "react-google-recaptcha";

import Button from "../../components/Button";
import LabelInput from "../../components/Inputs/label-input";
import Notification from '../../components/notifications/notification'

import { APP_LOGOS, APP_TITLES, RECAPTCHA_SITE_KEYS } from "../../constants";

import { AuthWrapper } from "./style";

import { SignIn, SetAuthState } from "../../redux/slices/auth-slice";

const hostName = window.location.hostname;

const recaptchaKey = RECAPTCHA_SITE_KEYS[hostName];
const schema = yup.object().shape({
  email: yup
    .string()
    .email("Field should contain a valid e-mail")
    .max(255)
    .required("E-mail is required"),
  password: yup.string().required("Password is required"),
});

const Index = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [passwordShow, SetPasswordShow] = useState(true);
  const [isCaptchaClick, setIsCaptchaClick] = useState(true);
  const [captchaValue, setCaptchValue] = useState('');

  const { error, loading, logout } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(SetAuthState({ field: "loading", value: false }));
    setTimeout(() => {
      if (logout) {
        location.reload(true);
        dispatch(SetAuthState({ field:'logout', value: null}));
      }
    }, 500);
  }, []);

  useEffect(() => {
    if (error) {
      Notification({
        type: "error",
        title: "Sign In Error",
        description: error
      });

      if (error.includes('account is not verified')) {
        history.push("/auth/email-verification");
      }

      dispatch(SetAuthState({ field: "error", value: "" }));
      if (!isCaptchaClick) {
        resetCaptcha();
      }
    }
  }, [error]);

  const handleSetPassword = () => {
    SetPasswordShow(!passwordShow);
  };

  const getStyles = (touched, errors, fieldName) => {
    if (touched[fieldName]) {
      if (getIn(errors, fieldName)) {
        return {
          border: "1px solid red",
        };
      }
    }
  };

  const onChange = () => {
    setIsCaptchaClick(false);
  };

  const setCaptchaRef = (ref) => {
    if (ref) {
      setCaptchValue(ref);
    }
  };

  const resetCaptcha = () => {
    if (captchaValue) {
      captchaValue.reset();
    }
    setIsCaptchaClick(true);
  };

  return (
    <AuthWrapper>
      <div className="content-holder" style={loading ? { opacity: 0.5 } : null}>
        <Row className="m-0 header-space">
          <Col md={5} sm={12}>
            <div className="left-section">
              {APP_LOGOS[hostName] ?
                <img src={APP_LOGOS[hostName]} alt="no-logo" />
                :
                <h2>{APP_TITLES[hostName]}</h2>
              }
              <p className="logo-description mb-0"> Don’t have an account?
              Create one. It takes less than a minute!
              </p>
            </div>
          </Col>
          <Col md={7} sm={12}>
            <div className="right-content">
              <div className="user-form">
                <h2 className="heading">Sign in to your account</h2>
                <span className="heading-caption">
                  Enter your credentials to access your account{" "}
                </span>
                <hr className="bottom-line" />
                <Formik
                  validationSchema={schema}
                  onSubmit={(values) => {
                    dispatch(SignIn(values));
                  }}
                  initialValues={{
                    email: "",
                    password: "",
                    hostName
                  }}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    touched,
                    errors,
                  }) => (
                    <Form noValidate onSubmit={handleSubmit} className="auth-form">
                      <LabelInput
                        name="email"
                        label="Email"
                        value={values.email}
                        placeholder="Your Email Here"
                        onChange={handleChange}
                        onBlur={handleBlur("email")}
                        style={getStyles(touched, errors, "email")}
                      />

                      <div className="password-input">
                        <LabelInput
                          name="password"
                          label="Password"
                          value={values.password}
                          type={passwordShow ? "password" : "text"}
                          placeholder="Your password Here"
                          onChange={handleChange}
                          onBlur={handleBlur("password")}
                          style={getStyles(touched, errors, "password")}
                          className="password-field"
                        />
                        {passwordShow ? (
                          <FaEyeSlash
                            className="cursor-pointer"
                            onClick={handleSetPassword}
                          />
                        ) : (
                          <FaEye
                            className="cursor-pointer"
                            onClick={handleSetPassword}
                          />
                        )}
                      </div>

                      <div className="text-end  d-flex justify-content-end">
                        <Link
                          to="/auth/forget-password"
                          className="forget-password"
                          onClick={() => history.push("/auth/forget-password")}
                        >
                          Forgot Password?
                      </Link>
                      </div>
                      <div className="white-box captcha-box">
                        <ReCAPTCHA
                          style={{ display: 'inline-block' }}
                          sitekey={recaptchaKey}
                          onChange={onChange}
                          ref={setCaptchaRef}
                        />
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <Button
                          text={loading ? "Login...." : "Login"}
                          className="primary mb-0"
                          type="submit"
                          width=""
                          disabled={isCaptchaClick}
                        />
                        <div className="d-flex justify-content-center have-account">
                          <p>
                            Don't have an account!
                          <Link
                              to="/auth/sign-up"
                            >
                              Register
                          </Link>
                          </p>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </AuthWrapper>
  );
};

export default Index;
