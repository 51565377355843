import React, { useState, useEffect } from "react";
import { Form, ProgressBar } from "react-bootstrap";

import Notification from '../../notifications/notification';

import { InputWrapper } from "../style";

const Index = (props) => {
  const {
    label,
    type,
    placeholder,
    inputStatus,
    name,
    strength,
    onChange,
    value,
    style,
    onBlur,
    fromPage,
    className,
    disabled,
    icon,
    imgicon,
    svgicon,
    copyToClipboard,
    as,
    rows
  } = props;

  const [progress, setProgress] = useState(0);
  const [variant, setVariant] = useState();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (name === "password") {
      if (value === "") {
        setProgress(0);
      }
      passwordStrength(value);
    }
  }, [value]);

  const passwordStrength = (value) => {
    let strength = 0;
    if (value.length == 0) {
      strength = 0;
      return;
    }

    const regex = ["[A-Z]", "[a-z]", "[0-9]", "[$@$!%*#?&]"];

    let passed = 0;

    for (let i = 0; i < regex.length; i++) {
      if (new RegExp(regex[i]).test(value)) {
        passed++;
      }
    }
    if (value.length > 8) {
      passed++;
    }

    switch (passed) {
      case 0:
        setVariant("");
        break;
      case 1:
      case 2:
        strength = 20;
        setVariant("danger");
        break;
      case 3:
        strength = 40;
        setVariant("warning");
        break;
      case 4:
        strength = 60;
        setVariant("warning");
        break;
      case 5:
        strength = 100;
        setVariant("success");
        break;
    }
    setProgress(strength);
  };


  const handleCopy = () => {
    copyToClipboard();
    setOpen(true);
  };

  useEffect(() => {
    if (open) {
      Notification({
        type: "success",
        title: "Copied !!!"
      });
    }
    setOpen(false);
  }, [open]);

  const entering = (e) => {
    e.children[1].style.backgroundColor = 'white';
    e.children[1].style.padding = '5px';
};
  return (
    <InputWrapper className={className}>
      {label && (
        <div className="d-flex justify-content-between">
          {label ? <Form.Label>{label}</Form.Label> : null}
          {strength ? (
            // <div className="d-flex align-items-center grid-column-gap-10 progress-status">
            <div className="progress-status">
              <label className="progress-title">Strength</label> <ProgressBar now={progress} />
            </div>
          ) : null}

        </div>
      )}
      <div className={icon && 'input-with-icon'}>
        <Form.Control
          type={type}
          placeholder={placeholder}
          value={value}
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          style={style}
          disabled={disabled}
          required
          as={as}
          rows={rows}
        />
        {svgicon && <img className="input-icon" src={svgicon} alt="icon-img" />}
        {imgicon && <img className="input-icon" src={imgicon} alt="icon-img" onClick={handleCopy}/>}
      {name === "password" && fromPage === "signUp" && (
        <span style={{ fontSize: "9px", color: "#607D8B" }}>
          Must Contain 8 Characters, One Uppercase, One Lowercase, One Number
          and One Special Case Character
        </span>
      )}
      {inputStatus && <Form.Text>{inputStatus}</Form.Text>}
    </div>
    </InputWrapper>
  );
};

export default Index;
