import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Spin } from "antd";

import ButtonSt from "../../../components/ButtonSt";

import ErrorIcon from "../../../assets/images/error-icon.svg";

import { ModalPaymentLaterStyleWrapper } from "./style";
import { GetStore, UpdateStore } from "../../../redux/slices/store-slice";
import { SetAuthState } from "../../../redux/slices/auth-slice";

const ModalStepsFour = ({ paymentLater, setPaymentLater, handleNext, clearPaymentStates }) => {
  const dispatch = useDispatch();
  const { storeId } = useSelector((state) => state.store);
  const [loading, setLoading] = useState(false);

  return (
    <Modal
      footer={null}
      centered
      onOk={() => setPaymentLater(false)}
      closeIcon={false}
      closable={false}
      okText="Proceed"
      width={480}
      open={paymentLater}
      className="processed-payment-modal"
    >
      <ModalPaymentLaterStyleWrapper>
        {
          loading ? (
            <Spin size="large"/>
          ):(
              <div className="box-wrapper">
                <img src={ErrorIcon} />
                <h6>Are you sure you want to skip this step?</h6>
                <p>
                  Your cases cannot be filed until you add your preferred payment
                  method.
                </p>
                <div className="button-footer-ui">
                  <ButtonSt outline onClick={() => {
                    dispatch(UpdateStore({ storeId, updateParams: { payLater: true } }));
                    dispatch(SetAuthState({ field: 'payLater', value: true }));
                    setLoading(false);
                    setPaymentLater(false);
                    handleNext();
                  }}>Add Later</ButtonSt>
                  <ButtonSt onClick={() => {
                    clearPaymentStates();
                    setPaymentLater(false);
                  }}>Add New</ButtonSt>
                </div>
              </div>
          )
        } 
      </ModalPaymentLaterStyleWrapper>
    </Modal>
  );
};

export default ModalStepsFour;
