import papa from 'papaparse';
import { debounce, isEmpty, round, startCase } from "lodash";

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { components } from "react-select";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { BsBriefcaseFill } from "react-icons/bs";

import { ButtonGroup } from 'react-bootstrap';

import Button from "../../components/Button";
import Input from '../../components/Inputs/label-input';
import Modal from '../../components/Modal/modal';
import Pagination from "../../components/Pagination/pagination";
import Select from "../../components/Select";
import SearchInput from "../../components/SearchInput";
import Spin from "../../components/Spin/Index";
import Table from "../../components/Table/table";
import Toast from '../../components/Toast';

import CA from '../../assets/img/flags/north-america/canada.svg';
import US from '../../assets/img/flags/north-america/usa.svg';
import BR from '../../assets/img/flags/north-america/brazil.svg';
import MX from '../../assets/img/flags/north-america/mexico.svg';
import NoImage from '../../assets/images/product.svg';

import dummyFile from '../../assets/True Dimensions Template.csv';

import {
  ALL_MARKETPLACES,
  COUNTRY_CODE_WRT_MARKETPLACE,
  CURRENCY_SYMBOLS_BY_MARKETPLACE_ID,
  REGION_TO_MARKETPLACE
} from '../../constants';
import { FormatValueToLocaleString } from "../../helpers/format-value";

import {
  ClaimFulfillmentFee,
  GetFulfillmentFee,
  GetFulfillmentFeeForUser,
  SetFulfillmentFeeState,
  MakeAffectedOrdersFile,
  SaveTrueDimensions
} from "../../redux/slices/fulfillment-fee-slice";

import { GetStoreNames } from "../../redux/slices/store-slice";

import { FulfillmentFeeWrapper } from "./style";

const { Option } = components;
const Tile = (props) => {
  return (
    <Option {...props} value={"default"}>
      <div className="select-option">
        <span className="d-block bold-weight">{props.data.userName !== 'All' ? props.data.userName : null}</span>
        <span>{props.data.name}</span>
      </div>
    </Option>
  );
}

const fulfillmentFeeStatusDropDown = [
  {
    value: 'To_Be_Claimed',
    label: 'Claimable'
  },
  {
    value: 'Claimed',
    label: 'Claimed'
  }
];

const Index = () => {
  const dispatch = useDispatch();

  const [searchKeyword, setSearchKeyword] = useState('');
  const [storeNames, setStoreNames] = useState([]);
  const [marketplaceDropdownData, setMarketplaceDropdownData] = useState([]);

  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [severity, setSeverity] = useState('');

  const [pageNumber, setPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState(25);
  const [pageOption, setPageOption] = useState({
    value: 25,
    lable: 25
  });

  const [filters, setFilters] = useState({
    storeId: 'All',
    marketplaceId: 'All',
    status: 'To_Be_Claimed',
    searchKeyword: '',
    dimensions: 'trueDimensions'
  });

  const [caseId, setCaseId] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [fulfillmentFee, setFulfillmentFee] = useState({});
  const [fulfillmentCaseError, setFulfillmentCaseError] = useState('');
  const [caseStatus, setCaseStatus] = useState('');
  const [fileModal, setFileModal] = useState(false);
  const [selectedStore, setSelectedStore] = useState('');
  const [disableButton, setDisableButton] = useState(true);
  const [fileData, setFileData] = useState(null);
  const [dimensions, setDimensions] = useState('trueDimensions');

  const {
    fulfillmentFeesData,
    totalFulfillmentFee: total,
    message,
    error,
    loading,
    fileUploaded
  } = useSelector((state) => state.fulfillmentFee);

  const { user, storeStatus, storesCount, payLater } = useSelector((state) => state.auth);
  const { storeNamesList, store } = useSelector((state) => state.store);

  const countryObj = {
    CA, US, BR, MX
  };

  useEffect(() => {
    if (!isEmpty(filters)) {
      getFulfillmentFee();
    }
  }, [filters, pageNumber, pageLimit]);

  useEffect(() => {
    if (error) {
      setToastMessage(error);
      setSeverity('error');
      setToastOpen(true);
      dispatch(SetFulfillmentFeeState({ field: 'error', value: '' }));
      return;
    } else if (message) {
      setToastMessage(message);
      setSeverity('info');
      setToastOpen(true);
      dispatch(SetFulfillmentFeeState({ field: 'message', value: '' }));
      return;
    }
  }, [error, message]);

  useEffect(() => {
    if (payLater || store?.payLater
      || ["admin", "agency-manager", "affiliate-manager"].includes(user.role)
    ) {
      dispatch(GetStoreNames());
    }

    setMarketplaceDropdownData(ALL_MARKETPLACES);
  }, [dispatch]);

  useEffect(() => {
    if (storeNamesList && storeNamesList.length > 0) {
      setSelectedStore(storeNamesList[0]._id);
      const storeNamesData = storeNamesList?.map(store => ({
        value: store._id,
        label: `${store.name}`,
        userName: store.userName,
        name: store.name
      }));

      const stores = [];

      stores.push(...storeNamesData);
      setStoreNames(stores);
    } else {
      setStoreNames([]);
    }
  }, [storeNamesList]);

  const getFulfillmentFee = () => {
    const skip = (pageNumber - 1) * pageLimit;
    const limit = pageLimit;
    if (user.role === 'user' || user.role === 'affiliate-manager') {
      dispatch(GetFulfillmentFeeForUser({ filters, skip, limit }));
    } else {
      dispatch(GetFulfillmentFee({ filters, skip, limit }));
    }
  };

  const handleSearch = debounce((value, key) => {
    setSearchKeyword(value);
    setPageNumber(1);
    setFilters({
      ...filters,
      [key]: value
    });
  }, 500);

  const handlePageLimit = (e) => {
    setPageLimit(e.value);
    setPageOption(e);
  };

  const handlePageNumberChange = (e) => {
    const totalPages = Math.ceil(total / pageLimit)
    if (e.key == "Enter") {
      if (e.target.value < 1 || !e.target.value) {
        setPageNumber(1);
      } else if (e.target.value > totalPages) {
        setPageNumber(totalPages);
      } else {
        setPageNumber(Number(e.target.value));
      }
    }
  };

  const handlePageChangeLeft = () => {
    if (pageNumber - 1 > 0) {
      setPageNumber(pageNumber - 1);
    } else {
      setPageNumber(1);
    }
  };

  const handlePageChangeRight = () => {
    const totalPages = Math.ceil(total / pageLimit)
    if (pageNumber + 1 < totalPages) {
      setPageNumber(pageNumber + 1);
    } else {
      setPageNumber(totalPages > 0 ? totalPages : 1);
    }
  };

  const handleChange = (e, key) => {
    setPageNumber(1);
    if (!e) {
      setMarketplaceDropdownData(ALL_MARKETPLACES);
      setFilters({
        ...filters,
        storeId: 'All',
        marketplaceId: 'All',
        searchKeyword: ''
      });
    } else {
      setFilters({
        ...filters,
        [key]: e?.value
      });

      const { region } = storeNamesList.find((st) => st._id === e.value);
      const marketplaces = REGION_TO_MARKETPLACE[region];
      if (marketplaces) {
        setMarketplaceDropdownData(marketplaces);
      } else {
        setMarketplaceDropdownData([]);
      }
    }
  };

  const handleDimensions = (key, value) => {
    setDimensions(value);
    setFilters({
      ...filters,
      [key]: value
    });
  }

  const handleMarketplaceChange = (e, key) => {
    setPageNumber(1);
    if (!e) {
      setFilters({
        ...filters,
        marketplaceId: 'All'
      });
    } else {
      setFilters({
        ...filters,
        [key]: e.value
      });
    }
  };

  const handleFulfillmentFeeStatus = (status) => {
    setFilters({
      ...filters,
      status: status.value
    });
  };

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToastOpen(false);
  };

  const handleModal = (fee) => {
    setFulfillmentFee(fee);
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setCaseStatus('');
    setCaseId('');
  };

  const handleFulfillmentCase = (e) => {
    if (caseId !== '' || caseStatus !== '') {
      const {
        _id: fulfillmentFeesCalculatorId,
        storeId
      } = fulfillmentFee;

      dispatch(ClaimFulfillmentFee({
        storeId,
        fulfillmentFeesCalculatorId,
        caseId
      }));

      setShowModal(false);
      setCaseStatus('');
      setCaseId('');
      getFulfillmentFee();
    } else {
      setFulfillmentCaseError('Enter the correct caseId');
    }
  };

  const handleFileUpload = () => {
    if (fileData !== [] && selectedStore !== '') {
      const data = {
        storeId: selectedStore,
        data: fileData
      };
      dispatch(SaveTrueDimensions(data));
      dispatch(SetFulfillmentFeeState({ field: 'err', value: '' }));
      setFileModal(false);
    } else {
      dispatch(SetFulfillmentFeeState({ field: 'err', value: 'Enter the required fields' }));
    }
  };

  const handleFileChange = (event) => {
    const fileObj = event.target.files[0];
    const { name: fileName } = fileObj;

    papa.parse(fileObj, {
      header: true,
      trimHeaders: true,
      dynamicTyping: true,
      skipEmptyLines: 'greedy',
      complete: (res) => {
        if (res.errors && res.errors.length > 0) {
          setDisableButton(true);
          setSeverity('error');
          setToastOpen(true);
          dispatch(SetFulfillmentFeeState({ field: 'error', value: `${fileName} file is not csv file !!` }));
          console.log(`${fileName} file is not csv file !!`);
        } else {
          setDisableButton(false);
          console.log(`${fileName} file uploaded successfully.`);
          setFileData(res.data);
        }
      }
    });
  };

  useEffect(() => {
    if (fileUploaded) {
      getFulfillmentFee();
    }
  }, [fileUploaded]);

  return (
    <React.Fragment>
      <FulfillmentFeeWrapper>
        <Toast
          message={toastMessage}
          severity={severity}
          toastOpen={toastOpen}
          handleToastClose={handleToastClose}
        />
        {(user.role === 'user' || user.role === 'affiliate-manager')
            ?
          <Button
              text={"Upload a File"}
              className=" mb-2 outlined"
              type="button"
              width=""
              outlined
              onClick={() => setFileModal(true)}
            />
            : null }
          <br/>
        <div className="d-flex justify-content-between mb-3 align-items-center postion-realtive flex-wrap">
          <SearchInput placeholder="Title/Asin/Seller Sku" onChange={(e) => handleSearch(e.target.value, 'searchKeyword')} />

          <div className="d-flex justify-content-end grid-column-gap-10 mb-3">
          {user.role === 'admin' || user.role === 'agency-manager'
            ? <Select
            options={fulfillmentFeeStatusDropDown}
            className="dashbaord-select"
            handleChange={(e) => handleFulfillmentFeeStatus(e)}
            placeholder='Select Status'
            defaultValue={fulfillmentFeeStatusDropDown?.[0] || ''}
          /> : null
          }
            <Select
              handleChange={(e) => handleChange(e, 'storeId')}
              options={storeNames}
              className="dashbaord-select"
              isClearable={true}
              components={{ Option: Tile }}
              placeholder='Select Store'
            />
            <Select
              className="dashbaord-select mb-0"
              name='selectedMarketplace'
              placeholder='Select Marketplace'
              options={marketplaceDropdownData}
              isClearable={true}
              handleChange={(e) => handleMarketplaceChange(e, 'marketplaceId')}
            />
          </div>

          <br/>
          <div className="d-flex flex-wrap grid-column-gap-16 align-items-center">
              <ButtonGroup
                className="dimensions-filter"
              >
                <Button
                  text="True"
                  value="trueDimensions"
                  className={dimensions === 'trueDimensions' ? "active" : "non-active"}
                  onClick={() => handleDimensions('dimensions', 'trueDimensions')}
                /> 
                  <Button
                    text="Amazon"
                    value="packageDimensions"
                    className={dimensions === 'packageDimensions' ? "active" : "non-active"}
                    onClick={() => handleDimensions('dimensions', 'packageDimensions')}
                  />
                </ButtonGroup>
              </div>
        </div>
        <div className="pt-3">
          <Table
            viewHeight="fulfillment-fees-table"
            tableHeader={
              <tr>
                <th>Store</th>
                <th>Marketplace</th>
                <th>Product Description</th>
                {user.role === 'admin' || user.role === 'agency-manager' ?
                <th>FNSKU</th>
                : null }
                <th>{startCase(dimensions)}</th>
                {user.role === 'admin' || user.role === 'agency-manager' ?
                <>
                <th>Units Sold</th>
                <th>Units Affected</th>
                <th>Charged Fee/unit</th>
                <th>Expected Fee/unit</th>
                <th>Money Lost</th>
                <th>Actions</th>
                </> : null }
              </tr>
            }
            tableBody={
              <>
                {!loading && !fulfillmentFeesData?.length ? (
                  <tr><td>
                    <div className='error'>
                      <h1>No Data Available</h1>
                    </div>
                  </td></tr>
                ) : null}
                {loading ? <tr><td><Spin /></td></tr> : fulfillmentFeesData?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <>
                          <span className="d-block mb-0">{item.storeName}</span>
                          <span className="weight-bold small-fontSize">
                            {item.userEmail}
                          </span>
                        </>
                      </td>
                      <td>
                        <span className="d-block mb-0">
                          <img
                            src={countryObj[COUNTRY_CODE_WRT_MARKETPLACE[item.marketplaceId]]}
                            alt="marketplace-countries"
                            width="22"
                            height="22"
                          /> &nbsp;
                          {COUNTRY_CODE_WRT_MARKETPLACE[item.marketplaceId]}
                        </span>
                      </td>
                      <td>
                        <>
                          <div className="d-flex align-items-center ">
                            <img width="40" height="40" src={item.imageUrl !== 'N/A' ? item.imageUrl : NoImage} alt="no-product" />
                            <div className="table-content-wrapper ml-2">
                              <h6 className="fs-12  text-primary">
                                <OverlayTrigger overlay={<Tooltip>{item.title}</Tooltip>}>
                                  <span className="d-inline-block">
                                    {item.title?.length > 35 ? `${item.title.substring(0, 35)}.....` : item.title}
                                  </span>
                                </OverlayTrigger>
                              </h6>
                              <div className="d-flex grid-column-gap-12">
                                <span className="fs-10">
                                  ASIN:
                                  <span className="highlighted-text">
                                    {item.asin}
                                  </span>
                                </span>
                                <span className="outlined-color fs-10">
                                  SKU:
                                  <OverlayTrigger overlay={<Tooltip>{item.sellerSku}</Tooltip>}>
                                    <span className="text-primary weight-bold d-inline-block">
                                      {item.sellerSku?.length > 18 ? `${item.sellerSku.substring(0, 18)}...` : item.sellerSku}
                                    </span>
                                  </OverlayTrigger>
                                </span>
                              </div>
                            </div>
                          </div>
                        </>
                      </td>
                      {user.role === 'admin' || user.role === 'agency-manager' ?
                      <td><span className="text-right">{item.fnsku}</span></td>
                      : null }
                      <td>
                        <div className="table-type-data d-flex align-items-center space-between">
                          <div>
                            <span className="d-block mb-0">LENGTH</span>
                            {item[dimensions]?.length.value ?
                            <span className="weight-bold small-fontSize">
                              {item[dimensions]?.length.value}
                              ({item[dimensions]?.length?.unit})
                            </span>
                            : '--'}
                          </div>
                          <div>
                            <span className="d-block mb-0">WIDTH</span>
                            {item[dimensions]?.length.value ?
                            <span className="weight-bold small-fontSize">
                              {item[dimensions]?.width.value}
                              ({item[dimensions]?.width?.unit})
                            </span>
                            : '--' }
                          </div>
                          <div>
                            <span className="d-block mb-0">HEIGHT</span>
                            {item[dimensions]?.length.value ?
                            <span className="weight-bold small-fontSize">
                              {item[dimensions]?.height.value}
                              ({item[dimensions]?.height?.unit})
                            </span>
                            : '--' }
                          </div>
                          <div>
                            <span className="d-block mb-0">WEIGHT</span>
                            {item[dimensions]?.length.value ?
                            <span className="weight-bold small-fontSize">
                              {item[dimensions]?.weight.value}
                              ({item[dimensions]?.weight?.unit})
                            </span> 
                            : '--' }
                          </div>
                        </div>
                      </td>
                      {user.role === 'admin' || user.role === 'agency-manager' ?
                      <>
                      <td><span className="text-right">{item.unitsSold}</span></td>
                      <td><span className="d-block mb-0">{item.unitsAffected}</span></td>
                      <td><span className="d-block mb-0">{round(item.fulfillmentFeeChargedPerUnit, 2)}</span></td>
                          <td><span className="d-block mb-0">{round(item.fulfillmentFeeExpectedPerUnit, 2)}</span></td>
                      <td><span className="d-block mb-0">{`${CURRENCY_SYMBOLS_BY_MARKETPLACE_ID[item.marketplaceId]}${FormatValueToLocaleString(item.moneyLost)}`}</span></td>
                      <td>
                        <Button
                          text="Download"
                          value="Download"
                          className={"active"}
                          onClick={() => dispatch(MakeAffectedOrdersFile({
                            storeId: item.storeId,
                            sellerSku: item.sellerSku
                          }))}
                        />
                      </td>
                      <td>
                      {
                        item.status == 'To_Be_Claimed' ?
                        <div className="text-center" style={{ marginTop: '25%' }}>
                            <OverlayTrigger
                              placement="left"
                              delay={{ show: 250, hide: 400 }}
                              transition={false}
                              overlay={<Tooltip>{
                                <div
                                  style={{
                                    backgroundColor: '#299F63',
                                    padding: '2px 10px',
                                    color: 'white',
                                    borderRadius: 3,
                                  }}
                                >
                                  Submit Case ID
                          </div>
                              }</Tooltip>}
                            >
                              <BsBriefcaseFill size='1.5em' className="icon-color cursor-pointer" onClick={() => handleModal(item)} />
                            </OverlayTrigger>
                          </div> : <></>
                      }
                      </td> </>: null
                }
                    </tr>
                  )
                })
                }
              </>
            }
          />

        {showModal ? <Modal
          showModal={showModal}
          modalHeader="Claim Fulfillment Fee"
          modalBody={<div>
            <p>
              1. Copy & Paste Case Id. <br></br>
            </p>
            <Input
              type="number"
              style={{ maxWidth: '160px' }}
              placeholder="Paste Case Id"
              value={caseId}
              onChange={(e) => { setCaseId(e.target.value); setCaseStatus('Claimed') }}
              disabled={(caseStatus !== '' && caseStatus !== 'Claimed') ? true : false}
            />
            <p style={{ color: 'red', font: '11px' }}>{fulfillmentCaseError} </p>
          </div>
          }
          handleConfirm={() => handleFulfillmentCase()}
          handleClose={() => handleModalClose()}
          showFooter={true}
        />
          : null
        }

        {fileModal ? <Modal
        showModal={fileModal}
        modalType="file"
        modalHeader="Upload a File"
        modalBody={<div>
          <p>
            1. Select a file to upload. <br></br>
          </p>
          <Select
            options={storeNames}
            className="dashbaord-select"
            handleChange={(e) => setSelectedStore(e.value)}
            placeholder='Select Store'
            defaultValue={storeNames?.[0] || ''}
          />
           <input
              type="file"
              placeholder='choose file'
              onChange={(e) => handleFileChange(e)}
            />
         <a href={dummyFile}>
            Download Sample.csv 
         </a>
        </div>
        }
        disabled={disableButton}
        handleConfirm={() => handleFileUpload()}
        handleClose={() => setFileModal(false)}
        showFooter={true}
      />
        : null
      }
          <Pagination
            pageLimit={pageLimit}
            total={total}
            pageNumber={pageNumber}
            totalPages={Math.ceil(total / pageLimit)}
            handlePageChangeLeft={handlePageChangeLeft}
            handlePageChangeRight={handlePageChangeRight}
            handleLimitChange={handlePageLimit}
            handlePageNumberChange={handlePageNumberChange} />
        </div>
      </FulfillmentFeeWrapper>
    </React.Fragment>
  );
};

export default Index;
