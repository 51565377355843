import styled from "styled-components";

const TitleStyleWrapper = styled.div`
  max-width: 239px;
  h3.current-tile {
    margin: 0px;
    color: var(--Selected_Tab, #1890ff);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
  }

  p {
    color: var(--Body-Text-Varaint, #98a1a5);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }
  p.current-case-item {
    margin-bottom: 16px;
    color: #4e5969;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
  }
`;
const StepThreeWrapper = styled.div`
  height: 100%;
  .amount_Box_overlay {
    border-radius: 8px;
    border: 1px solid var(--Border-Variant, #dadfe3);
    background: var(--White, #fff);
    padding: 16px;
    display: flex;
    grid-gap: 32px;
    justify-content: space-between;
    .amount_Box_item {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      grid-row-gap: 12px;
      h4 {
        color: var(--Secondary, #151a26);
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        margin: 0px;
      }
      .amount_card_overlay {
        display: flex;
        align-items: center;
        grid-gap: 24px;
        flex-wrap: wrap;
        grid-row-gap: 16px;
      }
    }
  }
`;
const ManegePermissionsWrapper = styled.div`
  .table_container {
    border-radius: 4px;
    border: 1px solid ${(props) => props.theme["new-primary"]};
    background: var(--White, #fff);
    box-shadow: 0px 1.852px 3.148px 0px rgba(0, 0, 0, 0.01),
      0px 8.148px 6.519px 0px rgba(0, 0, 0, 0.01),
      0px 20px 13px 0px rgba(0, 0, 0, 0.01),
      0px 38.519px 25.481px 0px rgba(0, 0, 0, 0.02);
    padding: 10px 16px;
    h6 {
      color: var(--text-field-body-color, #5e718d);
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
      margin-bottom: 10px;
    }
  }
  .sub-header-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    grid-gap: 12px;
}
  .manage-permissions {
    margin: 0;
    display: grid;
    grid-template-columns: 1fr 20px;
    margin-left: auto;
    width: 100%;
    grid-gap: 7px;
    justify-content:space-between;
    .title-wrapper{
      svg{
        width:29px;
      }
    }
    svg{
      width: 26px;
    }
    p{
      color: #151A26;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      b{
        color:${(props) => props.theme["new-primary"]};
      }
    }
}
  .ask-question-wrapper {
    border-radius: 4px;
    border: 1px solid ${(props) => props.theme["new-primary"]};
    background: var(--White, #fff);
    display: flex;
    flex-direction: column;
    grid-row-gap: 8px;
    padding: 12px 12px 8px;
    h6 {
      color: #151A26;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px; 
      margin: 0;
    }
    p {
      color: var(--text-field-body-color, #5e718d);
      font-size: 11px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      margin: 0;
    }
  }
  .btn-type-text {
    border-radius: 3px;
    border: 1px solid ${(props) => props.theme["new-primary"]};
    color: ${(props) => props.theme["new-primary"]} !important;
    padding: 6px 9px;
    min-width: 106px;
  }
  .card_wrapper {
    border-radius: 8px;
    border: 1px solid var(--Border-Variant, #dadfe3);
    background: var(--White, #fff);
    padding: 22.5px 32px;
    .card_overlay_wrapper {
      max-width: 344px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin: auto;
      grid-row-gap: 16px;

      .card_overlay_item {
        display: flex;
        flex-direction: column;
        grid-row-gap: 8px;
        p {
          color: var(--Body-Darker, #474747);
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
          margin: 0px;
        }
        h3 {
          color: var(--Secondary, #151a26);
          text-align: center;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 20px;
          margin: 0px;
        }
      }
    }
  }
  .image-container-wrapper {
    display: flex;
    flex-direction: column;
    grid-row-gap: 12px;
    margin-top: 16px;
  }
  .card_ui_wrapper {
    display: flex;
    align-items: center;
    grid-gap: 8px;
    padding: 6px 8px;
    border-radius: 3px;
    justify-content: space-between;
    /* border: 1px solid var(--Border-Variant, #dadfe3); */
    user-select: none;
    align-items: center;
    height: 24px;
    grid-gap: 8px;
    p {
      margin: 0px;
      color: var(--text-field-body-color, #5e718d);
      font-style: normal;
      font-size: 10px;
      font-weight: 500;
      line-height: 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      grid-gap: 8px;
      margin: 0px !important;
    }
    img {
      cursor: pointer;
    }
  }
  .overlay_card_item {
    display: flex;
    grid-gap: 16px;
    align-items: center;
  }
  .card_ui_item {
    display: flex;
    align-items: center;
    grid-gap: 8px;
    padding: 6px 8px;
    border-radius: 3px;
    justify-content: space-between;
    border: 1px solid var(--Border-Variant, #dadfe3);
    user-select: none;
    align-items: center;
    color: var(--text-field-body-color, #5e718d);
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px;
    height: 24px;
    margin: 0px !important;
  }

  .overlay_wrapper {
    border-radius: 8px;
    border: 1px solid var(--Border-Variant, #dadfe3);
    background: var(--White, #fff);
    padding: 16px;
    margin-top: 16px;
    .header_wrapper {
      display: flex;
      align-items: center;
      grid-gap: 24px;
      img {
        width: 24px;
      }
      h2 {
        color: var(--Secondary, #151a26);
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        margin: 0;
      }
    }
    .card-items_wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 16px;
      padding-top:8px;
      .card_items {
        display: flex;
        align-items: center;
        grid-gap: 16px;
        padding-bottom: 16px;
        border-bottom: 1px solid #dadfe3;
        button {
          width: 128px;
        }
        p {
          margin-bottom: 0px;
          color: var(--text-field-body-color, #5e718d);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }
      }
    }
  }
  .card_items_overlay {
    min-height: 385px;
    border-radius: 8px;
    border: 1px solid var(--Border-Variant, #dadfe3);
    background: var(--White, #fff);
    padding: 16px;
  }
  .card_items_content-overlay {
    p {
      margin-top: 32px;
      margin-bottom: 12px;
    }
  }
  .right_side_content_overlay {
    border-radius: 8px;
    border: 1px solid var(--Border-Variant, #dadfe3);
    background: var(--White, #fff);
    padding: 16px;
    .header_st_wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .card_st_item {
        display: flex;
        align-items: center;
        grid-gap: 8px;
        h6 {
          color: var(--Secondary, #151a26);
          text-align: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 18px;
          margin: 0;
        }
      }
    }
    .right_side_header_item {
      display: flex;
      align-items: center;
      grid-gap: 8px;
      span {
        border-radius: 4px;
        border: 1px solid var(--Border-Variant, #dadfe3);
        padding: 6px 8px;
        color: var(--text-field-body-color, #5e718d);
        font-size: 10px;
        font-style: normal;
        font-weight: 600;
        line-height: 12px;
      }
    }
  }
  .bar_ui_wrapper_st {
    display: flex;
    flex-direction: column;
    grid-row-gap: 4px;
    margin-top: 12px;
    align-items: end;
    button {
      margin-top: 8px;
      width: 148px;
    }
  }
`;
const StepFourWrapper = styled.div`
  background-color: var(--White, #fff);
  border: 1px solid var(--Border-Variant, #dadfe3);
  padding: 24px;
  border-radius: 8px;
  margin-top: 16px;
  h6 {
    color: var(--Secondary, #151a26);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    margin-bottom: 16px;
  }
  .input-with-icon.input-icon-left.input-cvc .StripeElement {
    border-left: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 4px !important;
}
  .page-overlay-wrapper {
    display: flex;
    grid-gap: 24px;
  }
  .buttons-wrapper {
    display: flex;
    align-items: center;
    grid-gap: 16px;
    justify-content: end;
    margin-top: 16px;
    button {
      width: 172px;
    }
  }
  .form-ui-wrapper-st {
    min-width: 568px;
    width: 75%;
    border-radius: 8px;
    border: 1px solid var(--Border-Variant, #dadfe3);
    background: var(--White, #fff);
    padding: 16px;

    h3 {
      color: var(--Secondary, #151a26);
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      margin-bottom: 48px !important;
      line-height: 20px;
      margin-bottom: 24px;
    }
  }
  label.card-label-wrapper {
    margin: 0px;
    color: #000;
    position: relative;
    top: -4px;
    left: 0px;
    display: flex;
    align-items: center;
    color: var(--Label, #8e97a4);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    span {
      color: var(--Label, #8e97a4);
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      display: flex;
    }
  }
  .form-wrapper {
    display: flex;
    flex-direction: column;
    grid-row-gap: 16px;
  }
  .input-with-icon.input-icon-left{
    width:50%;
    height:54px;
  }
  .card-input div {
    border-bottom: 0px !important;
}
.input-with-icon.input-icon-left.cvc-card-ui div:nth-child(1) {
    border-left: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 4px;
}
.input-with-icon.input-icon-left div {
    border-bottom-left-radius: 4px !important;
}
  .strip-st-ui-wrapper {
    display: flex;
    width: 100%;
    border-radius: 4px;
    /* height: 32px; */
    /* border: 1px solid var(--Placeholder-Border, #dbdfe1); */
    background: var(--White, #fff);
    flex-wrap: wrap;
    align-items: center;
    .card-input {
      border: none !important;
      div {
    border: 1px solid #c4c4c4;
    border-top-left-radius: 4px !important;
    border-top-right-radius: 4px !important;
}
}
    div {
      border:none;
      &.card-number-stripe{
        width:100%;

      }
      &:nth-child(1) {
        width:100%;
    border: 1px solid #c4c4c4;
      }
      &.StripeElement {
        /* border: 1px solid #CFD8DC !important; */
        padding: 8px;
        border-radius: 0px;
      &.input-with-icon.input-icon-left {
        position: relative;
        width: 50% !important;
        border-top: 0px !important;
        height: 54px;
        span {
        color: red;
        position: absolute;
        left: 16px;
        white-space: nowrap;
      }
      }
    }
  }
}
  .item-overlay-wrapper {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    width: 100%;
    grid-gap: 16px;
  }
  .form-wrapper {
    .ant-select.ant-select-single.ant-select-show-arrow {
      width: 100% !important;
      display: flex;
      width: 182px;
      height: var(--Main-System-16px, 16px);
      flex-direction: column;
      justify-content: center;
      background-color: transparent !important;
    }
    .item-wrapper {
      width: 100%;
      label {
        color: var(--Label, #8e97a4);
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        padding-bottom: 4px;
      }
      p {
        margin: 0px;
        margin-top: 8px;
        color: var(--Label, #8e97a4);
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        padding-bottom: 4px;
      }
    }
  }
  .loader-wrapper {
    width: 100%;
    right: 0;
    height: calc(100vh - 62px);
    background: #ffffffd9;
    top: 0;
    z-index:2;
}
`;
const ModalStyleWrapper = styled.div`
  width: 100%;
  .box-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h6 {
      color: var(--Secondary, #151a26);
      text-align: center;
      font-family: Lato;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
      margin: 0;
      margin-top: 24px;
      margin-bottom: 12px;
    }
    p {
      color: var(--text-field-body-color, #5e718d);
      text-align: center;
      font-family: Lato;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      margin: 0px;
      margin-bottom: 24px;
    }
  }
  button {
    width: 100%;
  }
`;
const ModalPaymentLaterStyleWrapper = styled.div`
  width: 100%;
  .box-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h6 {
      color: var(--Secondary, #151a26);
      text-align: center;
      font-family: Lato;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
      margin: 0;
      margin-top: 16px;
      margin-bottom: 12px;
      width: 100%;
      max-width: 316px;
    }
    p {
      color: var(--text-field-body-color, #5e718d);
      text-align: center;
      font-family: Lato;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      margin: 0px;
      margin-bottom: 24px;
      width: 100%;
      max-width: 336px;
    }
  }
  button {
    width: 100%;
  }
  .button-footer-ui {
    display: flex;
    align-items: center;
    grid-gap: 16px;
  }
`;
const StepFiveWrapper = styled.div`
  margin-top: 16px;
  width: 100%;
  height: 445px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--White, #fff);
  border: 1px solid var(--Border-Variant, #dadfe3);
  .container-finish-wrapper {
    max-width: 401px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h6 {
      color: var(--Secondary, #151a26);
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
      margin-bottom: 12px;
      margin-top: 24px;
    }
    p {
      color: var(--text-field-body-color, #5e718d);
      text-align: center;
      margin: 0;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      margin-bottom: 24px;
    }
  }
  .buttons-wrapper {
    display: flex;
    align-items: center;
    grid-gap: 16px;
  }
`;
export {
  TitleStyleWrapper,
  StepThreeWrapper,
  ManegePermissionsWrapper,
  StepFourWrapper,
  ModalStyleWrapper,
  ModalPaymentLaterStyleWrapper,
  StepFiveWrapper,
};
