import Styled from "styled-components";

const ModalWrapper = Styled.div`
 .confirm-btn{
  background:${({theme}) => `linear-gradient(180deg, ${theme['primary-variant']} 0%, ${theme['primary']} 49.67%, ${theme['primary-btn-gradient-3']} 98.63%);`};
  color: #fff;
  font-weight: 700;
  font-size: 14px;
  min-width: 88px;
  height: 32px;
  border:none ;
  border-radius: 10px;
 }
`;

export { ModalWrapper };
