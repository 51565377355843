import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from 'react-router';
import { Row, Col, Form } from "react-bootstrap";
import {
  AiFillEyeInvisible,
  AiFillEye
} from "react-icons/ai";
import { Formik, getIn } from "formik";
import * as yup from "yup";
import { isValidPhoneNumber } from "react-phone-number-input";
import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

import Button from "../../components/Button";
import LabelInput from "../../components/Inputs/label-input";
import PhoneNumberInput from "../../components/Inputs/phoneNumberInput/index";
import Notification from '../../components/notifications/notification'

import { SignUp, SetAuthState } from "../../redux/slices/auth-slice";

import { APP_LOGOS, APP_TITLES, RECAPTCHA_SITE_KEYS } from "../../constants";

import { AuthWrapper } from "./style";

const hostName = window.location.hostname;
const recaptchaKey = RECAPTCHA_SITE_KEYS[hostName];

const schema = yup.object().shape({
  firstName: yup.string().required("First Name is required"),
  lastName: yup.string().required("Last Name is required"),
  company: yup
    .string()
    .max(30)
    .required("Company Name is required"),
  promoCode: yup.string(),
  email: yup
    .string()
    .email("Field should contain a valid e-mail")
    .max(255)
    .required("Email is required"),
  password: yup
    .string()
    .required("Password is required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
  confirmPassword: yup
    .string()
    .required("Password must match")
    .oneOf([yup.ref("password"), null], "Password must match")
});

const Index = () => {
  const queryParams = new URLSearchParams(useLocation().search);
  const affiliateId = queryParams.get('affiliateId');

  const history = useHistory();
  const dispatch = useDispatch();

  const [passwordShow, SetPasswordShow] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState();
  const [phoneNumberError, setPhoneNumberError] = useState('');
  const [isCaptchaClick, setIsCaptchaClick] = useState(true);
  const [captchaValue, setCaptchValue] = useState('');

  const {
    error,
    loading,
    message,
    success
  } = useSelector((state) => state.auth);

  useEffect(() => {
    if (error) {
      Notification({
        type: "error",
        title: "Registration Error",
        description: error
      });
      dispatch(SetAuthState({ field: "error", value: "" }));
      if (!isCaptchaClick) {
        resetCaptcha();
      }

      return;
    }
    if (success) {
      dispatch(SetAuthState({ field: "success", value: false }));
      dispatch(SetAuthState({ field: "message", value: "" }));
      history.push("/auth/email-verification");
    }
    if (message) {
      Notification({
        type: "info",
        title: "Promo Code",
        description: message
      });
      dispatch(SetAuthState({ field: "message", value: "" }));
    }
  }, [error, message, success]);

  const handleSetPassword = () => {
    SetPasswordShow(!passwordShow);
  };

  const getStyles = (touched, errors, fieldName) => {
    if (touched[fieldName]) {
      if (getIn(errors, fieldName)) {
        if (!isCaptchaClick) {
          resetCaptcha();
        }

        return {
          border: "1px solid red",
        };
      }
    }
  };

  const handelValidate = (e) => {
    if (e) {
      const isValid = isValidPhoneNumber(e);
      setPhoneNumber(e);
      if (!isValid) setPhoneNumberError('Invalid phone number');
      else setPhoneNumberError('');
      return isValid;
    }
  }

  const onChange = () => {
    setIsCaptchaClick(false);
  };

  const setCaptchaRef = (ref) => {
    if (ref) {
      setCaptchValue(ref);
    }
  };

  const resetCaptcha = () => {
    if (captchaValue) {
      captchaValue.reset();
    }
    setIsCaptchaClick(true);
  };

  const handlePromoCodeChange = (value) => {
    setPromoCode(value);
  };

  return (
    <AuthWrapper>
      <div
        className="content-holder signup-background"
        style={loading ? { opacity: 0.5 } : null}
      >
        <Row className="m-0 header-space">
          <Col md={5} sm={12}>
            <div className="left-section">
              {APP_LOGOS[hostName] ?
                <img src={APP_LOGOS[hostName]} alt="no-logo" />
                :
                <h2>{APP_TITLES[hostName]}</h2>
              }
              <p className="logo-description mb-0"> Don’t have an account?
              Create one. It takes less than a minuite!
              </p>
            </div>
          </Col>
          <Col md={7} sm={12}>
            <div className="right-content">
              <div className="user-form">
                <h2 className="heading heading-secondary">Register a new account</h2>
                <Formik
                  validationSchema={schema}
                  onSubmit={(values) => {
                    if (phoneNumberError === '') {
                      dispatch(SignUp({
                        values,
                        phoneNumber,
                        promoCode: values.promoCode ? values.promoCode: undefined,
                        affiliateId: affiliateId ? affiliateId : undefined
                      }));
                    }
                  }}
                  initialValues={{
                    firstName: "",
                    lastName: "",
                    email: "",
                    password: "",
                    company: "",
                    promoCode: "",
                    confirmPassword: "",
                    terms: false,
                  }}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    touched,
                    errors,
                  }) => (
                    <Form
                      noValidate
                      onSubmit={handleSubmit}
                      className="signup-form"
                    >
                      <Row className="customized-row">
                        <Col sm={6}>
                          <LabelInput
                            name="firstName"
                            label="First Name*"
                            value={values.firstName}
                            placeholder="First Name"
                            onChange={handleChange}
                            onBlur={handleBlur("firstName")}
                            style={getStyles(touched, errors, "firstName")}
                          />
                        </Col>
                        <Col sm={6}>
                          <LabelInput
                            name="lastName"
                            label="Last Name*"
                            value={values.lastName}
                            placeholder="Last Name"
                            onChange={handleChange}
                            onBlur={handleBlur("lastName")}
                            style={getStyles(touched, errors, "lastName")}
                          />
                        </Col>
                      </Row>
                      <Row className="customized-row">
                        <Col sm={12}>
                          <LabelInput
                            name="company"
                            label="Company*"
                            value={values.company}
                            placeholder="Company"
                            onChange={handleChange}
                            onBlur={handleBlur("company")}
                            style={getStyles(touched, errors, "company")}
                          />
                        </Col>
                      </Row>
                      <Row className="customized-row">
                        <Col sm={12}>
                          <LabelInput
                            name="email"
                            label="Email*"
                            value={values.email}
                            placeholder="Email"
                            onChange={handleChange}
                            onBlur={handleBlur("email")}
                            className="email-field"
                            style={getStyles(touched, errors, "email")}
                          />
                        </Col>
                      </Row>

                      <Row className="customized-row">
                        <Col sm={12}>
                          <PhoneNumberInput
                            value={phoneNumber}
                            label="Mobile"
                            defaultCountry="US"
                            placeholder="Enter phone number"
                            onChange={(e) => handelValidate(e)}
                          />
                          {phoneNumberError !== '' ? <span style={{ color: 'red', fontSize: '12px' }}> {phoneNumberError} </span> : null}
                        </Col>
                      </Row>

                      <Row className="customized-row">
                        <Col sm={6}>
                          <div className="password-input signup-password pl-0">
                            <LabelInput
                              name="password"
                              strength="true"
                              label="Password"
                              value={values.password}
                              type={passwordShow ? "password" : "text"}
                              placeholder="Your Password Here"
                              onChange={handleChange}
                              onBlur={handleBlur("password")}
                              className={(touched.password && errors.password) ? "password-field-2" : "password-field"}
                              style={getStyles(touched, errors, "password")}
                            />
                            {touched.password && errors.password && (
                              <div style={{ marginBottom: '4px' }}><span style={{ color: 'red', fontSize: '8px' }}>{errors.password}</span></div>
                            )}
                            {passwordShow ? (
                              <AiFillEyeInvisible onClick={handleSetPassword} />
                            ) : (
                              <AiFillEye onClick={handleSetPassword} />
                            )}
                          </div>
                        </Col>
                        <Col sm={6}>
                          <div className="password-input signup-password pl-0">
                            <LabelInput
                              name="confirmPassword"
                              label="Confirm Password*"
                              value={values.confirmPassword}
                              type={passwordShow ? "password" : "text"}
                              placeholder="Confirm Password"
                              onChange={handleChange}
                              onBlur={handleBlur("confirmPassword")}
                              style={getStyles(touched, errors, "confirmPassword")}
                              className={(touched.confirmPassword && errors.confirmPassword) ? "password-field-2" : "password-field"}
                            />
                            {touched.confirmPassword && errors.confirmPassword && (
                              <div style={{ marginBottom: '' }}><span style={{ color: 'red', fontSize: '8px' }}>{errors.confirmPassword}</span></div>
                            )}
                            {passwordShow ? (
                              <AiFillEyeInvisible onClick={handleSetPassword} />
                            ) : (
                              <AiFillEye onClick={handleSetPassword} />
                            )}
                          </div>
                        </Col>
                        <Col sm={12}>
                          <div className="password-input signup-password pl-0">
                            <LabelInput
                              name="promoCode"
                              label="Promo Code"
                              value={values.promoCode}
                              placeholder="Promo Code"
                              onChange={handleChange}
                            />
                          </div>
                        </Col>
                      </Row>
                      <div className="white-box captcha-box">
                        <ReCAPTCHA
                          sitekey={recaptchaKey}
                          onChange={onChange}
                          ref={(r) => setCaptchaRef(r)}
                        />
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <Button
                          text={loading ? "Loading...." : "Register"}
                          className="primary mb-0"
                          type="submit"
                          width=""
                          disabled={isCaptchaClick}
                        />
                        <div className="d-flex justify-content-center have-account">
                          <p>
                            Already have an account?
                            <Link
                              to="/auth/sign-in"
                            >
                              Login
                            </Link>
                          </p>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </AuthWrapper>
  );
};

export default Index;
