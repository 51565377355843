import React from "react";
import { FiArrowRightCircle } from "react-icons/fi";
import { FiArrowLeftCircle } from "react-icons/fi";

import DollarIcon from "../../assets/images/dollar_icon_st.svg";

import { PotentialAmountWrapper } from "./style";

const PotentialAmount = ({
  amountText,
  amountName,
  currency = '$',
  next,
  prev
}) => {
  return (
    <PotentialAmountWrapper>
      <div className="potential_overlay_wrapper">
        <img src={DollarIcon} />
        <p>{amountName}</p>
        <div className="potential_overlay_bottom">
          <FiArrowLeftCircle onClick={prev} />
          <p>{currency} {amountText}</p>
          <FiArrowRightCircle onClick={next} />
        </div>
      </div>
    </PotentialAmountWrapper>
  );
};

export default PotentialAmount;
