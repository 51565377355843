import React, { useEffect } from "react";
import { isEmpty } from 'lodash';
import { Row, Col } from "react-bootstrap";
import { useLocation, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';

import Header from '../Header/header';

import CricleImage from "../../assets/images/Circles.svg";
import WaitIcon from "../../assets/images/waiticon.svg";
import Button from "../../components/Button/index";
import { StoreWrapper } from "./style";

import { SetAuthState } from '../../redux/slices/auth-slice';
import { GetStore } from '../../redux/slices/store-slice';

import { AGENCIES_EMAIL } from '../../constants';

const WaitingScreen = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const { storeId, store, success } = useSelector((state) => state.store);
  const { user, storeStatus, showSidebar } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(GetStore({ storeId }));
    const intervalId = setInterval(async () => {
      await dispatch(GetStore({ storeId }));
    }, 30000);
    console.log('intervalId', intervalId);

    return () => {
      console.log('Leaving the page');
      console.log('intervalId', intervalId);
      clearInterval(intervalId);
    }
  }, [])

  useEffect(() => {
    if (!isEmpty(store)) {
      const { status } = store;
      console.log({ store });
      dispatch(SetAuthState({ field: "storeStatus", value: status }));
    }
  }, [store, success]);

  useEffect(() => {
    if (storeStatus === 'Pending' && location?.state?.step === 'Step 2') {
      history.push("/invite-user");
    } else if (storeStatus === "Invite_Accepted" && location?.state?.step !== 'Step 2') {
      history.push("/permissions");
    } else if (storeStatus === 'Connected') {
      history.push("/store-success");
    }
  }, [storeStatus]);

  return (
    <>
      {showSidebar ? null : <Header />}
      <StoreWrapper>
        <Row>
          <Col lg={{span: showSidebar ? 5 : 6 , offset: showSidebar ? 0 : 1 }} sm={7} className="">
            <div className="justify-content-end section-heading thankyou-heading">
              <h2>
                Thank you for completing <span>{location?.state?.step}</span>
              </h2>
            </div>
            <div className= { !showSidebar ? "thankyou-content": 'thankyou-content-with-layout'}>
              <div className="thankyou-step d-flex justify-content-between align-items-center">
                <div>
                  <h3>
                    Please wait while we&nbsp;
                    {location?.state?.content}
                  </h3>
                  <p>
                    This page will refresh shortly
                  </p>
                </div>
                <div className="thankyou-image">
                  <img src={WaitIcon} alt="icon" />
                </div>
              </div>
            </div>
          </Col>
          <Col sm={showSidebar ? 8 : 4}>
            <div className={!showSidebar ? "onboarding-space-right": null}>
              <div className={showSidebar ? 'onboarding-right-section' : 'sidebar-wrapper-new'}>
                <div className="info-box-main">
                  <div className="circle-section">
                    <img src={CricleImage} alt="circles" />
                  </div>
                  <div className="info-block text-center">
                    <h3>Have a Question?</h3>
                    <p>Contact your case manager:</p>
                    <a href={`mailto:${AGENCIES_EMAIL[user.agencyId]}`}>
                      <Button text="Contact" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </StoreWrapper>
    </>
  );
};

export default WaitingScreen;
