import moment from 'moment';
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';

import Notification from '../../components/notifications/notification';
import Pagination from "../../components/Pagination/pagination";
import Spin from "../../components/Spin/Index";
import Table from "../../components/Table/table";

import {
  GetAgencyReceipts,
  SetAgencyState
} from '../../redux/slices/agency-slice';

import {
  AffiliateWrapper,
} from "./style";

import {
  CURRENCY_SYMBOLS
} from '../../constants';

const Index = () => {
  const dispatch = useDispatch();

  const {
    agencyReceipts,
    totalAgencyReceipts,
    message,
    loading,
    error,
  } = useSelector(state => state.agency);

  const [pageNumber, setPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState(25);

  const handlePageChangeLeft = () => {
    if (pageNumber - 1 > 0) setPageNumber(pageNumber - 1);
    else setPageNumber(1);
  };

  const handlePageChangeRight = () => {
    const totalPages = Math.ceil(totalAgencyReceipts / pageLimit)
    if (Number(pageNumber) < totalPages) setPageNumber(Number(pageNumber) + 1)
    else setPageNumber(totalPages > 0 ? totalPages : 1);
  };

  const handleLimitChange = (e) => {
    setPageNumber(1);
    setPageLimit(e.value);
  };

  const handlePageNumberChange = (e) => {
    const totalPages = Math.ceil(totalAgencyReceipts / pageLimit)
    if (e.key == "Enter") {
      if (e.target.value < 1 || !e.target.value) {
        setPageNumber(1);
      } else if (e.target.value > totalPages) {
        setPageNumber(totalPages)
      } else setPageNumber(e.target.value)
    }
  };

  useEffect(() => {
    dispatch(GetAgencyReceipts({
      limit: pageLimit + (pageNumber - 1) * pageLimit,
      skip: (pageNumber - 1) * pageLimit,
    }));
  }, [pageLimit, pageNumber]);

  useEffect(() => {
    if (error) {
      Notification({
        type: "error",
        title: "Error",
        description: error
      });
      dispatch(SetAgencyState({ field: 'error', value: '' }));
      return;
    }

    if (message) {
      Notification({
        type: "success",
        title: "Success",
        description: message
      });

      dispatch(GetAgencyReceipts({
        limit: pageLimit,
        skip: (pageNumber - 1) * pageLimit,
      }));

      dispatch(SetAgencyState({ field: 'message', value: '' }));
      dispatch(SetAgencyState({ field: 'success', value: false }));
      return;
    }
  }, [error, message]);

  return (
    <>
      <AffiliateWrapper>
        <div className="d-flex justify-content-between align-items-center flex-sm-column flex-md-row">
          <h3 className="mb-0">Agency Monthly Commission</h3>
        </div>
        <div className="pt-4">
          <Table
            viewHeight="invoices"
            tableHeader={
              <tr>
                <th>Month</th>
                <th>Year</th>
                <th>Agency Name</th>
                <th>Commission Earned</th>
                <th>Total Commission</th>
                <th>Status</th>
              </tr>
            }
            tableBody={
              <>
                {!loading && !agencyReceipts?.length ? (
                  <tr><td>
                    <div className='error'>
                      <h1>No Data Available</h1>
                    </div>
                  </td></tr>
                ) : null}
                {loading ? <tr><td><Spin /></td></tr> : agencyReceipts?.map((item, ind) => (
                  <tr key={ind}>
                    <td>
                      {moment(item.month).format('MMMM')}
                    </td>
                    <td>{moment(item.month, 'Y').format('YYYY')}</td>
                    <td>{item.agencyName}</td>
                    <td>{CURRENCY_SYMBOLS[item.currency] + ' ' + Number(item.monthlyCommissionEarned).toFixed(2)}</td>
                    <td>{CURRENCY_SYMBOLS[item.currency] + ' ' + Number(item.totalCommission).toFixed(2)}</td>
                    <td>{item.status}</td>

                  </tr>
                ))}
              </>
            }
          />
        </div>
        <Pagination
          className="affiliate-pagination-wrapper"
          pageLimit={pageLimit}
          total={totalAgencyReceipts}
          pageNumber={pageNumber}
          totalPages={Math.ceil(totalAgencyReceipts / pageLimit)}
          handlePageChangeLeft={handlePageChangeLeft}
          handlePageChangeRight={handlePageChangeRight}
          handleLimitChange={handleLimitChange}
          handlePageNumberChange={handlePageNumberChange}
        />
      </AffiliateWrapper>
      {loading && <Spin />}
    </>
  );
};

export default Index;
