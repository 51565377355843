import React, { useState, useEffect } from "react";
import * as Sentry from '@sentry/browser';
import { Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { BsExclamationCircleFill } from "react-icons/bs";

import CheckIcon from "../../assets/icons/check-icon.svg";

import Notification from '../../components/notifications/notification';
import StripeCard from '../../components/PaymentCard'
import Button from "../../components/Button/index";
import Spin from "../../components/Spin/Index";

import SaveSentryLog from '../../helpers/sentry-log';

import {
  BillingPaymentWrapper,
  PaymentCardWrapper,
  PaymentForm,
  InfoBlock,
  InfoBlockForAffiliate
} from "./style";

import { SetAuthState } from '../../redux/slices/auth-slice';
import { GetUser } from '../../redux/slices/user-slice';
import { SetStripeState } from '../../redux/slices/stripe-slice';

import { AGENCIES_EMAIL } from '../../constants';

const Index = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [isCardActive, setCardActive] = useState(false);

  const { user: userData } = useSelector(state => (state.auth));
  const {
    success,
    customerCreated,
    customerUpdated,
    error,
    message,
    loading
  } = useSelector(state => (state.stripe));
  const { user } = useSelector(state => (state.user));

  useEffect(() => {
    dispatch(GetUser({ userId: userData._id }));
  }, []);

  useEffect(() => {
    if (user.payment) {
      dispatch(SetAuthState({ field: 'user', value: { ...userData, payment: user.payment } }));
      setCardActive(true);
    }
  }, [user]);

  useEffect(() => {
    if (customerCreated) {
      Notification({
        type: "success",
        title: "Success",
        description: 'Card Added Successfully'
      });
    } else if (customerUpdated) {
      Notification({
        type: "success",
        title: "Success",
        description: 'Card Info Updated Successfully'
      });
    }

    if (customerCreated || customerUpdated) {
      dispatch(GetUser({ userId: userData._id }));
      dispatch(SetStripeState({ field: "customerCreated", value: false }));
      dispatch(SetStripeState({ field: "customerUpdated", value: false }));
      if (userData?.role !== 'affiliate-manager') {
        history.push('/dashboard');
      } else {
        history.push('/affiliate-users');
      }
    }
  }, [customerCreated, customerUpdated]);

  // useEffect(() => {
  //   if (error) {
  //     Notification({
  //       type: "error",
  //       title: "Error",
  //       description: error
  //     });

  //     // SaveSentryLog(user._id, error);
  //     dispatch(SetStripeState({ field: 'error', value: '' }));
  //     return;
  //   }
  // }, [error]);

  return (
    <BillingPaymentWrapper>
      <div className="">
        <div className="page-header">
          <h3>Billing & Payment</h3>
          <p className="highlighted-text">
            {userData?.role === 'affiliate-manager'
              ? 'Affiliate commissions earned will be paid into this account on the 01st of each month.'
            : 'You will be charged a ' + (user.commissionDiscount || 0) + '% comission for successful reimbursments credited to your account, billed weekly'
          }
          <br/>
            {userData?.role === 'affiliate-manager' && 'You will be charged a ' + (user.commissionDiscount || 0) + '% comission for successful reimbursments credited to your account, billed weekly.'}
          </p>
        </div>
        <Row>
          <Col md={3}>
            <PaymentCardWrapper>
              <div className="payment-card-header">
                {isCardActive ?
                  <p>
                    <img src={CheckIcon} alt="check icon" />  Active
                  </p>
                :
                  <p>
                    <BsExclamationCircleFill /> Inactive
                  </p>
                }
              </div>
              <div className="payment-card-content">
                <p>{`XXXX-XXXX-XXXX-${userData?.payment?.cardLast4Digits}`}</p>
              </div>
            </PaymentCardWrapper>
          </Col>
          <Col md={1}>
            <div className="section-divider"></div>
          </Col>
          <Col md={3}>
            <PaymentForm>
              {user?.payment ? <h3>Update Card Information</h3> : <h3>Add Card Information</h3>}
              <StripeCard />
            </PaymentForm>
          </Col>
          <Col md={5}>
            {userData?.role === 'affiliate-manager' ?
            <InfoBlockForAffiliate>
            <div className="info-box-main">
              <div className="info-block text-center">
                <h3>Have a Question?</h3>
                <p>Contact your case manager:</p>
                <a href={`mailto:${AGENCIES_EMAIL[userData.agencyId]}`}>
                  <Button text="Contact" />
                </a>
              </div>
            </div>
          </InfoBlockForAffiliate>
            : <InfoBlock>
              <div className="info-box-main">
                <div className="info-block text-center">
                  <h3>Have a Question?</h3>
                  <p>Contact your case manager:</p>
                  <a href={`mailto:${AGENCIES_EMAIL[userData.agencyId]}`}>
                    <Button text="Contact" />
                  </a>
                </div>
              </div>
            </InfoBlock>
            }
          </Col>
        </Row>
      </div>
      {
        loading ? <Spin /> : null
      }
    </BillingPaymentWrapper>
  );
};

export default Index;
