import axios from 'axios';

const service = axios.create({
  baseURL: process.env.API_URL
});

service.interceptors.response.use(
  response => response,
  (error) => {
    const status = error.response ? error.response.status : null;
    if (status === 401) {
      if (window.location.pathname !== '/auth/sign-in' && window.location.pathname !== '/logout') {
        window.location.href = '/logout';
      }
    }

    return Promise.reject(error);
  }
)

const setAuthToken = (token) => {
  if (token) {
    service.defaults.headers.common.Authorization = `Bearer ${token}`;
  }
};

const axiosBaseUrl = () =>  service;

export {
  setAuthToken,
  axiosBaseUrl
};
