import styled from "styled-components";
import CricleImage from "../../assets/images/Circles.svg";

const DashbaordWrapper = styled.div`
  .welcome-text {
    font-weight: 400;
    font-size: ${({ theme }) => theme["base-font-size"]};
    line-height: 19px;
    margin-bottom: 40px;
    span {
      color: ${({theme})=> theme['primary']};
      font-weight:500;
      font-size:16px;
      line-height:19.36px;
    }
  }
  p.card-wrapper-arrow-issues{
    min-width: 90px !important;
    
  }
  .country-item {
    list-style: none;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0px;
    text-align: left;
    color: #4B4F51;
    display: flex;
    align-items: center;
    img{
      margin-right:6px ;
      width:16px !important;
      height:16px !important;
    }
}
.add-payment-overlay-wrapper{
  padding: 0 !important;
  min-width: 124px;
}
.add-payment-method-wrapper{
  background:${({theme})=> `linear-gradient(91.7deg, ${theme['primary-btn-gradient-1']} 4.69%, ${theme['primary-btn-gradient-2']} 79%)`};
  color:#fff !important;
  border: none;
  border-radius:30px ;
}
.country-card-btn-wrapper{
  background:${({theme})=> `linear-gradient(91.7deg, ${theme['primary-btn-gradient-1']} 4.69%, ${theme['primary-btn-gradient-2']} 79%)`};
  color: #fff !important;
  font-weight: 700;
  font-size: 10px;
  min-width: 121px;
  border:none ;
  border-radius:30px ;
}
  span.agency-account-manager {
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0px;
    text-align: left;
    color: #000;
    margin-left: 8px;
}
.dashboard-contact-btn-wrapper{
background:${({theme})=> `linear-gradient(91.7deg, ${theme['primary-btn-gradient-1']} 4.69%, ${theme['primary-btn-gradient-2']} 79%)`};
border-radius: 50px;
border-radius:40px;
color:#fff ;
font-weight:700;
font-size:10px;
border: none;
}

  .account-details-box {
    padding-bottom: 16px;
    .account-details-content {
      border-top: 1px solid ${({theme})=> theme['secondary-variant']};;
      padding-top: 16px;
      padding-bottom: 15px;
      p {
        font-weight: 600;
        font-size: ${({ theme }) => theme["base-font-size-small"]};
        line-height: 15px;
        margin-bottom: 16px;
        color: ${({theme})=> theme['body-text']};
      }
      h6 {
        font-weight: 700;
        font-size: 17px;
        line-height: 21px;
        color: ${({theme})=> theme['primary']};
        margin-bottom: 0;
      }
      h3 {
        font-weight: 700;
        font-size: 16px;
        line-height: 19.36px;
        color: ${({theme})=> theme['primary']};
        margin-bottom: 12px;
      }
      .account-progress-status {
        padding-top: 0px;
        padding-bottom: 0x;
        p {
          margin-bottom: 8px;
        }
      }
      .account-manager-info {
        font-weight: 400;
        font-size: ${({ theme }) => theme["base-font-size-small"]};
        line-height: 15px;
        color: ${({theme})=> theme['primary']};
        margin-bottom: 0;
        svg {
          margin-right: 8px;
          font-size: 26px;
          color: ${({theme})=> theme['primary']};
        }
        p {
          font-weight: 400;
          font-size: 12px;
          line-height: 14.52px;
          color: ${({theme})=> theme['body-text-variant']};
          margin-top: 13px;
          margin-bottom: 16px;
        }
        button {
          margin-bottom: 0;
        }
      }
      .check-info {
        margin-bottom: 8px;
      }
    }
  }
  h3 {
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    color: ${({theme})=> theme['primary']};
    margin-bottom: 12px;
    color: ${({theme})=> theme['primary']};
  }
  .check-info {
    font-weight: 400;
    font-size: ${({ theme }) => theme["base-font-size-small"]};
    line-height: 15px;
    margin-bottom: 16px;
    color: ${({theme})=> theme['body-text']};
    img {
      width: 16px;
      height: 16px;
      margin-right: 7px;
    }
  }
  .region-details {
    ul {
      padding-left: 0;
      display: flex;
      gap: 8px;
      list-style: none;
      margin-bottom: 22px;
      flex-wrap: wrap;
      li {
        img {
          width: 16px;
          height: 16px;
          border-radius: 55%;
        }
      }
    }
    .check-info {
      margin-bottom: 12px;
    }
    .currency-info {
      p {
        font-weight: 700;
        font-size: 20px;
        line-height: 24.2px;
        color: ${({theme})=> theme['accent-success']};
        margin-bottom: 0;
        min-width: 143px;
        text-align: center;
      }
      svg {
        color: ${({theme})=> theme['primary']};
        height: 16px;
        font-size: ${({ theme }) => theme["base-font-size"]};
        position: relative;
        top: 4px;
        fill: currentColor;
        cursor: pointer;
        &:first-child{
          position: relative;
        }
        &:last-child{
          position: relative;
        }
      }
    }
  }
  .data-collapse-top {
        padding: 12px 22px 12px;
        background-color: ${({ theme }) => theme["primary"]};
        text-align: center;
        border-radius: 14px;
        position: relative;
        ${'' /* z-index: 1; */}
        &.primary-variant {
          background-color: ${({ theme }) => theme["primary-variant"]};
          padding-top: 25px;
          & + .data-collapse-bottom {
            padding-top: 14px;
          }
          .data-collapse-btn {
            border-color: ${({ theme }) => theme["primary-variant"]};
          }
        }
        .CircularProgressbar {
          display: inline-block;
          width: 108px;
          height: 108px;
          margin: 0 auto;
          margin-bottom: 16px;
        }
        h3 {
          
        color: #ffffff;
        margin-bottom: 12px;
        font-family: Inter;
        font-size: 12px;
        font-weight: 700;
        line-height: 15px;
        letter-spacing: 0px;
        text-align: center;

        }
        h2 {
          font-weight: 700;
          font-size: 20px;
          line-height: 24px;
          color: ${({ theme }) => theme["on-primary"]};
          padding-top: 7px;
          margin-bottom: 16px;
        }
        p {
          font-weight: 400;
          font-size: 8px;
          line-height: 10px;
          color: ${({ theme }) => theme["on-primary"]};
        }
        button {
          &.collpase-btn {
            background-color: ${({ theme }) => theme["on-primary"]};
            padding: 10px 10px;
            font-size: ${({ theme }) => theme["xs-small-size"]};
            line-height: 12px;
            height: auto;
            width: 100%;
            border-radius: 10px;
            &:focus {
              background-color: ${({ theme }) => theme["on-primary"]};
            }
          }
          margin-bottom: 0;
        }
      }
  .amount-details {
    .amount-recovery {
      background: ${({theme})=> theme['primary-variant']};
      border-radius: 16px;
      text-align: center;
      padding: 16px 16px;
      margin-bottom: 16px !important;
      position: relative;
      margin:auto;
      box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.14);;
      &:after {
        content: "";
        background-color: ${({theme})=> theme['secondary-variant']};;
        height: 16px;
        position: absolute;
        bottom: -16px;
        width: 1px;
      }
      h2 {
        font-weight: 700;
        font-size: ${({ theme }) => theme["medium-font-size"]};
        line-height: 17px;
        color: ${({theme})=> theme['on-primary']};
        max-width: 125px;
        text-align: center;
        margin-bottom: 12px !important;
        margin:auto;
}
      }
      .region-details {
        ul {
          justify-content: center;
          margin-bottom: 10px;
        }
        .check-info {
          color: ${({theme})=> theme['on-primary']};
          margin-bottom: 7px;
        }
        .currency-info {
          justify-content: center;
          p {
            font-size: 20px;
            line-height: 24px;
            color: ${({theme})=> theme['on-primary']};
            margin-left: 12px;
            margin-right: 12px;
          }
          svg {
            color: ${({theme})=> theme['on-primary']};
            font-size: 20px;
            top: 3px;
          }
        }
      }
    }
    .amount-description {
      background:${({theme})=> theme['background']};
      border-radius: 16px;
      padding: 12px 12px;
      text-align: center;
      margin-bottom: 16px;
      position: relative;
      box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.14);
      p {
        font-weight: 400;
        font-size: ${({ theme }) => theme["medium-font-size"]};
        line-height: 17px;
        color: ${({theme})=> theme['on-surface']};
        margin-bottom: 8px;
      }
      h4 {
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        color: ${({theme})=> theme['primary-variant']};
        margin-bottom: 0;
        &.color-primary2 {
          color: #414bc6;
        }
        &.color-primary3 {
          color: #726af5;
        }
        &.color-primary4 {
          color: #0071b9;
        }
      }
      &:after {
        content: "";
        background-color: ${({theme})=> theme['secondary-variant']};;
        height: 16px;
        position: absolute;
        bottom: -16px;
        width: 1px;
      }
      &:last-of-type {
        &:after {
          display: none;
        }
      }
    }
  }
  .active-marketplace {
    background-color: ${({theme})=> theme['background']};
    border-radius: 16px;
    padding: 15px 15px;
    text-align: center;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.14);
    h3 {
      margin-bottom: 12px !important;
    }
    .region-details {
      .check-info {
        margin-bottom: 15px;
      }
      ul {
        gap: 10px 16px;
        padding-left: 12px;
        padding-right: 12px;
        li {
          img {
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }
  .action-required {
    border: 1px solid ${({theme})=> theme['accent-info']};
    padding: 9px;
    border-radius: 16px;
    text-align: center;
    h4 {
      font-weight: 600;
      font-size: ${({ theme }) => theme["base-font-size-small"]};
      line-height: 14.52px;
      color: ${({theme})=> theme['body-text']};
      margin-bottom: 16px;
      svg {
        color: ${({theme})=> theme['accent-info']};
        position: relative;
        top: -1px;
        font-size: ${({ theme }) => theme["base-font-size"]};
        margin-right: 6px;
      }
    }
    h3 {
      font-weight: 700;
      font-size: ${({ theme }) => theme["base-font-size-small"]};
      line-height: 15px;
      color: ${({theme})=> theme['accent-info']};
      margin-bottom: 16px;
    }
    p {
      font-weight: 400;
      font-size: 11px;
      line-height: 13px;
      color: ${({theme})=> theme['on-surface']};
      margin-bottom: 12px;
      &:last-of-type {
        margin-bottom: 20px;
      }
    }
  }
  button {
    font-size: ${({ theme }) => theme["xs-small-size"]};
    line-height: 12px;
    padding: 10px 12px;
    margin-bottom: 0;
  }

  .bubbles-section {
    height: calc(16vh - -73px);
    display: flex;
    align-items: end;
    text-align: right;
    .info-box-main {
      position: relative;
      width: 100%;
      padding-bottom: 36px;
      &:after {
        content: url(${CricleImage});
        position: static;
        z-index: -1;
        position: relative;
        left: -37px;
        top: 6px;
      }
    }
  }
`;
const BillingPaymentWrapper = styled.div`
  .page-header {
    padding-bottom: 40px;
    h3 {
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      color: ${({theme})=> theme['on-surface']};
      margin-bottom: 12px;
    }
    p {
      font-weight: 400;
      font-size: ${({ theme }) => theme["base-font-size-small"]};
      line-height: 15px;
      color: ${({theme})=> theme['body-text-variant']};
      margin-bottom: 0;
      &.color-primary {
        color: ${({theme})=> theme['primary']};
      }
    }
  }
  .section-divider {
    height: 100%;
    max-height: 394px;
    text-align: center;
    &:after {
      width: 1px;
      content: "";
      background: ${({theme})=> theme['secondary-variant']};
      height: 100%;
      display: inline-block;
    }
  }
  &.profileWrapper{
    .section-divider{
      max-height:347px;
    }
  }
.highlighted-text{
    color:${({ theme }) => `${theme['secondary-color']}!important`}; 
}
`;
const PaymentCardWrapper = styled.div`
  background: ${({theme}) => theme['card-background-color']};
  padding: 12px;
  border-radius: 16px;
  .payment-card-header {
    background: #1d1d1b;
    padding: 9px 12px 8px;
    p {
      font-weight: 400;
      font-size: ${({ theme }) => theme["base-font-size-small"]};
      line-height: 17px;
      letter-spacing: 0.2px;
      color: ${({theme})=> theme['on-primary']};
      margin-bottom: 0px;
      svg {
        color: ${({theme})=> theme['accent-info']};
        position: relative;
        top: -1px;
        font-size: ${({ theme }) => theme["base-font-size"]};
        margin-right: 3px;
        background-color: ${({theme})=> theme['on-primary']};
        border-radius: 500px;
      }
    }
  }
  .payment-card-content {
    padding-top: 42px;
    padding-bottom: 13px;
    text-align: center;
    p {
      font-weight: 700;
      font-size: ${({ theme }) => theme["base-font-size-small"]};
      line-height: 15px;
      color: ${({theme})=> theme['on-primary']};
      margin-bottom: 0;
    }
  }
`;
const PaymentForm = styled.div`
  h3 {
    font-weight: 700;
    font-size: ${({ theme }) => theme["medium-font-size"]};
    line-height: 17px;
    color: ${({theme})=> theme['primary']};
    margin-bottom: 24px;
  }
  .form-content {
    .form-group {
      margin-bottom: 12px;
      .form-label {
        margin-bottom: 8px;
      }
    }
    .form-control {
      border-radius: 14px;
      padding-left: 10px;
      font-size: ${({ theme }) => theme["xs-small-size"]};
      line-height: 12px;
    }
    .input-with-icon {
      .form-control {
        padding-left: 38px !important;
      }
      .input-icon {
        height: 13px;
        width: 15px;
        left: 13px;
        top: 7px;
        filter: grayscale(1);
        opacity: 0.5;
      }
    }
    .select2-container {
      margin-bottom: 12px;
      .select2-selection__control {
        border-radius: 14px;
        min-height: 28px;
        max-height: 28px;
      }
      .select2-selection__placeholder,
      .select2-selection__single-value {
        font-size: ${({ theme }) => theme["xs-small-size"]};
        line-height: 12px;
        margin-top: -3px;
      }
      .select2-selection__indicator {
        padding-top: 5px;
      }
    }
  }
  .form-button {
    margin-top: 24px;
    button {
      padding: 8px 15px;
    }
  }
`;
const InfoBlock = styled.div`
  max-width: 195px;
  margin: 0 auto;
  margin-right: 0;
  height: calc(100vh - 166px);
  display: flex;
  align-items: end;
  .info-box-main {
    position: relative;
    width: 100%;
    &:after {
      content: url(${CricleImage});
      position: absolute;
      top: -233px;
      left: -112px;
      z-index: -1;
    }
    &.no-bubbles {
      &:after {
        display: none;
      }
    }
  }
  .circle-section {
    margin-left: -112px;
    margin-bottom: 3px;
    display: none;
  }
  .info-block {
    background: ${({ theme }) => theme["background"]};
    border-radius: 16px;
    padding: 16px 15px;
    margin-bottom: 13px;
    &:last-of-type {
      margin-bottom: 0;
    }
    h3 {
      font-weight: 400;
      font-size: ${({ theme }) => theme["base-font-size-small"]};
      line-height: 15px;
      color: ${({ theme }) => theme["primary"]};
      margin-bottom: 8px;
    }
    p {
      font-weight: 400;
      font-size: ${({ theme }) => theme["xs-small-size"]};
      line-height: 12px;
      color: ${({ theme }) => theme["body-text-variant"]};
      margin-bottom: 16px;
    }
    button {
      background:${({theme}) => `linear-gradient(180deg, ${theme['primary-variant']} 0%, ${theme['primary']} 49.67%, ${theme['primary-btn-gradient-3']} 98.63%);`};

      margin-bottom: 0;
    }
    &.success-block {
      p {
        color: ${({ theme }) => theme["primary"]};
      }
      button {
        background: ${({ theme }) => theme["accent-success"]};
        border: 1px solid ${({ theme }) => theme["accent-success"]};
      }
    }
  }
`;

const InfoBlockForAffiliate = styled.div`
  max-width: 195px;
  margin: 0 auto;
  margin-right: 0;
  height: calc(98vh - 345px);
  display: flex;
  align-items: end;
  .info-box-main {
    position: relative;
    width: 100%;
    &:after {
      content: url(${CricleImage});
      position: absolute;
      top: -233px;
      left: -112px;
      z-index: -1;
    }
    &.no-bubbles {
      &:after {
        display: none;
      }
    }
  }
  .circle-section {
    margin-left: -112px;
    margin-bottom: 3px;
    display: none;
  }
  .info-block {
    background: ${({ theme }) => theme["background"]};
    border-radius: 16px;
    padding: 16px 15px;
    margin-bottom: 13px;
    &:last-of-type {
      margin-bottom: 0;
    }
    h3 {
      font-weight: 400;
      font-size: ${({ theme }) => theme["base-font-size-small"]};
      line-height: 15px;
      color: ${({ theme }) => theme["primary"]};
      margin-bottom: 8px;
    }
    p {
      font-weight: 400;
      font-size: ${({ theme }) => theme["xs-small-size"]};
      line-height: 12px;
      color: ${({ theme }) => theme["body-text-variant"]};
      margin-bottom: 16px;
    }
    button {
      background:${({theme}) => `linear-gradient(180deg, ${theme['primary-variant']} 0%, ${theme['primary']} 49.67%, ${theme['primary-btn-gradient-3']} 98.63%);`};

      margin-bottom: 0;
    }
    &.success-block {
      p {
        color: ${({ theme }) => theme["primary"]};
      }
      button {
        background: ${({ theme }) => theme["accent-success"]};
        border: 1px solid ${({ theme }) => theme["accent-success"]};
      }
    }
  }
`;

const ProfileForm = styled.div`
  .form-header {
    margin-bottom: 25px;
    h3 {
      font-weight: 700;
      font-size: ${({ theme }) => theme["medium-font-size"]};
      line-height: 17px;
      color: ${({theme})=> theme['primary']};
      margin-bottom: 6px;
    }
    p {
      font-weight: 400;
      font-size: ${({ theme }) => theme["base-font-size-small"]};
      line-height: 14px;
      color: ${({theme})=> theme['body-text-variant']};
      margin-bottom: 0;
    }
  }
  .form-content {
    border-bottom: 1px solid ${({theme})=> theme['secondary-variant']};
    h4 {
      font-weight: 400;
      font-size: ${({ theme }) => theme["xs-small-size"]};
      line-height: 12px;
      color: ${({theme})=> theme['primary']};
      margin-bottom: 12px;
    }
    .form-group {
      margin-bottom: 12px;
      .form-control {
        border-radius: 14px;
        padding: 9px 12px;
        max-height: 32px;
        font-size: ${({ theme }) => theme["base-font-size-small"]};
        line-height: 15px;
      }
    }
    button {
      margin-top: 12px;
      margin-bottom: 24px;
      padding:8px 16px;
    }
    .form-button {
    }
    .note {
      font-weight: 400;
      font-size: ${({ theme }) => theme["xs-small-size"]};
      line-height: 12px;
      color: ${({theme})=> theme['body-text-variant']};
      margin-bottom: 24px;
      a {
        color: ${({theme})=> theme['primary']};
        text-decoration: none;
      }
    }
    .form-block-top {
      border-bottom: 1px solid ${({theme})=> theme['secondary-variant']};
    }
    .form-block-bottom {
      padding-top: 53px;
      padding-bottom: 30px;
      button{
        margin-top:0;
      }
    }
    h2 {
      font-weight: 700;
      font-size: ${({ theme }) => theme["base-font-size"]};
      line-height: 19px;
      color: ${({theme})=> theme['primary']};
      margin-bottom: 12px;
    }
  }
`;
export {
  DashbaordWrapper,
  BillingPaymentWrapper,
  PaymentCardWrapper,
  PaymentForm,
  InfoBlock,
  InfoBlockForAffiliate,
  ProfileForm,
};
