import React, { useLayoutEffect, useEffect } from 'react';
import { Backdrop, CircularProgress } from '@mui/material';
import { Route, useHistory, } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import crypto from 'crypto';

import { ClearStates } from '../helpers/clear-store-states';

import ChatBot from '../components/Chat';
import ExitImpersonation from '../components/Impersonate';

import { APP_IDS, REDIRECT_URIS } from '../constants';

import { SetStoreState, GetStore, GetStoreBySellerId } from '../redux/slices/store-slice';
import { SetAuthState } from '../redux/slices/auth-slice';

const PrivateRoute = ({
  component: Component,
  withLayout: Layout,
  ...rest
}) => {
  const hostName = window.location.hostname;
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    token,
    storesCount,
    storeStatus,
    storeId,
    user,
    payLater,
    connectedStore,
    amazonOnbarding,
    amazonUrl,
    showSidebar,
    inImpersonation
  } = useSelector((state) => state.auth);
  const { store, storeExist, sellerId } = useSelector((state) => state.store);

  const renderRoute = () => (
    <Route
      {...rest}
      render={(props) => <> 
      <ChatBot route='private'/><Component {...props} /></>}
    />
  );

  useLayoutEffect(() => {
    const path = location.pathname;
    if (!token || token === '') {
      history.push('/auth/sign-in');
      return;
    } else if (token && ['user', 'affiliate-manager'].includes(user.role)) {
      if (user?.role === 'affiliate-manager') {
        history.push('/affiliate-users');
      } else {
        if (!isEmpty(storeId)) {
          dispatch(SetStoreState({ field: 'storeId', value: storeId }));
          dispatch(GetStore({ storeId }))
        } else if (!isEmpty(connectedStore) && path !== '/auth/sp-api') {
          dispatch(GetStore({ storeId: connectedStore }))
          dispatch(SetStoreState({ field: 'storeId', value: connectedStore }));
          dispatch(SetAuthState({ field: 'storeId', value: connectedStore }));
        }

        if (window.location.search.includes('spapi_oauth_code')) {
          if(storeStatus !== 'Initial') {
            history.push('/auth/sp-api');
          }
        } else {
          if (payLater && path !== '/auth/sp-api') {
            history.push(path);
          } else {
            history.push('/auth/sp-api');
          }
        }
      }
    } else {
      history.push(path);
    }
  }, [token, storesCount]);

  useEffect(() => {
    if (amazonOnbarding !== null && amazonUrl) {
      dispatch(GetStoreBySellerId({ sellerId, userId: user?.userId || '', token }))
      dispatch(SetStoreState({ field:'sellerId', value: ''}))
    }
  }, [amazonOnbarding]);

  useEffect(() => {
    if (storeExist !== null) {
      dispatch(SetStoreState({ field: 'error', value: '' }))
      dispatch(SetStoreState({ field: 'storeExist', value: null }));
      if (storeExist) {
        if (!store?.spApi?.apiConnected ) {
          if (store?.userId === user?.userId) {
            ClearStates({ dispatch, fromApp: showSidebar, storeId: store?._id })
    
            const state = crypto.randomBytes(20).toString("hex");
    
            dispatch(SetStoreState({ field: 'state', value: state }));
            dispatch(SetAuthState({ field: 'amazonOnbarding', value: null }));
            dispatch(SetAuthState({ field: 'amazonUrl', value: '' }));

            const url = `https://sellercentral-europe.amazon.com/authorization/select-account?returnTo=/apps/authorize/consent?state=${state}%26application_id=${APP_IDS[hostName]}&redirect_uri=${REDIRECT_URIS[hostName]}`
            window.open(url, "_self");
          }
        }
        dispatch(SetAuthState({ field: 'amazonOnbarding', value: null }));
        dispatch(SetAuthState({ field: 'amazonUrl', value: '' }));
      } else {
        ClearStates({dispatch, fromApp:showSidebar})
        history.push('/auth/sp-api');
      }
    }
  }, [storeExist]);

  return token ? (<Layout>{renderRoute()}</Layout>) : (
    <Backdrop open={true} style={{ zIndex: '9' }}>
      <CircularProgress color='inherit' />
    </Backdrop>
  );
};

export default PrivateRoute;
