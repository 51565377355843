import React from "react";
import { Popover } from "antd";
import ArrowIcon from "../../../assets/images/arrow.svg";
import Flag from "../../../assets/images/flag.svg";
import Flag1 from "../../../assets/images/flag-1.svg";
import Flag2 from "../../../assets/images/flag-2.svg";
import Flag3 from "../../../assets/images/flag-3.svg";
import { TitleStyleWrapper } from "./style";

const Title = ({ currentStep }) => {
  return (
    <TitleStyleWrapper>
     {currentStep === 3 ? <h3>
      Grant Permissions
      </h3> : null}
     {currentStep === 4 ? <h3>
      Add Payment Method
      </h3> : null}
     {currentStep === 5 ? <h3>
      Finish
      </h3> : null}
        {currentStep === 3 ? <p className="current-case-item">To submit claims we need limited access to your account</p> : null}
        {currentStep === 4 ? <p className="current-case-item">We need a payment method on file; you'll only be charged on successful recovery</p> : null}
        {currentStep === 5 ? <p className="current-case-item">We're ready to start recovering your funds</p> : null}
    </TitleStyleWrapper>
  );
};

export default Title;
