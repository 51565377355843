import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { MenuItem } from 'react-pro-sidebar';

import { setAuthToken } from '../../config/axios-configuration';

import { SetAuthState } from '../../redux/slices/auth-slice';
import { SetUserState } from '../../redux/slices/user-slice';
import { SetStoreState } from '../../redux/slices/store-slice';

import Icon from '../icons';

const ExitImpersonation = ({
  styles
}) => {
  const { iconSize } = styles || {};
  const history = useHistory();
  const dispatch = useDispatch();
  const { admin, adminToken } = useSelector((state) => state.auth);
  const handleExitImpersonation = () => {
    setAuthToken(adminToken);
    dispatch(SetAuthState({ field: 'token', value: adminToken }));
    dispatch(SetAuthState({ field: 'user', value: admin }));
    dispatch(SetAuthState({ field: 'inImpersonation', value: false }));
    dispatch(SetAuthState({ field: 'inAppOnboarding', value: false }));
    dispatch(SetUserState({ field: 'user', value: admin }));
    dispatch(SetStoreState({field: 'stores', value: [] }));
    dispatch(SetStoreState({field: 'storeId', value: '' }));
    dispatch(SetStoreState({field: 'storeName', value: '' }));
    dispatch(SetStoreState({field: 'store', value: '' }));
    dispatch(SetStoreState({field: 'totalStores', value: '' }));
    dispatch(SetStoreState({field: 'totalConnected', value: '' }));
    dispatch(SetStoreState({field: 'totalPending', value: '' }));
    dispatch(SetStoreState({field: 'totalInviteAccepted', value: '' }));
    dispatch(SetStoreState({field: 'progress', value: '' }));
    dispatch(SetStoreState({field: 'storeExist', value: null }));
    dispatch(SetStoreState({field: 'connectedStore', value: '' }));

    history.push('/users');
  }

  return (
    <MenuItem
    icon={<Icon size={iconSize || '100%'} icon="logout" />}
    onClick={handleExitImpersonation}>
      Exit Impersonation
    </MenuItem>
  )
}

export default ExitImpersonation;
