import React, { useState } from 'react';
import { Popover } from 'antd';

import Select from '../../components/Select';
import Input from '../../components/Inputs/label-input'
import Button from '../../componentsv2/Button';
import filterIconSt from '../../assets/icons/filter-icon.svg';
import filterIconSp from '../../assets/icons/filter-icon-sp.svg'
import SortIconSt from '../../assets/icons/sort-arrow-up.svg';
import SortIconSp from '../../assets/icons/sort-arrow-up-sp.svg';
import DownIconSt from '../../assets/icons/sort-arrow-down.svg';
import DownIconSp from '../../assets/icons/sort-arrow-down-sp.svg';
import TopArrow from '../../assets/icons/top-arrow.svg';
import DownArrow from '../../assets/icons/down-arrow.svg';
import { APP_DOMAINS } from '../../constants';

const StoreFilter = ({
  title,
  showArrow,
  expireIn,
  options,
  components,
  handleChange,
  filterName,
  operationList,
  selectedStore,
  handleSorting,
  sorting
}) => {
  const [openFilter, setOpenFilter] = useState(false);
  const [event, setEvent] = useState({})
  const [err, setErr] = useState('');

  return (
    <>
      <div className='d-flex align-items-center'>
        <span>{title}</span>
        {
          filterName !== 'storeId' ? (
            <div className="d-flex flex-column mx-2">
              <div
                onClick={() => {
                  let value = null;
                  if (sorting[filterName] === -1 || !sorting[filterName]) {
                    value = 1;
                  } else {
                    value = -1
                  }

                  handleSorting(filterName, value)
                }}
                className='lineheight-normal'
              >
                <img src={sorting[filterName] === 1 ? TopArrow : sorting[filterName] === -1 ? DownArrow : window.location.hostname === APP_DOMAINS.sellerTerminal ? SortIconSt : SortIconSp} className="pointer" alt='arrow' />
              </div>
              {
                !sorting[filterName] ? (
                  <div onClick={() => handleSorting(filterName, -1)} className='lineheight-normal'>
                    <img src={window.location.hostname === APP_DOMAINS.sellerTerminal ? DownIconSt : DownIconSp } className="pointer" alt='arrow' />
                  </div>
                ) : null
              }
            </div>
          ) : null
        }
        <div onClick={() => setOpenFilter(true)}>
          <img src={window.location.hostname === APP_DOMAINS.sellerTerminal ? filterIconSt : filterIconSp} alt='filter' />
        </div>
      </div>
      <Popover
        content={(
          <div className='filter-popover'>
            {['discrepancy', 'potentialRecovery', 'shipmentExpirationDate'].includes(filterName) ?
              <div>
                <Select
                  placeholder='Select Operations'
                  options={operationList}
                  isClearable={true}
                  components={components}
                  handleChange={(e) => {
                    console.log({ e })
                    if (e?.value) {
                      setEvent({ operation: e.value });
                      if (err) {
                        setErr('');
                      }
                      document.getElementsByName('operationValue')[0].value = '';
                    } else {
                      setEvent({});
                      document.getElementsByName('operationValue')[0].value = '';
                    }
                  }}
                />              
                <Input
                  name='operationValue'
                  placeholder='Enter'
                  onChange={(e) => {
                    setEvent((prev) => ({ ...prev, value: Number(e.target.value) }));
                    if (err) {
                      setErr('');
                    }
                  }} 
                />                
              </div>
              : (
                <>
                  <Select
                    placeholder={`Select ${title}`}
                    options={options}
                    isClearable={true}
                    components={components}
                    handleChange={(e) => {
                      if (e?.value) {
                        setEvent(e);
                        if (err) {
                          setErr('');
                        }
                      } else {
                        setEvent({});
                      }
                    }}
                  />
                </>
              )
            }

            <Button
              text='Apply'
              width="100%"
              className="primary"
              onClick={() => {
                console.log({event})
                handleChange(filterName, event);
                setOpenFilter(false)
              }}
            />
          </div>
        )}
        title='Filter By'
        placement="bottom"
        trigger="click"
        open={openFilter}
        onOpenChange={() => setOpenFilter(false)}
      />
    </>
  )
}

export default StoreFilter;
