import React from "react";
import { Progress } from "antd";
import { ProgressStWrapper } from "./style";

const ProgressSt = ({ percent, ...rest }) => {
  return (
    <ProgressStWrapper>
      <Progress {...rest} percent={percent} />
    </ProgressStWrapper>
  );
};
export default ProgressSt;
