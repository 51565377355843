import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import AmountCard from "../../../components/amountCardSt";
import PotentialAmount from "../../../components/potentialAmount";
import ManegePermissions from "./manegePermissions";
import StepFour from "./stepFour";
import StepFive from "./stepFive";

import { APP_DOMAINS } from '../../../constants';

import DollarDown from "../../../assets/images/dollar_down_arrow.svg";
import DollarDownSp from "../../../assets/images/dollar-sp.svg";
import InCorrect from "../../../assets/images/incorrect-st.svg";
import InCorrectSp from "../../../assets/images/sheet-sp.svg";
import HistoryIcon from "../../../assets/images/cash-back-st.svg";
import HistoryIconSp from "../../../assets/images/cash-back-sp.svg";
import CrashIcon from "../../../assets/images/crash-icon.svg";
import CrashIconSp from "../../../assets/images/crack-sp.svg";
import DisplayIcon from "../../../assets/images/display-icon.svg";
import DisplayIconSp from "../../../assets/images/point-sp.svg";

import { StepThreeWrapper } from "./style";

import { GetPtRecoveryBreakdown } from "../../../redux/slices/store-slice";

const StepThree = ({ currentStep, handleNext, handleStepChange }) => {
  const host = window.location.hostname;
  const dispatch = useDispatch();
  const {
    storeId,
    potentialRecoveryBreakdown,
    potentialRecovery
  } = useSelector((state) => state.store);

  const [value, setValues] = useState();
  const [total, setTotal] = useState(0);
  const [currencies, setCurrencies] = useState([]);
  const [currency, setCurrency] = useState('$');
  const [slider, setSlider] = useState(0);

  const amountCardData = [
    {
      icon: host !== APP_DOMAINS.sellerTerminal ? DollarDownSp : DollarDown,
      label: "Lost",
      tag: 'lostClaimableAmount'
    },
    {
      icon: host !== APP_DOMAINS.sellerTerminal ? CrashIconSp : CrashIcon,
      label: "Damaged/Destroyed",
      tag: 'destroyedClaimableAmount'
    },
    {
      icon: host !== APP_DOMAINS.sellerTerminal ? InCorrectSp : InCorrect,
      label: "Incorrect Fee's",
      tag: 'fulfillmentFeeClaimableAmount'
    },
    {
      icon: host !== APP_DOMAINS.sellerTerminal ? HistoryIconSp : HistoryIcon,
      label: "Refunds",
      tag: 'processedReturnClaimableAmount'
    },
    {
      icon: host !== APP_DOMAINS.sellerTerminal ? DisplayIconSp : DisplayIcon,
      label: "Shipments",
      tag: 'shipmentsClaimableAmount'
    },
  ];

  const calculateTotal = (cur) => {
    let sum = 0;
    Object.values(potentialRecoveryBreakdown).forEach((item) => {
      sum += item[cur] || 0;
    });

    return sum;
  };

  useEffect(() => {
    dispatch(GetPtRecoveryBreakdown({ storeId }));
  }, [])

  useEffect(() => {
    const currenciesArray = [];
    const keys = Object.keys(potentialRecoveryBreakdown);
    for (let i = 0; i < keys.length; i += 1) {
      currenciesArray.push(...Object.keys(potentialRecoveryBreakdown[keys[i]]));
    }
    const uniqueCurrecncies = [...new Set(currenciesArray)];
    if (uniqueCurrecncies?.length) {
      setCurrencies(uniqueCurrecncies);
      setCurrency(uniqueCurrecncies[slider]);
      setTotal(calculateTotal(uniqueCurrecncies[0]));
    } else {
      setCurrencies([]);
      setCurrency('$');
      setTotal(0);
    }
  }, [potentialRecoveryBreakdown]);

  useEffect(() => {
    setCurrency(currencies[slider]);
    setTotal(calculateTotal(currencies[slider]));
  }, [slider])

  return (
    <StepThreeWrapper>
      <div className="amount_Box_overlay">
        <div className="amount_Box_item">
          <h4>Recovery Breakdown</h4>
          <div className="amount_card_overlay">
            {amountCardData.map((item, index) => (
              <AmountCard
                key={index}
                icon={item.icon}
                label={item.label}
                price={item.label === 'Damaged/Destroyed' ?
                  ((potentialRecoveryBreakdown?.['damagedClaimableAmount']?.[currency] || 0) + (potentialRecoveryBreakdown?.[item.tag]?.[currency] || 0))
                  : potentialRecoveryBreakdown?.[item.tag]?.[currency] || 0}
                currency={currency}
              />
            ))}
          </div>
        </div>
        <PotentialAmount
          amountName="Potential Recovery Amount"
          amountText={total || 0}
          currency={currency}
          next={() => setSlider((slider + 1) % currencies?.length)}
          prev={() => setSlider((prev) => {
            if (prev >= 1) {
              return prev - 1;
            } else {
              return prev + currencies?.length - 1;
            }
          })}
        />
      </div>
      {currentStep === 3 ? <ManegePermissions /> : currentStep === 4 ? <StepFour handleNext={handleNext} /> : currentStep === 5 ? <StepFive handleStepChange={(step) => handleStepChange(step)}/>: null}
    </StepThreeWrapper>
  );
};

export default StepThree;
