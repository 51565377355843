import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { axiosBaseUrl } from '../../config/axios-configuration';

import SaveSentryLog from '../../helpers/sentry-log';

const axios = axiosBaseUrl();

export const GetLatestAmazonAccountDetail = createAsyncThunk(
  'account-detail/getLatestAmazonAccountDetail',
   async (data, { rejectWithValue }) => {
     try {
       const response = await axios.get('/account/get-latest-amazon-account-detail');
       return response.data;
     } catch (err) {
       SaveSentryLog(new Error(err), 'GetLatestAmazonAccountDetailError');
       if (err.response && err.response.data) {
         return rejectWithValue({ 
           err: {
             message: err.response.data.message,
             status: err.response.status
           }
         });
       }
       return rejectWithValue({
         err: {
           message: 'Network Error',
           status: 502
         }
       });
     }
   }
);

export const GetAmazonAccountDetail = createAsyncThunk(
  'account-detail/getAmazonAccountDetail',
  async (data, { rejectWithValue }) => {
    try {
      const { accountId } = data;
      console.log(data);
      const response = await axios.get('/account/get-amazon-account-details',{
        params: {
          accountId
        }
      });
      return response.data;
    } catch (err) {
      SaveSentryLog(new Error(err), 'GetAmazonAccountDetailError');
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: {
            message: err.response.data.message,
            status: err.response.status
          }
        });
      }
      return rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

export const StartEcsPermissionTask = createAsyncThunk(
  'task/start-permission-task',
  async ({ storeId }, { rejectWithValue }) => {
    try {
      const response = await axios.post('/ecs/check-user-permissions', {
        storeId
      });
      return response.data;
    } catch (err) {
      SaveSentryLog(new Error(err), 'StartEcsPermissionTaskError');
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

const accountReducer = createSlice({
  name: 'account-detail',
  initialState: {
    error: '',
    account: {},
    success: false,
    accountFound: false,
    status: '',
    ecsPrmissionTaskStart: false,
    latestAmazonAccountDetail: '',
    amazonAccountSuccess: null,
    managerName: '',
    accountId: ''
  },
  reducers: {
    SetState(state, { payload: { field, value } }) {
      state[field] = value;
    }
  },
  extraReducers: {
    [GetLatestAmazonAccountDetail.pending]: (state, action) => ({
      ...state,
      loading: true
    }),
    [GetLatestAmazonAccountDetail.fulfilled]: (state, action) => ({
      ...state,
      success: true,
      loading: false,
      latestAmazonAccountDetail: action.payload.latestAmazonAccountDetail,
      managerName: action.payload.managerName,
      accountId: action.payload.accountId,
      amazonAccountSuccess: true
    }),
    [GetLatestAmazonAccountDetail.rejected]: (state, action) => ({
      ...state,
      success: false,
      loading: false,
      error: action.payload.err.message,
      amazonAccountSuccess: false
    }),
    [GetAmazonAccountDetail.pending]: (state, action) => ({
      ...state,
      loading: true
    }),
    [GetAmazonAccountDetail.fulfilled]: (state, action) => ({
      ...state,
      success: true,
      loading: false,
      account: action.payload.accountDetail,
      latestAmazonAccountDetail: action.payload.accountDetail.sellerCentralLoginEmail,
      managerName: action.payload.accountDetail?.name,
      amazonAccountSuccess: true
    }),
    [GetAmazonAccountDetail.rejected]: (state, action) => ({
      ...state,
      success: false,
      loading: false,
      error: action.payload.err.message,
      amazonAccountSuccess: false
    }),
    [StartEcsPermissionTask.pending]: (state, action) => ({
      ...state,
      loading: true
    }),
    [StartEcsPermissionTask.fulfilled]: (state, action) => ({
      ...state,
      success: true,
      loading: false,
      ecsPrmissionTaskStart: true,
    }),
    [StartEcsPermissionTask.rejected]: (state, action) => ({
      ...state,
      success: false,
      loading: false,
      error: action.payload.err.message
    })
  }
});

const { reducer, actions } = accountReducer;

export const {
  SetState
} = actions;

export default reducer;
