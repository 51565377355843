import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { axiosBaseUrl } from '../../config/axios-configuration';

import SaveSentryLog from "../../helpers/sentry-log";

const axios = axiosBaseUrl();

export const GetAgenciesName = createAsyncThunk(
  'agency/get-agencies-name-list',
   async (data, { rejectWithValue }) => {
     try {
       const response = await axios.get('/agency/get-agencies-name');
       return response.data;
     } catch (err) {
       SaveSentryLog(new Error(err), 'GetAgenciesNameError');
       if (err.response && err.response.data) {
         return rejectWithValue({
           err: {
             message: err.response.data.message,
             status: err.response.status
           }
         });
       }
       return rejectWithValue({
         err: {
           message: 'Network Error',
           status: 502
         }
       });
     }
   }
);


export const GetAgencyReceipts = createAsyncThunk(
  'agency/get-agency-receipts',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get('/agency/get-agency-receipts', {
        params: data
      });
      return response.data;
    } catch (err) {
      SaveSentryLog(new Error(err), 'GetAgencyReceiptsError');
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: {
            message: err.response.data.message,
            status: err.response.status
          }
        });
      }
      return rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);


const job = createSlice({
    name: 'agency',
    initialState: {
      agenciesName: [],
      error: '',
      loading: '',
      message: '',
      agencyReceipts:[],
      totalAgencyReceipts: 0
    },
    reducers: {
      SetAgencyState(state, {payload: {field, value} }) {
        state[field] = value;
      }
    },
    extraReducers: {
      [GetAgenciesName.pending]: (state, action) => ({
        ...state,
        loading: true
      }),
      [GetAgenciesName.fulfilled]: (state, action) => ({
        ...state,
        success: true,
        loading: false,
        agenciesName: action.payload.agenciesName,
        // message: action.payload.message
      }),
      [GetAgenciesName.rejected]: (state, action) => ({
        ...state,
        success: false,
        loading: false,
        error: action.payload.err.message
      }),
      [GetAgencyReceipts.pending]: (state, action) => ({
        ...state,
        loading: true,
        success: false
      }),
      [GetAgencyReceipts.fulfilled]: (state, action) => ({
        ...state,
        success: true,
        loading: false,
        totalAgencyReceipts: action.payload.response.totalAgencyReceipts,
        agencyReceipts: action.payload.response.agencyReceipts,
      }),
      [GetAgencyReceipts.rejected]: (state, action) => ({
        ...state,
        success: true,
        loading: false,
        error: action.payload.err.message
      }),
    }
  });

  const { reducer, actions } = job;

  export const { SetAgencyState } = actions;

  export default reducer;
