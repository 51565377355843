import moment from "moment";
import React, {
  useState,
  useEffect
} from "react";
import {
  useDispatch,
  useSelector
} from "react-redux";
import {
  BsArrowLeftShort,
} from "react-icons/bs";

import Spin from "../../components/Spin/Index";
import Table from "../../components/Table/table";

import { GetEmailLogs, SetUserState } from "../../redux/slices/user-slice";

const Index = ({
  userData,
  handleClick
}) => {
  const dispatch = useDispatch();
  const [tableBody, setTableBody] = useState([]);

  const{
    loading,
    success,
    userEmailLogs
  } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(GetEmailLogs({userId: userData?._id}));

    return () => {
      dispatch(SetUserState({ field: 'userEmailLogs', value: [] }));
    }
  }, [])

  useEffect(() => {
    if (success && userEmailLogs.length > 0) {
      const emailTable =
        userEmailLogs.map((item, i) => {
          return (
            <tr key={i} >
              <td>{item.emailSubject || ''}</td>
              <td>{item.storeName || ''}</td>
              <td>{moment(item.emailSentAt).format('lll') || ''}</td>
            </tr>
          );
        });
      setTableBody(emailTable);
    } else setTableBody([]);
  }, [userEmailLogs]);

  return (
    <>
      <div className="d-flex align-items-center grid-column-gap-10">
      </div>
      <div className="p-4">
        <div className="d-flex align-items-center grid-column-gap-10">
          <BsArrowLeftShort
            onClick={() => { 
              setTableBody([]);
              handleClick();
            }}
            className="cursor-pointer icon-color"
          />
          <h3 className="mb-0">User Email Logs</h3>
        </div>
        <div className="mt-4 invoice-status p-3 d-flex justify-content-between">
          <div className="d-flex flex-column">
            <span className="text-primary weight-bold fs-12">User Email:</span>
            <span className="fs-14 d-block text-primary">{userData?.email || ''}</span>
          </div>
          <div className="d-flex flex-column">
            <span className="text-primary weight-bold fs-12">User Name:</span>
            <span className="fs-14 d-block text-primary">{userData?.name || ''}</span>
          </div>
          <div className="d-flex flex-column">
            <span className="text-primary weight-bold fs-12">Total Stores:</span>
            <span className="fs-14 d-block text-primary">{userData?.userStoreInfo?.length || 0}</span>
          </div>
        </div>
        <div className="pt-4">
          <Table
            viewHeight="case-stat-table"
            tableHeader={
              <tr>
                <th>Email Type</th>
                <th>Store Name</th>
                <th>Email Sent At</th>
              </tr>
            }
            tableBody={
              <>
                {(!loading && !userEmailLogs.length && success) ? (
                  <tr><td>
                    <div className='error'>
                      <h1>No Data Available</h1>
                    </div>
                  </td>
                  </tr>
                ) : loading ? <tr><td><Spin /></td></tr> : tableBody}
              </>
            }
          />
        </div>
      </div>

    </>
  );
};

export default Index;
