import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Spin } from "antd";

import Radio from "../RadioButton/index";
import Notification from '../notifications/notification';

import CheckIcon from "../../assets/images/green-check.svg";
import CopyIcon from "../../assets/images/copy-content.svg";
import CrossIcon from "../../assets/images/delete-button-icon.svg";

import { RadioCardBarWrapper } from "./style";

const RadioCardBar = ({
  showSpin,
  titleText,
  labelText,
  name,
  firstValue,
  secondValue,
  showButton
}) => {
  const [showConfirmation, setShowConfirmation] = useState();
  const [open, setOpen] = useState(false);
  const {
    store: {
      permissions
    }
  } = useSelector((state) => state.store);

  useEffect(() => {
    setShowConfirmation(permissions?.[name])
  }, [permissions])

  const CopyText = (text) => {
    setOpen(true);
    return navigator.clipboard
      .writeText(text)
      .then(t => true)
      .catch(e => false);
  }

  useEffect(() => {
    if (open) {
      Notification({
        type: "success",
        title: "Copied !!!"
      });
    }
    setOpen(false);
  }, [open]);

  return (
    <RadioCardBarWrapper>
      <div
        className={`card_overlay_wrapper-st ${typeof showConfirmation === "undefined"
          ? ""
          : !showConfirmation
            ? "card-failed"
            : "card-success"
          }`}
      >
        <div className="radio_card_overlay">
          <div className="analyzing-text-wrapper">
            <div className="radio_card_item">
              {
              (showSpin && !showButton && !showConfirmation) ? (
                  <Spin size="small" />
              ) :
              (showSpin === true && typeof(showConfirmation) === 'undefined') ? (
                <Spin size="small" />
              ) : (showSpin === false && typeof(showConfirmation) === "undefined") ? (
                <span className="circle"></span>
              ) :
                !showConfirmation ? (
                  <img src={CrossIcon} />
                ) : (
                  <img src={CheckIcon} />
                )}
              <p>{titleText}</p> <img onClick={()=> CopyText(titleText)} src={CopyIcon} />
            </div>
            <span>{labelText}</span>
          </div>
          {firstValue ? <Radio disabled /> : <Radio defaultValue={false} disabled />}
          {secondValue ? <Radio disabled /> : <Radio defaultValue={false} disabled />}
        </div>
      </div>
    </RadioCardBarWrapper>
  );
};

export default RadioCardBar;
