import React, { useState, useEffect } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  BsArrowLeftShort,
} from "react-icons/bs";

import Pagination from "../../components/Pagination/pagination";
import Spin from "../../components/Spin/Index";
import Table from "../../components/Table/table";

import { GetShipmentItems } from "../../redux/slices/shipment-slice";

const Index = ({
  shipmentIdd,
  shipmentItemHeader,
  handleClick
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  
  const [filters, setFilters] = useState({
    storeId: ''
  });
  const [pageLimit, setPageLimit] = useState(25);
  const [pageNumber, setPageNumber] = useState(1);
  const [shipmentId, setShipmentId] = useState('');

  const {
    shipmentItems,
    shipmentItemsTotal,
    loading
  } = useSelector((state) => state.shipment);

  useEffect(() => {
    const shipmentId = shipmentIdd;
    setShipmentId(shipmentId);
  }, [])

  useEffect(() => {
    const { storeId } = shipmentItemHeader;
    setFilters({
      storeId
    });
  },[shipmentItemHeader]);
  useEffect(() => {
    const { storeId } = filters;
    if (shipmentId !== '' && storeId !== '') {
      getShipmentItems();
    }
  }, [shipmentId, pageNumber, pageLimit, filters]);

  const getShipmentItems = () => {
    const skip = (pageNumber - 1) * pageLimit;
    const limit = pageLimit;


    dispatch(GetShipmentItems({ shipmentId, filters, limit, skip }))
  };


  const handlePageLimit = (e) => {
    setPageLimit(e.value);
  };

  const handlePageNumberChange = (e) => {
    const totalPages = Math.ceil(shipmentItemsTotal / pageLimit)
    if (e.key == "Enter") {
      if (e.target.value < 1 || !e.target.value) {
        setPageNumber(1);
      } else if (e.target.value > totalPages) {
        setPageNumber(totalPages)
      } else setPageNumber(e.target.value)
    }
  }

  const handlePageChangeLeft = () => {
    if (pageNumber - 1 > 0) setPageNumber(pageNumber - 1);
    else setPageNumber(1);
  }

  const handlePageChangeRight = () => {
    const totalPages = Math.ceil(shipmentItemsTotal / pageLimit)
    if (pageNumber + 1 < totalPages) setPageNumber(pageNumber + 1);
    else setPageNumber(totalPages > 0 ? totalPages : 1);
  }

  return (
    <>
      <div className="d-flex align-items-center grid-column-gap-10">
      </div>
          <div className="p-4">
            <div className="d-flex align-items-center grid-column-gap-10">
              <BsArrowLeftShort
                onClick={handleClick}
                className="cursor-pointer icon-color"
              />
              <h3 className="mb-0">Shipment Items</h3>
            </div>
            <div className="mt-4 invoice-status p-3 d-flex justify-content-between">
              <div className="d-flex flex-column">
                <span className="text-primary weight-bold fs-12">Shipment</span>

                <span className="text-primary weight-bold fs-10">
                  Store: <span className="color-black">{shipmentItemHeader?.storeName || 'N/A'}</span>
                </span>
                <span className="text-primary weight-bold fs-10">
                  User Email: <span className="color-black">{shipmentItemHeader?.userEmail || 'N/A'}</span>
                </span>
              </div>
              <div className="d-flex flex-column">
                <span className="text-primary weight-bold fs-12">Shipment ID</span>
                <span className="fs-14 d-block text-primary">
                  {shipmentItemHeader.shipmentId}
                </span>
              </div>
              <div className="d-flex flex-column">
                <span className="text-primary weight-bold fs-12">Total Quantity Received</span>
                <span className="fs-14 d-block text-primary">  {shipmentItemHeader.quantityReceived} </span>
              </div>

              <div className="d-flex flex-column">
                <span className="text-primary weight-bold fs-12">Total Quantity Shipped</span>
                <span className="fs-14 d-block text-primary">  {shipmentItemHeader.quantityShipped} </span>
              </div>

              <div className="d-flex flex-column">
                <span className="text-primary weight-bold fs-12">Total Discrepancies</span>
                <span className="fs-14 d-block text-primary">  {shipmentItemHeader.discrepency} </span>
              </div>
              
            </div>
            <div className="pt-4">
              <Table
                viewHeight="case-stat-table"
                tableHeader={
                  <tr>
                    <th>Seller Sku</th>
                    <th>FNSKU</th>
                    <th>Quantity Received</th>
                    <th>Quantity Shipped</th>
                    <th>Discrepancy</th>
                  </tr>
                }
                tableBody={
                  <>
                    {!loading && !shipmentItems.length ? (
                      <tr><td>
                        <div className='error'>
                          <h1>No Data Available</h1>
                        </div>
                      </td></tr>
                    ) : null}
                    {loading ? <tr><td><Spin /></td></tr> :
                      shipmentItems?.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <>
                                {item.sellerSku || 'N/A'}
                              </>
                            </td>
                            <td>
                              <>
                                {item.fnsku || 'N/A'}
                              </>
                            </td>
                            <td>{item.quantityReceived}</td>
                            <td>{item.quantityShipped}</td>
                            <td>{item.discrepancies}</td>
                          </tr>
                        )
                      })
                    }
                  </>
                }
              />
              <Pagination
                pageLimit={pageLimit}
                total={shipmentItemsTotal}
                pageNumber={pageNumber}
                totalPages={Math.ceil(shipmentItemsTotal / pageLimit)}
                handlePageChangeLeft={handlePageChangeLeft}
                handlePageChangeRight={handlePageChangeRight}
                handleLimitChange={handlePageLimit}
                handlePageNumberChange={handlePageNumberChange}
              />
            </div>
          </div>
        
    </>
  );
};

export default Index;

