import styled from "styled-components";

const RadioCardBarWrapper = styled.div`
  width: 100%;
  .card_overlay_wrapper-st {
    border-radius: 4px;
    border: 1px solid var(--Placeholder-Border, #dbdfe1);
    background: var(--bg, #f9fafb);
    padding: 8px;
    &.card-success {
      border-radius: 4px;
      background: rgba(82, 196, 26, 0.1);
    }
    &.card-failed {
      border-radius: 4px;
      background: rgba(255, 77, 79, 0.05);
    }

    .radio_card_overlay {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .radio_card_item {
        display: flex;
        align-items: center;
        grid-gap: 8px;
        .circle {
          background: #d9d9d9;
          width: 12px;
          height: 12px;
          border-radius: 10px;
        }
        img {
          cursor: pointer;
        }
        p {
          color: var(--Body-Darker, #474747);
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: 16px;
          margin: 0px !important;
        }
      }
    }
    .analyzing-text-wrapper {
      width: 73%;
      overflow-wrap: anywhere;
      span {
        color: var(--text-field-body-color, #5e718d);
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        &:nth-child(2) {
          padding-left: 20px;
        }
      }
    }
  }
`;
export { RadioCardBarWrapper };
