import React from "react";

import { AmountCardWrapper } from "./style";

const AmountCard = ({
  price,
  label,
  icon,
  currency = '$'
}) => {
  return (
    <AmountCardWrapper>
      <div className="card_overlay_wrapper">
        <div className="card_overlay_item">
          <img src={icon} />
          <div className="card_item">
            <p>{label}</p>
            <h6>{currency} {price}</h6>
          </div>
        </div>
      </div>
    </AmountCardWrapper>
  );
};
export default AmountCard;
