import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import PrivateRoute from './private-route';

import AddCard from "../pages/AddCards/index";
import Dashboard from '../pages/Dashboard/dashboard';
import Payments from '../pages/Dashboard/payment';
import DataGathering from '../pages/OnBoarding/data-gathering';
import WaitingScreen from '../pages/waitingScreen';
import EditStore from '../pages/store/edit-store';
import Invoice from "../pages/Invoice/index";
import AffiliateManagerInvoice from "../pages/AffiliateManagerInvoice/index";
import Jobs from '../pages/Jobs/jobs';
import LayoutNew from '../pages/Layout/layout.js';
import PageNotFound from '../pages/Other/page-not-found';
import SelectRegion from '../pages/OnBoarding/select-region';
import StoreSuccess from '../pages/OnBoarding/store-success';
import Stores from '../pages/store';
import Users from '../pages/Users/users';
import InvoiceDetails from "../pages/InvoiceDetails/index"
import { setAuthToken } from '../config/axios-configuration';
import SellerCentralPermission from '../pages/OnBoarding/invite-user';
import Permissions from '../pages/OnBoarding/permissions';
import Profile from "../pages/Profile/index";
import Shipments from "../pages/Shipments";
import ShipmentsFba from "../pages/ShipmentFba";

import AffiliateManager from "../pages/AffiliateManager"
import FulfillmentFees from "../pages/FulfillmentFees";
import Stats from "../pages/CaseStats";
import ThankyouStep from '../pages/OnBoarding/waiting-screen';
import AffiliateDashboard from '../pages/AffiliateDashboard';
import AffiliateMonthlyScripts from '../pages/AffiliateDashboard/affiliate-monthly-scripts';
import AgencyReceipts from '../pages/AgencyReceipts';
import ActivityLog from '../pages/ActivityLog/ActivityLog';
import OnboardingFlowSt from '../pages/OnBoardingSt';
import MeetingCalender from '../components/MeetingCalender';

import { LogOut, SetAuthState } from '../redux/slices/auth-slice.js';

const Index = () => {
  const dispatch = useDispatch();
  const {
    token,
    showSidebar,
    user
  } = useSelector(state => state.auth);

  useEffect(() => {
    const { userId } = user || {};
    if (userId) {
      localStorage.setItem('userId', userId);
    }
  }, [location.pathname]);

  setAuthToken(token);

  if (location.pathname === '/logout' && token) {
    dispatch(LogOut());
    dispatch(SetAuthState({field:'logout', value: true}));
  }

  return (
    <Switch>
      <Route exact path='/'>
        <Redirect to='/dashboard' />
      </Route>
      <PrivateRoute exact path='/dashboard' component={Dashboard} withLayout={LayoutNew} />
      <PrivateRoute exact path='/payments' component={Payments} withLayout={LayoutNew} />
      <PrivateRoute exact path='/data-sync' component={WaitingScreen} withLayout={WaitingScreen} />
      <PrivateRoute exact path='/jobs' component={Jobs} withLayout={LayoutNew} />
      <PrivateRoute exact path='/users' component={Users} withLayout={LayoutNew} />
      <PrivateRoute exact path='/invoices' component={Invoice} withLayout={LayoutNew} />
      <PrivateRoute exact path='/affiliate-manager-invoices' component={AffiliateManagerInvoice} withLayout={LayoutNew} />
      <PrivateRoute exact path='/case-stats' component={Stats} withLayout={LayoutNew} />
      <PrivateRoute exact path='/invoice/:id' component={InvoiceDetails} withLayout={LayoutNew} />
      <PrivateRoute exact path='/shipments' component={ShipmentsFba} withLayout={LayoutNew} />
      <PrivateRoute exact path='/affiliate-manager' component={AffiliateManager} withLayout={LayoutNew} />
      <PrivateRoute exact path='/fulfillment-fees' component={FulfillmentFees} withLayout={LayoutNew} />
      <PrivateRoute exact path='/affiliate-users' component={AffiliateDashboard} withLayout={LayoutNew} />
      <PrivateRoute exact path='/affiliate-monthly-scripts' component={AffiliateMonthlyScripts} withLayout={LayoutNew} />
      <PrivateRoute exact path='/agency-receipts' component={AgencyReceipts} withLayout={LayoutNew}/>
      <PrivateRoute exact path='/activity-logs' component={ActivityLog} withLayout={LayoutNew}/>
      <PrivateRoute exact path='/schedule-a-call' component={MeetingCalender} withLayout={LayoutNew}/>
      {/* <PrivateRoute exact path='/invoices-details' component={InvoiceDetails} withLayout={Layout} /> */}
      <PrivateRoute
        exact
        path='/data-gathering'
        component={DataGathering}
        withLayout={DataGathering}/>
      <PrivateRoute
        exact
        path='/auth/sp-api'
        component={OnboardingFlowSt}
        withLayout={
          showSidebar
            ? LayoutNew
            : OnboardingFlowSt
        } />
      <PrivateRoute
        exact
        path='/invite-user'
        component={SellerCentralPermission}
        withLayout={
          showSidebar
            ? LayoutNew
            : SellerCentralPermission
        } />
      <PrivateRoute
        exact
        path='/permissions'
        component={Permissions}
        withLayout={
          showSidebar
            ? LayoutNew
            : Permissions
        } />
      <PrivateRoute
        exact
        path='/waiting-screen'
        component={ThankyouStep}
        withLayout={
          showSidebar
            ? LayoutNew
            : ThankyouStep
        } />
      <PrivateRoute
        exact
        path='/store-success'
        component={StoreSuccess}
        withLayout={
          showSidebar
            ? LayoutNew
            : StoreSuccess
        }  />
      <PrivateRoute exact path='/setting/edit-store' component={EditStore} withLayout={LayoutNew} />
      <PrivateRoute exact path='/setting/payment' component={AddCard} withLayout={LayoutNew} />
      <PrivateRoute exact path='/profile' component={Profile} withLayout={LayoutNew} />
      <PrivateRoute exact path='/stores' component={Stores} withLayout={LayoutNew} />
      <PrivateRoute exact path='/setting/stores' component={Stores} withLayout={LayoutNew} />
      <Route path='/not-found' component={PageNotFound} />
      <Redirect from='*' to='/' />
    </Switch>
  )
}

export default Index;
