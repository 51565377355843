import React, {
  useState,
  useEffect
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import SearchBar from "../../components/SearchInput";
import Button from "../../components/Button/index";
import Table from "../../components/Table/table";
import Pagination from "../../components/Pagination/pagination";
import Notification from '../../components/notifications/notification';
import Spin from "../../components/Spin/Index";

import { AffiliateDashboardWrapper } from "./style";

import {
  GetAffiliateUsersData,
  SetAffiliateState
} from '../../redux/slices/affiliate-slice';

const AffiliateDashboard = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { user } = useSelector(state => state.auth);
  const {
    loading,
    success,
    totalAffiliateUsers,
    totalEarnings,
    affiliateLink,
    affiliateUsersData
  } = useSelector(state => state.affiliate);

  const [searchKeyword, setSearchKeyword] = useState('');
  const [pageNumber, setPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState(25);

  const copyToClipboard = (text) => {
    Notification({
      type: "success",
      title: "Copied !!!"
    });

    return navigator.clipboard
      .writeText(text)
      .then(t => true)
      .catch(e => false);
  };

  const handlePageChangeLeft = () => {
    if (pageNumber - 1 > 0) setPageNumber(pageNumber - 1);
    else setPageNumber(1);
  };

  const handlePageChangeRight = () => {
    const totalPages = Math.ceil(totalAffiliateUsers / pageLimit)
    if (Number(pageNumber) + 1 < totalPages) setPageNumber(Number(pageNumber) + 1);
    else setPageNumber(totalPages > 0 ? totalPages : 1);
  };

  const handleLimitChange = (e) => {
    setPageNumber(1);
    setPageLimit(e.value);
  };

  const handlePageNumberChange = (e) => {
    const totalPages = Math.ceil(totalAffiliateUsers / pageLimit)
    if (e.key == "Enter") {
      if (e.target.value < 1 || !e.target.value) {
        setPageNumber(1);
      } else if (e.target.value > totalPages) {
        setPageNumber(totalPages)
      } else setPageNumber(e.target.value)
    }
  };

  useEffect(() => {
    dispatch(GetAffiliateUsersData({
      searchKeyword,
      limit: pageLimit,
      skip: (pageNumber - 1) * pageLimit,
    }));
  }, []);

  useEffect(() => {
    dispatch(GetAffiliateUsersData({
      searchKeyword,
      limit: pageLimit,
      skip: (pageNumber - 1) * pageLimit,
    }));
  }, [searchKeyword, pageLimit, pageNumber]);

  return (
    <AffiliateDashboardWrapper>
      <div className="page-info-wrapper">
        <p>
          <span>Hello,</span> {user?.email} <span>Welcome to your Affiliate dashboard</span>
        </p>
      </div>
      <div className="page-header">
        <SearchBar placeholder="Search" onChange={(e) => setSearchKeyword(e.target.value)}/>
        <div className="buttons-wrapper">
          <Button CopyIcon text="Affiliate Link" onClick={() => copyToClipboard(affiliateLink)}></Button>
          <Button
            text={
              <span>
                Total Earnings: <span>{`€${totalEarnings}`}</span>
              </span>
            }
          ></Button>
          <Button Eye text="View Monthly Receipts" onClick={() => history.push('affiliate-monthly-scripts')}></Button>
        </div>
      </div>
      <div className="pt-3 table-responsive">
        <Table
        className="table-fd-wrapper table-fixed"
          tableHeader={
            <tr>
              <th>Name</th>
              <th>Store Name</th>
              <th>Commission%</th>
              <th>Status</th>
              <th>Monthly Commission Earned</th>
              <th>Lifetime Commission</th>
            </tr>
          }
          tableBody={
            <>
            {!loading && !affiliateUsersData.length ? (
                <div className='error error-wrapper'>
                  <h1>No Data Available</h1>
                  </div>
            ) : null}
            {loading ? <tr><td><Spin /></td></tr> : affiliateUsersData.map((item, index) => (
              <tr>
              <td>{item.name}</td>
              <td>{item.storeName || 'N/A'}</td>
              <td>{item.commission}%</td>
              <td className={item.status === 'Active' ? 'table-active-batch' : 'table-inactive-batch'}>{item.status}</td>
              <td>€{item.monthlyCommission}</td>
              <td>€{item.lifeTimeCommission}</td>
            </tr>
            ))}
            </>
          }
        />
        <Pagination
          className="affiliate-pagination-wrapper"
          pageLimit={pageLimit}
          total={totalAffiliateUsers}
          pageNumber={pageNumber}
          totalPages={Math.ceil(totalAffiliateUsers / pageLimit)}
          handlePageChangeLeft={handlePageChangeLeft}
          handlePageChangeRight={handlePageChangeRight}
          handleLimitChange={handleLimitChange}
          handlePageNumberChange={handlePageNumberChange}
        />
        </div>
    </AffiliateDashboardWrapper>
  );
};

export default AffiliateDashboard;
