import Styled from 'styled-components';

const DatePickerWrapper = Styled.div`
  .calender-input{
    .date-picker{
   position: absolute;
   right: 3px;
   z-index: 99;
   box-shadow: 0 0 10px #ccc;
   background-color: white
   }
}
`;
export { DatePickerWrapper };
