import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { axiosBaseUrl } from '../../config/axios-configuration';

import SaveSentryLog from "../../helpers/sentry-log";

const axios = axiosBaseUrl();

export const GetAutomationLogs = createAsyncThunk(
  'automation-logs/getAutomationLogs',
   async (data, { rejectWithValue }) => {
     try {
       const response = await axios.get('/automation-logs/get-automation-logs', {
        params: {
          ...data
        }
       });
       return response.data;
     } catch (err) {
       SaveSentryLog(new Error(err), 'GetAutomationLogsError');
       if (err.response && err.response.data) {
         return rejectWithValue({ 
           err: {
             message: err.response.data.message,
             status: err.response.status
           }
         });
       }
       return rejectWithValue({
         err: {
           message: 'Network Error',
           status: 502
         }
       });
     }
   }
);

export const GetAutomationLogsSummary =  createAsyncThunk(
  'automation-logs/getAutomationLogsSummary',
   async (data, { rejectWithValue }) => {
     try {      
       const response = await axios.get('/automation-logs/get-automation-logs-summary', {
        params: {
          ...data
        }
       });
       return response.data;
     } catch (err) {
       SaveSentryLog(new Error(err), 'GetAutomationLogsSummaryError');
       if (err.response && err.response.data) {
         return rejectWithValue({ 
           err: {
             message: err.response.data.message,
             status: err.response.status
           }
         });
       }
       return rejectWithValue({
         err: {
           message: 'Network Error',
           status: 502
         }
       });
     }
   }
);

const automation = createSlice({
  name: 'automation',
  initialState: {
    error: '',
    loading: '',
    message: '',
    success: false,
    storeSuccess: false,
    total: 0,
    totalStores: 0,
    automationlogs: [],
    totalCaseClaimed: 0,
    totalRejected: 0,
    totalCasesOpen: 0
  },
  reducers: {
    SetAutomationState(state, { payload: { field, value } }) {
      state[field] = value;
    }
  },
  extraReducers: {
    [GetAutomationLogs.pending]: (state, action)=> ({
      ...state,
      loading: true
    }),
    [GetAutomationLogs.fulfilled]: (state, action)=> ({
      ...state,
      success: true,
      loading: false,
      automationlogs: action.payload.automationlogs,
      total: action.payload.total
    }),
    [GetAutomationLogs.rejected]: (state, action) => ({
      ...state,
      success: false,
      loading: false,
      error: action.payload.err.message
    }),
    [GetAutomationLogsSummary.pending]: (state, action)=> ({
      ...state,
      loading: true
    }),
    [GetAutomationLogsSummary.fulfilled]: (state, action)=> ({
      ...state,
      success: true,
      loading: false,
      totalRejected: action.payload.totalRejected,
      totalCaseClaimed: action.payload.totalCaseClaimed,
      totalCasesOpen: action.payload.totalCount
    }),
    [GetAutomationLogsSummary.rejected]: (state, action) => ({
      ...state,
      success: false,
      loading: false,
      error: action.payload.err.message
    }),
  }
});

const { reducer, actions } = automation;

export const { SetAutomationState } = actions;

export default reducer;
