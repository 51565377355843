import styled from "styled-components";

const CardWrapper = styled.div`
  .marketplace {
    border-radius: 12px;
    border: 1px solid #e6e6e6;
    padding: ${({ theme }) => theme["base-padding"]};
    background: url("https://sale-support-logos.s3.us-west-1.amazonaws.com/light-bg.svg"),
      #f8f8f8;
    background-size: 100%;
    background-repeat: no-repeat;
    cursor: pointer;
    @media (max-width: 1600px) {
      padding: 14px;
    }

    .card-number {
      color: ${({ theme }) => theme["text-primary"]};
    }
    .switch {
      background: ${({ theme }) => theme["success-color"]};
      color: ${({ theme }) => theme["white-color"]};
      display: flex;
      align-items: center;
      padding: 0px 20px;
      border-radius: 50px;
      font-size: ${({ theme }) => theme["base-font-size-small"]};
      font-weight: ${({ theme }) => theme["bold-weight"]};
    }
    p {
      color: ${({ theme }) => theme["text-primary"]};
      font-size: ${({ theme }) => theme["medium-font-size"]};
    }
    .arrow-badge {
      grid-column-gap: 12px;
      .switch-badge {
        color: ${({ theme }) => theme["white-color"]};
        font-size: 1.125rem;
        padding: 1px 12px;
        border-radius: 50px;
        &.disabled {
          background-color: ${({ theme }) => theme["orange_peel"]};
        }
      }
    }
    .error {
      color: red;
      font-size: 12px;
    }
    .fba-details {
      flex: 1;
      .marketplace-detail {
        grid-row-gap: 16px;
        flex: 1;
        .info {
          grid-row-gap: 5px;
          align-items: flex-start;
          span {
            font-size: ${({ theme }) => theme["font-size-small"]};
            color: ${({ theme }) => theme["secondary-color"]};
          }
          .badge {
            background: ${({ theme }) => theme["dusty_grey"]};
            color: ${({ theme }) => theme["white-color"]};
            border-radius: 50px;
            padding: 2px 20px;
          }
        }
      }
    }
    &.enabled {
      background: url("https://sale-support-logos.s3.us-west-1.amazonaws.com/blue-bg.svg"),
        linear-gradient(135deg, #1565d8 0%, #090c53 100%);
      .title,
      .card-number {
        color: ${({ theme }) => theme["white-color"]};
      }

      .check-svg {
        width: 16px;
        height: 16px;
        border-radius: 50px;
        position: relative;
        background: ${({ theme }) => theme["success-color"]};
        svg {
          color: ${({ theme }) => theme["white-color"]};
          position: absolute;
        }
      }
      p {
        color: ${({ theme }) => theme["grey-secondary"]};
        font-size: ${({ theme }) => theme["medium-font-size"]};
      }
    }
  }
  .mt-36 {
    margin-top: 36px;
    margin-bottom: 36px;
  }
`;
const StripeInput = styled.div`
  margin-bottom: 12px;
  label {
    margin-bottom: 6px;
    color: ${({ theme }) => theme["label-color"]};
    font-size: ${({ theme }) => theme["xs-small-size"]};
    font-weight: 300;
    line-height: 12.1px;
  }
  input {
    border: 1px solid ${({ theme }) => theme["secondary-variant"]};
    border-radius: 1.5px;
    color: ${({ theme }) => theme["body-text"]};
    font-size: ${({ theme }) => theme["xs-small-size"]};
    max-height: 28px;
    padding: 8px 10px;
    min-height: 2px;
    &::-webkit-input-placeholder {
      /* Edge */
      letter-spacing: 1px;
      color: ${({ theme }) => theme["placeholder"]};
    }
    &:focus {
      border-color: ${({ theme }) => theme["secondary-variant"]};
      box-shadow: none;
      color: ${({ theme }) => theme["body-text"]};
    }

    &::placeholder {
      font-size: ${({ theme }) => theme["xs-small-size"]};
      color: ${({ theme }) => theme["placeholder"]};
    }
  }
  .StripeElement {
    border: 1px solid ${({ theme }) => theme["secondary-variant"]};
    color: ${({ theme }) => theme["body-text"]};
    font-size: ${({ theme }) => theme["xs-small-size"]};
    max-height: 28px;
    padding: 8px 10px;
    min-height: 2px;
    border-radius: 14px;
    &.cardnumber-stripe{
        padding-left:13px;
        background-color: #FFFFFF
        -webkit-text-fill-color: #FFFFF
    }
    .ElementsApp {
      font-size: 10px;
    }
    &.StripeElement--webkit-autofill{
      background-color: white !important;
    }
    &.StripeElement--invalid{

    }
  }
  .input-with-icon {
    position: relative;
    .StripeElement {
      padding-right: 22px;
    }
    .input-icon {
      position: absolute;
      right: 8px;
      top: 7px;
      cursor: pointer;
    }
    &.input-icon-left {
        .StripeElement {
          padding-left: 38px !important;
          padding-right: 10px !important;
        }
        .input-icon {
        }
    }
  }
`;
export { CardWrapper, StripeInput };
