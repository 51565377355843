import React, { useState, useEffect } from "react";
import { useDispatch,
  useSelector
} from "react-redux";
import {
  BsArrowLeftShort,
} from "react-icons/bs";

import Pagination from "../../components/Pagination/pagination";
import Spin from "../../components/Spin/Index";
import Table from "../../components/Table/table";

import { CURRENCY_SYMBOLS } from '../../constants';
import {
  GetAffiliateManagerUsersDetail,
  SetAffiliateState
} from '../../redux/slices/affiliate-slice';

const Index = ({
  affiliateManager,
  detailsModal,
  handleClick
}) => {
  const dispatch = useDispatch();
  const {
    loading,
    success,
    totalAffiliateUsers,
    affiliateUsersData,
    totalAffiliateCommission,
    totalAgencyCommission
  } = useSelector(state => state.affiliate);
  const [pageNumber, setPageNumber] = useState(1);
  const [invoiceBody, setInvoiceBody] = useState([]);

  const handlePageLimit = (e) => {
    setPageNumber(1)
    setFilter({ ...filter, skip: 0, limit: e.value });
  };

  const [filter, setFilter] = useState({ skip: 0, limit: 25 });

  useEffect(() => {
    return () => {
      dispatch(SetAffiliateState({ field: 'totalAffiliateUsers', value: 0 }))
      dispatch(SetAffiliateState({ field: 'affiliateUsersData', value: [] }))
      dispatch(SetAffiliateState({ field: 'totalAffiliateCommission', value: 0 }))
      dispatch(SetAffiliateState({ field: 'totalAgencyCommission', value: 0 }))
    }
  }, [])

  const handlePageNumberChange = (e) => {
    if (e.key == "Enter") {
      if (e.target.value < 1 || !e.target.value) {
        setPageNumber(1);
      } else if (e.target.value > Math.ceil(totalAffiliateUsers / filter.limit)) {
        setPageNumber(Math.ceil(totalAffiliateUsers / filter.limit) -1)
        setFilter({ ...filter, skip: filter.limit  * (Number(e.target.value) - 1) })
      } else {
        setPageNumber(Number(e.target.value)),
        setFilter({ ...filter, skip: (Number(e.target.value)-1) * filter.limit })
      }
    }
  }

  const handlePageChangeLeft = () => {
    if (pageNumber - 1 > 0) {
      setPageNumber(pageNumber - 1);
      setFilter({ ...filter, skip: filter.skip - filter.limit })
    }
    else setPageNumber(1);
  }

  const handlePageChangeRight = () => {
    if (pageNumber + 1 <= Math.ceil(totalAffiliateUsers / filter.limit)) {
      setFilter({ ...filter, skip: filter.skip + filter.limit })
      setPageNumber(pageNumber + 1)
    }
  }

  useEffect(() => {
    if (detailsModal == true) {
      dispatch(GetAffiliateManagerUsersDetail({ affiliateManagerId: affiliateManager._id, skip: (pageNumber-1) * filter.limit, limit: filter.limit }))
    }
  }, [affiliateManager, detailsModal, filter]);

  useEffect(() => {
    if (success && affiliateUsersData?.length > 0) {
      const affiliateUsersDataBody =
        affiliateUsersData
          .map((item, i) => {
            return (
              <tr key={i} style={item.refunded ? { backgroundColor: '#FFFDF2' } : null}>
                <td>{item.name}</td>
                <td>{item.email}</td>
                <td>{CURRENCY_SYMBOLS[item.currency]}{Number(item.affialiateCommission).toFixed(2)}</td>
                <td>{CURRENCY_SYMBOLS[item.currency]}{Number(item.agencyCommision).toFixed(2)}</td>
              </tr>
            );

          });
      setInvoiceBody(affiliateUsersDataBody);
    } else setInvoiceBody([]);
  }, [affiliateUsersData, pageNumber]);

  return (
    <>
      <div className="p-4">
        <div className="d-flex align-items-center grid-column-gap-10">
          <BsArrowLeftShort
            onClick={() => { handleClick() }}
            className="icon-color cursor-pointer"
          />
          <h3 className="mb-0">Affiliate Manager Detail</h3>
        </div>
        <div className="mt-4 invoice-status p-3 d-flex justify-content-between">
          <div className="d-flex flex-column">
            <span className="text-primary weight-bold fs-10">
              Affiliate Manager Email: <span className="color-black">{affiliateManager.email}</span>
            </span>
            <span className="text-primary weight-bold fs-10">
              Affiliate Manager Name: <span className="color-black">{affiliateManager.name}</span>
            </span>
          </div>
          <div className="d-flex flex-column">
            <span className="text-primary weight-bold fs-12">Total Agency Commision</span>
            <span className="fs-14 d-block text-primary">  {affiliateUsersData?.length? CURRENCY_SYMBOLS[affiliateUsersData[0].currency]: ''}{Number(totalAgencyCommission).toFixed(2)} </span>
          </div>
          <div className="d-flex flex-column">
            <span className="text-primary weight-bold fs-12">Total Affiliate Commision</span>
            <span className="fs-14 d-block text-primary">  {affiliateUsersData?.length? CURRENCY_SYMBOLS[affiliateUsersData[0].currency]: ''}{Number(totalAffiliateCommission).toFixed(2)} </span>
          </div>
        </div>
        <div className="pt-4">
          <Table
            viewHeight="case-stat-table"
            tableHeader={
              <tr>
                <th>User Name</th>
                <th>User Email</th>
                <th>Affialiate Commission</th>
                <th>Agency Commision</th>
              </tr>
            }
            tableBody={
              <>
                {(!loading && !affiliateUsersData?.length && success) ? (
                  <tr><td>
                    <div className='error'>
                      <h1>No Data Available</h1>
                    </div>
                  </td>
                  </tr>
                ) : loading ? <tr><td><Spin /></td></tr> : invoiceBody}
              </>
            }
          />
          <Pagination
            pageLimit={filter.limit}
            total={totalAffiliateUsers}
            pageNumber={pageNumber}
            totalPages={Math.ceil(totalAffiliateUsers / filter.limit)}
            handlePageChangeLeft={handlePageChangeLeft}
            handlePageChangeRight={handlePageChangeRight}
            handleLimitChange={handlePageLimit}
            handlePageNumberChange={handlePageNumberChange}
          />
        </div>
      </div>

    </>
  );
};

export default Index;
