import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import reportWebVitals from './report-web-vitals';

import App from './app';

import Store from './redux/store';

import { APP_ICONS, APP_TITLES } from './constants';

let persistor = persistStore(Store);

import { SELLER_TERMINAL_SENTRY_DSN } from './constants';

const utils = () => {
  const host = window.location.hostname;
  const favicon = APP_ICONS[host];
  let link = document.createElement('link');
  link.rel = 'icon';
  link.type="image/png"
  document.getElementsByTagName('head')[0].appendChild(link);
  link.href = favicon;

  let title = document.createElement('title');
  title.innerText = APP_TITLES[host];
  document.getElementsByTagName('head')[0].appendChild(title);
};

utils();

if (process.env.NODE_ENV === 'production') {
  Sentry.setUser({ id: localStorage.getItem('userId') });
  Sentry.init({
    dsn: SELLER_TERMINAL_SENTRY_DSN
  });
}

ReactDOM.render(
  <Provider store={Store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

reportWebVitals();
