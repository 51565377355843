import styled from "styled-components";

const DropdownStyle = styled.div`
  .ant-dropdown-trigger {
    .ant-space-item {
      &:nth-child(2) {
        display: none;
      }
    }
    .ant-space-item {
      width: 36px;
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: var(--Main-System-10px, 10px);
      background: #e8e8e9;
      p {
        margin: 0;
        color: #666b70;
        font-family: Lato;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 18px;
      }
    }
  }
  
`;
export { DropdownStyle };
