import Styled from "styled-components";

const GridWrapper = Styled.div`
.QB-dataTable{
    height: ${(props) => `calc(100vh - ${props.height || "300px"})`};
    /* padding-top: ${(props) => props.paddingTop ? props.paddingTop: "16px"}; */
    font-family: 'Inter', sans-serif;
}
.ag-row-odd {
  background-color: ${({ theme }) => theme["light-color"]};
}
.QB-dataTable .ag-root-wrapper{
 border: 0;
}
.QB-dataTable .ag-root{
    border: none;
}
.QB-dataTable .ag-header{
    border-bottom-color: #4E5969;
    background-color: ${({ theme }) => theme["light-color"]};
    .ag-header-row {
      height: 40px !important;
    }
}
.ag-root .ag-header {
    display: ${(props) => props.header && "none"};
}
.ag-header-cell .ag-labeled {
    padding-right: 24px;
}
.ag-pivot-off {
    height: 40px !important;
    min-height: 40px !important;
}
.QB-dataTable .ag-pinned-left-header {
  border-right: none;
}
.QB-dataTable .ag-pinned-right-header {
  border-left: none;
}
.QB-dataTable .ag-header-cell{
    color:${({ theme }) => theme["on-surface"]};
    font-weight: ${({ theme }) => theme["bold-weight"]};
    line-height: 16px;
    padding: 0px 12px;
}
.QB-dataTable .ag-header-cell::after{
    width: 0;
}
.QB-dataTable .ag-center-cols-viewport .ag-cell-value{
    line-height: 14px;
    color: ${({ theme }) => theme["body-text-v2"]};
    padding: 0 12px;
    display: flex;
    align-items: center;
}
.QB-dataTable  .ag-row-even,
.QB-dataTable  .ag-row-odd,
.QB-dataTable  .ag-row{
    border-bottom: 1px solid ${({ theme }) => theme["table-border-color"]};
    color: ${({ theme }) => theme["body-text-v2"]};
    line-height: 14px;
    padding: 0 12px;
    .ag-cell {
      display: flex;
      align-items: center;
      border-top: none;
    }
}
.QB-dataTable .ag-cell.ag-cell-last-left-pinned, .ag-cell.ag-cell-first-right-pinned {
  border-right-color: ${({ theme }) => theme["table-border-color"]};
}
.QB-dataTable .ag-cell.ag-cell-first-right-pinned {
  border-left-color: ${({ theme }) => theme["table-border-color"]};
}
`;
export { GridWrapper };
