import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Button } from "antd";
import { FiLogOut } from "react-icons/fi";
import { TbArrowBackUp } from "react-icons/tb";

import ButtonSt from "../../components/ButtonSt";
import ChatBot from "../../components/Chat";
import DropdownMenu from "../../components/Dropdown";

import { APP_DOMAINS } from "../../constants";

import Logo from "../../assets/images/st-logo.svg";
import sellerRepay from "../../assets/images/logo-sp.svg";

import { HeaderStWrapper, HeaderDropDownStyleWrapper } from "./style";

import { LogOut, SetAuthState } from "../../redux/slices/auth-slice";
import ExitImpersonation from "../../components/Impersonate";

const HeaderSt = () => {
  const hostName = window.location.hostname;
  const dispatch = useDispatch();
  const history = useHistory();
  const { user, inAppOnboarding, location, inImpersonation } = useSelector((state) => state.auth);
  const initials = user?.name?.match(/\b\w/g)?.join('');

  const backToApp = () => {
    const returnPath = location;
    dispatch(SetAuthState({ field: 'showSidebar', value: true }));
    dispatch(SetAuthState({ field: 'inAppOnboarding', value: false }));
    dispatch(SetAuthState({ field: 'location', value: '' }));

    history.push({
      pathname: returnPath || '/dashboard'
    });
  }

  const dropdownMenuTitle = () => {
    return (
      <HeaderDropDownStyleWrapper>
        <div className="title_wrapper">
          <span>{initials?.toUpperCase() || 'YOU'}</span>
          <div className="right_side_text">
            <p>{user?.name || 'Your Name'}</p>
            <p>{user?.email || 'Your Email'}</p>
          </div>
        </div>
      </HeaderDropDownStyleWrapper>
    );
  };

  const menuItems = [
    {
      label: dropdownMenuTitle(),
      key: "0",
    },
    {
      label: (
        <HeaderDropDownStyleWrapper>
          {inImpersonation ? <ExitImpersonation styles={{ iconSize: '6%' }} /> : 
          <Button onClick={() => dispatch(LogOut())} icon={<FiLogOut />}>Log Out</Button>
      }
        </HeaderDropDownStyleWrapper>
      ),
      key: "1",
    },
  ];

  return (
    <>
      <ChatBot route='private'/>
      <HeaderStWrapper>
        <img
          className={
            hostName === APP_DOMAINS.sellerTerminal
              ? " seller-terminal"
              : "seller-repay"
          }
          src={hostName === APP_DOMAINS.sellerTerminal ? Logo : sellerRepay}
          alt="no-logo"
        />
       
        {
          inAppOnboarding ? (
            <ButtonSt onClick={backToApp} className="back-button" outline><TbArrowBackUp/>Back To App</ButtonSt>)
          : (
            <div className="d-flex">
              <DropdownMenu
                overlayClassName="header_dropdown_wrapper"
                menuItems={menuItems}
                triggerType="click"
                buttonText={<p>{initials?.toUpperCase() || 'YOU'}</p>}
              />
            </div>
          )
        }
      </HeaderStWrapper>
    </>
  );
};

export default HeaderSt;
