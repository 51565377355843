import React from "react";

import ProgressSt from "../../../components/ProgressSt";

import SearchIcon from "../../../assets/images/exploration-icon.svg";
import { EligibleWrapper } from "./style";

const Eligible = ({ progress = 0 }) => {
  return (
    <EligibleWrapper>
      <div className="card_ui_overlay">
        <div className="card_ui_wrapper">
          <img src={SearchIcon} />
          <h4>We are analysing your data</h4>
          <p>
            Processing reimbursement data for
            your account.
          </p>
          <ProgressSt percent={progress} />
          <p>
            This process can take some time. We'll notify you by email once we're finished.
          </p>
        </div>
      </div>
    </EligibleWrapper>
  );
};

export default Eligible;
