import React from "react";
import {SpinWrapper} from "./style"

const Index = () => {
  return (
    <SpinWrapper>
      <div className="container-spin">
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
      </div>
    </SpinWrapper>
  );
};
export default Index;