import styled from "styled-components";

const ProgressStWrapper = styled.div`
width:100%;
display: flex;
span.ant-progress-text {
    display: none;
}
.ant-progress-bg {
    position: relative;
    border-radius: 100px;
    transition: all 0.4s cubic-bezier(0.08, 0.82, 0.17, 1) 0s;
    background:${(props) => props.theme["new-primary"]};
}
.ant-progress-inner {
    background: var(--Placeholder-Border, #DBDFE1);
}
`;
export { ProgressStWrapper }