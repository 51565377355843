import React from "react";
import RoundCircle from "../../assets/images/round-bg.svg";
import GreenCheck from "../../assets/images/green-check.svg";
import { CommissionCardWrapper } from "./style";
import { useSelector } from "react-redux";

const CommissionCard = () => {
  const { user } = useSelector((state) => state.auth);

  return (
    <CommissionCardWrapper>
      <div className="card-wrapper-commission">
        <img src={RoundCircle} />
        <h1>Our commission rate is {user?.commissionDiscount || 20}%</h1>
      </div>
      <div className="bar-row-wrapper">
        <div className="card-items-st">
          <img src={GreenCheck} />
          <p>We only charge for successful reimbursements</p>
        </div>
        <div className="card-items-st">
          <img src={GreenCheck} />
          <p>We only charge for successful reimbursements</p>
        </div>
        <div className="card-items-st">
          <img src={GreenCheck} />
          <p>We only charge for successful reimbursements</p>
        </div>
      </div>
    </CommissionCardWrapper>
  );
};

export default CommissionCard;
