import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Spin } from "antd";
import crypto from 'crypto';

import Alert from "../../../components/alert";
import Button from "../../../components/ButtonSt"
import Notification from "../../../components/notifications/notification";

import { APP_DOMAINS, APP_IDS, REDIRECT_URIS } from "../../../constants";

import BulbIcon from "../../../assets/images/bulb-icon.svg";
import Amazon from "../../../assets/images/amazon-st.svg";
import ApiPartner from "../../../assets/images/partner-logo.svg";
import Icon from "../../../assets/images/step-1-icon.svg";
import IconSp from "../../../assets/images/small-sp-logo.svg";
import ArrowIcon from "../../../assets/images/arrow-curve.svg";
import HomeIcon from "../../../assets/images/home-st.svg";
import AmazonBar from "../../../assets/images/amazon-st-1.svg";

import { StepOneWrapper } from "./style";

import { CreateStore, SetStoreState } from "../../../redux/slices/store-slice";
import { SetAuthState } from "../../../redux/slices/auth-slice";

const StepOne = () => {
  const hostName = window.location.hostname;
  const [err, setError] = useState('');
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    state,
    storeSuccess,
    store,
    error,
    loading
  } = useSelector((state) => state.store);

  const { 
    storesCount,
    amazonUrl,
    amazonOnbarding
    } = useSelector((state) => state.auth);

  useEffect(() => {
    const params = new URLSearchParams(window.location?.search);
    const authCode = params.get('spapi_oauth_code');
    const sellerId = params.get('selling_partner_id');
    const spApiState = params.get('state');

    if (location.pathname === '/auth/sp-api' && authCode && sellerId && spApiState) {
      console.log({
        authCode,
        sellerId,
        spApiState,
        state
      });

      if (sellerId && spApiState === state) {
        dispatch(CreateStore({
          authCode: authCode,
          sellerId: sellerId
        }));
      } else {
        dispatch(SetStoreState({ field: 'error', value: 'Error in store creation' }))
      }
    }
  }, []);

  useEffect(() => {
    if (storeSuccess && store) {
      dispatch(SetAuthState({ field: 'storesCount', value: storesCount + 1 }));
      dispatch(SetAuthState({ field: 'storeStatus', value: store.status }));
      dispatch(SetAuthState({ field: 'storeId', value: store._id }));
      dispatch(SetAuthState({ field: 'connectedStore', value: store._id }));
      dispatch(SetStoreState({ field: 'storeSuccess', value: false }));
      dispatch(SetStoreState({ field: 'state', value: '' }));
    }
  }, [storeSuccess]);

  useEffect(() => {
    if (error === 'Network Error') {
      Notification({
        type: "error",
        title: "Error",
        description: error
      });
    } else if (error) {
      setError(error);
      dispatch(SetStoreState({ field: 'error', value: '' }));
      dispatch(SetStoreState({ field: 'state', value: '' }));
      history.push('/auth/sp-api');
    }
  }, [error])

  useEffect(() => {
    if (amazonOnbarding !== null && amazonUrl) {
      dispatch(SetAuthState({ field:'amazonOnbarding', value: null }));
      dispatch(SetAuthState({ field: 'amazonUrl', value: '' }));
      handleRegisterAccount();
    }
  }, [amazonOnbarding]);

  const handleRegisterAccount = async () => {
    const state = crypto.randomBytes(20).toString("hex");

    dispatch(SetStoreState({ field: 'state', value: state }));

    const url = `https://sellercentral-europe.amazon.com/authorization/select-account?returnTo=/apps/authorize/consent?state=${state}%26application_id=${APP_IDS[hostName]}&redirect_uri=${REDIRECT_URIS[hostName]}`
    window.open(url, "_self");
  };

  return (
    <StepOneWrapper>
      {
        err && (
          <Alert
            hide={setError}
            errorText={err}
            resolve={handleRegisterAccount}
          />
        )
      }
      {
        (loading || amazonOnbarding) && (
          <StepOneWrapper>
            <div className="d-flex position-absolute justify-content-center align-items-center loader-wrapper">
              <Spin size="large" />
            </div>
          </StepOneWrapper>
        )
      }
      <div className="bar_overlay_wrapper">
        <div className="left_side_data">
          <img src={BulbIcon} />
          <div className="left_side_content">
            <h6>
              Maximise your FBA refunds and unlock your full reimbursement potential
            </h6>
            <p>
              Connect your Amazon Seller account to ensure you don’t miss any
              refunds or reimbursements.
            </p>
          </div>
        </div>
        <div className="right_side_data">
          <p>Connect With</p>
          <div className="right_side_content">
            <img src={Amazon} />
            <img src={ApiPartner} />
          </div>
        </div>
      </div>
      <div className="amazon_overlay_bar">
        <div className="header_overlay_wrapper">
          <div className="amazon_overlay_item">
            <h6>Select an Account and Marketplace</h6>
            <p>
              Select your merchant and marketplace to begin. You can adjust this
              choice at any time using the top navigation bar.
            </p>
          </div>
          <div className="amazon_overlay_item">
            <img src={hostName === APP_DOMAINS.sellerTerminal ? Icon : IconSp} />
            <img src={ArrowIcon} />
            <img src={HomeIcon} />
          </div>
        </div>
        <img src={AmazonBar} />
      </div>
      <div className="bar_footer_wrapper">
        <div className="footer_item">
          <h5>Authorize your Store</h5>
          <p>
            Once logged into your Seller Central account, simply click the
            'Authorise' button below to enable access to your store data for the
            {hostName === APP_DOMAINS.sellerRepay? ' Seller Repay': ' Seller Terminal'} App.
          </p>
        </div>
        <Button onClick={handleRegisterAccount}>Authorise</Button>
      </div>
    </StepOneWrapper>
  );
};

export default StepOne;
