import styled from "styled-components";

const AlertWrapper = styled.div`
  display: flex;
  border-radius: 4px;
  border: 1px solid var(--Accent-Alert, #ff4d4f);
  background: #fff6f7;
  padding: 7px 12px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${props => props.marginBottom || '24px'};
  width: fit-content;
  .title-wrapper {
    display: flex;
    align-items: center;
    grid-gap: 8px;
    p {
      color: var(--Secondary, #151a26);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      margin: 0;
    }
    svg {
      font-size: 16px;
      color: #ff4d4f;
      cursor: auto;
    }
  }
  svg {
    font-size: 24px;
    color: #ff4d4f;
    cursor: pointer;
  }
  button {
    color: ${(props) => props.theme["new-primary"]};
    font-size: 12px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    border: none;
    background-color: transparent;
    border-bottom: 1px solid ${(props) => props.theme["new-primary"]} !important;
    border-radius: 0px;
    padding: 0px !important;
    height: 16px;
    &:hover {
      color: ${(props) => props.theme["new-primary"]};
      background-color: transparent;
    }
    &:focus {
      color: ${(props) => props.theme["new-primary"]};
      background-color: transparent;
    }
  }
`;
export { AlertWrapper };
