import { isEmpty } from "lodash";
import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  useStripe,
  useElements,
  Elements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import calendarIcon from "../../assets/icons/calendar.svg";
import lockIcon from "../../assets/icons/lock.svg";

import LabelInput from "../Inputs/label-input";
import Select from "../Select/index";
import Button from "../Button";
import Notification from '../notifications/notification';

import SaveSentryLog from '../../helpers/sentry-log';

import { StripeInput } from "../../components/PaymentCard/style";

import {
  CreateCustomer,
  SetStripeState,
  UpdateCustomer,
} from "../../redux/slices/stripe-slice";

import { SetAuthState } from "../../redux/slices/auth-slice";
import { GetUser } from "../../redux/slices/user-slice";

import { COUNTRIES, PUBLIC_KEYS } from "../../constants";

import { CardWrapper } from "./style";

const hostName = window.location.hostname;

const publishablekey = PUBLIC_KEYS[hostName];

const stripePromise = loadStripe(publishablekey);

const PaymentForm = (props) => {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();

  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [countries, setCountries] = useState([]);
  const [vatNumber, setVatNumber] = useState('');
  const [address, setAddress] = useState({
    line1: "",
    country: "",
  });
  const [addressError, setAddressError] = useState({
    line1Error: "",
    countryError: "",
  });
  const [cardValidationError, setCardValidationError] = useState({
    cvcError: "",
    dateError: "",
    numberError: "",
    cvcTouched: false,
    dateTouched: false,
    numberTouched: false,
  });

  const [selectedCountry, setSelectedCountry] = useState({});

  const { user: userData } = useSelector((state) => state.auth);
  const {
    success,
    customerCreated,
    customerUpdated,
    error,
    loading
  } = useSelector((state) => state.stripe);
  const { user } = useSelector((state) => state.user);

  useEffect(() => {
    if (error) {
      Notification({
        type: "error",
        title: "Error",
        description: error
      });

      SaveSentryLog(new Error(error), 'PaymentError');
      dispatch(SetStripeState({ field: "error", value: "" }));
      return;
    }
  }, [error]);

  useEffect(() => {
    dispatch(GetUser({ userId: userData._id }));
    const countriesData = COUNTRIES?.map((country, index) => {
      return {
        value: country.code,
        label: country.name,
      };
    });
    setCountries(countriesData);
  }, []);

  useEffect(() => {
    if (user.payment) {
      const country = COUNTRIES.find(
        (country, index) => country.code === user.payment?.address?.country
      );
      if (country) {
        const countryOption = {
          value: country.code,
          label: country.name,
        };
        setSelectedCountry(countryOption);
        setAddress(user.payment?.address);
        setName(user.payment?.name);
      }
      dispatch(
        SetAuthState({
          field: "user",
          value: { ...userData, payment: user.payment },
        })
      );
    }
  }, [user]);

  useEffect(() => {
    if (customerCreated || customerUpdated) {
      dispatch(GetUser({ userId: userData._id }));
      dispatch(SetStripeState({ field: "customerCreated", value: false }));
      dispatch(SetStripeState({ field: "customerUpdated", value: false }));
    }
  }, [customerCreated, customerUpdated]);

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      return;
    }
    const { cvcTouched, dateTouched, numberTouched } = cardValidationError;

    if (
      address.line1 === "" ||
      address.country === "" ||
      name === "" ||
      cvcTouched !== true ||
      dateTouched !== true ||
      numberTouched !== true
    ) {
      let line1Error = "";
      let countryError = "";
      let nameError = "";
      let cvcError = "";
      let numberError = "";
      let dateError = "";

      if (address.line1 === "") line1Error = "Billing address is required!";
      if (address.country === "") countryError = "Country is required!";
      if (name === "") nameError = "Name is Required!";
      if (cardValidationError.cvcError === "" && cvcTouched === false)
        cvcError = "Cvc is Requried!";
      else cvcError = cardValidationError.cvcError;
      if (cardValidationError.dateError === "" && dateTouched === false)
        dateError = "Date is Required!";
      else dateError = cardValidationError.dateError;
      if (cardValidationError.numberError === "" && numberTouched === false)
        numberError = "Card Number is Requried!";
      else numberError = cardValidationError.numberError;

      setAddressError({
        line1Error,
        countryError,
      });
      setNameError(nameError);

      setCardValidationError({
        ...cardValidationError,
        cvcError,
        numberError,
        dateError,
      });

      return;
    }

    const card = elements.getElement(CardNumberElement);

    try {
      const { token } = await stripe.createToken(card);

      if (token) {
        if (user.payment) {
          dispatch(
            UpdateCustomer({
              token,
              name,
              address,
              stripeUserId: user.payment.stripeUserId,
              vatNumber
            })
          );
        } else {
          dispatch(CreateCustomer({
            token,
            name,
            address,
            vatNumber
          }));
        }
      } else {
        dispatch(SetStripeState({ field: "error", value: "Enter the Valid Card Number!" }));
      }
    } catch (error) {
      dispatch(SetStripeState({ field: "error", value: error.message }));
    }
  };

  const handleAddress = (e, key) => {
    if (key === "line1") {
      let line1Error = "";
      setAddressError({
        ...addressError,
        line1Error,
      });
    }
    if (key === "country") {
      let countryError = "";
      setAddressError({
        ...addressError,
        countryError,
      });
      setSelectedCountry(e);
    }

    setAddress({
      ...address,
      [key]: e.value,
    });
  };

  useEffect(() => {
    if (props.modal && success && user.payment) {
      props.handleCloseModal();
    }
  }, [success, user]);

  const handleName = (value) => {
    const name = value.trim();

    if (name) {
      setNameError("");
    }
    setName(name);
  };

  const handleCardValidationError = (e) => {
    const { elementType, error, complete, empty } = e;

    let cvcTouched = false;
    let dateTouched = false;
    let numberTouched = false;

    let cvcError = "";
    let dateError = "";
    let numberError = "";

    if (elementType === "cardNumber") {
      if (!isEmpty(error)) {
        numberError = error.message;
        numberTouched = true;
      } else if (complete) numberTouched = true;
      else if (empty) numberTouched = false;
      setCardValidationError({
        ...cardValidationError,
        numberError: numberError,
        numberTouched,
      });
    } else if (elementType === "cardCvc") {
      if (!isEmpty(error)) {
        dateError = error.message;
        cvcTouched = true;
      } else if (complete) cvcTouched = true;
      else if (empty) cvcTouched = false;
      setCardValidationError({
        ...cardValidationError,
        cvcError: dateError,
        cvcTouched,
      });
    } else if (elementType === "cardExpiry") {
      if (!isEmpty(error)) {
        cvcError = error.message;
        dateTouched = true;
      } else if (complete) dateTouched = true;
      else if (empty) dateTouched = false;
      setCardValidationError({
        ...cardValidationError,
        dateError: cvcError,
        dateTouched,
      });
    }
  };

  const handleBlur = (e) => {
    const { name, value } = e;
    if (name === "name" && isEmpty(value)) {
      setNameError("Name is Required!");
    } else if (name === "billingAddress" && isEmpty(value)) {
      setAddressError({
        ...addressError,
        line1Error: "Billing address is required!",
      });
    }
  };

  const Stripe_Options = {
    style: {
      base: {
        iconColor: "#c4f0ff",
        color: "#494F51",
        fontWeight: 500,
        fontSize: "10px",
        fontSmoothing: "antialiased",
        "::placeholder": {
          color: "#C0C0C0",
        },
      },
      invalid: {
        iconColor: "#ffc7ee",
        color: "#ffc7ee",
      },
    },
  };

  const CardNumberOptions = {
    showIcon: true,
    placeholder: "Card Number",
    style: {
      base: {
        // iconColor: "#c4f0ff",
        color: "#494F51",
        fontWeight: 500,
        fontSize: "10px",
        fontSmoothing: "antialiased",
        "::placeholder": {
          color: "#C0C0C0",
        },
      },
      invalid: {
        iconColor: "#ffc7ee",
        color: "#ffc7ee",
      },
    },
  };

  return (
    <>
      <div className="form-content">
        <div className='mb-2'>
          <LabelInput
            className='mb-0'
            name="text"
            label="Cardholder Name*"
            placeholder="Full Name"
            onChange={(e) => handleName(e.target.value)}
          />
          <span style={{ color: "red", fontSize: "12px" }}>
            {nameError}{" "}
          </span>
        </div>
        <StripeInput>
          <Form.Label className="d-none">Card Number</Form.Label>
          <CardNumberElement
            className="cardnumber-stripe"
            onChange={(e) => handleCardValidationError(e)}
            options={CardNumberOptions}
            placeholder="Card Number"
            autoFill={true}
          />
          <span style={{ color: "red", fontSize: "12px" }}>
            {" "}
            {cardValidationError.numberError}{" "}
          </span>
        </StripeInput>
        <StripeInput>
          <Form.Label className="d-none">Date</Form.Label>
          <div className="input-with-icon input-icon-left">
            <CardExpiryElement
              className="cardnumber-stripe"
              onChange={(e) => handleCardValidationError(e)}
              options={Stripe_Options}
              autoFill={true}
            />
            <img className="input-icon" src={calendarIcon} alt="icon-img" />
            <span style={{ color: "red", fontSize: "12px" }}>
              {" "}
              {cardValidationError.dateError}{" "}
            </span>
          </div>
        </StripeInput>
        <StripeInput>
          <Form.Label className="d-none">CVC</Form.Label>
          <div className="input-with-icon input-icon-left">
            <CardCvcElement
              options={Stripe_Options}
              onChange={(e) => handleCardValidationError(e)}
              autoFocus={true}
              autoFill={true}
            />
            <img className="input-icon" src={lockIcon} alt="icon-img" />
          </div>
          <span style={{ color: "red", fontSize: "12px" }}>
            {" "}
            {cardValidationError.cvcError}{" "}
          </span>
        </StripeInput>
        <div className='mb-2'>
          <LabelInput
          className='mb-0'
            name="text"
            placeholder="Full Address"
            onChange={(e) => handleAddress(e.target, 'line1')}
          />
          <span style={{ color: "red", fontSize: "12px" }}>
            {addressError.line1Error}{" "}
          </span>
        </div>
        <div className='mb-2'>
          <Select
            className='mb-0'
            name="country"
            placeholder="Country"
            options={countries}
            handleChange={(e) => handleAddress(e, 'country')}
          />
          <span style={{ color: "red", fontSize: "12px" }}>
            {addressError.countryError}{" "}
          </span>
        </div>
        <LabelInput
          name="text"
          placeholder="VAT Number"
          onChange={(e) => setVatNumber(e.target.value)}
        />
      </div>
      <div className="form-button">
        <Button
          text={`${user?.payment ? 'Update' : 'Add'} payment method`}
          className="outlined"
          onClick={handleSubmit}
          disabled={loading}
        />
      </div>
    </>
  );
};

const StripeForm = (props) => {
  return (
    <Elements stripe={stripePromise}>
      <PaymentForm
        modal={props?.modal || false}
        handleCloseModal={props.handleCloseModal}
      />
    </Elements>
  );
};

export default StripeForm;
