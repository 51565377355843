import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import Header from '../Header/header';

import { GetDataSyncProgress } from '../../redux/slices/auth-slice';

import WaitingScreenImg from "../../assets/images/Waiting-Screen.svg"

import { WaitingScreenWrapper } from './style';

const WaitingScreen = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [intervalId, setIntervalId] = useState();
  const { user, dataSyncProgress } = useSelector((state) => state.auth);

  useEffect(() => {
    if (user.role !== 'user') history.push('/dashboard');

    const myInterval = setInterval(getSyncDataProgressStart, 8000);
    setIntervalId(myInterval);
  }, []);

  useEffect(() => {
   return () => {
    getSyncDataProgressStop(intervalId);
  };
  }, [intervalId])

  useEffect(() => {
    // dispatch(GetUser());
    if (dataSyncProgress > 99 && user.syncStatus) {
      getSyncDataProgressStop(intervalId);
      setTimeout(() => {
        history.push('/dashboard');
      }, 1000)
    }
  }, [dataSyncProgress, user.syncStatus]);

  const getSyncDataProgressStart = () => {
    dispatch(GetDataSyncProgress({ userId: user.userId }));
  };
  
  const getSyncDataProgressStop = (intervalId) => {
    clearInterval(intervalId);
  };

  return (

    <WaitingScreenWrapper>
      <Header />
      <div className='main-wrapper'>
        <h3>Hello!! Please be patient while we synchronize you data</h3>
        <h6>Loading... {dataSyncProgress >= 0 ? dataSyncProgress + '%' : null}</h6>
        <img src={WaitingScreenImg} />
      </div>
    </WaitingScreenWrapper>
  );
}
export default WaitingScreen;