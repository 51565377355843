import React from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import {    
    buildStyles
  } from "react-circular-progressbar";
const Index = (props) => {
const {percentage, textColor}=props
  return (
    <>
      <CircularProgressbar strokeWidth="9" value={percentage} text={`${percentage}%`}     styles={buildStyles({
          textColor: "#ffffff ",
          pathColor: "#1FB090",
          trailColor: "#FFFFFF"
        })} />
    </>
  );
};
export default Index;
