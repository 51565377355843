import Styled from 'styled-components';
import { Button } from "react-bootstrap";

const ButtonWrapper = Styled(Button)`
    font-size:${({ theme }) => theme['medium-font-size']};
    font-weight: 700;
    line-height: 16px;
    padding: 8px 24px;
    height:32px;
    width: ${props => props.width};
    border-radius: 10px;

    &:hover, &:focus {
       box-shadow:none;
      }
      &.primary{
        background: ${({ theme }) => theme['new-primary']};
        border:none;
        &.btn-primary:not(:disabled):not(.disabled):active{
          background: ${({ theme }) => theme['new-primary']};
          color:${({ theme }) => theme['white-color']};
      }
      }
      &.outlined{
        border: 1px solid ${({ theme }) => theme['new-primary']};
        background-color: transparent;
        color: ${({ theme }) => theme['new-primary']};
        &.btn-primary:not(:disabled):not(.disabled):active{
        background-color:transparent; 
        border: 1px solid ${({ theme }) => theme['new-primary']};
        color:${({ theme }) => theme['new-primary']};
      }
      
      }
  `;
export { ButtonWrapper };
