import { createSlice, createAsyncThunk , current} from "@reduxjs/toolkit";

import { axiosBaseUrl } from '../../config/axios-configuration';

import SaveSentryLog from "../../helpers/sentry-log";
import { GetS3ImageUrl, UploadFileToUrl } from "../../helpers/get-s3-image-url";

const axios = axiosBaseUrl();

export const GetShipments = createAsyncThunk(
 'shipment/getShipments',
  async (data, {rejectWithValue }) => {
    try {
      const {
        filters,
        skip,
        limit
      } = data;
      const response = await axios.get('/fba-shipments/get-fba-shipments', {
        params: {
          filters,
          skip,
          limit
        }
      });
      return response.data;
    } catch (err) {
      SaveSentryLog(new Error(err), 'GetShipmentsError');
      if (err.response && err.response.data) {
        return rejectWithValue({ 
          err: {
            message: err.response.data.message,
            status: err.response.status
          }
        });
      }
      return rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

export const GetShipmentItems = createAsyncThunk(
  'shipment/getShipmentsItems',
   async (data, { rejectWithValue }) => {
     try {
       const {
         filters,
         shipmentId,
         skip,
         limit
       } = data;
      
       const response = await axios.get('/fba-shipments/get-fba-shipment-items', {
         params: {
           shipmentId,
           filters,
           skip,
           limit
         }
       });
       return response.data;
     } catch (err) {
       SaveSentryLog(new Error(err), 'GetShipmentItemsError');
       if (err.response && err.response.data) {
         return rejectWithValue({ 
           err: {
             message: err.response.data.message,
             status: err.response.status
           }
         });
       }
       return rejectWithValue({
         err: {
           message: 'Network Error',
           status: 502
         }
       });
     }
   }
 );

export const ClaimShipmentCase = createAsyncThunk(
  'shipment/postClaimShipmentCase',
   async (data, { rejectWithValue }) => {
     try {
       const {
          storeId,
          shipmentId,
          status,
          caseId
       } = data;
      
       const response = await axios.post('/fba-shipments/claim-shipment-case', {
          storeId,
          shipmentId,
          caseId,
          status
       });
       return response.data;
     } catch (err) {
       SaveSentryLog(new Error(err), 'ClaimShipmentCaseError');
       if (err.response && err.response.data) {
         return rejectWithValue({ 
           err: {
             message: err.response.data.message,
             status: err.response.status
           }
         });
       }
       return rejectWithValue({
         err: {
           message: 'Network Error',
           status: 502
         }
       });
     }
   }
 );

 export const UploadInvoice = createAsyncThunk(
  'upload/inovoice',
   async (data, { rejectWithValue }) => {
     try {
       const {
          file,
          shipment
       } = data;

       const formData = new FormData();
       for (let i = 0 ; i < file.length ; i++) {
          formData.append('invoices', file[i]);
       }

       formData.append("shipment", JSON.stringify(shipment));
       const response = await axios.post('/fba-shipments/upload-invoice', formData);
       return response.data;
     } catch (err) {
       SaveSentryLog(new Error(err), 'UploadInvoiceError');
       if (err.response && err.response.data) {
         return rejectWithValue({ 
           err: {
             message: err.response.data.message,
             status: err.response.status
           }
         });
       }
       return rejectWithValue({
         err: {
           message: 'Network Error',
           status: 502
         }
       });
     }
   }
 );

export const UploadInvoiceToS3 = createAsyncThunk(
  'shipment/uploadInvoiceToS3',
  async (data, { rejectWithValue }) => {
    try {
      const {
        files,
        shipment
      } = data;
      const { storeId, shipmentId } = shipment;
      const payload = files.map((file) => {
        const parts = file.name.split('.');
        const key = `${storeId}-${shipmentId}-${parts[0].split(' ').join('-')}`;
        const extension = parts[parts.length - 1];

        return { filename: key, filetype: extension };
      });
      const response = await axios.post('/s3/pre-signed-url', {
        files: payload,
        module: 'Shipment'
      });
      const { preSignedUrls } = response.data;
      const uploadFiles = [];
      const attachments = [];
      preSignedUrls.map((url, index) => {
        const file = files[index];
        uploadFiles.push(UploadFileToUrl(url, file));

        attachments.push({ shipment, url: GetS3ImageUrl({ bucketName: process.env.ST_SHIPMENT_BUCKET, key: payload[index]['filename'] })});
      });

      await Promise.all(uploadFiles);

      return attachments;
    } catch (err) {
      SaveSentryLog(new Error(err), 'UploadInvoiceToS3Error');
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: {
            message: err.response.data.message,
            status: err.response.status
          }
        });
      }
      return rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

export const GetFbaShipments = createAsyncThunk(
  'shipment/getFbaShipments',
  async (data, { rejectWithValue }) => {
    try {
      const {
        filters,
        skip,
        limit
      } = data;
      console.log({ filters, skip, limit });
      const response = await axios.get('/fba-shipments/get-shipments', {
        params: {
          filters: JSON.stringify(filters),
          skip,
          limit
        }
      });
      return response.data;
    } catch (err) {
      SaveSentryLog(new Error(err), 'GetFbaShipmentsError');
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: {
            message: err.response.data.message,
            status: err.response.status
          }
        });
      }
      return rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

export const ExportShipments = createAsyncThunk(
  'shipment/exportShipments',
  async (data, { rejectWithValue }) => {
    try {
      const {
        filters,
        userId
      } = data;
      console.log({ filters, userId });
      const url = `${process.env.API_URL}/export-shipments?userId=${userId}&filters=${JSON.stringify(filters) }`
      window.open(url, '_blank')
    } catch (err) {
      SaveSentryLog(new Error(err), 'ExportFbaShipmentsError');
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: {
            message: err.response.data.message,
            status: err.response.status
          }
        });
      }
      return rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

export const GetFbaShipmentsItems = createAsyncThunk(
  'shipment/getFbaShipmentsItems',
  async (data, { rejectWithValue }) => {
    try {
      const {
        shipmentId,
        storeId
      } = data;
      console.log({ shipmentId, storeId });
      const response = await axios.get('/fba-shipments/get-shipment-items', {
        params: {
          storeId,
          shipmentId
        }
      });
      return response.data;
    } catch (err) {
      SaveSentryLog(new Error(err), 'GetFbaShipmentItemsError');
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: {
            message: err.response.data.message,
            status: err.response.status
          }
        });
      }
      return rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

export const GetFbaShipmentDocuments = createAsyncThunk(
  'shipment/getFbaShipmentDocuments',
  async (data, { rejectWithValue }) => {
    try {
      const {
        shipmentId
      } = data;
      console.log({ shipmentId });
      const response = await axios.get('/fba-shipments/get-shipment-documents', {
        params: {
          shipmentId
        }
      });
      return response.data;
    } catch (err) {
      SaveSentryLog(new Error(err), 'GetFbaShipmentDocumentsError');
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: {
            message: err.response.data.message,
            status: err.response.status
          }
        });
      }
      return rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

export const GetPreSignedUrl = createAsyncThunk(
  'shipment/getPreSignedUrl',
  async (data, { rejectWithValue }) => {
    try {
      const {
        files,
        module,
        category,
        caseType
      } = data;
      
      const payload = files.map((file) => {
        const parts = file.name.split('.');
        const key = `${parts[0]}-${Date.now()}`;
        const extension = parts[parts.length - 1];

        return { filename: key, filetype: extension };
      });

      const response = await axios.post('/s3/pre-signed-url', {
          files: payload,
          module
      });
      const { preSignedUrls } = response.data;
      const uploadFiles = [];
      const attachments = preSignedUrls.map((url, index) => {
        const file = files[index];
        uploadFiles.push(UploadFileToUrl(url, file.originFileObj));
        const downloadLink = GetS3ImageUrl({ bucketName: process.env.ST_SHIPMENT_BUCKET, key: payload[index]['filename'] });

        return {
          name: file.name,
          key: payload[index]['filename'],
          s3Url: downloadLink,
          category,
          caseType
        };
      });

      await Promise.all(uploadFiles);

      return attachments;
    } catch (err) {
      SaveSentryLog(new Error(err), 'GetPreSignedUrlError');
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: {
            message: err.response.data.message,
            status: err.response.status
          }
        });
      }
      return rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

export const SubmitDocuments = createAsyncThunk(
  'shipment/submitDocuments',
  async (data, { rejectWithValue }) => {
    try {
      const {
        shipmentId,
        files,
        storeId
      } = data;

      const response = await axios.post('/fba-shipments/submit-documents', {
        shipmentId,
        files,
        storeId
      });
      return response.data;
    } catch (err) {
      SaveSentryLog(new Error(err), 'SubmitDocumentsError');
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: {
            message: err.response.data.message,
            status: err.response.status
          }
        });
      }
      return rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

export const SaveNotes = createAsyncThunk(
  'shipment/saveNotes',
  async (data, { rejectWithValue }) => {
    try {
      const {
        shipmentId,
        notes
      } = data;

      const response = await axios.post('/fba-shipments/save-notes', {
        shipmentId,
        notes
      });
      return response.data;
    } catch (err) {
      SaveSentryLog(new Error(err), 'SaveNotesError');
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: {
            message: err.response.data.message,
            status: err.response.status
          }
        });
      }
      return rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

export const SaveCaseId = createAsyncThunk(
  'shipment/saveCaseId',
  async (data, { rejectWithValue }) => {
    try {
      const {
        shipmentId,
        caseId
      } = data;

      const response = await axios.post('/fba-shipments/save-case', {
        shipmentId,
        caseId
      });
      return response.data;
    } catch (err) {
      SaveSentryLog(new Error(err), 'SaveCaseIdError');
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: {
            message: err.response.data.message,
            status: err.response.status
          }
        });
      }
      return rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

export const SaveUrls = createAsyncThunk(
  'shipment/saveUrls',
  async (data, { rejectWithValue }) => {
    try {
      const {
        shipment,
        shipmentUrls
      } = data;

      const response = await axios.post('/fba-shipments/save-urls', {
        shipment,
        invoiceS3Urls: shipmentUrls
      });
      return response.data;
    } catch (err) {
      SaveSentryLog(new Error(err), 'SaveUrlsError');
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: {
            message: err.response.data.message,
            status: err.response.status
          }
        });
      }
      return rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

export const GetCaseChat = createAsyncThunk(
  'shipment/getCaseChat',
  async (data, { rejectWithValue }) => {
    try {
      const {
        storeId,
        shipmentConfirmationId,
        caseId
      } = data;

      const response = await axios.get('/fba-shipments/get-case-chat', {
        params: {
          storeId,
          shipmentConfirmationId,
          caseId
        }
      });
      console.log(response)
      return response.data;
    } catch (err) {
      SaveSentryLog(new Error(err), 'GetCaseChatError');
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: {
            message: err.response.data.message,
            status: err.response.status
          }
        });
      }
      return rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

export const UpdateShipment = createAsyncThunk(
  'shipment/updateShipment',
  async (data, { rejectWithValue }) => {
    try {
      const {
        shipmentId,
        shipmentData
      } = data;

      const response = await axios.post('/fba-shipments/update-shipment', {
        shipmentId,
        data: shipmentData
      });
      return response.data;
    } catch (err) {
      SaveSentryLog(new Error(err), 'UpdateShipmentError');
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: {
            message: err.response.data.message,
            status: err.response.status
          }
        });
      }
      return rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

export const getShipment = (state) => {
  return state['shipment'].invoiceToUpload;
}

const shipment = createSlice({
    name: 'shipment',
    initialState: {
      error: '',
      loading: '',
      message: '',
      success: false,
      total: 0,
      shipments: [],
      shipmentItems: [],
      shipmentItemsTotal: 0,
      shipmentDocuments: [],
      uploadedDocuments: [],
      invoiceUploaded: false,
      invoiceToUpload: {},
      shipmentUrls: [],
      caseChat: []
    },
    reducers: {
      SetShipmentState(state, {payload: {field, value} }) {
        state[field] = value;
      }
    },
    extraReducers: {
      [GetShipments.pending]: (state, action) => ({
        ...state,
        loading: true
      }),
      [GetShipments.fulfilled]: (state, action) => ({
        ...state,
        success: true,
        loading: false,
        shipments: action.payload.fbaShipments,
        total: action.payload.total
      }),
      [GetShipments.rejected]: (state, action) => ({
        ...state,
        shipments: [],
        shipmentItems: [],
        total: 0,
        shipmentItemsTotal: 0,
        success: false,
        loading: false,
        error: action.payload.err.message
      }),
      [GetShipmentItems.pending]: (state, action) => ({
        ...state,
        loading: true
      }),
      [GetShipmentItems.fulfilled]: (state, action) => ({
        ...state,
        success: true,
        loading: false,
        message: action.payload.message,
        shipmentItems: action.payload.fbaShipmentItems,
        shipmentItemsTotal: action.payload.total
      }),
      [GetShipmentItems.rejected]: (state, action) => ({
        ...state,
        shipmentItems: [],
        shipmentItemsTotal: 0,
        success: false,
        loading: false,
        error: action.payload.err.message
      }),
      [GetFbaShipmentsItems.pending]: (state, action) => ({
        ...state,
        loading: true
      }),
      [GetFbaShipmentsItems.fulfilled]: (state, action) => ({
        ...state,
        success: true,
        loading: false,
        message: action.payload.message,
        shipmentItems: action.payload.fbaShipmentItems,
        shipmentItemsTotal: 10
      }),
      [GetFbaShipmentsItems.rejected]: (state, action) => ({
        ...state,
        shipmentItems: [],
        shipmentItemsTotal: 0,
        success: false,
        loading: false,
        error: action.payload.err.message
      }),
      [GetFbaShipmentDocuments.pending]: (state, action) => ({
        ...state,
        loading: true
      }),
      [GetFbaShipmentDocuments.fulfilled]: (state, action) => ({
        ...state,
        success: true,
        loading: false,
        shipmentDocuments: action.payload.data,
      }),
      [GetFbaShipmentDocuments.rejected]: (state, action) => ({
        ...state,
        shipmentDocuments: [],
        success: false,
        loading: false,
        error: action.payload.err.message
      }),
      [GetPreSignedUrl.pending]: (state, action) => ({
        ...state,
        loading: true
      }),
      [GetPreSignedUrl.fulfilled]: (state, action) => ({
        ...state,
        success: true,
        loading: false,
        uploadedDocuments: [...state.uploadedDocuments||[],...action.payload || []],
      }),
      [GetPreSignedUrl.rejected]: (state, action) => ({
        ...state,
        uploadedDocuments: [],
        success: false,
        loading: false,
        error: action.payload.err.message
      }),
      [SubmitDocuments.pending]: (state, action) => ({
        ...state,
        loading: true
      }),
      [SubmitDocuments.fulfilled]: (state, action) => ({
        ...state,
        success: true,
        loading: false,
        message: action.payload.message
      }),
      [SubmitDocuments.rejected]: (state, action) => ({
        ...state,
        success: false,
        loading: false,
        error: action.payload.err.message
      }),
      [UploadInvoiceToS3.pending]: (state, action) => ({
        ...state,
        loading: true,
        shipmentUrls: []
      }),
      [UploadInvoiceToS3.fulfilled]: (state, action) => ({
        ...state,
        success: true,
        loading: false,
        shipmentUrls: action.payload
      }),
      [UploadInvoiceToS3.rejected]: (state, action) => ({
        ...state,
        success: false,
        loading: false,
        error: action.payload.err.message
      }),
      [SaveNotes.pending]: (state, action) => ({
        ...state,
        loading: true
      }),
      [SaveNotes.fulfilled]: (state, action) => ({
        ...state,
        success: true,
        loading: false,
        message: action.payload.message
      }),
      [SaveNotes.rejected]: (state, action) => ({
        ...state,
        success: false,
        loading: false,
        error: action.payload.err.message
      }),
      [GetCaseChat.pending]: (state, action) => ({
        ...state,
        loading: true
      }),
      [GetCaseChat.fulfilled]: (state, action) => ({
        ...state,
        success: true,
        loading: false,
        caseChat: action.payload
      }),
      [GetCaseChat.rejected]: (state, action) => ({
        ...state,
        success: false,
        loading: false,
        error: action.payload.err.message
      }),
      [SaveCaseId.pending]: (state, action) => ({
        ...state,
        loading: true
      }),
      [SaveCaseId.fulfilled]: (state, action) => ({
        ...state,
        success: true,
        loading: false,
        message: action.payload.message
      }),
      [SaveCaseId.rejected]: (state, action) => ({
        ...state,
        success: false,
        loading: false,
        error: action.payload.err.message
      }),
      [UpdateShipment.pending]: (state, action) => ({
        ...state,
        loading: true
      }),
      [UpdateShipment.fulfilled]: (state, action) => ({
        ...state,
        success: true,
        loading: false,
        message: action.payload.message
      }),
      [UpdateShipment.rejected]: (state, action) => ({
        ...state,
        success: false,
        loading: false,
        error: action.payload.err.message
      }),
      [ClaimShipmentCase.pending]: (state, action) => ({
        ...state,
        loading: true
      }),
      [ClaimShipmentCase.fulfilled]: (state, action) => ({
        ...state,
        success: true,
        loading: false,
        message: action.payload.message,
      }),
      [ClaimShipmentCase.rejected]: (state, action) => ({
        ...state,
        success: false,
        loading: false,
        error: action.payload.err.message
      }),
      [UploadInvoice.pending]: (state, action) => ({
        ...state,
        loading: true,
        invoiceUploaded: false,
      }),
      [UploadInvoice.fulfilled]: (state, action) => ({
        ...state,
        success: true,
        invoiceUploaded: true,
        message: action.payload.message,
        loading: false,
      }),
      [UploadInvoice.rejected]: (state, action) => ({
        ...state,
        success: false,
        loading: false,
        invoiceUploaded: false,
        error: action.payload.err.message
      }),
      [SaveUrls.pending]: (state, action) => ({
        ...state,
        loading: true,
        invoiceUploaded: false,
      }),
      [SaveUrls.fulfilled]: (state, action) => ({
        ...state,
        success: true,
        invoiceUploaded: true,
        message: action.payload.message,
        loading: false,
        shipmentUrls: []
      }),
      [SaveUrls.rejected]: (state, action) => ({
        ...state,
        success: false,
        loading: false,
        invoiceUploaded: false,
        error: action.payload.err.message
      }),
      [GetFbaShipments.pending]: (state, action) => ({
        ...state,
        loading: true
      }),
      [GetFbaShipments.fulfilled]: (state, action) => ({
        ...state,
        success: true,
        loading: false,
        shipments: action.payload.fbaShipments,
        total: action.payload.total
      }),
      [GetFbaShipments.rejected]: (state, action) => ({
        ...state,
        shipments: [],
        shipmentItems: [],
        total: 0,
        shipmentItemsTotal: 0,
        success: false,
        loading: false,
        error: action.payload.err.message
      }),
    }
  });
  
  const { reducer, actions } = shipment;

  export const { SetShipmentState, GetShipmentState } = actions;

  export default reducer;
