import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

import PrivateRoute from './private-route';
import PublicRoute from './public-route';

import ChangePassword from '../pages/Auth/password-change';
import ForgetPassword from '../pages/Auth/forget-password';
import ForgetPasswordOtpVerification from '../pages/Auth/forget-password-otp-verification.js';
import SignUp from '../pages/Auth/sign-up';
import SignIn from '../pages/Auth/sign-in';
import SelectMarketplaces from '../pages/OnBoarding/select-marketplaces';
import PageNotFound from '../pages/Other/page-not-found';
import Layout from '../pages/Layout/layout.js';
import SelectRegion from '../pages/OnBoarding/select-region';
import ResgisterAffiliateManager from '../pages/AffiliateManager/register-affiliate-manager';
import ResendVerificationEmail from '../pages/Auth/resend-email.js'

import { setAuthToken } from '../config/axios-configuration';
import EmailVerification from '../pages/Auth/mail-verification';
import OnboardingFlowSt from '../pages/OnBoardingSt';

const AuthRoute = () => {
  const { token, showSidebar } = useSelector(state => state.auth);
  setAuthToken(token);

  return (
    <Switch>
      <PublicRoute exact path='/auth/sign-up' component={SignUp} />
      <PublicRoute exact path='/auth/email-verification' component={EmailVerification} />
      <PublicRoute exact path='/auth/sign-in' component={SignIn} />
      <PublicRoute exact path='/auth/forget-password' component={ForgetPassword} />
      <PublicRoute exact path='/auth/otp-verification' component={ForgetPasswordOtpVerification} />
      <PublicRoute exact path='/auth/reset-password' component={ChangePassword} />
      <PublicRoute exact path='/auth/register-affiliate-manager' component={ResgisterAffiliateManager} />
      <PublicRoute exact path='/auth/resend-verification-email' component={ResendVerificationEmail}/>
      <PrivateRoute
        exact
        path='/auth/sp-api'
        component={OnboardingFlowSt}
        withLayout={
          showSidebar
            ? Layout
            : OnboardingFlowSt
        } />
      <Route path='/not-found' component={PageNotFound} />
      <Redirect from='*' to='/' />
    </Switch>
  );
}

export default AuthRoute;
