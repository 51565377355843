import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import { components } from "react-select";
import crypto from 'crypto';

import { BsExclamationCircleFill } from "react-icons/bs";
import { GoAlert } from 'react-icons/go';

import {
  isEmpty,
  round
} from 'lodash';

import CheckIcon from "../../assets/icons/check-icon.svg";

import Alert from "../../components/alert";
import Button from "../../components/Button/index";
import Icon from "../../components/icons";
import Spin from "../../components/Spin/Index";
import ProgressBarCircular from "../../components/progress";
import Select from "../../components/Select";

import UserIcon from "../../assets/images/user.svg"
import StUserIcon from "../../assets/images/st-user.svg";

import { DashbaordWrapper } from "./style";

import { SetAuthState } from '../../redux/slices/auth-slice';
import {
  GetOverallPercentageSummary,
  GetUserAmountsSummary
} from '../../redux/slices/dashboard-slice';
import {
  GetUsersName,
  SetUserState
} from "../../redux/slices/user-slice";
import { GetStore, GetStoreNames, SetStoreState } from '../../redux/slices/store-slice';

import {
  APP_DOMAINS,
  AGENCIES_EMAIL,
  AGENCY_USERS,
  CURRENCY_SYMBOLS,
  ALL_MARKETPLACES_IDS,
  STORE_STATUS,
  APP_IDS,
  REDIRECT_URIS,
  ROLES
} from '../../constants';

import { ClearStates } from "../../helpers/clear-store-states";

const { Option } = components;
const Tile = (props) => {
  return (
    <Option {...props} >
      <div className="select-option">
        <span className="d-block bold-weight">{props.data.userEmail !== 'All' ? props.data.userEmail: null}</span>
        <span>{props.data.name}</span>
      </div>
    </Option>
  );
}

const Index = () => {
  const hostName = window.location.hostname;
  let intervalId;
  const {
    loading,
    overAllPercentageSummary,
    userAmountsSummary
  } = useSelector(state => state.dashboard);

  const { storeNamesList, store } = useSelector((state) => state.store);

  const { usersName } = useSelector((state) => state.user);
  const { user, payLater } = useSelector(state => state.auth);

  const [marketplaces, setMarketplaces] = useState([]);
  const [storeError, setStoreError] = useState([]);
  const [potentialRecoverySliderValue, setPotentialRecoverySliderValue] = useState(0);
  const [recoveredSliderValue, setRecoveredSliderValue] = useState(0);
  const [inProgressSliderValue, setInProgressSliderValue] = useState(0);
  const [currency, setSelectedCurrencyCode] = useState('USD');
  const [allMarketplaces, setAllMarketplaces] = useState([]);
  const [overAllProgress, setOverAllProgress] = useState(0);
  const [usersNameData, setUsersNameData] = useState([]);
  const [isSelected, setIsSelected] = useState(false);
  const [selectedUser, setSelectedUser] = useState({ value: 'All', label: 'All' });
  const [storeDropdownData, setDropdownData] = useState([]);

  let currencyCode = 'USD';

  const [filters, setFilters] = useState({    
    userId: '',
    storeId: 'All'
  });
  const history = useHistory();
  const dispatch = useDispatch();

  const potentialRecoveryMoneyFormatting = (amountObj, index) => {
    let value = index;

    const amountObjSort = Object.entries(amountObj)
    .sort(([,a],[,b]) => b-a)
    .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});

    const keys = Object.keys(amountObjSort);

    if (marketplaces.length === 0) {
      setMarketplaces(keys);
    }

    if (index >= keys?.length) {
      value = 0;
      setPotentialRecoverySliderValue(value);
    } else if (index < 0) {
      value = keys?.length - 1;
      setPotentialRecoverySliderValue(value);
    }
    currencyCode = keys[value] ? keys[value] : 'USD';
    return keys.length > 0 ? `${CURRENCY_SYMBOLS[currencyCode]}${round((amountObj[currencyCode] || 0), 0)}`: '$0';
  };

  const activeMarketplaces = () => {
    let naRegion = false;
    let euRegion = false;
    let feRegion = false;

    const allMarketplaceIds = allMarketplaces?.map((obj) => obj.marketplaceId);
    allMarketplaceIds?.filter((marketplaceId) => {
      if (ALL_MARKETPLACES_IDS.na.includes(marketplaceId)) {
        naRegion = true;
      }

      if (ALL_MARKETPLACES_IDS.eu.includes(marketplaceId)) {
        euRegion = true;
      }

      if (ALL_MARKETPLACES_IDS.fe.includes(marketplaceId)) {
        feRegion = true;
      }
    })

    const list = (
      <ul>
          {euRegion ? <li className="country-item"><img src={CheckIcon} className="check-icon-dashboard" /> Europe</li> : null}
          {naRegion ? <li className="country-item"><img src={CheckIcon} className="check-icon-dashboard" /> North America</li> : null}
          {feRegion ? <li className="country-item"><img src={CheckIcon} className="check-icon-dashboard" /> Far East</li> : null}
      </ul>
    )

    return list;
  };

  useEffect(() => {
    if (storeNamesList && storeNamesList.length) {
      const errorIds = [];
      const data = storeNamesList.map((doc) => {
        if(doc.status !== STORE_STATUS.CONNECTED) {
          errorIds.push(doc._id);
        }

        return {
          storeId: doc._id,
          value: [doc._id, doc.email],
          label: `${doc.name || 'N/A'}`,
          userEmail: doc.name,
          name: doc.email
        }
      })
      setStoreError(errorIds);
      setFilters({...filters,  storeId: 'All'});
      setDropdownData(data);
    } else {
      setDropdownData([]);
    }
  }, [storeNamesList])

  useEffect(() => {
    if (usersName && usersName.length > 0) {
      dispatch(SetUserState({ field: 'message', value: ''}));
      const usersNamesData = usersName.map(user => ({
        value: [user._id, user.email],
        label: `${user.name}`,
        userEmail: user.name,
        name: user.email
      }));

      if (filters.userId) {
        let userSelected = usersNamesData.find(user => user.value === filters.userId);
        userSelected ={
          value: userSelected.value,
          label: userSelected.name,
          userName: userSelected.email,
          name: userSelected.name
        }
        setSelectedUser(userSelected);
      } else {
        setSelectedUser({
          value: 'All',
          label: 'All',
          userEmail: '',
          name: 'All'
        });
      }

      const users = [];

      users.push(...usersNamesData);
      setUsersNameData(users);
    }
  }, [usersName]);

  useEffect(() => {
    if (user.role === 'user' || user.role === 'affiliate-manager' || storeDropdownData.length && usersNameData.length) {
      if (payLater || store?.payLater) {
        dispatch(SetAuthState({ field: "showSidebar", value: true }));
      }
      dispatch(GetOverallPercentageSummary(filters));
      dispatch(GetUserAmountsSummary(filters));
    }
  }, [filters]);

  useEffect(() => {    
    if (user.role === 'admin' || user.role === 'agency-manager' || user.role === "affiliate-manager") {
      dispatch(GetUsersName());
      dispatch(GetStoreNames());
    } 
    if(user.role === 'user'){
      dispatch(GetStoreNames());
    }
  }, []);

  useEffect(() => {
    if (userAmountsSummary?.potentialRecoveryAmount) {
      const keys = Object.keys(userAmountsSummary?.potentialRecoveryAmount);
      setSelectedCurrencyCode(keys[potentialRecoverySliderValue]);
    }
  },[potentialRecoverySliderValue]);

  useEffect(() => {
    if (userAmountsSummary?.potentialRecoveryAmount) {
      const keys = Object.keys(userAmountsSummary?.potentialRecoveryAmount);
      if (keys.length > 0) {
        setSelectedCurrencyCode(keys[0]);
      }
    }

    if (userAmountsSummary?.marketplaces) {
      setAllMarketplaces(userAmountsSummary?.marketplaces);
    }

    if (overAllPercentageSummary?.overAllPercentage) {
      setOverAllProgress(overAllPercentageSummary?.overAllPercentage || 0);
    }
  }, [overAllPercentageSummary, userAmountsSummary]);

  useEffect(() => {
    if (overAllProgress >= 100) {
      dispatch(SetAuthState({ field: 'user', value: { ...user, syncStatus: true } }));
      return () => {
        console.log('Going to clear interval', intervalId);
        clearInterval(intervalId);
      }
    }
  }, [overAllProgress]);

  const recoveredMoneyFormatting = (amountObj, index) => {
    let value = index;

    const amountObjSort = Object.entries(amountObj)
    .sort(([,a],[,b]) => b-a)
    .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});

    const keys = Object.keys(amountObjSort);

    if (index >= keys?.length) {
      value = 0;
      setRecoveredSliderValue(value);
    } else if (index < 0) {
      value = keys?.length - 1;
      setRecoveredSliderValue(value);
    }
    const currencyCode = keys[value];

    return `${CURRENCY_SYMBOLS[currencyCode]}${round((amountObj[currencyCode] || 0), 0)}`;
  };

  const inProgressMoneyFormatting = (amountObj, index) => {
    let value = index;

    const amountObjSort = Object.entries(amountObj)
    .sort(([,a],[,b]) => b-a)
    .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});

    const keys = Object.keys(amountObjSort);
    if (index >= keys?.length) {
      value = 0;
      setInProgressSliderValue(value);
    } else if (index < 0) {
      value = keys?.length - 1;
      setInProgressSliderValue(value);
    }
    const currencyCode = keys[value];

    return `${CURRENCY_SYMBOLS[currencyCode]}${round((amountObj[currencyCode] || 0), 0)}`;
  };

  const getEstimatedAmount = (amount) => {
    if (amount && amount[currencyCode]) {
      return `${CURRENCY_SYMBOLS[currencyCode]}${round((amount[currencyCode]), 0)}`;
    }

    return (CURRENCY_SYMBOLS[currencyCode] || '$') + '0';
  };

  const handleAddStore = () => {
    ClearStates({ dispatch, fromApp: true });

    history.push({
      pathname: '/auth/sp-api'
    });
  }

  const handleStoreCompleteion = (id, spapi) => {
    ClearStates({dispatch, fromApp: true, storeId: id});

    if (!spapi?.apiConnected) {
      const state = crypto.randomBytes(20).toString("hex");

      dispatch(SetStoreState({ field: 'state', value: state }));

      const url = `https://sellercentral-europe.amazon.com/authorization/select-account?returnTo=/apps/authorize/consent?state=${state}%26application_id=${APP_IDS[hostName]}&redirect_uri=${REDIRECT_URIS[hostName]}`
      window.open(url, "_self");
    } else {
      history.push({
        pathname: '/auth/sp-api'
      });
    }
  }

  const getAdjustmentsAmount = (lost, damaged, destroyed) => {
    const allKeys = [
      ...Object.keys(lost || {}),
      ...Object.keys(damaged || {}),
      ...Object.keys(destroyed || {})
    ];

    const uniqueKeys = [...new Set(allKeys)];
    let potentialRecoveryAmount = {};

    for (let i = 0; i < uniqueKeys.length; i += 1) {
      const currencySymbol = uniqueKeys[i];
      potentialRecoveryAmount = {
        ...potentialRecoveryAmount,
        [currencySymbol]: round(
          (lost[currencySymbol] || 0)
          + (destroyed[currencySymbol] || 0)
          + (damaged[currencySymbol] || 0)
          + (potentialRecoveryAmount[currencySymbol] || 0), 2
        )
      };
    }

    if (potentialRecoveryAmount && potentialRecoveryAmount[currencyCode]) {
      return `${CURRENCY_SYMBOLS[currencyCode]}${round((potentialRecoveryAmount[currencyCode]), 0)}`;
    }

    return (CURRENCY_SYMBOLS[currencyCode] || '$') + '0';
  };

  const handleChange = (e, key) => {
    const userSelected = usersNameData.find(user => user.value === e?.value);
    setSelectedUser({
      value: userSelected?.value || 'All',
      label: userSelected?.name || 'All',
      userEmail: userSelected?.email || 'All',
      name: userSelected?.name || 'All'
    });
    // setPass(true);
    if (key === 'userId' && e?.value) {
      dispatch(GetStoreNames({
        userId: e.value
      }));
    }
    setFilters({
      ...filters,      
      [key]: e?.value[0] || 'All'
    })
  };
  return (
    <DashbaordWrapper>
      <div className="">
        <div className="d-flex align-items-baseline justify-content-between">
          <h2 className="welcome-text">
            <span class>Hello</span>, {user?.name || 'User'}{" "}
            <span>Welcome to the dashboard</span>
          </h2>
          <div className="d-flex align-items-center">
          {user.role === 'admin' || user.role === 'agency-manager'?
                <>
                  <Select
                    styles={{
                      option: (base, state) => ({
                        ...base,
                        paddingLeft: "11px",
                        paddingRight: "11px",
                        paddingTop: "5px",
                        paddingBottom: "5px",
                        borderBottom: `1px solid #E3E2E2`,
                        height: "100%",
                        backgroundColor: 'transparnet',
                        color: !isSelected ? '#4E5969' : "#064AAC"
                      }),
                      menuList: (provided, state) => ({
                        ...provided,
                        padding: 10,
                      }),
                    }}
                    className="mb-0 w-232 mr-3"
                    options={usersNameData}
                    isClearable={true}
                    handleChange={(e) => handleChange(e, 'userId')}
                    components={{ Option: Tile }}
                    placeholder='Select User'
                  /> 
                  <Select
                      handleChange={(e) => handleChange(e, 'storeId')}
                    options={storeDropdownData}
                    className="dashbaord-select case-state-dropdown"
                    isClearable={true}
                    components={{ Option: Tile }}
                    placeholder='Select Store'
                  />
                </>         
                  : <>
                <Select
                  handleChange={(e) => handleChange(e, 'storeId')}
                  options={storeDropdownData}
                  className="dashbaord-select case-state-dropdown"
                  isClearable={true}
                  components={{ Option: Tile }}
                  placeholder='Select Store'
                />
                  </>}
          </div>
      </div>
       
        
        <Row>
          <Col md={2}>
            <div className="account-details">
              <div className="account-details-box">
                <h3>Account Status</h3>
                <p className="check-info">
                  {
                    userAmountsSummary?.accountStatus ? <>
                      <img src={CheckIcon} alt="check icon" /> Account Connected
                    </> : <>
                      <BsExclamationCircleFill
                        style={{ color: 'red' }}
                      /> In Active
                    </>
                  }
                </p>
                <div className="account-details-content">
                  <p>We’ve Recovered</p>
                  <div className="region-details">
                    <div className="currency-info d-flex justify-content-between">
                      <Icon
                      className="left-arrow-dashboard"
                        icon="left-arrow"
                        onClick={() => setRecoveredSliderValue(recoveredSliderValue - 1)}
                      />
                        <p>{userAmountsSummary?.recovered ? Object.keys(userAmountsSummary?.recovered)?.length > 0 ? recoveredMoneyFormatting(userAmountsSummary?.recovered, recoveredSliderValue) : '$0': '$0'}</p>
                      <Icon
                      className="right-arrow-dashboard"
                        icon="right-arrow"
                        onClick={() => setRecoveredSliderValue(recoveredSliderValue + 1)}
                      />
                    </div>
                  </div>
                </div>
                <div className="account-details-content">
                <div className="amount-recovery">
                <p>In Progress</p>
                <div className="region-details">
                  <div className="currency-info d-flex">
                    <Icon
                    icon="left-arrow"
                    onClick={() => setInProgressSliderValue(inProgressSliderValue - 1)}
                  />
                    <p>{userAmountsSummary?.inProgressAmount ? Object.keys(userAmountsSummary?.inProgressAmount)?.length > 0 ? inProgressMoneyFormatting(userAmountsSummary?.inProgressAmount, inProgressSliderValue) : '$0': '$0'}</p>
                    <Icon
                    icon="right-arrow"
                    onClick={() => setInProgressSliderValue(inProgressSliderValue + 1)}
                  />
                  </div>
                </div>
              </div>
                </div>
                <div className="account-details-content">
                  <h3>Your Account Manager</h3>
                  <div className="account-manager-info">
                    <div className="d-flex align-items-center">
                      <img src={hostName === APP_DOMAINS.sellerTerminal ? StUserIcon: UserIcon} alt="user"/><span className="agency-account-manager">{AGENCY_USERS[user.agencyId]}</span>
                    </div>
                    <p>
                      If you have any questions so you can schedule a call with
                      me any- time!
                    </p>
                    <a href={`mailto:${AGENCIES_EMAIL[user.agencyId]}`}>
                      <Button text="Contact" className="outlined dashboard-contact-btn-wrapper"/>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col md={2}>
            <div className="amount-details">
              <div className="amount-recovery">
                <h2>Potential Recovery Amount</h2>
                <div className="region-details">
                  <div className="currency-info d-flex">
                    <Icon
                    className="arrow-font-wrapper"
                      icon="left-arrow"
                      onClick={() => setPotentialRecoverySliderValue(potentialRecoverySliderValue - 1)}
                    />
                    <p className="card-wrapper-arrow-issues">{Object.keys(userAmountsSummary?.potentialRecoveryAmount || {})?.length > 0 ? potentialRecoveryMoneyFormatting(userAmountsSummary?.potentialRecoveryAmount, potentialRecoverySliderValue) : '$0'}</p>
                    <Icon
                      icon="right-arrow"
                      onClick={() => setPotentialRecoverySliderValue(potentialRecoverySliderValue + 1)}
                    />
                  </div>
                </div>
              </div>
              <div className="amount-description">
                <p>Lost & Damaged</p>
                <h4>{getAdjustmentsAmount(userAmountsSummary?.lostClaimableAmount, userAmountsSummary?.damagedClaimableAmount, userAmountsSummary?.destroyedClaimableAmount) || '$0'}</h4>
              </div>
              <div className="amount-description">
                <p>Incorrect Fees</p>
                <h4>{getEstimatedAmount(userAmountsSummary?.fulfillmentFeeClaimableAmount) || '$0'}</h4>
              </div>
              <div className="amount-description">
                <p>Inbound / Outbound Shipping</p>
                <h4>{getEstimatedAmount(userAmountsSummary?.shipmentsClaimableAmount) || '$0'}</h4>
              </div>
              <div className="amount-description">
                <p>Refunds</p>
                <h4>{getEstimatedAmount(userAmountsSummary?.processedReturnClaimableAmount) || '$0'}</h4>
              </div>
            </div>
          </Col>
          <Col md={2}>
            <div className="active-marketplace">
              <h3>Active Marketplaces</h3>
              <div className="region-details">
               {activeMarketplaces()}
                <div className="form-button">
                  <Button
                    text="Add another store"
                    className="outlined country-card-btn-wrapper"
                    onClick={handleAddStore}
                  />
                </div>
              </div>
            </div>
          </Col>
          <Col md={2}>
          <div className="data-collapse-top">
              <ProgressBarCircular percentage={overAllProgress} />
              <h3>{overAllProgress < 100 ? 'About 1 hour left' : 'Data Syncing Complete'}</h3>
              <p>
                Find out when we’ve
                <br /> completed our analysis
              </p>
            </div>
          </Col>
        </Row>
      </div>
      {
        loading && (
          <Spin />
        )
      }
    </DashbaordWrapper>
  );
};

export default Index;
