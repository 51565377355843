import Form from "react-bootstrap/Form";
import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { round, set, isEmpty } from 'lodash';
import { useHistory } from 'react-router-dom';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import Header from "../Header/header";

import Button from "../../components/Button/index";
import ProgressBar from "react-bootstrap/ProgressBar";
import ProgressBarCircular from "../../components/progress";
import calendarIcon from "../../assets/icons/calendar.svg";
import lockIcon from "../../assets/icons/lock.svg";
import Modal from '../../components/Modal/modal';
import LabelInput from "../../components/Inputs/label-input";
import Notification from '../../components/notifications/notification';
import { StripeInput } from "../../components/PaymentCard/style";

import AddCard from '../AddCards';

import US from "../../assets/images/newImages/flags/US.svg";
import CA from "../../assets/images/newImages/flags/CA.svg";
import MX from "../../assets/images/newImages/flags/MX.svg";
import BR from "../../assets/images/newImages/flags/BR.svg";
import EG from "../../assets/images/newImages/flags/EG.svg";
import FR from "../../assets/images/newImages/flags/FR.svg";
import GB from "../../assets/images/newImages/flags/GB.svg";
import IN from "../../assets/images/newImages/flags/IN.svg";
import IT from "../../assets/images/newImages/flags/IT.svg";
import NL from "../../assets/images/newImages/flags/NL.svg";
import PL from "../../assets/images/newImages/flags/PL.svg";
import SE from "../../assets/images/newImages/flags/SE.svg";
import SA from "../../assets/images/newImages/flags/SA.svg";
import TN from "../../assets/images/newImages/flags/TN.svg";
import SG from "../../assets/images/newImages/flags/SG.svg";
import AU from "../../assets/images/newImages/flags/AU.svg";
import JP from "../../assets/images/newImages/flags/JP.svg";
import AE from "../../assets/images/newImages/flags/AE.svg";
import DE from "../../assets/images/newImages/flags/DE.svg";
import ES from "../../assets/images/newImages/flags/ES.svg";
import UK from "../../assets/images/newImages/flags/UK.svg";
import BE from "../../assets/images/newImages/flags/BE.svg";
import TR from "../../assets/images/newImages/flags/TR.svg";
import Select from "../../components/Select/index";

import { CURRENCY_SYMBOLS, PUBLIC_KEYS } from "../../constants";

import { StoreWrapper, DataGatheringWrapper } from "./style";

import { SetAuthState } from '../../redux/slices/auth-slice';
import {
  EnableNotification,
  GetDataSyncProgress,
  SetState as SetDataGatheringState
} from '../../redux/slices/data-gathering';
import { GetStore } from '../../redux/slices/store-slice';
import {
  CreateCustomer,
  SetStripeState,
  UpdateCustomer,
} from "../../redux/slices/stripe-slice";
import { GetUser } from '../../redux/slices/user-slice';

import {
  AGENCIES_EMAIL,
  COUNTRIES
} from "../../constants";

const flags = {
  'US': US,
  'CA': CA,
  'MX': MX,
  'BR': BR,
  'EG': EG,
  'FR': FR,
  'GB': GB,
  'IN': IN,
  'IT': IT,
  'NL': NL,
  'PL': PL,
  'SE': SE,
  'SA': SA,
  'TN': TN,
  'SG': SG,
  'AU': AU,
  'JP': JP,
  'AE': AE,
  'DE': DE,
  'ES': ES,
  'UK': UK,
  'TR': TR,
  'BE': BE
};

const regions = {
  na: 'Nort America',
  fe: 'Far East',
  eu: 'Europe'
};


const hostName = window.location.hostname;
const publishablekey = PUBLIC_KEYS[hostName];

const overAllDataSyncProgress = ({
  lostPercentage,
  damagedDestroyedPercentage,
  customerReturnPercentage,
  fbaShipmentsPercentage,
  feesPercentage
}) => {
  const overAllPercentage = round((lostPercentage * 0.25) + (damagedDestroyedPercentage * 0.25) + (fbaShipmentsPercentage * 0.25) + (feesPercentage * 0.25), 0);

  return overAllPercentage;
};

const getEstimatedAmount = ({ amount }) => {
  let finalValue = '';
  if (!isEmpty(amount) && amount !== 0) {
    const amounts = Object.values(amount);
    const maxValue = Math.max(...amounts);
    const keyWithValue = Object.entries(amount).find(([, value]) => maxValue === value);
    finalValue = `${CURRENCY_SYMBOLS[keyWithValue[0]]}${round(keyWithValue[1], 0)}`
  }

  return finalValue;
};

let intervalId;

const PaymentForm = (props) => {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();

  const [show, setShow] = useState(false);
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [countries, setCountries] = useState([]);
  const [vatNumber, setVatNumber] = useState('');
  const [address, setAddress] = useState({
    line1: "",
    country: "",
  });
  const [addressError, setAddressError] = useState({
    line1Error: "",
    countryError: "",
  });
  const [cardValidationError, setCardValidationError] = useState({
    cvcError: "",
    dateError: "",
    numberError: "",
    cvcTouched: false,
    dateTouched: false,
    numberTouched: false,
  });

  const [selectedCountry, setSelectedCountry] = useState({});
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [severity, setSeverity] = useState("");

  const { user: userData } = useSelector((state) => state.auth);
  const {
    success,
    customerCreated,
    customerUpdated,
    error,
    loading
  } = useSelector((state) => state.stripe);
  const { user } = useSelector((state) => state.user);

  useEffect(() => {
    if (error) {
      setToastMessage(error);
      setSeverity("error");
      setToastOpen(true);
      dispatch(SetStripeState({ field: "error", value: "" }));
      return;
    }
  }, [error]);

  useEffect(() => {
    dispatch(GetUser({ userId: userData._id }));
    const countriesData = COUNTRIES?.map((country, index) => {
      return {
        value: country.code,
        label: country.name,
      };
    });
    setCountries(countriesData);
  }, []);

  useEffect(() => {
    if (user.payment) {
      const country = COUNTRIES.find(
        (country, index) => country.code === user.payment?.address?.country
      );
      if (country) {
        const countryOption = {
          value: country.code,
          label: country.name,
        };
        setSelectedCountry(countryOption);
        setAddress(user.payment?.address);
        setName(user.payment?.name);
      }
      dispatch(
        SetAuthState({
          field: "user",
          value: { ...userData, payment: user.payment },
        })
      );
    }
  }, [user]);

  useEffect(() => {
    if (customerCreated) {
      Notification({
        type: "success",
        title: "Success",
        description: 'Card Added Successfully'
      });
    } else if (customerUpdated) {
      Notification({
        type: "success",
        title: "Success",
        description: 'Card Info Updated Successfully'
      });
    }

    if (customerCreated || customerUpdated) {
      dispatch(GetUser({ userId: userData._id }));
      dispatch(SetStripeState({ field: "customerCreated", value: false }));
      dispatch(SetStripeState({ field: "customerUpdated", value: false }));
    }
  }, [customerCreated, customerUpdated]);

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      return;
    }
    const { cvcTouched, dateTouched, numberTouched } = cardValidationError;

    if (
      address.line1 === "" ||
      address.country === "" ||
      name === "" ||
      cvcTouched !== true ||
      dateTouched !== true ||
      numberTouched !== true
    ) {
      let notificationError = "";
      let line1Error = "";
      let countryError = "";
      let nameError = "";
      let cvcError = "";
      let numberError = "";
      let dateError = "";

      if (name === "") {
        nameError = "Name is Required!";
        if (!notificationError) {
          notificationError = nameError;
        }
      }

      if (cardValidationError.numberError === "" && numberTouched === false) {
        numberError = "Card Number is Requried!";
        if (!notificationError) {
          notificationError = numberError;
        }
      } else {
        numberError = cardValidationError.numberError;
        if (!notificationError) {
          notificationError = numberError;
        }
      }

      if (cardValidationError.dateError === "" && dateTouched === false) {
        dateError = "Date is Required!";
        if (!notificationError) {
          notificationError = dateError;
        }
      } else {
        dateError = cardValidationError.dateError;
        if (!notificationError) {
          notificationError = dateError;
        }
      }

      if (cardValidationError.cvcError === "" && cvcTouched === false){
        cvcError = "Cvc is Requried!";
        if (!notificationError) {
          notificationError = cvcError;
        }
      } else {
        cvcError = cardValidationError.cvcError;
        if (!notificationError) {
          notificationError = cvcError;
        }
      }

      if (address.line1 === "") {
        line1Error = "Billing address is required!";
        if (!notificationError) {
          notificationError = line1Error;
        }
      }

      if (address.country === "") {
        countryError = "Country is required!";
        if (!notificationError) {
          notificationError = countryError;
        }
      }

      if (notificationError) {
        Notification({
          type: "error",
          title: "Error",
          description: notificationError
        });
      }
      setAddressError({
        line1Error,
        countryError,
      });
      setNameError(nameError);

      setCardValidationError({
        ...cardValidationError,
        cvcError,
        numberError,
        dateError,
      });

      return;
    }

    const card = elements.getElement(CardNumberElement);

    const { token } = await stripe.createToken(card);

    if (token) {
      if (user.payment) {
        dispatch(
          UpdateCustomer({
            token,
            name,
            address,
            stripeUserId: user.payment.stripeUserId,
            vatNumber
          })
        );
      } else {
        dispatch(CreateCustomer({
          token,
          name,
          address,
          vatNumber
        }));
      }
    } else {
      setToastMessage("Enter the Valid Card Number!");
      setSeverity("error");
      setToastOpen(true);
    }
  };

  const handleAddress = (e, key) => {
    if (key === "line1") {
      let line1Error = "";
      setAddressError({
        ...addressError,
        line1Error,
      });
    }
    if (key === "country") {
      let countryError = "";
      setAddressError({
        ...addressError,
        countryError,
      });
      setSelectedCountry(e);
    }

    setAddress({
      ...address,
      [key]: e.value,
    });
  };

  useEffect(() => {
    if (props.modal && success && user.payment) {
      props.handleCloseModal();
    }
  }, [success, user]);

  const handleName = (value) => {
    const name = value.trim();

    if (name) {
      setNameError("");
    }
    setName(name);
  };

  const handleCardValidationError = (e) => {
    const { elementType, error, complete, empty } = e;

    let cvcTouched = false;
    let dateTouched = false;
    let numberTouched = false;

    let cvcError = "";
    let dateError = "";
    let numberError = "";

    if (elementType === "cardNumber") {
      if (!isEmpty(error)) {
        numberError = error.message;
        numberTouched = true;
      } else if (complete) numberTouched = true;
      else if (empty) numberTouched = false;
      setCardValidationError({
        ...cardValidationError,
        numberError: numberError,
        numberTouched,
      });
    } else if (elementType === "cardCvc") {
      if (!isEmpty(error)) {
        dateError = error.message;
        cvcTouched = true;
      } else if (complete) cvcTouched = true;
      else if (empty) cvcTouched = false;
      setCardValidationError({
        ...cardValidationError,
        cvcError: dateError,
        cvcTouched,
      });
    } else if (elementType === "cardExpiry") {
      if (!isEmpty(error)) {
        cvcError = error.message;
        dateTouched = true;
      } else if (complete) dateTouched = true;
      else if (empty) dateTouched = false;
      setCardValidationError({
        ...cardValidationError,
        dateError: cvcError,
        dateTouched,
      });
    }
  };

  const Stripe_Options = {
    style: {
      base: {
        iconColor: "#c4f0ff",
        color: "#494F51",
        fontWeight: 500,
        fontSize: "10px",
        fontSmoothing: "antialiased",
        ":-webkit-autofill": {
          color: "#fce883",
        },
        "::placeholder": {
          color: "#C0C0C0",
        },
      },
      invalid: {
        iconColor: "#ffc7ee",
        color: "#ffc7ee",
      },
    },
  };

  const CardNumberOptions = {
    showIcon: true,
    placeholder: "Card Number",
    style: {
      base: {
        // iconColor: "#c4f0ff",
        color: "#494F51",
        fontWeight: 500,
        fontSize: "10px",
        fontSmoothing: "antialiased",
        ":-webkit-autofill": {
          color: "#fce883",
        },
        "::placeholder": {
          color: "#C0C0C0",
        },
      },
      invalid: {
        iconColor: "#ffc7ee",
        color: "#ffc7ee",
      },
    },
  };

  return (
    <>
      <div className="form-content">
        <LabelInput
          name="text"
          label="Cardholder Name*"
          placeholder="Full Name"
          onChange={(e) => handleName(e.target.value)}
        />
        <StripeInput>
          <Form.Label className="d-none">Card Number</Form.Label>
          <CardNumberElement
            className="cardnumber-stripe"
            onChange={(e) => handleCardValidationError(e)}
            options={CardNumberOptions}
            placeholder="Card Number"
          />
        </StripeInput>
        <StripeInput>
          <Form.Label className="d-none">Date</Form.Label>
          <div className="input-with-icon input-icon-left">
            <CardExpiryElement
              onChange={(e) => handleCardValidationError(e)}
              options={Stripe_Options}
            />
            <img className="input-icon" src={calendarIcon} alt="icon-img" />
          </div>
        </StripeInput>
        <StripeInput>
          <Form.Label className="d-none">CVC</Form.Label>
          <div className="input-with-icon input-icon-left">
            <CardCvcElement
              options={Stripe_Options}
              onChange={(e) => handleCardValidationError(e)}
              autoFocus={true}
            />
            <img className="input-icon" src={lockIcon} alt="icon-img" />
          </div>
        </StripeInput>

        <LabelInput
          name="text"
          placeholder="Full Address"
          onChange={(e) => handleAddress(e.target, 'line1')} 
        />
        <Select
          name="country"
          placeholder="Country"
          options={countries}
          handleChange={(e) => handleAddress(e, 'country')}
        />
        <LabelInput 
          name="text"
          placeholder="VAT Number"
          onChange={(e) => setVatNumber(e.target.value)}
        />
      </div>
      <div className="form-button">
        <Button
          text="Add payment method"
          className="outlined"
          onClick={handleSubmit}
          disabled={loading}
        />
      </div>
    </>
  );
};

const DataGathering = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [notify, setNotify] = useState(false);
  const [recovery, setRecovery] = useState(false);
  const [overAllProgress, setOverAllProgress] = useState(0);
  const [overAllEstimatedAmount, setOverAllEstimatedAmount] = useState('');
  const [showPaymentCard, setShowPaymentCard] = useState(false);
  const [useExistingNumber, setUseExistingNumber] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [showModal, setShowModal] = useState(false);

  const {
    user
  } = useSelector(state => state.auth);

  const {
    dataSyncProgress,
    error,
    message,
    loading,
    success
  } = useSelector(state => state.dataGathering);

  const {
    store
  } = useSelector(state => state.store);

  const { user: userData } = useSelector(state => state.user);

  const handleClick = (event) => {
    // 👇️ toggle isActive state on click
    setNotify((current) => !current);
  };
  const recoveryhandleClick = (event) => {
    // 👇️ toggle isActive state on click
    setRecovery((current) => !current);
  };

  const handleUseExistingNumber = () => {
    if (useExistingNumber) {
      setUseExistingNumber(false);
    } else {
      setUseExistingNumber(true)
    }
  };

  const handleEnableNotification = () => {
    if (useExistingNumber) {
      dispatch(EnableNotification({
        useExistingNumber,
        phoneNumber
      }));

      return;
    } else if (!phoneNumber || phoneNumber.length < 10 || phoneNumber.length > 14) {
      dispatch(SetDataGatheringState({ field: 'error', value: 'Invalid Phone Number' }));
    } else {
      dispatch(EnableNotification({
        useExistingNumber,
        phoneNumber
      }));
    }

    setPhoneNumber('');
  };

  useEffect(() => {
    console.log('get store calling from data gathering');
    dispatch(GetStore({ fromDataGatheringPage: 'true' }))

    return () => {
      console.log('Going to clear interval', intervalId);
      clearInterval(intervalId);
    }
  }, []);

  useEffect(() => {
    if (store && store._id) {
      dispatch(GetDataSyncProgress({
        storeId: store._id,
        progress: overAllProgress
      }));
      intervalId = setInterval(async () => {
        dispatch(GetDataSyncProgress({
          storeId: store._id,
          progress: overAllProgress
        }));
        console.log('intervalId', intervalId)
      }, 60000 * 5);
    }
  }, [store])

  useEffect(() => {
    console.log('overAllProgress', overAllProgress);
    if (user && user.syncStatus && overAllProgress >= 100) {
      history.push('/dashboard');
    } else if (overAllProgress >= 100) {
      dispatch(SetAuthState({ field: 'user', value: { ...user, syncStatus: true } }));
    }
  }, [user?.syncStatus, user?.payment, overAllProgress]);

  useEffect(() => {
    if (userData) {
      dispatch(SetAuthState({ field: 'user', value: { ...user, ...userData } }));
    }
  }, [userData]);

  useEffect(() => {
    if (dataSyncProgress) {
      const progress = overAllDataSyncProgress({
        lostPercentage: dataSyncProgress?.lostAdjustmentJobsPercentage || 0,
        damagedDestroyedPercentage: dataSyncProgress?.damagedDestroyedJobsPercentage || 0,
        fbaShipmentsPercentage: dataSyncProgress?.shipmentJobsPercentage || 0,
        feesPercentage: dataSyncProgress?.feeJobsPercentage || 0
      });

      setOverAllProgress(progress);

      const estimatedAmount = getEstimatedAmount({ amount: dataSyncProgress?.overAllEstimatedPrice || 0 });
      setOverAllEstimatedAmount(estimatedAmount || '$0');
    }
  }, [dataSyncProgress]);

  useEffect(() => {
    if (error) {
      Notification({
        type: "error",
        title: error,
        description: error
      });
      dispatch(SetDataGatheringState({ field: "error", value: "" }));

      return;
    }

    if (message) {
      Notification({
        type: "success",
        title: message,
        description: error
      });
    }

    if (success) {
      dispatch(SetDataGatheringState({ field: "success", value: false }));
      dispatch(SetDataGatheringState({ field: "message", value: "" }));

      return;
    }
  }, [error, success, message]);

  const hanldeAddAnotherStoreEvent = () => {
    console.log('handle click add store button');
    // history.push('/select-region')
  };

  return (
    <>
      <Header />
      <StoreWrapper>
        <Row className="justify-content-end section-heading">
          <Col md={11}>
            <h2>Data Gathering</h2>
            <div className="caption">
              We analyze your data to start filing claims tickets
            </div>
          </Col>
        </Row>
        <DataGatheringWrapper>
          <Row>
            <Col lg={{ span: 3, offset: 1 }} sm={7} className="data-gathering-divider">
              <div className="data-gathering-info">
                <div className="data-gathering-box">
                  <p>Lost </p>
                  <ProgressBar now={dataSyncProgress?.lostAdjustmentJobsPercentage || 0} />
                </div>
                <div className="data-gathering-box">
                  <p>Incorrect Fees </p>
                  <ProgressBar variant="progress2" now={dataSyncProgress?.feeJobsPercentage || 0} />
                </div>
                {/* <div className="data-gathering-box">
                  <p>Customer Returns </p>
                  <ProgressBar variant="progress3" now={60} />
                </div> */}
                <div className="data-gathering-box">
                  <p>Inbound / Outbound<br /> Shipping</p>
                  <ProgressBar variant="progress4" now={dataSyncProgress?.shipmentJobsPercentage || 0} />
                </div>
                <div className="data-gathering-box">
                  <p>Damage & Destroyed </p>
                  <ProgressBar variant="progress5" now={dataSyncProgress?.damagedDestroyedJobsPercentage || 0} />
                </div>
              </div>
            </Col>
            <Col sm={4}>
              <Row className="data-collapse-main">
                <Col
                  sm={6}
                  className={
                    notify
                      ? "gathering-collapse-open data-gathering-collapse pl-0"
                      : "data-gathering-collapse pl-0"
                  }
                >
                  <div className="data-collapse-top">
                    <ProgressBarCircular percentage={overAllProgress} />
                    <h3>{overAllProgress < 100 ? 'About 1 hour left' : 'Data Syncing Complete'}</h3>
                    <p>
                      Find out when we’ve
                      <br /> completed our analysis
                    </p>
                    <Button
                      onClick={handleClick}
                      className="outlined collpase-btn data-collapse-btn"
                      text="Enable text notifications"
                    />
                  </div>
                  <div
                    className={
                      notify
                        ? "data-collapse-open data-collapse-bottom"
                        : "data-collapse-bottom"
                    }
                  >
                    <div className="form-content form-content-primary">
                      <Form.Check
                        type="radio"
                        aria-label="radio 1"
                        label="Use Existing Mobile Number"
                        checked={useExistingNumber}
                        onClick={() => handleUseExistingNumber()}
                      />
                      <LabelInput
                        name="text"
                        label="Add New Mobile Number"
                        placeholder="+44"
                        value={phoneNumber}
                        disabled={useExistingNumber ? true : false}
                        onChange={(event) => setPhoneNumber(event.target.value)}
                      />
                    </div>
                    <div className="form-button">
                      <Button
                        text="Submit"
                        className="outlined"
                        onClick={() => handleEnableNotification()}
                      />
                    </div>
                  </div>
                </Col>
                <Col
                  sm={6}
                  className={
                    recovery
                      ? "gathering-collapse-open data-gathering-collapse"
                      : "data-gathering-collapse"
                  }
                >
                  <div className="data-collapse-top primary-variant">
                    <h3>
                      See how
                      <br /> much we’ve
                      <br /> found!
                    </h3>
                    <h2>{overAllEstimatedAmount}</h2>
                    <Button
                      onClick={() => setShowModal(true)}
                      className="outlined collpase-btn data-collapse-btn"
                      text="Start Recovering Money"
                    />
                  </div>
                </Col>
              </Row>
            </Col>
            <Col sm={4}>
              <div className="onboarding-space-right">
                <div className="onboarding-right-section h-100">
                  <div className="marketplace-region">
                    <div className="select-region">
                      <div className="region_block">
                        <div className="region-block-content">
                          <h3>Marketplaces connected</h3>
                          <div className="region-block-groups">
                            <div className="region-block-group">
                              <div className="d-flex align-items-center region-name">
                                <div className="round">
                                  <input
                                    type="checkbox"
                                    checked
                                    key="checkbox"
                                  />
                                  <label htmlFor="checkbox"></label>
                                </div>
                                <span>{regions[store?.region]}</span>
                              </div>
                              <div className="d-flex flex-wrap country-region ">
                                {store?.marketplaces?.map((marketplace, index) => (
                                  <img src={flags[marketplace?.countryCode]} key={index} />
                                ))}
                              </div>
                            </div>
                          </div>
                          <div className="form-button">
                            <Button
                              text="Add another store"
                              className="outlined"
                              onClick={hanldeAddAnotherStoreEvent}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="info-box-main no-bubbles">
                    <div className="info-block text-center">
                      <h3>Have a Question?</h3>
                      <p>Contact your case manager:</p>
                      <a href={`mailto:${AGENCIES_EMAIL[user.agencyId]}`}>
                        <Button text="Contact" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <div style={{ marginTop: '10%' }}>
            {showModal ? <Modal
              showModal={showModal}
              modalHeader="Add Credit Card & Address"
              modalBody={<div>
                <div style={{ marginBottom: '4px' }} >
                  <span style={{ fontSize: '12px', marginLeft: '2px', fontWeight: 'bold' }}>Please enter your payment details before we can start claiming your relevant cases </span>
                </div>
                <AddCard modal={true} handleCloseModal={() => setShowModal(false)} />
              </div>
              }
              handleConfirm={() => handleDelete(selectedId)}
              handleClose={() => setShowModal(false)}
              showFooter={false}
            />
              : null
            }
          </div>
        </DataGatheringWrapper>
      </StoreWrapper>
    </>
  );
};

export default DataGathering;