import { cloneDeep } from "lodash";
import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";

import { axiosBaseUrl } from '../../config/axios-configuration';

import SaveSentryLog from "../../helpers/sentry-log";

const axios = axiosBaseUrl();

export const GetJobsList = createAsyncThunk(
 'jobs/getJobsList',
  async (data, { rejectWithValue }) => {
    try {
      const {
        filters,
        skip,
        limit
      } = data;
      const response = await axios.get('/jobs/get-jobs', {
        params: {
         filters,
         skip,
         limit
        }
      });
      return response.data;
    } catch (err) {
      SaveSentryLog(new Error(err), 'GetJobsListError');
      if (err.response && err.response.data) {
        return rejectWithValue({ 
          err: {
            message: err.response.data.message,
            status: err.response.status
          }
        });
      }
      return rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

export const DeleteJob = createAsyncThunk(
  'jobs/deleteJob',
   async (data, { rejectWithValue }) => {
     try {
       const {
         id,
         jobType
       } = data;
       const response = await axios.delete('/jobs/delete-job', {
         params: {
          id,
          jobType
        }
       });
       return response.data;
     } catch (err) {
       SaveSentryLog(new Error(err), 'DeleteJobError');
       if (err.response && err.response.data) {
         return rejectWithValue({ 
           err: {
             message: err.response.data.message,
             status: err.response.status
           }
         });
       }
       return rejectWithValue({
         err: {
           message: 'Network Error',
           status: 502
         }
       });
     }
   }
);

export const RequeueJob = createAsyncThunk(
  'jobs/requeueJob',
   async (data, { rejectWithValue }) => {
     try {
       const {
         id,
         jobType
       } = data;
       const response = await axios.patch('/jobs/re-queue-job', {
         id,
          jobType
       });
       return response.data;
     } catch (err) {
       SaveSentryLog(new Error(err), 'RequeueJobError');
       if (err.response && err.response.data) {
         return rejectWithValue({ 
           err: {
             message: err.response.data.message,
             status: err.response.status
           }
         });
       }
       return rejectWithValue({
         err: {
           message: 'Network Error',
           status: 502
         }
       });
     }
   }
);

const job = createSlice({
    name: 'job',
    initialState: {
      error: '',
      loading: '',
      message: '',
      jobsList: [],
      success: false,
      total: 0
    },
    reducers: {
      SetJobsState(state, {payload: {field, value} }) {
        state[field] = value;
      }
    },
    extraReducers: {
      [GetJobsList.pending]: (state, action) => ({
        ...state,
        loading: true
      }),
      [GetJobsList.fulfilled]: (state, action) => ({
        ...state,
        success: true,
        loading: false,
        jobsList: action.payload.jobsList,
        total: action.payload.total
      }),
      [GetJobsList.rejected]: (state, action) => ({
        ...state,
        success: false,
        loading: false,
        error: action.payload.err.message
      }),
      [DeleteJob.pending]: (state, action) => ({
        ...state,
        loading: true
      }),
      [DeleteJob.fulfilled]: (state, action) => ({
        ...state,
        success: true,
        loading: false,
        message: action.payload.message
      }),
      [DeleteJob.rejected]: (state, action) => ({
        ...state,
        success: false,
        loading: false,
        error: action.payload.err.message
      }),
      [RequeueJob.pending]: (state, action) => ({
        ...state,
        loading: true
      }),
      [RequeueJob.fulfilled]: (state, action) => {
        const currentState = current(state);

        let jobList = cloneDeep(currentState.jobsList);

        const id = action.payload.updatedJob._id;

        const index =  jobList.findIndex(i => i._id === id);
        const { storeName, userName } = jobList[index]

        const obj = {
          ...action.payload.updatedJob,
          storeName,
          userName
        }

        jobList[index] = obj;
       
        return({
        ...state,
        success: true,
        loading: false,
        message: action.payload.message,
        jobsList: jobList
      })},
      [RequeueJob.rejected]: (state, action) => ({
        ...state,
        success: false,
        loading: false,
        error: action.payload.err.message
      })
    }
  });
  
  const { reducer, actions } = job;

  export const { SetJobsState } = actions;

  export default reducer;
