import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Form } from "react-bootstrap";
import { Formik, getIn } from 'formik';
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { BsExclamationCircleFill } from "react-icons/bs";
import { Edit } from "@mui/icons-material";

import * as yup from 'yup';
// import Icon from "../../components/icons";
import CheckIcon from "../../assets/icons/check-icon.svg";
import Notification from '../../components/notifications/notification'
import LabelInput from "../../components/Inputs/label-input";
import Spin from "../../components/Spin/Index";
import Button from "../../components/Button/index";
import Modal from "../../components/Modal/modal";

import { ProfileForm } from "./style";

import Payment from '../Dashboard/payment';

import {
  BillingPaymentWrapper,
  InfoBlock,
} from "./style";

import {
  ForgotPassword,
  LogOut,
  SetAuthState,
  UpdatePassword,
  ValidateUser,
} from "../../redux/slices/auth-slice";

import {
  UpdateUserInfo,
  GetUser,
  SetUserState,
  PauseUserCaseFiling
} from '../../redux/slices/user-slice';

import { AGENCIES_EMAIL, APP_DOMAINS } from '../../constants';

const schema = yup.object().shape({
  password: yup.string().required('Password is required').matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
  confirmPassword: yup.string().required('Confirm Password is Required').oneOf([yup.ref('password'), null], 'Passwords must match'),
  oldPassword: yup.string().required('Old Password is Required')
});

const updateEmailSchema = yup.object().shape({
  oldPassword: yup.string().required('Password is Required'),
  newEmail: yup.string().email('Please Enter a Valid Email').required('Email is Required'),
});

const Index = () => {
  const hostName = window.location.hostname;
  const dispatch = useDispatch();
  const {
    user,
    message,
    error,
    loading,
    isCaseFilingUpdated,
    updateCaseFilingLoading
  } = useSelector(state => state.user);
  const {
    user: userData,
    loading: authLoading,
    message: authMessage,
    error: authError,
    userValiated
  } = useSelector(state => state.auth);

  const handlePasswordReset = () => {
    dispatch(ForgotPassword({ email: user?.email }));
  };

  const handleSetPassword = () => {
    SetPasswordShow(!passwordShow);
  };

  const [passwordShow, SetPasswordShow] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [err, setErr] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [editEmail, setEditEmail] = useState({
    state: false,
    oldPassword: '',
    verified: false,
    otpSent: false,
    otp: ''
  });

  const [userInfo, setUserInfo] = useState({
    userId: user?.userId ||'',
    name: user?.name || '',
    number: user?.number || '',
    company: user?.company || '',
    address: user?.address || ''
  });

  const handleChangeUserInfo = (value, key) => {
    setUserInfo({
      ...userInfo,
      [key]: value
    });
  };

  const getStyles = (touched, errors, fieldName) => {
    if (touched[fieldName]) {
      if (getIn(errors, fieldName)) {
        return {
          border: '1px solid red'
        }
      }
    }
  };

  const handleUpdatePassword = ({ values }) => {
    if (values?.oldPassword && values?.password && values.confirmPassword) {
      dispatch(UpdatePassword({
        oldPassword: values?.oldPassword,
        password: values?.password,
        userId: userData?.userId
      }));
    }
  };

  const handleUpdateUserInfo = () => {
    const {
      name,
      company,
      number,
      address
    } = userInfo || {};

    if (!name && !company && !number && !address) {
      dispatch(SetUserState({ field: 'error', value: 'Enter any of the field' }));
    } else if (company?.length > 30) {
      dispatch(SetUserState({ field: 'error', value: 'Company name cannot be more than 30 characters' }));
    } else {
      dispatch(UpdateUserInfo({
        userId: userData?.userId,
        name,
        company,
        number,
        address
      }));
    }
  }

  const handleUpdateUserCaseFiling = () => {
    let caseFiling = true;
    if (user.caseFiling) {
      caseFiling = false;
    }

    dispatch(PauseUserCaseFiling({ caseFiling }));
    setShowModal(false);
  }

  useEffect(() => {
    if (isCaseFilingUpdated) {
      dispatch(SetUserState({ field: 'user', value: { ...user, caseFiling: !user.caseFiling } }));
      dispatch(SetUserState({ field: 'isCaseFilingUpdated', value: false }));
    }
  }, [isCaseFilingUpdated]);


  const copyToClipboard = (text) => {
    return navigator.clipboard
      .writeText(text)
      .then(t => true)
      .catch(e => false);
  };

  useEffect(() => {
    dispatch(GetUser());
  }, []);

  useEffect(() => {
    if (user) {
      setUserInfo({
        userId: user?.userId || '',
        name: user?.name || '',
        company: user?.company || '',
        number: user?.number || '',
        address: user?.address || ''
      });
    }
  }, [user]);

  useEffect(() => {
    if (error || authError) {
      Notification({
        type: "error",
        title: "Error",
        description: error || authError
      });
      dispatch(SetAuthState({ field: "error", value: "" }));
      dispatch(SetUserState({ field: "error", value: "" }))
      return;
    }

    if (message || authMessage) {
      Notification({
        type: "success",
        title: "Success",
        description: message || authMessage
      });
      dispatch(SetAuthState({ field: "message", value: "" }));
      if (!authMessage?.includes('OTP')) {
        dispatch(LogOut());
      } else if (editEmail?.verified) {
        handleEditEmailChange({ field:'otpSent', value: true})
      }
      dispatch(SetUserState({ field: "message", value: "" }));
      return;
    }
  }, [error, message, authMessage, authError]);

  useEffect(() => {
    if (userValiated) {
      handleEditEmailChange({ field: 'email', value: newEmail })
      dispatch(ForgotPassword({ email: userData?.email, newEmail: newEmail, purpose: 'emailUpdate' }));
      dispatch(SetAuthState({ field:'userValiated', value: null }));
      handleEditEmailChange({ field:'verified', value: true });
    }
  }, [userValiated]);

  const handleEditEmailChange = ({ field, value }) => {
    setEditEmail((prev) => ({
      ...prev,
      [field]: value
    }));
  }

  const validatePassword = ({values}) => {
    if (values?.oldPassword) {
      setNewEmail(values?.newEmail);
      dispatch(ValidateUser({
        oldPassword: values?.oldPassword,
        userId: userData?.userId,
        newEmail: values?.newEmail
      }));
    }
  }

  const handleOtpSubmission = () => {
    if (editEmail?.otpSent && editEmail.otp) {
      dispatch(UpdateUserInfo({ userId: user?.userId, verificationCode: editEmail.otp, email: editEmail.email}))
    }
  }

  return (
    <>
    <BillingPaymentWrapper className="profileWrapper">
      <div className="">
        <div className="page-header">
          <h3>Account & Profile Settings</h3>
          <p>
            Manage your Seller Repay contact information & General Account
            Settings
          </p>
        </div>
        {!updateCaseFilingLoading &&
        <ProfileForm>
          <Row>
            <Col md={3}>
              <div className="form-contact">
                <div className="form-header">
                  <h3>Contact Information</h3>
                  <p>Details to help us support you better</p>
                </div>
                <div className="form-content">
                  <h4>Basic Information</h4>
                  <LabelInput
                    placeholder="Name"
                    value={userInfo?.name}
                    onChange={(e) => handleChangeUserInfo(e.target.value, 'name')}
                  />
                  <LabelInput
                    placeholder="Phone Number"
                    value={userInfo?.number}
                    onChange={(e) => handleChangeUserInfo(e.target.value, 'number')}
                  />
                  <LabelInput
                    placeholder="Company Name (For billing invoices)"
                    value={userInfo?.company}
                    onChange={(e) => handleChangeUserInfo(e.target.value, 'company')}
                    style={userInfo?.company?.length > 30 ? { border: '1px solid red' } : {}}
                  />
                  <LabelInput
                    placeholder="Address"
                    value={userInfo?.address}
                    onChange={(e) => handleChangeUserInfo(e.target.value, 'address')}
                  />
                  <Button
                    className="outlined"
                    text="Update Contact Info"
                    onClick={() => handleUpdateUserInfo()}
                  />
                </div>
              </div>
            </Col>
            <Col md={1}>
              <div className="section-divider"></div>
            </Col>
            <Col md={3}>
              <div className="form-account">
                <div className="form-header">
                  <h3>Account Credentials</h3>
                  <p>Manage your login email and password</p>
                </div>
                <div className="form-content">
                  <div className="form-block-top">
                    <h4>Email for Login, Notifications, and Billing</h4>
                    <div className="email-inout-wrapper">
                      <LabelInput
                        name='email'
                        placeholder="Johnny@emailaddress.com"
                        value={editEmail?.email || user?.email || ''}
                        disabled={true}
                      />
                      {
                        !editEmail?.state && (
                          <Edit onClick={() => handleEditEmailChange({field:'state', value: true})}/>
                        )
                      }
                      {
                        err && (
                          <div style={{ marginBottom: '4px' }}><span style={{ color: 'red', fontSize: '8px' }}>{err}</span></div>
                        )
                      }
                      {
                        (editEmail?.verified && editEmail?.otpSent) && (
                          <>
                            <LabelInput
                              placeholder="Enter OTP"
                              onChange={(e) => handleEditEmailChange({ field: 'otp', value: e.target.value })}
                            />
                            <div className="d-flex align-items-center button-top-change justify-content-between">
                              <Button
                                text={loading ? "Saving...." : "Submit OTP"}
                                className=" mb-0 outlined"
                                type="submit"
                                width=""
                                outlined
                                disabled={(editEmail?.email && !err) ? false: true}
                                onClick={handleOtpSubmission}
                              />
                            </div>
                          </>
                        )
                      }
                    </div>
                    {
                      (editEmail?.state && !editEmail?.verified) && (
                        <Formik
                          validationSchema={updateEmailSchema}
                          onSubmit={(values) => validatePassword({ values })}
                          initialValues={{
                            oldPassword: '',
                            newEmail: ''
                          }}
                        >
                          {({
                            handleSubmit,
                            handleChange,
                            handleBlur,
                            values,
                            errors,
                            touched
                          }) => (
                            <Form onSubmit={handleSubmit}>
                              <div className="password-input position-relative">
                                <LabelInput
                                  name='newEmail'
                                  type={"text"}
                                  placeholder="Type New Email"
                                  value={values.newEmail}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  style={getStyles(touched, errors, 'newEmail')}
                                />
                                {touched.newEmail && errors.newEmail && (
                                  <div style={{ marginBottom: '4px' }}><span style={{ color: 'red', fontSize: '8px' }}>{errors.newEmail}</span></div>
                                )}
                                <LabelInput
                                  name='oldPassword'
                                  type={passwordShow ? "password" : "text"}
                                  placeholder="Type Old Password"
                                  value={values.oldPassword}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  style={getStyles(touched, errors, 'oldPassword')}
                                />
                                {touched.oldPassword && errors.oldPassword && (
                                  <div style={{ marginBottom: '4px' }}><span style={{ color: 'red', fontSize: '8px' }}>{errors.oldPassword}</span></div>
                                )}
                                <span className="eye-icon-second-wraper position-absolute">
                                  {passwordShow ? (
                                    <AiFillEyeInvisible onClick={handleSetPassword} />
                                  ) : (
                                    <AiFillEye onClick={handleSetPassword} />
                                  )}
                                </span>                    
                              </div>

                              <div className="d-flex align-items-center button-top-change justify-content-between">
                                <Button
                                  text={loading ? "Validating...." : "Submit"}
                                  className=" mb-0 outlined"
                                  type="submit"
                                  width=""
                                  outlined
                                />
                              </div>
                            </Form>
                          )}
                        </Formik>
                      )
                    }
                    <p className="note">
                      Need to change your email? <a href="#">Contact us</a>
                    </p>
                  </div>
                  <div className="">
                    <Formik
                      validationSchema={schema}
                      onSubmit={(values) => handleUpdatePassword({ values })}
                      initialValues={{
                        password: '',
                        confirmPassword: '',
                        oldPassword: ''
                      }}
                    >
                      {({
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                        errors,
                        touched
                      }) => (
                        <Form onSubmit={handleSubmit}>
                          <div className="password-input position-relative">
                            <LabelInput
                              name='oldPassword'
                              type={passwordShow ? "password" : "text"}
                              placeholder="Type Old Password"
                              value={values.oldPassword}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              style={getStyles(touched, errors, 'oldPassword')}
                            />
                            {touched.oldPassword && errors.oldPassword && (
                              <div style={{ marginBottom: '4px' }}><span style={{ color: 'red', fontSize: '8px' }}>{errors.oldPassword}</span></div>
                            )}
                            <span className="eye-icon-wraper position-absolute">
                              {passwordShow ? (
                                <AiFillEyeInvisible onClick={handleSetPassword} />
                              ) : (
                                <AiFillEye onClick={handleSetPassword} />
                              )}
                            </span>
                            <LabelInput
                              name='password'
                              type={passwordShow ? "password" : "text"}
                              placeholder="Type Password"
                              value={values.password}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              style={getStyles(touched, errors, 'password')}
                            />
                            {touched.password && errors.password && (
                              <div style={{ marginBottom: '4px' }}><span style={{ color: 'red', fontSize: '8px' }}>{errors.password}</span></div>
                            )}
                              <span className="eye-icon-second-wraper position-absolute">
                              {passwordShow ? (
                                <AiFillEyeInvisible onClick={handleSetPassword} />
                              ) : (
                                <AiFillEye onClick={handleSetPassword} />
                              )}
                            </span>
                            <LabelInput
                              name='confirmPassword'
                              type={passwordShow ? "password" : "text"}
                              placeholder="Confirm Your Password"
                              value={values.confirmPassword}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              style={getStyles(touched, errors, 'confirmPassword')}
                            />
                            {touched.confirmPassword && errors.confirmPassword && (
                              <div style={{ marginBottom: '4px' }}><span style={{ color: 'red', fontSize: '8px' }}>{errors.confirmPassword}</span></div>
                            )}
                            <span className="eye-icon-third-wraper position-absolute">
                              {passwordShow ? (
                                <AiFillEyeInvisible onClick={handleSetPassword} />
                              ) : (
                                <AiFillEye onClick={handleSetPassword} />
                              )}
                            </span>
                          </div>

                          <div className="d-flex align-items-center button-top-change justify-content-between">
                            <Button
                              text={loading ? "Saving...." : "Update Password"}
                              className=" mb-0 outlined"
                              type="submit"
                              width=""
                              outlined
                            />
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </Col>
            </Row>
            <Row>
            <Col md={3}>
              <div>
                {user.caseFiling ?
                  <p>
                    <img src={CheckIcon} alt="check icon" />  Active
                  </p>
                :
                  <p>
                    <BsExclamationCircleFill /> Inactive
                  </p>
                }
              </div>
              <Button
                  className="outlined"
                  text={user.caseFiling ? 'Pause Case Filing': 'Resume Case Filing'}
                  onClick={() => setShowModal(true)}
                />
            </Col>
          </Row>
        </ProfileForm>
}
      {showModal ? <Modal
        showModal={showModal}
        modalHeader="Confirm Case Filing"
        modalBody={<div>
          <p>
            Are you sure you want to {user.caseFiling ? 'Pause' : 'Resume'} your Case Filing? <br></br>
          </p>
        </div>
        }
        handleConfirm={() => handleUpdateUserCaseFiling()}
        handleClose={() => setShowModal(false)}
        showFooter={true}
      />
        : null
      }
      </div>
      {loading || authLoading || updateCaseFilingLoading && (
        <Spin />
      )}
    
    </BillingPaymentWrapper>
    {userData?.role === 'affiliate-manager' ? <div style={{ marginTop: '20px' }}><Payment /></div> : null}
    </>
  );
};

export default Index;
