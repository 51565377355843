import Styled from 'styled-components';

const PaginationWrapper = Styled.div`
  background-color:${({ theme }) => theme['pagination-bg']};
  padding: 6px 16px;
  font-size: ${({ theme }) => theme['medium-font-size']};
  border-top: 1px solid #4B5664;
    .item-per-page{
      position: relative;
      padding-right: 12px;
        &::after{
          content:"";
          position: absolute;
          right: 0;
          height: 24px;
          width: 1px;
          top: 4px;
          background: ${({ theme }) => theme['after-color']};
      }
      span {
        color: ${({ theme }) => theme['gray-text-color']};
        line-height: 18px;
      }
    }
    .total-pages{
      .select-pages {
        position: relative;
        padding-left: 6px;
        padding-right: 12px;
        .page-number {
          color: ${({ theme }) => theme['gray-text-color']};
          line-height: 18px;
        }
        &:before {
          content:"";
          position: absolute;
          left: 0;
          height: 24px;
          width: 1px;
          top: 4px;
          background: ${({ theme }) => theme['after-color']};
        }  
        &:after {
          content:"";
          position: absolute;
          right: 0;
          height: 24px;
          width: 1px;
          top: 4px;
          background: ${({ theme }) => theme['after-color']};
        }  
      }    
    }
      .arrows{
        position: relative;
        display: flex; 
        align-items: center;
        gap: 12px;
        padding-left: 12px;
        .arrow-border{
          height: 24px;
          width: 1px;
          background-color: ${({ theme }) => theme['after-color']};
        }
      }
      .left-controle{
        color: ${({ theme }) => theme['new-primary']};
        font-size: 18px;
    }
    .pagination-select {
      .select2-selection__value-container {
        padding: 0 6px;
      }
      .select2-selection__single-value {
        font-size: ${({ theme }) => theme['medium-font-size']};
      }
      .select2-selection__indicator {
        padding: 0;
      }
      .select2-selection__control {
        svg {
          color: ${({ theme }) => theme['new-primary']} !important;
        }
      }
    }
    .total-items {
        color: ${({ theme }) => theme['dark-grey-color']};
    }
`;

export  {PaginationWrapper};
