import io from 'socket.io-client';

import { GetPtRecoveryBreakdown, GetStore, SetStoreState } from '../redux/slices/store-slice';

export const SocketConnection = ({ url, userId }) => {
  const Socket = io(url);
  Socket.on('connect', () => {
    Socket.emit('add-user', { userId });
  })

  return Socket;
}

export const DisconnectSocket = (socket) => {
  socket.disconnect();
};

export const HandleStatusUpdate = (socket, dispatch, storeId, store) => {
  socket.on('status-update', (data) => {
    if (data?.storeId === storeId && data?.status !== store?.status) {
      dispatch(GetStore({ storeId }));
    }
  });
};

export const HandleInviteUpdate = (socket, dispatch, storeId) => {
  socket.on('invite-update', (data) => {
    if (data?.storeId === storeId && data?.invite) {
      dispatch(GetStore({ storeId }));
    }
  });
};

export const HandleRecoveryUpdate = (socket, dispatch, storeId, potentialRecovery) => {
  socket.on('recovery-update', (data) => {
    if (storeId === data?.storeId && potentialRecovery !== data?.potentialRecovery) {
      dispatch(SetStoreState({ field: 'potentialRecovery', value: data.potentialRecovery}));
      dispatch(GetPtRecoveryBreakdown({ storeId }));
    }
  });
};

export const HandlePermissionUpdate = (socket, dispatch, storeId) => {
  socket.on('permission-update', (data) => {
    if (data?.storeId === storeId && Object.keys(data?.permissions)?.length > 0) {
      dispatch(GetStore({ storeId }));
    }
  });
};
