import Styled from 'styled-components';

const JobsWrapper = Styled.div`
  .jobs{
    background-color: ${({ theme }) => theme['white-color']};
    margin:10px;
    padding:10px;
    border-radius:5px;
    margin-top:10px;
    h1{
        color: ${({ theme }) => theme['secondary-color']};
    }
  }
  .error {
    position: absolute;
    left: 40%;
    top: 50%;
    h1{
      color: black;
    }
 } 
`;

export { JobsWrapper };
