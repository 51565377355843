import styled from "styled-components";
const SelectWrapper = styled.div`
  label {
    margin-bottom: 4px;
    color: ${({ theme }) => theme["text-secondary"]};
    font-size: ${({ theme }) => theme["base-font-size-small"]};
    margin-top: 5px;
  }
  .select2-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
    &.store-select {
      margin-bottom: 0px;
      width: 232px;
    }
    &.w-232 {
      width: 232px;
    }
    &.dashbaord-select {
      width: 250px;
      margin-bottom: 0;
    }
    &.job-selectors {
      width: 215px;
      margin-bottom: 30px;
    }
    &.date-select {
      width: 250px;
      margin-bottom: 0;
    }
    &.pagination-select {
      width: fit-content;
      margin-bottom: 0;
      .select2-selection__control {
        background: transparent;
        border: none;
        font-weight: 600;
      }
    }
    .select2-selection__placeholder,
    .select2-selection__single-value {
      color: ${({ theme }) => theme["regent_gray"]};
      font-size: ${({ theme }) => theme["base-font-size-small"]};
    }
    .select2-selection__control {
      border: 1px solid #cfd8dc;
      max-height: 32px;
      min-height: 32px;
      &:focus {
        outline: none;
      }
      &.select2-selection__control--is-focused {
        box-shadow: none !important;
      }
      .select2-selection__rendered {
        line-height: 36px;
        padding-left: 12px;
        color: $input-color;
      }

      .select2-selection__arrow {
        height: 34px;
        width: 34px;
        right: 3px;
      }

      .select2-selection__placeholder {
        color: #ccc;
      }
      svg {
        color:${({theme}) => `${theme['primary']}`};
        height: 15px;
        width: 15px;
      }
    }
    .select2-selection__indicator-separator {
      display: none;
    }
    .select2-selection__input-container {
      margin: 0;
    }
  }
`;
export { SelectWrapper };
