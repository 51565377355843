import React from "react";
import { FormGroup } from "react-bootstrap";
import Select from "react-select";
import { SelectWrapper } from "./style";

const ReactSelect = (props) => {
  const {
    label,
    className,
    options,
    defaultValue,
    components,
    menuPlacement,
    styles,
    theme,
    handleChange,
    value,
    width,
    placeholder,
    isClearable,
    disabled
  } = props;

  return (
    <SelectWrapper style={{width: `${width}px`}}>
      <FormGroup className={`select2-container ${className}`}>
        {label ? <label>{label}</label> : null}
        <Select
        isDisabled={disabled}
          value= {value}
          theme={theme}
          placeholder= {placeholder}
          isClearable={isClearable}
          styles={styles}
          defaultValue={defaultValue}
          menuPlacement={menuPlacement}
          options={options}
          components={components}
          classNamePrefix="select2-selection"
          onChange={handleChange}
        />
      </FormGroup>
    </SelectWrapper>
  );
};
export default ReactSelect;
