import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { extend } from 'lodash';

import CricleImage from '../../assets/images/Circles.svg';
import Button from '../../components/Button/index';
import { StoreWrapper } from "./style";
import Table from "react-bootstrap/Table";
import LabelInput from "../../components/Inputs/label-input";
import Select from "../../components/Select/index"
import Form from 'react-bootstrap/Form';
import CopyImg from '../../assets/images/copyicon.svg';
import Header from "../Header/header";

import { GetLatestAmazonAccountDetail } from '../../redux/slices/account-detail-slice';
import { SetAuthState } from '../../redux/slices/auth-slice';
import { GetStore, UpdateStoreData } from '../../redux/slices/store-slice';

import { AGENCIES_EMAIL, APP_DOMAINS, MANAGER_NAMES, SELLER_CENTRAL_EMAILS } from '../../constants';

const hostName = window.location.hostname;

const sellerCentralEmail = SELLER_CENTRAL_EMAILS;

const naUrl = 'https://sellercentral.amazon.com/gp/account-manager/home.html/ref=xx_userperms_dnav_xx';
const euUrl = 'https://sellercentral.amazon.co.uk/gp/account-manager/home.html/ref=xx_userperms_dnav_xx';
const feUrl = 'https://sellercentral-japan.amazon.com/gp/account-manager/home.html/ref=xx_userperms_dnav_xx';

const AddUser = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { user, showSidebar } = useSelector((state) => state.auth)
  const { latestAmazonAccountDetail } = useSelector((state) => state.accountDetail);
  const { store, storeId } = useSelector((state) => state.store);

  const copyToClipboard = (text) => {
    
    return navigator.clipboard
      .writeText(text)
      .then(t => true)
      .catch(e => false);
  };

  const handleInviteClick = () => {
    const { region } = store;

    console.log('click', region)
    if (region === 'na') {
      window.open(naUrl, "_blank");
    } else if (region === 'eu') {
      window.open(euUrl, "_blank");
    } else if (region === 'fe') {
      window.open(feUrl, "_blank");
    }
  };

  useEffect(() => {
    dispatch(GetStore({ storeId }));
    dispatch(GetLatestAmazonAccountDetail());
  }, []);

  useEffect(() => {
    if (hostName === APP_DOMAINS.sellerRepay) {
      extend(sellerCentralEmail, { [hostName]: latestAmazonAccountDetail });
    }
    dispatch(UpdateStoreData({ storeId, secondaryUserEmail: sellerCentralEmail[hostName] }));
  }, [latestAmazonAccountDetail]);

  useEffect(() => {
    if (store) {
      const { status } = store;
      if (status) {
        dispatch(SetAuthState({ field: "storeStatus", value: status }));
      }
      if (status === "Invite_Accepted") {
        history.push("/permissions");
      } else if (status === "Connected") {
        history.push("/store-success");
      }
    }
  }, [store]);

  return (
    <>
      {showSidebar ? null : <Header />}
      <StoreWrapper>
        <Row>
          <Col lg={{ span: showSidebar ? 7 : 6 , offset: showSidebar ? 0 : 1 }} sm={7} className="">
            <div className="permission-scroll">
              <div className='justify-content-end section-heading'>
                <h2>Step 1: Add New User</h2>
              </div>
              <div className="add-details d-flex">
                <div>
                  <Button width="130px" text="Add New User" className="outlined" onClick={handleInviteClick} />
                </div>
                <p>or Sign into your Seller Central Account click <strong>'Settings’</strong> Tab, then <strong>'User Permissions’</strong> </p>
              </div>
              <div className="caption">Enter the Information below, then click the ‘Invite’ button:</div>
              <div className="table-region-main">
                <Table className="table-region">
                  <thead>
                    <tr>
                      <th width="178px">Name</th>
                      <th width="193px">Email</th>
                      <th width='101px'>Language</th>
                      <th colSpan={2}>Add to Global Account</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <LabelInput
                          name='name'
                          type="text"
                          value={MANAGER_NAMES[hostName]}
                          icon imgicon={CopyImg}
                          copyToClipboard={() => copyToClipboard(MANAGER_NAMES[hostName])}
                        />
                      </td>
                      <td>
                        <LabelInput
                          name='email'
                          type="email"
                          value={sellerCentralEmail[hostName]}
                          icon imgicon={CopyImg}
                          copyToClipboard={() => copyToClipboard(sellerCentralEmail[hostName])}
                        />
                      </td>
                      <td>
                        <Select className="" width="75px"
                          disabled={true}
                          placeholder='English'
                        />
                      </td>
                      <td>
                        <div className="add-global-account">
                          <Form.Check disabled aria-label="option 1" />
                        </div>
                      </td>
                      <td><Button width="56px" text="Invite" className="outlined invite-btn" onClick={handleInviteClick} /></td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              <p className="region-note">Once you have Invited Seller Repay Manager as a New User, Click ‘Continue’</p>

              <div className='add-button-space text-right'>
                <Button className="outlined" text="Continue" onClick={() => history.push({
                  pathname: '/waiting-screen',
                  state: {
                    step: 'Step 1',
                    content: 'accept your invitation',
                    nextStep: 'Step 2'
                  }
                })} />
              </div>
            </div>
          </Col>
          <Col sm={5}>
            <div className={showSidebar ? null : "inviting-user-wrapper"}>
            <div className={ !showSidebar ? "onboarding-space-right": "new-wrapper-without-side"}>
              <div className="onboarding-right-section">
                <div className="info-box-main">
                  <div className="circle-section">
                    <img src={CricleImage} alt="circles" />
                  </div>
                  <div className="info-block text-center">
                    <h3>Have a Question?</h3>
                    <p>Contact your case manager:</p>
                    <a href={`mailto:${AGENCIES_EMAIL[user.agencyId]}`}>
                      <Button text="Contact" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </Col>
        </Row>

      </StoreWrapper>
    </>
  );
};

export default AddUser;
