import styled from "styled-components";
import { ProSidebar } from "react-pro-sidebar";
const SidebarWrapper = styled(ProSidebar)`
  color: ${({ theme }) => theme["body-text"]};
  overflow: auto;
  z-index: 9;
  width: 220px;
  min-width: 220px;
  .affiliate-height{
    height: calc(130vh - 0px);
  }
  .general-height{
    height: calc(100vh - 0px);
  }
  .pro-sidebar-inner {
    background: ${({ theme }) => theme["secondary"]};
    height: 100vh;
  }
  .pro-sidebar-header {
    text-align: center;
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .pro-sidebar-footer {
    li {
      list-style: none;
    }
  }
  .pro-menu {
    padding-top: 8px;
    padding-bottom: 7px;
    .pro-menu-item {
      .pro-inner-item {
        padding: 8px 8px;
        font-size: 12px;
        line-height: 15px;
        font-weight: 400;
        color: ${({ theme }) => theme["on-secondary"]};
        padding-left: 24px;
        cursor: pointer;
        .pro-icon-wrapper {
          font-size: 16px;
          width: 24px;
          min-width: 24px;
          height: 24px;
          line-height:24px;
          color: ${({ theme }) => theme["primary"]};
          .pro-icon{
            svg{
              fill:currentColor;
            }
          }
        }
        &:after {
          content: "";
          background-color: ${({ theme }) => theme["secondary-variant"]};
          bottom: 0;
          height: 1px;
          position: absolute;
          width: calc(100% - 24px);
          transition: 0.3s all ease-in-out;
          left: 12px;
          display: none;
        }
        &:hover,
        &:focus,.active {
          color: ${({ theme }) => theme["primary"]};
          background-color: ${({ theme }) => theme["secondary"]};
          .pro-item-content {
            &:after {
              background-color: ${({ theme }) => theme["secondary-variant"]};
              width: 100%;
              left: 0;
              transition: 0.3s all ease-in-out;
            }
          }

          .pro-icon-wrapper {
            .pro-icon {
              animation: none;
            }
          }
        }
      }
      &.active {
        color: ${({ theme }) => theme["primary"]};
        .pro-inner-item {
          color: ${({ theme }) => theme["primary"]};
        }
      }
      &.pro-sub-menu {
        .pro-inner-list-item {
          padding-left: 40px;
          ul {
            padding-top: 0;
            padding-bottom: 0;
            li {
            }
          }
          .pro-menu-item {
            .pro-item-content {
              &:before {
                content: "";
                height: 70%;
                width: 1px;
                position: absolute;
                left: -8px;
                top: 7px;
                display: none;
                background-color: ${({ theme }) => theme["primary"]};
              }
            }
            &:hover,
            &.active {
              .pro-item-content {
                &:before {
                  display: block;
                }
              }
            }
          }
        }
        &.open {
          position: relative;
          .pro-inner-item {
            background-color: ${({ theme }) => theme["secondary"]};
            &:after {
              display: none;
            }
          }
          &:after {
          }
          .pro-inner-list-item {
            .pro-menu-item {
              &:hover,
              &:focus,
              &.active {
                .pro-item-content {
                  &:before {
                    left: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
    ul {
      .pro-sub-menu {
        .pro-inner-list-item {
          background-color: ${({ theme }) => theme["secondary"]};
        }
      }
    }
    a {
      &:hover {
        color: ${({ theme }) => theme["primary"]};
      }
    }
  }
  .pro-sidebar-content {
    .pro-menu {
      border-bottom: 1px solid ${({ theme }) => theme["secondary-variant"]};
      &:last-of-type {
        border-bottom: 0px;
      }
    }
  }
  .pro-sidebar-footer {
        .stepper_side_footer {
              padding: 0px !important;
              flex-wrap: wrap;
              .footer-st-buttons{
                button{
                  padding:8px 10px;
                  font-size:9px;
                  img{
                    width:14px;
                  }
                }
              }
          }
    .pro-inner-item {
      padding: 18px 8px 24px;
      font-size: 12px;
      line-height: 15px;
      font-weight: 400;
      color: ${({ theme }) => theme["on-secondary"]};
      display: flex;
      cursor: pointer;
      align-items: center;
      padding-left: 24px;
      .pro-icon-wrapper {
        font-size: 16px;
        margin-right: 10px;
        font-size: 14px;
        width: 24px;
        min-width: 24px;
        height: 24px;
        line-height: 24px;
        text-align: center;
        display: inline-block;
        color: ${({ theme }) => theme["primary"]};
        .pro-icon {
          display: flex;
          height: 100%;
          align-items: center;
          justify-content: center;
          svg{
            fill:currentColor;
          }
        }
      }
      &:after {
        content: "";
        background-color: ${({ theme }) => theme["secondary-variant"]};
        bottom: 0;
        height: 1px;
        position: absolute;
        width: calc(100% - 24px);
        transition: 0.3s all ease-in-out;
        left: 12px;
        display: none;
      }
      &:hover,
      &:focus {
        color: ${({ theme }) => theme["primary"]};
        background-color: ${({ theme }) => theme["secondary"]};
        .pro-item-content {
          &:after {
            background-color: ${({ theme }) => theme["secondary-variant"]};
            width: 100%;
            left: 0;
            transition: 0.3s all ease-in-out;
          }
        }

        .pro-icon-wrapper {
          .pro-icon {
            animation: none;
          }
        }
      }
    }
    &.active {
      color: ${({ theme }) => theme["primary"]};
      .pro-inner-item {
        color: ${({ theme }) => theme["primary"]};
      }
    }
    &.pro-sub-menu {
      .pro-inner-list-item {
        padding-left: 40px;
        ul {
          padding-top: 0;
          padding-bottom: 0;
          li {
          }
        }
        .pro-menu-item {
          .pro-item-content {
            &:before {
              content: "";
              height: 70%;
              width: 1px;
              position: absolute;
              left: -8px;
              top: 7px;
              display: none;
              background-color: ${({ theme }) => theme["secondary"]};
            }
          }
          &:hover,
          &.active {
            .pro-item-content {
              &:before {
                display: block;
              }
            }
          }
        }
      }
      &.open {
        position: relative;
        .pro-inner-item {
          background-color: ${({ theme }) => theme["secondary"]};
          &:after {
            display: none;
          }
        }
        &:after {
        }
        .pro-inner-list-item {
          .pro-menu-item {
            &:hover,
            &:focus,
            &.active {
              .pro-item-content {
                &:before {
                  left: 0;
                }
              }
            }
          }
        }
      }
    }
  }
  &.collapsed {
    width: 52px;
    min-width: 52px;
    .pro-menu {
      ul {
        .pro-menu-item {
          .pro-inner-list-item {
            background-color: ${({ theme }) => theme["secondary"]};
            .pro-menu-item {
              &:hover,
              &.active {
                .pro-item-content {
                  &:before {
                    /* display: none; */
                  }
                }
              }
            }
          }

          &.pro-sub-menu {
            &:hover {
              .pro-inner-item {
                background-color: ${({ theme }) => theme["secondary"]};
                &:after {
                  background-color: ${({ theme }) => theme["secondary"]};
                  width: 100%;
                  left: 0;
                  transition: 0.3s all ease-in-out;
                }
              }
              .pro-icon-wrapper {
                color: ${({ theme }) => theme["primary"]};
                .pro-icon {
                  animation: none;
                }
              }
            }
            &.open {
              .pro-inner-list-item {
                .pro-menu-item {
                  &:hover,
                  &.active {
                    .pro-item-content {
                      &:before {
                        left: -8px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .pro-menu-item.pro-sub-menu {
          .pro-inner-list-item {
            .popper-inner {
              background-color: ${({ theme }) => theme["secondary"]};
              padding: 12px 24px;
              .pro-inner-item {
                &:after {
                  display: none;
                }
              }
            }
          }
        }
      }
      &:last-of-type {
        border-bottom: 0;
      }
    }
  }

  /* .react-slidedown {
  height: 0;
  transition-property: none;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out; } */
`;

const Content = styled.div`
  width: 100%;
  padding-top: 52px;
  height: 100vh;
  overflow: hidden;
  display: flex;
  .dashboard-top-space {
    padding-top: 32px !important;
    padding-bottom: 0 !important;
  }
`;
const HeaderWrappper = styled.div`
  height: 52px;
  background-color: ${({ theme }) => theme["primary"]};
  position: fixed;
  width: 100%;
  z-index: 99;
  /* padding:0px 12px; */
  padding: 0px 36px 0px 12px;
  .header-wrapper {
    width: 100%;
    .header-logo {
      font-size: 14px;
    }
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0px;
    text-align: left;
    color: ${({ theme }) => theme["on-primary"]};
    /* margin-left:24px; */
    margin-left: 52px;
    letter-spacing: -1px;
  }
  button#dropdown-basic {
    width: 34px;
    border-radius: 40px;
    height: 34px;
    background: ${({ theme }) => theme["on-primary"]};
    color: ${({ theme }) => theme["primary"]};
    font-size: 14px;
    border: none;
    &::after {
      display: none;
    }
    .drop-icon {
      position: absolute;
      top: 22px;
      right: 0;
    }
  }
`;
export { Content, SidebarWrapper, HeaderWrappper };
