import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { axiosBaseUrl } from '../../config/axios-configuration';

import SaveSentryLog from '../../helpers/sentry-log';

const axios = axiosBaseUrl();

export const SignIn = createAsyncThunk(
  'auth/signIn',
  async (data, { rejectWithValue }) => {
    try {
      const { email, password, hostName } = data;
      const response = await axios.post('/auth/sign-in', { email, password, hostName });
      return response.data;
    } catch (err) {
      SaveSentryLog(new Error(err), 'SignInError');
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: {
            message: err.response.data.message,
            status: err.response.status
          }
        });
      }
      return rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

export const SignUp = createAsyncThunk(
  'auth/signUp',
  async (data, { rejectWithValue }) => {
    try {
      const {
        values,
        phoneNumber,
        promoCode,
        affiliateId,
      } = data || {};

      const {
        firstName,
        lastName,
        email,
        password,
        company
      } = values;

      const response = await axios.post('/auth/sign-up', {
        firstName,
        lastName,
        company,
        email,
        password,
        hostName: window.location.host,
        phoneNumber,
        promoCode,
        affiliateId
      });
      return response.data;
    } catch (err) {
      SaveSentryLog(new Error(err), 'SignUpError');
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: {
            message: err.response.data.message,
            status: err.response.status
          }
        });
      }
      return rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

export const ForgotPassword = createAsyncThunk(
  'auth/forgot-password',
  async (data, { rejectWithValue }) => {
    try {
      const {
        email,
        purpose,
        newEmail
      } = data;

      const response = await axios.post('/auth/forgot-password', {
        email,
        purpose,
        hostName: window.location.host,
        newEmail
      });
      return response.data;
    } catch (err) {
      SaveSentryLog(new Error(err), 'ForgotPasswordError');
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: {
            message: err.response.data.message,
            status: err.response.status
          }
        });
      }
      return rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

export const ResetPassword = createAsyncThunk(
  'auth/reset-password',
  async (data, { rejectWithValue }) => {
    try {
      const {
        password,
        verificationCode
      } = data;

      const response = await axios.post('/auth/reset-password', {
        password,
        verificationCode
      });
      return response.data;
    } catch (err) {
      SaveSentryLog(new Error(err), 'ResetPasswordError');
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: {
            message: err.response.data.message,
            status: err.response.status
          }
        });
      }
      return rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

export const UpdatePassword = createAsyncThunk(
  'auth/update-password',
  async (data, { rejectWithValue }) => {
    try {
      const {
        oldPassword,
        password,
        userId
      } = data;

      const response = await axios.post('/auth/update-password', {
        oldPassword,
        password,
        userId
      });
      return response.data;
    } catch (err) {
      SaveSentryLog(new Error(err), 'UpdatePasswordError');
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: {
            message: err.response.data.message,
            status: err.response.status
          }
        });
      }
      return rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

export const ValidateUser = createAsyncThunk(
  'auth/validate-user',
  async (data, { rejectWithValue }) => {
    try {
      const {
        oldPassword,
        userId,
        newEmail
      } = data;

      const response = await axios.post('/auth/validate-user', {
        oldPassword,
        userId,
        newEmail
      });
      return response.data;
    } catch (err) {
      SaveSentryLog(new Error(err), 'ValidateUserError');
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: {
            message: err.response.data.message,
            status: err.response.status
          }
        });
      }
      return rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

export const ValidateAndGenerateToken = createAsyncThunk(
  'auth/validate-and-generate-token',
  async (data, { rejectWithValue }) => {
    try {
      const {
        email,
        hostName
      } = data;

      const response = await axios.post('/auth/validate-and-generate-token', {
        email,
        hostName
      });
      console.log({ response: response.data })
      return response.data;
    } catch (err) {
      SaveSentryLog(new Error(err), 'ValidateAndGenerateTokenError');
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: {
            message: err.response.data.message,
            status: err.response.status
          }
        });
      }
      return rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

export const GetDataSyncProgress = createAsyncThunk(
  'auth/get-data-sync-progress',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get('/auth/get-data-sync-progress', {
        params: {
          userId: data.userId
        }
      });
      return response.data;
    } catch (err) {
      SaveSentryLog(new Error(err), 'GetDataSyncProgressError');
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: {
            message: err.response.data.message,
            status: err.response.status
          }
        });
      }
      return rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

export const VerifyUser = createAsyncThunk(
  'auth/verifyEmail', async (data, { rejectWithValue }) => {
    try {
      const {
        verificationCode
      } = data || {};
      const response = await axios.get('/auth/verify-user', {
        params: {
          verificationCode
      }});
      return response.data;
    } catch (err) {
      SaveSentryLog(new Error(err), 'VerifyUserError');
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: {
            message: err.response.data.message,
            status: err.response.status
          }
        });
      }
      return rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

export const VerifyForgotPasswordOtp = createAsyncThunk(
  'auth/verifyForgotPasswordOtp', async (data, { rejectWithValue }) => {
    try {
      const {
        verificationCode
      } = data || {};
      const response = await axios.get('/auth/verify-forgot-password-otp', {
        params: {
          verificationCode
      }});
      return response.data;
    } catch (err) {
      SaveSentryLog(new Error(err), 'VerifyForgotPasswordOtpError');
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: {
            message: err.response.data.message,
            status: err.response.status
          }
        });
      }
      return rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

export const ResendVerifyEmail = createAsyncThunk(
  'auth/resendVerifyEmail', async (data, { rejectWithValue }) => {
    try {
      const {
        email
      } = data || {};
      const response = await axios.get('/auth/resend-verify-user', {
        params: {
          email
      }});
      return response.data;
    } catch (err) {
      SaveSentryLog(new Error(err), 'ResendVerifyEmailError');
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: {
            message: err.response.data.message,
            status: err.response.status
          }
        });
      }
      return rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

export const ImpersonateUser = createAsyncThunk(
  'auth/impersonate-user', async (data, { rejectWithValue }) => {
    try {
      const {
        userId
      } = data || {};
      const response = await axios.get('/auth/impersonate-user', {
        params: {
          userId
      }});
      return response.data;
    } catch (err) {
      SaveSentryLog(new Error(err), 'ImpersonateUserError');
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: {
            message: err.response.data.message,
            status: err.response.status
          }
        });
      }
      return rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

const auth = createSlice({
  name: 'auth',
  initialState: {
    error: '',
    loading: false,
    message: '',
    success: false,
    token: '',
    user: {},
    storesCount: 0,
    storeStatus: null,
    showSidebar: false,
    storeId: '',
    dataSyncProgress: '',
    syncStatus: false,
    nextPage: false,
    menuType: '',
    verifyToken: '',
    verifyEmailResend: false,
    payLater: false,
    connectedStore: '',
    verificationCode: '',
    amazonUrl: '',
    amazonOnbarding: null,
    admin: '',
    adminToken: '',
    inImpersonation: false,
    impersonateLoading: false
  },
  reducers: {
    SetAuthState(state, { payload: { field, value } }) {
      state[field] = value;
    },
    LogOut: () => ({
      error: '',
      loading: false,
      message: '',
      success: false,
      token: '',
      user: {},
    })
  },
  extraReducers: {
    [SignIn.pending]: (state, action) => ({
      ...state,
      loading: true
    }),
    [SignIn.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      success: true,
      message: action.payload.message,
      token: action.payload.token,
      adminToken: action.payload.user.role === 'admin' ? action.payload.token : '',
      user: action.payload.user,
      admin: action.payload.user.role === 'admin' ? action.payload.user : '',
      storesCount: action.payload.storesCount,
      storeStatus: action.payload.storeStatus,
      showSidebar: action.payload.showSidebar,
      storeId: action.payload.storeId,
      payLater: action.payload.payLater,
      connectedStore: action.payload.storeId
    }),
    [SignIn.rejected]: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload.err.message
    }),
    [SignUp.pending]: (state, action) => ({
      ...state,
      loading: true
    }),
    [SignUp.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      success: true,
      message: action.payload.message,
      verifyToken: action.payload.token
    }),
    [SignUp.rejected]: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload.err.message
    }),
    [ForgotPassword.pending]: (state, action) => ({
      ...state,
      loading: true
    }),
    [ForgotPassword.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      success: true,
      message: action.payload.message
    }),
    [ForgotPassword.rejected]: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload.err.message
    }),
    [ResetPassword.pending]: (state, action) => ({
      ...state,
      loading: true
    }),
    [ResetPassword.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      success: true,
      message: action.payload.message
    }),
    [ResetPassword.rejected]: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload.err.message
    }),
    [UpdatePassword.pending]: (state, action) => ({
      ...state,
      loading: true
    }),
    [UpdatePassword.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      success: true,
      message: action.payload.message
    }),
    [UpdatePassword.rejected]: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload.err.message
    }),
    [ValidateUser.pending]: (state, action) => ({
      ...state,
      loading: true,
      success: false
    }),
    [ValidateUser.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      success: true,
      userValiated: true
    }),
    [ValidateUser.rejected]: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload.err.message
    }),
    [ValidateAndGenerateToken.pending]: (state, action) => ({
      ...state,
      loading: true
    }),
    [ValidateAndGenerateToken.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      success: true,
      message: action.payload.message,
      token: action.payload.token,
      user: action.payload.user,
      storesCount: action.payload.storesCount,
      storeStatus: action.payload.storeStatus,
      showSidebar: action.payload.showSidebar,
      storeId: action.payload.storeId
    }),
    [ValidateAndGenerateToken.rejected]: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload.err.message
    }),
    [GetDataSyncProgress.pending]: (state, action) => ({
      ...state,
      loading: true
    }),
    [GetDataSyncProgress.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      syncStatus: action.payload.syncStatus,
      dataSyncProgress: action.payload.dataSyncProgress,
      user: {
        ...state.user,
        syncStatus: action.payload.syncStatus
      }
    }),
    [GetDataSyncProgress.rejected]: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload.err.message
    }),
    [VerifyUser.pending]:(state, action)=>({
      ...state,
      loading: true
    }),
    [VerifyUser.fulfilled]: (state, action) => ({
      ...state,
      success: true,
      loading: false,
      message: action.payload.message
    }),
    [VerifyUser.rejected]: (state, action) => ({
      ...state,
      success: false,
      loading: false,
      error: action.payload.err.message
    }),
    [VerifyForgotPasswordOtp.pending]:(state, action)=>({
      ...state,
      loading: true
    }),
    [VerifyForgotPasswordOtp.fulfilled]: (state, action) => ({
      ...state,
      success: true,
      loading: false,
      verificationCode: action.payload.verificationCode,
      message: action.payload.message
    }),
    [VerifyForgotPasswordOtp.rejected]: (state, action) => ({
      ...state,
      success: false,
      loading: false,
      error: action.payload.err.message
    }),
    [ResendVerifyEmail.pending]:(state, action)=>({
      ...state,
      loading: true,
      verifyEmailResend: false
    }),
    [ResendVerifyEmail.fulfilled]: (state, action) => ({
      ...state,
      success: true,
      loading: false,
      verifyEmailResend: true,
      message: `You'll receive an email soon!`
    }),
    [ResendVerifyEmail.rejected]: (state, action) => ({
      ...state,
      success: false,
      loading: false,
      verifyEmailResend: false,
      error: action.payload.err.message
    }),
    [ImpersonateUser.pending]: (state, action) => ({
      ...state,
      impersonateLoading: true
    }),
    [ImpersonateUser.fulfilled]: (state, action) => ({
      ...state,
      impersonateLoading: false,
      success: true,
      message: action.payload.message,
      token: action.payload.token,
      user: action.payload.user,
      storesCount: action.payload.storesCount,
      storeStatus: action.payload.storeStatus,
      showSidebar: action.payload.showSidebar,
      storeId: action.payload.storeId,
      payLater: action.payload.payLater,
      connectedStore: action.payload.storeId,
      inImpersonation: true
    }),
    [ImpersonateUser.rejected]: (state, action) => ({
      ...state,
      impersonateLoading: false,
      error: action.payload.err.message
    }),
  }
});

const { reducer, actions } = auth;

export const { SetAuthState, LogOut } = actions;

export default reducer;
