import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Menu,
  MenuItem,
  SidebarFooter,
  SidebarHeader,
  SidebarContent,
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { SidebarWrapper } from "./sidebar.styled";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineFileText } from "react-icons/ai"

import Icon from "../../components/icons";
import ExitImpersonation from "../../components/Impersonate";
import StepperFooter from "../OnBoardingSt/Stepper/StepperFooter";

import SellerTerminalLogo from '../../assets/img/logo/app.sellerterminal.svg';
import SellerRepayLogo from '../../assets/images/logo-sidebar.svg'

import { SetAuthState, LogOut } from "../../redux/slices/auth-slice";

import { APP_DOMAINS, APP_LOGOS, APP_TITLES } from "../../constants";

const hostName = window.location.hostname;

const Layout = (props) => {
  const { user, menuType: menuItemType, inImpersonation } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!menuItemType) {
      if(user?.role === 'affiliate-manager'){
        dispatch(SetAuthState({ field: 'menuType', value: 'Affiliate Users' }));
      } else {
        dispatch(SetAuthState({ field: 'menuType', value: 'Dashboard' }));
      }
    }
  }, [menuItemType]);

  const handleMenuType = (type) => {
    dispatch(SetAuthState({ field: 'menuType', value: type }));
  };

  return (
    <SidebarWrapper className={user?.role ? 'general-height' : 'affiliate-height'}>
      <SidebarHeader>
        <a href="#">
          {APP_LOGOS[hostName] ?
            <img src={hostName === APP_DOMAINS.sellerRepay ? SellerRepayLogo : hostName === APP_DOMAINS.sellerTerminal ? SellerTerminalLogo : APP_LOGOS[hostName]} alt="no-logo" />
            :
            <h2>{APP_TITLES[hostName]}</h2>
          }
        </a>
      </SidebarHeader>
      <SidebarContent>
        <Menu>
          {user?.role ?
            <>
              <MenuItem
                icon={<Icon icon="home" />}
                className={menuItemType === 'Dashboard' ? 'active' : ''}
                onClick={() => handleMenuType('Dashboard')}
              >
                <Link to="/dashboard" /> Dashboard
              </MenuItem>
              <MenuItem
                icon={<Icon icon="case" />}
                className={menuItemType === 'Your Cases' ? 'active' : ''}
                onClick={() => handleMenuType('Your Cases')}
              >
                <Link to="/case-stats" /> Your Cases
              </MenuItem>
              <MenuItem
                icon={<Icon icon="shipping-van" />}
                className={menuItemType === 'FBA Shipments' ? 'active' : ''}
                onClick={() => handleMenuType('FBA Shipments')}
              >
                <Link to="/shipments" /> FBA Shipments
              </MenuItem> </>
                : null 
          } 
          {user?.role === 'affiliate-manager' ?
            <MenuItem
              icon={<Icon icon="affiliatemanager" />}
              className={menuItemType === 'Affiliate Users' ? 'active' : ''}
              onClick={() => handleMenuType('Affiliate Users')}
            >
              <Link to="/affiliate-users" /> Affiliate Users
            </MenuItem>
            : null}
          {user?.role === 'admin' || user?.role === 'agency-manager' || user?.role === 'user' || user?.role === 'affiliate-manager' ?
            <MenuItem
              icon={<Icon icon="protector" />}
              className={menuItemType === 'Fulfillment Fees' ? 'active' : ''}
              onClick={() => handleMenuType('Fulfillment Fees')}
            >
              <Link to="/fulfillment-fees" /> Fee Protector
            </MenuItem> : ''}
          {user?.role === 'admin' || user?.role === 'agency-manager' ?
            <MenuItem
              icon={<Icon icon="affiliatemanager" />}
              className={menuItemType === 'Affiliate Manager' ? 'active' : ''}
              onClick={() => handleMenuType('Affiliate Manager')}
            >
              <Link to="/affiliate-manager" /> Affiliate Manager
            </MenuItem> : null
          }
          {user?.role === 'admin' || user?.role === 'agency-manager' ?
            <MenuItem
              icon={<Icon icon="user-o" />}
              className={menuItemType === 'Users' ? 'active' : ''}
              onClick={() => handleMenuType('Users')}
            >
              <Link to="/users" /> Users
            </MenuItem> : null
          }
          {user?.role === 'admin' ?
            <MenuItem
              icon={<Icon icon="job" />}
              className={menuItemType === 'Jobs' ? 'active' : ''}
              onClick={() => handleMenuType('Jobs')}
            >
              <Link to="/jobs" /> Jobs
            </MenuItem> : null
          }
          
        </Menu>
        <Menu>
          <MenuItem
            icon={<Icon icon="account" />}
            className={menuItemType === 'Account' ? 'active' : ''}
            onClick={() => handleMenuType('Account')}
          >
            <Link to="/profile" /> Account
          </MenuItem>
          {user?.role ?
            <>
              <MenuItem
                icon={<Icon icon="location" />}
                className={menuItemType === 'Stores' ? 'active' : ''}
                onClick={() => handleMenuType('Stores')}
              >
                <Link to="/stores" /> Stores
              </MenuItem>
              <MenuItem
                icon={<Icon icon="credit-card" />}
                className={menuItemType === 'Invoices' ? 'active' : ''}
                onClick={() => handleMenuType('Invoices')}
              >
                <Link to="/invoices" /> Invoices
              </MenuItem>
              {user?.role === 'admin' || user?.role === 'agency-manager' ? <MenuItem
                icon={<Icon icon="credit-card" />}
                className={menuItemType === 'Affiliate Manager Invoices' ? 'active' : ''}
                onClick={() => handleMenuType('Affiliate Manager Invoices')}
              >
                <Link to="/affiliate-manager-invoices" /> Affiliate Manager Invoices
              </MenuItem> : null}

            </> : null
          }
          {user?.role === 'admin' ?
            <MenuItem
              icon={<Icon icon="credit-card" />}
              className={menuItemType === 'Agency Receipts' ? 'active' : null}
              onClick={() => handleMenuType('Agency Receipts')}
            >
              <Link to="/agency-receipts" /> Agency Receipts
            </MenuItem> : null
          }
          {user?.role === 'admin' || user?.role === 'agency-manager' ? null :
            <MenuItem
              icon={<Icon icon="settings" />}
              className={menuItemType === 'Billing & Payment' ? 'active' : null}
              onClick={() => handleMenuType('Billing & Payment')}
            >
              <Link to="/payments" /> Billing & Payment
            </MenuItem>
          }
          {user?.role === 'admin' ?
            <MenuItem
              icon={<AiOutlineFileText fontSize={19}/>}
              className={menuItemType === 'Activity Logs' ? 'active' : ''}
              onClick={() => handleMenuType('Activity Logs')}
            >
              <Link to="/activity-logs" /> Activity Logs
            </MenuItem> : null
          }
          {/* <MenuItem
            icon={<Icon icon="magnifier" />}
            className={menuItemType === 'FAQs' ? 'active' : ''}
            onClick={() => setMenuItemType('FAQs')}
          >
            FAQ’s
          </MenuItem> */}
        </Menu>
      </SidebarContent>
      <SidebarFooter>
        <MenuItem>
         <StepperFooter/>
        </MenuItem>
        {inImpersonation ? <ExitImpersonation /> : <MenuItem
          icon={<Icon icon="logout" />}
          onClick={() => dispatch(LogOut())}>
          Log Out
          <Link to="/" />
        </MenuItem>
      }
      </SidebarFooter>
    </SidebarWrapper>
  );
};

export default Layout;
