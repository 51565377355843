import React, { useEffect } from 'react';
import { Route, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import ChatBot from '../components/Chat';

import { SetAuthState, ValidateAndGenerateToken } from '../redux/slices/auth-slice';
import { GetStoreBySellerId, SetStoreState } from '../redux/slices/store-slice';

const PublicRoute = ({ component: Component, ...rest }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const host = window.location.hostname;

  const {
    user,
    token,
    error,
    message,
    storeStatus,
    payLater
  } = useSelector((state) => state.auth);

  const { storeExist } = useSelector((state) => state.store);

  const setStates = () => {
    const url = window.location.href;
    if (url.includes('amazon_callback_uri')) {
      const params = new URLSearchParams(window.location?.search);
      const sellerId = params.get('selling_partner_id');
      dispatch(SetAuthState({ field: 'amazonUrl', value: url }));
      dispatch(SetAuthState({ field: 'amazonOnbarding', value: true }));
      dispatch(SetStoreState({ field:'sellerId', value: sellerId}))
    }
  }

  useEffect(() => {
    if (host === 'reimbursements.sales.support' && location.pathname === '/auth/sign-in') {
      const params = new URLSearchParams(history.location.search);
      const encrypedHash = params.get("e-hash");
      if (encrypedHash) {
        const dectypedObject = decodeURIComponent(escape(window.atob(encrypedHash)));
        if (dectypedObject) {
          const { email } = JSON.parse(dectypedObject);
          if (email) {
            dispatch(ValidateAndGenerateToken({
              email,
              hostName: 'reimbursements.sales.support'
            }))
          }
        }
      }
    }
    if (token) {
      if (['admin', 'agency-manager', 'affiliate-manager'].includes(user?.role)){
        history.push('/dashboard');
      } else if (payLater) {
        setStates();
        history.push('/dashboard');
      } else {
        setStates();
        history.push('/auth/sp-api')
      }
    }
  }, [token]);

  useEffect(() => {
    if (host === 'reimbursements.sales.support' && error) {
      alert(message || 'Your account setup is pending')
      window.open('https://sales.support')
    }
  }, [error])

  useEffect(() => {
    const url = window.location.href;

    if (url.includes('amazon_callback_uri') && !token) {
      const params = new URLSearchParams(window.location?.search);
      const sellerId = params.get('selling_partner_id');
      if (sellerId) {
        dispatch(GetStoreBySellerId({ sellerId }));
        dispatch(SetStoreState({ field: 'sellerId', value: sellerId }))
      }
    }
  }, []);

  useEffect(() => {
    if (storeExist !== null) {
      dispatch(SetAuthState({ field: 'amazonUrl', value: window.location.href }));
      dispatch(SetAuthState({ field: 'amazonOnbarding', value: true }));
      dispatch(SetStoreState({field:'error', value:''}))
      if (storeExist) {
        history.push('/auth/sign-in');
      } else {
        history.push('/auth/sign-up');
      }

      dispatch(SetStoreState({ field: 'storeExist', value: null }));
    }
  }, [storeExist])


  return (
    <div>
      <Route {...rest} render={(props) => <><ChatBot route='public'/><Component {...props} /></>} />
    </div>
  );
}

export default PublicRoute;
