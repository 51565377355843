import styled from 'styled-components';

const CalenderWrapper = styled.div`
.PjPBl3MPKsCvWXT51KHv._Jtap3zpvOIYtGbqACME {
  margin-left: auto !important;
  margin-right: auto !important;
}
`;

export default CalenderWrapper;
