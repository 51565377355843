import React from "react";
import { Dropdown, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { DropdownStyle } from "./style";

const DropdownMenu = ({
  menuItems,
  triggerType,
  buttonText,
  className,
  ...rest
}) => {
  const items = menuItems || [];

  return (
    <DropdownStyle>
      <Dropdown
        {...rest}
        className={className}
        menu={{
          items,
        }}
        trigger={[triggerType]}
      >
        <a onClick={(e) => e.preventDefault()}>
          <Space>
            {buttonText}
            <DownOutlined />
          </Space>
        </a>
      </Dropdown>
    </DropdownStyle>
  );
};

export default DropdownMenu;
