import React from "react";
import { Button } from "antd";
import { GoAlertFill } from "react-icons/go";
import { TiDeleteOutline } from "react-icons/ti";

import { AlertWrapper } from "./style";

const Alert = ({
  buttonText = "- Add New Store",
  errorText = "Your store already exists, please try again to add new store",
  hide,
  resolve,
  marginBottom = '24px',
  className = '',
  endText = ''
}) => {
  return (
    <AlertWrapper className={className} marginBottom={marginBottom}>
      <div className="title-wrapper">
        <GoAlertFill />
        <p>{errorText}</p>
        <Button onClick={resolve}>{buttonText}</Button>
        <p>{endText}</p>
      </div>
      {
        hide ? (
          <TiDeleteOutline onClick={() => hide('')} />
        ): null
      }
    </AlertWrapper>
  );
};

export default Alert;
