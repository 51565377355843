import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from "react-bootstrap";
import { BsTrash } from "react-icons/bs";
import { debounce } from "lodash";

import Select from "../../components/Select";
import SearchInput from "../../components/SearchInput";
import Button from "../../components/Button/index";
import Table from "../../components/Table/table";
import Modal from "../../components/Modal/modal";
import LabelInput from "../../components/Inputs/label-input";
import Pagination from "../../components/Pagination/pagination";
import Notification from '../../components/notifications/notification';
import Spin from "../../components/Spin/Index";
import Icon from "../../components/icons";

import {
  GetAffiliateManagers,
  InviteAffiliateManager,
  DeleteAffiliateManager,
  EditAffiliateManagerData,
  SetAffiliateState
} from '../../redux/slices/affiliate-slice';

import {
  AffiliateWrapper,
  UserForm
} from "./style";

import {
  APP_DOMAINS,
  AGENCY_NAMES,
  AGENCIES,
  CURRENCY_SYMBOLS
} from '../../constants';
import Drawer from "../../components/Drawer";
import { Link } from 'react-router-dom';

import AffiliateUsersDetails from './affiliate-users-drawer'
import AffiliateDetailsDetails from './affiliate-details-drawer'

const Index = () => {
  const dispatch = useDispatch();
  const hostName = window.location.hostname;

  const { user } = useSelector(state => state.auth);
  const {
    affiliateManagers,
    totalAffiliateManagers,
    message,
    loading,
    error,
    success
  } = useSelector(state => state.affiliate);

  const [showModal, setShowModal] = useState(false);
  const [agencies, setAgencies] = useState([]);
  const [selectedAgency, setSelectedAgency] = useState('');
  const [agencyName, setAgencyName] = useState('');
  const [agencyManagerData, setAgencyManagerData] = useState({
    name: '',
    email: '',
    commission: ''
  });
  const [copiedOpenNotification, setCopiedOpenNotification] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editAffiliateModal, setEditAffiliateModal] = useState(false);
  const [editCommission, setEditCommission] = useState('');
  const [affiliateId, setAffiliateId] = useState('');
  const [searchKeyword, setSearchKeyword] = useState('');
  const [searchUser, setsearchUser] = useState('');
  const [pageNumber, setPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState(25);
  const [detailsModal, setDetailsModal] = useState(false)
  const [usersDetailModal, setUsersDetailModal] = useState(false)

  const [affiliateManager, setAffiliateManager] = useState({})

  const handleChange = (e) => {
    if (!e) {
      setSelectedAgency('');
    } else {
      setSelectedAgency(e.value);
    }
  };

  const handleChangeAgencyManager = (key, value) => {
    if (key === 'commission') {
      if (!value || /^\d+$/.test(value)) {
        setAgencyManagerData({
          ...agencyManagerData,
          [key]: value
        });
      }
    } else {
      setAgencyManagerData({
        ...agencyManagerData,
        [key]: value
      });
    }
  };

  const isEmailValid = (email) => {
    const regExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regExp.test(email);
  };

  const handleEditCommision = (value) => {
    if (!value || /^\d+$/.test(value)) {
      setEditCommission(value);
    }
  };

  const handleInviteAffiliateManager = () => {
    const {
      name,
      email,
      commission
    } = agencyManagerData || {};

    if (!name) {
      dispatch(SetAffiliateState({ field: 'error', value: 'Name cannot be empty' }));
    } else if (!email) {
      dispatch(SetAffiliateState({ field: 'error', value: 'Email cannot be empty' }));
    } else if (!isEmailValid(email)) {
      dispatch(SetAffiliateState({ field: 'error', value: 'Enter a valid email address' }));
    } else if (!commission) {
      dispatch(SetAffiliateState({ field: 'error', value: 'Commission cannot be empty' }));
    } else if (!selectedAgency) {
      dispatch(SetAffiliateState({ field: 'error', value: 'Select any of the Agency' }));
    } else {
      dispatch(InviteAffiliateManager({
        agencyId: selectedAgency,
        name,
        email,
        commission
      }));
    }

    setShowModal(false);
  };

  const handlePageChangeLeft = () => {
    if (pageNumber - 1 > 0) setPageNumber(pageNumber - 1);
    else setPageNumber(1);
  };

  const handlePageChangeRight = () => {
    const totalPages = Math.ceil(totalAffiliateManagers / pageLimit)
    if (Number(pageNumber) + 1 < totalPages) setPageNumber(Number(pageNumber) + 1);
    else setPageNumber(totalPages > 0 ? totalPages : 1);
  };

  const handleLimitChange = (e) => {
    setPageNumber(1);
    setPageLimit(e.value);
  };

  const handlePageNumberChange = (e) => {
    const totalPages = Math.ceil(totalAffiliateManagers / pageLimit)
    if (e.key == "Enter") {
      if (e.target.value < 1 || !e.target.value) {
        setPageNumber(1);
      } else if (e.target.value > totalPages) {
        setPageNumber(totalPages)
      } else setPageNumber(e.target.value)
    }
  };

  const handleAffiliateDeleteModal = (affiliateId) => {
    setAffiliateId(affiliateId);
    setDeleteModal(true);
  };

  const handleAffiliateDelete = (affiliateId) => {
    dispatch(DeleteAffiliateManager({ affiliateId }));
    setDeleteModal(false);
  };

  const handleEditAffiliateDataModal = (affiliateId) => {
    setAffiliateId(affiliateId);
    setEditAffiliateModal(true);
  };

  const handleEditAffiliateData = (affiliateId, commission) => {
    if (editCommission) {
      dispatch(EditAffiliateManagerData({
        affiliateId,
        commission
      }));
    } else {
      dispatch(SetAffiliateState({ field: 'error', value: 'Commission cannnot be empty' }));
    }

    setEditAffiliateModal(false);
  };

  const copyToClipboard = (text) => {
    setCopiedOpenNotification(true);
    return navigator.clipboard
      .writeText(text)
      .then(t => true)
      .catch(e => false);
  };

  const handleSearch = debounce((value, key) => {
    setPageNumber(1)
    if (key === 'user') {
      setsearchUser(value);
    } else if (key === 'affiliate') {
      setSearchKeyword(value);
    }
  }, 500);

  useEffect(() => {
    if (copiedOpenNotification) {
      Notification({
        type: "success",
        title: "Copied !!!"
      });
    }
    setCopiedOpenNotification(false);
  }, [copiedOpenNotification]);

  useEffect(() => {
    if (user?.role === 'admin') {
      setAgencies(AGENCIES);
    } else if (user?.role === 'agency-manager') {
      setSelectedAgency(user.agencyId);
      const agency = AGENCIES.filter(obj => obj.value === user.agencyId);
      setAgencyName(agency[0].label);
    }
  }, []);

  useEffect(() => {
    dispatch(GetAffiliateManagers({
      searchUser,
      searchKeyword,
      limit: pageLimit,
      skip: (pageNumber - 1) * pageLimit,
    }));
  }, [searchUser, searchKeyword, pageLimit]);

  useEffect(() => {
    if (error) {
      Notification({
        type: "error",
        title: "Error",
        description: error
      });
      dispatch(SetAffiliateState({ field: 'error', value: '' }));
      return;
    }

    if (message) {
      Notification({
        type: "success",
        title: "Success",
        description: message
      });

      dispatch(GetAffiliateManagers({
        searchKeyword,
        limit: pageLimit,
        skip: (pageNumber - 1) * pageLimit,
      }));
      dispatch(SetAffiliateState({ field: 'message', value: '' }));
      dispatch(SetAffiliateState({ field: 'success', value: false }));

      return;
    }
  }, [error, message]);

  return (
    <>
      <AffiliateWrapper>
        <div className="d-flex justify-content-between align-items-center flex-sm-column flex-md-row">
          <h3 className="mb-0">Affiliate Manager</h3>
          <div className="d-flex flex-wrap grid-column-gap-16 align-items-center">
            <SearchInput placeholder="Search User By Name/Email" name="userSearch" onChange={(e) => handleSearch(e.target.value, 'user')} />
            <SearchInput placeholder="Search Affiliate By Name/Email" name="search" onChange={(e) => handleSearch(e.target.value, 'affiliate')} />
            <Button
              text={"Add Affiliate"}
              className=" mb-0 outlined"
              type="button"
              width=""
              outlined
              onClick={() => setShowModal(true)}
            />
          </div>
        </div>
        <div className="pt-4">
          <Table
            viewHeight="invoices"
            tableHeader={
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th className="text-center">Commission</th>
                <th>No. Of User</th>
                <th>Life Time earning</th>
                <th>Current Balance</th>
                <th>Status</th>
                <th className="text-center">Action</th>
              </tr>
            }
            tableBody={
              <>
                {!loading && !affiliateManagers.length ? (
                  <tr><td>
                    <div className='error'>
                      <h1>No Data Available</h1>
                    </div>
                  </td></tr>
                ) : null}
                {loading ? <tr><td><Spin /></td></tr> : affiliateManagers.map((item, ind) => (
                  <tr key={ind}>
                    <td>
                      <Link to="#" onClick={() => { setDetailsModal(true); setAffiliateManager(item); }}>
                        {item.name}
                      </Link>
                    </td>
                    <td>{item.email}</td>
                    <td className="text-center">{item.commission}</td>
                    <td>
                      <Button text={item.noOfUsers}
                        className=" mb-0 outlined"
                        type="button"
                        width=""
                        outlined onClick={() => { setUsersDetailModal(true); setAffiliateManager(item); }} />
                    </td>
                    <td>{item.currency ? CURRENCY_SYMBOLS[item.currency] : ''} {item.totalEanring?.toFixed(2)}</td>
                    <td>{item.currency ? CURRENCY_SYMBOLS[item.currency] : ''} {item.currentBalance?.toFixed(2)}</td>

                    <td>{item.status}</td>
                    <td className="text-center">
                      <div className="action-class">
                        <Icon icon="pencil-square" color={hostName === APP_DOMAINS.sellerTerminal ? "#008989": '#4438F9'} className="edit-icon cursor-pointer" onClick={() => handleEditAffiliateDataModal(item._id)} />
                        <BsTrash
                          styel={{ marginLeft: '5px' }}
                          onClick={() => handleAffiliateDeleteModal(item._id)}
                          className="trash edit-icon cursor-pointer"
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </>
            }
          />
        </div>
        <Pagination
          className="affiliate-pagination-wrapper"
          pageLimit={pageLimit}
          total={totalAffiliateManagers}
          pageNumber={pageNumber}
          totalPages={Math.ceil(totalAffiliateManagers / pageLimit)}
          handlePageChangeLeft={handlePageChangeLeft}
          handlePageChangeRight={handlePageChangeRight}
          handleLimitChange={handleLimitChange}
          handlePageNumberChange={handlePageNumberChange}
        />
        {showModal ? (
          <Modal
            showModal={showModal}
            modalHeader="Add User"
            modalBody={
              <UserForm>
                <Row className="add-user">
                  <Col md={6}>
                    <label className="mb-1">Name</label>
                    <LabelInput placeholder="Name Here" onChange={(e) => handleChangeAgencyManager('name', e.target.value)} />
                  </Col>
                  <Col md={6}>
                    <label className="mb-1">Email</label>
                    <LabelInput placeholder="Email Here" onChange={(e) => handleChangeAgencyManager('email', e.target.value)} />
                  </Col>
                </Row>
                <Row className="add-user">
                  <Col md={6}>
                    <label className="mb-1">Commission</label>
                    <LabelInput placeholder="Commission Here" onChange={(e) => handleChangeAgencyManager('commission', e.target.value)} value={agencyManagerData.commission} />
                  </Col>
                  <Col md={6}>
                    {user?.role === 'admin' ?
                      <>
                        <label className="mb-1">Agencies</label>
                        <Select
                          handleChange={handleChange}
                          options={agencies}
                          className="dashbaord-select case-state-dropdown"
                          isClearable={true}
                          placeholder='Select Agency'
                        /></> :
                      <>
                        <label className="mb-1">Agency</label>
                        <LabelInput placeholder="Agency" value={agencyName} disabled={true} />
                      </>
                    }
                  </Col>
                </Row>
              </UserForm>
            }
            handleConfirm={() => handleInviteAffiliateManager()}
            handleClose={() => setShowModal(false)}
            showFooter={true}
          />
        ) : null}

        {deleteModal ?
          <Modal
            showModal={deleteModal}
            modalHeader="Delete Affiliate"
            modalBody="Are you sure want to delete it ?"
            handleConfirm={() => handleAffiliateDelete(affiliateId)}
            handleClose={() => setDeleteModal(false)}
            showFooter={true}
          />
          : null
        }

        {usersDetailModal ? <Drawer show={usersDetailModal} size="75%">

          <AffiliateUsersDetails
            affiliateManager={affiliateManager}
            detailsModal={usersDetailModal}
            handleClick={() => setUsersDetailModal(false)}
          />
        </Drawer> : null}
        {detailsModal ? <Drawer show={detailsModal} size="75%">

          <AffiliateDetailsDetails
            affiliateManager={affiliateManager}
            detailsModal={detailsModal}
            handleClick={() => setDetailsModal(false)}
          />
        </Drawer> : null}

        {editAffiliateModal ? (
          <Modal
            showModal={editAffiliateModal}
            modalHeader="Edit Commission"
            modalBody={
              <UserForm>
                <Row className="add-user">
                  <Col md={6}>
                    <label className="mb-1">Commission</label>
                    <LabelInput placeholder="Commission Here" onChange={(e) => handleEditCommision(e.target.value)} value={editCommission} />
                  </Col>
                </Row>
              </UserForm>
            }
            handleConfirm={() => handleEditAffiliateData(affiliateId, editCommission)}
            handleClose={() => setEditAffiliateModal(false)}
            showFooter={true}
          />
        ) : null}

      </AffiliateWrapper>
      {loading && <Spin />}
    </>
  );
};

export default Index;
