import React from 'react';

function Index() {
  return (
    <div>
      <h1>Page Not found</h1>
    </div>
  );
}

export default Index;
