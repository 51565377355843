import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { axiosBaseUrl } from '../../config/axios-configuration';

import SaveSentryLog from "../../helpers/sentry-log";

const axios = axiosBaseUrl();

export const GetUsers = createAsyncThunk(
 'user/getUsers',
  async (data, { rejectWithValue }) => {
    try {
      const {
        filters,
        skip,
        limit,
        sortType
      } = data;
      const response = await axios.get('/users/get-users', {
        params: {
          filters,
          skip,
          limit,
          sortType
        }
      });
      return response.data;
    } catch (err) {
      SaveSentryLog(new Error(err), 'GetUsersError');
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: {
            message: err.response.data.message,
            status: err.response.status
          }
        });
      }
      return rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

export const GetUsersName = createAsyncThunk(
  'user/getUsersName',
   async (data, { rejectWithValue }) => {
     try {
       const params = data;
       const response = await axios.get('/users/get-users-name', {
         params
       });
       return response.data;
     } catch (err) {
       SaveSentryLog(new Error(err), 'GetUsersNameError');
       if (err.response && err.response.data) {
         return rejectWithValue({
           err: {
             message: err.response.data.message,
             status: err.response.status
           }
         });
       }
       return rejectWithValue({
         err: {
           message: 'Network Error',
           status: 502
         }
       });
     }
   }
 );

 export const GetUser = createAsyncThunk(
  'user/getUser',
   async (data, { rejectWithValue }) => {
     try {
       const response = await axios.get('/users/get-user');
       return response.data;
     } catch (err) {
       SaveSentryLog(new Error(err), 'GetUserError');
       if (err.response && err.response.data) {
         return rejectWithValue({
           err: {
             message: err.response.data.message,
             status: err.response.status
           }
         });
       }
       return rejectWithValue({
         err: {
           message: 'Network Error',
           status: 502
         }
       });
     }
   }
 );

export const GetEmailLogs = createAsyncThunk(
  'user/getEmailLogs',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get('/users/get-email-logs', {
        params: data
      });
      return response.data;
    } catch (err) {
      SaveSentryLog(new Error(err), 'GetUserError');
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: {
            message: err.response.data.message,
            status: err.response.status
          }
        });
      }
      return rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

export const UpdateUserInfo = createAsyncThunk(
  'user/updateUserInfo',
   async (data, { rejectWithValue }) => {
     try {
       const response = await axios.post('/users/update-user-info',{
          data
       });
       return response.data;
     } catch (err) {
       SaveSentryLog(new Error(err), 'UpdateUserInfoError');
       if (err.response && err.response.data) {
         return rejectWithValue({
           err: {
             message: err.response.data.message,
             status: err.response.status
           }
         });
       }
       return rejectWithValue({
         err: {
           message: 'Network Error',
           status: 502
         }
       });
     }
   }
);

export const SendRequestDeleteInvitation = createAsyncThunk(
  'users/send-request-delete-invitation',
  async (data, { rejectWithValue }) => {
    try {
      const {
        userId
      } = data || {}
      const response = await axios.post('users/send-request-delete-invitation', { userId })
      return response.data
    } catch (err) {
      SaveSentryLog(new Error(err), 'SendRequestDeleteInvitationError');
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: {
            message: err.response.data.message,
            status: err.response.status
          }
        });
      }
      return rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
)

export const PauseUserCaseFiling = createAsyncThunk(
  'user/pauseUserCaseFiling',
   async (data, { rejectWithValue }) => {
     try {
       const { caseFiling } = data || {};

       const response = await axios.post('/users/update-user-case-filing-status',{
         caseFiling
       });
       return response.data;
     } catch (err) {
       SaveSentryLog(new Error(err), 'PauseUserCaseFilingError');
       if (err.response && err.response.data) {
         return rejectWithValue({
           err: {
             message: err.response.data.message,
             status: err.response.status
           }
         });
       }
       return rejectWithValue({
         err: {
           message: 'Network Error',
           status: 502
         }
       });
     }
   }
);

export const SendEmailToUser = createAsyncThunk(
  'user/sendEmailToUser',
  async (data, { rejectWithValue }) => {
    try {
      const { 
        subject,
        sellerId,
        userId
      } = data || {};

      const response = await axios.post('/users/send-email', {
        subject,
        sellerId,
        userId
      });
      return response.data;
    } catch (err) {
      SaveSentryLog(new Error(err), 'SendEmailToUserError');
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: {
            message: err.response.data.message,
            status: err.response.status
          }
        });
      }
      return rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

const user = createSlice({
    name: 'user',
    initialState: {
      error: '',
      loading: '',
      message: '',
      user: {},
      users: [],
      total: 0,
      success: false,
      usersName: [],
      isCaseFilingUpdated: false,
      updateCaseFilingLoading: false,
      userEmailLogs: []
    },
    reducers: {
      SetUserState(state, {payload: {field, value} }) {
        state[field] = value;
      }
    },
    extraReducers: {
      [GetUsers.pending]: (state, action) => ({
        ...state,
        loading: true
      }),
      [GetUsers.fulfilled]: (state, action) => ({
        ...state,
        success: true,
        loading: false,
        // message: action.payload.message,
        users: action.payload.usersList,
        total: action.payload.total
      }),
      [GetUsers.rejected]: (state, action) => ({
        ...state,
        success: false,
        loading: false,
        error: action.payload.err.message
      }),
      [GetUsersName.pending]: (state, action) => ({
        ...state,
        loading: true
      }),
      [GetUsersName.fulfilled]: (state, action) => ({
        ...state,
        success: true,
        loading: false,
        // message: action.payload.message,
        usersName: action.payload.usersName,
      }),
      [GetUsersName.rejected]: (state, action) => ({
        ...state,
        success: false,
        loading: false,
        error: action.payload.err.message
      }),
      [GetUser.pending]: (state, action) => ({
        ...state,
        loading: true
      }),
      [GetUser.fulfilled]: (state, action) => ({
        ...state,
        success: true,
        loading: false,
        // message: action.payload.message,
        user: action.payload.user,
      }),
      [GetUser.rejected]: (state, action) => ({
        ...state,
        success: false,
        loading: false,
        error: action.payload.err.message
      }),
      [GetEmailLogs.pending]: (state, action) => ({
        ...state,
        loading: true
      }),
      [GetEmailLogs.fulfilled]: (state, action) => ({
        ...state,
        success: true,
        loading: false,
        userEmailLogs: action.payload.data,
      }),
      [GetEmailLogs.rejected]: (state, action) => ({
        ...state,
        success: false,
        loading: false,
        error: action.payload.err.message
      }),
      [UpdateUserInfo.pending]: (state, action) => ({
        ...state,
        loading: true
      }),
      [UpdateUserInfo.fulfilled]: (state, action) => ({
        ...state,
        success: true,
        loading: false,
        message: action.payload.message
      }),
      [UpdateUserInfo.rejected]: (state, action) => ({
        ...state,
        success: false,
        loading: false,
        error: action.payload.err.message
      }),
      [SendRequestDeleteInvitation.pending]: (state, action) => ({
        ...state,
        loading: true
      }),
      [SendRequestDeleteInvitation.fulfilled]: (state, action) => ({
        ...state,
        success: true,
        loading: false,
        message: action.payload.message
      }),
      [SendRequestDeleteInvitation.rejected]: (state, action) => ({
        ...state,
        success: false,
        loading: false,
        error: action.payload.err.message
      }),
      [PauseUserCaseFiling.pending]: (state, action) => ({
        ...state,
        updateCaseFilingLoading: true
      }),
      [PauseUserCaseFiling.fulfilled]: (state, action) => ({
        ...state,
        success: true,
        updateCaseFilingLoading: false,
        isCaseFilingUpdated: true,
        message: action.payload.message
      }),
      [PauseUserCaseFiling.rejected]: (state, action) => ({
        ...state,
        success: false,
        updateCaseFilingLoading: false,
        isCaseFilingUpdated: false,
        error: action.payload.err.message
      }),
      [SendEmailToUser.pending]: (state, action) => ({
        ...state,
        loading: false
      }),
      [SendEmailToUser.fulfilled]: (state, action) => ({
        ...state,
        loading: false,
        success: true,
        message: action.payload.message
      }),
      [SendEmailToUser.rejected]: (state, action) => ({
        ...state,
        success: false,
        loading: false,
        error: action.payload.err.message
      })
    }
  });

  const { reducer, actions } = user;

  export const { SetUserState } = actions;

  export default reducer;
