import Styled from "styled-components";

const AffiliateWrapper = Styled.div`
.grid-column-gap-16{
    grid-column-gap:16px;
}
.small-fontSize{
    font-size:0.675rem;
}
.copy-icon{
    width: 12px;
    height: 12px;
}
.edit-icon{
    width: 16px;
    height: 16px;
}
.error {
    position: absolute;
    left: 40%;
    top: 50%;
    h1{
      color: black;
    }
} 
`;
const UserForm = Styled.div`
.add-user{
    .form-group{
        .form-control{
            border-radius: 5px;
            padding: 20px 10px;
        }
    }
}
.icon-color {
    color:${({theme}) => `${theme['secondary-color']}!important`};
}
`;

export { AffiliateWrapper, UserForm };
