import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { axiosBaseUrl } from '../../config/axios-configuration';

import SaveSentryLog from "../../helpers/sentry-log";

const axios = axiosBaseUrl();

export const InviteAffiliateManager = createAsyncThunk(
  'affiliate/invite-affiliate-manager',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post('/affiliate/invite-affiliate-manager', data);
      return response.data;
    } catch (err) {
      SaveSentryLog(new Error(err), 'InviteAffiliateManagerError');
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: {
            message: err.response.data.message,
            status: err.response.status
          }
        });
      }
      return rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

export const RegisterAffiliateManager = createAsyncThunk(
  'affiliate/register-affiliate-manager',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post('/affiliate/register-affiliate-manager', data);
      return response.data;
    } catch (err) {
      SaveSentryLog(new Error(err), 'RegisterAffiliateManagerError');
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: {
            message: err.response.data.message,
            status: err.response.status
          }
        });
      }
      return rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

export const GetAffiliateManagers = createAsyncThunk(
  'affiliate/get-affiliate-managers',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get('/affiliate/get-affiliate-managers', {
        params: data
      });
      return response.data;
    } catch (err) {
      SaveSentryLog(new Error(err), 'GetAffiliateManagersError');
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: {
            message: err.response.data.message,
            status: err.response.status
          }
        });
      }
      return rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

export const GetMonthlyReceipts = createAsyncThunk(
  'affiliate/get-monthly-receipts',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get('/affiliate/get-monthly-receipts', {
        params: data
      });
      return response.data;
    } catch (err) {
      SaveSentryLog(new Error(err), 'GetMonthlyReceiptsError');
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: {
            message: err.response.data.message,
            status: err.response.status
          }
        });
      }
      return rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

export const GetAffiliateUsersData = createAsyncThunk(
  'affiliate/get-affiliate-users-data',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get('/affiliate/get-affiliate-users-data', {
        params: data
      });
      return response.data;
    } catch (err) {
      SaveSentryLog(new Error(err), 'GetAffiliateUsersDataError');
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: {
            message: err.response.data.message,
            status: err.response.status
          }
        });
      }
      return rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

export const DeleteAffiliateManager = createAsyncThunk(
  'affiliate/delete-affiliate-manager',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post('/affiliate/delete-affiliate-manager', data);
      return response.data;
    } catch (err) {
      SaveSentryLog(new Error(err), 'DeleteAffiliateManagerError');
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: {
            message: err.response.data.message,
            status: err.response.status
          }
        });
      }
      return rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

export const EditAffiliateManagerData = createAsyncThunk(
  'affiliate/edit-affiliate-manager-data',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post('/affiliate/edit-affiliate-manager-data', data);
      return response.data;
    } catch (err) {
      SaveSentryLog(new Error(err), 'EditAffiliateManagerDataError');
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: {
            message: err.response.data.message,
            status: err.response.status
          }
        });
      }
      return rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

export const GetAffialiteManagerDetail = createAsyncThunk(
  'affiliate/get-affiliate-manager-detail',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get('/affiliate/get-affiliate-manager-detail', { params: data });
      return response.data;
    } catch (err) {
      SaveSentryLog(new Error(err), 'GetAffialiteManagerDetailError');
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: {
            message: err.response.data.message,
            status: err.response.status
          }
        });
      }
      return rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

export const GetAffiliateMonthlyReceiptInvoices = createAsyncThunk(
  'affiliate/get-monthly-affiliate-receipt',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get('/affiliate/get-monthly-affiliate-receipt', {
        params: data
      });
      return response.data;
    } catch (err) {
      SaveSentryLog(new Error(err), 'GetAffiliateMonthlyReceiptInvoicesError');
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: {
            message: err.response.data.message,
            status: err.response.status
          }
        });
      }
      return rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

export const PayReceipt = createAsyncThunk(
  'affiliate/editAffilaiteInvoiceStatus',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post('/affiliate/edit-affiliate-invoice-status', data);
      return response.data;
    } catch (err) {
      SaveSentryLog(new Error(err), 'PayReceiptError');
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: {
            message: err.response.data.message,
            status: err.response.status
          }
        });
      }
      return rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

export const GetAffiliateManagerUsersDetail = createAsyncThunk(
  'affiliate/GetAffiliateManagerUsersDetail',
  async (data, { rejectWithValue }) => {
    try {
      console.log(data)
      const response = await axios.get('/affiliate/get-affiliate-manager-users-detail', { params: data });
      return response.data;
    } catch (err) {
      SaveSentryLog(new Error(err), 'GetAffiliateManagerUsersDetailError');
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: {
            message: err.response.data.message,
            status: err.response.status
          }
        });
      }
      return rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
)

const affiliate = createSlice({
  name: 'affiliate',
  initialState: {
    error: '',
    loading: '',
    message: '',
    success: false,
    affiliateManagers: [],
    totalAffiliateManagers: 0,
    totalAffiliateUsers: 0,
    affiliateLink: '',
    totalEarnings: 0,
    affiliateUsersData: [],
    monthlyReceipts: [],
    totalMonthlyReceipts: 0,
    affiliateManagerName: '',
    affiliateManagerDetail: [],
    monthlyAffiliateReceiptData: [],
    total: 0,
    affiliateManagers: [],
    totalAffiliateCommission : 0,
    totalAgencyCommission : 0,
    totalAmountPaid: 0,
    totlaCommissionEarn:0
  },
  reducers: {
    SetReceiptState(state, { payload: { field, value } }) {
      state[field] = value;
    },

    SetAffiliateState(state, { payload: { field, value } }) {
      state[field] = value;
    }
  },
  extraReducers: {
    [InviteAffiliateManager.pending]: (state, action) => ({
      ...state,
      loading: true,
      success: false
    }),
    [InviteAffiliateManager.fulfilled]: (state, action) => ({
      ...state,
      success: true,
      loading: false,
      message: action.payload.message
    }),
    [InviteAffiliateManager.rejected]: (state, action) => ({
      ...state,
      success: true,
      loading: false,
      error: action.payload.err.message
    }),
    [RegisterAffiliateManager.pending]: (state, action) => ({
      ...state,
      loading: true,
      success: false
    }),
    [RegisterAffiliateManager.fulfilled]: (state, action) => ({
      ...state,
      success: true,
      loading: false,
      message: action.payload.message
    }),
    [RegisterAffiliateManager.rejected]: (state, action) => ({
      ...state,
      success: true,
      loading: false,
      error: action.payload.err.message
    }),
    [GetAffiliateManagers.pending]: (state, action) => ({
      ...state,
      loading: true,
      success: false
    }),
    [GetAffiliateManagers.fulfilled]: (state, action) => ({
      ...state,
      success: true,
      loading: false,
      affiliateManagers: action.payload.affiliateManagers,
      totalAffiliateManagers: action.payload.totalAffiliateManagers
    }),
    [GetAffiliateManagers.rejected]: (state, action) => ({
      ...state,
      success: true,
      loading: false,
      error: action.payload.err.message
    }),
    [GetMonthlyReceipts.pending]: (state, action) => ({
      ...state,
      loading: true,
      success: false
    }),
    [GetMonthlyReceipts.fulfilled]: (state, action) => ({
      ...state,
      success: true,
      loading: false,
      totalMonthlyReceipts: action.payload.totalMonthlyReceipts,
      monthlyReceipts: action.payload.monthlyReceipts,
      affiliateManagerName: action.payload.affiliateManagerName
    }),
    [GetMonthlyReceipts.rejected]: (state, action) => ({
      ...state,
      success: true,
      loading: false,
      error: action.payload.err.message
    }),
    [GetAffiliateUsersData.pending]: (state, action) => ({
      ...state,
      loading: true,
      success: false
    }),
    [GetAffiliateUsersData.fulfilled]: (state, action) => ({
      ...state,
      success: true,
      loading: false,
      totalAffiliateUsers: action.payload.totalAffiliateUsers,
      affiliateLink: action.payload.affiliateLink,
      totalEarnings: action.payload.totalEarnings,
      affiliateUsersData: action.payload.affiliateUsersData
    }),
    [GetAffiliateUsersData.rejected]: (state, action) => ({
      ...state,
      success: true,
      loading: false,
      error: action.payload.err.message
    }),
    [DeleteAffiliateManager.pending]: (state, action) => ({
      ...state,
      loading: true,
      success: false
    }),
    [DeleteAffiliateManager.fulfilled]: (state, action) => ({
      ...state,
      success: true,
      loading: false,
      message: action.payload.message
    }),
    [DeleteAffiliateManager.rejected]: (state, action) => ({
      ...state,
      success: true,
      loading: false,
      error: action.payload.err.message
    }),
    [EditAffiliateManagerData.pending]: (state, action) => ({
      ...state,
      loading: true,
      success: false
    }),
    [EditAffiliateManagerData.fulfilled]: (state, action) => ({
      ...state,
      success: true,
      loading: false,
      message: action.payload.message
    }),
    [EditAffiliateManagerData.rejected]: (state, action) => ({
      ...state,
      success: true,
      loading: false,
      error: action.payload.err.message
    }),
    [GetAffiliateMonthlyReceiptInvoices.pending]: (state, action) => ({
      ...state,
      loading: true,
      success: false
    }),
    [GetAffiliateMonthlyReceiptInvoices.fulfilled]: (state, action) => ({
      ...state,
      success: true,
      loading: false,
      monthlyAffiliateReceiptData: action.payload.response.totalAffiliateinvoices,
      total: action.payload.response.total,
      affiliateManagers: action.payload.response.affiliates,
    }),
    [GetAffiliateMonthlyReceiptInvoices.rejected]: (state, action) => ({
      ...state,
      success: true,
      loading: false,
      error: action.payload.err.message
    }),
    [PayReceipt.pending]: (state, action) => ({
      ...state,
      success: true,
      loading: false,
    }),
    [GetAffialiteManagerDetail.pending]: (state, action) => ({
      ...state,
      loading: true,
      success: false
    }),
    [PayReceipt.fulfilled]: (state, action) => ({
      ...state,
      success: true,
      loading: false,
      message: action.payload.message
    }),
    [PayReceipt.rejected]: (state, action) => ({
      ...state,
      success: true,
      loading: false,
      error: action.payload.err.message
    }),
    [GetAffialiteManagerDetail.fulfilled]: (state, action) => ({
      ...state,
      success: true,
      loading: false,
      total: action.payload.total,
      affiliateManagerDetail: action.payload.affiliateManagerData,
      totalAmountPaid: action.payload.totalAmountPaid,
      totlaCommissionEarn: action.payload.totlaCommissionEarn
    }),
    [GetAffialiteManagerDetail.rejected]: (state, action) => ({
      ...state,
      success: true,
      loading: false,
      error: action.payload.err.message
    }),

    [GetAffiliateManagerUsersDetail.pending]: (state, action) => ({
      ...state,
      loading: true,
      success: false
    }),
    [GetAffiliateManagerUsersDetail.fulfilled]: (state, action) => ({
      ...state,
      success: true,
      loading: false,
      totalAffiliateUsers: action.payload.response.noOfUsers,
      affiliateUsersData: action.payload.response.finalData,
      totalAgencyCommission: action.payload.response.totalAgencyCommission,
      totalAffiliateCommission: action.payload.response.totalAffiliateCommission
    }),
    [GetAffiliateManagerUsersDetail.rejected]: (state, action) => ({
      ...state,
      success: true,
      loading: false,
      error: action.payload.err.message
    })
  }
});

const { reducer, actions } = affiliate;

export const { SetReceiptState, SetAffiliateState } = actions;

export default reducer;
