import { SetAuthState } from "../redux/slices/auth-slice";
import { SetStoreState } from "../redux/slices/store-slice";
import { SetState } from "../redux/slices/account-detail-slice";

export const ClearStates = ({ dispatch, storeId, fromApp }) => {
  dispatch(SetAuthState({ field: 'storeStatus', value: 'Initial' }));
  dispatch(SetAuthState({ field: 'storeId', value: storeId || '' }));
  dispatch(SetStoreState({ field: 'store', value: {} }));
  dispatch(SetStoreState({ field: 'storeId', value: storeId || '' }));
  dispatch(SetStoreState({ field: 'potentialRecovery', value: null }));
  dispatch(SetStoreState({ field: 'progress', value: 0 }));
  dispatch(SetStoreState({ field: 'potentialRecoveryBreakdown', value: {} }));
  dispatch(SetStoreState({ field: 'updateSuccess', value: null }));
  dispatch(SetStoreState({ field: 'progressSuccess', value: null }));
  dispatch(SetState({ field: 'account', value: {} }));

  if (fromApp) {
    dispatch(SetAuthState({ field: 'showSidebar', value: false }));
    dispatch(SetAuthState({ field: 'inAppOnboarding', value: true }));
    dispatch(SetAuthState({ field: 'location', value: window.location.pathname }));
  }
}
