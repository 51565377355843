import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { axiosBaseUrl } from '../../config/axios-configuration';

import SaveSentryLog from '../../helpers/sentry-log';

const axios = axiosBaseUrl();

export const GetDataSyncProgress = createAsyncThunk(
  'dataGathering/getDataSyncProgress',
  async (data, { rejectWithValue }) => {
    try {
      const { storeId } = data;

      const response = await axios.get('/data-gathering/get-data-sync-progress', {
        params: { storeId }
      });

      return response.data;
    } catch (err) {
      SaveSentryLog(new Error(err), 'GetDataSyncProgressError');
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const EnableNotification = createAsyncThunk(
  'dataGathering/EnableNotification',
  async (data, { rejectWithValue }) => {
    try {
      const {
        useExistingNumber,
        phoneNumber
      } = data;

      const response = await axios.post('/data-gathering/enable-notifications', {
        useExistingNumber,
        phoneNumber
      });

      return response.data;
    } catch (err) {
      SaveSentryLog(new Error(err), 'EnableNotificationError');
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

const dataGathering = createSlice({
  name: 'dataGathering',
  initialState: {
    message: '',
    err: '',
    dataSyncProgress: {},
    loading: false
  },
  reducers: {
    SetState(state, { payload: { field, value } }) {
      state[field] = value;
    }
  },
  extraReducers: {
    [GetDataSyncProgress.pending]: (state, action) => ({
      ...state,
      loading: true
    }),
    [GetDataSyncProgress.fulfilled]: (state, action) => ({
      ...state,
      dataSyncProgress: action.payload,
      loading: false
    }),
    [GetDataSyncProgress.rejected]: (state, action) => ({
      ...state,
      loading: false
    }),
    [EnableNotification.pending]: (state, action) => ({
      ...state,
      loading: true
    }),
    [EnableNotification.fulfilled]: (state, action) => ({
      ...state,
      success: true,
      message: action.payload.message,
      loading: false
    }),
    [EnableNotification.rejected]: (state, action) => ({
      ...state,
      success: true,
      loading: false,
      error: action.payload.err?.message
    })
  }
});

const { reducer, actions } = dataGathering;

export const {
  SetState
} = actions;

export default reducer;
