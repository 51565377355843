import React from "react";
import { OverlayTrigger, Button, Tooltip } from "react-bootstrap";
const Toast = (props) => {
  const { text, body,placement } = props;
  return (
    <OverlayTrigger
      placement={placement}
      overlay={
        <Tooltip>
          <div className="tooltip-box">{body}</div>
        </Tooltip>
      }
    >
      <span className="cursor-pointer">{text}</span>
    </OverlayTrigger>
  );
};
export default Toast;
