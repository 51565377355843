import styled from "styled-components";

const ActivityLogWrapper = styled.div`
  .header-wrapper {
    display: flex;
    justify-content: space-between;
    height: 32px;
    h6 {
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      color: #353e44;
      margin: 0px;
    }

  }
  .header-right-section {
      display: flex;
      grid-gap: 24px;
      flex-wrap: wrap;
      margin-bottom: 16px;
    }
  .select2-container{
    margin-bottom:0px;
  }
  .sub-header-item {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    margin-top: 12px;
    color: #a2a2a2;
    margin-bottom: 8px;
  }
  .error {
    position: absolute;
    left: 40%;
    top: 60%;
 }
  .card-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(255, 153, 0, 0.02);
    border: 1.5px solid #ff9900;
    border-radius: 12px;
    padding: 36px 24px;
    &.success-cases {
      background:${({ theme }) => theme["header-success-color"]}03;
      border: 1px solid ${({ theme }) => theme["header-success-color"]};
    }
    &.rejected-cases {
      background:${({ theme }) => theme["header-warning-color"]}03;
      border: 1px solid ${({ theme }) => theme["header-warning-color"]};
    }
    &.total-cases{
      background:${({ theme }) => theme["primary-color"]}03;
      border: 1px solid ${({ theme }) => theme["primary-color"]};
  }
    p {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      margin-bottom: 8px;
      color: #707070;
    }

    h2 {
      font-weight: 700;
      font-size: 28px;
      line-height: 34px;
      margin: 0px;
      display: flex;
      align-items: center;
      text-align: right;

      color: #353e44;
    }
  }

  span.bages-item {
    background: #009c34;
    border-radius: 18px;
    padding: 4px 12px;
    font-weight: 700;
    font-size: 14px;
    line-height: 12px;
    color: #ffffff;
  }
  .open-case {
    background-color: #ff9900 !important;
  }
  .erorr-color {
    background-color: #f31819 !important;
  }
  .table-activity-log-wrapper {
    margin-top: 24px;
    .table-responsive {
          height: calc(100vh - 401px);
          border-bottom:1px solid ;
    }
  }

  button#dateRangeButton {
    background: #ffffff;
    border: 1px solid #e8e8e8;
    border-radius: 5px;
    padding: 5px 12px;
    display: flex;
    align-items: center;
    grid-gap: 6px;
    span {
      font-weight: 400;
      font-size: 12px !important;
      line-height: 16px;
      color: #757585;
    }
    svg {
      color: ${({theme}) => theme['calender-icon-color']};
    }
  }
`;
export { ActivityLogWrapper };
