import Styled from "styled-components";
import { Form } from "react-bootstrap";

const InputWrapper = Styled(Form.Group)`
  margin-bottom:16px;
  &.company-filed{
    margin-bottom:8px;
  }
  &.email-field{
    margin-bottom:16px;
  }
  &.password-field{
    margin-bottom:10px;
  }
  &.coupen-code{
    margin-bottom: 13px;
  }
  &.email-name{
    margin-bottom:2
  }
  width:100%;
  label{
    margin-bottom:6px;
    color: ${({ theme }) => theme["label-color"]};
    font-size: ${({ theme }) => theme["xs-small-size"]};
    font-weight:300;
    line-height:12.1px;
  }

  input{
    border: 1px solid ${({ theme }) => theme["secondary-variant"]};
    border-radius: 1.5px;
    color: ${({ theme }) => theme["body-text"]};
    font-size: ${({ theme }) => theme["xs-small-size"]};
    max-height:28px;
    padding:8px 10px;
    min-height:2px;
    &::-webkit-input-placeholder { /* Edge */
      letter-spacing:1px;
      color: ${({ theme }) => theme["placeholder"]};
      }
    &:focus{
      border-color:${({ theme }) => theme["secondary-variant"]};
      box-shadow:none;
      color: ${({ theme }) => theme["body-text"]};
    }

    &::placeholder{
      font-size: ${({ theme }) => theme["xs-small-size"]};
      color: ${({ theme }) => theme["placeholder"]};
    }
    &:-webkit-autofill{
          /* -webkit-box-shadow: 0 0 0px 1000px #000 inset; */
          transition: background-color 5000s ease-in-out 0s;
    }
  }
  textarea {
    border: 1px solid ${({ theme }) => theme["secondary-variant"]};
    border-radius: 4px;
    color: ${({ theme }) => theme["body-text"]};
    &:focus{
      border-color:${({ theme }) => theme["secondary-variant"]};
      box-shadow:none;
      color: ${({ theme }) => theme["body-text"]};
    }
    &::placeholder{
      font-size: ${({ theme }) => theme["xs-small-size"]};
      color: ${({ theme }) => theme["placeholder"]};
    }
  }
  .input-with-icon{
    position:relative;
    input{
      padding-right:22px;
    }
    .input-icon{
      position: absolute;
      right: 8px;
      top: 7px;
      cursor: pointer;
    }
  }
  &.input-icon-left{
    .input-with-icon{
      input{
        padding-left:24px!important;
        padding-right:10px!important;
      }
      .input-icon{
          right:auto;
          left:7px;
          top:5px;
        }
    }
  }
  .form-text{
    color: ${({ theme }) => theme["lynch-color"]};
    font-size: ${({ theme }) => theme["base-font-size-small"]};
  }
  .progress-status{
    display: flex;
    flex-direction:column;
    .progress-title{
      font-size:7px;
      color:#C1C1C1;
      line-height: 8px;
      margin-bottom:1.8px;
    }
      .progress{
        min-width:60px;
        height:2.18px;
        /* .progress-bar{
          background-color: #FFD600;
        } */
        .progress-bar[aria-valuenow='0'] {
            background-color: #FFD600;
          }
        .progress-bar[aria-valuenow='20'] {
          background-color: #FFD600;
        }
        .progress-bar[aria-valuenow='40'] {
          background-color: #FFA500;
        }
        .progress-bar[aria-valuenow='60'] {
          background-color: #71c98f;;
        }
        .progress-bar[aria-valuenow='80'] {
          background-color: #009C34;
        }
        .progress-bar[aria-valuenow='100'] {
          background-color: #009C34;
        }
      }
  }
  input[type="date"]::-webkit-calendar-picker-indicator {
        color: rgba(0, 0, 0, 0);
        opacity: 1;
        display: block;
        background: none;
        width: 14px;
        height: 14px;
        border-width: thin;
        margin-top:2px ;
    }

    .tooltip > div.tooltip-inner{
      background-color: #fff;
      padding: 0px;
      box-shadow: 6px 6px 12px rgb(0 0 0 / 16%);
      color: #000;
      min-width: 420px;
      border-radius: 4px
      };
  `;

const PhoneNumberWrapper = Styled.div`
  margin-bottom:16px;
  .form-label{
    margin-bottom:6px;
    font-weight:300;
    line-height:12.1px;
    font-size: ${({ theme }) => theme["xs-small-size"]};
    display:block;
  }
  .PhoneInput{
    border: 1px solid #CFD8DC;
    padding: 0.375rem 0.75rem;
    border-radius: 0.25rem;
    font-size: ${({ theme }) => theme["xs-small-size"]};
    max-height:28px;
    &.--PhoneInput-color--focus{
      color:transparent;
    }
  }
  .PhoneInputInput{
    border: none;
    outline:none;
  }`;

export { InputWrapper, PhoneNumberWrapper };
