import { isEmpty } from 'lodash';
import moment from "moment";

import React, { useState, useEffect } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Stack } from "@mui/material";
import {
  BsArrowLeftShort,
  BsDownload,
  BsThreeDotsVertical,
} from "react-icons/bs";
import { AiOutlineArrowLeft } from 'react-icons/ai';

import CustomTooltip from "../../components/Tooltip/index";
import Drawer from "../../components/Drawer/index";
import Pagination from "../../components/Pagination/pagination";
import Spin from "../../components/Spin/Index";
import Table from "../../components/Table/table";
import Toast from '../../components/Toast';

import {
  DownloadInvoicePdf,
  GetCaseStatDetail,
  GetInvoiceDetails,
  SetInvoiceState
} from "../../redux/slices/invoice-slice";

import { CURRENCY_SYMBOLS, DOMAINS, MARKETPLACE_TO_REGION } from '../../constants';

import { FormatValueToLocaleString, CapitalizeFirstLetter } from '../../helpers/format-value';

import Product from "../../assets/images/product.svg";

import { InvoiceWrapper } from "./style";

const formatExchangeRatesWithCurrencySymbol = (exchangeRates) => {
  if (exchangeRates) {
    const columnData = [];
    for (const [key, value] of Object.entries(exchangeRates)) {
      const currencySymbol = CURRENCY_SYMBOLS[key];
      columnData.push(
        <Stack key={key} className="q36rmd" direction="column" spacing={5}>
          {currencySymbol + '' + FormatValueToLocaleString(value)}
        </Stack>
      )
    };
    return columnData;
  }

}

const formatReimbursedAmountWithCurrencySymbol = (amountReimbursed) => {
  if (amountReimbursed) {
    const columnData = [];

    for (const [key, value] of Object.entries(amountReimbursed)) {
      if (key !== 'units') {
        columnData.push(
          <Stack key={key} direction="column">
            {`${CURRENCY_SYMBOLS[key]}${FormatValueToLocaleString(value)}`}
          </Stack>
        )
      }
    };
    return columnData;
  }
};

const Index = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();

  const [details, setDetails] = useState(false);
  const [pageLimit, setPageLimit] = useState(25);
  const [pageNumber, setPageNumber] = useState(1);
  const [caseStatPageLimit, setCaseStatPageLimit] = useState(10);
  const [caseStatPageNumber, setCaseStatPageNumber] = useState(1);

  const [invoiceDetailsBody, setInvoiceDetailsBody] = useState([]);
  const [caseStatHeader, setCaseStatHeader] = useState({});
  const [caseId, setCaseId] = useState('');
  const [reimbursementIds, setReimbursementIds] = useState([]);
  const [type, setType] = useState('');
  const [baseURl, setBaseURL] = useState('');

  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [severity, setSeverity] = useState('');
  const [callApiForStat, setCallApiForStat] = useState(false)

  const {
    invoiceDetails,
    message,
    error,
    success,
    invoiceDetailsTotal: total,
    headerDetailOfInvoiceDetail,
    totalReimbursments,
    caseStatDetails,
    caseStatDetailsTotal,
    loading
  } = useSelector((state) => state.invoice);

  const invoiceId = params.id;
  useEffect(() => {
    getInvoiceDetails(invoiceId);
  }, [pageNumber, pageLimit])

  useEffect(() => {
    if (caseId !== '' && reimbursementIds.length) {
      getCaseStatDetails();
    }
  }, [caseId,callApiForStat, caseStatPageNumber, caseStatPageLimit]);


  useEffect(() => {
    if (success && invoiceDetails.length > 0) {
      const invoiceDetailData = invoiceDetails.map((item, index) => {
        const currencySymbol = CURRENCY_SYMBOLS[item.baseCurrency?.toUpperCase()] || '$';
        const region = MARKETPLACE_TO_REGION[item?.marketplaceId] || 'na';
        const baseURl = DOMAINS[region];
        return (
          <tr key={index}>
            <td>
              <>
               <div className="shipping-detail d-flex">
                <img
                  width="40"
                  height="40"
                  src={item.imageUrl || Product}
                  alt="no-product"
                />
                <div className="info d-flex flex-column">
                  <span className="fs-14 text-ellipse">
                    <CustomTooltip
                      placement="top-start"
                      text={item.title?.length > 50 ? `${item.title?.substring(0, 50)}.....` : item.title}
                      body={item.title}
                    />
                  </span>
                  <span className="d-flex details">
                    <span>
                      <span className="text-primary">ASIN:</span>
                      <span className="fw-bold p-1 weight-bold">
                        {item.asin}
                      </span>
                    </span>
                    <span>
                      <span className="text-primary">SKU:</span>
                      <span className="p-1 weight-bold">
                        <span className="fs-14 text-ellipse">
                          <CustomTooltip
                            placement="bottom-start"
                            text={item.sellerSku?.length > 15 ? `${item.sellerSku.substring(0, 11)}....` : item.sellerSku}
                            body={item.sellerSku}
                          />
                        </span>
                      </span>
                    </span>
                  </span>
                </div>
              </div>
              </>
            </td>
            <td>
             <>
              <span className="badge-warning color-light">{item.type}</span>
              </>
            </td>
            <td>
              <>
              <a 
               href={`${baseURl}/cu/case-dashboard/view-case?ref=sc_cd_lobby_vc_v3&ie=UTF&caseID=${item.caseId}&View+or+Respond=Submit`} 
               onClick={(e) => handleCaseIdClick(e, baseURl, item.caseId)}
               style={{ textDecoration: 'none', cursor: "pointer" }}
              >
               {item.caseId}
              </a>
              </>
              </td>
            <td>
              <>
              {currencySymbol + (item.inventoryReimbursed.amount || 0)?.toFixed(2)}
              <span className="weight-bold d-block">
                Units <b className="color-black">{item.inventoryReimbursed.units}</b>
              </span>
              </>
            </td>
            <td>
              <>
              {currencySymbol + (item.cashReimbursed.amount || 0)?.toFixed(2)}
              <span className="weight-bold d-block">
                Units <b className="color-black">{item.cashReimbursed.units}</b>
              </span>
              </>
            </td>
            <td>
              <>{currencySymbol + (item.totalReimbursed.amount || 0 )?.toFixed(2)}
              <span className="weight-bold d-block">
                Units <b className="color-black">{item.totalReimbursed.units}</b>
              </span>
              </>
            </td>
            {/* <td>
              {currencySymbol + item.totalReimbursed.amount}
              <span className="d-block">
                <CustomTooltip
                  text={currencySymbol + item.totalReimbursed.amount}
                  body={
                    <>
                      <div className="d-flex justify-content-between">
                        <span className="fs-12 text-primary">
                          Currency Conversion Rate
                    </span>
                        <span>
                          $300 x 166 = <b>$000.00</b>
                        </span>
                      </div>
                      <div className="d-flex justify-content-between">
                        <span className="fs-12 text-primary">
                          Currency Conversion Rate
                    </span>
                        <span>
                          $240 x 150 = <b>$000.00</b>
                        </span>
                      </div>
                      <div className="d-flex justify-content-between">
                        <span className="fs-12 text-primary">
                          Currency Conversion Rate
                    </span>
                        <span>
                          $110 x 90 = <b>$000.00</b>
                        </span>
                      </div>
                      <div className="d-flex justify-content-between">
                        <span className="fs-12 text-primary">total</span>
                        <span>
                          <b>$000.00</b>
                        </span>
                      </div>
                      <div className="d-flex justify-content-between">
                        <span className="fs-12 text-primary">
                          Rate (16%)
                    </span>
                        <span>
                          <b>$000.00</b>
                        </span>
                      </div>
                      <hr />
                      <div className="d-flex justify-content-between">
                        <span className="fs-12 text-primary">Total</span>
                        <span>
                          <b>$000.00</b>
                        </span>
                      </div>
                    </>
                  }
                />
              </span>
              <span>
                Units <b className="color-black">{item.totalReimbursed.units}</b>
              </span>
            </td> */}
            <td><>{currencySymbol + (item.comissionedAmount || 0)?.toFixed(2)}</></td>
            <td><>{moment(item.dateOfCaseSubmitted).format('ll')}</></td>
            <td><>{moment(item.closeDate).format('ll')}</></td>
            <td>
              <>
                <BsThreeDotsVertical
                  className="color-secondary cursor-pointer"
                  onClick={() => handleSideBar(item, baseURl)}
                />
              </>
            </td>
          </tr>
        )
      })
      setInvoiceDetailsBody(invoiceDetailData);
    } else setInvoiceDetailsBody([]);
  }, [invoiceDetails]);

  useEffect(() => {
    if (error) {
      setToastMessage(error);
      setSeverity('error');
      setToastOpen(true);
      dispatch(SetInvoiceState({ field: 'error', value: '' }));
      setCaseStatHeader({});
      return;
    }
  }, [error]);

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToastOpen(false);
  };

  const handleSideBar = (doc, baseURl) => {
    setBaseURL(baseURl);
    setCaseStatHeader(doc);
    setDetails(true);
    setReimbursementIds(doc?.reimbursementIds || []);
    const caseId = doc.caseId;
    setCaseId(caseId);
    setType(doc.type);
    setCallApiForStat(!callApiForStat)
  }

  const handleCaseIdClick = (e, baseUrl, caseId) => {
    e.preventDefault();
    const caseUrl = `${baseUrl}/cu/case-dashboard/view-case?ref=sc_cd_lobby_vc_v3&ie=UTF&caseID=${caseId}&View+or+Respond=Submit`;
    window.open(caseUrl, '_blank');
  }

  const handleReimbursementIdClick = (e, baseUrl, reimbursementId) => {
    e.preventDefault();
    const caseUrl = `${baseUrl}reportcentral/REIMBURSEMENTS/0/%7B"filters":["","","${reimbursementId}",""],"searchDays":365%7D`;
    window.open(caseUrl, '_blank');
  }

  const getCaseStatDetails = () => {
    const skip = (caseStatPageNumber - 1) * caseStatPageLimit;
    const limit = caseStatPageLimit;

    dispatch(GetCaseStatDetail({ caseId, skip, limit, type, reimbursementIds }))
  };

  const handleBack = () => {
    dispatch(SetInvoiceState({ field: 'totalReimbursments', value: {} }))

    history.push({
      pathname: '/invoices',
      state: {
        from: history.location.pathname
      }
    });
  }

  const getInvoiceDetails = (invoiceId) => {
    const skip = (pageNumber - 1) * pageLimit;
    const limit = pageLimit;
    dispatch(GetInvoiceDetails({ invoiceId, skip, limit }));
  };

  const handlePageLimit = (e) => {
    setPageLimit(e.value);
  };

  const handlePageNumberChange = (e) => {
    const totalPages = Math.ceil(total / pageLimit)
    if (e.key == "Enter") {
      if (e.target.value < 1 || !e.target.value) {
        setPageNumber(1);
      } else if (e.target.value > totalPages) {
        setPageNumber(totalPages)
      } else setPageNumber(e.target.value)
    }
  }

  const handlePageChangeLeft = () => {
    if (pageNumber - 1 > 0) setPageNumber(pageNumber - 1);
    else setPageNumber(1);
  }

  const handlePageChangeRight = () => {
    const totalPages = Math.ceil(total / pageLimit)
    if (pageNumber + 1 < totalPages) setPageNumber(pageNumber + 1);
    else setPageNumber(totalPages > 0 ? totalPages : 1);
  }

  const caseStatHandlePageLimit = (e) => {
    setCaseStatPageLimit(e.value);
  }

  const caseStatHandlePageNumberChange = (e) => {
    const totalPages = Math.ceil(caseStatDetailsTotal / caseStatPageLimit)
    if (e.key == "Enter") {
      if (e.target.value < 1 || !e.target.value) {
        setCaseStatPageNumber(1);
      } else if (e.target.value > totalPages) {
        setCaseStatPageNumber(totalPages)
      } else setCaseStatPageNumber(e.target.value)
    }
  }

  const caseStatHandlePageChangeLeft = () => {
    if (caseStatPageNumber - 1 > 0) setCaseStatPageNumber(caseStatPageNumber - 1);
    else setCaseStatPageNumber(1);
  }

  const caseStatHandlePageChangeRight = () => {
    const totalPages = Math.ceil(caseStatDetailsTotal / caseStatPageLimit)
    if (caseStatPageNumber + 1 < totalPages) setCaseStatPageNumber(caseStatPageNumber + 1);
    else setCaseStatPageNumber(totalPages);
  }

  const handleDownloadPdf = (invoiceId) => {
    console.log('\n invoiceId', invoiceId);
    dispatch(DownloadInvoicePdf({ invoiceNumber: invoiceId }));
  };

  return (
    <InvoiceWrapper>
      <div className="d-flex align-items-center grid-column-gap-10">
       <Toast
        message={toastMessage}
        severity={severity}
        toastOpen={toastOpen}
        handleToastClose={handleToastClose}
      />
      <AiOutlineArrowLeft className="cursor-pointer" onClick={handleBack} />
        <h3 className="mb-0">Invoice Details</h3>
      </div>
      <div className="mt-4 invoice-status p-3 d-flex justify-content-between">
        <div className="d-flex flex-column">
          <span className="text-primary weight-bold fs-12">Store</span>
          <span className="fs-14 d-block text-primary weight-bold">
            {headerDetailOfInvoiceDetail.storeName}
          </span>
          <span className="text-primary weight-bold fs-10">
            {headerDetailOfInvoiceDetail.userEmail}
          </span>
        </div>
        <div className="d-flex flex-column">
          <span className="text-primary weight-bold fs-12">Invoice #</span>
          <span className="fs-14 d-block text-primary weight-bold">
            {headerDetailOfInvoiceDetail.invoiceNumber}
          </span>
          <span className="text-primary weight-bold fs-10"> {moment(headerDetailOfInvoiceDetail.invoiceCreatedAt).format('ll')}</span>
        </div>
        <div className="d-flex flex-column">
          <span className="text-primary weight-bold fs-12"> Status</span>
          <span className="fs-12 d-block  weight-bold badge"> {CapitalizeFirstLetter(headerDetailOfInvoiceDetail.status)}</span>
        </div>
        <div className="d-flex flex-column">
          <span className="text-primary weight-bold fs-12">
            Total Reimbursments{" "}
          </span>
          <span className="fs-12 d-block text-primary   weight-bold">
            {totalReimbursments && formatExchangeRatesWithCurrencySymbol(totalReimbursments, false) || 'N/A'}
          </span>
        </div>
        <div className="d-flex flex-column">
          <span className="text-primary weight-bold fs-12">
            Comissioned Rate (%)
          </span>
          <span className="fs-12 d-block text-primary   weight-bold">{headerDetailOfInvoiceDetail.commissionDiscount}</span>
        </div>
        <div className="d-flex flex-column">
          <span className="text-primary weight-bold fs-12">{headerDetailOfInvoiceDetail.status === 'paid' ? 'Total Paid' : 'Total Due'}</span>
          <span className="fs-12 d-block text-primary   weight-bold">      
            {
              headerDetailOfInvoiceDetail.status === 'paid' ? (
                CURRENCY_SYMBOLS[headerDetailOfInvoiceDetail.amountPaidCurrency]+(headerDetailOfInvoiceDetail.amountPaid || 0)?.toFixed(2)
              ):(
                CURRENCY_SYMBOLS[headerDetailOfInvoiceDetail.amountPaidCurrency]+(headerDetailOfInvoiceDetail.amountDue || 0)?.toFixed(2)
              )
            }
          </span>
        </div>
        <div className="d-flex align-items-center">
          <BsDownload
            className="color-secondary cursor-pointer"
            onClick={() => handleDownloadPdf(invoiceId)}
          />
        </div>
      </div>
      <div className="pt-4">
        <Table
          viewHeight="invoice-details-table"
          tableHeader={
            <tr>
              <th>Description</th>
              <th> Type </th>
              <th>Case ID</th>
              <th>
                <span className="d-block">Inventory</span>
                <span className="fs-8">Reimbursed</span>
              </th>

              <th>
                <span className="d-block">Cash</span>
                <span className="fs-8">Reimbursed</span>
              </th>
              <th>
                <span className="d-block">Total</span>
                <span className="fs-8">Reimbursed</span>
              </th>
              <th>
                <span className="d-block">Amount</span>
                <span className="fs-8">Comissioned</span>
              </th>
              <th>Case Submitted Date</th>
              <th>Case Closed Date</th>
              <th className="text-right"></th>
            </tr>
          }
          tableBody={
            <> 
             {!loading && !invoiceDetails.length ? (
                <tr><td>
                  <div className='error'>
                    <h1>No Data Available</h1>
                  </div>
                </td></tr>
                 ) : null}
                {loading ?<tr><td><Spin /></td></tr>: invoiceDetailsBody}
           </>
           }
        />

        <Pagination
          pageLimit={pageLimit}
          total={total}
          pageNumber={pageNumber}
          totalPages={Math.ceil(total / pageLimit)}
          handlePageChangeLeft={handlePageChangeLeft}
          handlePageChangeRight={handlePageChangeRight}
          handleLimitChange={handlePageLimit}
          handlePageNumberChange={handlePageNumberChange}
        />
      </div>
      {details && (
        <Drawer show={details} size="75%">
          <div className="p-4">
            <div className="d-flex align-items-center grid-column-gap-10">
              <BsArrowLeftShort
                onClick={() => {
                  setDetails(false);
                  setCaseId('');
                  setType('');
                }}
                className="cursor-pointer color-secondary"
              />
              <h3 className="mb-0">Details</h3>
            </div>
            <div className="mt-4 invoice-status p-3 d-flex justify-content-between">
              <div className="d-flex flex-column">
                <span className="text-primary weight-bold fs-12">Product</span>

                <span className="text-primary weight-bold fs-10">
                  FNSKU:<span className="color-black">{caseStatHeader?.fnsku}</span>
                </span>
                <span className="text-primary weight-bold fs-10">
                  SKU:<span className="color-black">{caseStatHeader?.sellerSku}</span>
                </span>
              </div>
              <div className="d-flex flex-column">
                <span className="text-primary weight-bold fs-12">Case ID</span>
                <span className="fs-14 d-block color-secondary">
                  <a
                    href={`${baseURl}/cu/case-dashboard/view-case?ref=sc_cd_lobby_vc_v3&ie=UTF&caseID=${caseStatHeader?.caseId}&View+or+Respond=Submit`}
                    onClick={(e) => handleCaseIdClick(e, baseURl, caseStatHeader?.caseId)}
                    style={{ textDecoration: 'none', cursor: "pointer" }}
                  >
                  {caseStatHeader?.caseId}
                  </a>
                </span>
              </div>
              <div className="d-flex flex-column">
                <span className="text-primary weight-bold fs-12">Inventory </span>
                <span className="fs-8 d-block text-primary  weight-bold">
                  Reimbursed{" "}
                </span>
                <span className="fs-14 d-block text-primary">{ isEmpty(caseStatHeader) ? "$" : CURRENCY_SYMBOLS[caseStatHeader.baseCurrency]}{(caseStatHeader?.inventoryReimbursed?.amount || 0)?.toFixed(2)} </span>
                <span className="fs-10 d-block text-primary  weight-bold">
                  <span>
                    Units <b className="color-black">{caseStatHeader?.inventoryReimbursed?.units}</b>
                  </span>
                </span>
              </div>
              <div className="d-flex flex-column">
                <span className="text-primary weight-bold fs-12">Cash </span>
                <span className="fs-8 d-block text-primary  weight-bold">
                  Reimbursed{" "}
                </span>
                <span className="fs-14 d-block text-primary">{CURRENCY_SYMBOLS[caseStatHeader.baseCurrency]}{(caseStatHeader?.cashReimbursed?.amount || 0)?.toFixed(2)}</span>
                <span className="fs-10 d-block text-primary  weight-bold">
                  <span>
                    Units <b className="color-black">{caseStatHeader?.cashReimbursed?.units}</b>
                  </span>
                </span>
              </div>
              <div className="d-flex flex-column">
                <span className="text-primary weight-bold fs-12">Total </span>
                <span className="fs-8 d-block text-primary  weight-bold">
                  Reimbursed{" "}
                </span>
                <span className="fs-14 d-block text-primary">{CURRENCY_SYMBOLS[caseStatHeader?.baseCurrency]}{(caseStatHeader?.totalReimbursed?.amount || 0 )?.toFixed(2)}</span>
                <span className="fs-10 d-block text-primary  weight-bold">
                  <span>
                    Units <b className="color-black">{caseStatHeader?.totalReimbursed?.units}</b>
                  </span>
                </span>
              </div>
              <div className="d-flex flex-column">
                <span className="text-primary weight-bold fs-12">Rate (%) </span>
                <span className="fs-8 d-block text-primary  weight-bold">
                  Comissioned{" "}
                </span>
                <span className="fs-14 d-block text-primary">{ !isEmpty(caseStatHeader) ? headerDetailOfInvoiceDetail.commissionDiscount: null}</span>
              </div>
              <div className="d-flex flex-column">
                <span className="text-primary weight-bold fs-12">Amount </span>
                <span className="fs-8 d-block text-primary  weight-bold">
                  Comissioned{" "}
                </span>
                <span className="fs-14 d-block text-primary">{CURRENCY_SYMBOLS[caseStatHeader?.baseCurrency]}{(caseStatHeader?.comissionedAmount || 0)?.toFixed(2)}</span>
              </div>
            </div>
            <div className="pt-4">
              <Table
                viewHeight="case-stat-table"
                tableHeader={
                  <tr>
                    <th>Reimbursed ID</th>
                    <th>
                      <span className="d-block">Inventory</span>
                      <span className="fs-8">Reimbursed</span>
                    </th>

                    <th>
                      <span className="d-block">Cash</span>
                      <span className="fs-8">Reimbursed</span>
                    </th>

                    <th>
                      <span className="d-block">Total</span>
                      <span className="fs-8">Reimbursed</span>
                    </th>
                    <th>
                      <span className="d-block">Rate</span>
                      <span className="fs-8">Exchange</span>
                    </th>
                    <th>
                      <span className="d-block">Amount</span>
                      <span className="fs-8">Exchange</span>
                    </th>
                    <th>
                      <span className="d-block">Amount</span>
                      <span className="fs-8">Comissioned</span>
                    </th>
                  </tr>
                }
                tableBody={
                  <>
                   {!loading && !caseStatDetails.length ? (
                     <tr><td>
                      <div className='error'>
                        <h1>No Data Available</h1>
                      </div>
                      </td></tr>
                 ) : null}
                {loading ? <tr><td><Spin /></td></tr>:
                  caseStatDetails?.map((item, index) => {
                    return (
                      <tr key={index}>
                        {/* <td>
                          <span>{item.reimbursementId}</span>
                        </td> */}
                        <td>
                          <>
                          <a 
                            href={`${baseURl}/reportcentral/REIMBURSEMENTS/0`}
                            style={{ textDecoration: 'none', cursor: "pointer" }} 
                            onClick={(e) => handleReimbursementIdClick(e, baseURl, item.reimbursementId)}
                          >
                            {item.reimbursementId}
                          </a>
                          </>
                         </td>
                        {/* <td>
                        <span className="badge-warning color-light">Destroyed</span>
                      </td> */}
                        {/* <td className="color-secondary">123456789</td> */}
                        <td>
                        <>
                          <span className="d-block">
                            {item.inventoryReimbursed && formatReimbursedAmountWithCurrencySymbol(item.inventoryReimbursed)}
                            {/* <CustomTooltip
                            text={item.inventoryReimbursed && formatReimbursedAmountWithCurrencySymbol(item.inventoryReimbursed)}
                            
                          /> */}
                          </span>
                          <span className="weight-bold">
                            Units <b className="color-black">{item.inventoryReimbursed.units}</b>
                          </span>
                          </>
                        </td>
                        <td>
                          <>
                          <span className="d-block">
                            {item.cashReimbursed && formatReimbursedAmountWithCurrencySymbol(item.cashReimbursed)}
                          </span>
                          <span>
                            Units <b className="color-black">{item.cashReimbursed.units}</b>
                          </span>
                          </>
                        </td>
                        <td>
                          <>
                            <span className="d-block">
                              {item.totalReimbursed && formatReimbursedAmountWithCurrencySymbol(item.totalReimbursed)}
                            </span>
                            <span>
                              Units <b className="color-black">{item.totalReimbursed.units}</b>
                            </span>
                          </>
                        </td>
                        <td><>{CURRENCY_SYMBOLS[item.exchangedRateCurrency] + (item.exchangedRate || 0)?.toFixed(2)}</></td>
                        <td><>{CURRENCY_SYMBOLS[item.exchangedAmountCurrency] + (item.exchangedAmount || 0)?.toFixed(2)}</></td>
                        <td><>{CURRENCY_SYMBOLS[item.comissionedAmountCurrency] + (item.comissionedAmount || 0)?.toFixed(2)}</></td>
                      </tr>
                    )
                  })
                 }
                </>
                }
              />

              <Pagination
                pageLimit={caseStatPageLimit}
                total={caseStatDetailsTotal}
                pageNumber={caseStatPageNumber}
                totalPages={Math.ceil(caseStatDetailsTotal / caseStatPageLimit)}
                handlePageChangeLeft={caseStatHandlePageChangeLeft}
                handlePageChangeRight={caseStatHandlePageChangeRight}
                handleLimitChange={caseStatHandlePageLimit}
                handlePageNumberChange={caseStatHandlePageNumberChange}
              />
            </div>
          </div>
        </Drawer>
      )}
    </InvoiceWrapper>
  );
};
export default Index;
