import Styled from 'styled-components';

const TableWrapper = Styled.div`
  .table-responsive{
    overflow:auto;
    background-color: #FFFF;
    &.dashboard{
      height:calc(100vh - 446px);
    }
    &.stats-table{
      height:calc(100vh - 269px);
      border-bottom: 1px solid #4b5664;
      @media(min-width:1602px){
              height:calc(100vh - 239px);
      }
    }
    &.jobs-table{
      height:calc(100vh - 155px);
      border-bottom: 1px solid #4b5664;
    }
    &.users-table{
      height:calc(100vh - 142px);
      border-bottom: 1px solid #4b5664;
    }
    &.fulfillment-fees-table{
      height:calc(100vh - 160px);
      border-bottom: 1px solid #4b5664;
    }
    &.invoice-details-table{
      height:calc(100vh - 300px); 
    }
    &.case-stat-table {
      height:calc(100vh - 239px);
    }
    &.jobs{
      height:calc(100vh - 200px);
    }
    &.invoices{
      height:calc(100vh - 148px);
      border-bottom: 1px solid #4b5664;
    }
    &.invoice-table{
      height:calc(100vh - 239px); 
    }
    &.users{
      height:calc(100vh - 180px);
    } 
    &.drawer{
      height:calc(100vh - 155px);
    }
    &.stores{
      height:calc(100vh - 165px);
    } 
    }
    .table{
      thead{
        tr{
          border-bottom:1px solid #FAFAFA;
          th{
            background-color:${({ theme }) => theme['table-header']};
            padding:10px 16px;
            font-size:${({ theme }) => theme['base-font-size-small']};
            color:${({ theme }) => theme['text-primary']};
            border-top:none;
            border-bottom:1px solid  ${({ theme }) => theme['text-primary']};
            position: sticky;
            top: 0;
          }
        }
      }
      tbody{
        tr{
          &:nth-of-type(even){
        background-color:${({ theme }) => theme['table-header']};
      }
          td{
            font-size:${({ theme }) => theme['medium-font-size']};
            color:#4E6069;
            border-top:none;
            vertical-align:middle;
            white-space:nowrap;
            padding: 10px 16px;
            .badge-paid{
            background-color:${({ theme }) => theme['success-color']}10; 
            color:${({ theme }) => theme['success-color']};
            border:1px solid ${({ theme }) => theme['success-color']}; 
            font-size:${({ theme }) => theme['base-font-size-small']}; 
            padding: 3px 15px;
            border-radius: 50px;
            }
            .shipping-detail{
              span{
                font-size:0.625rem
            }
            }
          }
        }
      }
    }
  }
  .trash{
    color:${({ theme }) => theme["danger-color"]};
}
.action-class{
    display: flex;
    justify-content: space-evenly;
}
  }
  `;


export { TableWrapper };
