import styled from "styled-components";
import RedCard from "../../../assets/images/red-card.svg";

const TitleStyleWrapper = styled.div`
  max-width: 239px;
  h3.current-tile {
    margin: 0px;
    color: var(--Selected_Tab, #1890ff);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
  }
  p.circle-icon {
    border-radius: 30px;
    background: rgba(2, 99, 99, 0.1);
    display: flex;
    width: var(--Main-System-16px, 16px);
    height: var(--Main-System-16px, 16px);
    padding: 0px 2px 0px 1px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    cursor: pointer;
    width: 20px;
    font-size: 11px !important;
    height: 20px;
    margin: 0px;
    padding: 5px !important;
    margin: 0px !important;
    color: #026363 !important;
  }

  p {
    color:  #98a1a5;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }
  .step_one_text {
    color:  #98a1a5;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }

  .card_overlay_wrapper {
    display: flex;
    align-items: center;
    grid-gap: 39px;
    p {
      color: var(--Label, #8e97a4);

      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px; /* 133.333% */
      margin: 0px;
    }
    span {
      a{
        color:${(props) => props.theme["new-primary"]};
        text-decoration: none;
      }

      /* Selected */

      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px; /* 133.333% */
      display: flex;
      grid-gap: 4px;
    }
    .card_flags_wrapper {
      display: flex;
      align-items: center;
      grid-gap: 4px;
    }
  }
`;
const StepOneWrapper = styled.div`
  .bar_overlay_wrapper {
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    border-radius: 8px;
    border: 1px solid #89c0c0;
    background: #f8ffff;
    padding: 15px 16px;
    .left_side_data {
      display: flex;
      align-items: center;
      grid-gap: 16px;
      .left_side_content {
        display: flex;
        flex-direction: column;
        grid-row-gap: 4px;
        h6 {
          color: var(--Body-Darker, #474747);
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 18px;
          margin: 0px;
        }
        p {
          color: var(--Text-Body-Text, #535e63);
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
          margin: 0px;
        }
      }
    }
    .right_side_data {
      display: flex;
      flex-direction: column;
      grid-row-gap: 6px;
      p {
        color: var(--Label, #8e97a4);
        text-align: center;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: 12px;
        margin: 0px;
      }
    }
    .right_side_content {
      display: flex;
      align-items: end;
      grid-gap: 4px;
      p {
        color: var(--Label, #8e97a4);
        text-align: center;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: 12px;
        margin: 0px;
      }
    }
  }
  .footer_item {
    p {
      b {
        padding-left: 3px;
      }
    }
  }
  .amazon_overlay_bar {
    border-radius: 8px;
    border: 1px solid var(--Border-Variant, #dadfe3);
    background: var(--White, #fff);
    margin-top: 16px;
    padding: 24px;
    .header_overlay_wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .amazon_overlay_item {
      display: flex;
      flex-direction: column;
      grid-row-gap: 11px;
      img {
        margin: 0;
      }
      &:nth-child(2) {
        flex-direction: row;
        grid-gap: 8px;
      }
      h6 {
        color: var(--Body-Darker, #474747);
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        margin: 0px;
      }
      p {
        color: var(--text-field-body-color, #5e718d);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        margin: 0px;
      }
    }
    img {
      width: 100%;
      margin-top: 24px;
    }
  }
  .bar_footer_wrapper {
    border-radius: 8px;
    border: 1px solid var(--Border-Variant, #dadfe3);
    background: var(--White, #fff);
    padding: 24px;
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    grid-row-gap: 16px;
    h5 {
      color: var(--Body-Darker, #474747);
      font-family: Lato;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
      margin-bottom: 0px;
    }
    p {
      color: var(--text-field-body-color, #5e718d);
      font-family: Lato;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
    button {
      margin-left: auto;
    }
  }
    .loader-wrapper {
    width: 100%;
    right: 0;
    height: calc(100vh - 62px);
    background: #ffffffd9;
    top: 0;
    z-index:2;
}
`;
const NoEligibleWrapper = styled.div`
  border-radius: 8px;
  border: 1px solid var(--Placeholder-Border, #dbdfe1);
  background: var(--White, #fff);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .card_ui_overlay {
    background-image: url(${RedCard});
    width: 670px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    height: 424px;
    .card_ui_wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      h4 {
        color: var(--Secondary, #151a26);
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        margin: 0;
        margin-top: 16px;
        margin-bottom: 12px;
      }
      p {
        max-width: 353px;
        color: var(--Body-Darker, #474747);
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        margin: 0px;
      }
      button{
        margin-top: 16px;
        width: 397px;
        justify-content: center;
      }
    }
  }
`;
export { TitleStyleWrapper, StepOneWrapper, NoEligibleWrapper };
