import Styled from 'styled-components';

const InvoiceWrapper = Styled.div`
 .invoice-filter{
    border: 1px solid #E6E6E6;
    border-radius:50px;
    button{
        border-radius:50px;
        background-color:transparent ;
        border:none;
        margin-bottom:0;
        color: ${({ theme }) => theme["text-primary"]};
        font-weight:${({ theme }) => theme["normal-weight"]};
        &.active{
        background:${({theme}) => `linear-gradient(90deg, ${theme['button-secondary-color']} 0%, ${theme['primary-color']} 100%)`};
        border-radius:50px;
        font-weight:${({ theme }) => theme["bold-weight"]};
        border-top-right-radius: 50px!important;
        border-bottom-right-radius: 50px!important;
        }
    }
}
.grid-column-gap-16{
    grid-column-gap:16px;
}
.small-fontSize{
    font-size:0.675rem;
}
.table-badge{
    color:${({ theme }) => theme["white-color"]};
    padding:2px 12px;
    border-radius:50px;
    font-weight:${({ theme }) => theme["weight-bold"]};
    &.paid{
        background-color:${({ theme }) => theme["success-color"]};
    }
    &.unpaid{
        background-color:${({ theme }) => theme["warning-color"]};
    }
}
.error {
    position: absolute;
    left: 40%;
    top: 50%;
    h1{
      color: black;
    }
}
`;

export { InvoiceWrapper };
