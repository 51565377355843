import Styled from 'styled-components';
import { Button } from "react-bootstrap"
const ButtonWrapper = Styled(Button)`
    margin-bottom:5px;
    font-size:${({ theme }) => theme['medium-font-size']};
    font-weight: 700;
    line-height: 17px;
    padding:7.5px 24px;
    height:32px;
    min-width:77px;
    width: ${props => props.width};
    align-items: center;
    border-radius: 10px;

    &:hover, &:focus {
       box-shadow:none;
      }
      &.primary{
        background:${({theme}) => `linear-gradient(180deg, ${theme['primary-variant']} 0%, ${theme['primary']} 49.67%, ${theme['primary-btn-gradient-3']} 98.63%)`};
        border:none;
        &.btn-primary:not(:disabled):not(.disabled):active{
          background:${({theme}) => `linear-gradient(180deg, ${theme['primary-variant']} 0%, ${theme['primary']} 49.67%, ${theme['primary-btn-gradient-3']} 98.63%)`};

        color:${({ theme }) => theme['white-color']};
      }
      }
      &.outlined{
        border: 1px solid ${({ theme }) => theme['primary']};
        /* border: 1px solid #4438F9; */
        background-color: transparent;
        /* color:${({ theme }) => theme['secondary-color']}; */
        color: ${({ theme }) => theme['primary']};
        &.btn-primary:not(:disabled):not(.disabled):active{
        background-color:transparent; 
        border: 1px solid ${({ theme }) => theme['primary']};
        color:${({ theme }) => theme['primary']};
      }
      
      }
  `;
export { ButtonWrapper };
