import { isEmpty  } from 'lodash';
import React, { useEffect, useState } from 'react';
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";

import Select from "../../components/Select/index";

import { PaginationWrapper } from './style';

const Index = (props) => {
  const {
    total,
    totalPages,
    pageNumber,
    pageLimit,
    handleLimitChange,
    defaultValue,
    handlePageNumberChange,
    handlePageChangeRight,
    handlePageChangeLeft,
    className
  } = props;

  const [page, setPage] = useState(pageNumber);
  const [selectedValue, setSelectedValue] = useState({value: 25, label: 25});

  useEffect(() => {
    setPage(pageNumber);
    if (defaultValue) {
      if (!isEmpty(defaultValue)) {
        setSelectedValue(defaultValue);
      } else {
        setSelectedValue({value: 25, label: 25});
      }
    }
  }, [pageNumber, defaultValue]);

  const handlePageChange = (value) => {
    if (!value || /^\d+$/.test(value)) {
      setPage(value);
    }
  };

  const options = [
    { value: 25, label: 25 },
    { value: 50, label: 50 },
    { value: 100, label: 100 },
  ];

  const handleLimit = (e) => {
   setSelectedValue(e);
    handleLimitChange(e);
  }

  return (
    <PaginationWrapper className={className}>
      <div className='d-flex justify-content-between'>
        <div className='d-flex align-items-center'>
          <div className='d-flex item-per-page align-items-center'>
            <span className='fs-12'>Items per page </span>
            <Select
              menuPlacement="top"
              value={selectedValue}
              className="pagination-select"
              options={options}
              handleChange={(e) => handleLimit(e)}
            />
          </div>
          <div className='total-items d-flex ml-3 fs-12'>
          <span className='pr-1'>{(pageLimit * pageNumber) < total ? (pageLimit * pageNumber) : total}</span> of {total} items
          </div>
        </div>
        <div className="d-flex total-pages align-items-center" >
        <div className='total-items d-flex fs-12'>
          <input style={{maxHeight: '21px', maxWidth: "42px"}} value={totalPages > 0 ? page : 0} onChange={(e) => handlePageChange(e.target.value)} onKeyDown={(e) => handlePageNumberChange(e)}></input>
            <span className='pr-1 pl-2'></span> of {totalPages} pages
          </div>
          <div className='arrows pl-2'>
            <BiChevronLeft className='left-controle' onClick={handlePageChangeLeft} />
            <BiChevronRight className='left-controle' onClick={handlePageChangeRight} />
          </div>
        </div>
      </div>
    </PaginationWrapper>
  );
}

export default Index;

