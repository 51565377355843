import React, { useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';

import { GridWrapper } from './style';

const Index = (props) => {
  const {
    rowData,
    columnDefs,
    sortable,
    resizable,
    editable,
    height,
    rowHeight,
    onGridReady,
    className,
  } = props;

  const [gridOptions] = useState({
    defaultColDef: {
      sortable: sortable,
      resizable: resizable,
      editable: editable
    },
  });
  return (
    <GridWrapper height={height} className={className}>
      <div className="ag-theme-balham QB-dataTable">
        <AgGridReact
          enableCellTextSelection={true}
          suppressRowClickSelection
          rowHeight={rowHeight}
          rowSelection="multiple"
          columnDefs={columnDefs}
          rowData={rowData}
          onGridReady={onGridReady}
          {...gridOptions}
        />
      </div>
    </GridWrapper>
  );
};

export default Index;
