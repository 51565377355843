import React, { useEffect, useState } from "react";
import { Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";

import { APP_DOMAINS, STORE_STATUS } from "../../../constants";

import { TitleStyleWrapper } from "./style";

import { GetAmazonAccountDetail, GetLatestAmazonAccountDetail, SetState } from "../../../redux/slices/account-detail-slice";

const Title = ({ currentStep }) => {
  const hostName = window.location.hostname;
  const dispatch = useDispatch();
  const [sellerCentralEmail, setSellerCentralEmail] = useState('Manager Email');
  const { storeId } = useSelector((state) => state.auth);
  const [loader, setLoader] = useState(!!storeId);

  const { latestAmazonAccountDetail, account, amazonAccountSuccess, managerName } = useSelector((state) => state.accountDetail);
  const { store } = useSelector((state) => state.store);

  useEffect(() => {
    if (store?.status === STORE_STATUS.PENDING) {
      dispatch(GetLatestAmazonAccountDetail())
    } else if (store?.invite?.amazonAccountDetailId) {
      dispatch(GetAmazonAccountDetail({ accountId: store?.invite?.amazonAccountDetailId }))
    }
  }, [store?.status]);

  useEffect(() => {
    if (account?.sellerCentralLoginEmail) {
      setSellerCentralEmail(account.sellerCentralLoginEmail);
    }
  }, [account])

  useEffect(() => {
    if (amazonAccountSuccess !== null) {
      setLoader(false);
      dispatch(SetState({ field: 'amazonAccountSuccess', value: null }));
    }
  }, [amazonAccountSuccess])

  return (
    <TitleStyleWrapper>
      <h3 className={currentStep === 0 ? "current-tile" : "complete-tile"}>
        Invite Account Manager
      </h3>

      <div className="sub_title_wrapper">
        <h4>
          {
            currentStep === 0 ? null :
              loader ? (
                <Spin size='small' />
              ) : (
                hostName === APP_DOMAINS.sellerRepay ? managerName || 'Seller Repay Manager' :
                  managerName || 'Seller Terminal Manager'
              )
          }
        </h4>
        <p>
          <b>
            {
              currentStep === 1 ? latestAmazonAccountDetail : currentStep > 1 ? sellerCentralEmail || 'Manager Email' : 'Account managers need access to your account to file claims on your behalf'
            }
          </b>
        </p>
      </div>
    </TitleStyleWrapper>
  );
};

export default Title;
