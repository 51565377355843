import 'antd/dist/antd.css';
import { notification } from 'antd';

const Notification = ({ type, title, description, duration = 4.5 }) => {
    console.log({type, title, description});
    notification[type]({
      message: title,
      description: description,
      top: 65,
      duration
    });
  };

export default Notification;
