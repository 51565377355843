import React from 'react';
import { Form } from 'react-bootstrap';

import { CheckBoxWrapper } from './style';

const Index = (props) => {
  const {
    type,
    label,
    value,
    name,
    onChange,
    checked,
    style,
    disabled
  } = props;

  return (
    <CheckBoxWrapper>
      <Form.Group>
        <Form.Check
          checked={checked}
          type={type}
          label={label}
          value={value}
          onChange={onChange}
          name={name}
          style={style}
          disabled={disabled}
        />
      </Form.Group>
    </CheckBoxWrapper>
  )
}

export default Index
