import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import LabelInput from '../../components/Inputs/label-input';
import Button from '../../components/Button';
import Notification from '../../components/notifications/notification'

import ValidateEmail from '../../helpers/validate-email';

import { APP_LOGOS, APP_TITLES } from '../../constants';

import { AuthWrapper } from './style';

import { ResendVerifyEmail, SetAuthState } from '../../redux/slices/auth-slice';


const hostName = window.location.hostname;

const ResendVerificationEmail = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState();

  const { message, error, loading } = useSelector((state) => state.auth);

  useEffect(() => {
    if (error) {
      Notification({
        type: 'error',
        title: 'Error',
        description: error
      });
      dispatch(SetAuthState({ field: 'error', value: '' }));
      return;
    }
    if (message) {
      Notification({
        type: 'success',
        title: 'Success',
        description: message
      });
      dispatch(SetAuthState({ field: 'message', value: '' }));
      dispatch(SetAuthState({ field: 'success', value: false }));

      history.push('/auth/email-verification');
      return;
    }
  }, [error, message]);


  const handleChange = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const resendVerificationEmail = () => {
    if (email === '') {
      getStyles();
      setEmailError('Enter an Email');
    } else {
      const result = ValidateEmail(email);
      if (result) {
        dispatch(ResendVerifyEmail({ email }));
      } else setEmailError('Enter a Valid Email');
    }
  };

  const handleBlur = () => {
    if (email === '') {
      setEmailError('Enter an Email');
    } else {
      const result = ValidateEmail(email);
      if (!result) {
        setEmailError('Enter a Valid Email');
      } else {
        setEmailError('');
      }
    }
  };

  const getStyles = () => {
    if (emailError) {
      return {
        border: '1px solid red',
      };
    }
  };
  return (
    <AuthWrapper>
      <div className='content-holder' style={loading ? { opacity: 0.5 } : null}>
        <Row className='m-0 header-space'>
          <Col md={5} sm={12}>
            <div className='left-section'>
              {APP_LOGOS[hostName] ?
                <img src={APP_LOGOS[hostName]} alt='no-logo' />
                :
                <h2>{APP_TITLES[hostName]}</h2>
              }
              <p className='logo-description mb-0'> Don’t have an account?
              Create one. It takes less than a minuite!
                </p>
            </div>
          </Col>
          <Col md={7} sm={12}>
            <div className='right-content'>
              <div className='user-form'>
                <h2 className='heading'>Didn't Receive Our Email?</h2>
                <span className='heading-caption'>
                  Enter the email address you used when you joined and we'll send
              you a verification Code to verify Your Email Address{' '}
                </span>
                <hr className='bottom-line' />
                <LabelInput
                  label='Email'
                  placeholder='Email'
                  value={email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  style={getStyles()}
                  name='email'
                />
                <span style={{ color:'red', fontSize: '10px' }}>{emailError}</span>
                <div className='d-flex align-items-center button-top justify-content-between'>
                  <Button
                    text={loading ? 'Sending.....' : 'Send Verification Code'}
                    className='primary mb-0'
                    onClick={resendVerificationEmail}
                    width=''
                  />
                  <div className='d-flex justify-content-center have-account'>
                    <p>
                      Return to
                  <Link
                        to='/auth/sign-in'
                      >
                        Login
                  </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </AuthWrapper>
  );
};

export default ResendVerificationEmail;
