import React from "react";
import PropTypes from "prop-types";
import IcomoonReact from "icomoon-react";
import iconSet from "./icons";

const Icon = props => {
  const { color, size, icon, className, name, onClick } = props;
  return (
    <IcomoonReact
    className={className}
    iconSet={iconSet}
    color={color}
    size={size}
    icon={icon}
    onClick={onClick}
  />
  );
};

Icon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.string.isRequired,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

Icon.defaultProps = {
  className: "",
  color: "",
  size: "100%"
};

export default Icon;
