import styled from "styled-components";

const OtpStyleWrapper = styled.div`
input{
    text-align: center;
    width: 48px !important;
    height: 48px;
    padding: 5px 14px;
    border-radius: 8px;
    border: 1px solid #DDD;
    color: #474747;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}




`;
export { OtpStyleWrapper }