import moment from 'moment';
import React, { useState, useEffect } from "react";
import { debounce } from 'lodash';
import { Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { components } from "react-select";

import Checkbox from "../../components/CheckBox/index";
import SearchInput from "../../components/SearchInput";

import DatePickerRange from "../../components/DatePicker/date-picker-range";
import Select from "../../components/Select";
import Table from "../../components/Table/table";
import Pagination from "../../components/Pagination/pagination";
import Spin from "../../components/Spin/Index";

import {
  CURRENCY_SYMBOLS, DOMAINS,
  MARKETPLACE_TO_REGION
} from '../../constants';

import { FormatValueToLocaleString } from '../../helpers/format-value';

import Product from "../../assets/images/product.svg";
import { StatsWrapper } from "./style";

import { GetStoreNames } from '../../redux/slices/store-slice';
import { GetCases } from "../../redux/slices/dashboard-slice";
import { GetUsersName } from '../../redux/slices/user-slice';

const dropDown = [
  { value: 'Pending', label: 'Awaiting Action' },
  { value: 'Resolved', label: 'Resolved' },
  { value: 'Partially Resolved', label: 'Partially Resolved' }
];

const typeDropdown = [
  { value: 'Lost', label: 'Lost' },
  { value: 'Damaged', label: 'Damaged' },
  { value: 'Destroyed', label: 'Destroyed' },
  { value: 'Fba_Shipment', label: 'FBA Shipment' },
  { value: 'Fulfillment_Fee', label: 'Fulfillment Fee' },
  { value: 'Refund', label: 'Refund' },
  { value: 'Removal_Order', label: 'Removal Order' }
];

const { Option } = components;
const Tile = (props) => (
  <Option {...props} value={"default"}>
    <div className="select-option">
      <span className="d-block bold-weight">{props.data.userName !== 'All' ? props.data.userName : null}</span>
      <span>{props.data.email}</span>
    </div>
  </Option>
);

const formatReimbursedAmountWithCurrencySymbol = (amountReimbursed) => {
  if (amountReimbursed) {
    const columnData = [];
    for (const [key, value] of Object.entries(amountReimbursed)) {
      columnData.push(
        <Stack key={key} direction="column">
          {`${CURRENCY_SYMBOLS[key]}${FormatValueToLocaleString(value)}`}
        </Stack>
      )
    };

    return columnData;
  }
};

const Index = () => {
  const dispatch = useDispatch();
  const [date, setDate] = useState([
    {
      startDate: moment({ day: 1, month: 0, year: 2022 }).toDate(),
      endDate: moment().toDate(),
      key: 'selection'
    }
  ]);

  const [selectedStoreValue, setSelectedStoreValue] = useState();
  const [selectedUserValue, setSelectedUserValue] = useState();
  const [selectedCaseStatus, setSelectedCaseStatus] = useState({ label: 'Resolved', value: 'Resolved' });
  const [selectedCaseType, setSelectedCaseType] = useState({ label: 'Select Case Type', value: null });
  const [draft, setDraft] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [pageNumber, setPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState(25);
  const [storeDropdownData, setDropdownData] = useState([]);
  const [selectedStore, setSelectedStore] = useState({});
  const [clicked, setClicked] = useState(true)
  const [userNamesData, setUserNamesData] = useState([]);
  const [isSelected, setIsSelected] = useState(false);

  const [startDate, setStartDate] = useState({
    startDay: 1,
    startMonth: 1,
    startYear: 2022
  });
  const [endDate, setEndDate] = useState({
    endDay: '',
    endMonth: '',
    endYear: ''
  });

  const {
    cases,
    loading,
    totalCases,
    success: casesSuccess
  } = useSelector((state) => state.dashboard);

  const { success, storeNamesList, store } = useSelector((state) => state.store);
  const { user, storeStatus, storesCount, payLater } = useSelector((state) => state.auth);
  const { usersName } = useSelector((state) => state.user);

  useEffect(() => {
    const formatedEndDate = moment(endDate).format('L');
    const checkEnd = moment(formatedEndDate);
    const endDay = checkEnd.format('D');
    const endMonth = checkEnd.format('M');
    const endYear = checkEnd.format('YYYY');
    setEndDate({ endDay, endMonth, endYear });
  }, []);

  useEffect(() => {
    if (usersName && usersName.length > 0) {
      const userNamesData = usersName?.map((user) => {
        return {
          userId: user._id,
          value: [user._id, user.email],
          label: `${user.name || 'N/A'}`,
          userName: user.name,
          email: user.email
        }
      });

      setUserNamesData(userNamesData);
    } else {
      setUserNamesData([]);
    }
  }, [usersName]);

  useEffect(() => {
    if (payLater || store?.payLater || ['admin', 'agency-manager', 'affiliate-manager'].includes(user.role)) {
      dispatch(GetStoreNames());
    }
  }, [dispatch]);

  useEffect(() => {
    if (user && (user.role === 'admin' || user.role === 'agency-manager')) {
      dispatch(GetUsersName());
    }
  }, []);

  useEffect(() => {
    if (success && storeNamesList && storeNamesList.length) {
      const mapedData = storeNamesList.map((doc) => {
        return {
          storeId: doc._id,
          value: [doc._id, doc.email],
          label: `${doc.name || 'N/A'}`,
          userName: doc.name,
          email: doc.email
        }
      })
      const data = [
        ...mapedData
      ]
      setSelectedStoreValue('all');
      setDropdownData(data);
    } else {
      setDropdownData([]);
    }
  }, [success, storeNamesList])

  useEffect(() => {
    if (selectedStoreValue || selectedUserValue) {
      let caseStatus = {};
      let caseType;
      if (selectedCaseStatus) {
        caseStatus = { [selectedCaseStatus.value]: true }
      }

      if (selectedCaseType) {
        caseType = selectedCaseType.value;
      }

      if (!loading && clicked) {
        dispatch(GetCases({
          userId: selectedUserValue,
          storeId: selectedStoreValue,
          caseStatus,
          caseType,
          draft,
          searchKeyword: searchKeyword.trim(),
          limit: pageLimit,
          skip: (pageNumber - 1) * pageLimit,
          startDate,
          endDate
        }));
        setClicked(false)
      }
    }
  }, [
    searchKeyword,
    selectedStoreValue,
    selectedUserValue,
    selectedCaseStatus,
    selectedCaseType,
    pageNumber,
    pageLimit,
    draft,
    startDate,
    endDate,
    casesSuccess
  ]);

  const handleChange = (e, key) => {
    if (key === 'storeId') {
      setPageNumber(1);
      if (!e) {
        setSelectedStoreValue("all");
        setSelectedStore({})
      } else {
        setSelectedStore({
          value: e.value[0],
          label: e.userName,
          email: e.email,
          userName: e.userName
        })
        setSelectedStoreValue(e.storeId);
      }
    } else if (key === 'userId') {
      if (e) {
        setSelectedUserValue(e.userId);
        dispatch(GetStoreNames({
          userId: e.value
        }));
      } else {
        dispatch(GetStoreNames());
        setSelectedUserValue('All');
      }
    }

    setClicked(true)
  };

  const handleSearch = debounce((value) => {
    setClicked(true)
    setSearchKeyword(value);
    setPageNumber(1);
  }, 500);

  const handleCaseIdClick = (e, baseUrl, caseId) => {
    e.preventDefault();
    const caseUrl = `${baseUrl}cu/case-dashboard/view-case?ref=sc_cd_lobby_vc_v3&ie=UTF&caseID=${caseId}&View+or+Respond=Submit`;
    window.open(caseUrl, '_blank');
  }

  const handleLimitChange = (e) => {
    setClicked(true)
    setPageNumber(1);
    setPageLimit(e.value);
  }

  const handleDraftChange = (e) => {
    setClicked(true)
    setDraft(e.target.checked);
    if (!e.target.checked) {
      setSelectedCaseStatus({ label: 'Resolved', value: 'Resolved' })
    } else setSelectedCaseStatus('');
    setPageNumber(1);
  }

  const handlePageNumberChange = (e) => {
    const totalPages = Math.ceil(totalCases / pageLimit)
    if (e.key == "Enter") {
      setClicked(true)
      if (e.target.value < 1 || !e.target.value) {
        setPageNumber(1);
      } else if (e.target.value > totalPages) {
        setPageNumber(totalPages)
      } else setPageNumber(e.target.value)
    }
  }

  const handlePageChangeLeft = () => {
    setClicked(true)
    if (pageNumber - 1 > 0) setPageNumber(pageNumber - 1);
    else setPageNumber(1);
  }

  const handlePageChangeRight = () => {
    setClicked(true)
    const totalPages = Math.ceil(totalCases / pageLimit)
    if (Number(pageNumber) + 1 < totalPages) setPageNumber(Number(pageNumber) + 1);
    else setPageNumber(totalPages > 0 ? totalPages : 1);
  }

  const handleCaseStatusChange = (e) => {
    setClicked(true)
    setPageNumber(1);
    setSelectedCaseStatus(e);
    setDraft(false);
  }

  const handleCaseTypeChange = (e) => {
    setClicked(true)
    setPageNumber(1);
    if (!e) {
      setSelectedCaseType({});
    } else setSelectedCaseType(e);
    setDraft(false);
  }

  const onApply = () => {
    const { startDate, endDate } = date[0];

    const formatedStartDate = moment(startDate).format('L');
    const formatedEndDate = moment(endDate).format('L');

    const checkStart = moment(formatedStartDate);
    const startDay = checkStart.format('D');
    const startMonth = checkStart.format('M');
    const startYear = checkStart.format('YYYY');

    setStartDate({ startDay, startMonth, startYear });

    const checkEnd = moment(formatedEndDate);
    const endDay = checkEnd.format('D');
    const endMonth = checkEnd.format('M');
    const endYear = checkEnd.format('YYYY');

    setEndDate({ endDay, endMonth, endYear });
  }

  const handleDateChange = (item) => {
    setClicked(true)
    setDate([item.selection]);
    setPageNumber(1);
    console.log({
      item: item.selection
    });
  }

  return (
    <React.Fragment>
      <StatsWrapper>
        <div className='heading-wrapper'>
          <h2 className='mb-0'>Your Cases</h2>
          <p className='mb-0'>All details about your cases.</p>
        </div>
        <div className="d-flex justify-content-between mb-3 align-items-center postion-realtive flex-wrap">
          <SearchInput placeholder="Search" onChange={(e) => handleSearch(e.target.value)} />
          {user && (user.role == "admin" || user.role == "agency-manager" || user.role == "affiliate-manager") ? (<Checkbox
            label="Draft"
            value="Draft"
            onChange={(e) => handleDraftChange(e)}
            checked={draft}
          />) : ''}
          <div className="d-flex justify-content-end grid-column-gap-10 calendar-wrapper">
            <DatePickerRange
              date={date}
              startDate={startDate}
              endDate={endDate}
              onApply={onApply}
              onChange={handleDateChange}
            />
            {(user.role === 'admin' || user.role === 'agency-manager') ?
              <>
                <Select
                  styles={{
                    option: (base, state) => ({
                      ...base,
                      paddingLeft: "11px",
                      paddingRight: "11px",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                      borderBottom: `1px solid #E3E2E2`,
                      height: "100%",
                      backgroundColor: 'transparnet',
                      color: !isSelected ? '#4E5969' : "#064AAC"
                    }),
                    menuList: (provided, state) => ({
                      ...provided,
                      padding: 10,
                    }),
                  }}
                  className="mb-0 w-232 mr-3"
                  options={userNamesData}
                  isClearable={true}
                  handleChange={(e) => handleChange(e, 'userId')}
                  components={{ Option: Tile }}
                  placeholder='Select User'
                />
              </>
              : null}
            <Select
              handleChange={(e) => handleChange(e, 'storeId')}
              options={storeDropdownData}
              className="dashbaord-select case-state-dropdown"
              isClearable={true}
              components={{ Option: Tile }}
              placeholder='Select Store'
            />
            <Select
              className="dashbaord-select case-state-dropdown mb-0"
              name='selectedCaseType'
              placeholder='Select Case type'
              options={typeDropdown}
              isClearable={true}
              handleChange={(e) => handleCaseTypeChange(e)}
            />
            <Select
              className="dashbaord-select case-state-dropdown mb-0"
              name='selectedCaseStatus'
              value={selectedCaseStatus}
              placeholder='Select the case status'
              options={dropDown}
              handleChange={(e) => handleCaseStatusChange(e)}
            />
          </div>
        </div>
        <div className="pt-3">
          <Table
            viewHeight="stats-table"
            tableHeader={
              <tr>
                <th>Store</th>
                <th>Product Description</th>
                <th>Type</th>
                <th>Status</th>
                <th>File Date</th>
                {
                  draft || selectedCaseStatus.value === 'Partially Resolved' || selectedCaseStatus.value === 'Resolved' ?
                    <>
                      <th>Close Date</th>
                    </>
                    : null
                }
                <th> Case Id </th>
                {
                  selectedCaseStatus.value === 'Pending' && (selectedCaseType.value === 'Lost' || !selectedCaseType.value || selectedCaseType.value === null || selectedCaseType.value === 'Removal_Order') ? <th width="200px"> Reimbursement Id</th> : ''
                }
                {
                  (selectedCaseStatus.value === 'Partially Resolved' || selectedCaseStatus.value === 'Resolved') ?
                    <>
                      <th>
                        <h6 className="mb-0 fs-12 ">Units</h6>
                        <span className="fs-10">Reimbursed</span>
                      </th>
                    </>
                    :
                    (selectedCaseType.value === 'Fulfillment_Fee' && selectedCaseStatus.value === 'Pending') ?
                      <>
                        <th>
                          <h6 className="mb-0 fs-12 ">Units</h6>
                          <span className="fs-10">Affected</span>
                        </th>
                      </>
                      :
                      <>
                        <th>
                          <h6 className="mb-0 fs-12 ">Units</h6>
                          <span className="fs-10">Claimed</span>
                        </th>
                      </>
                }
                {selectedCaseStatus.value !== 'Pending' &&
                  <>
                    <th>
                      <h6 className="mb-0 fs-12">Amount</h6>
                      <span className="fs-10">Reimbursed</span>
                    </th>
                  </>
                }
                {(['admin', 'agency-manager'].includes(user.role)) &&
                  <th>
                    <h6 className="mb-0 fs-12">Amount</h6>
                    <span className="fs-10">Estimated</span>
                  </th>
                }
              </tr>
            }
            tableBody={
              <>
                {loading && !casesSuccess ? <tr><td><Spin /></td></tr> : (casesSuccess && cases && cases.length) ? cases.map((item, index) => {
                  const region = MARKETPLACE_TO_REGION[item?.marketplaceId] || 'na';
                  const baseURl = DOMAINS[region];
                  return (
                    <tr key={index} className="table-row-wrapper">
                      <td>
                        <>
                          <span className="d-block mb-0">{item.storeName}</span>
                          <span className="weight-bold small-fontSize">
                            {item.userEmail}
                          </span>
                        </>
                      </td>
                      <td>
                        <>
                          <div className="d-flex align-items-center td-tile-wrapper">
                            <img width="40" height="40" src={item.imageUrl || Product} alt="no-product" />
                            <div className="table-content-wrapper ml-2">
                              <h6 className="fs-12  text-primary">
                                <OverlayTrigger overlay={<Tooltip>{item.title}</Tooltip>}>
                                  <span className="d-inline-block">
                                    {item.title ? item.title?.length > 35 ? `${item.title.substring(0, 35)}.....` : item.title : 'N/A'}
                                  </span>
                                </OverlayTrigger>
                              </h6>
                              <div className="d-flex grid-column-gap-12">
                                {selectedCaseType === 'Fba_Shipment' || item.type === 'Fba_Shipment' ?
                                  <span className="fs-10">
                                    ShipmentID:
                                    <span className="highlighted-text">
                                      {item.shipmentId || 'N/A'}
                                    </span>
                                  </span> :
                                  selectedCaseStatus === 'Refund' || item.type === 'Refund' ?
                                    <>
                                      <span className="fs-10">
                                        ASIN:
                                        <span className="highlighted-text">
                                          {item.asin || 'N/A'}
                                        </span>
                                      </span>
                                      <span className="outlined-color fs-10">
                                        Amazon Order ID:
                                        <OverlayTrigger overlay={<Tooltip>{item.amazonOrderId}</Tooltip>}>
                                          <span className="text-primary weight-bold d-inline-block">
                                            {item.amazonOrderId}
                                          </span>
                                        </OverlayTrigger>
                                      </span>
                                    </> :
                                    item.type === 'Removal_Order' ? <>
                                      <span className="fs-10">
                                        RemovalOrderId:
                                        <span className="highlighted-text">
                                          {item.removalOrderId || 'N/A'}
                                        </span>
                                      </span>
                                    </> : <>
                                      <span className="fs-10">
                                        ASIN:
                                        <span className="highlighted-text">
                                          {item.asin || 'N/A'}
                                        </span>
                                      </span>
                                      <span className="outlined-color fs-10">
                                        SKU:
                                        <OverlayTrigger overlay={<Tooltip>{item.sellerSku}</Tooltip>}>
                                          <span className="text-primary weight-bold d-inline-block">
                                            {item.sellerSku?.length > 18 ? `${item.sellerSku.substring(0, 18)}...` : item.sellerSku}
                                          </span>
                                        </OverlayTrigger>
                                      </span>
                                    </>

                                }

                              </div>
                            </div>
                          </div>
                        </>
                      </td>
                      <td>
                        <>
                          <div className="table-type-data d-flex align-items-center">
                            <div className={item.type}></div>
                            <p className="mb-0">{item.type}</p>
                          </div>
                        </>
                      </td>
                      <td><><span className={`table-badge ${item.status}`}> {item.status}</span></></td>
                      <td><>{moment(item.dateOfCaseSubmitted).format('ll')}</></td>
                      {
                        draft || selectedCaseStatus.value === 'Partially Resolved' || selectedCaseStatus.value === 'Resolved' ?
                          <td><>{item.closeDate ? moment(item.closeDate).format('ll') : 'N/A'}</></td>
                          : null
                      }
                      <td>
                        <>
                          {
                            item?.caseId ? (
                              <a
                                href={`${baseURl}cu/case-dashboard/view-case?ref=sc_cd_lobby_vc_v3&ie=UTF&caseID=${item.caseId}&View+or+Respond=Submit`}
                                style={{ textDecoration: 'none', cursor: "pointer" }}
                                onClick={(e) => handleCaseIdClick(e, baseURl, item.caseId)}
                              >
                                {item.caseId}
                              </a>
                            ) : 'N/A'
                          }
                        </>
                      </td>
                      {
                        selectedCaseStatus.value === 'Pending' && (selectedCaseType.value === 'Lost' || !selectedCaseType.value || selectedCaseType.value === null || selectedCaseType.value === 'Removal_Order') && (
                          <td>
                            {(item?.reimbursementIds && item.reimbursementIds.length > 0) ?
                              <a
                                href={`${baseURl}reportcentral/REIMBURSEMENTS/0/%7B"filters":["","","${item?.reimbursementIds[0]}",""],"searchDays":365%7D`}
                                style={{ textDecoration: 'none', cursor: "pointer" }}
                                target='_blank'
                              >
                                {item?.reimbursementIds?.join(',')}
                              </a>
                              : 'N/A'}
                          </td>
                        )
                      }
                      <td><>{item.status === 'Pending' ? item.reimbursableUnits : item.unitsReimbursed}</></td>
                      {selectedCaseStatus.value !== 'Pending' &&
                        <>
                          <td>{formatReimbursedAmountWithCurrencySymbol(item.amountReimbursed)}</td>
                        </>
                      }
                      {['admin', 'agency-manager'].includes(user.role) &&
                        <td>{formatReimbursedAmountWithCurrencySymbol(item.estimatedAmount)}</td>
                      }
                    </tr>
                  )
                }) :
                  ((!loading && casesSuccess) || (success && !storeNamesList.length)) &&
                  (<tr><td>
                    <div className='error'>
                      <h1>No Data Available</h1>
                    </div>
                  </td></tr>)
                }
              </>
            }
          />
          {/* <Total totalUnits={1000} totalAmount={"$10000"} /> */}
          <Pagination
            className="cases-pagination-wrapper"
            pageLimit={pageLimit}
            total={totalCases}
            pageNumber={pageNumber}
            totalPages={Math.ceil(totalCases / pageLimit)}
            handlePageChangeLeft={handlePageChangeLeft}
            handlePageChangeRight={handlePageChangeRight}
            handleLimitChange={handleLimitChange}
            handlePageNumberChange={handlePageNumberChange} />
        </div>
      </StatsWrapper>
    </React.Fragment>
  );
};

export default Index;
