import React, { useState, useEffect } from "react";
import { debounce, isEmpty } from "lodash";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { BsThreeDotsVertical } from "react-icons/bs";
import { components } from "react-select";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { BsFillCloudUploadFill, BsFillInfoCircleFill, BsBriefcaseFill } from "react-icons/bs";

import CheckBox from '../../components/CheckBox';
import Drawer from "../../components/Drawer";
import Input from '../../components/Inputs/label-input';
import Modal from '../../components/Modal/modal';
import Pagination from "../../components/Pagination/pagination";
import Select from "../../components/Select";
import SearchInput from "../../components/SearchInput";
import Spin from "../../components/Spin/Index";
import Table from "../../components/Table/table";
import Toast from '../../components/Toast';
import ShipmentItems from './shipment-items-drawer';

import { ShipmentWrapper } from "./style";

import { APP_DOMAINS, DOMAINS, MARKETPLACE_TO_REGION } from '../../constants';

import store from '../../redux/store';
import {
  GetShipments,
  SetShipmentState,
  ClaimShipmentCase,
  getShipment,
  UploadInvoiceToS3,
  SaveUrls
} from "../../redux/slices/shipment-slice";
import { GetStoreNames } from "../../redux/slices/store-slice";

const { Option } = components;
const Tile = (props) => {
  return (
    <Option {...props} value={"default"}>
      <div className="select-option">
        <span className="d-block bold-weight">{props.data.userName !== 'All' ? props.data.userName : null}</span>
        <span>{props.data.name}</span>
      </div>
    </Option>
  );
}

const shipmentStatusDropDown = [{
  value: 'To_Be_Claimed',
  label: 'Claimable'
}, {
  value: 'Claimed',
  label: 'Claimed'
}, {
  value: 'Already_Reimbursed',
  label: 'Already Reimbursed'
}, {
  value: 'Rejected',
  label: 'Rejected'
}, {
  value: 'Other',
  label: 'Other'
}];

const Index = () => {
  const hostName = window.location.hostname;
  const dispatch = useDispatch();
  const history = useHistory();

  const [shipmentBody, setShipmentBody] = useState([]);
  const [isSelected, setIsSelected] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');

  const [storeNames, setStoreNames] = useState([]);
  const [shipmentItemHeader, setShipmentItemHeader] = useState([]);
  const [shipmentId, setShipmentId] = useState();
  const [details, setDetails] = useState(false);

  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [severity, setSeverity] = useState('');

  const [pageNumber, setPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState(25);
  const [pageOption, setPageOption] = useState({
    value: 25,
    lable: 25
  });

  const [filters, setFilters] = useState({
    status: 'To_Be_Claimed',
    storeId: '',
    searchKeyword: ''
  });

  const [showModal, setShowModal] = useState(false);
  const [caseId, setCaseId] = useState('');
  const [caseStatus, setCaseStatus] = useState('');
  const [shipmentCaseError, setShipmentCaseError] = useState('');
  const [shipment, setShipment] = useState({});

  const {
    shipments,
    total,
    message,
    error,
    success,
    loading,
    invoiceUploaded,
    shipmentUrls
  } = useSelector((state) => state.shipment);

  const { usersName } = useSelector((state) => state.user);
  const { user, storeStatus, storesCount, payLater } = useSelector((state) => state.auth);
  const { storeNamesList, store: userStore } = useSelector((state) => state.store);

  useEffect(() => {
    if (!isEmpty(filters)) {
      getShipments();
    }
  }, [filters, pageNumber, pageLimit, invoiceUploaded]);

  const handleFileChange = (e) => {
    e.preventDefault()
    const fileName = e.target.files[0].name;
    const invoiceToUpload = getShipment(store.getState());

    dispatch(UploadInvoiceToS3({ files: [...e.target.files], shipment: invoiceToUpload }));
  }

  const handleModal = (shipment) => {
    setShipment(shipment);
    setShowModal(true);
  };

  const handleDocumentIdClick = (shipment) => {
    dispatch(SetShipmentState({ field: 'invoiceToUpload', value: shipment }));
    document.getElementById("upload-file").click();
  };

  useEffect(() => {
    if (success && shipments.length > 0) {
      const shipmentsData = shipments.map((shipment, i) => {
        return (
          <tr key={i}>
            {(user.role === 'agency-manager' || user.role === 'admin') && (
              <td>
                <>
                  <span className="d-block mb-0">{shipment.storeName || 'StoreName'}</span>
                  <span className="weight-bold small-fontSize">
                    {shipment.userEmail || 'dummy@email.com'}
                  </span>
                </>
              </td>
            )}
            <td>
              <>
                <span className="d-block mb-0">{shipment.shipmentId}</span>
                <span className="weight-bold small-fontSize">
                  {moment(shipment.shipmentCreatedAtDate).format('ll') || 'N/A'}
                </span>
              </>
            </td>
            <td><>{shipment.shipmentName}</></td>
            <td><>{shipment.quantityShipped}</></td>
            <td><>{shipment.quantityReceived}</></td>
            <td><>{shipment.discrepency}</></td>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around'
              }}
            >
            {shipment.status == 'To_Be_Claimed' && !shipment?.invoiceS3Urls ?
              <div className="text-center" style={{ marginTop: '25%' }}>
                <input
                  type="file"
                  id="upload-file"
                  style={{ display: 'none' }}
                  onChange={(e) => handleFileChange(e)}
                  multiple
                />
                <OverlayTrigger
                  placement="left"
                  delay={{ show: 250, hide: 400 }}
                  transition={false}
                  overlay={<Tooltip>{
                    <div
                      style={{
                        backgroundColor: 'rgba(255, 100, 100, 0.85)',
                        padding: '2px 10px',
                        color: 'white',
                        borderRadius: 3,
                      }}
                    >
                      Please, upload the corresponding commercial invoice for your claim to be processed
                    </div>
                  }</Tooltip>}
                >
                  <BsFillCloudUploadFill size='1.5em' className="icon-color cursor-pointer" onClick={() => handleDocumentIdClick(shipment) } />
                </OverlayTrigger>
              </div>
              :
              shipment.status == 'To_Be_Claimed' && shipment?.invoiceS3Urls ?
              <>
                <div className="text-center" style={{ marginTop: '25%' }}>
                  <OverlayTrigger
                    placement="left"
                    delay={{ show: 250, hide: 400 }}
                    transition={false}
                    overlay={<Tooltip>{
                      <div
                        style={{
                          backgroundColor: '#299F63',
                          padding: '2px 10px',
                          color: 'white',
                          borderRadius: 3,
                        }}
                      >
                        We have received the corresponding document and we will file the case for this shipment.
                </div>
                    }</Tooltip>}
                  >
                    <BsFillInfoCircleFill size='1.5em' className="icon-color cursor-pointer" />
                  </OverlayTrigger>
                </div>
                <div className="text-center" style={{ marginTop: '25%' }}>
                <input
                  type="file"
                  id="upload-file"
                  style={{ display: 'none' }}
                  onChange={(e) => handleFileChange(e)}
                  multiple
                />
                <OverlayTrigger
                  placement="left"
                  delay={{ show: 250, hide: 400 }}
                  transition={false}
                  overlay={<Tooltip>{
                    <div
                      style={{
                        backgroundColor: 'rgba(255, 100, 100, 0.85)',
                        padding: '2px 10px',
                        color: 'white',
                        borderRadius: 3,
                      }}
                    >
                      Want to Upload again shipment invoice, Case will be filed
                    </div>
                  }</Tooltip>}
                >
                  <BsFillCloudUploadFill size='1.5em' className="icon-color cursor-pointer" onClick={() => handleDocumentIdClick(shipment)} />
                </OverlayTrigger>
              </div></>
                 : null
            }
            {
              shipment.status == 'To_Be_Claimed' && (user.role == 'admin' || user.role == 'agency-manager') ?
              <div className="text-center" style={{ marginTop: '25%' }}>
                  <OverlayTrigger
                    placement="left"
                    delay={{ show: 250, hide: 400 }}
                    transition={false}
                    overlay={<Tooltip>{
                      <div
                        style={{
                          backgroundColor: '#299F63',
                          padding: '2px 10px',
                          color: 'white',
                          borderRadius: 3,
                        }}
                      >
                        Submit Case ID
                </div>
                    }</Tooltip>}
                  >
                    <BsBriefcaseFill size='1.5em' className="icon-color cursor-pointer" onClick={() => handleModal(shipment)} />
                  </OverlayTrigger>
                </div> : <></>
            }
            </div>
            <td>
              <>
                <BsThreeDotsVertical
                  className="icon-color cursor-pointer"
                  onClick={() => handleSideBar(shipment)}
                />
              </>
            </td>
          </tr>
        );
      });
      setShipmentBody(shipmentsData);
    } else setShipmentBody([]);
  }, [shipments]);

  useEffect(() => {
    if (error) {
      setToastMessage(error);
      setSeverity('error');
      setToastOpen(true);
      dispatch(SetShipmentState({ field: 'error', value: '' }));
      return;
    } else if (message) {
      setToastMessage(message);
      setSeverity('info');
      setToastOpen(true);
      dispatch(SetShipmentState({ field: 'message', value: '' }));
      return;
    }
  }, [error, message]);

  useEffect(() => {
    if (shipmentUrls.length) {
      dispatch(SaveUrls({ shipment: getShipment(store.getState()), shipmentUrls }));
    }
  }, [shipmentUrls])

  useEffect(() => {
    if (
      payLater || userStore?.payLater ||
      ["admin", "agency-manager", "affiliate-manager"].includes(user.role)
    ) {
      dispatch(GetStoreNames());
    }
  }, [dispatch]);

  useEffect(() => {
    if (storeNamesList && storeNamesList.length > 0) {
      const storeNamesData = storeNamesList?.map(store => ({
        value: store._id,
        label: `${store.name}`,
        userName: store.userName,
        name: store.name
      }));

      const stores = [];

      stores.push(...storeNamesData);
      setStoreNames(stores);
    } else {
      setStoreNames([]);
    }
  }, [storeNamesList]);

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToastOpen(false);
  };

  const handleSideBar = (doc) => {
    setShipmentItemHeader(doc);
    setDetails(true);
    const shipmentId = doc.shipmentId;
    setShipmentId(shipmentId);
  }

  const redirectToTool = () => {
    const region = MARKETPLACE_TO_REGION[shipment?.marketplaceId] || 'na';
    const baseUrl = DOMAINS[region];

    if (shipment.shipmentId) {
      window.open(`${baseUrl}/fba/inbound-shipment/summary/${shipment.shipmentId}/contents`, '_blank').focus();
    } else {
      window.open(`${baseUrl}/help/hub/reference/201214140?ref=efph_login_wf_201214140#mnd_2jc_jcb`, '_blank').focus();
    }
  };

  const getShipments = () => {
    const skip = (pageNumber - 1) * pageLimit;
    const limit = pageLimit;
    dispatch(GetShipments({ filters, skip, limit }));
  };

  const handleSearch = debounce((value, key) => {
    setSearchKeyword(value);
    setFilters({
      ...filters,
      [key]: value
    });
    setPageNumber(1);
  }, 500);

  const handlePageLimit = (e) => {
    setPageLimit(e.value);
    setPageOption(e);
  };

  const handlePageNumberChange = (e) => {
    const totalPages = Math.ceil(total / pageLimit)
    if (e.key == "Enter") {
      if (e.target.value < 1 || !e.target.value) {
        setPageNumber(1);
      } else if (e.target.value > totalPages) {
        setPageNumber(totalPages);
      } else {
        setPageNumber(Number(e.target.value));
      }
    }
  }

  const handlePageChangeLeft = () => {
    if (pageNumber - 1 > 0) {
      setPageNumber(pageNumber - 1);
    }
    else {
      setPageNumber(1);
    }
  }

  const handlePageChangeRight = () => {
    const totalPages = Math.ceil(total / pageLimit)
    if (pageNumber + 1 < totalPages) {
      setPageNumber(pageNumber + 1);
    }
    else {
      setPageNumber(totalPages > 0 ? totalPages : 1);
    }
  }

  const handleChange = (e, key) => {
    setPageNumber(1);
    setFilters({
      ...filters,
      [key]: e?.value || 'All'
    })
  };

  const handleShipmentCase = (e) => {
    if (caseId !== '' || caseStatus !== '') {
      const { shipmentId, storeId } = shipment;
      dispatch(ClaimShipmentCase({ storeId, shipmentId, status: caseStatus, caseId }));
      setShowModal(false);
      setCaseId('');
      setCaseStatus('');
      getShipments();
    } else {
      setShipmentCaseError('Enter the caseId or select any of option for claiming the shipment');
    }
  };

  const handleCheckBoxClick = (e) => {
    if (e.target.checked) setCaseStatus(e.target.value);
    else setCaseStatus('');
  };

  const handleModalClose = () => {
    setShowModal(false);
    setCaseStatus('');
    setCaseId('');
  };

  return (

    <ShipmentWrapper>
      <Toast
        message={toastMessage}
        severity={severity}
        toastOpen={toastOpen}
        handleToastClose={handleToastClose}
      />
      <div className="d-flex justify-content-between align-items-center flex-sm-column flex-md-row">
        <h3 className="mb-0">Shipments</h3>
        <div className="d-flex flex-wrap grid-column-gap-16 align-items-center">
          <Select
            options={shipmentStatusDropDown}
            className="dashbaord-select"
            handleChange={(e) => handleChange(e, 'status')}
            placeholder='Select Status'
            defaultValue={shipmentStatusDropDown?.[0] || ''}
          />
          {user.role === 'admin' || user.role === 'agency-manager' || user.role === 'affiliate-manager' ?
            <Select
              styles={{
                option: (base, state) => ({
                  ...base,
                  paddingLeft: "11px",
                  paddingRight: "11px",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                  borderBottom: `1px solid #E3E2E2`,
                  height: "100%",
                  backgroundColor: 'transparnet',
                  color: !isSelected ? '#4E5969' : "#064AAC"
                }),
                menuList: (provided, state) => ({
                  ...provided,
                  padding: 10,
                }),
              }}
              className="dashbaord-select"
              options={storeNames}
              isClearable={true}
              handleChange={(e) => handleChange(e, 'storeId')}
              components={{ Option: Tile }}
              placeholder='Select Store'
            />
            : null}
          <SearchInput placeholder="Search By shipment/Name" name='search' onChange={(e) => handleSearch(e.target.value, 'searchKeyword')} />
        </div>
      </div>
      <div className="pt-4">
        <Table
          viewHeight="invoices"
          tableHeader={
            <tr>
              {(user.role === 'agency-manager' || user.role === 'admin') && (
                <th> Store </th>
              )}
              <th>Shipment</th>
              <th>Name</th>
              <th>Quantity Shipped</th>
              <th>Quantity Received</th>
              <th>Discrepency</th>
              {filters.status == 'To_Be_Claimed' ? <th>Action</th> : null}

              <th className="text-right"></th>
            </tr>
          }
          tableBody={
            <>
              {!loading && !shipments.length ? (
                <tr><td>
                  <div className='error'>
                    <h1>No Data Available</h1>
                  </div>
                </td></tr>
              ) : null}
              {loading ? <tr><td><Spin /></td></tr> : shipmentBody}
            </>
          }
        />
        {details && (
          <Drawer show={details} size="75%">
            <ShipmentItems
              shipmentItemHeader={shipmentItemHeader}
              shipmentIdd={shipmentId}
              handleClick={() => setDetails(false)}
            />
          </Drawer>
        )}

        {showModal ? <Modal
          showModal={showModal}
          modalHeader="Claim Shipment"
          modalBody={<div>
            <p>
              1. If your shipment is eligible
              <a
                style={{ textDecoration: 'none', cursor: "pointer", color: hostName === APP_DOMAINS.sellerTerminal ? '#008989' : 'blue' }}
                onClick={() => redirectToTool()}
              >
                &nbsp;
                Click Here &nbsp;

              </a>
              to See The Shipment Summary. <br></br>
              2. Upload Documents. <br></br>
              3. Provide Additional Info if you want any. <br></br>
              4. Review Your Request. <br></br>
              5. Copy & Paste Case Id. <br></br>
            </p>
            <Input
              type="number"
              style={{ maxWidth: '160px' }}
              placeholder="Paste Case Id"
              value={caseId}
              onChange={(e) => { setCaseId(e.target.value); setCaseStatus('Claimed') }}
              disabled={(caseStatus !== '' && caseStatus !== 'Claimed') ? true : false}
            />

            <CheckBox
              type="checkbox"
              name="Already_Reimbursed"
              value='Already_Reimbursed'
              label={
                <>
                  Mark Case As Already Reimbursed.
                </>
              }
              onChange={(e) => handleCheckBoxClick(e)}
              disabled={caseId !== '' || (caseStatus !== '' && caseStatus === 'Rejected') ? true : false}
              defaultChecked={false}
            />

            <CheckBox
              type="checkbox"
              name="Rejected"
              value='Rejected'
              label={
                <>
                  Mark Case As Rejected
                </>
              }
              onChange={(e) => handleCheckBoxClick(e)}
              disabled={caseId !== '' || (caseStatus !== '' && caseStatus === 'Already_Reimbursed') ? true : false}
              defaultChecked={false}
            />

            <p style={{ color: 'red', font: '11px' }}>{shipmentCaseError} </p>
          </div>
          }
          handleConfirm={() => handleShipmentCase()}
          handleClose={() => handleModalClose()}
          showFooter={true}
        />
          : null
        }

        <Pagination
          className="shipment-pagination-wrapper"
          pageLimit={pageLimit}
          total={total}
          pageNumber={pageNumber}
          totalPages={Math.ceil(total / pageLimit)}
          handlePageChangeLeft={handlePageChangeLeft}
          handlePageChangeRight={handlePageChangeRight}
          handleLimitChange={handlePageLimit}
          handlePageNumberChange={handlePageNumberChange}
        />
      </div>
    </ShipmentWrapper>
  );
};
export default Index;
