import Styled from 'styled-components';

const PaginationWrapper = Styled.div`
 background-color:${({ theme }) => theme['table-header']};
 padding:9px 16px;
 font-size:${({ theme }) => theme['medium-font-size ']};
 border-top-left-radius: ${({ theme }) => theme['border-radius-base']};
 border-top-right-radius: ${({ theme }) => theme['border-radius-base']};
      .item-per-page{
        position: relative;
              &::after{
                content:"";
                position: absolute;
                right: 0;
                height: 20px;
                width:1px;
                top:8px;
                background: #C4C4C4;
            }
      }
      .total-pages{
              position: relative;            }
            .arrows{
              position: relative;
              svg:nth-child(1){
                height: 21px;
                 border-right: 1px solid #C4C4C4;
              }
            }
            .left-controle{
              color:#4438F9;
              font-size: 18px;
            }
`;

export  {PaginationWrapper};
