import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

import { APP_DOMAINS, TAWK_DATA } from '../../constants';

const ChatBot = ({ route }) => {
  const hostname = window.location.hostname;
  const { user, token } = useSelector((state) => state.auth);
  
  useEffect(() => {
    window.Tawk_API = window.Tawk_API || {};
    window.Tawk_API.customStyle = {
      visibility: {
        desktop: {
          position: 'br',
          xOffset: '15px',
          yOffset: '45px'
        },
        mobile: {
          position: 'br',
          xOffset: 0,
          yOffset: 0
        },
        bubble: {
          rotate: '0deg',
          xOffset: -20,
          yOffset: 0
        }
      }
    };

    if (route === 'private' && token) {
      const visitorName = user?.name || '';
      const visitorEmail = user?.email || '';

      window.Tawk_API = window.Tawk_API || {};
      window.Tawk_API.visitor = { name: visitorName, email: visitorEmail };
      if (Object.keys(window.Tawk_API)?.length > 2) {
        window.Tawk_API.minimize();
        window.Tawk_API.hideWidget();
        window.Tawk_API.switchWidget({
          propertyId: hostname === APP_DOMAINS.sellerRepay ? TAWK_DATA.SELLER_REPAY_PRIVATE.property : TAWK_DATA.SELLER_TERMINAL_PRIVATE.property,
          widgetId: hostname === APP_DOMAINS.sellerRepay ? TAWK_DATA.SELLER_REPAY_PRIVATE.widget : TAWK_DATA.SELLER_TERMINAL_PRIVATE.widget
        }, function (error) {
          if (error) {
            console.log('Error switching the widget');
          }
        });
      }

      if (window.Tawk_API?.onLoaded) {
        window.Tawk_API.showWidget();
      }
    } else if (route === 'public') {
      if (Object.keys(window.Tawk_API)?.length >1) {
        delete window.Tawk_API.visitor;
        window.Tawk_API.minimize();
        window.Tawk_API.hideWidget();
        window.Tawk_API.switchWidget({
          propertyId: hostname === APP_DOMAINS.sellerRepay ? TAWK_DATA.SELLER_REPAY_PUBLIC.property : TAWK_DATA.SELLER_TERMINAL_PUBLIC.property,
          widgetId: hostname === APP_DOMAINS.sellerRepay ? TAWK_DATA.SELLER_REPAY_PUBLIC.widget : TAWK_DATA.SELLER_TERMINAL_PUBLIC.widget
        }, function (error) {
          if (error) {
            console.log('Error switching the widget');
          };
        });
      }
    }

  }, []);

  return (
    <div>
      <TawkMessengerReact
        propertyId={
          hostname === APP_DOMAINS.sellerRepay ?
            (route === 'public' ?
              (TAWK_DATA.SELLER_REPAY_PUBLIC.property) :
              (TAWK_DATA.SELLER_REPAY_PRIVATE.property)
            ) :
            (route === 'public' ?
              (TAWK_DATA.SELLER_TERMINAL_PUBLIC.property) :
              (TAWK_DATA.SELLER_TERMINAL_PRIVATE.property)
            )
        }
        widgetId={
          hostname === APP_DOMAINS.sellerRepay ?
            (route === 'public' ?
              (TAWK_DATA.SELLER_REPAY_PUBLIC.widget) :
              (TAWK_DATA.SELLER_REPAY_PRIVATE.widget)
            ) :
            (route === 'public' ?
              (TAWK_DATA.SELLER_TERMINAL_PUBLIC.widget) :
              (TAWK_DATA.SELLER_TERMINAL_PRIVATE.widget)
            )
        }
      />
    </div>
  );
};

export default ChatBot;
