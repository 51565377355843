import SellerRepayNewIcon from '../assets/images/seller_repay_new_icon.png';
import sellerTerminalIcon from '../assets/icons/host/app.sellerterminal.png';
import localHostIcon from '../assets/icons/host/localhost.png';
import SellerRepayLogo from '../assets/img/seller-repay-logo.svg';
import SellerTerminalLogo from '../assets/img/logo/seller-terminal-white-logo.svg';
import SalesSupportLogo from '../assets/img/logo/reimbursements.sales.support.png';
import Mexico from '../assets/images/flag.svg';
import Brazil from '../assets/images/flag-1.svg';
import Canada from '../assets/images/flag-2.svg';
import US from '../assets/images/flag-3.svg';
import UK from '../assets/images/eu-1.svg';
import France from '../assets/images/eu-2.svg';
import Spain from '../assets/images/eu-3.svg';
import Belgium from '../assets/images/eu-4.svg'
import Netherlands from '../assets/images/eu-5.svg';
import Germany from '../assets/images/eu-6.svg';
import Italy from '../assets/images/eu-7.svg';
import Sweden from '../assets/images/eu-8.svg';
import Poland from '../assets/images/eu-9.svg';
import Turkey from '../assets/images/eu-10.svg';
import Saudia from '../assets/images/eu-11.svg';
import UAE from '../assets/images/eu-12.svg';
import India from '../assets/images/eu-13.svg';
import Egypt from '../assets/images/eu-14.svg';
import Singapore from '../assets/images/fe-2.svg';
import Japan from '../assets/images/fe-1.svg';
import Australia from '../assets/images/fe-3.svg';

const STRIPE_PUBLIC_KEY_LOCALHOST = process.env.STRIPE_PUBLIC_KEY_LOCALHOST;
const STRIPE_PUBLIC_KEY_SELLER_TERMINAL = process.env.STRIPE_PUBLIC_KEY_SELLER_TERMINAL;
const STRIPE_PUBLIC_KEY_SELLER_REPAY = process.env.STRIPE_PUBLIC_KEY_SELLER_REPAY;
const LOCAL_HOST_RECAPTCHA_SITE_KEY = process.env.LOCAL_HOST_RECAPTCHA_SITE_KEY;
const SELLER_REPAY_RECAPTCHA_SITE_KEY = process.env.SELLER_REPAY_RECAPTCHA_SITE_KEY;
const SELLER_TERMINAL_RECAPTCHA_SITE_KEY = process.env.SELLER_TERMINAL_RECAPTCHA_SITE_KEY;
const SALES_SUPPORT_RECAPTCHA_SITE_KEY = process.env.SALES_SUPPORT_RECAPTCHA_SITE_KEY;
const ECOMM_REFUND_RECAPTCHA_SITE_KEY = process.env.ECOMM_REFUND_RECAPTCHA_SITE_KEY;
const APP_ID_SELLER_TERMINAL = process.env.APP_ID_SELLER_TERMINAL;
const AMAZON_REDIRECT_URI_SELLER_TERMINAL = process.env.AMAZON_REDIRECT_URI_SELLER_TERMINAL;
const APP_ID_SELLER_REPAY = process.env.APP_ID_SELLER_REPAY;
const AMAZON_REDIRECT_URI_SELLER_REPAY = process.env.AMAZON_REDIRECT_URI_SELLER_REPAY;
const AMAZON_REDIRECT_URI_ECOM_REFUND = process.env.AMAZON_REDIRECT_URI_ECOM_REFUND;
const APP_ID_ECOM_REFUND = process.env.APP_ID_ECOM_REFUND;
const SELLER_TERMINAL_CALENDLY_LINK = process.env.SELLER_TERMINAL_CALENDLY_LINK;
const SELLER_REPAY_CALENDLY_LINK = process.env.SELLER_REPAY_CALENDLY_LINK;

export const CURRENCY_SYMBOLS = {
  USD: '$',
  CAD: 'C$',
  MXN: 'MX$',
  BRL: 'R$',
  GBP: '£',
  EUR: '€',
  EUR: '€',
  EUR: '€',
  EUR: '€',
  EUR: '€',
  SEK: 'SEK',
  PLN: 'zł',
  TRY: '₺',
  AED: 'د.إ',
  INR: '₹',
  SGD: 'S$',
  AUD: 'A$',
  JPY: '¥',
  CZK: 'Kč'
};

export const MARKETPLACE_TO_REGEION_MAPPING = {
  tr: 'eu',
  sa: 'eu',
  ae: 'eu',
  in: 'eu',
  eg: 'eu',
  jp: 'fe',
  au: 'fe',
  sg: 'fe',
  na: 'na',
  eu: 'eu'
};

export const COUNTRY_CODE_WRT_MARKETPLACE = {
  ATVPDKIKX0DER: 'US',
  A2EUQ1WTGCTBG2: 'CA',
  A1AM78C64UM0Y8: 'MX',
  A2Q3Y263D00KWC: 'BR'
};

export const CURRENCY_SYMBOLS_BY_MARKETPLACE_ID = {
  ATVPDKIKX0DER: '$',
  A2EUQ1WTGCTBG2: 'C$',
  A1AM78C64UM0Y8: 'MX$',
  A2Q3Y263D00KWC: 'R$',
  A1F83G8C2ARO7P: '£',
  A1PA6795EUMFR9: '€',
  A13V1IB3VIYZZH: '€',
  APJ6JRA9NG5V4: '€',
  A1RKKUPIHCS9HS: '€',
  A1PA6795UKMFR9: '€',
  A1805IZSGTT6HS: 'SEK',
  A2NODRKZP88ZB9: 'zł',
  A1C3SOZRARQ6R3: '₺',
  A33AVAJ2PDY3EV: 'د.إ',
  A2VIGQ35RCS4UG: '₹',
  A21TJRUUN4KGV: 'S$',
  A19VAU5U5O7RUS: 'A$',
  A39IBJ37TRP1C6: '¥',
  A1VC38T7YXB528: 'Kč'
};

export const MARKETPLACE_BY_CURRENCYCODE = {
  USD: 'ATVPDKIKX0DER',
  CAD: 'A2EUQ1WTGCTBG2',
  MXN: 'A1AM78C64UM0Y8',
  BRL: 'A2Q3Y263D00KWC',
  GBP: 'A1F83G8C2ARO7P',
  EUR: 'A1PA6795EUMFR9',
  EUR: 'A13V1IB3VIYZZH',
  EUR: 'APJ6JRA9NG5V4',
  EUR: 'A1RKKUPIHCS9HS',
  DE: 'A1PA6795UKMFR9',
  SEK: 'A1805IZSGTT6HS',
  PLN: 'A2NODRKZP88ZB9',
  TRY: 'A1C3SOZRARQ6R3',
  AED: 'A33AVAJ2PDY3EV',
  INR: 'A2VIGQ35RCS4UG',
  SGD: 'A21TJRUUN4KGV',
  AUD: 'A19VAU5U5O7RUS',
  JPY: 'A39IBJ37TRP1C6',
  CZK: 'A1VC38T7YXB528'
};

export const CURRENCYCODE_BY_MARKETPLACE = {
  ATVPDKIKX0DER: 'USD',
  A2EUQ1WTGCTBG2: 'CAD',
  A1AM78C64UM0Y8: 'MXN',
  A2Q3Y263D00KWC: 'BRL',
  A1F83G8C2ARO7P: 'GBP',
  A1PA6795EUMFR9: 'EUR',
  A13V1IB3VIYZZH: 'EUR',
  APJ6JRA9NG5V4: 'EUR',
  A1RKKUPIHCS9HS: 'EUR',
  A1PA6795UKMFR9: 'DE',
  A1805IZSGTT6HS: 'SEK',
  A2NODRKZP88ZB9: 'PLN',
  A1C3SOZRARQ6R3: 'TRY',
  A33AVAJ2PDY3EV: 'AED',
  A2VIGQ35RCS4UG: 'INR',
  A21TJRUUN4KGV: 'SGD',
  A19VAU5U5O7RUS: 'AUD',
  A39IBJ37TRP1C6: 'JPY',
  A1VC38T7YXB528: 'CZK',
};

export const MARKETPLACE_TO_REGION = {
  ATVPDKIKX0DER: 'na',
  A2EUQ1WTGCTBG2: 'na',
  A2Q3Y263D00KWC: 'na',
  A1AM78C64UM0Y8: 'na',
  A1RKKUPIHCS9HS: 'eu',
  A1F83G8C2ARO7P: 'eu',
  A13V1IB3VIYZZH: 'eu',
  A1805IZSGTT6HS: 'eu',
  A1PA6795UKMFR9: 'eu',
  APJ6JRA9NG5V4: 'eu',
  A2NODRKZP88ZB9: 'eu',
  A1C3SOZRARQ6R3: 'eu',
  A33AVAJ2PDY3EV: 'eu',
  A21TJRUUN4KGV: 'eu',
  A19VAU5U5O7RUS: 'fe',
  A39IBJ37TRP1C6: 'fe',
  A1VC38T7YXB528: 'fe',
  A2VIGQ35RCS4UG: 'fe'
};

export const REGION_TO_MARKETPLACE = {
  na: [
    {
      label: 'United States',
      value: 'ATVPDKIKX0DER'
    },
    {
      label: 'Canada',
      value: 'A2EUQ1WTGCTBG2'
    },
    {
      label: 'Mexico',
      value: 'A1AM78C64UM0Y8'
    },
    {
      label: 'Brazil',
      value: 'A2Q3Y263D00KWC'
    }
  ]
};

export const ALL_MARKETPLACES = [
  {
    label: 'United States',
    value: 'ATVPDKIKX0DER'
  },
  {
    label: 'Canada',
    value: 'A2EUQ1WTGCTBG2'
  },
  {
    label: 'Mexico',
    value: 'A1AM78C64UM0Y8'
  },
  {
    label: 'Brazil',
    value: 'A2Q3Y263D00KWC'
  },
  {
    label: 'United Kindom',
    value: 'A1F83G8C2ARO7P'
  },
  {
    label: 'Germany',
    value: 'A1PA6795UKMFR9'
  },
  {
    label: 'France',
    value: 'A13V1IB3VIYZZH'
  },
  {
    label: 'Italy',
    value: 'APJ6JRA9NG5V4'
  },
  {
    label: 'Spain',
    value: 'A1RKKUPIHCS9HS'
  },
  {
    label: 'Netherlands',
    value: 'A1805IZSGTT6HS'
  },
  {
    label: 'Sweden',
    value: 'A2NODRKZP88ZB9'
  },
  {
    label: 'Poland',
    value: 'A1C3SOZRARQ6R3'
  },
  {
    label: 'Turkey',
    value: 'A33AVAJ2PDY3EV'
  },
  {
    label: 'India',
    value: 'A21TJRUUN4KGV'
  }
];

export const DOMAINS = {
  na: 'https://sellercentral.amazon.com/',
  eu: 'https://sellercentral.amazon.co.uk/',
  fe: 'https://sellercentral.amazon.com/'
};

export const COUNTRIES = [
  { name: 'Afghanistan', code: 'AF' },
  { name: 'Åland Islands', code: 'AX' },
  { name: 'Albania', code: 'AL' },
  { name: 'Algeria', code: 'DZ' },
  { name: 'American Samoa', code: 'AS' },
  { name: 'AndorrA', code: 'AD' },
  { name: 'Angola', code: 'AO' },
  { name: 'Anguilla', code: 'AI' },
  { name: 'Antarctica', code: 'AQ' },
  { name: 'Antigua and Barbuda', code: 'AG' },
  { name: 'Argentina', code: 'AR' },
  { name: 'Armenia', code: 'AM' },
  { name: 'Aruba', code: 'AW' },
  { name: 'Australia', code: 'AU' },
  { name: 'Austria', code: 'AT' },
  { name: 'Azerbaijan', code: 'AZ' },
  { name: 'Bahamas', code: 'BS' },
  { name: 'Bahrain', code: 'BH' },
  { name: 'Bangladesh', code: 'BD' },
  { name: 'Barbados', code: 'BB' },
  { name: 'Belarus', code: 'BY' },
  { name: 'Belgium', code: 'BE' },
  { name: 'Belize', code: 'BZ' },
  { name: 'Benin', code: 'BJ' },
  { name: 'Bermuda', code: 'BM' },
  { name: 'Bhutan', code: 'BT' },
  { name: 'Bolivia', code: 'BO' },
  { name: 'Bosnia and Herzegovina', code: 'BA' },
  { name: 'Botswana', code: 'BW' },
  { name: 'Bouvet Island', code: 'BV' },
  { name: 'Brazil', code: 'BR' },
  { name: 'British Indian Ocean Territory', code: 'IO' },
  { name: 'Brunei Darussalam', code: 'BN' },
  { name: 'Bulgaria', code: 'BG' },
  { name: 'Burkina Faso', code: 'BF' },
  { name: 'Burundi', code: 'BI' },
  { name: 'Cambodia', code: 'KH' },
  { name: 'Cameroon', code: 'CM' },
  { name: 'Canada', code: 'CA' },
  { name: 'Cape Verde', code: 'CV' },
  { name: 'Cayman Islands', code: 'KY' },
  { name: 'Central African Republic', code: 'CF' },
  { name: 'Chad', code: 'TD' },
  { name: 'Chile', code: 'CL' },
  { name: 'China', code: 'CN' },
  { name: 'Christmas Island', code: 'CX' },
  { name: 'Cocos (Keeling) Islands', code: 'CC' },
  { name: 'Colombia', code: 'CO' },
  { name: 'Comoros', code: 'KM' },
  { name: 'Congo', code: 'CG' },
  { name: 'Congo, The Democratic Republic of the', code: 'CD' },
  { name: 'Cook Islands', code: 'CK' },
  { name: 'Costa Rica', code: 'CR' },
  { name: 'Cote D\'Ivoire', code: 'CI' },
  { name: 'Croatia', code: 'HR' },
  { name: 'Cuba', code: 'CU' },
  { name: 'Cyprus', code: 'CY' },
  { name: 'Czech Republic', code: 'CZ' },
  { name: 'Denmark', code: 'DK' },
  { name: 'Djibouti', code: 'DJ' },
  { name: 'Dominica', code: 'DM' },
  { name: 'Dominican Republic', code: 'DO' },
  { name: 'Ecuador', code: 'EC' },
  { name: 'Egypt', code: 'EG' },
  { name: 'El Salvador', code: 'SV' },
  { name: 'Equatorial Guinea', code: 'GQ' },
  { name: 'Eritrea', code: 'ER' },
  { name: 'Estonia', code: 'EE' },
  { name: 'Ethiopia', code: 'ET' },
  { name: 'Falkland Islands (Malvinas)', code: 'FK' },
  { name: 'Faroe Islands', code: 'FO' },
  { name: 'Fiji', code: 'FJ' },
  { name: 'Finland', code: 'FI' },
  { name: 'France', code: 'FR' },
  { name: 'French Guiana', code: 'GF' },
  { name: 'French Polynesia', code: 'PF' },
  { name: 'French Southern Territories', code: 'TF' },
  { name: 'Gabon', code: 'GA' },
  { name: 'Gambia', code: 'GM' },
  { name: 'Georgia', code: 'GE' },
  { name: 'Germany', code: 'DE' },
  { name: 'Ghana', code: 'GH' },
  { name: 'Gibraltar', code: 'GI' },
  { name: 'Greece', code: 'GR' },
  { name: 'Greenland', code: 'GL' },
  { name: 'Grenada', code: 'GD' },
  { name: 'Guadeloupe', code: 'GP' },
  { name: 'Guam', code: 'GU' },
  { name: 'Guatemala', code: 'GT' },
  { name: 'Guernsey', code: 'GG' },
  { name: 'Guinea', code: 'GN' },
  { name: 'Guinea-Bissau', code: 'GW' },
  { name: 'Guyana', code: 'GY' },
  { name: 'Haiti', code: 'HT' },
  { name: 'Heard Island and Mcdonald Islands', code: 'HM' },
  { name: 'Holy See (Vatican City State)', code: 'VA' },
  { name: 'Honduras', code: 'HN' },
  { name: 'Hong Kong', code: 'HK' },
  { name: 'Hungary', code: 'HU' },
  { name: 'Iceland', code: 'IS' },
  { name: 'India', code: 'IN' },
  { name: 'Indonesia', code: 'ID' },
  { name: 'Iran, Islamic Republic Of', code: 'IR' },
  { name: 'Iraq', code: 'IQ' },
  { name: 'Ireland', code: 'IE' },
  { name: 'Isle of Man', code: 'IM' },
  { name: 'Israel', code: 'IL' },
  { name: 'Italy', code: 'IT' },
  { name: 'Jamaica', code: 'JM' },
  { name: 'Japan', code: 'JP' },
  { name: 'Jersey', code: 'JE' },
  { name: 'Jordan', code: 'JO' },
  { name: 'Kazakhstan', code: 'KZ' },
  { name: 'Kenya', code: 'KE' },
  { name: 'Kiribati', code: 'KI' },
  { name: 'Korea, Democratic People\'S Republic of', code: 'KP' },
  { name: 'Korea, Republic of', code: 'KR' },
  { name: 'Kuwait', code: 'KW' },
  { name: 'Kyrgyzstan', code: 'KG' },
  { name: 'Lao People\'S Democratic Republic', code: 'LA' },
  { name: 'Latvia', code: 'LV' },
  { name: 'Lebanon', code: 'LB' },
  { name: 'Lesotho', code: 'LS' },
  { name: 'Liberia', code: 'LR' },
  { name: 'Libyan Arab Jamahiriya', code: 'LY' },
  { name: 'Liechtenstein', code: 'LI' },
  { name: 'Lithuania', code: 'LT' },
  { name: 'Luxembourg', code: 'LU' },
  { name: 'Macao', code: 'MO' },
  { name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK' },
  { name: 'Madagascar', code: 'MG' },
  { name: 'Malawi', code: 'MW' },
  { name: 'Malaysia', code: 'MY' },
  { name: 'Maldives', code: 'MV' },
  { name: 'Mali', code: 'ML' },
  { name: 'Malta', code: 'MT' },
  { name: 'Marshall Islands', code: 'MH' },
  { name: 'Martinique', code: 'MQ' },
  { name: 'Mauritania', code: 'MR' },
  { name: 'Mauritius', code: 'MU' },
  { name: 'Mayotte', code: 'YT' },
  { name: 'Mexico', code: 'MX' },
  { name: 'Micronesia, Federated States of', code: 'FM' },
  { name: 'Moldova, Republic of', code: 'MD' },
  { name: 'Monaco', code: 'MC' },
  { name: 'Mongolia', code: 'MN' },
  { name: 'Montserrat', code: 'MS' },
  { name: 'Morocco', code: 'MA' },
  { name: 'Mozambique', code: 'MZ' },
  { name: 'Myanmar', code: 'MM' },
  { name: 'Namibia', code: 'NA' },
  { name: 'Nauru', code: 'NR' },
  { name: 'Nepal', code: 'NP' },
  { name: 'Netherlands', code: 'NL' },
  { name: 'Netherlands Antilles', code: 'AN' },
  { name: 'New Caledonia', code: 'NC' },
  { name: 'New Zealand', code: 'NZ' },
  { name: 'Nicaragua', code: 'NI' },
  { name: 'Niger', code: 'NE' },
  { name: 'Nigeria', code: 'NG' },
  { name: 'Niue', code: 'NU' },
  { name: 'Norfolk Island', code: 'NF' },
  { name: 'Northern Mariana Islands', code: 'MP' },
  { name: 'Norway', code: 'NO' },
  { name: 'Oman', code: 'OM' },
  { name: 'Pakistan', code: 'PK' },
  { name: 'Palau', code: 'PW' },
  { name: 'Palestinian Territory, Occupied', code: 'PS' },
  { name: 'Panama', code: 'PA' },
  { name: 'Papua New Guinea', code: 'PG' },
  { name: 'Paraguay', code: 'PY' },
  { name: 'Peru', code: 'PE' },
  { name: 'Philippines', code: 'PH' },
  { name: 'Pitcairn', code: 'PN' },
  { name: 'Poland', code: 'PL' },
  { name: 'Portugal', code: 'PT' },
  { name: 'Puerto Rico', code: 'PR' },
  { name: 'Qatar', code: 'QA' },
  { name: 'Reunion', code: 'RE' },
  { name: 'Romania', code: 'RO' },
  { name: 'Russian Federation', code: 'RU' },
  { name: 'RWANDA', code: 'RW' },
  { name: 'Saint Helena', code: 'SH' },
  { name: 'Saint Kitts and Nevis', code: 'KN' },
  { name: 'Saint Lucia', code: 'LC' },
  { name: 'Saint Pierre and Miquelon', code: 'PM' },
  { name: 'Saint Vincent and the Grenadines', code: 'VC' },
  { name: 'Samoa', code: 'WS' },
  { name: 'San Marino', code: 'SM' },
  { name: 'Sao Tome and Principe', code: 'ST' },
  { name: 'Saudi Arabia', code: 'SA' },
  { name: 'Senegal', code: 'SN' },
  { name: 'Serbia and Montenegro', code: 'CS' },
  { name: 'Seychelles', code: 'SC' },
  { name: 'Sierra Leone', code: 'SL' },
  { name: 'Singapore', code: 'SG' },
  { name: 'Slovakia', code: 'SK' },
  { name: 'Slovenia', code: 'SI' },
  { name: 'Solomon Islands', code: 'SB' },
  { name: 'Somalia', code: 'SO' },
  { name: 'South Africa', code: 'ZA' },
  { name: 'South Georgia and the South Sandwich Islands', code: 'GS' },
  { name: 'Spain', code: 'ES' },
  { name: 'Sri Lanka', code: 'LK' },
  { name: 'Sudan', code: 'SD' },
  { name: 'Suriname', code: 'SR' },
  { name: 'Svalbard and Jan Mayen', code: 'SJ' },
  { name: 'Swaziland', code: 'SZ' },
  { name: 'Sweden', code: 'SE' },
  { name: 'Switzerland', code: 'CH' },
  { name: 'Syrian Arab Republic', code: 'SY' },
  { name: 'Taiwan, Province of China', code: 'TW' },
  { name: 'Tajikistan', code: 'TJ' },
  { name: 'Tanzania, United Republic of', code: 'TZ' },
  { name: 'Thailand', code: 'TH' },
  { name: 'Timor-Leste', code: 'TL' },
  { name: 'Togo', code: 'TG' },
  { name: 'Tokelau', code: 'TK' },
  { name: 'Tonga', code: 'TO' },
  { name: 'Trinidad and Tobago', code: 'TT' },
  { name: 'Tunisia', code: 'TN' },
  { name: 'Turkey', code: 'TR' },
  { name: 'Turkmenistan', code: 'TM' },
  { name: 'Turks and Caicos Islands', code: 'TC' },
  { name: 'Tuvalu', code: 'TV' },
  { name: 'Uganda', code: 'UG' },
  { name: 'Ukraine', code: 'UA' },
  { name: 'United Arab Emirates', code: 'AE' },
  { name: 'United Kingdom', code: 'GB' },
  { name: 'United States', code: 'US' },
  { name: 'United States Minor Outlying Islands', code: 'UM' },
  { name: 'Uruguay', code: 'UY' },
  { name: 'Uzbekistan', code: 'UZ' },
  { name: 'Vanuatu', code: 'VU' },
  { name: 'Venezuela', code: 'VE' },
  { name: 'Viet Nam', code: 'VN' },
  { name: 'Virgin Islands, British', code: 'VG' },
  { name: 'Virgin Islands, U.S.', code: 'VI' },
  { name: 'Wallis and Futuna', code: 'WF' },
  { name: 'Western Sahara', code: 'EH' },
  { name: 'Yemen', code: 'YE' },
  { name: 'Zambia', code: 'ZM' },
  { name: 'Zimbabwe', code: 'ZW' }
];

export const AGENCIES_EMAIL = {
  '62554fddfa47443d8ac78e44': 'admin@salessupport.com',
  '626906540d34bcb47ffa9432': 'support@sellerrepay.com',
  '63076bd993c6a6240e12a717': 'admin@ecommrefund.com',
  '646b70dc3726b75cf76f7be0': 'support@sellerterminal.com'
};

export const AGENCY_NAMES = {
  '626906540d34bcb47ffa9432': 'Seller Repay',
  '63076bd993c6a6240e12a717': 'Ecomm Refund',
  '62554fddfa47443d8ac78e44': 'Sales Support',
  '646b70dc3726b75cf76f7be0': 'Seller Terminal'
};

export const AGENCIES = [
  {
    value: '626906540d34bcb47ffa9432',
    label: 'Seller Repay'
  },
  {
    value: '63076bd993c6a6240e12a717',
    label: 'Ecomm Refund'
  },
  {
    value: '62554fddfa47443d8ac78e44',
    label: 'Sales Support'
  },
  {
    value: '646b70dc3726b75cf76f7be0',
    name: 'Seller Terminal'
  }
];

export const AGENCY_USERS = {
  '626906540d34bcb47ffa9432': 'Johnny Green',
  '62554fddfa47443d8ac78e44': 'Sales Support',
  '63076bd993c6a6240e12a717': 'Ecomm Refund',
  '646b70dc3726b75cf76f7be0': 'Seller Terminal'
};

export const ALL_MARKETPLACES_IDS = {
  na: [
    'ATVPDKIKX0DER',
    'A2EUQ1WTGCTBG2',
    'A1AM78C64UM0Y8',
    'A2Q3Y263D00KWC'
  ],
  eu: [
    'A1F83G8C2ARO7P',
    'A1PA6795UKMFR9',
    'A13V1IB3VIYZZH',
    'APJ6JRA9NG5V4',
    'A1RKKUPIHCS9HS',
    'A1805IZSGTT6HS',
    'A2NODRKZP88ZB9',
    'A1C3SOZRARQ6R3',
    'A33AVAJ2PDY3EV',
    'A21TJRUUN4KGV',
    'A2VIGQ35RCS4UG'
  ],
  fe: [
    'A1VC38T7YXB528',
    'A39IBJ37TRP1C6',
    'A19VAU5U5O7RUS'
  ]
};

export const MONTHS = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December'
}

export const APP_DOMAINS = {
  sellerRepay: 'app.sellerrepay.com',
  sellerTerminal: 'app.sellerterminal.com',
  salesSupport: 'reimbursements.sales.support',
  ecommRefund: 'ecommrefund.com',
  localhost: 'localhost'
};

export const APP_ICONS = {
  [APP_DOMAINS.sellerRepay]: SellerRepayNewIcon,
  [APP_DOMAINS.localhost]: SellerRepayNewIcon,
  [APP_DOMAINS.sellerTerminal]: sellerTerminalIcon,
  [APP_DOMAINS.salesSupport]: localHostIcon,
  [APP_DOMAINS.ecommRefund]: localHostIcon
};

export const APP_TITLES = {
  [APP_DOMAINS.sellerRepay]: 'Seller Repay',
  [APP_DOMAINS.localhost]: 'Localhost',
  [APP_DOMAINS.sellerTerminal]: 'Seller Terminal',
  [APP_DOMAINS.salesSupport]: 'Sales Support',
  [APP_DOMAINS.ecommRefund]: 'Ecomm Refund'
};

export const PUBLIC_KEYS = {
  [APP_DOMAINS.sellerRepay]: STRIPE_PUBLIC_KEY_SELLER_REPAY,
  [APP_DOMAINS.localhost]: STRIPE_PUBLIC_KEY_LOCALHOST,
  [APP_DOMAINS.sellerTerminal]: STRIPE_PUBLIC_KEY_SELLER_TERMINAL,
  [APP_DOMAINS.salesSupport]: STRIPE_PUBLIC_KEY_SELLER_TERMINAL,
  [APP_DOMAINS.ecommRefund]: STRIPE_PUBLIC_KEY_SELLER_TERMINAL
};

export const APP_LOGOS = {
  [APP_DOMAINS.sellerRepay]: SellerRepayLogo,
  [APP_DOMAINS.sellerTerminal]: SellerTerminalLogo,
  [APP_DOMAINS.salesSupport]: SalesSupportLogo,
  [APP_DOMAINS.localhost]: SellerRepayLogo
};

export const RECAPTCHA_SITE_KEYS = {
  [APP_DOMAINS.sellerRepay]: SELLER_REPAY_RECAPTCHA_SITE_KEY,
  [APP_DOMAINS.sellerTerminal]: SELLER_TERMINAL_RECAPTCHA_SITE_KEY,
  [APP_DOMAINS.ecommRefund]: ECOMM_REFUND_RECAPTCHA_SITE_KEY,
  [APP_DOMAINS.localhost]: LOCAL_HOST_RECAPTCHA_SITE_KEY,
  [APP_DOMAINS.salesSupport]: SALES_SUPPORT_RECAPTCHA_SITE_KEY
};

export const SELLER_CENTRAL_EMAILS = {
  [APP_DOMAINS.sellerRepay]: 'accountaudit@sellerrepay.com',
  [APP_DOMAINS.sellerTerminal]: 'support@sellerterminal.com',
  [APP_DOMAINS.ecommRefund]: 'casemanager@ecommrefund.com',
  [APP_DOMAINS.localhost]: 'localhost@gmail.com'
};

export const MANAGER_NAMES = {
  [APP_DOMAINS.sellerRepay]: 'Seller Repay Manager',
  [APP_DOMAINS.sellerTerminal]: 'Seller Terminal Manager',
  [APP_DOMAINS.ecommRefund]: 'Ecomm Refund Manager',
  [APP_DOMAINS.localhost]: 'Localhost Manager'
};

export const REDIRECT_URIS = {
  [APP_DOMAINS.sellerRepay]: AMAZON_REDIRECT_URI_SELLER_REPAY,
  [APP_DOMAINS.sellerTerminal]: AMAZON_REDIRECT_URI_SELLER_TERMINAL,
  [APP_DOMAINS.ecommRefund]: AMAZON_REDIRECT_URI_ECOM_REFUND,
  [APP_DOMAINS.localhost]: AMAZON_REDIRECT_URI_SELLER_TERMINAL
};

export const APP_IDS = {
  [APP_DOMAINS.sellerRepay]: APP_ID_SELLER_REPAY,
  [APP_DOMAINS.localhost]: APP_ID_SELLER_TERMINAL,
  [APP_DOMAINS.sellerTerminal]: APP_ID_SELLER_TERMINAL,
  [APP_DOMAINS.ecommRefund]: APP_ID_ECOM_REFUND
};

export const CALENDLY_LINKS = {
  [APP_DOMAINS.sellerTerminal]: SELLER_TERMINAL_CALENDLY_LINK,
  [APP_DOMAINS.sellerRepay]: SELLER_REPAY_CALENDLY_LINK,
  [APP_DOMAINS.localhost]: SELLER_TERMINAL_CALENDLY_LINK
};

export const SELLER_TERMINAL_SENTRY_DSN = 'https://025325bdd6f3abae839b6d5264d71fc0@o127726.ingest.sentry.io/4506071033905152';

export const MARKETPLACES_IMAGES = {
  'CA': Canada,
  'US': US,
  'MX': Mexico,
  'BR': Brazil,
  'ES': Spain,
  'GB': UK,
  'FR': France,
  'NL': Netherlands,
  'DE':  Germany,
  'IT': Italy,
  'SE': Sweden,
  'PL': Poland,
  'TR': Turkey,
  'AE': UAE,
  'IN': India,
  'SA': Saudia,
  'EG': Egypt,
  'SG': Singapore,
  'AU': Australia,
  'JP': Japan
}

export const BASE_URLS = {
  na: 'https://sellercentral.amazon.com/',
  eu: 'https://sellercentral.amazon.co.uk/',
  fe: 'https://sellercentral.amazon.co.jp/'
}

export const REPORT_ISSUES_LIST = [
  {
    label: 'Request for Passport',
    value: 'Request for Passport'
  }, {
    label: 'Invite  Issue',
    value: 'Invite Issue'
  }, {
    label: 'Permissions  Issue',
    value: 'Permissions Issue'
  }, {
    label: 'Payment Issue',
    value: 'Payment Issue'
  }, {
    label: 'Other',
    value: 'Other'
  }
];

export const ST_REPORT_ISSUE_SLACK_CHANNEL = 'https://hooks.slack.com/services/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';

export const STORE_STATUS = {
  CONNECTED: 'Connected',
  INVITE_ACCEPTED: 'Invite_Accepted',
  PENDING: 'Pending'
};

export const ROLES = {
  USER: 'user',
  ADMIN: 'admin',
  AFFILIATE_MANAGER: 'affiliate-manager',
  AGENCY_MANAGER: 'agency-manager',
};

export const USER_STATUS = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive'
}

export const TAWK_DATA = {
  SELLER_REPAY_PUBLIC: {
    property: '66389f679a809f19fb2dd30e',
    widget: '1ht6itb7c'
  },
  SELLER_TERMINAL_PUBLIC: {
    property: '66388a5d07f59932ab3c6f2b',
    widget: '1ht6dovf5'
  },
  SELLER_REPAY_PRIVATE: {
    property: '66389e8207f59932ab3c7553',
    widget: '1ht6imcdr'
  },
  SELLER_TERMINAL_PRIVATE: {
    property: '663887d79a809f19fb2dcc47',
    widget: '1ht6d594d'
  }
}

export const AUTOMATION_LOG_STATUS = [{
  value: '',
  label: 'All'
}, {
  value: 'Already_Reimbursed',
  label: 'Already Reimbursed'
}, {
  value: 'Case Open',
  label: 'Case Open'
}, {
  value: 'No_Actionable_Event',
  label: 'No Actionable Event'
}, {
  value: 'No_Matching_Listing',
  label: 'No Matching Listing'
}, {
  value: 'Not_Eligible',
  label: 'Not Eligible'
}, {
  value: 'Pending_Eligibility',
  label: 'Pending Eligibility'
}, {
  value: 'Reimbursed',
  label: 'Reimbursed'
}, {
  value: 'Success',
  label: 'Success'
}, {
  value: 'Other',
  label: 'Other'
}, {
  value: 'Failed',
  label: 'Failed'
}, {
  value: 'Rejected',
  label: 'Rejected'
}];
