import { configureStore, combineReducers } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import { persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import createFilter from 'redux-persist-transform-filter';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

import accountDetailSlice from '../slices/account-detail-slice';
import affiliateSlice from '../slices/affiliate-slice';
import authSlice from '../slices/auth-slice';
import automationSlice from '../slices/automation-slice'
import dashboardSlice from '../slices/dashboard-slice';
import dataGatheringSlice from '../slices/data-gathering';
import fulfillmentFeeSlice from '../slices/fulfillment-fee-slice';
import agencySlice from '../slices/agency-slice';
import invoiceSlice from '../slices/invoice-slice';
import jobSlice from '../slices/jobs-slice';
import shipmentSlice from '../slices/shipment-slice';
import storeSlice from '../slices/store-slice';
import stripeSlice from '../slices/stripe-slice';
import userSlice from '../slices/user-slice';
import paymentSlice from '../slices/payment-slice';

const storeTransform = createFilter('store', ['storeSuccess', 'marketplaces', 'state', 'storeId', 'stores']);
const invoiceTransform = createFilter('invoice', ['headerDetailOfInvoiceDetail', 'totalReimbursments']);

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel2,
  whitelist: ['auth', 'store', 'invoice'],
  transforms: [storeTransform, invoiceTransform]
};

const reducers = combineReducers({
  accountDetail: accountDetailSlice,
  affiliate: affiliateSlice,
  auth: authSlice,
  automation: automationSlice,
  dashboard: dashboardSlice,
  dataGathering: dataGatheringSlice,
  fulfillmentFee: fulfillmentFeeSlice,
  store: storeSlice,
  job: jobSlice,
  user: userSlice,
  invoice: invoiceSlice,
  stripe: stripeSlice,
  agency: agencySlice,
  shipment: shipmentSlice,
  payment: paymentSlice
});

const rootReducer = (state, action) => {
  if (action.type === 'auth/LogOut') {
    state = undefined;
  }
  return reducers(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default configureStore({
  reducer: persistedReducer,
  middleware: [thunk, logger],
  devTools: true
});
